import React, { Component } from 'react'; 
import SecuritySetting from './securitysetting';
import EventSetting from './eventsetting';
import LocationSetting from './locationsettings';
import AttributeSetting from './attribute-settings';

class SettingsListItem extends Component {
  constructor() {
    super();
    this.state= {
      text: '',
      active: false,
      viewState: true,
      securityHeirarchialState: []
    }
  }
  componentWillMount() {
    this.setState({
      ...this.state,
        text : this.props.text,
        viewState: this.props.viewState
    })
  }
  componentWillReceiveProps(nextProps) {
    if(this.props.viewState !== nextProps.viewState) {
      this.setState({
        ...this.state,
        viewState: nextProps.viewState
      })
    }
  }
  handleClick = (e) => {
    this.setState({
      ...this.state,
        active:!this.state.active,
    },() => {
      window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
      window.Mixpanel.MixpanelProps.Setting = this.state.text;
      window.Mixpanel.actions.identify(this.props.userData.username);
      let actionTrackerText = this.state.active ? `Expanded ${this.state.text}` : `Minimised ${this.state.text}`
      window.Mixpanel.actions.track(actionTrackerText, window.Mixpanel.MixpanelProps).then(data => {
        window.Mixpanel.actions.people.set({
          UserName: this.props.userData.username
        });
      })
      this.props.settingToggle(this.state.text, this.state.active)
    })
  }
  render() {
    const { active, text, viewState } = this.state;
    let list = {}; let actualText = '';
    switch(text) {
      case 'Assets':
        list[text] = 'fa-line-chart';
        actualText = 'Related Assets / Domains';
        break;
      case 'Events':
          list[text] = 'fa-podcast';
          actualText = 'Event Types';
          break;
      case 'Locations':
          list[text] = 'fa-map-marker';
          actualText = text;
          break;
      case 'Topics':
          list[text] = 'fa-tag';
          actualText = text;
          break;
      case 'Correlations':
          list[text] = 'fa-chain';
          actualText = text;
          break;
      default:
        break;
    }
    return(
      viewState ? (
        <div className={`settings-list-item ${!viewState ? 'nodisp' : ''}`}>
        
          <div className={`settings-item-title sm-fs12 ${active ? 'selected': ''}`} onClick={this.handleClick}>
            <span className={`settings-item-icon`}><i className={` fa ${list[text]}`}></i></span>
            { 
              
              text !== 'Assets' && text !== 'Events' && text !== 'Locations' && text !== 'Topics' ? `${actualText} (Coming Soon)` : actualText
              
            } <i className={`fa ${!active ? 'fa-caret-down' : 'fa-caret-up selected'} float-left open-but`}></i>
          </div>
          {
            active && (
              <div className={`settings-item-content`}>
                { text === 'Assets' ? (<SecuritySetting active={this.state.active} text={this.state.text} />) : ('') }
                { text === 'Events' ? (<EventSetting active={this.state.active} text={this.state.text} />) : ('') }
                { text === 'Locations' ? (<LocationSetting active={this.state.active} text={this.state.text} />) : ('') }
                { text === 'Topics' ? (<AttributeSetting active={this.state.active} text={this.state.text} />) : ('') }
              </div>
            )
          }
          
          
          
        </div>
      ) : ('')
      
    )
  }
}
export default SettingsListItem