import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';

import Home from './components/core_layout/home'; 
import Authenticate from './components/core_layout/auntheticate';

import Stories from './screens/stories';

import Settings from './screens/settings';
import Userpreferences from './screens/userpreferences';
import Feedback from './screens/feedback';
import Changepassword from './screens/changepassword';
// import Securities from './components/securities/securities';
// import Contactus from './components/contactus/contactus';
// import TermsAndConditions from './components/termsandconditions/termsnconditions';
// import PrivacyPolicy from './components/privacypolicy/privacy_policy';
// import FAQ from './components/faq/faq';

class AppRouter extends Component {
  constructor() {
    super();
    this.state = {}
  }



  render() {
    const { userData } = this.props;
    
    return (
      
        <Switch>
          
            {
              (userData && (userData.isLoggedIn && userData.TNC)) ? (
                 
                  
                <Switch>
                  
                  <Route exact path="/" render={(props) => 
                    <Home {...props} userData={this.props.userData} page="stories" >
                      <Stories {...props} userData={this.props.userData} />
                    </Home>
                  }/>
                  <Route path="/Settings" render={(props) => 
                    <Home {...props} userData={this.props.userData} page="settings" >
                      <Settings {...props} userData={this.props.userData} />
                    </Home>
                  }/>
                  <Route path="/User-Preferences" render={(props) => 
                    <Home {...props} userData={this.props.userData} page="userpreferences" >
                      <Userpreferences {...props} userData={this.props.userData}  />
                    </Home>
                  }/>
                  <Route path="/Feedbacks" render={(props) => 
                    <Home {...props} userData={this.props.userData} page="feedbacks" >
                      <Feedback {...props} userData={this.props.userData}  />
                    </Home>
                  }/>
                  <Route path="/Change-Password" render={(props) => 
                    <Home {...props} userData={this.props.userData} page="changepassword" >
                      <Changepassword {...props} userData={this.props.userData} />
                    </Home>
                  }/>
                  <Redirect to="/" />
                </Switch>
                
              ) : (
                <Switch>
                  {
                    // userData.isLoggedIn && !userData.TNC && !userData.InitialPasswordReset ? (
                    //   <Route exact path="/" render={ (props)=><Authenticate {...props} userData={userData} page="login" modalShow={true} modalContent={'PASSRESET'} /> } />
                    // ) :&& userData.InitialPasswordReset
                     userData.isLoggedIn && !userData.TNC ? (
                      <Route exact path="/" render={ (props)=><Authenticate {...props} userData={userData} page="login" modalShow={true} modalContent={'TNC'} /> } /> 
                    ) : (
                      <Route exact path="/" render={ (props)=><Authenticate {...props} userData={userData} page="login" modalShow={false} modalContent={''} /> } />
                    )
                  }
                  <Redirect to="/" />
                </Switch>
              )
            }
          
        </Switch> 
        
    )
  }

}

const mapStateToProps = (state) => {
  return {
    userData : state.settings.userData,
  }
}


export default connect(mapStateToProps, null)(AppRouter)