import React, { Component } from 'react'; 
import { connect } from 'react-redux';
// import Logo from '../../headbar/logo';
import { Row, Col } from 'react-bootstrap';
import * as searchActionCreators from '../../../../actions/search.action';


class SearchTitleSave extends Component {
  constructor() {
    super();
    this.state = {
      searchsavetitle: '',
      validationErrors: {
        maxlengthError : false
      },
      styles : {
        validationError: {
          color: "#ff3333",
          fontSize: "12px",
        }
      }
    }
  }

  captureSaveTitle = (e) => {
    this.setState({
      ...this.state,
      [`${e.target.id}`] : e.target.value.length > 15 ? this.state[`${e.target.id}`] : e.target.value,
      validationErrors: {
        ...this.state.validationErrors,
        maxlengthError: e.target.value.length > 15 ? true : false
      }
    })
  }

  saveSearchTitle = (e) => {
    const { searchsavetitle } = this.state
    const saveInit = true;
    
    this.props.initSearchSave(saveInit, searchsavetitle !== '' ? searchsavetitle : 'Search Results');
    this.props.changeView('Loader')
  }

  render() {
    
    const { searchsavetitle } = this.state;
    
    return (
      <div className="Savetitle-content">
        <button type="button" className="close text-white nopad marg-0" data-dismiss="alert" aria-label="Close" onClick={this.saveSearchTitle}>
          <span aria-hidden="true">&times;</span>
        </button> 
        <Row className="marg-0">
          <Col className="nopad">
            <div className="Savetitle-header" >
              <h5>Enter Search Results Title</h5>
            </div>
          </Col>
        </Row>
        
        {/* <div className="Savetitle-body"> */}
          <Row className="marg-0 Savetitle-body">
            <Col lg={11} sm={11} xs={12} md={11} xl={11} className="nopad">
              <input type="text" id="searchsavetitle" className="form-control" value={searchsavetitle} onChange={this.captureSaveTitle} placeholder="Search Results" />
            </Col>
            <Col lg={1} sm={1} xs={21} md={1} xl={1} className="nopad text-right">
              <button className="saveTitleButton btn btn-SearchTitle" onClick={this.saveSearchTitle}><i className="fa fa-check"></i></button>
            </Col>
          </Row>
          <Row className={`marg-0 ${!this.state.validationErrors.maxlengthError ? 'nodisp' : ''}`}>
            <Col className={`nopad padt2`} >
              <p style={this.state.styles.validationError} >Kindly limit title to maximum allowed (15) characters.</p>
            </Col>
          </Row>
        {/* </div> */}
        
      </div>
    )
  }
}

const mapDispatchToProps = {
  ...searchActionCreators
};



export default connect(null, mapDispatchToProps)(SearchTitleSave)