import React, { Component } from 'react'; 
import { Row, Col } from 'react-bootstrap';
import { APPPATH } from '../../../../modules/helper.module';

import LikeDislike from '../../../stories/story_likedislike';
import StoryMessage from '../../../stories/story_message';
import MuteUnmute from '../../../stories/muteunmute';

class StoryOptionsMenuIndex extends Component {
    constructor(props) {
        super();
        this.state = {
            story: props.data,
            ActiveTab: 'likedislike',
            ActiveView: <LikeDislike {...props.data} modalClose={props.modalClose}/>
        }
    }

    changeActiveTabTo = (tab) => {
        let activeView = this.state.ActiveView
        switch(tab) {
            case 'likedislike':
                activeView = <LikeDislike {...this.state.story} modalClose={this.props.modalClose}   />
                break;
            case 'share':
                activeView = <StoryMessage {...this.state.story} modalClose={this.props.modalClose}   />
                break;
            case 'muteunmute':
                activeView = <MuteUnmute {...this.state.story} modalClose={this.props.modalClose}   />
                break;
            default: 
                break;
        }
        this.setState({
            ...this.state,
            ActiveTab: tab,
            ActiveView: activeView
        })
    }

    render() {
        const { ActiveTab, ActiveView } = this.state;
        return(
            <div className="storymenuoptions-content">
                <div className="btn storymenuoption-close text-white nopad marg-0"  onClick={this.props.modalClose}>
                {/* <span aria-hidden="true">&times;</span> */}
                    <span className="pad5" ><img  className="storymenuoption-closeicon" src={`${APPPATH.ImageUrl}/modalcloseicon.svg`} alt="" /></span>
                </div> 
                <Row className="marg-0 storymenuoptions-tabheader">
                    <Col className="nopad">
                        <div className="nav nav-tabs storymenuoptions nav-fill  ">
                            <div className={`nav-item fs16 ${ActiveTab === 'likedislike'? 'active' : ''}`} onClick={() => this.changeActiveTabTo('likedislike')}>
                                <span >
                                    <img className="nav-tab-img" src={`${APPPATH.ImageUrl}/likedislikevectoricon.svg`} alt="" />
                                </span>
                                <span >Like/Dislike</span>
                            </div>
                            <div className={`nav-item fs16 ${ActiveTab === 'share'? 'active' : ''}`} onClick={() => this.changeActiveTabTo('share')}>
                                <span >
                                    <img className="nav-tab-img" src={`${APPPATH.ImageUrl}/shareicon.svg`} alt="" />
                                </span>
                                <span >Share</span>
                            </div>
                            <div className={`nav-item fs16 ${ActiveTab === 'muteunmute'? 'active' : ''}`} onClick={() => this.changeActiveTabTo('muteunmute')}>
                                <span >
                                    <img className="nav-tab-img" src={`${APPPATH.ImageUrl}/muteunmuteicon.svg`} alt="" />
                                </span>
                                <span >Mute</span>
                            </div>
                        </div>
                    </Col>
                </Row>
                { ActiveView }
                
                {/* <Row className="marg-0 Savetitle-body">
                    <Col lg={11} sm={11} xs={12} md={11} xl={11} className="nopad">
                    <input type="text" id="searchsavetitle" className="form-control" value={searchsavetitle} onChange={this.captureSaveTitle} placeholder="Enter search title..." />
                    </Col>
                    <Col lg={1} sm={1} xs={21} md={1} xl={1} className="nopad text-right">
                    <button className="saveTitleButton btn btn-SearchTitle" onClick={this.saveSearchTitle}><i className="fa fa-check"></i></button>
                    </Col>
                </Row>
                <Row className={`marg-0 ${!this.state.validationErrors.maxlengthError ? 'nodisp' : ''}`}>
                    <Col className={`nopad padt2`} >
                    <p style={this.state.styles.validationError} >Kindly limit title to maximum allowed (15) characters.</p>
                    </Col>
                </Row> */}
                
                
            </div>
        );
    }
}

export default StoryOptionsMenuIndex