import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';

class ColorPreferenceSlider extends Component {
  constructor(props) {
    super();
    let { bgImage, unselectedColorStop } = this.extractColorCodeValues(props.colorCodeValue);
    this.state = {
      thisInstance: this,
      min: 1,
      max: 100,
      width: 80,
      ColorProperties: props.colorCodeValue,
      bgImage,
      sliderValue: unselectedColorStop,
      sliderTouched: false
    };
    this.componentWrap = '';
    this.slider = '';
    this.ttHandle = '';
    this.Overlay = React.createRef();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if(JSON.stringify(nextProps.colorCodeValue) !== JSON.stringify(prevState.colorCodeValue)) {
      let { bgImage, unselectedColorStop } = prevState.thisInstance.extractColorCodeValues(
        nextProps.colorCodeValue,
        prevState.sliderValue
      );
      // console.log(unselectedColorStop, bgImage)
      return {
        ColorProperties: nextProps.colorCodeValue,
        bgImage,
        sliderValue: unselectedColorStop,
      };
    }
    
  }

  extractColorCodeValues = (ColorCodeVal, unselectedColorStop = false) => {
    const unselectedRangeColor = '#2F3236';
    // console.log(!unselectedColorStop)
    let Colors = Object.keys(ColorCodeVal);
    let CheckStatusArr = Colors.map((clr) => ColorCodeVal[clr].CheckStatus);
    let colorStopPercArr = Colors.map((clr) => ColorCodeVal[clr].ColorStopPerc);
    let colorStartPercArr = Colors.map((clr) => ColorCodeVal[clr].ColorStartPerc);
    if (unselectedColorStop === false) {
      // console.log(ColorCodeVal, unselectedColorStop)
      unselectedColorStop = this.getSliderValue(CheckStatusArr, colorStartPercArr, colorStopPercArr);
    }
    // console.log(unselectedColorStop)
    let bgImage = `linear-gradient(to right,`;
    if (unselectedColorStop !== false) {
      bgImage = `${bgImage} ${unselectedRangeColor} ${
        unselectedColorStop - parseFloat(unselectedColorStop / 10) - 0.5
      }%,`;
    }
    let clrArr = [];
    Colors.forEach((Color, indx) => {
      clrArr.push(
        `${Color} ${ColorCodeVal[Color].ColorStartPerc ? ColorCodeVal[Color].ColorStartPerc + '%' : ''} ${
          ColorCodeVal[Color].ColorStopPerc
        }%`
      );
    });
    bgImage = `${bgImage} ${clrArr.join(',')})`;
    return { bgImage, unselectedColorStop };
  };

  getSliderValue = (CheckStatus, ColorStartPerc, ColorStopPerc) => {
    // console.log(CheckStatus, ColorStopPerc)
    let sliderindicator = 0;
    let sliderVal = 0;
    for (let i = 0; i < CheckStatus.length; i++) {
      // console.log(CheckStatus[i] === 'true')
      if (CheckStatus[i] === 'true' || CheckStatus[i] === true) {
        sliderindicator = i;
        // console.log(i)
        break;
      }
    }
    // console.log(sliderindicator)
    if (sliderindicator === 0) {
      sliderVal = 0;
    } else if (sliderindicator === CheckStatus.length - 1) {
      sliderVal = 99;
    } else {
      sliderVal = ColorStartPerc[sliderindicator]
        ? ColorStartPerc[sliderindicator] + 10
        : ColorStopPerc[sliderindicator] - 10;
    }
    return sliderVal;
  };

  sliderMoving = (e) => {
    // console.log('oninput', e.target.value)
    // console.log(slider)
    e.persist();
    e.preventDefault();
    e.stopPropagation();
    let colorStopVal = e.target.value - e.target.getAttribute('min');
    let { bgImage } = this.extractColorCodeValues(this.state.ColorProperties, colorStopVal);
    // console.log(e.target.value , e.target.getAttribute('min'), colorStopVal)
    this.setState(
      {
        ...this.state,
        bgImage,
        sliderValue: e.target.value,
        sliderTouched: true
      },
      () => {
        e.target.style.backgroundImage = bgImage;
      }
    );
  };

  sliderMoved = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let val = e.target.value;
    let { sliderTouched } = this.state;
    if(sliderTouched) {
      this.setState({
        ...this.state,
        sliderTouched: false
      }, () => {
        let Colors = Object.keys(this.state.ColorProperties);
        if (Colors.length > 0) {
          for (let i = 0; i <= Colors.length - 1; i++) {
            if (
              val >= this.state.ColorProperties[Colors[i]].ColorStartPerc &&
              val <= this.state.ColorProperties[Colors[i]].ColorStopPerc
            ) {
              this.props.updateAction(Colors[i], true);
              break;
            }
          }
        }
      })
      
    }
    
  };

  sliderToolTipText = () => {
    let val = this.slider.value;
    let tooltiptext = '';
    let Colors = Object.keys(this.state.ColorProperties);
    if (Colors.length > 0) {
      for (let i = 0; i <= Colors.length - 1; i++) {
        if (
          val >= this.state.ColorProperties[Colors[i]].ColorStartPerc &&
          val <= this.state.ColorProperties[Colors[i]].ColorStopPerc
        ) {
          tooltiptext = this.state.ColorProperties[Colors[i]].tooltip;
          break;
        }
      }
    }
    return tooltiptext;
  };

  toggleSliderToolTip = (showState) => {
    if(this.props.usertype === 'None' || this.props.usertype === 'WUser' || this.props.usertype === 'WAdmin') {
      let { sliderValue, width, max } = this.state;
      let SliderRect = this.slider.getBoundingClientRect();
      let Pos = {
        left: SliderRect.left + sliderValue * (width / max) - 30,
        top: SliderRect.bottom + 10,
        width: 100,
        backgroundColor: '#50c0ff',
        color: '#fff'
        // position: 'relative',
      };
      // console.log(Pos)
      let tooltiptext = this.sliderToolTipText();
      if (this.ttHandle) {
        clearTimeout(this.ttHandle);
      }
      const containerStyleClass = `infoTip blue normal-middle ${this.props.Theme === 'light' ? 'lightmode' : ''}`;
      if (showState) {
        if (!isMobile) {
          this.ttHandle = setTimeout(() => {
            // this.props.toggleOverlay(showState, this.Overlay, tooltiptext, {
            //   Arrow: {
            //     marginLeft: 30,
            //   },
            //   ToolTip: {
            //     align: 'center',
            //   },
            // });
              this.props.togglePopup(showState, tooltiptext, Pos, containerStyleClass);
            window.Mixpanel.MixpanelProps.UserName = this.props.username;
            window.Mixpanel.MixpanelProps.Tooltip = tooltiptext;
            window.Mixpanel.MixpanelProps.ToolTipType = 'Preference Slider';
            window.Mixpanel.actions.identify(this.props.username);
            window.Mixpanel.actions.track(`Tooltip shown`, window.Mixpanel.MixpanelProps).then((data) => {
              window.Mixpanel.actions.people.set({
                UserName: this.props.username,
              });
              window.Mixpanel.actions.resetProp('Tooltip');
              window.Mixpanel.actions.resetProp('ToolTipType');
            });
          }, 1000);
        }
      } else {
        this.props.togglePopup(showState);
      }
    }
    
  };

  render() {
    const { min, max, bgImage, sliderValue } = this.state;
    return (
      <span
        ref={this.Overlay}
        style={{ marginRight: 5, position: 'relative' }}
        onMouseEnter={() => {
          this.toggleSliderToolTip(true);
        }}
        onMouseLeave={() => {
          this.toggleSliderToolTip(false);
        }}
      >
        <input
          type="range"
          style={{ verticalAlign: 'middle', backgroundImage: `${bgImage}` }}
          min={min}
          max={max}
          ref={(el) => (this.slider = el)}
          defaultValue={sliderValue}
          onChange={(e) => this.sliderMoving(e)}
          // onInput={(e) => this.sliderMoving(e)}
          onMouseUp={(e) => {
            this.sliderMoved(e);
          }}
          onTouchStart={(e) => {
            e.stopPropagation();
            let scrollDiv = document.getElementsByClassName('cScroll-Mob')[0];
            if(scrollDiv) {
              scrollDiv.style.overflowX = 'hidden'
            }
          }}
          onTouchMove={(e) => e.stopPropagation()}
          onTouchEnd={(e) => {
            let scrollDiv = document.getElementsByClassName('cScroll-Mob')[0];
            if(scrollDiv) {
              scrollDiv.style.overflowX = 'hidden'
            }
            this.sliderMoved(e);
          }}
        />
      </span>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    Theme: state.app_preferences.preferences.user_interface.Theme,
  }
}

export default  connect(mapStateToProps, null)(ColorPreferenceSlider);
