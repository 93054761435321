import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { APPPATH } from '../../../modules/helper.module';
import PremiumButton from '../headbar/PremiumButton';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';

class GoPremiumBanner extends Component {
  constructor() {
    super();
    this.state = {
      show: true,
    };
  }

  closeBanner = () => {
    // this.setState({ ...this.state, show: false });
    //update the setting showPremiumBanner to false

    setTimeout(() => {
      this.props.closePremiumBanner();
    }, 2);
  };

  render() {
    console.log(this.props.freeTrial, this.props.daystogo)
    let trialState = 0;
    let bannerText = '';
    if(!this.props.freeTrial && this.props.daystogo === 0) {
      trialState = 0;
    } else if(this.props.freeTrial && this.props.daystogo > 0) {
      trialState = 1;
    } else if(this.props.freeTrial && this.props.daystogo <= 0) {
      trialState = 2;
    }
    switch(trialState) {
      case 0:
        bannerText = 'Free 2 weeks trial of our premium features';
        break;
      case 1:
        bannerText = 
        // this.props.daystogo === 0 ? 
        // 'You premium trial will be ending today, To keep using it for 70% off...' : 
        `${this.props.daystogo} more days remaining on your premium trial. To keep using it for 70% off...`;
        break;
      case 2:
        bannerText = `Upgrade today for a limited time 70% off offer`;
        break;
      default:
        bannerText = 'For faster real time arrival of data for thousands of coins';
        break;
    }
    return (
      <Row className="marg-0 nopad flying-banner mob h-100">
        <Col lg={11} sm={11} xs={11} md={11} xl={11} className="nopad" 
          onClick={() => {
            trialState === 0 ? this.props.showModal('VerifyEmail') : this.props.toggleConfirmPopup(true)
          }}
        >
          <Row className="marg-0 nopad h-100">
            <Col 
              lg={isMobile ? 7 : 12} 
              sm={isMobile ? 7 : 12} 
              xs={isMobile ? 7 : 12} 
              md={isMobile ? 7 : 12} 
              xl={isMobile ? 7 : 12} 
              className="bannertext align-self-center"
            >
            
              <span className="bannerspan1 fs14 fs13-sm">{ bannerText } { !isMobile && trialState === 0 ? <span style={{color: 'rgb(80 94 255)', fontSize: 11}}>No credit card needed</span> : ''}</span>
              {
                isMobile && trialState === 0 ? (
                  <div style={{color: 'rgb(80 94 255)', fontSize: 11}}>No credit card needed</div>
                ) : ('')
              }
              <span className={isMobile ? 'nodisp' : ''}>
              {
              trialState === 0 ? (
                <span className="btn tryforfree-btn" onClick={() => {this.props.showModal('VerifyEmail')}} >
                  <img src={`${APPPATH.ImageUrl}/button-premium-small-noArrow.svg`} alt="CrowdSense.ai" style={{marginTop: '-1px'}} /><span style={{marginLeft: '-3px', padding: '5px 5px 0px 0px'}}>Try for Free</span>
                </span>
              ) : (
                <PremiumButton
                  noTitle={false}
                  align={true}
                  beta={false}
                  {...this.props}
                  minify={true}
                  isCalledFromTrendingColumn={false}
                />
              )
            }
              </span>
              {/* google play button */}
            </Col> 
            <Col lg={5} sm={5} xs={5} md={5} xl={5} className={`bannertext align-self-center ${isMobile ? '' : 'nodisp'}`}>
            {
              trialState === 0 ? (
                <span className="btn tryforfree-btn" onClick={() => {this.props.showModal('VerifyEmail')}} >
                  <img src={`${APPPATH.ImageUrl}/button-premium-small-noArrow.svg`} alt="CrowdSense.ai" style={{marginTop: '-1px'}} /><span style={{marginLeft: '-3px', padding: '5px 5px 0px 0px'}}>Try for Free</span>
                </span>
              ) : (
                <PremiumButton
                  noTitle={false}
                  align={true}
                  beta={false}
                  {...this.props}
                  minify={true}
                  isCalledFromTrendingColumn={false}
                />
              )
            }
                {/* <span>
                  <img
                      src={`${APPPATH.ImageUrl}/googleplay.png`}
                      className={`mobileAppButtonImage`}
                      alt="CrowdSense.ai"
                      onClick={this.GotoPlayStore}
                  />
                </span> */}
            </Col>
              
              {/* <span>
                <img
                    src={`${APPPATH.ImageUrl}/applestore.png`}
                    className={`mobileAppButtonImage`}
                    alt="CrowdSense.ai"
                />
              </span> */}
            </Row>
        </Col>
      
        <Col lg={1} sm={1} xs={1} md={1} xl={1} className={` bannerClose mob mobinstAppbnr ${!this.state.show ? 'nodisp' : ''} pointer`} >
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 16.458 15.828" onClick={this.closeBanner}>
            <g transform="translate(1.414 1.414)">
              <path
                className="a"
                d="M1560.6,103.5l-13.63,13"
                transform="translate(-1546.97 -103.5)"
                fill="none"
                stroke={'#000'}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2px"
              />
              <g transform="translate(-1546.97 -103.5)">
                <path
                  className="a"
                  d="M1546.97,103.5l13.63,13"
                  fill="none"
                  stroke={'#000'}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2px"
                />
              </g>
            </g>
          </svg>
        </Col>
      </Row>
      // <div class="col-lg-12 flying-banner">
      //   <div class="col-lg-11 bannertext">
      //     <span class="bannerspan">{ bannerText }</span>
      //     {
      //       trialState === 0 ? (
      //         <span className="btn tryforfree-btn" onClick={() => {this.props.toggleConfirmPopup(true)}}>
      //           Try for Free
      //         </span>
      //       ) : (
      //         <PremiumButton
      //           noTitle={false}
      //           align={true}
      //           beta={false}
      //           {...this.props}
      //           minify={true}
      //           isCalledFromTrendingColumn={false}
      //         />
      //       )
      //     }
          
      //   </div>
      //   <div className={`col-lg-1 bannerClose ${!this.state.show ? 'nodisp' : ''} pointer`} >
      //     <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 16.458 15.828" onClick={this.closeBanner}>
      //       <g transform="translate(1.414 1.414)">
      //         <path
      //           className="a"
      //           d="M1560.6,103.5l-13.63,13"
      //           transform="translate(-1546.97 -103.5)"
      //           fill="none"
      //           stroke={'#000'}
      //           strokeLinecap="round"
      //           strokeLinejoin="round"
      //           strokeWidth="2px"
      //         />
      //         <g transform="translate(-1546.97 -103.5)">
      //           <path
      //             className="a"
      //             d="M1546.97,103.5l13.63,13"
      //             fill="none"
      //             stroke={'#000'}
      //             strokeLinecap="round"
      //             strokeLinejoin="round"
      //             strokeWidth="2px"
      //           />
      //         </g>
      //       </g>
      //     </svg>
      //   </div>
      // </div>
      
    );
  }
}

// export default GoPremiumBanner;

const mapStateToApp = (state) => {
  return {
    freeTrial: state.settings.freeTrial,
    daystogo: state.settings.freeTrialDetails.daystogo
  };
};

const mapDispatchToProps = {
  
};

export default connect(mapStateToApp, mapDispatchToProps)(GoPremiumBanner);

/*
  trial state 
  0 -> free first time
  1 -> in free trial
  2 -> free trial ended
*/