import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Badge } from 'react-bootstrap';

class UpgrageToPremiumPopUp extends Component {
  render() {
    return (
      <SweetAlert
        customClass="upgrade-to-premium"
        showCloseButton
        title={
          <>
            <span
              style={{
                float: 'right',
                fontSize: 16,
                marginTop: -20,
                marginRight: -15,
                cursor: 'pointer',
                color: 'white',
              }}
              onClick={this.props.onConfirm}
            >
              <i className="fa fa-times"></i>
            </span>
            <span style={{ fontSize: 16 }}>
              <p style={{ marginBottom: 30 }}>This option is not available in the BASIC plan.</p>
              <p>
                To upgrade to the PREMIUM plan for <strong>33% OFF</strong>
                <br />
                <span style={{ marginTop: -20 }}>(No delays, 3000+ assets)</span>
              </p>
            </span>
          </>
        }
        style={{ border: '1px solid #37aaf8', backgroundColor: '#000', color: 'white' }}
        reverseButtons={true}
        customButtons={
          <Badge pill className="premium-badge" onClick={this.props.onConfirm}>
            <span className="inner-span"> PRESS HERE</span>
          </Badge>
        }
      />
    );
  }
}

export default UpgrageToPremiumPopUp;
