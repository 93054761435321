import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { ResetTMSort } from '../../actions/trendingmentions.action';
// import * as moment from 'moment';
import { connect } from 'react-redux';
import { APPPATH } from '../../modules/helper.module';
import { isMobile } from 'react-device-detect';

class TMTabs extends Component {
  constructor(props) {
    super();
    // console.log(props)
    let TabArr = [];
    if(props.showtopreach) {
      if(props.usertype === 'None' || props.usertype === 'WUser' || props.usertype === 'WAdmin') {
        TabArr = ['TopSentiment', 'TopMentions', 'TopReach', 'TopPrice', 'TopVolume']
      } else {
        TabArr = ['TopSentiment', 'TopMentions', 'TopReach']
      }
    } else {
      if(props.usertype === 'None' || props.usertype === 'WUser' || props.usertype === 'WAdmin') {
        TabArr = ['TopSentiment', 'TopMentions', 'TopPrice', 'TopVolume']
      } else {
        TabArr = ['TopSentiment', 'TopMentions']
      }
    }
    this.state = {
      tabs: TabArr,
      tabIcons: [
        
        
        `${APPPATH.ImageUrl}/icon-positive`,
        `${APPPATH.ImageUrl}/icon-trending`,
        `${APPPATH.ImageUrl}/icon-mentions`,
        `${APPPATH.ImageUrl}/icon_price`,
        `${APPPATH.ImageUrl}/icon_volume`,
      ],
      activeTab:
        TabArr.indexOf(props.activeTab) > -1
          ? props.activeTab
          : 'TopSentiment',
      minify: props.minify,
    };

    this.tabsWrap = [];
    this.ttHandle = '';
    this.OverlayRef = React.createRef();
    this.OverlayRef1 = React.createRef();
    this.OverlayRef2 = React.createRef();
    this.OverlayRef3 = React.createRef();
    this.OverlayRef4 = React.createRef();
  }

  componentWillReceiveProps(nextProps, prevState) {
    this.setState({
      ...this.state,
      activeTab: this.state.tabs.indexOf(nextProps.activeTab) > -1 ? nextProps.activeTab : this.state.activeTab,
      minify: nextProps.minify,
    });
  }

  toggleToolTip = (e, showState, tabIndex, TipText) => {
    let ContainerRect = this.tabsWrap[tabIndex].getBoundingClientRect();
    // console.log(ContainerRect, (ContainerRect.width * 0.25))
    let Pos = {
      left: ContainerRect.left + ContainerRect.width * 0.25,
      top: ContainerRect.bottom + 10,
      width: !this.state.minify ? 150 : 100,
    };
    if (this.ttHandle) {
      clearTimeout(this.ttHandle);
    }
    const containerStyleClass = `infoTip normal-middle ${this.props.Theme === 'light' ? 'lightmode' : ''}`;
    if (showState) {
      if (!isMobile) {
        this.ttHandle = setTimeout(() => {
          this.props.togglePopup(showState, TipText, Pos, containerStyleClass);
          window.Mixpanel.MixpanelProps.UserName = this.props.username;
          window.Mixpanel.MixpanelProps.TabName = this.props.TipText;
          window.Mixpanel.actions.identify(this.props.username);
          window.Mixpanel.actions.track(`Tooltip shown`, window.Mixpanel.MixpanelProps).then((data) => {
            window.Mixpanel.actions.people.set({
              UserName: this.props.username,
            });
            window.Mixpanel.actions.resetProp('TabName');
          });
        }, 1000);
      }
    } else {
      this.props.togglePopup(showState);
    }
  };

  getToolTipText = (Tab) => {
    let Text =
      Tab === 'TopMentions'
        ? 'Top trending crypto coins by social media posts mentioning the coin'
        : Tab === 'TopReach'
        ? 'Top trending crypto coins by social media followers receiving posts about that coin'
        : Tab === 'TopSentiment'
        ? 'Top trending crypto coins by social media net sentiment score (0/10 for most negative, 10/10 for most positive)'
        : Tab === 'TopPrice'
        ? 'Top trending crypto coins by price'
        : Tab === 'TopVolume'
        ? 'Top trending crypto coins by trading volume'
        : '';
    return Text;
  };
  getOverlayRef = (Tab) => {
    let OverlayRef =
      Tab === 'TopMentions'
        ? this.OverlayRef
        : Tab === 'TopReach'
        ? this.OverlayRef1
        : Tab === 'TopSentiment'
        ? this.OverlayRef2
        : Tab === 'TopPrice'
        ? this.OverlayRef3
        : Tab === 'TopVolume'
        ? this.OverlayRef4
        : this.OverlayRef;
    return OverlayRef;
  };

  ChangingTabs = (Tab) => {
    // console.log(Tab);
    let { cc_index } = this.props;
    // console.log(cc_Preferences, cc_index, Tab)
    // cc_Preferences.activeTab = Tab;
    // this.props.updateCoinColumnPreferences(cc_Preferences, cc_index)
    
    this.setState(
      {
        ...this.state,
        activeTab: Tab,
      },
      () => {
        window.Mixpanel.MixpanelProps.UserName = this.props.username;
        window.Mixpanel.MixpanelProps.Tab = Tab;
        window.Mixpanel.actions.identify(this.props.username);
        window.Mixpanel.actions.track(`Changed to ${Tab} tab`, window.Mixpanel.MixpanelProps).then((data) => {
          window.Mixpanel.actions.people.set({
            UserName: this.props.username,
          });
          window.Mixpanel.actions.resetProp('Tab');
        });
        this.props.changeTMTabs(Tab, cc_index, false, false, this.props.listkey);
      }
    );
  };
  entering = (e) => {
    e.children[1].style.backgroundColor = '#37aaf8';
  };

  gettabcontents = (tb, i) => {
    return (
      <div>
        <span className="padlr5">
          <img
            src={`${this.state.tabIcons[i]}${tb === this.state.activeTab ? this.props.Theme === 'light' ? '-selected-bluelightmode' : '-selected-blue' : ''}.svg`}
            alt=""
          />
        </span>
        {
          this.props.viewState === 'nonexpanded' || this.props.viewState === 'expandedmobile' ? (
            <div
              className={`${tb === this.state.activeTab ? 'bold-font text-blue-primary' : ''} ${this.props.isPro ? '' : 'nodisp'}`}
              ref={this.getOverlayRef(tb)}
              style={{ position: 'relative' }}
              onMouseEnter={(e) => {
                let ToolTipStyleData = {
                  Arrow: {
                    marginLeft: i < this.state.tabs.length - 1 ? '30%' : '50%',
                  },
                  ToolTip: {
                    align: i === this.state.tabs.length - 1 ? 'center' : 'left',
                  },
                };
                this.props.toggleOverlay(true, this.getOverlayRef(tb), this.getToolTipText(tb), ToolTipStyleData);
              }}
              onMouseLeave={(e) => {
                this.props.toggleOverlay(false);
              }}
            >
              {tb === 'TopMentions'
                ? 'Mentions'
                : tb === 'TopReach'
                ? 'Reach'
                : tb === 'TopSentiment'
                ? 'Sentiment'
                : tb === 'TopPrice'
                ? 'Price'
                : tb === 'TopVolume'
                ? 'Volume'
                : ''}
            </div>
          ) : ('')
        }
        
      </div>
    )
  }

  render() {
    // console.log(this.props.isPro)
    
    if(this.props.viewState === 'expandeddesktop') {
      return(
        
          <tr>
            <th className={`th1`}></th>
            {this.state.tabs.map((tb, i) => (
              <th 
                colSpan={2}
                key={`${tb}`} 
                ref={(el) => this.tabsWrap.push(el)} 
                style={{ padding: 5 }} 
                className={`nopad th1 tmColTitle ${tb === this.state.activeTab ? 'active' : ''}`}
                onClick={() => {
                  this.ChangingTabs(tb);
                }}
              >
                {this.gettabcontents(tb, i)}
              </th>
            ))}
          </tr>
        
      )
      
    } else {
      return (
      
        <Row className={`marg-0 nopad tmTabWrap`} style={{marginBottom: '3px'}}>
          <Col lg={12} sm={12} xs={12} md={12} xl={12} className={`nopad tmCategories ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>
            <Row className={`marg-0 padleft5 padright5`}>
              {this.state.tabs.map((tb, i) => (
                <Col
                  key={`${tb}`}
                  ref={(el) => this.tabsWrap.push(el)}
                  style={{ position: 'relative', padding: 5 }}
                  className={`nopad tmColTitle  ${tb === this.state.activeTab ? 'active' : ''} ${this.state.tabs.length < 5 && 'unsetmaxwidth'}`}
                  onClick={() => {
                    this.ChangingTabs(tb);
                  }}
                >
                  {this.gettabcontents(tb, i)}
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      );
    }
    
    // return (
      
    //   <Row className={`marg-0 nopad tmTabWrap`}>
    //     <Col lg={12} sm={12} xs={12} md={12} xl={12} className={`nopad tmCategories`}>
    //       <Row className={`marg-0 padleft5 padright5`}>
    //         {this.state.tabs.map((tb, i) => (
    //           <Col
    //             key={`${tb}`}
    //             ref={(el) => this.tabsWrap.push(el)}
    //             style={{ position: 'relative', padding: 5 }}
    //             className={`nopad tmColTitle ${tb === this.state.activeTab ? 'active' : ''}`}
    //             onClick={() => {
    //               this.ChangingTabs(tb);
    //             }}
    //           >
    //             <span className="padlr5">
    //               <img
    //                 src={`${this.state.tabIcons[i]}${tb === this.state.activeTab ? '-selected-blue' : ''}.svg`}
    //                 alt=""
    //               />
    //             </span>

    //             <div
    //               className={`${tb === this.state.activeTab ? 'bold-font' : ''}mod ${this.props.isPro ? '' : 'nodisp'}`}
    //               ref={this.getOverlayRef(tb)}
    //               style={{ position: 'relative' }}
    //               onMouseEnter={(e) => {
    //                 let ToolTipStyleData = {
    //                   Arrow: {
    //                     marginLeft: i < this.state.tabs.length - 1 ? '30%' : '50%',
    //                   },
    //                   ToolTip: {
    //                     align: i === this.state.tabs.length - 1 ? 'center' : 'left',
    //                   },
    //                 };
    //                 this.props.toggleOverlay(true, this.getOverlayRef(tb), this.getToolTipText(tb), ToolTipStyleData);
    //               }}
    //               onMouseLeave={(e) => {
    //                 this.props.toggleOverlay(false);
    //               }}
    //             >
    //               {tb === 'TopMentions'
    //                 ? 'Mentions'
    //                 : tb === 'TopReach'
    //                 ? 'Reach'
    //                 : tb === 'TopSentiment'
    //                 ? 'Sentiment'
    //                 : tb === 'TopPrice'
    //                 ? 'Price'
    //                 : tb === 'TopVolume'
    //                 ? 'Volume'
    //                 : ''}
    //             </div>
    //           </Col>
    //         ))}
    //       </Row>
    //     </Col>
    //   </Row>
    // );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    showtopreach: state.settings.showtopreach,
    Theme: state.app_preferences.preferences.user_interface.Theme,
  };
};

const mapDispatchToProps = {
  ResetTMSort,
};

export default connect(mapStateToProps, mapDispatchToProps)(TMTabs);
