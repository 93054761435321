import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import TNCModalView from './tncpp/tnc_modalView';
import SearchTitleSave from './modalforms/searchtitlesave';
import ResetPassword from './modalforms/changepassword';
import StoryOptionsMenuIndex from './storyoptionsmenu/menuIndex';
import StoryShareMenuIndex from './storyshare/storyShareIndex';
import WatchListIndex from './watchlist/watchlistIndex';
import EventTypeList from './watchlist/eventtypelist';
import IntroVideo from './watchlist/introvideo';
import WelcomeScreen from './watchlist/welcomescreen';
import UserPreferenceIndex from './watchlist/preferenceList';
import ExchangesAndRates from './trade/exchangesandrates';
import SearchHelpVideo from './youtubeLinks/SearchHelpVideo';
import GraphHelpVideo from './youtubeLinks/GraphHelpVideo';
import TopCoinHelpVideo from './youtubeLinks/TopCoinHelpVideo';
import CoinColumnHelpVideo from './youtubeLinks/CoinColumnHelpVideo';
import RelationChart from './charts/relationchart';
import EventModal from './EventModal/eventModal';
import VerifyEmail from './verifyEmail/verifyEmail';
import PasswordlessLoginPopup from './passlesslogin/passwordless_login';
import AdModal from './admodal/popupad';
import UnderMaintenance from './undermaintenance';
import InvalidSearch from './invalidsearch';
import ErrorMsg from './errormsg';
import SpinnerLoader from '../loaders/apploaderspinner/appLoaderSpinner';
import { isMobile } from 'react-device-detect';

class Modals extends Component {
  constructor() {
    super();
    this.state = {
      showState: false,
      view: '',
    };
  }
  componentWillMount() {
    this.setState({
      ...this.state,
      view: this.props.view,
    });
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.view !== '') {
      this.setState({
        ...this.state,
        view: nextProps.view,
      });
    }

  }

  closeWelcomeModal = (updateMixPanel = false, step) => {
    this.props.onHide();
    //remove url params from url
    // window.history.replaceState({}, document.title, '/');
    window.Mixpanel.MixpanelProps.UserName = this.props.userdata.username;
    window.Mixpanel.actions.identify(this.props.userdata.username);
    if (updateMixPanel) {
      switch (step) {
        case 1:
          window.Mixpanel.actions
            .track('Skipping the welcome steps at intro video', window.Mixpanel.MixpanelProps)
            .then((data) => {
              window.Mixpanel.actions.people.set({
                UserName: this.props.userdata.username,
              });
              this.finalSkipMixpanelEventLog();
            });
          break;
        case 2:
          window.Mixpanel.actions
            .track('Skipping the welcome steps at welcome page', window.Mixpanel.MixpanelProps)
            .then((data) => {
              window.Mixpanel.actions.people.set({
                UserName: this.props.userdata.username,
              });
              this.finalSkipMixpanelEventLog();
            });
          break;
        case 3:
          window.Mixpanel.actions
            .track('Skipping the welcome steps at watchlist', window.Mixpanel.MixpanelProps)
            .then((data) => {
              window.Mixpanel.actions.people.set({
                UserName: this.props.userdata.username,
              });
              this.finalSkipMixpanelEventLog();
            });
          break;
        case 4:
          window.Mixpanel.actions
            .track('Skipping the welcome steps at event type list', window.Mixpanel.MixpanelProps)
            .then((data) => {
              window.Mixpanel.actions.people.set({
                UserName: this.props.userdata.username,
              });
              this.finalSkipMixpanelEventLog();
            });
          break;
        default:
          window.Mixpanel.actions
            .track('Skipping the welcome steps at User preference', window.Mixpanel.MixpanelProps)
            .then((data) => {
              window.Mixpanel.actions.people.set({
                UserName: this.props.userdata.username,
              });
              this.finalSkipMixpanelEventLog();
            });
          break;
      }
    } else {
      switch (step) {
        case 1:
          window.Mixpanel.actions.track('Closing intro video', window.Mixpanel.MixpanelProps).then((data) => {
            window.Mixpanel.actions.people.set({
              UserName: this.props.userdata.username,
            });
          });
          break;
        case 2:
          window.Mixpanel.actions.track('Closing welcome page', window.Mixpanel.MixpanelProps).then((data) => {
            window.Mixpanel.actions.people.set({
              UserName: this.props.userdata.username,
            });
          });
          break;
        case 3:
          window.Mixpanel.actions.track('Closing watchlist', window.Mixpanel.MixpanelProps).then((data) => {
            window.Mixpanel.actions.people.set({
              UserName: this.props.userdata.username,
            });
          });
          break;
        case 4:
          window.Mixpanel.actions.track('Closing event type list', window.Mixpanel.MixpanelProps).then((data) => {
            window.Mixpanel.actions.people.set({
              UserName: this.props.userdata.username,
            });
          });
          break;
        default:
          window.Mixpanel.actions.track('Closing User preference', window.Mixpanel.MixpanelProps).then((data) => {
            window.Mixpanel.actions.people.set({
              UserName: this.props.userdata.username,
            });
          });
          break;
      }
    }
  };

  modalColse = () => {
    console.log('herevvv')
    window.history.replaceState({}, document.title, '/');
    this.props.onHide();
  };

  finalSkipMixpanelEventLog() {
    window.Mixpanel.MixpanelProps.UserName = this.props.userdata.username;
    window.Mixpanel.actions.identify(this.props.userdata.username);
    window.Mixpanel.actions.track('End of welcome process', window.Mixpanel.MixpanelProps).then((data) => {
      window.Mixpanel.actions.people.set({
        UserName: this.props.userdata.username,
      });
    });
  }
  changeView = (key) => {
    this.props.changeModalContent(key);
  };

  render() {
    const { view } = this.state;
    // console.log(view, this.props.show)
    let content;
    let backdrop = 'static';
    let mobilevideo = false;

    switch (view) {
      case 'ErrorMsg':
        content = <ErrorMsg {...this.props} modalClose={this.modalColse} />;
        break;
      case 'Loader':
        content = <SpinnerLoader {...this.props} modalClose={this.modalColse} />;
        break;
      case 'InvalidSearch':
        content = <InvalidSearch {...this.props} modalClose={this.modalColse} />;
        break;
      case 'UnderMaintenance':
        content = <UnderMaintenance {...this.props} modalClose={this.modalColse} />;
        break;
      case 'PASSRESET':
        content = <ResetPassword {...this.props} modalClose={this.modalColse} />;
        break;
      case 'passwordlesslogin':
        content = <PasswordlessLoginPopup {...this.props} modalClose={this.modalColse} changeView={this.changeView} />;
        break;
      case 'TNC':
        content = <TNCModalView {...this.props} modalClose={this.modalColse} />;
        break;
      case 'SearchTitleSave':
        content = <SearchTitleSave {...this.props} modalClose={this.modalColse} changeView={this.changeView} />;
        break;
      case 'StoryOptionsMenu':
        content = <StoryOptionsMenuIndex {...this.props} modalClose={this.modalColse} />;
        break;
      case 'StoryShareMenu':
        content = <StoryShareMenuIndex {...this.props} modalClose={this.modalColse} />;
        break;
      case 'WatchList':
        content = <WatchListIndex {...this.props} modalClose={this.closeWelcomeModal} changeView={this.changeView} />;
        break;
      case 'search':
        content = <SearchHelpVideo {...this.props} modalClose={this.modalColse} changeView={this.changeView} />;
        mobilevideo = isMobile;
        break;
      case 'graph-help':
        content = <GraphHelpVideo {...this.props} modalClose={this.modalColse} changeView={this.changeView} />;
        mobilevideo = isMobile;
        break;
      case 'top-daily-coins':
        content = <TopCoinHelpVideo {...this.props} modalClose={this.modalColse} changeView={this.changeView} />;
        mobilevideo = isMobile;
        break;
      case 'coin-column':
        content = <CoinColumnHelpVideo {...this.props} modalClose={this.modalColse} changeView={this.changeView} />;
        mobilevideo = isMobile;
        break;
      case 'Event':
        content = <EventModal {...this.props} modalClose={this.modalColse} />;
        break;
      case 'EventTypeList':
        content = <EventTypeList {...this.props} modalClose={this.closeWelcomeModal} changeView={this.changeView} />;
        break;
      case 'UserPreferenceList':
        content = (
          <UserPreferenceIndex {...this.props} modalClose={this.closeWelcomeModal} changeView={this.changeView} />
        );
        break;
      case 'IntroVideo':
        window.Mixpanel.MixpanelProps.UserName = this.props.userdata.username;
        window.Mixpanel.actions.identify(this.props.userdata.username);
        window.Mixpanel.actions
          .track('Welcome screen intiated with Intro video', window.Mixpanel.MixpanelProps)
          .then((data) => {
            window.Mixpanel.actions.people.set({
              UserName: this.props.userdata.username,
            });
          });
        content = (
          <IntroVideo
            {...this.props}
            modalClose={this.closeWelcomeModal}
            WelcomeScreen={this.props.WelcomeScreen}
            changeView={this.changeView}
          />

        );
        mobilevideo = isMobile;
        break;
      case 'WelcomeScreen':
        content = <WelcomeScreen {...this.props} modalClose={this.closeWelcomeModal} changeView={this.changeView} />;
        break;
      case 'TradeExchanges':
        content = <ExchangesAndRates {...this.props} modalClose={this.modalColse} changeView={this.changeView} />;
        break;
      case 'RelationChart':
        content = <RelationChart {...this.props} modalClose={this.modalColse} changeView={this.changeView} />;
        break;
      case 'VerifyEmail':
        content = <VerifyEmail {...this.props} modalClose={this.modalColse} changeView={this.changeView} />;
        break;
      case 'PopupAd':
        content = <AdModal {...this.props} modalClose={this.modalColse} changeView={this.changeView} />;
        backdrop = true;
        break;
      default:
        content = '';
        break;
    }
    return (
      <Modal
        backdrop={backdrop}
        keyboard={false}
        show={this.props.show}
        onHide={this.props.onHide}
        // fullscreen={view === 'PopupAd' ? true : false}
        dialogClassName={`${view === 'PopupAd' ? 'modal-100w nopad' : mobilevideo ? 'modal-mobile' : ''}`}
        backdropClassName={`${view === 'Loader' ? 'transparent-backdrop' : ''}`}
        size={
          view === 'StoryShareMenu' ||
            view === 'WatchList' ||
            view === 'EventTypeList' ||
            view === 'IntroVideo' ||
            view === 'WelcomeScreen' ||
            view === 'UserPreferenceList' ||
            view === 'top-daily-coins' ||
            view === 'coin-column' ||
            view === 'graph-help' ||
            view === 'search' ||
            view === 'RelationChart' ||
            view === 'UnderMaintenance'
            ? 'lg'
            : view === 'VerifyEmail'
              ? 'sm'
              : view === 'PopupAd'
                ? ''
                : 'md'
        }
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {content}
      </Modal>
    );
  }
}

export default Modals;
