import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import './Iforexloader.css';
import { APPPATH } from '../../../../modules/helper.module';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';

class APPLoader extends Component {
    constructor(props) {
      super();
      this.state = {
        viewSize: 'sm',
        showModal: props.apploading,
        __this: this
      }
    
    }

    componentDidMount() {
      
      this.close = setTimeout(() => {
      //     window.location.href = APPPATH.AppUrl;
      // console.log('calling close')
      this.modalclose();
      },8000)
      
      
    }

    modalclose = () => {
      this.setState({
        ...this.state,
        showModal: false
      })
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      if((prevState.__this.props.apploading !== nextProps.apploading) && nextProps.apploading) {
        return {
          showModal: true
        }
      }
      if((prevState.__this.props.apploading !== nextProps.apploading) && !nextProps.apploading) {
        return {
          showModal: false
        }
      }
      return null;

    }

    
    

    render() {
      // console.log(this.props.Theme)
      let Theme = this.props.forcetheme || this.props.Theme
        return (
          <Modal
            backdrop={'static'}
            keyboard={false}
            show={this.state.showModal}
            onHide={this.modalclose}
            // fullscreen={view === 'PopupAd' ? true : false}
            
            backdropClassName={`loader-backdrop ${Theme}`}
            size={'sm'}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <div className={`full-width-height ${Theme}`} style={{position: 'relative', backgroundColor: Theme === 'dark' ? '#222' : '#fff', left: '0px'}}>
              <div className={`loader full-width-height ${this.state.viewSize}`}></div>
            </div>
          </Modal>
          
        );
    }

}




const mapStateToProps = (state, ownProps) => {
    return {
      // Theme: state.app_preferences.preferences.user_interface.Theme,
      apploading: state.settings.apploading,
      Theme: state.app_preferences.preferences.user_interface.Theme,
      forcetheme: state.settings.forcetheme
    };
};
  
const mapDispatchToProps = {
    
};

export default connect(mapStateToProps, mapDispatchToProps)(APPLoader)