import mixpanel from 'mixpanel-browser';
import * as moment from 'moment';

let env_check = process.env.NODE_ENV;

const userVisitData = {
  VisitTime: moment().format(),
  LoginTime: '',
  ExpiryTime: '',
  LogoutTime: '',
  LoggedInStatus: false,
  UserName: '',
  EventId: '',
  actionTime: '',
  search: '',
  InterestedNotifications: '',
  Publisher: ''
}

const actions = {
  init: (token) => {
    mixpanel.init(token);
  },
  identify: (id) => {
    if (env_check) mixpanel.identify(id);
  },
  alias: (id) => {
    if (env_check) mixpanel.alias(id);
  },
  track: async (name, props) => {
    try {
      if (env_check) {
        props.actionTime = moment().format();
        return await mixpanel.track(name, props);
      }
    }
    catch (err) {
      console.log(err)
    }
    // if (env_check) { /*console.log(props);*/ props.actionTime = moment().format();  return await mixpanel.track(name, props); }
  },
  resetProp: (prop = null) => {
    if (env_check) {
      if (prop) {
        userVisitData[`${prop}`] = '';
      } else {
        userVisitData['VisitTime'] = moment().format()
        userVisitData['LoginTime'] = ''
        userVisitData['ExpiryTime'] = ''
        userVisitData['LogoutTime'] = ''
        userVisitData['LoggedInStatus'] = false
        userVisitData['UserName'] = ''
        userVisitData['EventId'] = ''
        userVisitData['actionTime'] = ''
        userVisitData['search'] = ''
        userVisitData['InterestedNotifications'] = ''
        userVisitData['Publisher'] = ''

      }
    }
  },
  people: {
    set: (props) => {
      if (env_check) mixpanel.people.set(props);
    }
  },
};

const MixPanel = {
  actions: actions,
  MixpanelProps: userVisitData
}
export default MixPanel;

// <!-- start Mixpanel --><script type="text/javascript">(function(c,a){if(!a.__SV){var b=window;try{var d,m,j,k=b.location,f=k.hash;d=function(a,b){return(m=a.match(RegExp(b+"=([^&]*)")))?m[1]:null};f&&d(f,"state")&&(j=JSON.parse(decodeURIComponent(d(f,"state"))),"mpeditor"===j.action&&(b.sessionStorage.setItem("_mpcehash",f),history.replaceState(j.desiredHash||"",c.title,k.pathname+k.search)))}catch(n){}var l,h;window.mixpanel=a;a._i=[];a.init=function(b,d,g){function c(b,i){var a=i.split(".");2==a.length&&(b=b[a[0]],i=a[1]);b[i]=function(){b.push([i].concat(Array.prototype.slice.call(arguments,
//   0)))}}var e=a;"undefined"!==typeof g?e=a[g]=[]:g="mixpanel";e.people=e.people||[];e.toString=function(b){var a="mixpanel";"mixpanel"!==g&&(a+="."+g);b||(a+=" (stub)");return a};e.people.toString=function(){return e.toString(1)+".people (stub)"};l="disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove".split(" ");
//   for(h=0;h<l.length;h++)c(e,l[h]);var f="set set_once union unset remove delete".split(" ");e.get_group=function(){function a(c){b[c]=function(){call2_args=arguments;call2=[c].concat(Array.prototype.slice.call(call2_args,0));e.push([d,call2])}}for(var b={},d=["get_group"].concat(Array.prototype.slice.call(arguments,0)),c=0;c<f.length;c++)a(f[c]);return b};a._i.push([b,d,g])};a.__SV=1.2;b=c.createElement("script");b.type="text/javascript";b.async=!0;b.src="undefined"!==typeof MIXPANEL_CUSTOM_LIB_URL?
//   MIXPANEL_CUSTOM_LIB_URL:"file:"===c.location.protocol&&"//cdn4.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\/\//)?"https://cdn4.mxpnl.com/libs/mixpanel-2-latest.min.js":"//cdn4.mxpnl.com/libs/mixpanel-2-latest.min.js";d=c.getElementsByTagName("script")[0];d.parentNode.insertBefore(b,d)}})(document,window.mixpanel||[]);
//   mixpanel.init("532379def90f8f00fdc06f39f6545a0d");</script><!-- end Mixpanel -->