import React, { Component } from 'react'; 
import { Row, Col } from 'react-bootstrap';
import SettingsListItem from '../components/settings/settings-list-item';
import { APPPATH } from '../modules/helper.module';

class Settings extends Component {

  constructor() {
    super()
    this.state= {
      viewState: {
        Assets: true,
        Events: true,
        Locations: false,
        Topics: false,
        Correlations: false
      }
    }
  }

  componentWillMount() {
    window.Mixpanel.actions.identify(this.props.userData.username);
    window.Mixpanel.actions.track('Visiting Settings', window.Mixpanel.MixpanelProps).then(data => {
      window.Mixpanel.actions.people.set({
        UserName: this.props.userData.username
      });
    })
  }

  showSettings = (setting, active) => {
    if(active) {
      
      let newViewState = {}
      Object.keys(this.state.viewState).map((key) => {
        // newViewState[key] = key === setting ?  true : false
        newViewState[key] =  (key !== 'Correlations' && key !== 'Locations' && key !== 'Topics') ? true : false
        
        return null
      })  
      this.setState({
        ...this.state,
        viewState: newViewState
      })
    } else {

      this.setState({
        viewState: {
          Assets: true,
          Events: true,
          Locations: false,
          Topics: false,
          Correlations: false
        }
      })
    }
  }
  render() {
    

    return (
      <Row className=" marg-0 margt2 settings-list-wrap ">
        <Col lg={12} sm={12} xs={12} md={12} xl={12}>
          <Row className="justify-content-center  ">
            <Col lg={11} sm={11} xs={11} md={11} xl={11} className="nopad filtershead justify-content-left text-left">
              {/* <i className="fa fa-linode"></i><span>Filters</span> */}
              <img src={`${APPPATH.ImageUrl}/Filter-Standard-Gray.png`} className="" alt="CrowdSense.ai" /><span>Filters</span>
            </Col>
          </Row>
          <Row className="justify-content-center  ">
            <Col lg={11} sm={11} xs={11} md={11} xl={11} className="nopad settings-list justify-content-left text-left">
              {
                Object.keys(this.state.viewState).map((key) => {
                  return <SettingsListItem 
                    text={key} 
                    settingToggle={this.showSettings} 
                    viewState={this.state.viewState[`${key}`]} 
                    key={key} 
                    {...this.props}
                  />
                })
              }
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}


export default Settings