import React, {useState, useEffect} from 'react';
// import { Link } from 'react-router-dom';
import { APPPATH } from '../../../modules/helper.module';
import { useDispatch, useSelector } from 'react-redux';

const LogoIframe = () => {

//   let render = useSelector(({settings}) => settings.render);
//   let Theme = useSelector(({app_preferences}) => app_preferences.preferences.user_interface.Theme);

 

//   console.log(render)
  return (
    <div className={` `}>
      <div className="logo-link pointer" >
        <span>
            <img src={`${APPPATH.ImageUrl}/logo_algense.png`} className={`logo-iframe`} alt="CrowdSense.ai" />
        </span>
      </div>
    </div>
  );
};

export default LogoIframe;
