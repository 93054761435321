import React, { Component } from 'react'; 
import { Row, Col } from 'react-bootstrap';
import StoryHeadLineWrap from './story_headlinewrap';


class StoryHeadBar extends Component {
  
  constructor() {
    super();
    this.state = {
      story: {},
      parentColWidth: ''
    }
  }
  componentWillMount() {
    
    this.setState({
      ...this.state,
      story: this.props.story,
      parentColWidth : this.props.TDeckColWidth
    })
  }
  
  componentWillReceiveProps(nextProps) {
    
    this.setState({
      ...this.state,
      story: nextProps.story,
      parentColWidth : nextProps.TDeckColWidth
    })
  }
  
  

  render() {
    const { parentColWidth } = this.state;
    
    return(
      <Row className="story-headlinebar marg-0" ref={`headBarWrap`}>
        <Col xs={12} sm={12} md={12} xl={12} lg={12} className={ `align-self-center nopad togglebarColWidCorrection ${this.props.tabmode ? 'istabbed' : ''} `}>
          <StoryHeadLineWrap {...this.props} story={this.props.story} TDeckColWidth={parentColWidth} userData={this.props.userData} handleCollapse={this.props.handleCollapse} EventReadStatus={this.props.EventReadStatus}   />
        </Col>
         
       
      </Row>
    )
    
    
  }
} 
export default StoryHeadBar