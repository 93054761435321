import React, { Component } from 'react'; 
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
// import Tooltip from '../shared/tooltip/generaltooltip';
import * as appSettingsActionCreators from '../../actions/settings.action';
import * as filtersActionCreator from '../../actions/filters.action';
import { CorrelationsExpert } from '../../modules/helper.module';


class MuteUnmute extends Component {
  constructor() {
    super();
    this.state= {
      isAnyMuted: false,
      muteunmuteReasonTip: false,
      muteunmuteToolTip: false,
      EventMute: false,
      EventMuteData: '',
      // locationMute: false,
      // locationMuteData: {},
      // mentionTypeMute: false,
      // mentionTypeMuteData: {},
      securitiesMute: false,
      securitiesMuteData : {},
      // locationmutedataChangecount: 0,
      isClickedInstance: false
    }
    this.ttHandle = '';
    this.corelHandle = '';
  }

  componentWillMount() {
    let headline = this.props.HeadlineText;
    let eventFilter = this.props.eventFilter;
    // let locationFilter = this.props.locationFilter;
    // let mentiontypesFilter = this.props.mentiontypesFilter.length ? this.props.mentiontypesFilter.map(item => (item.toLowerCase())) : ([]);
    // let storyLocation = this.props.Location;
    let storyEventType = this.props.EventType;
    // let storyMentionType = this.props.MentionTypes;
    // let MentionTypes = this.props.mentionTypes;
    let Securities = this.props.securities;
    let SecuritiesFilter = this.props.securitiesFilter.length ? this.props.securitiesFilter.map(item => (item.toLowerCase())) : ([])
    
    let corelOptions = {
      CorrelationResults : this.props.CorrelationResults
    }
    this.corelHandle = new CorrelationsExpert(corelOptions)
    let resp = this.corelHandle.setCorrelations()
    let CorrelationResults = resp.corelList ? resp.corelList : [];
    // this.MuteUnmuteHandler(storyEventType, storyLocation, storyMentionType, headline, eventFilter, locationFilter, mentiontypesFilter, MentionTypes, Securities, SecuritiesFilter, CorrelationResults)
    this.MuteUnmuteHandler(storyEventType, headline, eventFilter, Securities, SecuritiesFilter, CorrelationResults)
    

  }

  componentWillReceiveProps(nextProps) {
    let headline = nextProps.HeadlineText;
    let eventFilter = nextProps.eventFilter;
    let locationFilter = nextProps.locationFilter;
    let mentiontypesFilter = nextProps.mentiontypesFilter.length ? nextProps.mentiontypesFilter.map(item => (item.toLowerCase())) : ([]);
    let storyLocation = nextProps.Location;
    let storyEventType = nextProps.EventType;
    let storyMentionType = nextProps.MentionTypes;
    let MentionTypes = nextProps.mentionTypes;
    let Securities = nextProps.securities;
    let SecuritiesFilter = nextProps.securitiesFilter.length ? nextProps.securitiesFilter.map(item => (item.toLowerCase())) : ([]);
    let corelOptions = {
      CorrelationResults : nextProps.CorrelationResults
    }
    this.corelHandle = new CorrelationsExpert(corelOptions)
    let resp = this.corelHandle.setCorrelations()
    let CorrelationResults = resp.corelList
    this.MuteUnmuteHandler(storyEventType, storyLocation, storyMentionType, headline, eventFilter, locationFilter, mentiontypesFilter, MentionTypes, Securities, SecuritiesFilter, CorrelationResults, true, nextProps)
    
  }



  MuteUnmuteHandler = (storyEventType, headline, eventFilter, Securities, SecuritiesFilter, CorrelationResults, update = false, nextProps = null) => {
    let EventMute = false;
    // let locationMute = false;
    // let mentionTypeMute = false;
    let securitiesMute = false;
    let EventMuteData = '';
    // let locationMuteData = {};
    // let mentionTypeMuteData = {};
    let securitiesMuteData = {};
    let EventTypeRef = storyEventType;
    // let LocationRef = '';
    // let mentionTypeRef = storyMentionType ? (storyMentionType.split(',').map(mntype => (mntype.trim()))) : ([])
    
    let securitiesRef = CorrelationResults.length ? (
      CorrelationResults.filter((crl) => (crl.Security !== '')).map(secItm => (secItm.Security)).filter(itm => (itm))
    ) : ([])


    // if(MentionTypes.length) {
    //   MentionTypes.forEach(MNTYP => {
    //     let TmpMntTypeRef = storyMentionType ? (storyMentionType.split(',').map(mntype => (mntype.trim().toLowerCase()))) : ([])
    //     if(TmpMntTypeRef.indexOf(MNTYP.Name.toLowerCase()) > -1) {
    //       // console.log(TmpMntTypeRef.indexOf(MNTYP.Name.toLowerCase()), MNTYP.Name.toLowerCase())
    //       if(MNTYP.children.length) {
            
    //         mentionTypeRef.splice(TmpMntTypeRef.indexOf(MNTYP.Name.toLowerCase()), 1)
    //       }
    //     }
    //   })
    // }
    
    // LocationRef = storyLocation.split(';');
    let evntfiltersLowerCase = eventFilter.map((evnt) => { return evnt.toLowerCase(); });
    if(evntfiltersLowerCase.indexOf(EventTypeRef.toLowerCase()) === -1) {
      EventMute = true;
    }
    EventMuteData = EventTypeRef;
    
    
    // if(LocationRef.length) {
    //   LocationRef.forEach(locStr => {
    //     let tmpstat = false; let validBit;
    //     if(locStr.split(',').length > 1) {
    //       locStr.split(',').map((bit,i) => {
    //         if(i === (locStr.split(',').length -1)) {
    //         if(locationFilter.indexOf(bit.trim()) > -1 ) {
    //           tmpstat = true;
    //           validBit = bit.trim();
    //         } else {
    //           tmpstat = false;
    //           validBit = bit.trim();
    //         }
    //         locationMuteData[`${validBit}`] = tmpstat;
    //         }
    //       })
    //     } else {
    //       if(locationFilter.indexOf(locStr.trim()) > -1 ) {
    //         tmpstat = true;
    //         validBit = locStr.trim();
    //       } else {
    //         tmpstat = false;
    //         validBit = locStr.trim();
    //       }
    //       locationMuteData[`${validBit}`] = tmpstat;
    //     }
        
    //   })
    //   let locKeys = Object.keys(locationMuteData)
    //   locKeys.forEach(key => {
    //     if(!locationMuteData[key]) {
    //       locationMute = true;
    //     }
    //   })
      
    // }
    // if(mentionTypeRef.length) {
    //   mentionTypeRef.forEach(mntyp => {
    //     let mtmpstat = false; let mvalidBit;
    //     if(mentiontypesFilter.indexOf(mntyp.toLowerCase()) > -1) {
    //       mtmpstat = true;
    //     } 
    //     mvalidBit = mntyp;
    //     mentionTypeMuteData[`${mvalidBit}`] = mtmpstat;
    //   })
    //   let mntpKeys = Object.keys(mentionTypeMuteData);
    //   mntpKeys.forEach(key => {
    //     if(!mentionTypeMuteData[key]) {
    //       mentionTypeMute = true;
    //     }
    //   })
    // }
    // console.log(securitiesRef)
    if(securitiesRef.length) {
      securitiesRef.forEach(sec => {
        let secTmpstat = false; let secValidBit;
        if(SecuritiesFilter.indexOf(sec.toLowerCase()) > -1) {
          secTmpstat = true;
        } 
        secValidBit = sec;
        securitiesMuteData[`${secValidBit}`] = secTmpstat;
      })
      let secKeys = Object.keys(securitiesMuteData);
      secKeys.forEach(key => {
        if(!securitiesMuteData[key]) {
          securitiesMute = true;
        }
      })
    }
    if(nextProps) {
      let restip = false;
      if(this.props.muteToolTipHandler !== nextProps.muteToolTipHandler) {
        if(!nextProps.muteToolTipHandler || (nextProps.muteToolTipHandler !== nextProps.FeedId)) {
          restip = false
          this.setState({
            ...this.state,
            muteunmuteReasonTip : restip,
            isAnyMuted: EventMute || securitiesMute ? true : false,
            // isAnyMuted: EventMute || locationMute || mentionTypeMute || securitiesMute ? true : false,
            EventMute: EventMute,
            EventMuteData: EventMuteData,
            // locationMute: locationMute,
            // locationMuteData: locationMuteData,
            // mentionTypeMute: mentionTypeMute,
            // mentionTypeMuteData: mentionTypeMuteData,
            securitiesMute: securitiesMute,
            securitiesMuteData : securitiesMuteData
          })
        } else {
          window.Mixpanel.MixpanelProps.UserName = nextProps.userData.username;
          window.Mixpanel.MixpanelProps.EventId = nextProps.FeedId;
          window.Mixpanel.MixpanelProps.Headline = nextProps.HeadlineText;
          window.Mixpanel.actions.identify(nextProps.userData.username);
          window.Mixpanel.actions.track(`Clicked mute future alerts`, window.Mixpanel.MixpanelProps).then(data => {
            window.Mixpanel.actions.people.set({
              UserName: nextProps.userData.username
            });
            window.Mixpanel.actions.resetProp('EventId');
            window.Mixpanel.actions.resetProp('Headline');
          })
        }
      }
    } else {
      this.setState({
        ...this.state,
        isAnyMuted: EventMute || securitiesMute ? true : false,
        // isAnyMuted: EventMute || locationMute || mentionTypeMute || securitiesMute ? true : false,
        EventMute: EventMute,
        EventMuteData: EventMuteData,
        // locationMute: locationMute,
        // locationMuteData: locationMuteData,
        // mentionTypeMute: mentionTypeMute,
        // mentionTypeMuteData: mentionTypeMuteData,
        securitiesMute: securitiesMute,
        securitiesMuteData : securitiesMuteData
      })
    }

    
  }
  MuteUnmuteClicked = () => {
    let muteunmuteTip = !this.state.muteunmuteReasonTip;
    if(this.ttHandle) {
      clearTimeout(this.ttHandle)
    }
    this.setState({
      ...this.state,
      muteunmuteReasonTip : muteunmuteTip,
      isClickedInstance: true,  
      muteunmuteToolTip : false
    },() => {
      if(muteunmuteTip) {
        this.props.tipHandle(muteunmuteTip, this.props.FeedId, 'muteToolTipHandler')
      } else {
        window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
        window.Mixpanel.MixpanelProps.EventId = this.props.FeedId;
        window.Mixpanel.MixpanelProps.Headline = this.props.HeadlineText;
        window.Mixpanel.actions.identify(this.props.userData.username);
        window.Mixpanel.actions.track(`Unclicked mute future alerts`, window.Mixpanel.MixpanelProps).then(data => {
          window.Mixpanel.actions.people.set({
            UserName: this.props.userData.username
          });
          window.Mixpanel.actions.resetProp('EventId');
          window.Mixpanel.actions.resetProp('Headline');
        })
      }
      this.props.modalClose();
    })
  }
  toggleReasonMuteUnmute = (event) => {
    event.preventDefault()
    if(!this.props.EventReadStatusMain) {
      this.props.storyClicked().then(() => {
        this.MuteUnmuteClicked()
      })
    } else {
      this.MuteUnmuteClicked()
    }
    
    
  }

  toggleMuteUnmute = (muteType, muteKey = null) => {
    let mutetypestate;
    // let locationmutedataChangecount = this.state.locationmutedataChangecount;
    if(muteType === 'EventMute') { mutetypestate = !this.state[`${muteType}`] }
    // else if(muteType === 'locationMute') {
    //   let haschecked = this.state.locationMuteData[`${muteKey}`] ? false : true;
    //   if(!haschecked === true) {
    //     mutetypestate = true
    //   } else {
    //     mutetypestate = false;
    //   }
    // } else if(muteType === 'mentionTypeMute') {
    //   let haschecked = this.state.mentionTypeMuteData[`${muteKey}`] ? false : true;
    //   if(!haschecked === true) {
    //     mutetypestate = true
    //   } else {
    //     mutetypestate = false;
    //   }
    // } 
    else if(muteType === 'securitiesMute') {
      let haschecked = this.state.securitiesMuteData[`${muteKey}`] ? false : true;
      if(!haschecked === true) {
        mutetypestate = true
      } else {
        mutetypestate = false;
      }
    }
    this.setState({
      ...this.state,
      [`${muteType}`]: mutetypestate,
      // locationmutedataChangecount: locationmutedataChangecount
    },() => {
      if(muteKey) {
        // if(muteType === 'locationMute') {
        //   this.setState({
        //     ...this.state,
        //     locationMuteData : {
        //       ...this.state.locationMuteData,
        //       [`${muteKey}`]: !this.state.locationMuteData[`${muteKey}`]
        //     } 
        //   })
        // } else if(muteType === 'mentionTypeMute') {
        //   this.setState({
        //     ...this.state,
        //     mentionTypeMuteData : {
        //       ...this.state.mentionTypeMuteData,
        //       [`${muteKey}`]: !this.state.mentionTypeMuteData[`${muteKey}`]
        //     } 
        //   })
        // } else 
        if(muteType === 'securitiesMute') {
          this.setState({
            ...this.state,
            securitiesMuteData : {
              ...this.state.securitiesMuteData,
              [`${muteKey}`]: !this.state.securitiesMuteData[`${muteKey}`]
            } 
          })
        }
        
      }
    })
  }

  MentionTypesParentsRecursive = (mntpKey, MentiontypeMaster, MentionTypeFilters) => {
    const { mentionTypeMute, mentionTypeMuteData } = this.state;

    let mntpId = MentiontypeMaster.filter(itm => ( itm.Name === mntpKey ))[0].MentionTypeID
    let mntpParent = MentiontypeMaster.filter(itm => (mntpId && itm.children.indexOf(mntpId) > -1))[0]
    let mntpSiblings = MentiontypeMaster.filter(itm => (itm.MentionTypeID !== mntpId && mntpParent.children.indexOf(itm.MentionTypeID) > -1) && MentionTypeFilters.indexOf(itm.Name) > -1)
    if(!mntpSiblings.length) {
      if(mentionTypeMute) {
        if(!mentionTypeMuteData[mntpKey]) {
          if(MentionTypeFilters.indexOf(mntpParent.Name) > -1) {
            MentionTypeFilters.splice(MentionTypeFilters.indexOf(mntpParent.Name), 1)
          }
        }
      } else {
        if(mentionTypeMuteData[mntpKey]) {
          if(MentionTypeFilters.indexOf(mntpParent.Name) === -1) {
            MentionTypeFilters.push(mntpParent.Name)
          }
        }
      }
    }
    if(mntpParent.MentionTypeParent !== 0) {
      this.MentionTypesParentsRecursive(mntpParent.Name, MentiontypeMaster, MentionTypeFilters)
    }
    return MentionTypeFilters
  }

  SecuritiesParentsRecursive = (secParent, secKey, SecurityTypeMaster, SecurityTypeFilter, SecuritiesMaster, SecuritiesFilter, Security_Type_rel, prevParent = '') => {
    const { securitiesMute, securitiesMuteData } = this.state;
    let siblingSecurities = Security_Type_rel.filter(itm => (itm.SecurityType === secParent)).map(itm => {
      if(SecuritiesFilter.indexOf(itm.Security) > -1 && itm.Security !== secKey) { return itm.Security } 
      return null;
    }).filter(itm => (itm))

    let sectypParentChildren = SecurityTypeMaster.filter(itm => (itm.Name === secParent))[0].children
    let siblingSecurityTypes = SecurityTypeMaster.filter(itm => (sectypParentChildren.indexOf(itm.SecurityTypeID) > -1)).map(itm => {
      if(SecurityTypeFilter.indexOf(itm.Name) > -1 && itm.Name !== prevParent) { return itm.Name }
      return null;
    }).filter(itm => (itm))
    if(!siblingSecurities.length && !siblingSecurityTypes.length) {
      if(securitiesMute) {
        if(!securitiesMuteData[secKey]) {
          if(SecurityTypeFilter.indexOf(secParent) > -1) {
            SecurityTypeFilter.splice(SecurityTypeFilter.indexOf(secParent), 1);
          }
        }
      } else {
        if(securitiesMuteData[secKey]) {
          if(SecurityTypeFilter.indexOf(secParent) === -1) {
            SecurityTypeFilter.push(secParent);
          }
        }
      }
    }
    if(SecurityTypeMaster.filter(itm => (itm.Name === secParent))[0].SecurityTypeParent !== 0) {
      let newSecTypParentId = SecurityTypeMaster.filter(itm => (itm.Name === secParent))[0].SecurityTypeParent;
      let newSecTypParent = SecurityTypeMaster.filter(itm => (itm.SecurityTypeID === newSecTypParentId))[0].Name;
      this.SecuritiesParentsRecursive(newSecTypParent, secKey, SecurityTypeMaster, SecurityTypeFilter, SecuritiesMaster, SecuritiesFilter, Security_Type_rel, secParent)
    }
    return SecurityTypeFilter;
  }

  confirmMuteUnmuteAction = () => {
    const { EventMute, EventMuteData, securitiesMute, securitiesMuteData } = this.state;
    
    let eventType = EventMute ? EventMuteData : EventMute;
    let LocType = [];
    let MentionType = [];
    let Securities = [];
    // if(locationMute) {
    //   let Locs = Object.keys(locationMuteData);
    //   Locs.forEach(loc => {
    //     if(!locationMuteData[loc]) {
    //       LocType.push(loc)
    //     }
    //   })
    // }
    // if(mentionTypeMute) {
    //   let Mntps = Object.keys(mentionTypeMuteData);
    //   Mntps.forEach(mntp => {
    //     if(!mentionTypeMuteData[mntp]) {
    //       MentionType.push(mntp)
    //     }
    //   })
    // }
    if(securitiesMute) {
      let Secs = Object.keys(securitiesMuteData);
      Secs.forEach(sec => {
        if(!securitiesMuteData[sec]) {
          Securities.push(sec)
        }
      })
    }
    
    let eventFilter = this.props.eventFilter;
    
    // let locationFilter = this.props.locationFilter;
   
    // let continentFilteredLocations = this.props.continentFilteredLocations;
    
    // let MentionTypeFilters = this.props.mentiontypesFilter
    // let MentiontypeMaster = this.props.mentionTypes

    let SecuritiesFilter = this.props.securitiesFilter
    let SecuritiesMaster = this.props.securities
    let SecurityTypeMaster = this.props.securitiesRawCollection
    let SecurityTypeFilter = this.props.securityTypeFilter
    let Security_Type_rel = this.props.securitiesResults
    
    // let Continents = Object.keys(continentFilteredLocations);
    if(EventMute) {
      if(eventFilter.indexOf(EventMuteData) > -1) {
        eventFilter.splice(eventFilter.indexOf(EventMuteData), 1);
      }
    } else {
      if(eventFilter.indexOf(EventMuteData) === -1) {
        eventFilter.push(EventMuteData);
      }
    }
    Object.keys(securitiesMuteData).map(secKey => {
      if(securitiesMute) {
        if(!securitiesMuteData[secKey]) {
          if(SecuritiesFilter.indexOf(secKey) > -1) {
            SecuritiesFilter.splice(SecuritiesFilter.indexOf(secKey), 1);
            let secParent = '';
            this.props.securitiesResults.forEach(relItm => {
              if(relItm.Security === secKey) {
                secParent = relItm.SecurityType;
              }
            })
            SecurityTypeFilter = this.SecuritiesParentsRecursive(secParent, secKey, SecurityTypeMaster, SecurityTypeFilter, SecuritiesMaster, SecuritiesFilter, Security_Type_rel);
          }
        }
      } else {
        if(securitiesMuteData[secKey]) {
          if(SecuritiesFilter.indexOf(secKey) === -1) {
            SecuritiesFilter.push(secKey);
            let secParent = '';
            this.props.securitiesResults.forEach(relItm => {
              if(relItm.Security === secKey) {
                secParent = relItm.SecurityType;
              }
            })
            SecurityTypeFilter = this.SecuritiesParentsRecursive(secParent, secKey, SecurityTypeMaster, SecurityTypeFilter, SecuritiesMaster, SecuritiesFilter, Security_Type_rel);
          }
        }
      }
      return null;
    })
    // Object.keys(mentionTypeMuteData).map(mntpKey => {
    //   if(mentionTypeMute) {
    //     if(!mentionTypeMuteData[mntpKey]) {
    //       if(MentionTypeFilters.indexOf(mntpKey) > -1) {
    //         MentionTypeFilters.splice(MentionTypeFilters.indexOf(mntpKey), 1)
    //         if(MentiontypeMaster.filter(itm => ( itm.Name === mntpKey ))[0].MentionTypeParent !== 0) {
    //           MentionTypeFilters = this.MentionTypesParentsRecursive(mntpKey, MentiontypeMaster, MentionTypeFilters)
    //         }
    //       }
    //     } 
    //   } else {
    //     if(mentionTypeMuteData[mntpKey]) {
    //       if(MentionTypeFilters.indexOf(mntpKey) === -1) {
    //         MentionTypeFilters.push(mntpKey)
    //         if(MentiontypeMaster.filter(itm => ( itm.Name === mntpKey ))[0].MentionTypeParent !== 0) {
    //           MentionTypeFilters = this.MentionTypesParentsRecursive(mntpKey, MentiontypeMaster, MentionTypeFilters)
    //         }
    //       }
    //     }
    //   }
    // })
      // Object.keys(locationMuteData).map(locKey => {
        
      //   if(locationMute) {
      //     if(!locationMuteData[locKey] && locationFilter.indexOf(locKey) > -1) {
      //       locationFilter.splice(locationFilter.indexOf(locKey),1)
      //       Continents.forEach(item => {
      //         let CF_LOC_KEYS = Object.keys(continentFilteredLocations[item]).map(i => {
      //           return continentFilteredLocations[item][i].name;
      //         })
      //         if(CF_LOC_KEYS.indexOf(locKey) > -1) {
      //           let cflag = false;
      //           continentFilteredLocations[item].forEach(cfloc => {
      //             if(cfloc.selected && cfloc.name !== locKey) {
      //               cflag = true;
      //             }
      //           })
      //           if(!cflag) {
      //             locationFilter.splice(locationFilter.indexOf(item),1)
      //           }
      //           continentFilteredLocations[item].forEach(i => {
      //             if(i.name === locKey) {
      //               // console.log(i.name, locKey)
      //               i.selected = false;
      //             }
      //           })
      //         }
      //       })
      //     }
      //     // console.log(eventFilter, locationFilter, continentFilteredLocations);
          
      //   } else {
      //     if(locationMuteData[locKey] && locationFilter.indexOf(locKey) === -1) {
      //       locationFilter.push(locKey);
            
      //       Continents.forEach(item => {
      //         let CF_LOC_KEYS = Object.keys(continentFilteredLocations[item]).map(i => {
      //           return continentFilteredLocations[item][i].name
      //         })
      //         if(CF_LOC_KEYS.indexOf(locKey) > -1) {
      //           let cflag = false;
      //           let cflagcnt = 0;
      //           continentFilteredLocations[item].forEach(cfloc => {
      //             if(!cfloc.selected && cfloc.name !== locKey) {
      //               cflagcnt++;
      //               cflag = true;
      //             }
      //           })
      //           if(!cflag) {
      //             if(locationFilter.indexOf(item) === -1) {
      //               locationFilter.push(item);
      //               continentFilteredLocations[item].forEach(i => {
      //                 if(i.name === locKey) {
      //                   // console.log(i.name, locKey)
      //                   i.selected = true;
      //                 }
      //               })
      //             }
                  
                  
      //           }
      //         }
      //       })
      //     }
          
      //   }
      // })
    
    // return;
    let neweventFilter = eventFilter;
    // let newlocationFilter = [];
    // locationFilter.forEach(item => {
    //   newlocationFilter.push(item)  
    // })
    // let newMentionTypeFilter = [];
    // MentionTypeFilters.forEach(item => {
    //   newMentionTypeFilter.push(item)
    // })

    let newSecuritiesFilter = [];
    SecuritiesFilter.forEach(item => {
      newSecuritiesFilter.push(item)
    })
    let newSecurityTypeFilter = [];
    SecurityTypeFilter.forEach(item => {
      newSecurityTypeFilter.push(item)
    })
    // console.log(locationFilter)
    // console.log(newlocationFilter)
    // console.log(newMentionTypeFilter)
    // console.log(newSecuritiesFilter)
    // console.log(newSecurityTypeFilter)

    let EventData = {
      "UserName": this.props.userData.username,
      "FilterString": neweventFilter.join(';')
    }
    // let LocationData = {
    //   "UserName": this.props.userData.username,
    //   "FilterString": newlocationFilter.join(';')
    // }
    // let MentionTypeData = {
    //   "UserName": this.props.userData.username,
    //   "FilterString": newMentionTypeFilter.join(';')
    // }
    let SecuritiesData = {
      "UserName": this.props.userData.username,
      "FilterString": newSecuritiesFilter.join(';')
    }
    let SecurityTypeData = {
      "UserName": this.props.userData.username,
      "FilterString": newSecurityTypeFilter.join(';')
    }
    
    this.setState({
      ...this.state,
      muteunmuteReasonTip: !this.state.muteunmuteReasonTip
    },() => {
      window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
      window.Mixpanel.MixpanelProps.EventId = this.props.FeedId;
      window.Mixpanel.MixpanelProps.EventType = eventType !== false ? eventType : '';
      window.Mixpanel.MixpanelProps.LocationType = LocType.length ? LocType.join(',') : '';
      window.Mixpanel.MixpanelProps.MentionType = MentionType.length ? MentionType.join(',') : '';
      window.Mixpanel.actions.identify(this.props.userData.username);
      window.Mixpanel.actions.track(`Confirm Mute Future Alerts`, window.Mixpanel.MixpanelProps).then(data => {
        window.Mixpanel.actions.people.set({
          UserName: this.props.userData.username
        });
        window.Mixpanel.actions.resetProp('EventId');
        window.Mixpanel.actions.resetProp('EventType');
        window.Mixpanel.actions.resetProp('LocationType');
        window.Mixpanel.actions.resetProp('MentionType');
      })

      this.props.updateEventSecurityFilter(EventData); 
      // this.props.updateLocationFilter(LocationData);
      // this.props.updateMentionTypesFilter(MentionTypeData)
      // this.props.setLocationByContinents(continentFilteredLocations)
      this.props.updateSecuritiesFilter(SecuritiesData)
      this.props.updateSecurityFilter(SecurityTypeData)
      this.props.modalClose();
    })
    
  }

  toggleMuteUnmuteToolTip = (showState) => {
      const { muteunmuteReasonTip } = this.state;
      if(!muteunmuteReasonTip) {
        if(this.ttHandle) {
          clearTimeout(this.ttHandle)
        }
        if(showState) {
          this.ttHandle = setTimeout(() => {
            this.setState({
              ...this.state,
              muteunmuteToolTip: showState
            },() => {
              window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
              window.Mixpanel.MixpanelProps.EventId = this.props.FeedId;
              window.Mixpanel.MixpanelProps.Headline = this.props.HeadlineText;
              window.Mixpanel.MixpanelProps.ToolTipType = 'Mute Unmute Notification';
              window.Mixpanel.actions.identify(this.props.userData.username);
              window.Mixpanel.actions.track(`Tooltip shown`, window.Mixpanel.MixpanelProps).then(data => {
                window.Mixpanel.actions.people.set({
                  UserName: this.props.userData.username
                });
                window.Mixpanel.actions.resetProp('EventId');
                window.Mixpanel.actions.resetProp('Headline');
                window.Mixpanel.actions.resetProp('ToolTipType');
              })
            })
          }, 1500)
        } else {
          this.setState({
            ...this.state,
            muteunmuteToolTip: showState
          })
        }
      }
      
    
  }

  render() {
    const { EventMute, EventMuteData, securitiesMuteData } = this.state;
    // const { isAnyMuted, EventMute, EventMuteData, locationMuteData, mentionTypeMuteData, securitiesMuteData, muteunmuteToolTip } = this.state;
    let muteunmuteList = [];
    muteunmuteList.push(EventMuteData && EventMuteData !== 'None' ? (
      <Col xs={6} sm={6} md={6} xl={6} lg={6} className="muteunmute-Item"  key={`muteunmuteEvent`}>
        <Row className="marg-0" key={`muteunmuteEvent`}>
          <Col xs={1} sm={1} md={1} xl={1} lg={1} className="align-self-center margright5" >
            <input type="checkbox" value={EventMuteData} checked={EventMute} onChange={()=>this.toggleMuteUnmute('EventMute')} />
          </Col>
          <Col className="align-self-center pad5 text-left" >
            <span className="">Filter out <b>{EventMuteData}</b> related events</span>
          </Col>
        </Row>
      </Col>
      
    ) : (''))
    // muteunmuteList.push(locationMuteData ? (
    //   Object.keys(locationMuteData).map(key => {
    //     return (
    //       <Col xs={6} sm={6} md={6} xl={6} lg={6} className={`${key === 'No Location' ? 'nodisp' : ''} muteunmute-Item`} key={`muteunmuteloc${key}`}>
    //         <Row className="marg-0">
    //           <Col xs={1} sm={1} md={1} xl={1} lg={1} className="align-self-center margright5" >
    //             <input type="checkbox" value={locationMuteData[key]} checked={locationMuteData[key] ? false : true } onChange={()=>this.toggleMuteUnmute('locationMute',key)} />
    //           </Col>
    //           <Col className="align-self-center pad5 text-left" >
    //             <span className="">Filter out <b>{key}</b> related events</span>
    //           </Col>
    //         </Row>
    //       </Col>
    //     )
    //   })
    // ) : (''))
    // muteunmuteList.push(mentionTypeMuteData ? (
    //   Object.keys(mentionTypeMuteData).map(key => {
    //     return (
    //       <Col xs={6} sm={6} md={6} xl={6} lg={6} className={`${key === '' ? 'nodisp' : ''} muteunmute-Item`} key={`muteunmutemnt${key}`}>
    //         <Row className={`marg-0 `} >
    //           <Col xs={1} sm={1} md={1} xl={1} lg={1} className="align-self-center margright5" >
    //             <input type="checkbox" value={mentionTypeMuteData[key]} checked={mentionTypeMuteData[key] ? false : true } onChange={()=>this.toggleMuteUnmute('mentionTypeMute',key)} />
    //           </Col>
    //           <Col className="align-self-center pad5 text-left" >
    //             <span className="">Filter out <b>{key}</b></span>
    //             {/* <span className="">Mute alerts from <b>{key}</b></span> */}
    //           </Col>
    //         </Row>
    //       </Col>
    //     )
    //   })
    // ) : (''))
    muteunmuteList.push(securitiesMuteData ? (
      Object.keys(securitiesMuteData).map(key => {
        return (
          <Col xs={6} sm={6} md={6} xl={6} lg={6} className={`${key === '' ? 'nodisp' : ''} muteunmute-Item`} key={`muteunmutesec${key}`}>
            <Row className={`marg-0 `} >
              <Col xs={1} sm={1} md={1} xl={1} lg={1} className="align-self-center margright5" >
                <input type="checkbox" value={securitiesMuteData[key]} checked={securitiesMuteData[key] ? false : true } onChange={()=>this.toggleMuteUnmute('securitiesMute',key)} />
              </Col>
              <Col className="align-self-center pad5 text-left" >
                <span className="">Filter out <b>{key}</b> related events</span>
              </Col>
            </Row>
          </Col>
        )
      })
    ) : (''))
    return(
      // <Col className={`story-mute margr2 ${this.props.Active ? this.props.EventReadStatusMain ? '' : 'bg-headbar-unread' : this.props.EventCurated ? 'mag-bg-gray' : 'mag-bg-black'} `}>
      // <Row className="h-100 my-auto marg-0">
      //   <Col className="align-self-center marg-0 nopad text-center">
      //     <img src={`${isAnyMuted ? APPPATH.ImageUrl+'/Unmute@2x.png': APPPATH.ImageUrl+'/Mute@2x.png'}`} className="muteunmute-icon" alt="mute-unmute" onClick={this.toggleReasonMuteUnmute} onMouseEnter={() => this.toggleMuteUnmuteToolTip(true)} onMouseLeave={() => this.toggleMuteUnmuteToolTip(false)} onPointerOut={() => this.toggleMuteUnmuteToolTip(false)} />
      //     <Tooltip view={`Mute alerts of this event type or locations`} show={muteunmuteToolTip} type={`normal-right`}/>
      //   </Col>
       
      //   <div className="muteunmute-popup">
       
          
          <Row className={`marg-0 padlr25 padb15`}>
            {/* <Col xs={12} sm={12} md={12} xl={12} lg={12} className="muteunmute-tipHead" >Mute Future Alerts</Col> */}
            <Col xs={12} sm={12} md={12} xl={12} lg={12} className="muteunmute-tipItem">
              <Row className="marg-0">
                <Col className="storyOptionTitle">
                  <span>Mute future alerts</span>
                </Col>
              </Row>
              <Row className={`marg-0 avatarWrap`}  >
                { muteunmuteList }
              </Row>
            </Col>
            <Col xs={12} sm={12} md={12} xl={12} lg={12} className="muteunmute-tipItem padright10">
              <button type="button" className="btn btn-crowdsense-prime float-right marglr5" onClick={this.confirmMuteUnmuteAction}>Confirm</button>
              <button type="button" className="btn btn-crowdsense-border float-right marglr5" onClick={this.MuteUnmuteClicked}>Cancel</button>
            </Col>
          </Row>
        
      //   </div>
      // </Row>
      // </Col>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.settings.userData,
    eventFilter: state.filters.eventFilter,
    // locationFilter :state.filters.locationFilter,
    // continentFilteredLocations: state.filters.continentFilteredLocations,
    // mentionTypes: state.filters.mentionTypes,
    // mentiontypesFilter: state.filters.mentiontypesFilter,
    securities: state.filters.securities,
    securitiesFilter: state.filters.securitiesFilter,
    securitiesResults: state.filters.securitiesResults,
    securitiesRawCollection: state.filters.securitiesRawCollection,
    securityTypeFilter: state.filters.securityFilter,
    muteToolTipHandler : state.settings.toolTipGlobals.muteToolTipHandler
  }
}

const mapDispatchToProps = {
  ...filtersActionCreator,
  ...appSettingsActionCreators
};

export default connect(mapStateToProps, mapDispatchToProps)(MuteUnmute)