import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Row, Col } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

class Scrollable extends Component {
    constructor(props) {
        super();
        this.state = {}
        this.ScrollStop = '';
    }

   

    render() {
        return (
            <div
                className="cScrollX cScrollX-Cntrl "
                style={{
                    height: `${this.props.homeviewport.height}px`,
                    overflowX: this.props.render === 'iframe' ? 'hidden' : 'auto',
                    overflowY: this.props.page === 'stories' ? 'hidden' : 'auto',
                    position: 'relative',
                    margin: '0px 0px 0px 0px',
                }}
                // onScroll={this.Scrolling}
                ref={`ScrollableWrap`}
            >
                { this.props.children }
            </div>
        )
    }
}

export default Scrollable