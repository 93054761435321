import React, { Component } from 'react'; 

class PP extends Component {
  constructor() {
    super();
    this.state = {
      buttonHighlight : 'accept' //'decline'
    }
  }



  render() {
    return (
      this.props.userData.usertype === 'None' ? (
        <div className="tnc-body bg-white">
          <h4 className="tnc-body-head-title">Crowdsense Privacy Notice</h4>
          <p className="tnc-body-content">
            We at CrowdSense Ltd. ("CrowdSense", "us", "we", or "our") recognize and respect the importance of maintaining the privacy of our customers. This Privacy Notice describes the types of information we collect from you when you visit our Site and/or use our Services. This Privacy Notice also explains how we collect, process, transfer, store and disclose the information collected, as well as your ability to control certain uses of the collected information. If not otherwise defined herein, capitalized terms have the meaning given to them in the Terms of Service, available at http://crowdsense.ai/toc  ("Terms").
          </p>
          <p className="tnc-body-content">
            CrowdSense is the data controller in respect of the processing activities outlined in this Privacy Notice. Our registered office is 5th Hateenah St., Rishon Le-Zion, Israel and our registration number is 515997500.
          </p>
          <p className="tnc-body-content">
            When you view or otherwise interact with any Twitter Content (as defined in the Terms and below), Twitter International Company ("Twitter") serves as a separate independent controller with respect to any Personal Data collected in connection with such Twitter Content. Twitter's registered office is One Cumberland Place, Fenian Street. Dublin 2, D02 AX07 Ireland, Attn: Data Protection Officer.
          </p>
          <p className="tnc-body-content">
            "Personal Data" means any information that refers, is related to, or is associated with an identified or identifiable individual or as otherwise may be defined by applicable law. This Privacy Notice details which Personal Data is collected by us in connection with provision of the Services.
          </p>
          <h4 className="tnc-body-title">Privacy Notice Key Points</h4>
          <div className="tnc-body-content">
            The key points listed below are presented in further detail throughout this Privacy Notice. These key points do not substitute the full Privacy Notice.
            <ul>
              <li>
                Personal Data We Collect, Uses and Legal Basis. We collect certain Personal Data that you provide to us including registration information, payment information, and contact information. We also collect certain Personal Data automatically when you use the Site and/or Services. We use your Personal Data for various reasons, including to provide you with the Site and Services, and improve our Services. These processing activities are based on different legal bases including performance of a contract and legitimate interests.
              </li>
              <li>
                Sharing the Personal Data We Collect. We share the Personal Data we collect with our service providers and subcontractors who assist us in the operation of the Site and process the information on our behalf and under our instructions. When you view or otherwise interact with Twitter Content on our Site and/or Services, Twitter acts as a separate independent controller and may receive certain automatically collected data including the web page you visited, your IP address, browser type and operating system.
              </li>
              <li>
                International Transfer. Some of our service providers, subcontractors or business partners who have access to your Personal Data are located in countries other than your own. We will ensure that we have agreements in place with such parties that ensure the same level of privacy and data protection as set forth in this Privacy Notice.
              </li>
              <li>
                Security. We implement measures aimed at protecting your Personal Data, but they do not provide absolute information security. Such measures include physical, electronic, and procedural safeguards.
              </li>
              <li>
                Your Rights. Subject to applicable law and in addition to other rights as set forth below, you may have a right to access, update, delete, and/or obtain a copy of the Personal Data we have collected about you. You also have the right to object at any time to processing your Personal Data for certain purposes.
              </li>
              <li>
                Data Retention. We retain Personal Data for as long as necessary for the purposes set forth in this Privacy Notice. We consider a few different factors when determining the appropriate retention periods.
              </li>
              <li>
                Children. We do not knowingly collect Personal Data from children under the age of sixteen (16).
              </li>
              <li>
                Third-Party Applications and Services. All use of third-party applications or services is at your own risk and subject to such third party's privacy policies.
              </li>
              <li>
                Communications. We may send you e-mail or other messages about us or our Services. You will have the opportunity to opt-out of receiving certain messages that are not service-related.
              </li>
              <li>
                Changes to the Privacy Notice. We may change this Privacy Notice from time to time and shall notify you of such changes by indicating on the Site, that the Privacy Notice has been amended by publishing an updated Privacy Notice on the Site.
              </li>
              <li>
                Comments and Questions. If you have any comments or questions about this Privacy Notice, or if you wish to exercise your legal rights with respect to your Personal Data, please contact us at info@crowdsense.ai. 
              </li>
            </ul>
          </div>
          <h4 className="tnc-body-title">Personal Data We Collect, Uses and Legal Basis</h4>
          <p className="tnc-body-content">
            Depending on your usage, we collect different types of data and we and any of our third-party sub-contractors and service providers use the data we collect for different purposes, as specified below. It is your voluntary decision whether to provide us with certain Personal Data, but if you refuse to provide such Personal Data, we may not be able to register you to the Site and/or provide you with the Services or part thereof.
          </p>
          <p className="tnc-body-content">
            Registration and Account Data - In order to use our Site and/or receive related Services, you will be required to register and provide us with the following Personal Data: your name, email address, and phone number. If you use the Services by logging in through a third-party login/account registration service (such as Facebook or Gmail), we also receive Personal Data about you as provided by such third-party service. We also collect any Alert preferences, parameters and settings you elect to provide upon registration or as otherwise configured by you on the Site and/or Services.
          </p>
          <p className="tnc-body-content">
            How we use this data: (1) to provide you with the Site and/or Services, including sending Alerts to your mobile device, and to respond to your inquiries and requests and to contact and communicate with you; and (2) to prevent fraud, protect the security of and address any problems with the Site and/or Services.
          </p>
          <p className="tnc-body-content">
            Legal Basis: (1) We process this Personal Data for the purpose of providing the Services to you, which is considered performance of a contract with you, including responding to your inquiries and requests and providing customer support. (2) When we process your Personal Data for the purposes of preventing fraud, protecting the security of and/or addressing problems with the Site and Services, such processing is based on our legitimate interests.
          </p>
          <p className="tnc-body-content">
            Payment Data - When you purchase access to the Site and/or Services, we receive information related to such purchase, such as  your  credit card number or other billing information you provide.
          </p>
          <p className="tnc-body-content">
            How we use this data: To process the payment for your subscription and for the purposes of fraud prevention.
          </p>
          <p className="tnc-body-content">
            Legal Basis: We process this Personal Data for the purpose of performance of a contract with you. Processing for the purposes of fraud prevention is based on our legitimate interest.
          </p>
          <p className="tnc-body-content">
            Automatically Collected Data - When you visit the Site, we automatically collect information about your computer or mobile device, including non-Personal Data such as your operating system, and Personal Data such as IP address, device ID, as well as your browsing history and any information regarding your viewing history on our Site.
          </p>
          <p className="tnc-body-content">
            How we use this data: (1) to review usage and operations, including in an aggregated non-specific analytical manner, develop new products or services and improve current content, products, and Services; (2) to prevent fraud, protect the security of our Site and Services, and address any problems with the Site and/or Services.
          </p>
          <p className="tnc-body-content">
            Legal Basis: We process this Personal Data for our legitimate interests to develop our products and Services, review usage, perform analytics, prevent fraud, for our recordkeeping and protection of our legal rights.
          </p>
          <h4 className="tnc-body-title">New Provision for Sharing with Brokers</h4>
          <p className="tnc-body-content">
            <ul>
              <li>In addition to our current data sharing practices, we may now also share contact details and level of activity data with trusted trading brokers or other fintech organizations. This is done to provide you with additional service offerings and opportunities in the financial sector.</li>
              <li>We ensure these brokers and organizations adhere to strict data protection and privacy standards comparable to our own.</li>
              <li>Your consent will be obtained for such data sharing, and you will have the option to opt-out.</li>
            </ul>
          </p>
          <h4 className="tnc-body-title">Additional Uses</h4>
          <p className="tnc-body-content">
            Statistical Information By analyzing all information we receive, including all information concerning users we may compile statistical information across a variety of platforms and users ("Statistical Information"). Statistical Information helps us understand trends and customer needs so that new products and services can be considered and so that existing products and services can be tailored to customer desires. Statistical Information is anonymous and aggregated, and we will not link Statistical Information to any Personal Data. We may share such Statistical Information with our partners, without restriction, on commercial terms that we can determine in our sole discretion. We may also share certain Statistical Information related to your Alert Preferences with other users of our Site and Services. 
          </p>
          <h4 className="tnc-body-title">Analytics</h4>
          <p className="tnc-body-content">
            We and/or our service providers or subcontractors, use analytics tools ("Tools”). Such Tools collect information such as how often users visit the Site, what pages they visit when they do so, and what other sites and mobile applications they used prior to visiting the Site. The Tools may collect certain Personal Data and may link such Personal Data to specific information stored in our customer database. We use the information we get from the Tools to improve our Site and Services.
          </p>
          <h4 className="tnc-body-title">Legal Uses</h4>
          <p className="tnc-body-content">
            We may use your Personal Data as required or permitted by any applicable law, for example, to comply with audit and other legal requirements.
          </p>
          <p className="tnc-body-content">
            Sharing the Personal Data we Collect
          </p>
          
          <p className="tnc-body-content">
            We share your information, including Personal Data, as follows:
          </p>
          <p className="tnc-body-content">
            <span>Service Providers, and Subcontractors</span>
            <span>We also disclose information, including Personal Data we collect from and/or about you, to our trusted service providers and subcontractors, who have agreed to confidentiality restrictions and who use such information solely on our behalf in order to: (1) help us provide you with the Site and/or Services, including sending you Alerts to your mobile device; (2) aid in their understanding of how users are using our Site and/or Services.</span>
          </p>
          <h4 className="tnc-body-title">Business Transfers</h4>
          <p className="tnc-body-content">
            Such service providers and subcontractors [provide us with IT and system administration services, data backup, security, and storage services, data analysis].[Crowdsense: please add as relevant]
          </p>
          <h4 className="tnc-body-title">Data Controllers</h4>
          <p className="tnc-body-content">
            The Site and/or Services include certain content provided through the Twitter Application Programming Interface ("Twitter Content").
          </p>
          <p className="tnc-body-content">
            When you view or otherwise interact with Twitter Content on our Site and/or Services, Twitter may receive certain automatically collected data including the web page you visited, your IP address, browser type, and operating system. Twitter uses this information to better understand the use of its services, to protect the safety and integrity of its platform and to show you more relevant content and advertisements. If you do not want Twitter to use your data for interest-based advertising and personalization, you may opt-out as set forth in Twitter's Privacy Policy, available at https://twitter.com/en/privacy.  Twitter serves as a separate independent controller with respect to any Personal Data collected from you in connection with Twitter Content. Use of Twitter's services, including any Twitter Content provided on the Site and/or Services, is subject to the Twitter Terms of Service, available at https://twitter.com/en/tos, and Twitter Privacy Policy, available at https://twitter.com/en/privacy. 
          </p>
          <p className="tnc-body-content">
            Twitter's details and contact information are as set forth below:
          </p>
          <p className="tnc-body-content">
            <span>Twitter International Company</span>
            <span>Attn: Data Protection Officer</span>
            <span>One Cumberland Place, Fenian Street</span>
            <span>Dublin 2, D02 AX07 Ireland</span>
          </p>
          <h4 className="tnc-body-title">Law Enforcement Related Disclosure</h4>
          <p className="tnc-body-content">
            We may share your Personal Data with third parties: (i) if we believe in good faith that disclosure is appropriate to protect our or a third party's rights, property or safety (including the enforcement of the Terms and this Privacy Notice); (ii) when required by law, regulation subpoena, court order or other law enforcement related issues, agencies and/or authorities; or (iii) as is necessary to comply with any legal and/or regulatory obligation.
          </p>
          <h4 className="tnc-body-title">Other Uses or Transfer of Your Personal Data</h4>
          <p className="tnc-body-content">
            If you use our Site and/or Services with or through a third party service, site and/or mobile application, we may receive information (including Personal Data) about you from those third parties. Please note that when you use third-parties outside of our Site and/or Services, their own terms and privacy policies will govern your use of those services.
          </p>
          <h4 className="tnc-body-title">International Transfer</h4>
          <p className="tnc-body-content">
            We use subcontractors and service providers who are located in countries other than your own, and send them information we receive (including Personal Data). We conduct such international transfers for the purposes described above. We will ensure that these third parties will be subject to written agreements ensuring the same level of privacy and data protection as set forth in this Privacy Notice, including appropriate remedies in the event of the violation of your data protection rights in such third country.  
          </p>
          <div className="tnc-body-content">
            Whenever we transfer your Personal Data to third parties based outside of the European Economic Area ("EEA"), we ensure a similar degree of protection is afforded to it by ensuring at least one of the following safeguards is implemented:
            <ul>
              <li>
                We will only transfer your Personal Data to countries that have been deemed to provide an adequate level of protection for Personal Data by the European Commission.
              </li>
              <li>
                Where we use certain service providers, we may use specific contracts approved by the European Commission which give Personal Data the same protection it has in the EEA.
              </li>
              <li>
                Where we use service providers based in the US, we may transfer Personal Data to them if they have been certified by the EU-US Privacy Shield, which requires them to provide similar protection to Personal Data shared between the EU and the US or any other arrangement which has been approved by the European Commission or other body having jurisdiction to approve such arrangement.
              </li>
            </ul>
          </div>
          <p className="tnc-body-content">
            Please contact us at info@crowdsense.ai if you would like further information on the specific mechanism used by us when transferring your Personal Data out of the EEA.
          </p>
          <h4 className="tnc-body-title">Security</h4>
          <p className="tnc-body-content">
            We have implemented and maintain appropriate technical and organization security measures, policies and procedures designed to reduce the risk of accidental destruction or loss, or the unauthorized disclosure or access to Personal Data appropriate to the nature of such data.
          </p>
          <div className="tnc-body-content">
            <span>Your Rights - How to Access and Limit Our Use of Certain Personal Data</span>
            <span>Subject to certain exemptions, and in some cases dependent upon the processing activity we are undertaking, you have certain rights in relation to the Personal Data that we hold about you, as detailed below. We will investigate and attempt to resolve complaints and disputes and make every reasonable effort to honour your wish to exercise your rights as quickly as possible and in any event, within the timescales provided by applicable data protection laws. We reserve the right to ask for reasonable evidence to verify your identity before we provide you with any information and/or comply with any of your requests, as detailed below:</span>
            <ul>
              <li>
                Right of Access. You have a right to know what Personal Data we collect about you and, in some cases, to have such Personal Data communicated to you. Subject to applicable law, we may charge you with a fee. Please note that we may not be able to provide you with all the information you request and in such case, we will endeavor to explain to you why.
              </li>
              <li>
                Right to Data Portability. If the processing is based on your consent or performance of a contract with you and processing is being carried out by automated means, you may be entitled to (request that we) provide you or another party with a copy of the Personal Data you provided to us in a structured, commonly-used, and machine-readable format.
              </li>
              <li>
                Right to Correct Personal Data. Subject to the limitations in applicable law, you may request that we update, complete, correct or delete inaccurate, incomplete, or outdated Personal Data. 
              </li>
              <li>
                Deletion of Personal Data ("Right to Be Forgotten"). You have a right to request that we delete your Personal Data if either: (i) it is no longer needed for the purpose for which it was collected, (ii) our processing was based on your consent and you have withdrawn your consent, (iii) you have successfully exercised your Right to Object (see below), (iv) processing was unlawful, or (iv) we are required to erase it for compliance with a legal obligation. We cannot restore information once it has been deleted. Please note that to ensure that we do not collect any further Personal Data, you should also delete our Site from your mobile devices, and terminate your account with us. We may retain certain Personal Data (including following your request to delete) for audit and record-keeping purposes, or as otherwise permitted and/or required under applicable law. 
              </li>
              <li>
                Right to Restrict Processing: You can ask us to limit the processing of your Personal Data if either: (i) you have contested its accuracy and wish us to limit processing until this is verified; (ii) the processing is unlawful, but you do not wish us to erase the Personal Data; (iii) it is no longer needed for the purposes for which it was collected, but we still need it to establish, exercise, or defend of a legal claim; (iv) you have exercised your Right to Object (below) and we are in the process of verifying our legitimate grounds for processing. We may continue to use your Personal Data after a restriction request under certain circumstances.
              </li>
              <li>
                Right to Object. You can object to any processing of your Personal Data which has our legitimate interests as its legal basis, if you believe your fundamental rights and freedoms outweigh our legitimate interests. If you raise an objection, we have an opportunity to demonstrate that we have compelling legitimate interests which override your rights and freedoms.
              </li>
              <li>
                Right to Lodge a Complaint with Your Local Supervisory Authority. You may have the right to submit a complaint to the relevant supervisory data protection authority if you have any concerns about how we are processing your Personal Data, though we ask that as a courtesy you please attempt to resolve any issues with us first.
              </li>
            </ul>
          </div>
          <h4 className="tnc-body-title">Data Retention</h4>
          <p className="tnc-body-content">
            Subject to applicable law, we retain Personal Data as necessary for the purposes set forth above. We may delete information from our systems without notice to you once we deem it is no longer necessary for these purposes. Retention by any of our processors may vary in accordance with the processor's retention policy.
          </p>
          <p className="tnc-body-content">
            In some circumstances, we may store your Personal Data for longer periods of time, for instance where we are required to do so in accordance with legal, regulatory, tax, audit, accounting requirements and so that we have an accurate record of your dealings with us in the event of any complaints or challenges, or if we reasonably believe there is a prospect of litigation relating to your Personal Data or dealings. To determine the appropriate retention period, we consider the amount, nature, and sensitivity of the Personal Data, the potential risk of harm from unauthorized use or disclosure of your Personal Data, the purposes for which we process your Personal Data, and whether those purposes can be achieved through other means, as well as applicable legal requirements. 
          </p>
          <p className="tnc-body-content">
            Please contact us at info@crowdsense.ai if you would like details regarding the retention periods for different types of your Personal Data.
          </p>
          <h4 className="tnc-body-title">Third-Party Applications and Services</h4>
          <p className="tnc-body-content">
            The Site and/or Services may provide you with third-party links to websites, applications, and services, including Twitter Content. 
          </p>
          <p className="tnc-body-content">
            All use of third-party applications or services is at your own risk and subject to such third party's terms and privacy policies. 
          </p>
          <h4 className="tnc-body-title">Communications</h4>
          <p className="tnc-body-content">
            We reserve the right to send you service-related communications, including service announcements and administrative messages, without offering you the opportunity to opt out of receiving them. Should you not wish to receive such communications, you may cancel your account. 
          </p>
          <h4 className="tnc-body-title">Children</h4>
          <p className="tnc-body-content">
            We do not knowingly collect Personal Data from children under the age of sixteen (16). In the event that you become aware that an individual under the age of sixteen (16) has enrolled without parental permission, please advise us immediately. 
          </p>
          <h4 className="tnc-body-title">Changes to the Privacy Notice</h4>
          <p className="tnc-body-content">
            We may update this Privacy Notice from time to time to keep it up to date with legal requirements and the way we operate our business, and we will place any updates on this webpage. Please come back to this page every now and then to make sure you are familiar with the latest version. If we make fundamental changes to this Privacy Notice, we will seek to inform you by notice on our Site or per email.
          </p>
          <h4 className="tnc-body-title">Comments and Questions</h4>
          <p className="tnc-body-content">
            If you have any comments or questions about this Privacy Notice or if you wish to exercise any of your legal rights as set out herein, please contact us at info@crowdsense.ai. 
          </p>
          <p className="tnc-body-content" style={{'fontSize': '12px','texAlign':'left'}}><i>Last updated: December 2023</i></p>
         
        </div>
      ) : (this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin') ? (
        <div className="tnc-body bg-white">
          {/* <h4 className="tnc-body-head-title">Algense Privacy Policy</h4> */}
          <h4 className="tnc-body-title">Privacy Policy</h4>
          <p className="tnc-body-content">
            We at Algense, which is part of CrowdSense Ltd. ("Algense", "CrowdSense", "us", "we", or "our") recognize and respect the importance of maintaining the privacy of our customers and your users. This Privacy Notice describes the types of information we collect from you when you visit our Site and/or use our Services. This Privacy Notice also explains how we collect, process, transfer, store and disclose the information collected, as well as your ability to control certain uses of the collected information. If not otherwise defined herein, capitalized terms have the meaning given to them in the Terms of Service, available at http://algense.com/toc ("Terms").
          </p>
          <p className="tnc-body-content">
            Algense is the data controller in respect of the processing activities outlined in this Privacy Notice. Our registered office is 5th Hateenah St., Tel-Aviv, Israel and our registration number is 515997500.
          </p>
          <p className="tnc-body-content">
            "Personal Data" means any information that refers, is related to, or is associated with an identified or identifiable individual or as otherwise may be defined by applicable law. This Privacy Notice details which Personal Data is collected by us in connection with provision of the Services.
          </p>
          <p className="tnc-body-content">
            When we work with institutions, we do not require any knowledge of user identifiable details. Customers such as brokers, are not required to pass user contact details to Algense. In some cases, where there is a request for personalization from the customer, customers can manage the user contact details and map them to unidentifiable ids to pass to Algense, in order for Algense to store filters for the user. Algense does not require any personal data or contact details for the users of the customers we work with.
          </p>
          <h4 className="tnc-body-head-title">Privacy Notice Key Points</h4>
          <p className="tnc-body-content">
            The key points listed below are presented in further detail throughout this Privacy Notice. These key points do not substitute the full Privacy Notice. The below refers to the rare cases where the customer would like Algense to manage the users and store data about them.
            <ul>
              <li>
                Personal Data We Collect, Uses and Legal Basis. We collect certain Personal Data that you provide to us including registration information, payment information, and contact information. We also collect certain Personal Data automatically when you use the Site and/or Services. We use your Personal Data for various reasons, including to provide you with the Site and Services, and improve our Services. These processing activities are based on different legal bases including performance of a contract and legitimate interests.
              </li>
              <li>
                Sharing the Personal Data We Collect. We share the Personal Data we collect with our service providers and subcontractors who assist us in the operation of the Site and process the information on our behalf and under our instructions. When you view or otherwise interact with any third party services or other content linked from our Site and/or Services, the applicable third party acts as a separate independent controller and may receive certain automatically collected data including the web page you visited, your IP address, browser type and operating system.
              </li>
              <li>
                International Transfer. Some of our service providers, subcontractors or business partners who have access to your Personal Data are located in countries other than your own. We will ensure that we have agreements in place with such parties that ensure the same level of privacy and data protection as set forth in this Privacy Notice.
              </li>
              <li>
                Security. We implement measures aimed at protecting your Personal Data, but they do not provide absolute information security. Such measures include physical, electronic, and procedural safeguards.
              </li>
              <li>
                Your Rights. Subject to applicable law and in addition to other rights as set forth below, you may have a right to access, update, delete, and/or obtain a copy of the Personal Data we have collected about you. You also have the right to object at any time to processing your Personal Data for certain purposes.
              </li>
              <li>
                Data Retention. We retain Personal Data for as long as necessary for the purposes set forth in this Privacy Notice. We consider a number of different factors when determining the appropriate retention periods.
              </li>
              <li>
                Children. We do not knowingly collect Personal Data from children under the age of sixteen (16).
              </li>
              <li>
                Third-Party Applications and Services. All use of third-party applications or services is at your own risk and subject to such third party's privacy policies.
              </li>
              <li>
                Communications. We may send you e-mail or other messages about us or our Services. You will have the opportunity to opt-out of receiving certain messages that are not service-related.
              </li>
              <li>
                Changes to the Privacy Notice. We may change this Privacy Notice from time to time and shall notify you of such changes by indicating on the Site, that the Privacy Notice has been amended by publishing an updated Privacy Notice on the Site.
              </li>
              <li>
                Comments and Questions. If you have any comments or questions about this Privacy Notice, or if you wish to exercise your legal rights with respect to your Personal Data, please contact us at info@algense.com.
              </li>
            </ul>
          </p>
          <h4 className="tnc-body-head-title">Analytics</h4>
          <p className="tnc-body-content">
            We and/or our service providers or subcontractors, use analytics tools ("Tools”). Such Tools collect information such as how often users visit the Site, what pages they visit when they do so, and what other sites and mobile applications they used prior to visiting the Site. The Tools may collect certain Personal Data, and may link such Personal Data to specific information stored in our customer database. We use the information we get from the Tools to improve our Site and Services.
          </p>
          <h4 className="tnc-body-head-title">Legal Uses</h4>
          <p className="tnc-body-content">
            We may use your Personal Data as required or permitted by any applicable law, for example, to comply with audit and other legal requirements.
          </p>
          <h4 className="tnc-body-head-title">Sharing personal data we collect</h4>
          <p className="tnc-body-content">
            We share your information, including Personal Data, as follows:
          </p>
          <h4 className="tnc-body-head-title">Service Providers, and Subcontractors</h4>
          <p className="tnc-body-content">
            We also disclose information, including Personal Data we collect from and/or about you, to our trusted service providers and subcontractors, who have agreed to confidentiality restrictions and who use such information solely on our behalf in order to: (1) help us provide you with the Site and/or Services, including sending you Alerts to your mobile device, sending emails on Event Alerts, or sending periodic Event lists emails; (2) aid in their understanding of how users are using our Site and/or Services.
          </p>
          <h4 className="tnc-body-head-title">Business Transfers</h4>
          <p className="tnc-body-content">
            Your Personal Data may be disclosed as part of, or during negotiations of, any merger, sale of company assets or acquisition (including in cases of liquidation) in such case, your Personal Data shall continue being subject to the provisions of this Privacy Notice.
          </p>
          <h4 className="tnc-body-head-title">Data Controllers</h4>
          <p className="tnc-body-content">
            The Site and/or Services include certain content provided through third party applications ("Third-Party Content"), including, without limitation, through the Twitter Application Programming Interface and the data sources listed at https://developer.twitter.com/en/products/twitter-api.
          </p>
          <p className="tnc-body-content">
            When you access any Third-Party Content, the applicable third party may be collecting certain Personal Data about you in accordance with such party's data collection practices. As such, each third party whose Third-Party Content you access may be serving as a separate and independent controller of your Personal Data.
          </p>
          <h4 className="tnc-body-head-title">Law Enforcement Related Disclosure</h4>
          <p className="tnc-body-content">
            We may share your Personal Data with third parties: (i) if we believe in good faith that disclosure is appropriate to protect our or a third party's rights, property or safety (including the enforcement of the Terms and this Privacy Notice); (ii) when required by law, regulation subpoena, court order or other law enforcement related issues, agencies and/or authorities; or (iii) as is necessary to comply with any legal and/or regulatory obligation.
          </p>
          <h4 className="tnc-body-head-title">Other Uses or Transfer of Your Personal Data</h4>
          <p className="tnc-body-content">
            If you use our Site and/or Services with or through a third party service, site and/or mobile application, we may receive information (including Personal Data) about you from those third parties. Please note that when you use third-parties outside of our Site and/or Services, their own terms and privacy policies will govern your use of those services.
          </p>
          <h4 className="tnc-body-head-title">International Transfer</h4>
          <p className="tnc-body-content">
            We use subcontractors and service providers who are located in countries other than your own, and send them information we receive (including Personal Data). We conduct such international transfers for the purposes described above. We will ensure that these third parties will be subject to written agreements ensuring the same level of privacy and data protection as set forth in this Privacy Notice, including appropriate remedies in the event of the violation of your data protection rights in such third country.
          </p>
          <p className="tnc-body-content">
            Whenever we transfer your Personal Data to third parties based outside of the European Economic Area ("EEA"), we ensure a similar degree of protection is afforded to it by ensuring at least one of the following safeguards is implemented:
          </p>
          <p className="tnc-body-content">
            We will only transfer your Personal Data to countries that have been deemed to provide an adequate level of protection for Personal Data by the European Commission.
          </p>
          <p className="tnc-body-content">
            Where we use certain service providers, we may use specific contracts approved by the European Commission which give Personal Data the same protection it has in the EEA.
          </p>
          <p className="tnc-body-content">
            Where we use service providers based in the US, we may transfer Personal Data to them if they have been certified by the EU-US Privacy Shield, which requires them to provide similar protection to Personal Data shared between the EU and the US or any other arrangement which has been approved by the European Commission or other body having jurisdiction to approve such arrangement.
          </p>
          <p className="tnc-body-content">
            Please contact us at info@algense.com if you would like further information on the specific mechanism used by us when transferring your Personal Data out of the EEA.
          </p>
          <h4 className="tnc-body-head-title">Security</h4>
          <p className="tnc-body-content">
            ​We have implemented and maintain appropriate technical and organization security measures, policies and procedures designed to reduce the risk of accidental destruction or loss, or the unauthorized disclosure or access to Personal Data appropriate to the nature of such data.
          </p>
          <h4 className="tnc-body-head-title">Your Rights - How to Access and Limit Our Use of Certain Personal Data</h4>
          <p className="tnc-body-content">
            Subject to certain exemptions, and in some cases dependent upon the processing activity we are undertaking, you have certain rights in relation to the Personal Data that we hold about you, as detailed below. We will investigate and attempt to resolve complaints and disputes and make every reasonable effort to honor your wish to exercise your rights as quickly as possible and in any event, within the timescales provided by applicable data protection laws. We reserve the right to ask for reasonable evidence to verify your identity before we provide you with any information and/or comply with any of your requests, as detailed below:
          </p>
          <p className="tnc-body-content">
            Right of Access. You have a right to know what Personal Data we collect about you and, in some cases, to have such Personal Data communicated to you. Subject to applicable law, we may charge you with a fee. Please note that we may not be able to provide you with all the information you request and in such case, we will endeavor to explain to you why.
          </p>
          <p className="tnc-body-content">
            Right to Data Portability. If the processing is based on your consent or performance of a contract with you and processing is being carried out by automated means, you may be entitled to (request that we) provide you or another party with a copy of the Personal Data you provided to us in a structured, commonly-used, and machine-readable format.
          </p>
          <p className="tnc-body-content">
            Right to Correct Personal Data. Subject to the limitations in applicable law, you may request that we update, complete, correct or delete inaccurate, incomplete, or outdated Personal Data.
          </p>
          <p className="tnc-body-content">
            Deletion of Personal Data ("Right to Be Forgotten"). You have a right to request that we delete your Personal Data if either: (i) it is no longer needed for the purpose for which it was collected, (ii) our processing was based on your consent and you have withdrawn your consent, (iii) you have successfully exercised your Right to Object (see below), (iv) processing was unlawful, or (iv) we are required to erase it for compliance with a legal obligation. We cannot restore information once it has been deleted. Please note that to ensure that we do not collect any further Personal Data, you should also delete our Site from your mobile devices, and terminate your account with us. We may retain certain Personal Data (including following your request to delete) for audit and record-keeping purposes, or as otherwise permitted and/or required under applicable law.
          </p>
          <p className="tnc-body-content">
            Right to Restrict Processing: You can ask us to limit the processing of your Personal Data if either: (i) you have contested its accuracy and wish us to limit processing until this is verified; (ii) the processing is unlawful, but you do not wish us to erase the Personal Data; (iii) it is no longer needed for the purposes for which it was collected, but we still need it to establish, exercise, or defend of a legal claim; (iv) you have exercised your Right to Object (below) and we are in the process of verifying our legitimate grounds for processing. We may continue to use your Personal Data after a restriction request under certain circumstances.
          </p>
          <p className="tnc-body-content">
            Right to Object. You can object to any processing of your Personal Data which has our legitimate interests as its legal basis, if you believe your fundamental rights and freedoms outweigh our legitimate interests. If you raise an objection, we have an opportunity to demonstrate that we have compelling legitimate interests which override your rights and freedoms.
          </p>
          <p className="tnc-body-content">
            Right to Lodge a Complaint with Your Local Supervisory Authority. You may have the right to submit a complaint to the relevant supervisory data protection authority if you have any concerns about how we are processing your Personal Data, though we ask that as a courtesy you please attempt to resolve any issues with us first.
          </p>
          <h4 className="tnc-body-head-title">Data Retention</h4>
          <p className="tnc-body-content">
            Subject to applicable law, we retain Personal Data as necessary for the purposes set forth above. We may delete information from our systems without notice to you once we deem it is no longer necessary for these purposes. Retention by any of our processors may vary in accordance with the processor's retention policy.
          </p>
          <p className="tnc-body-content">
            In some circumstances, we may store your Personal Data for longer periods of time, for instance where we are required to do so in accordance with legal, regulatory, tax, audit, accounting requirements and so that we have an accurate record of your dealings with us in the event of any complaints or challenges, or if we reasonably believe there is a prospect of litigation relating to your Personal Data or dealings. To determine the appropriate retention period, we consider the amount, nature, and sensitivity of the Personal Data, the potential risk of harm from unauthorized use or disclosure of your Personal Data, the purposes for which we process your Personal Data, and whether those purposes can be achieved through other means, as well as applicable legal requirements.
          </p>
          <p className="tnc-body-content">
            Please contact us at info@algense.com if you would like details regarding the retention periods for different types of your Personal Data.
          </p>
          <h4 className="tnc-body-head-title">Third-Party Applications and Services</h4>
          <p className="tnc-body-content">
            The Site and/or Services may provide you with third-party links to websites, applications, and services.
          </p>
          <p className="tnc-body-content">
            All use of third-party applications or services is at your own risk and subject to such third party's terms and privacy policies.
          </p>
          <h4 className="tnc-body-head-title">Communications</h4>
          <p className="tnc-body-content">
            We reserve the right to send you service-related communications, including service announcements and administrative messages, without offering you the opportunity to opt out of receiving them. Should you not wish to receive such communications, you may cancel your account.
          </p>
          <h4 className="tnc-body-head-title">Children</h4>
          <p className="tnc-body-content">
            We do not knowingly collect Personal Data from children under the age of sixteen (16). In the event that you become aware that an individual under the age of sixteen (16) has enrolled without parental permission, please advise us immediately.
          </p>
          <h4 className="tnc-body-head-title">Changes to the Privacy Notice</h4>
          <p className="tnc-body-content">
            We may update this Privacy Notice from time to time to keep it up to date with legal requirements and the way we operate our business, and we will place any updates on this webpage. Please come back to this page every now and then to make sure you are familiar with the latest version. If we make fundamental changes to this Privacy Notice, we will seek to inform you by notice on our Site or per email.
          </p>
          <h4 className="tnc-body-head-title">Comments and Questions</h4>
          <p className="tnc-body-content">
            If you have any comments or questions about this Privacy Notice or if you wish to exercise any of your legal rights as set out herein, please contact us at info@algense.com
          </p>
          <p className="tnc-body-content" style={{'fontSize': '12px','texAlign':'left'}}><i>Last updated: May 2024</i></p>
        </div>
      ) : (
        <div className="tnc-body bg-white">
          {/* <h4 className="tnc-body-head-title">Algense Privacy Policy</h4> */}
          <h4 className="tnc-body-title">Privacy Policy</h4>
          <p className="tnc-body-content">
            We at Algense, which is part of CrowdSense Ltd. ("Algense", "CrowdSense", "us", "we", or "our") recognize and respect the importance of maintaining the privacy of our customers and your users. This Privacy Notice describes the types of information we collect from you when you visit our Site and/or use our Services. This Privacy Notice also explains how we collect, process, transfer, store and disclose the information collected, as well as your ability to control certain uses of the collected information. If not otherwise defined herein, capitalized terms have the meaning given to them in the Terms of Service, available at http://algense.com/toc ("Terms").
          </p>
          <p className="tnc-body-content">
            Algense is the data controller in respect of the processing activities outlined in this Privacy Notice. Our registered office is 5th Hateenah St., Tel-Aviv, Israel and our registration number is 515997500.
          </p>
          <p className="tnc-body-content">
            "Personal Data" means any information that refers, is related to, or is associated with an identified or identifiable individual or as otherwise may be defined by applicable law. This Privacy Notice details which Personal Data is collected by us in connection with provision of the Services.
          </p>
          <p className="tnc-body-content">
            When we work with institutions, we do not require any knowledge of user identifiable details. Customers such as brokers, are not required to pass user contact details to Algense. In some cases, where there is a request for personalization from the customer, customers can manage the user contact details and map them to unidentifiable ids to pass to Algense, in order for Algense to store filters for the user. Algense does not require any personal data or contact details for the users of the customers we work with.
          </p>
          <h4 className="tnc-body-head-title">Privacy Notice Key Points</h4>
          <p className="tnc-body-content">
            The key points listed below are presented in further detail throughout this Privacy Notice. These key points do not substitute the full Privacy Notice. The below refers to the rare cases where the customer would like Algense to manage the users and store data about them.
            <ul>
              <li>
                Personal Data We Collect, Uses and Legal Basis. We collect certain Personal Data that you provide to us including registration information, payment information, and contact information. We also collect certain Personal Data automatically when you use the Site and/or Services. We use your Personal Data for various reasons, including to provide you with the Site and Services, and improve our Services. These processing activities are based on different legal bases including performance of a contract and legitimate interests.
              </li>
              <li>
                Sharing the Personal Data We Collect. We share the Personal Data we collect with our service providers and subcontractors who assist us in the operation of the Site and process the information on our behalf and under our instructions. When you view or otherwise interact with any third party services or other content linked from our Site and/or Services, the applicable third party acts as a separate independent controller and may receive certain automatically collected data including the web page you visited, your IP address, browser type and operating system.
              </li>
              <li>
                International Transfer. Some of our service providers, subcontractors or business partners who have access to your Personal Data are located in countries other than your own. We will ensure that we have agreements in place with such parties that ensure the same level of privacy and data protection as set forth in this Privacy Notice.
              </li>
              <li>
                Security. We implement measures aimed at protecting your Personal Data, but they do not provide absolute information security. Such measures include physical, electronic, and procedural safeguards.
              </li>
              <li>
                Your Rights. Subject to applicable law and in addition to other rights as set forth below, you may have a right to access, update, delete, and/or obtain a copy of the Personal Data we have collected about you. You also have the right to object at any time to processing your Personal Data for certain purposes.
              </li>
              <li>
                Data Retention. We retain Personal Data for as long as necessary for the purposes set forth in this Privacy Notice. We consider a number of different factors when determining the appropriate retention periods.
              </li>
              <li>
                Children. We do not knowingly collect Personal Data from children under the age of sixteen (16).
              </li>
              <li>
                Third-Party Applications and Services. All use of third-party applications or services is at your own risk and subject to such third party's privacy policies.
              </li>
              <li>
                Communications. We may send you e-mail or other messages about us or our Services. You will have the opportunity to opt-out of receiving certain messages that are not service-related.
              </li>
              <li>
                Changes to the Privacy Notice. We may change this Privacy Notice from time to time and shall notify you of such changes by indicating on the Site, that the Privacy Notice has been amended by publishing an updated Privacy Notice on the Site.
              </li>
              <li>
                Comments and Questions. If you have any comments or questions about this Privacy Notice, or if you wish to exercise your legal rights with respect to your Personal Data, please contact us at info@algense.com.
              </li>
            </ul>
          </p>
          <h4 className="tnc-body-head-title">Analytics</h4>
          <p className="tnc-body-content">
            We and/or our service providers or subcontractors, use analytics tools ("Tools”). Such Tools collect information such as how often users visit the Site, what pages they visit when they do so, and what other sites and mobile applications they used prior to visiting the Site. The Tools may collect certain Personal Data, and may link such Personal Data to specific information stored in our customer database. We use the information we get from the Tools to improve our Site and Services.
          </p>
          <h4 className="tnc-body-head-title">Legal Uses</h4>
          <p className="tnc-body-content">
            We may use your Personal Data as required or permitted by any applicable law, for example, to comply with audit and other legal requirements.
          </p>
          <h4 className="tnc-body-head-title">Sharing personal data we collect</h4>
          <p className="tnc-body-content">
            We share your information, including Personal Data, as follows:
          </p>
          <h4 className="tnc-body-head-title">Service Providers, and Subcontractors</h4>
          <p className="tnc-body-content">
            We also disclose information, including Personal Data we collect from and/or about you, to our trusted service providers and subcontractors, who have agreed to confidentiality restrictions and who use such information solely on our behalf in order to: (1) help us provide you with the Site and/or Services, including sending you Alerts to your mobile device, sending emails on Event Alerts, or sending periodic Event lists emails; (2) aid in their understanding of how users are using our Site and/or Services.
          </p>
          <h4 className="tnc-body-head-title">Business Transfers</h4>
          <p className="tnc-body-content">
            Your Personal Data may be disclosed as part of, or during negotiations of, any merger, sale of company assets or acquisition (including in cases of liquidation) in such case, your Personal Data shall continue being subject to the provisions of this Privacy Notice.
          </p>
          <h4 className="tnc-body-head-title">Data Controllers</h4>
          <p className="tnc-body-content">
            The Site and/or Services include certain content provided through third party applications ("Third-Party Content"), including, without limitation, through the Twitter Application Programming Interface and the data sources listed at https://developer.twitter.com/en/products/twitter-api.
          </p>
          <p className="tnc-body-content">
            When you access any Third-Party Content, the applicable third party may be collecting certain Personal Data about you in accordance with such party's data collection practices. As such, each third party whose Third-Party Content you access may be serving as a separate and independent controller of your Personal Data.
          </p>
          <h4 className="tnc-body-head-title">Law Enforcement Related Disclosure</h4>
          <p className="tnc-body-content">
            We may share your Personal Data with third parties: (i) if we believe in good faith that disclosure is appropriate to protect our or a third party's rights, property or safety (including the enforcement of the Terms and this Privacy Notice); (ii) when required by law, regulation subpoena, court order or other law enforcement related issues, agencies and/or authorities; or (iii) as is necessary to comply with any legal and/or regulatory obligation.
          </p>
          <h4 className="tnc-body-head-title">Other Uses or Transfer of Your Personal Data</h4>
          <p className="tnc-body-content">
            If you use our Site and/or Services with or through a third party service, site and/or mobile application, we may receive information (including Personal Data) about you from those third parties. Please note that when you use third-parties outside of our Site and/or Services, their own terms and privacy policies will govern your use of those services.
          </p>
          <h4 className="tnc-body-head-title">International Transfer</h4>
          <p className="tnc-body-content">
            We use subcontractors and service providers who are located in countries other than your own, and send them information we receive (including Personal Data). We conduct such international transfers for the purposes described above. We will ensure that these third parties will be subject to written agreements ensuring the same level of privacy and data protection as set forth in this Privacy Notice, including appropriate remedies in the event of the violation of your data protection rights in such third country.
          </p>
          <p className="tnc-body-content">
            Whenever we transfer your Personal Data to third parties based outside of the European Economic Area ("EEA"), we ensure a similar degree of protection is afforded to it by ensuring at least one of the following safeguards is implemented:
          </p>
          <p className="tnc-body-content">
            We will only transfer your Personal Data to countries that have been deemed to provide an adequate level of protection for Personal Data by the European Commission.
          </p>
          <p className="tnc-body-content">
            Where we use certain service providers, we may use specific contracts approved by the European Commission which give Personal Data the same protection it has in the EEA.
          </p>
          <p className="tnc-body-content">
            Where we use service providers based in the US, we may transfer Personal Data to them if they have been certified by the EU-US Privacy Shield, which requires them to provide similar protection to Personal Data shared between the EU and the US or any other arrangement which has been approved by the European Commission or other body having jurisdiction to approve such arrangement.
          </p>
          <p className="tnc-body-content">
            Please contact us at info@algense.com if you would like further information on the specific mechanism used by us when transferring your Personal Data out of the EEA.
          </p>
          <h4 className="tnc-body-head-title">Security</h4>
          <p className="tnc-body-content">
            ​We have implemented and maintain appropriate technical and organization security measures, policies and procedures designed to reduce the risk of accidental destruction or loss, or the unauthorized disclosure or access to Personal Data appropriate to the nature of such data.
          </p>
          <h4 className="tnc-body-head-title">Your Rights - How to Access and Limit Our Use of Certain Personal Data</h4>
          <p className="tnc-body-content">
            Subject to certain exemptions, and in some cases dependent upon the processing activity we are undertaking, you have certain rights in relation to the Personal Data that we hold about you, as detailed below. We will investigate and attempt to resolve complaints and disputes and make every reasonable effort to honor your wish to exercise your rights as quickly as possible and in any event, within the timescales provided by applicable data protection laws. We reserve the right to ask for reasonable evidence to verify your identity before we provide you with any information and/or comply with any of your requests, as detailed below:
          </p>
          <p className="tnc-body-content">
            Right of Access. You have a right to know what Personal Data we collect about you and, in some cases, to have such Personal Data communicated to you. Subject to applicable law, we may charge you with a fee. Please note that we may not be able to provide you with all the information you request and in such case, we will endeavor to explain to you why.
          </p>
          <p className="tnc-body-content">
            Right to Data Portability. If the processing is based on your consent or performance of a contract with you and processing is being carried out by automated means, you may be entitled to (request that we) provide you or another party with a copy of the Personal Data you provided to us in a structured, commonly-used, and machine-readable format.
          </p>
          <p className="tnc-body-content">
            Right to Correct Personal Data. Subject to the limitations in applicable law, you may request that we update, complete, correct or delete inaccurate, incomplete, or outdated Personal Data.
          </p>
          <p className="tnc-body-content">
            Deletion of Personal Data ("Right to Be Forgotten"). You have a right to request that we delete your Personal Data if either: (i) it is no longer needed for the purpose for which it was collected, (ii) our processing was based on your consent and you have withdrawn your consent, (iii) you have successfully exercised your Right to Object (see below), (iv) processing was unlawful, or (iv) we are required to erase it for compliance with a legal obligation. We cannot restore information once it has been deleted. Please note that to ensure that we do not collect any further Personal Data, you should also delete our Site from your mobile devices, and terminate your account with us. We may retain certain Personal Data (including following your request to delete) for audit and record-keeping purposes, or as otherwise permitted and/or required under applicable law.
          </p>
          <p className="tnc-body-content">
            Right to Restrict Processing: You can ask us to limit the processing of your Personal Data if either: (i) you have contested its accuracy and wish us to limit processing until this is verified; (ii) the processing is unlawful, but you do not wish us to erase the Personal Data; (iii) it is no longer needed for the purposes for which it was collected, but we still need it to establish, exercise, or defend of a legal claim; (iv) you have exercised your Right to Object (below) and we are in the process of verifying our legitimate grounds for processing. We may continue to use your Personal Data after a restriction request under certain circumstances.
          </p>
          <p className="tnc-body-content">
            Right to Object. You can object to any processing of your Personal Data which has our legitimate interests as its legal basis, if you believe your fundamental rights and freedoms outweigh our legitimate interests. If you raise an objection, we have an opportunity to demonstrate that we have compelling legitimate interests which override your rights and freedoms.
          </p>
          <p className="tnc-body-content">
            Right to Lodge a Complaint with Your Local Supervisory Authority. You may have the right to submit a complaint to the relevant supervisory data protection authority if you have any concerns about how we are processing your Personal Data, though we ask that as a courtesy you please attempt to resolve any issues with us first.
          </p>
          <h4 className="tnc-body-head-title">Data Retention</h4>
          <p className="tnc-body-content">
            Subject to applicable law, we retain Personal Data as necessary for the purposes set forth above. We may delete information from our systems without notice to you once we deem it is no longer necessary for these purposes. Retention by any of our processors may vary in accordance with the processor's retention policy.
          </p>
          <p className="tnc-body-content">
            In some circumstances, we may store your Personal Data for longer periods of time, for instance where we are required to do so in accordance with legal, regulatory, tax, audit, accounting requirements and so that we have an accurate record of your dealings with us in the event of any complaints or challenges, or if we reasonably believe there is a prospect of litigation relating to your Personal Data or dealings. To determine the appropriate retention period, we consider the amount, nature, and sensitivity of the Personal Data, the potential risk of harm from unauthorized use or disclosure of your Personal Data, the purposes for which we process your Personal Data, and whether those purposes can be achieved through other means, as well as applicable legal requirements.
          </p>
          <p className="tnc-body-content">
            Please contact us at info@algense.com if you would like details regarding the retention periods for different types of your Personal Data.
          </p>
          <h4 className="tnc-body-head-title">Third-Party Applications and Services</h4>
          <p className="tnc-body-content">
            The Site and/or Services may provide you with third-party links to websites, applications, and services.
          </p>
          <p className="tnc-body-content">
            All use of third-party applications or services is at your own risk and subject to such third party's terms and privacy policies.
          </p>
          <h4 className="tnc-body-head-title">Communications</h4>
          <p className="tnc-body-content">
            We reserve the right to send you service-related communications, including service announcements and administrative messages, without offering you the opportunity to opt out of receiving them. Should you not wish to receive such communications, you may cancel your account.
          </p>
          <h4 className="tnc-body-head-title">Children</h4>
          <p className="tnc-body-content">
            We do not knowingly collect Personal Data from children under the age of sixteen (16). In the event that you become aware that an individual under the age of sixteen (16) has enrolled without parental permission, please advise us immediately.
          </p>
          <h4 className="tnc-body-head-title">Changes to the Privacy Notice</h4>
          <p className="tnc-body-content">
            We may update this Privacy Notice from time to time to keep it up to date with legal requirements and the way we operate our business, and we will place any updates on this webpage. Please come back to this page every now and then to make sure you are familiar with the latest version. If we make fundamental changes to this Privacy Notice, we will seek to inform you by notice on our Site or per email.
          </p>
          <h4 className="tnc-body-head-title">Comments and Questions</h4>
          <p className="tnc-body-content">
            If you have any comments or questions about this Privacy Notice or if you wish to exercise any of your legal rights as set out herein, please contact us at info@algense.com
          </p>
          <p className="tnc-body-content" style={{'fontSize': '12px','texAlign':'left'}}><i>Last updated: May 2024</i></p>
        </div>
      )
    )
  }
}



export default PP