import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import * as moment from 'moment';
import StoryHeadBar from './story_headbar';
import StoryDetails from './story_details';
import SecurityDetails from './security_details';
import StoryMaps from './story_map';
import { connect } from 'react-redux';
import * as feedActionCreators from '../../actions/feeds.action';
import { activateGlobalTooltipRemover, resetTipHandles } from '../../actions/settings.action';
import { CustomMouseEvent } from '../../appEvents';
import { APPPATH, NotifyFeedsHandler } from '../../modules/helper.module';
import StoryToggleBar from './story_togglebar';
import CorelTag from './coreltag';
import { SpanishTranslations, JapaneseTranslations, ArabicTranslations, PortugueseTranslations } from '../../static_data/translations';
import NavbarCollapse from 'react-bootstrap/esm/NavbarCollapse';
import SpinnerLoader from '../shared/loaders/apploaderspinner/appLoaderSpinner';
// import { Locations } from '../../static_data/timezone_locations';

class SingleStory extends Component {
  constructor(props) {
    super();
    this.state = {
      detailssection: 'SecurityDetails',
      collapseToggle: props.feedOpenClose,
      collapseView: false,
      feedDetailsRequest: false,
      feedDetailsRequests: props.feedDetailsRequests,
      story: {},
      MouseEnterLeaveTracker: false,
      preferencesSecuritiesLine: false,
      mousemoveoverstory: false,
      preferencesLocationRelatedCountries: false,
      NaceUpdatedOld: 0,
      NaceUpdated: 0,
      withCuratedUpdated: 0,
      withBlackUpdated: 0,
      withCuratedUpdatedOld: 0,
      withBlackUpdatedOld: 0,
      hideThisStory: false,
      UserTimeZone: props.TimeZoneOffset,
      Timezone: props.Timezone,
      timeOffset: 0,
    };
    this.timeInterval = '';
    this.timeIntervalToClose = '';
    this.stopTimeout = '';
    this.OverlayRef = React.createRef();
    this.SecTag = React.createRef();
    this.trend = React.createRef();
    this.extraSecTag = '';
    this.MagTag = React.createRef();
  }
  componentWillMount() {
    let { offsetMins } = this.makeTimeZoneOffset(this.props.TimeZoneOffset, this.props.Timezone);

    this.setState({
      ...this.state,
      story: this.props.story,
      preferencesSecuritiesLine: this.props.preferencesSecuritiesLine,
      preferencesLocationRelatedCountries: this.props.preferencesLocationRelatedCountries,
      NaceUpdated: this.props.NaceUpdated,
      NaceUpdatedOld:
        this.props.NaceUpdated === this.state.NaceUpdatedOld ? this.props.NaceUpdated : this.state.NaceUpdatedOld,
      withBlackUpdated: this.props.withBlackUpdated,
      withBlackUpdatedOld:
        this.props.withBlackUpdated === this.state.withBlackUpdatedOld
          ? this.props.withBlackUpdated
          : this.state.withBlackUpdatedOld,
      withCuratedUpdated: this.props.withCuratedUpdated,
      withCuratedUpdatedOld:
        this.props.withCuratedUpdated === this.state.withCuratedUpdatedOld
          ? this.props.withCuratedUpdated
          : this.state.withCuratedUpdatedOld,
      timeOffset: parseInt(`${offsetMins}`),
    });
  }
  componentDidMount() {
    const { story, tdecktype } = this.props;
    if (story !== null) {
      document.getElementById(`${story.FeedId}`).addEventListener('mousemove', (e) => {
        this.handleMouseMove(e);
      });
      document.getElementById(`${story.FeedId}`).addEventListener('mousestop', (e) => {
        this.handleMouseStop(e);
      });
      if (tdecktype === 'event') {

        if (story.CorrelationResults !== null) {
          const TM = {
            SecurityName: story.CorrelationResults[0].Security,
            SecurityTicker: story.CorrelationResults[0].Symbol,
          };
          // window.localStorage.setItem('SavedCoinDetail', JSON.stringify(TM));
          // window.localStorage.setItem('CoinDetailColumnShowState', true);
          // this.props.pinCoinColumn(TM)
        }

        if (isMobile) {
          let scrollDiv = document.getElementsByClassName('cScroll-Mob')[0];
          if (scrollDiv) {
            setTimeout(() => {
              scrollDiv.scroll({
                left: story.CorrelationResults === null ? scrollDiv.scrollWidth : ((scrollDiv.scrollWidth / 3) * 1) - 10,
                behavior: 'smooth',
              });
            }, 1500);
          }
        }
      }
    }
  }
  handleMouseMove = (e) => {
    // this.props.MouseMoveOverStory(true);
    // clearTimeout(this.stopTimeout);
    // this.stopTimeout = setTimeout(function () {
    //   let event = CustomMouseEvent.mousestop;
    //   e.target.dispatchEvent(event);
    // }, 2000);
  };
  handleMouseStop = (e) => {
    this.props.MouseMoveOverStory(false);
  };
  componentWillUnmount() {
    const { story } = this.props;
    if (story !== null) {
      document.getElementById(`${story.FeedId}`).removeEventListener('mousemove', (e) => {
        this.handleMouseMove(e);
      });
      document.getElementById(`${story.FeedId}`).removeEventListener('mousestop', (e) => {
        this.handleMouseStop(e);
      });
    }
  }
  componentWillReceiveProps(nextProps, prevState) {
    let { offsetMins } = this.makeTimeZoneOffset(nextProps.TimeZoneOffset, nextProps.Timezone);
    // console.log(offsetMins, nextProps.TimeZoneOffset)
    // console.log('receiving props, withBlackUpdated')
    // console.log(this.props.preferencesLocationRelatedCountries, nextProps.preferencesLocationRelatedCountries)
    // console.log(JSON.stringify(this.props.NaceUpdated) !== JSON.stringify(nextProps.NaceUpdated))
    // console.log(this.props.NaceUpdated !== nextProps.NaceUpdated)
    let eachStory = nextProps.story;
    if (eachStory !== null) {
      let detailreq = this.state.feedDetailsRequest
      if (this.state.story && this.state.story.EventReadStatus) {
        eachStory.EventReadStatus = this.state.story.EventReadStatus;
      }
      if (nextProps.feedDetailsRequests > this.state.feedDetailsRequests) {
        detailreq = false;
      }
      this.setState({
        ...this.state,
        story: eachStory,
        preferencesSecuritiesLine: nextProps.preferencesSecuritiesLine,
        preferencesLocationRelatedCountries: nextProps.preferencesLocationRelatedCountries,
        NaceUpdated: nextProps.NaceUpdated,
        NaceUpdatedOld:
          nextProps.NaceUpdated === this.state.NaceUpdatedOld ? nextProps.NaceUpdated : this.state.NaceUpdatedOld,
        withBlackUpdated: nextProps.withBlackUpdated,
        withBlackUpdatedOld:
          nextProps.withBlackUpdated === this.state.withBlackUpdatedOld
            ? nextProps.withBlackUpdated
            : this.state.withBlackUpdatedOld,
        withCuratedUpdated: nextProps.withCuratedUpdated,
        withCuratedUpdatedOld:
          nextProps.withCuratedUpdated === this.state.withCuratedUpdatedOld
            ? nextProps.withCuratedUpdated
            : this.state.withCuratedUpdatedOld,
        timeOffset: parseInt(`${offsetMins}`),
        feedDetailsRequests: nextProps.feedDetailsRequests,
        feedDetailsRequest: detailreq
      });
    } else {
      if (this.state.story !== null) {
        this.setState({
          ...this.state,
          hideThisStory: true,
        });
      }
    }
  }

  makeTimeZoneOffset = (tzOffset, TZName) => {
    let offsetMins = parseInt(tzOffset.split(':')[0]) * 60 + parseInt(tzOffset.split(':')[1]);

    return { offsetMins };
  };

  toggleDetails = (newView) => {
    this.setState({
      ...this.state,
      detailssection: newView,
      collapseToggle: true,
    });
  };

  handleCollapse = () => {
    this.storyClicked().then(() => {
      let detailrequestState = false;
      if (this.props.story) {
        if (
          !this.props.feedDetails[`${this.props.story.FeedId}`] ||
          !this.props.feedDetails[`${this.props.story.FeedId}`].length
        ) {
          this.props.getStoryDetails(this.props.story.FeedId, 11);
          detailrequestState = true;
        }

        this.setState(
          {
            ...this.state,
            collapseToggle: !this.state.collapseToggle,
            feedDetailsRequest: detailrequestState,
          },
          () => {
            //capture open state with feed id
            // console.log(this.state.collapseToggle)
            this.props.storyopenlogger(this.props.story.FeedId, this.state.collapseToggle);
          }
        );
      }
    });
  };
  viewCollapse = (newViewState) => {
    this.setState({
      ...this.state,
      collapseView: newViewState,
    });
  };

  storyClicked = async (e) => {
    if (!this.state.story.EventReadStatus) {
      const { tdecktype, SavedId } = this.props;
      window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
      window.Mixpanel.MixpanelProps.EventId = this.props.story.FeedId;
      window.Mixpanel.MixpanelProps.Headline = this.props.story.HeadlineText;
      window.Mixpanel.MixpanelProps.ColumnType = tdecktype;
      window.Mixpanel.actions.identify(this.props.userData.username);
      window.Mixpanel.actions.track(`Read Event`, window.Mixpanel.MixpanelProps).then((data) => {
        window.Mixpanel.actions.people.set({
          UserName: this.props.userData.username,
        });
        window.Mixpanel.actions.resetProp('EventId');
        window.Mixpanel.actions.resetProp('Headline');
        window.Mixpanel.actions.resetProp('ColumnType');
      });
      let data = {
        RootId: this.props.story.FeedId,
        ReadStatus: true,
        UserName: this.props.userData.username,
      };
      this.setState({
        ...this.state,
        story: {
          ...this.state.story,
          EventReadStatus: !this.state.EventReadStatus,
        },
      });
      let Saveid = SavedId && SavedId !== '' && SavedId;
      await this.props.updateEventReadStatus(data, tdecktype, Saveid);
      await this.props.activateGlobalTooltipRemover();
      // await this.props.resetTipHandles()
    } else {
      await this.props.resetTipHandles();
    }
  };

  storyOptions = (e) => {
    // console.log(this.state.story)
    this.props.openStoryOptionsMenu(this.state.story);
  };

  storyShare = (e) => {
    // console.log(this.props.id);
    // console.log(document.getElementById(`${this.props.id}`));
    this.props.openStoryShareMenu(this.state.story, this.props.id);
  };

  handleMouseEnter = (e) => {
    window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
    window.Mixpanel.MixpanelProps.EventId = this.state.story.FeedId;
    window.Mixpanel.MixpanelProps.Headline = this.state.story.HeadlineText;
    window.Mixpanel.actions.identify(this.props.userData.username);
    window.Mixpanel.actions.track(`Mouse Over Event`, window.Mixpanel.MixpanelProps).then((data) => {
      window.Mixpanel.actions.people.set({
        UserName: this.props.userData.username,
      });
      window.Mixpanel.actions.resetProp('EventId');
      window.Mixpanel.actions.resetProp('Headline');
    });
  };

  setTimeAgoFromNow = (story) => {
    const { RootChangeLogList, FeedTime, LastUpdateTime, LastFeedsUpdateTime } = story;
    let alertTime;
    let alertType;
    let EventTime;
    let render = this.props.render
    // if (!RootChangeLogList.length) {
    // console.log(story)
    // }
    // if (RootChangeLogList.length) {
    // if(story.FeedId === 49368056) {
    //   console.log(RootChangeLogList)
    // }
    // alertTime = RootChangeLogList[RootChangeLogList.length - 1].RootChangeLogTime;
    EventTime = moment(LastFeedsUpdateTime);
    // console.log(story, EventTime)
    //   switch (RootChangeLogList[RootChangeLogList.length - 1].UpdateTypeCategory.toLowerCase()) {
    //     case 'activate':
    //       alertType = 'Event Raised';
    //       break;
    //     case 'detected':
    //       alertType = 'Signal Raised';
    //       break;
    //     case 'pre trade activate':
    //       alertType = 'Pre trade event confirmed';
    //       break;
    //     case 'details level':
    //       alertType = 'Integrity Updated';
    //       break;
    //     case 'event type':
    //       alertType = 'Event Type Updated';
    //       break;
    //     case 'attributes':
    //       alertType = 'Topics Updated';
    //       break;
    //     case 'location':
    //       alertType = 'Location Updated';
    //       break;
    //     case 'magnitude':
    //       alertType = 'Magnitude Updated';
    //       break;
    //     default:
    //       alertType = 'Major Update';
    //       break;
    //   }
    // } else {
    //   if (alertType === 'Updated') {
    //     alertType = 'Major Update';
    //   } else {
    //     alertType = 'First Feed';
    //   }
    // }
    // // console.log(alertType)
    // if (alertType === 'First Feed') {
    //   // if(story.FeedId === 49368056) {
    //   //   console.log('eventtime is Feed time', FeedTime)
    //   // }

    //   EventTime = moment(FeedTime);
    // } else {
    //   // console.log(alertTime)
    //   if (alertTime) {
    //     // if(story.FeedId === 49368056) {
    //     //   console.log('eventtime is alert time', alertTime)
    //     // }
    //     EventTime = moment(alertTime);
    //   } else {
    //     // if(story.FeedId === 49368056) {
    //     //   console.log('eventtime is LastFeedsUpdateTime', LastFeedsUpdateTime)
    //     // }
    //     EventTime = moment(LastFeedsUpdateTime)
    //   }
    // }
    // if(typeof EventTime === 'undefined') {
    // console.log(story, EventTime)
    // console.log(RootChangeLogList, FeedTime, LastUpdateTime, LastFeedsUpdateTime)
    // }

    // if(story.FeedId === 49368056) {
    //   console.log(LastFeedsUpdateTime)
    //   console.log(moment(LastFeedsUpdateTime))
    //   console.log(moment(EventTime))
    // }
    // let { timeOffset } = this.state;
    // console.log(offsetMins);
    // console.log(LastFeedsUpdateTime)
    // let EventTimeUTC = moment().utc(LastFeedsUpdateTime).utcOffset(0).format();
    // console.log(moment(LastFeedsUpdateTime))
    // if(story.FeedId === 49368056) {
    //   console.log(EventTime.fromNow(true))
    // }
    let TimeChar = EventTime.fromNow(true).split(' ')[0][0] === 'a' ? 1 : EventTime.fromNow(true).split(' ')[0];
    let FromChar = `${EventTime.fromNow(true).split(' ')[1][0]}${EventTime.fromNow(true).split(' ')[1][1]}`;
    const { lang } = this.props;

    // if(FromChar === 'we') { FromChar = 'wk' };
    // console.log('Detected Time',TimeChar.toString(), FromChar.toString())
    let EventTimeAgo = `${TimeChar}${FromChar}`;
    if (EventTimeAgo === '1fe') {
      // EventTimeAgo = '1m';
      TimeChar = 1;
      FromChar = 'mi';
    }

    if (FromChar === 'we') {
      if (lang && lang === 'spa') {
        FromChar = TimeChar === 1 || TimeChar === '1' ? SpanishTranslations['week'] : SpanishTranslations['weeks'];
      } else if (lang && lang === 'jap') {
        FromChar = TimeChar === 1 || TimeChar === '1' ? JapaneseTranslations['week'] : JapaneseTranslations['weeks'];
      } else if (lang && lang === 'ara') {
        FromChar = ArabicTranslations['week'];
      } else if (lang && lang === 'eng') {
        FromChar = TimeChar === 1 || TimeChar === '1' ? 'week' : 'weeks';
      } else if (lang && lang === 'por') {
        FromChar = TimeChar === 1 || TimeChar === '1' ? PortugueseTranslations['week'] : PortugueseTranslations['weeks'];
      } else {
        FromChar = 'w';
      }

    }
    if (FromChar === 'da') {
      if (lang && lang === 'spa') {
        FromChar = TimeChar === 1 || TimeChar === '1' ? SpanishTranslations['day'] : SpanishTranslations['days'];
      } else if (lang && lang === 'jap') {
        FromChar = TimeChar === 1 || TimeChar === '1' ? JapaneseTranslations['day'] : JapaneseTranslations['days'];
      } else if (lang && lang === 'ara') {
        FromChar = ArabicTranslations['days'];
      } else if (lang && lang === 'eng') {
        FromChar = TimeChar === 1 || TimeChar === '1' ? 'day' : 'days';
      } else if (lang && lang === 'por') {
        FromChar = TimeChar === 1 || TimeChar === '1' ? PortugueseTranslations['day'] : PortugueseTranslations['days'];
      } else {
        FromChar = 'd';
      }
    }
    if (FromChar === 'ho') {
      if (lang && lang === 'spa') {
        FromChar = TimeChar === 1 || TimeChar === '1' ? SpanishTranslations['hour'] : SpanishTranslations['hours'];
      } else if (lang && lang === 'jap') {
        FromChar = JapaneseTranslations['hours'];
      } else if (lang && lang === 'ara') {
        FromChar = ArabicTranslations['hours'];
      } else if (lang && lang === 'eng') {
        FromChar = TimeChar === 1 || TimeChar === '1' ? 'hour' : 'hours';
      } else if (lang && lang === 'por') {
        FromChar = TimeChar === 1 || TimeChar === '1' ? PortugueseTranslations['hour'] : PortugueseTranslations['hours'];
      } else {
        FromChar = 'h';
      }
    }
    if (FromChar === 'ye') {
      if (lang && lang === 'spa') {
        FromChar = TimeChar === 1 || TimeChar === '1' ? SpanishTranslations['year'] : SpanishTranslations['years'];
      } else if (lang && lang === 'jap') {
        FromChar = JapaneseTranslations['years'];
      } else if (lang && lang === 'ara') {
        FromChar = TimeChar === 1 || TimeChar === '1' ? ArabicTranslations['year'] : ArabicTranslations['years'];
      } else if (lang && lang === 'eng') {
        FromChar = TimeChar === 1 || TimeChar === '1' ? 'year' : 'years';
      } else if (lang && lang === 'por') {
        FromChar = TimeChar === 1 || TimeChar === '1' ? PortugueseTranslations['year'] : PortugueseTranslations['years'];
      } else {
        FromChar = 'y';
      }
    }
    if (FromChar === 'mi') {
      if (lang && lang === 'spa') {
        FromChar = TimeChar === 1 || TimeChar === '1' ? SpanishTranslations['minute'] : SpanishTranslations['minutes'];
      } else if (lang && lang === 'jap') {
        FromChar = JapaneseTranslations['minutes'];
      } else if (lang && lang === 'ara') {
        FromChar = ArabicTranslations['minutes'];
      } else if (lang && lang === 'eng') {
        FromChar = TimeChar === 1 || TimeChar === '1' ? 'minute' : 'minutes';
      } else if (lang && lang === 'por') {
        FromChar = TimeChar === 1 || TimeChar === '1' ? PortugueseTranslations['minute'] : PortugueseTranslations['minutes'];
      } else {
        FromChar = 'm';
      }
    }



    if (lang && lang === 'spa') {
      EventTimeAgo = `${TimeChar} ${FromChar}`;
    } else if (lang && lang === 'jap') {
      EventTimeAgo = `${TimeChar}${FromChar}`;
    } else if (lang && lang === 'ara') {
      EventTimeAgo = `${TimeChar} ${FromChar}`;
    } else if (lang && lang === 'eng') {
      EventTimeAgo = `${TimeChar} ${FromChar}`;
    } else if (lang && lang === 'por') {
      EventTimeAgo = `${TimeChar} ${FromChar}`;
    } else {
      EventTimeAgo = `${TimeChar}${FromChar}`;
    }
    // console.log(EventTimeAgo)

    // console.log(EventTimeAgo)
    return EventTimeAgo;
  };

  EventUpdateDetected = (story) => {

    let { offsetMins } = NotifyFeedsHandler.TimeZoneOffsetSettings();
    let DetectedTime = moment(story.FeedTime);
    const { lang } = this.props;
    // console.log(newChangesArr, DetectedTime)

    let TimeChar = DetectedTime.fromNow(true).split(' ')[0][0] === 'a' ? 1 : DetectedTime.fromNow(true).split(' ')[0];
    let FromChar = `${DetectedTime.fromNow(true).split(' ')[1][0]}${DetectedTime.fromNow(true).split(' ')[1][1]}`;
    // if(FromChar === 'we') { FromChar = 'wk' };
    // console.log('Updated Time',DetectedTime, TimeChar.toString(), FromChar.toString())
    let DetectedTimeAgo = `${TimeChar}${FromChar}`;
    // console.log(EventTimeAgo)
    if (DetectedTimeAgo === '1fe') {
      // DetectedTimeAgo = '1m';
      TimeChar = 1;
      FromChar = 'mi';
    }

    if (FromChar === 'we') {
      if (lang && lang === 'spa') {
        FromChar = TimeChar === 1 || TimeChar === '1' ? SpanishTranslations['week'] : SpanishTranslations['weeks'];
      } else if (lang && lang === 'jap') {
        FromChar = TimeChar === 1 || TimeChar === '1' ? JapaneseTranslations['week'] : JapaneseTranslations['weeks'];
      } else if (lang && lang === 'ara') {
        FromChar = ArabicTranslations['week'];
      } else if (lang && lang === 'eng') {
        FromChar = TimeChar === 1 || TimeChar === '1' ? 'week' : 'weeks';
      } else if (lang && lang === 'por') {
        FromChar = TimeChar === 1 || TimeChar === '1' ? PortugueseTranslations['week'] : PortugueseTranslations['weeks'];
      } else {
        FromChar = 'w';
      }
    }
    if (FromChar === 'da') {
      if (lang && lang === 'spa') {
        FromChar = TimeChar === 1 || TimeChar === '1' ? SpanishTranslations['day'] : SpanishTranslations['days'];
      } else if (lang && lang === 'jap') {
        FromChar = TimeChar === 1 || TimeChar === '1' ? JapaneseTranslations['day'] : JapaneseTranslations['days'];
      } else if (lang && lang === 'ara') {
        FromChar = ArabicTranslations['days'];
      } else if (lang && lang === 'eng') {
        FromChar = TimeChar === 1 || TimeChar === '1' ? 'day' : 'days';
      } else if (lang && lang === 'por') {
        FromChar = TimeChar === 1 || TimeChar === '1' ? PortugueseTranslations['day'] : PortugueseTranslations['days'];
      } else {
        FromChar = 'd';
      }
    }
    if (FromChar === 'ho') {
      if (lang && lang === 'spa') {
        FromChar = TimeChar === 1 || TimeChar === '1' ? SpanishTranslations['hour'] : SpanishTranslations['hours'];
      } else if (lang && lang === 'jap') {
        FromChar = JapaneseTranslations['hours'];
      } else if (lang && lang === 'ara') {
        FromChar = ArabicTranslations['hours'];
      } else if (lang && lang === 'eng') {
        FromChar = TimeChar === 1 || TimeChar === '1' ? 'hour' : 'hours';
      } else if (lang && lang === 'por') {
        FromChar = TimeChar === 1 || TimeChar === '1' ? PortugueseTranslations['hour'] : PortugueseTranslations['hours'];
      } else {
        FromChar = 'h';
      }
    }
    if (FromChar === 'ye') {
      if (lang && lang === 'spa') {
        FromChar = TimeChar === 1 || TimeChar === '1' ? SpanishTranslations['year'] : SpanishTranslations['years'];
      } else if (lang && lang === 'jap') {
        FromChar = JapaneseTranslations['years'];
      } else if (lang && lang === 'ara') {
        FromChar = TimeChar === 1 || TimeChar === '1' ? ArabicTranslations['year'] : ArabicTranslations['years'];
      } else if (lang && lang === 'eng') {
        FromChar = TimeChar === 1 || TimeChar === '1' ? 'year' : 'years';
      } else if (lang && lang === 'por') {
        FromChar = TimeChar === 1 || TimeChar === '1' ? PortugueseTranslations['year'] : PortugueseTranslations['years'];
      } else {
        FromChar = 'y';
      }
    }
    if (FromChar === 'mi') {
      if (lang && lang === 'spa') {
        FromChar = TimeChar === 1 || TimeChar === '1' ? SpanishTranslations['minute'] : SpanishTranslations['minutes'];
      } else if (lang && lang === 'jap') {
        FromChar = JapaneseTranslations['minutes'];
      } else if (lang && lang === 'ara') {
        FromChar = ArabicTranslations['minutes'];
      } else if (lang && lang === 'eng') {
        FromChar = TimeChar === 1 || TimeChar === '1' ? 'minute' : 'minutes';
      } else if (lang && lang === 'por') {
        FromChar = TimeChar === 1 || TimeChar === '1' ? PortugueseTranslations['minute'] : PortugueseTranslations['minute'];
      } else {
        FromChar = 'm';
      }
    }

    if (lang && lang === 'spa') {
      DetectedTimeAgo = `${TimeChar} ${FromChar}`;
    } else if (lang && lang === 'jap') {
      DetectedTimeAgo = `${TimeChar}${FromChar}`;
    } else if (lang && lang === 'ara') {
      DetectedTimeAgo = `${TimeChar} ${FromChar}`;
    } else if (lang && lang === 'eng') {
      DetectedTimeAgo = `${TimeChar} ${FromChar}`;
    } else if (lang && lang === 'por') {
      DetectedTimeAgo = `${TimeChar} ${FromChar}`;
    } else {
      DetectedTimeAgo = `${TimeChar}${FromChar}`;
    }


    return DetectedTimeAgo;
  }

  ExtractSentiment_Source = (story, type = 'Sentiment') => {
    let LocationString = story.Location
    if (LocationString !== null) {
      let items = LocationString.split(';')
      let SentimentTotal = null;
      let Sources = [];
      if (items.length) {
        items.forEach((el, i) => {
          // console.log(el)
          let key = el.split(':')[0]
          let value = el.split(':')[1]
          if (key === 'SentimentTotal') {
            SentimentTotal = value
          } else if (key === 'Source') {
            Sources = value.split(',')
          }
        })
      }
      if (type === 'Sentiment') {
        if (typeof SentimentTotal === 'undefined' || isNaN(SentimentTotal)) {
          SentimentTotal = null
        }
        if (SentimentTotal !== null) {
          return parseFloat(SentimentTotal);
        } else {
          return SentimentTotal;
        }

      } else if (type === 'Source') {
        return Sources;
      }
      // console.log(Sources, SentimentTotal)
      // return { Source : Sources, Sentiment: SentimentTotal }
    }


  }
  toggleMagtagtip = (status, tipText = false) => {
    if (!isMobile) {


      // let ContainerRect = this.MagTag.getBoundingClientRect();
      // let Pos = {
      //   left: ContainerRect.left - 12,
      //   top: ContainerRect.bottom + 10,
      // };
      if (this.SecMagttHandle) {
        clearTimeout(this.SecMagttHandle);
      }
      // const containerStyleClass = `infoTip normal-left blue ${this.props.Theme === 'light' ? 'lightmode' : ''}`;
      if (status) {
        this.SecMagttHandle = setTimeout(() => {
          let arrowstyledata = {};
          if (this.props.lang && this.props.lang === 'ara') {
            arrowstyledata.marginRight = '10px';
          } else {
            arrowstyledata.marginLeft = '10px';
          }
          let ToolTipStyleData = {
            Arrow: { ...arrowstyledata },
            ToolTip: {
              align: this.props.lang && this.props.lang === 'ara' ? 'right' : 'left',
            },
          };
          this.props.toggleOverlay(
            status,
            this.MagTag,
            tipText,
            ToolTipStyleData
          );

        }, 1500);
      } else {
        this.props.toggleOverlay(false)
      }
    }
  }
  toggleMagtagtipMobile = (status, tipText = false) => {
    if (isMobile) {

      // let ContainerRect = this.MagTag.getBoundingClientRect();
      // let Pos = {
      //   left: ContainerRect.left - 12,
      //   top: ContainerRect.bottom + 10,
      // };

      // const containerStyleClass = `infoTip normal-left blue ${this.props.Theme === 'light' ? 'lightmode' : ''}`;
      // if(this.SecMagttHandleMobile) {
      //   clearTimeout(this.SecMagttHandleMobile);
      // }
      if (status) {

        // this.SecMagttHandleMobile = setTimeout(() => {
        // this.props.togglePopup(status, tipText, Pos, containerStyleClass);
        let arrowstyledata = {};
        if (this.props.lang && this.props.lang === 'ara') {
          arrowstyledata.marginRight = '10px';
        } else {
          arrowstyledata.marginLeft = '10px';
        }
        let ToolTipStyleData = {
          Arrow: { ...arrowstyledata },
          ToolTip: {
            align: this.props.lang && this.props.lang === 'ara' ? 'right' : 'left',
          },
        };
        this.props.toggleOverlay(
          status,
          this.MagTag,
          tipText,
          ToolTipStyleData
        );
        // },1000)

      }
      // else {
      //   this.props.togglePopup(status);
      // }
    }
  }
  toggleSectagtip = (status, tipText = false) => {
    if (!isMobile) {
      // let ContainerRect = this.SecTag.getBoundingClientRect();
      // let Pos = {
      //   left: ContainerRect.left - 12,
      //   top: ContainerRect.bottom + 15,
      // };
      if (this.SecTagttHandle) {
        clearTimeout(this.SecTagttHandle);
      }
      // const containerStyleClass = `infoTip normal-left blue ${this.props.Theme === 'light' ? 'lightmode' : ''}`;
      if (status) {
        this.SecTagttHandle = setTimeout(() => {
          let arrowstyledata = {};
          if (this.props.lang && this.props.lang === 'ara') {
            arrowstyledata.marginRight = '10px';
          } else {
            arrowstyledata.marginLeft = '10px';
          }
          let ToolTipStyleData = {
            Arrow: { ...arrowstyledata },
            ToolTip: {
              align: this.props.lang && this.props.lang === 'ara' ? 'right' : 'left',
            },
          };
          this.props.toggleOverlay(
            status,
            this.SecTag,
            tipText,
            ToolTipStyleData
          );
          // this.props.togglePopup(status, tipText, Pos, containerStyleClass);
          // window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
          // window.Mixpanel.MixpanelProps.EventId = this.props.FeedId;
          // window.Mixpanel.MixpanelProps.Headline = this.props.HeadlineText;
          // window.Mixpanel.MixpanelProps.ToolTipType = 'Resource Indicator';
          // window.Mixpanel.actions.identify(this.props.userData.username);
          // window.Mixpanel.actions.track(`Tooltip shown`, window.Mixpanel.MixpanelProps).then((data) => {
          //   window.Mixpanel.actions.people.set({
          //     UserName: this.props.userData.username,
          //   });
          //   window.Mixpanel.actions.resetProp('EventId');
          //   window.Mixpanel.actions.resetProp('Headline');
          //   window.Mixpanel.actions.resetProp('ToolTipType');
          // });
        }, 1500);
      } else {
        this.props.toggleOverlay(status);
      }
    }

  }
  toggleSectagtipMobile = (status, tipText = false) => {
    if (isMobile) {
      // let ContainerRect = this.SecTag.getBoundingClientRect();
      // let Pos = {
      //   left: ContainerRect.left - 12,
      //   top: ContainerRect.bottom + 10,
      // };

      // const containerStyleClass = `infoTip normal-left blue ${this.props.Theme === 'light' ? 'lightmode' : ''}`;
      if (status) {

        // this.props.togglePopup(status, tipText, Pos, containerStyleClass);
        let arrowstyledata = {};
        if (this.props.lang && this.props.lang === 'ara') {
          arrowstyledata.marginRight = '10px';
        } else {
          arrowstyledata.marginLeft = '10px';
        }
        let ToolTipStyleData = {
          Arrow: { ...arrowstyledata },
          ToolTip: {
            align: this.props.lang && this.props.lang === 'ara' ? 'right' : 'left',
          },
        };
        this.props.toggleOverlay(
          status,
          this.SecTag,
          tipText,
          ToolTipStyleData
        );

      }
      // else {
      //   this.props.togglePopup(status);
      // }
    }
  }
  toggletrendtipMobile = (status, tipText = false) => {
    if (isMobile) {
      // let ContainerRect = this.trend.getBoundingClientRect();
      // let Pos = {
      //   left: ContainerRect.left - 12,
      //   top: ContainerRect.bottom + 10,
      // };

      // const containerStyleClass = `infoTip normal-left blue ${this.props.Theme === 'light' ? 'lightmode' : ''}`;
      if (status) {
        let arrowstyledata = {};
        if (this.props.lang && this.props.lang === 'ara') {
          arrowstyledata.marginRight = '210%';
        } else {
          arrowstyledata.marginLeft = '55%';
        }
        let ToolTipStyleData = {
          Arrow: { ...arrowstyledata },
          ToolTip: {
            align: this.props.lang && this.props.lang === 'ara' ? 'right' : 'left',
          },
        };
        this.props.toggleOverlay(
          status,
          this.trend,
          tipText,
          ToolTipStyleData
        );
        // this.props.togglePopup(status, tipText, Pos, containerStyleClass);


      }
      // else {
      //   this.props.togglePopup(status);
      // }
    }

  }
  toggletrendtip = (status, tipText = false) => {
    if (!isMobile) {
      // let ContainerRect = this.trend.getBoundingClientRect();
      // let Pos = {
      //   left: ContainerRect.left - 12,
      //   top: ContainerRect.bottom + 10,
      // };
      if (this.trendttHandle) {
        clearTimeout(this.trendttHandle);
      }
      // const containerStyleClass = `infoTip normal-left blue ${this.props.Theme === 'light' ? 'lightmode' : ''}`;
      if (status) {
        this.trendttHandle = setTimeout(() => {
          let arrowstyledata = {};
          if (this.props.lang && this.props.lang === 'ara') {
            arrowstyledata.marginRight = '210%';
          } else {
            arrowstyledata.marginLeft = '55%';
          }
          let ToolTipStyleData = {
            Arrow: { ...arrowstyledata },
            ToolTip: {
              align: this.props.lang && this.props.lang === 'ara' ? 'right' : 'left',
            },
          };
          this.props.toggleOverlay(
            status,
            this.trend,
            tipText,
            ToolTipStyleData
          );
          // this.props.togglePopup(status, tipText, Pos, containerStyleClass);
          // window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
          // window.Mixpanel.MixpanelProps.EventId = this.props.FeedId;
          // window.Mixpanel.MixpanelProps.Headline = this.props.HeadlineText;
          // window.Mixpanel.MixpanelProps.ToolTipType = 'Resource Indicator';
          // window.Mixpanel.actions.identify(this.props.userData.username);
          // window.Mixpanel.actions.track(`Tooltip shown`, window.Mixpanel.MixpanelProps).then((data) => {
          //   window.Mixpanel.actions.people.set({
          //     UserName: this.props.userData.username,
          //   });
          //   window.Mixpanel.actions.resetProp('EventId');
          //   window.Mixpanel.actions.resetProp('Headline');
          //   window.Mixpanel.actions.resetProp('ToolTipType');
          // });
        }, 1500);
      } else {
        this.props.toggleOverlay(status);
      }
    }

  }
  securityTag = (security, securitySymbol, Sentiment, SentimentIndicator) => {
    // console.log(security)
    if (security === 'BTC') {
      security = "Bitcoin";
    } else if (security === 'ETH') {
      security = "Ethereum"
    } else if (security === 'SOL') {
      security = 'Solana'
    } else if (security === 'DOGE') {
      security = 'DogeCoin'
    } else if (security === 'SHIB') {
      security = 'Shiba INU'
    } else if (security === 'BCH') {
      security = 'Bitcoin Cash'
    } else if (security === 'ICP') {
      security = 'Internet Computer'
    } else if (security === 'USDT') {
      security = 'Tether USDt'
    } else if (security === 'BNB') {
      security = 'BNB'
    } else if (security === 'USDC') {
      security = 'USD Coin'
    } else if (security === 'XRP') {
      security = 'XRP'
    } else if (security === 'TONCOIN') {
      security = 'Toncoin'
    } else if (security === 'ADA') {
      security = 'Cardano'
    } else if (security === 'TRX') {
      security = 'Tron'
    } else if (security === 'AVAX') {
      security = 'Avalanche'
    } else if (security === 'SHIB') {
      security = 'SHIBA INU'
    } else if (security === 'DOT') {
      security = 'Polkadot'
    } else if (security === 'LINK') {
      security = 'Chainlink'
    } else if (security === 'MATIC') {
      security = 'Polygon'
    }
    // let tiptext =  ''
    // if(Sentiment !== null && typeof Sentiment !== 'undefined' && !isNaN(Sentiment) && SentimentIndicator !== '') {
    //   if(SentimentIndicator === 'neg_sentiment.svg') {
    //     tiptext = 'Negative AI sentiment for event'
    //   } else if(SentimentIndicator === 'pos_sentiment.svg') {
    //     tiptext = 'Positive AI sentiment for event'
    //   }
    // } else {
    //   tiptext = 'Asset related to this event'
    // }

    // let MagnitudeRate = this.state.story.MagnitudeRate;
    // let iframeclickable = false;
    // if(MagnitudeRate >= 1) {
    //   if(Sentiment !== null && typeof Sentiment !== 'undefined' && !isNaN(Sentiment) && SentimentIndicator !== '') {
    //     iframeclickable = true
    //   }
    // }

    // ${this.props.render === 'iframe' ? 'iframe' : ''} ${sentimentbackground}
    let tiptext = '';
    if (this.props.lang && this.props.lang === 'spa') {
      tiptext = "Activo negociable correlacionado con este evento de IA."
    } else if (this.props.lang && this.props.lang === 'por') {
      tiptext = "Ativo negociável correlacionado com este evento de IA."
    } else if (this.props.lang && this.props.lang === 'jap') {
      tiptext = 'このAIイベントと相関する取引可能な資産'
    } else if (this.props.lang && this.props.lang === 'ara') {
      tiptext = "الأصل المتداول المرتبط بهذا الحدث الذكاء الاصطناعي"
    } else {
      tiptext = 'Tradeable asset correlated with this AI event'
    }
    let iconSymbol =
      security !== '' ? (
        <span
          onContextMenu={(e) => {
            e.preventDefault();
            e.stopPropagation(); // not necessary in my case, could leave in case stopImmediateProp isn't available? 
            // e.stopImmediatePropagation();
            return false;
          }}
          className={`preventselect tickhover  bg-symbol bold ${this.props.Theme === 'light' ? 'lightmode' : ''} ${this.props.render !== 'iframe' ? 'text-blue-primary01' : ''} ${this.props.iframenav && !this.props.iframeasset ? 'iframenavlink' : ''} `}
          ref={this.SecTag}
          onMouseEnter={(e) => {
            this.toggleSectagtip(true, tiptext)
          }}
          onMouseLeave={(e) => {
            this.toggleSectagtip(false)
          }}
          // onTouchStart={(e) => {
          //   // e.preventDefault();
          //   e.stopPropagation();
          //   if(isMobile) {
          //     // this.assettimerhandle = setTimeout((e) => {

          //       this.toggleSectagtipMobile(true, tiptext)
          //     // }, 300)
          //   }



          // }}
          // onTouchEnd={(e) => {
          //   // e.preventDefault();
          //   e.stopPropagation();
          //   if(this.assettimerhandle) {
          //     clearTimeout(this.assettimerhandle);
          //   }
          //   // console.log('touch end')
          // }}
          onClick={(e) => {
            if (this.props.render !== 'iframe') {
              e.stopPropagation();
              window.showCoinColumnFromText(security, securitySymbol, this.props.listkey);
            } else {
              if (this.props.userData.loginlessuser === 'Iforex') {
                // if (isMobile) {
                //   this.toggleSectagtipMobile(true, tiptext)
                // }
                if (this.props.iframenav) {
                  window.showCoinColumnFromText(security, securitySymbol);
                }
              } else {
                // console.log(security, securitySymbol)
                if (this.props.iframenav) {
                  window.showCoinColumnFromText(security, securitySymbol);
                }

              }

            }
            // else if(this.props.render === 'iframe') {
            //   if(iframeclickable) {
            //     let Direction = '';
            //     if(Sentiment <= 3.5) { 
            //       Direction = 'Sell';
            //     } else if(Sentiment >= 6.5) {
            //       Direction = 'Buy'
            //     }
            //     e.stopPropagation();
            //     window.showCoinColumnFromText(security, securitySymbol, false, false,  Direction);
            //   }
            // }

          }}
        >

          <img
            src={`${APPPATH.CoinIconUrl}/${security !== null ? security.toLowerCase().trim().replace(/ /g, '_') : ''
              }.png`}
            className="coinicon"
            alt="CrowdSense.ai"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = this.props.userData.usertype === 'None' ? `${APPPATH.ImageUrl}/logo_hdr.png` : this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin' ? `${APPPATH.ImageUrl}/companygenerallogo.png` : `${APPPATH.ImageUrl}/companygenerallogo.png`
            }}
          />


          {` ${securitySymbol}`}

        </span>
      ) : (
        ''
      );
    return iconSymbol;
  };
  extraSecurityTag = (extraSecurity) => {
    const count = extraSecurity.length;
    var temp = (
      <span
        id={`extraTag${this.props.listkey}${this.props.feedId}`}
        ref={(el) => (this.extraSecTag = el)}
        onMouseEnter={(e) => {
          this.toggleExtraSectagtip(true)
          // let ToolTipStyleData = {
          //   Arrow: {
          //     marginLeft: '10px',
          //   },
          //   ToolTip: {
          //     align: 'left',
          //   },
          // };
          // this.props.toggleOverlay(true, this.SecTag, tiptext, ToolTipStyleData);
        }}
        onMouseLeave={(e) => {
          // this.props.toggleOverlay(false);
          this.toggleExtraSectagtip(false)
        }}
        onClick={(e) => {
          this.toggleExtraSectagtip(false);
          this.clickToSeeExtraCoins(e, extraSecurity, `${this.props.listkey}${this.props.feedId}`, this.state.toggleExtraCoins)
        }}
        className="tickhover margr2  padlr5tb2 bg-symbol"
      >
        {'+'}
        {count}
      </span>
    );
    return temp;
  };

  toggleExtraSectagtip = (status) => {
    if (!isMobile) {
      let ContainerRect = this.extraSecTag.getBoundingClientRect();
      let Pos = {
        left: ContainerRect.left - 15,
        top: ContainerRect.bottom + 8,
      };
      if (this.ExtraSecTagttHandle) {
        clearTimeout(this.ExtraSecTagttHandle);
      }
      let tipText = 'More Related Assets'
      const containerStyleClass = `infoTip normal-left blue ${this.props.Theme === 'light' ? 'lightmode' : ''}`;
      if (status) {
        this.ExtraSecTagttHandle = setTimeout(() => {
          this.props.togglePopup(status, tipText, Pos, containerStyleClass);
          // window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
          // window.Mixpanel.MixpanelProps.EventId = this.props.FeedId;
          // window.Mixpanel.MixpanelProps.Headline = this.props.HeadlineText;
          // window.Mixpanel.MixpanelProps.ToolTipType = 'Resource Indicator';
          // window.Mixpanel.actions.identify(this.props.userData.username);
          // window.Mixpanel.actions.track(`Tooltip shown`, window.Mixpanel.MixpanelProps).then((data) => {
          //   window.Mixpanel.actions.people.set({
          //     UserName: this.props.userData.username,
          //   });
          //   window.Mixpanel.actions.resetProp('EventId');
          //   window.Mixpanel.actions.resetProp('Headline');
          //   window.Mixpanel.actions.resetProp('ToolTipType');
          // });
        }, 1500);
      } else {
        this.props.togglePopup(status);
      }
    }

  }

  clickToSeeExtraCoins = (e, extraSecurityTags, feedId, toggleFlag = false) => {
    e.stopPropagation();
    var extraTagElement = document.getElementById(`extraTag${feedId}`);
    if (extraTagElement) {
      let ContainerRect = extraTagElement.getBoundingClientRect();
      let mousePosition = {
        left: ContainerRect.x - 100,
        top: ContainerRect.bottom + 15,
      };
      let balCRLList = [];
      extraSecurityTags.forEach((item, i) => {
        if (item.Impact !== 'evttime' && item.Impact !== 'feeds' && item.Impact !== 'usrReach') {
          let tmp = (
            <Row className={`tipItem marg-0 h-100`} key={i}>
              <CorelTag each={item} tagicon={this.tagIcon(item)} tagcontent={this.tagContent(item)} {...this.props} />
            </Row>
          );
          balCRLList.push(tmp);
        }
      });
      let balCRLCountList = <div className={``}>{balCRLList}</div>;
      let containerClass = `crl-tooltipMenu ${this.props.Theme === 'light' ? 'lightmode' : ''}`;
      // console.log(!this.state.toggleExtraCoins, balCRLCountList, mousePosition, containerClass)
      this.props.togglePopup(!this.state.toggleExtraCoins, balCRLCountList, mousePosition, containerClass);
      this.setState({
        toggleExtraCoins: !this.state.toggleExtraCoins,
      });
    }
  };

  extraSecurityCount = (corelsArray) => {
    var extrasecurityTags = [];
    for (let corelCount = 0; corelCount < corelsArray.length; corelCount++) {
      if (corelCount >= 1) {
        extrasecurityTags.push(corelsArray[corelCount]);
      }
    }
    //balCRLCountList = <div className={``}>{balCRLList}</div>;
    return extrasecurityTags;
  };

  tagContent = (item) => <span className="coreltext fs12  fssmsec">{item.Symbol}</span>;
  tagIcon = (Item) => (
    <span>
      {/* <object
        className="timelineIndicator imground"
        data={`${APPPATH.CoinIconUrl}/${
          Item.Security !== null ? Item.Security.toLowerCase().trim().replace(/ /g, '_') : ''
        }.png`}
        type="image/png"
      > */}
      <img
        src={`${APPPATH.CoinIconUrl}/${Item.Security !== null ? Item.Security.toLowerCase().trim().replace(/ /g, '_') : ''
          }.png`}
        className="timelineIndicator imground"
        alt="CrowdSense.ai"
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = this.props.userData.usertype === 'None' ? `${APPPATH.ImageUrl}/logo_hdr.png` : this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin' ? `${APPPATH.ImageUrl}/companygenerallogo.png` : `${APPPATH.ImageUrl}/companygenerallogo.png`
        }}
      />
      {/* </object> */}
    </span>
  );

  FeedMagnitudeLevel = (Active, Curated, MagnitudeRate) => {
    let DetailsLevel = '';
    if (!Active && Curated) {
      DetailsLevel = 'gray';
    } else if (!Active && !Curated) {
      DetailsLevel = 'black';
    } else {
      switch (MagnitudeRate) {
        case 1:
          DetailsLevel = 'yellow';
          // magnitudeBg = !EventReadStatus && BlinkNotifyEvent ? 'blink-bg-medium' : 'mag-bg-medium';
          break;
        case 2:
          DetailsLevel = 'orange';
          // magnitudeBg = !EventReadStatus && BlinkNotifyEvent ? 'blink-bg-high' : 'mag-bg-high';
          break;
        case 3:
          DetailsLevel = 'red';
          // magnitudeBg = !EventReadStatus && BlinkNotifyEvent ? 'blink-bg-high' : 'mag-bg-high';
          break;
        default:
          DetailsLevel = 'white';
          // magnitudeBg = !EventReadStatus && BlinkNotifyEvent ? 'blink-bg-default' : 'mag-bg-default';
          break;
      }
    }

    return DetailsLevel;
  };

  render() {
    let {
      story,
      preferencesSecuritiesLine,
      preferencesLocationRelatedCountries,
      NaceUpdated,
      NaceUpdatedOld,
      withBlackUpdatedOld,
      withBlackUpdated,
      withCuratedUpdated,
      withCuratedUpdatedOld,
    } = this.state;
    let EventReadStatus = story !== null ? story.EventReadStatus : true;
    let SecuritySymbol = '';
    let Security = '';
    // let SecuritySymbolTickerIcon = '';
    const { lang } = this.props;
    let DetailsLevel = 0;
    let feedDetails =
      story !== null ? (
        <StoryDetails
          {...this.props}
          collapse={this.state.collapseToggle}
          collapsesection={'feed'}
          collapseview={this.viewCollapse}
          handleCollapse={this.handleCollapse}
          feedDetailsRequest={this.state.feedDetailsRequest}
          story={this.state.story}
          EventReadStatus={EventReadStatus}
          timeOffset={this.state.timeOffset}
          Theme={this.props.Theme}
          Reports={this.props.lang === false ? story.Reports : story.NumberOfFeeds}
        />
      ) : (
        ''
      );

    if (story !== null && story.CorrelationResults) {
      // console.log(story.CorrelationResults)
      let securities = story.CorrelationResults.map((itm) => ({
        SecuritySymbol: itm.Symbol,
        Security: itm.Security,
      })).filter((itm) => itm);

      // let uniqueFeedIds = Array.from(new Set(feedIds))
      if (story.CorrelationResults.length === 1 && securities.length) {
        SecuritySymbol = securities[0].SecuritySymbol;
        Security = securities[0].Security;
      }
    }
    // if(story && story.FeedId === 49344334) {
    //   console.log(story)
    // }

    let detailsSection =
      story !== null ? (
        // story.Active ? (
        // preferencesSecuritiesLine || preferencesLocationRelatedCountries ? (
        <SecurityDetails
          {...this.props}
          collapse={this.state.collapseToggle}
          collapsesection={'security'}
          collapseview={this.viewCollapse}
          handleCollapse={this.handleCollapse}
          correlationResults={story.CorrelationResults}
          Locations={story.Location}
          EventReadStatus={EventReadStatus}
          preferencesSecuritiesLine={preferencesSecuritiesLine}
          preferencesLocationRelatedCountries={preferencesLocationRelatedCountries}
          GeoLocation={story.GeoLocation}
          GeoMarkers={story.EventMapMarkers}
          // Event_Collapsed_View={this.props.Event_Collapsed_View}
          RootChangeLogList={story.RootChangeLogList}
          StoryFeedTime={story.TZ_FeedTime}
          StoryLastFeedsUpdateTime={story.TZ_LastFeedsUpdateTime}
          Resources={this.props.lang === false ? story.Resources : story.Resources.split(',').length}
          Reports={this.props.lang === false ? story.Reports : story.NumberOfFeeds}
          EventType={story.EventType}
          UserReputationRate={this.props.lang === false ? story.UserReputationRate : story.EventUserReputationRate}
          storyOptions={this.storyOptions}
          storyShare={this.storyShare}
          storyID={story.FeedId}
          tmOffset={this.state.timeOffset}
          story={story}
          Theme={this.props.Theme}
          render={this.props.render}
          lang={lang}
        />
      ) : (
        // ) : (
        //   ''
        // )
        ''
      );
    let mapSection =
      story !== null && !this.state.collapseToggle ? (
        story.GeoLocation !== '' && preferencesLocationRelatedCountries ? (
          <StoryMaps
            GMAPKEY={this.props.googleMapKey}
            GeoLocations={story.GeoLocation}
            GeoLocationsRadius={story.Radius}
            GeoMarkers={story.EventMapMarkers}
            collapse={this.state.collapseToggle}
          />
        ) : (
          ''
        )
      ) : (
        ''
      );
    // console.log(this.props.initDataRequest)
    if (story !== null) {
      // let now = moment();
      // let FeedTime = moment(story.FeedTime.toString());
      // console.log(this.props.columnCollapse)
      let EventActive = story.Active;
      let EventCurated = story.EventCurated;
      let MagnitudeRate = story.MagnitudeRate;
      let brdrbg = '';

      // if(story.EventType.toLowerCase().trim().includes('followers post')) {
      //   // console.log(story.EventType)
      //   brdrbg = 'event-brdr-special';
      // } else {
      if (!EventActive && EventCurated) {
        brdrbg = 'event-brdr-gray';
        DetailsLevel = 1;
      } else if (!EventActive && !EventCurated) {
        brdrbg = 'event-brdr-black';
        DetailsLevel = 0;
      } else {
        // console.log(MagnitudeRate)
        switch (MagnitudeRate) {
          case 1:
            brdrbg = 'event-brdr-medium';
            DetailsLevel = 3;
            // magnitudeBg = !EventReadStatus && BlinkNotifyEvent ? 'blink-bg-medium' : 'mag-bg-medium';
            break;
          case 2:
            brdrbg = 'event-brdr-high';
            DetailsLevel = 4;
            // magnitudeBg = !EventReadStatus && BlinkNotifyEvent ? 'blink-bg-high' : 'mag-bg-high';
            break;
          case 3:
            brdrbg = 'event-brdr-higher';
            DetailsLevel = 5;
            // magnitudeBg = !EventReadStatus && BlinkNotifyEvent ? 'blink-bg-high' : 'mag-bg-high';
            break;
          default:
            brdrbg = 'event-brdr-default';
            DetailsLevel = 2;
            // magnitudeBg = !EventReadStatus && BlinkNotifyEvent ? 'blink-bg-default' : 'mag-bg-default';
            break;
        }
      }
      const EventTimeAgo = `${this.setTimeAgoFromNow(story)}`;
      const DetectedTimeAgo = `${this.EventUpdateDetected(story)}`;

      // console.log('updated',EventTimeAgo)
      // console.log('detected',DetectedTimeAgo)
      // if(lang && lang === 'ara') {
      //   if(EventTimeAgo === DetectedTimeAgo) {
      //     console.log('updated',EventTimeAgo)
      //     console.log('detected',DetectedTimeAgo)
      //   }
      // }

      let Sentiment = this.ExtractSentiment_Source(story)
      let SentimentIndicator = ''
      if (Sentiment !== null && typeof Sentiment !== 'undefined' && !isNaN(Sentiment) && parseInt(story.MagnitudeRate) > 0) {
        if (Sentiment <= 3.5) {
          SentimentIndicator = 'neg_sentiment.svg'
        } else if (Sentiment >= 6.5) {
          SentimentIndicator = 'pos_sentiment.svg'
        }
      }

      // console.log(this.props.securitiesFilter)
      let watchlistfilteredcorrelations = [];
      let coins = '';
      story.CorrelationResults.forEach((corels, i) => {
        // if(this.props.story.FeedId === 49277420) {
        //   console.log(corels, this.props.securitiesFilter)
        // }

        if (this.props.render === 'iframe') {
          watchlistfilteredcorrelations.push(corels);
        } else {

          if (corels.Security !== null) {
            if (this.props.securitiesFilter.indexOf(corels.Security) > -1) {
              watchlistfilteredcorrelations.push(corels);
            } else if (this.props.SecurityName === corels.Security) {
              watchlistfilteredcorrelations.push(corels);
            } else if (this.props.securitiesFilter.indexOf(' Semrush') > -1 && corels.Security === 'Semrush') {
              watchlistfilteredcorrelations.push(corels);
            }
          }
        }

        // console.log(watchlistfilteredcorrelations)
      })

      if (watchlistfilteredcorrelations.length === 1 || this.props.render === 'iframe') {
        coins = <span>{this.securityTag(watchlistfilteredcorrelations[0].Security, watchlistfilteredcorrelations[0].CustomSymbol || watchlistfilteredcorrelations[0].Symbol, Sentiment, SentimentIndicator)}</span>;
      } else if (watchlistfilteredcorrelations.length > 1) {
        coins = (
          <span>
            {this.securityTag(watchlistfilteredcorrelations[0].Security, watchlistfilteredcorrelations[0].CustomSymbol || watchlistfilteredcorrelations[0].Symbol, this.props.StorySentiment, this.props.StorySentimentIndicator)}
            {/* {this.securityTag(watchlistfilteredcorrelations[1].Security, watchlistfilteredcorrelations[1].Symbol)} */}
            {this.extraSecurityTag(this.extraSecurityCount(watchlistfilteredcorrelations))}
          </span>
        );
      }

      let headlineText = this.props.lang === false ? story.HeadlineText : story.HeadlineTextAdmin
      let actualtext = headlineText.split('|')[0];
      let explanationtext = typeof headlineText.split('|')[1] !== 'undefined' && headlineText.split('|')[1] !== '' ? headlineText.split('|')[1] : false
      // ${brdrbg}
      // console.log(DetailsLevel)
      return (
        <div className={`storyCardHover ${this.props.render === 'iframe' ? 'iframe' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>
          <Col
            lg={12}
            sm={12}
            xs={12}
            md={12}
            xl={12}
            id={story.FeedId}
            // onClick={this.storyClicked}
            onMouseEnter={this.handleMouseEnter}
            className={`story-card bg-content ${this.props.Theme === 'light' ? 'lightmode' : ''} ${EventReadStatus ? '' : 'bg-story-unread'} ${this.state.collapseToggle ? 'padb10' : ''
              } `}
          >
            <Row className={`marg-0 `} style={{ padding: '10px 10px 0px 10px' }}>
              <Col lg={6} sm={6} xs={6} md={6} xl={6}

                className={`nopad  ${lang && lang === 'ara' ? 'text-right jutsify-content-right' : 'text-left jutsify-content-left'} 
                  
                `}
              >
                {/* <StoryToggleBar
                  toggleDet={this.toggleDetails}
                  TDeckColWidth={this.props.TDeckColWidth}
                  userData={this.props.userData}
                  story={this.state.story}
                  storyClicked={this.storyClicked}
                  EventReadStatus={EventReadStatus}
                  togglePopup={this.props.togglePopup}
                  toggleOverlay={this.props.toggleOverlay}
                  tabmode={this.props.tabmode}
                  colLength={this.props.colLength}
                /> */}
                <span
                  onContextMenu={(e) => {
                    e.preventDefault();
                    e.stopPropagation(); // not necessary in my case, could leave in case stopImmediateProp isn't available? 
                    // e.stopImmediatePropagation();
                    return false;
                  }}
                  className={`preventselect`}
                  ref={this.MagTag}
                  onMouseEnter={(e) => {
                    let tiptext = '';
                    if (this.props.lang && this.props.lang === 'spa') {
                      tiptext = "Magnitud del evento de IA basada en la credibilidad de las fuentes y los seguidores"
                    } else if (this.props.lang && this.props.lang === 'por') {
                      tiptext = "Magnitude do evento de IA com base na credibilidade das fontes e seguidores"
                    } else if (this.props.lang && this.props.lang === 'jap') {
                      tiptext = 'ソースの信頼性とフォロワーに基づくAIイベントの規模'
                    } else if (this.props.lang && this.props.lang === 'ara') {
                      tiptext = "يعتمد حجم حدث الذكاء الاصطناعي على مصداقية المصادر والمتابعين"
                    } else {
                      tiptext = 'AI event magnitude based on sources credibility and followers'
                    }
                    this.toggleMagtagtip(true, tiptext)
                  }}
                  onMouseLeave={(e) => {
                    this.toggleMagtagtip(false)
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    // e.preventDefault();
                    if (isMobile) {
                      // this.timerhandle = setTimeout((e) => {
                      let tiptext = '';
                      if (this.props.lang && this.props.lang === 'spa') {
                        tiptext = "Magnitud del evento de IA basada en la credibilidad de las fuentes y los seguidores"
                      } else if (this.props.lang && this.props.lang === 'por') {
                        tiptext = "Magnitude do evento de IA com base na credibilidade das fontes e seguidores"
                      } else if (this.props.lang && this.props.lang === 'jap') {
                        tiptext = 'ソースの信頼性とフォロワーに基づくAIイベントの規模'
                      } else if (this.props.lang && this.props.lang === 'ara') {
                        tiptext = "يعتمد حجم حدث الذكاء الاصطناعي على مصداقية المصادر والمتابعين"
                      } else {
                        tiptext = 'AI event magnitude based on sources credibility and followers'
                      }
                      this.toggleMagtagtipMobile(true, tiptext)
                      // }, 300)
                    }



                  }}
                // onTouchEnd={(e) => {
                //   // e.preventDefault();
                //   e.stopPropagation();
                //   if(this.timerhandle) {
                //     clearTimeout(this.timerhandle);
                //   }
                //   // console.log('touch end')
                // }}
                >
                  {
                    DetailsLevel === 0 ? (
                      <span className={`strengthindicator ${lang && lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''}`}></span>
                    ) : DetailsLevel === 1 ? (
                      <>
                        <span className={`strengthindicator ${lang && lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''}`}></span>
                        <span className={`strengthindicator ${lang && lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''}`}></span>
                      </>
                    ) : DetailsLevel === 2 ? (
                      <>
                        <span className={`strengthindicator ${lang && lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''}`}></span>
                        <span className={`strengthindicator ${lang && lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''}`}></span>
                        <span className={`strengthindicator ${lang && lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''}`}></span>
                      </>
                    ) : DetailsLevel === 3 ? (
                      <>
                        <span className={`strengthindicator ${lang && lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''} ${DetailsLevel > 0 ? 'active' : ''}`}></span>
                        <span className={`strengthindicator ${lang && lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''}`}></span>
                        <span className={`strengthindicator ${lang && lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''}`}></span>
                      </>
                    ) : DetailsLevel === 4 ? (
                      <>
                        <span className={`strengthindicator ${lang && lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''} ${DetailsLevel > 0 ? 'active' : ''}`}></span>
                        <span className={`strengthindicator ${lang && lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''} ${DetailsLevel > 1 ? 'active' : ''}`}></span>
                        <span className={`strengthindicator ${lang && lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''}`}></span>
                      </>
                    ) : DetailsLevel === 5 ? (
                      <>
                        <span className={`strengthindicator ${lang && lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''} ${DetailsLevel > 0 ? 'active' : ''}`}></span>
                        <span className={`strengthindicator ${lang && lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''} ${DetailsLevel > 1 ? 'active' : ''}`}></span>
                        <span className={`strengthindicator ${lang && lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''} ${DetailsLevel > 2 ? 'active' : ''}`}></span>
                      </>
                    ) : ('')
                  }
                  {/* <span className={`strengthindicator ${lang && lang === 'ara' ? 'arabic':''} ${this.props.Theme === 'light' ? 'lightmode' : ''} ${DetailsLevel > 0 ? 'active': ''}`}></span>
                  <span className={`strengthindicator ${lang && lang === 'ara' ? 'arabic':''} ${this.props.Theme === 'light' ? 'lightmode' : ''} ${DetailsLevel > 1 ? 'active': ''}`}></span>
                  <span className={`strengthindicator ${lang && lang === 'ara' ? 'arabic':''} ${this.props.Theme === 'light' ? 'lightmode' : ''} ${DetailsLevel > 2 ? 'active': ''}`}></span>
                  <span className={`strengthindicator ${lang && lang === 'ara' ? 'arabic':''} ${this.props.Theme === 'light' ? 'lightmode' : ''} ${DetailsLevel > 3 ? 'active': ''}`}></span>
                  <span className={`strengthindicator ${lang && lang === 'ara' ? 'arabic':''} ${this.props.Theme === 'light' ? 'lightmode' : ''} ${DetailsLevel > 4 ? 'active': ''}`}></span> */}
                </span>
                <span
                  onContextMenu={(e) => {
                    e.preventDefault();
                    e.stopPropagation(); // not necessary in my case, could leave in case stopImmediateProp isn't available? 
                    // e.stopImmediatePropagation();
                    return false;
                  }}
                  ref={this.trend}
                  className={`preventselect`}
                  onMouseEnter={(e) => {
                    let tiptext = '';
                    if (SentimentIndicator === 'neg_sentiment.svg') {
                      if (this.props.lang && this.props.lang === 'spa') {
                        tiptext = "La IA detectó que este evento podría desencadenar un cambio negativo en el precio de este activo"
                      } else if (this.props.lang && this.props.lang === 'por') {
                        tiptext = "A IA detectou que este evento pode desencadear uma mudança negativa no preço deste ativo"
                      } else if (this.props.lang && this.props.lang === 'jap') {
                        tiptext = 'AIは、このイベントがこの資産の価格にマイナスの変化をもたらす可能性があると検出しました'
                      } else if (this.props.lang && this.props.lang === 'ara') {
                        tiptext = "اكتشف الذكاء الاصطناعي أن هذا الحدث قد يؤدي إلى تغيير سلبي في سعر هذا الأصل"
                      } else {
                        tiptext = 'AI detected that this event could trigger a negative price change for this asset'
                      }

                    } else if (SentimentIndicator === 'pos_sentiment.svg') {
                      if (this.props.lang && this.props.lang === 'spa') {
                        tiptext = "La IA detectó que este evento podría desencadenar un cambio positivo en el precio de este activo"
                      } else if (this.props.lang && this.props.lang === 'por') {
                        tiptext = "A IA detectou que este evento pode desencadear uma mudança positiva no preço deste ativo"
                      } else if (this.props.lang && this.props.lang === 'jap') {
                        tiptext = 'AIは、このイベントがこの資産の価格にプラスの変化をもたらす可能性があると検出しました'
                      } else if (this.props.lang && this.props.lang === 'ara') {
                        tiptext = "اكتشف الذكاء الاصطناعي أن هذا الحدث قد يؤدي إلى تغيير إيجابي في سعر هذا الأصل"
                      } else {
                        tiptext = 'AI detected that this event could trigger a positive price change for this asset'
                      }

                    }
                    this.toggletrendtip(true, tiptext)
                  }}
                  onMouseLeave={(e) => {
                    this.toggletrendtip(false)
                  }}
                  onClick={(e) => {
                    // e.preventDefault();
                    e.stopPropagation();
                    if (isMobile) {
                      // this.trendtimerhandle = setTimeout((e) => {
                      let tiptext = '';
                      if (SentimentIndicator === 'neg_sentiment.svg') {
                        if (this.props.lang && this.props.lang === 'spa') {
                          tiptext = "La IA detectó que este evento podría desencadenar un cambio negativo en el precio de este activo"
                        } else if (this.props.lang && this.props.lang === 'por') {
                          tiptext = "A IA detectou que este evento pode desencadear uma mudança negativa no preço deste ativo"
                        } else if (this.props.lang && this.props.lang === 'jap') {
                          tiptext = 'AIは、このイベントがこの資産の価格にマイナスの変化をもたらす可能性があると検出しました'
                        } else if (this.props.lang && this.props.lang === 'ara') {
                          tiptext = "اكتشف الذكاء الاصطناعي أن هذا الحدث قد يؤدي إلى تغيير سلبي في سعر هذا الأصل"
                        } else {
                          tiptext = 'AI detected that this event could trigger a negative price change for this asset'
                        }

                      } else if (SentimentIndicator === 'pos_sentiment.svg') {
                        if (this.props.lang && this.props.lang === 'spa') {
                          tiptext = "La IA detectó que este evento podría desencadenar un cambio positivo en el precio de este activo"
                        } else if (this.props.lang && this.props.lang === 'por') {
                          tiptext = "A IA detectou que este evento pode desencadear uma mudança positiva no preço deste ativo"
                        } else if (this.props.lang && this.props.lang === 'jap') {
                          tiptext = 'AIは、このイベントがこの資産の価格にプラスの変化をもたらす可能性があると検出しました'
                        } else if (this.props.lang && this.props.lang === 'ara') {
                          tiptext = "اكتشف الذكاء الاصطناعي أن هذا الحدث قد يؤدي إلى تغيير إيجابي في سعر هذا الأصل"
                        } else {
                          tiptext = 'AI detected that this event could trigger a positive price change for this asset'
                        }

                      }
                      this.toggletrendtipMobile(true, tiptext)
                      // }, 300)
                    }



                  }}
                // onTouchEnd={(e) => {
                //   e.preventDefault();
                //   e.stopPropagation();
                //   if(this.trendtimerhandle) {
                //     clearTimeout(this.trendtimerhandle);
                //   }
                //   // console.log('touch end')
                // }}
                >
                  {
                    SentimentIndicator === 'neg_sentiment.svg' || SentimentIndicator === 'pos_sentiment.svg' ? (
                      <img
                        src={`${APPPATH.ImageUrl + '/' + SentimentIndicator} `}
                        className="pointer " alt="CrowdSense.ai"
                        style={{ width: '20px', marginTop: '-5px', marginLeft: '5px', marginRight: '5px' }}
                        onClick={() => ('')}



                      />
                    ) : ('')
                  }
                </span>
              </Col>
              {/* <Col lg={1} sm={1} xs={1} md={1} xl={1}
                style={{ marginTop: 1 }}
                className={`nopad  ${lang && lang === 'ara' ? 'text-right jutsify-content-right' : 'text-left jutsify-content-left'}`}
              >
                {
                  SentimentIndicator === 'neg_sentiment.svg' || SentimentIndicator === 'pos_sentiment.svg' ? (
                    <img 
                      src={`${APPPATH.ImageUrl+'/'+SentimentIndicator} `} 
                      className="pointer" alt="CrowdSense.ai" 
                      style={{width: '20px'}} 
                      onClick={() => ('')}
                      ref={(el) => (this.SecTag = el)}
                      onMouseEnter={(e) => {
                        let tiptext = '';
                        if(SentimentIndicator === 'neg_sentiment.svg') {
                          tiptext = 'Negative AI sentiment for event'
                        } else if(SentimentIndicator === 'pos_sentiment.svg') {
                          tiptext = 'Positive AI sentiment for event'
                        }
                        this.toggleSectagtip(true, tiptext)
                      }}
                      onMouseLeave={(e) => {
                        this.toggleSectagtip(false)
                      }}
                    />
                  ) : ('')
                }
                
              </Col> */}
              <Col lg={6} sm={6} xs={6} md={6} xl={6}

                className={`nopad ${lang && lang === 'ara' ? 'text-left jutsify-content-left' : 'text-right jutsify-content-right'}`}
              >
                {/* `Updated ${EventTimeAgo} ago` */}
                {/* `Detected ${DetectedTimeAgo} ago` */}
                <div className={`text-sigbarTime fs11 ${lang && lang === 'ara' ? 'text-left' : 'text-right'} text-regular-color`}>
                  {/* {
                    (lang && lang === 'spa') ? (
                      `${SpanishTranslations['Detected ago']} ${DetectedTimeAgo}`
                      ) : (lang && lang === 'jap') ? (
                        `${DetectedTimeAgo}${JapaneseTranslations['Detected ago']}`
                      ) : (lang && lang === 'ara') ? (
                        `${ArabicTranslations['Detected']}  ${ArabicTranslations['ago']} ${DetectedTimeAgo}`
                      ) : (lang && lang === 'eng') ? (
                        `Detected ${DetectedTimeAgo} ago`
                      ) : (lang && lang === 'por') ? (
                        `${PortugueseTranslations['Detected ago']} ${DetectedTimeAgo}`
                      ) : (`Detected ${DetectedTimeAgo} ago`)
                  } */}
                  {
                    DetectedTimeAgo !== EventTimeAgo ?
                      (lang && lang === 'spa') ? (
                        `${SpanishTranslations['Updated ago']} ${EventTimeAgo}`
                      ) : (lang && lang === 'jap') ? (
                        `${EventTimeAgo}${JapaneseTranslations['Updated ago']}`
                      ) : (lang && lang === 'ara') ? (
                        `${ArabicTranslations['Updated']} ${ArabicTranslations['ago']} ${EventTimeAgo}`
                      ) : (lang && lang === 'eng') ? (
                        `Updated ${EventTimeAgo} ago`
                      ) : (lang && lang === 'por') ? (
                        `${PortugueseTranslations['Updated ago']} ${EventTimeAgo}`
                      ) : (`Updated ${EventTimeAgo} ago`)
                      :
                      (lang && lang === 'spa') ? (
                        `${SpanishTranslations['Detected ago']} ${DetectedTimeAgo}`
                      ) : (lang && lang === 'jap') ? (
                        `${DetectedTimeAgo}${JapaneseTranslations['Detected ago']}`
                      ) : (lang && lang === 'ara') ? (
                        `${ArabicTranslations['Detected']}  ${ArabicTranslations['ago']} ${DetectedTimeAgo}`
                      ) : (lang && lang === 'eng') ? (
                        `Detected ${DetectedTimeAgo} ago`
                      ) : (lang && lang === 'por') ? (
                        `${PortugueseTranslations['Detected ago']} ${DetectedTimeAgo}`
                      ) : (`Detected ${DetectedTimeAgo} ago`)

                  }
                  {/* Detected {DetectedTimeAgo} ago | Updated {EventTimeAgo} ago */}
                </div>
              </Col>
            </Row>
            <Row className={`marg-0 `} style={{ padding: '10px 10px 0px 10px' }}>
              <Col lg={6} sm={6} xs={6} md={6} xl={6} className={`align-self-center  ${lang && lang === 'ara' ? 'text-right' : 'text-left'} nopad`}>
                {coins}
              </Col>
              <Col lg={6} sm={6} xs={6} md={6} xl={6} className={`align-self-center  ${lang && lang === 'ara' ? 'text-left' : 'text-right'} nopad`}>
                {
                  this.props.render === 'iframe' && this.props.userData.loginlessuser === 'Iforex' ? (
                    <span
                      className='btn-trade01 '
                      // style={{marginTop: '-15px'}}
                      onClick={(e) => {
                        // console.log(this.state.story, headlineText)
                        let MagnitudeRate = this.state.story.MagnitudeRate;
                        let iframeclickable = false;
                        if (MagnitudeRate >= 1) {
                          if (Sentiment !== null && typeof Sentiment !== 'undefined' && !isNaN(Sentiment) && SentimentIndicator !== '') {
                            iframeclickable = true
                          }
                        }
                        if (iframeclickable) {
                          let Direction = '';
                          if (Sentiment <= 3.5) {
                            Direction = 'Sell';
                          } else if (Sentiment >= 6.5) {
                            Direction = 'Buy'
                          }
                          e.stopPropagation();
                          window.showCoinColumnFromText(watchlistfilteredcorrelations[0].Security, watchlistfilteredcorrelations[0].CustomSymbol || watchlistfilteredcorrelations[0].Symbol, false, false, Direction, this.state.story.FeedId, headlineText, true);
                        }

                      }}>
                      {
                        (lang && lang === 'spa') ?
                          SpanishTranslations['Trade'] ? SpanishTranslations['Trade'] : 'Trade'
                          : (lang && lang === 'jap') ?
                            JapaneseTranslations['Trade'] ? JapaneseTranslations['Trade'] : 'Trade'
                            : (lang && lang === 'ara') ?
                              ArabicTranslations['Trade'] ? ArabicTranslations['Trade'] : 'Trade'
                              : (lang && lang === 'por') ?
                                PortugueseTranslations['Trade'] ? PortugueseTranslations['Trade'] : 'Trade'
                                : 'Trade'
                      }
                    </span>
                  ) : ('')
                }

              </Col>
            </Row>
            <Row className={`marg-0 `} style={{ padding: '10px 10px 0px 10px' }}>
              <Col lg={12} sm={12} xs={12} md={12} xl={12} className={`align-self-center cursor-contextmenu storyHeadline ${lang && lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>
                <div>
                  {actualtext}
                </div>
              </Col>
            </Row>

            <Row className={`marg-0`} style={{ padding: '10px 10px 0px 10px' }}>
              <Col lg={12} sm={12} xs={12} md={12} xl={12} className="align-self-center nopad">
                {detailsSection}
              </Col>
            </Row>

            {
              (!this.state.collapseToggle && explanationtext) && (
                <Row className={`marg-0`} style={{ padding: '10px 10px 0px 10px' }}>
                  <Col lg={12} sm={12} xs={12} md={12} xl={12} className={` ${lang && lang === 'ara' ? 'text-right' : 'text-left'} nopad explanationtextcontainer`}>
                    {explanationtext}
                  </Col>
                </Row>
              )
            }
            {/* <Row className={`marg-0 `} style={{ paddingTop: 10 }}>
              
              <Col
                lg={1}
                sm={1}
                xs={2}
                md={1}
                xl={1}
                style={{ marginTop: 2 }}
                className={`nopad  text-left jutsify-content-left   ${
                  this.props.storyState.TabMode
                    ? 'custom-col-1Tab'
                    : this.props.colLength > 2
                    ? 'custom-col-1min'
                    : 'custom-col-1'
                }`}
              >
                <StoryToggleBar
                  toggleDet={this.toggleDetails}
                  TDeckColWidth={this.props.TDeckColWidth}
                  userData={this.props.userData}
                  story={this.state.story}
                  storyClicked={this.storyClicked}
                  EventReadStatus={EventReadStatus}
                  togglePopup={this.props.togglePopup}
                  toggleOverlay={this.props.toggleOverlay}
                  tabmode={this.props.tabmode}
                  colLength={this.props.colLength}
                />
              </Col>
              <Col
                lg={11}
                sm={11}
                xs={10}
                md={11}
                xl={11}
                className={`align-self-center nopad ${
                  this.props.storyState.TabMode
                    ? 'custom-col-10Tab'
                    : this.props.colLength > 2
                    ? 'custom-col-10min'
                    : 'custom-col-10'
                }`}
              >
                <StoryHeadBar
                  {...this.props}
                  toggleDet={this.toggleDetails}
                  handleCollapse={this.handleCollapse}
                  story={this.state.story}
                  EventReadStatus={EventReadStatus}
                  storyClicked={this.storyClicked}
                  tabmode={this.props.tabmode}
                  // Event_Collapsed_View={this.props.Event_Collapsed_View}
                  collapse={this.state.collapseToggle}
                  SecuritySymbol={SecuritySymbol}
                  Security={Security}
                  securityTicker={story.CorrelationResults ? story.CorrelationResults[0] : {}}
                  tdecktype={this.props.tdecktype}
                />
                
                
              </Col>
              
            </Row>
            <div>
              {this.props.columnCollapse || (!this.state.collapseToggle && !this.props.columnCollapse) ? (
                <Row className={`marg-0 story-feeds-sec h-100 `}>
                  <Col lg={12} sm={12} xs={12} md={12} xl={12} className="story-feed-security nopad">
                    {detailsSection}
                  </Col>
                </Row>
              ) : (
                ''
              )}
             
            </div>
            {!this.state.collapseToggle && mapSection !== '' && (
              <Row className={`marg-0`}>
                <Col
                  lg={12}
                  sm={12}
                  xs={12}
                  md={12}
                  xl={12}
                  className="nopad posrelative"
                  style={{ width: '100%', height: '100%' }}
                >
                  {mapSection}
                </Col>
              </Row>
            )} */}
            {!this.state.collapseToggle && (
              <Row className={`marg-0`} style={{ padding: '10px 0px 0px 0px' }}>
                <Col lg={12} sm={12} xs={12} md={12} xl={12} className="brdr-bot-gray " ></Col>
              </Row>
            )}
            {feedDetails}
          </Col>
        </div>
      );
    } else {
      // if (!this.props.storyCount) {
      const { StoryRequestState, tdecktype, savedSearchStoryRequestState, initDataRequest, coindetailcolumnRequest } = this.props;

      const searchnullContent =
        tdecktype === 'search' ? (
          this.props.checkInSearch ? (
            this.props.searchRequest ? (
              <div>
                <div className="Col-Pref-Update-Head">Updating search results to match preferences</div>
                <div>
                  {/* <i className="fa fa-spinner fa-2x fa-spin storyloadindicator"></i> */}
                </div>
              </div>
            ) : (
              <div className="storyloadindicator">{`No events matching the search criteria from ${moment
                .utc(this.props.searchData.startDate)
                .local()
                .format('DD/MM/YYYY HH:mm:ss A')} to ${moment
                  .utc(this.props.searchData.endDate)
                  .local()
                  .format(
                    'DD/MM/YYYY HH:mm:ss A'
                  )}. Try changing the dates and times using the calendar icon or try changing search terms.`}</div>
            )
          ) : (
            ''
          )
        ) : (
          ''
        );
      const tmDetailsnullContent =
        tdecktype === 'tmd' ? (
          this.props.tmDetailsRequest ? (
            <div>
              <div className="Col-Pref-Update-Head">Updating results to match preferences</div>
              <div>
                {/* <i className="fa fa-spinner fa-2x fa-spin storyloadindicator"></i> */}
              </div>
            </div>
          ) : (
            <div className="storyloadindicator">{`No recent events matching this criteria.`}</div>
          )
        ) : (
          ''
        );
      const coindetailcolumnnullContent =
        tdecktype === 'tmcd' ? (
          this.props.coindetailcolumnRequest ? (
            <div>
              <div className="Col-Pref-Update-Head">Updating results to match preferences</div>
              <div>
                {/* <i className="fa fa-spinner fa-2x fa-spin storyloadindicator"></i> */}
              </div>
            </div>
          ) : (
            <div className="storyloadindicator">{`No recent events matching this criteria.`}</div>
          )
        ) : (
          ''
        );
      const eventDetailsnullContent =
        tdecktype === 'event' ? (
          this.props.tmDetailsRequest ? (
            <div>
              <div className="Col-Pref-Update-Head">Updating results</div>
              <div>
                {/* <i className="fa fa-spinner fa-2x fa-spin storyloadindicator"></i> */}
              </div>
            </div>
          ) : (
            <div className="storyloadindicator">{`No such event found`}</div>
          )
        ) : (
          ''
        );
      const notifynullContent =
        tdecktype === 'notify' ? (
          StoryRequestState === true || initDataRequest === true ? (
            <div>
              {/* <i className="fa fa-spinner fa-2x fa-spin storyloadindicator"></i> */}
            </div>
          ) : StoryRequestState === false && !this.state.story ? (
            <div className="storyloadindicator">...</div> //No breaking events found
          ) : (
            ''
          )
        ) : (
          ''
        );
      const analyzednullContent =
        tdecktype === 'analyzed' ? (
          StoryRequestState === true || initDataRequest === true ? (
            <div>
              {/* <i className="fa fa-spinner fa-2x fa-spin storyloadindicator"></i> */}
            </div>
          ) : StoryRequestState === false && !this.state.story ? (
            <div className="storyloadindicator">...</div> //No analyzed events found
          ) : (
            ''
          )
        ) : (
          ''
        );
      let color;
      switch (this.props.UserPreferenceUpdatedCategory) {
        case 'Red_Colored_Events':
          color = 'red';
          break;
        case 'Yellow_Colored_Events':
          color = 'orange';
          break;
        case 'Gray_Colored_Events':
          color = 'yellow';
          break;
        case 'Non_Active_Curated_Events':
          color = 'gray';
          break;
        default:
          color = '';
          break;
      }
      // console.log(tdecktype, StoryRequestState, initDataRequest)
      const mainnulliframecontent =
        (tdecktype === 'main' && this.props.render === 'iframe') && (<SpinnerLoader modalClose={this.props.modalClose} />)
      const mainnullContent =
        tdecktype === 'main' ? (
          StoryRequestState === true || initDataRequest === true ? (
            NaceUpdated !== NaceUpdatedOld ? (
              <div>
                {
                  this.props.render !== 'iframe' ? (
                    <div className="Col-Pref-Update-Head">Updating results to match preferences</div>
                  ) : ('')
                }

                {/* <div className="Col-Pref-Update-Head">Updating results to include {color} events</div> */}
                {/* <div className="Col-Pref-Update-Desc">(earlier curated signals for events before a deeper analysis)</div> */}
                {
                  this.props.render !== 'iframe' ? (
                    <div>
                      {/* <i className="fa fa-spinner fa-2x fa-spin storyloadindicator"></i> */}
                    </div>
                  ) : ('')
                }


              </div>
            ) : (
              <div>
                {
                  this.props.render !== 'iframe' ? (
                    <div>
                      {/* <i className="fa fa-spinner fa-2x fa-spin storyloadindicator"></i> */}
                    </div>
                  ) : ('')
                }


              </div>
            )
          ) : StoryRequestState === false && !this.state.story ? (
            !this.props.checkInSearch ? (
              !this.props.req_error.rootFeeds ? (
                <div className="storyloadindicator"></div>//No matched events foun
              ) : (
                // <div className="storyloadindicator">
                //   No connection to service, please try to refresh. If the problem persists, please let us know in{' '}
                //   <a href="mailto: info@crowdsense.ai">
                //     <u>info@crowdsense.ai</u>
                //   </a>
                // </div>
                <div className="storyloadindicator"></div>//No matched events found
              )
            ) : (
              <div className="storyloadindicator">{`No events matching the search criteria from ${moment
                .utc(this.props.searchData.startDate)
                .local()
                .format('DD/MM/YYYY HH:mm:ss A')} to ${moment
                  .utc(this.props.searchData.endDate)
                  .local()
                  .format(
                    'DD/MM/YYYY HH:mm:ss A'
                  )}. Try changing the dates and times using the calendar icon or try changing search terms.`}</div>
            )
          ) : (
            ''
          )
        ) : (
          ''
        );
      const savedSearchNullContent =
        tdecktype === 'savedsearch' ? (
          savedSearchStoryRequestState === true ? (
            <div>
              <div className="Col-Pref-Update-Head">
                {
                  this.props.render === 'iframe' && (<SpinnerLoader modalClose={this.props.modalClose} />)
                }
              </div>
              <div>
                {/* <i className="fa fa-spinner fa-2x fa-spin storyloadindicator"></i> */}
              </div>
            </div>
          ) : (
            <div className="storyloadindicator">{`No events matching the search criteria.`}</div>
          )
        ) : (
          ''
        );
      return (
        <Col lg={12} sm={12} xs={12} md={12} xl={12} className={`${this.state.hideThisStory ? 'nodisp' : ''}`}>
          {tdecktype === 'notify'
            ? notifynullContent
            : tdecktype === 'search'
              ? searchnullContent
              : tdecktype === 'main'
                ? this.props.render === 'iframe'
                  ? mainnulliframecontent
                  : mainnullContent
                : tdecktype === 'savedsearch'
                  ? savedSearchNullContent
                  : tdecktype === 'analyzed'
                    ? analyzednullContent
                    : tdecktype === 'tmd'
                      ? tmDetailsnullContent
                      : tdecktype === 'event'
                        ? eventDetailsnullContent
                        : tdecktype === 'tmcd'
                          ? coindetailcolumnnullContent
                          : ''}
        </Col>
      );
      // }
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  let srcfilter;
  if (ownProps.tdecktype === 'tmcd') {
    // console.log(state.trendingMentions.tmDetailPreferencesDataStore)
    // console.log(`${ownProps.SecurityName}${ownProps.SourceFilter}`)
    if (typeof ownProps.SourceFilter === undefined || ownProps.SourceFilter === '') {
      srcfilter = '';
    } else {
      srcfilter = ownProps.SourceFilter;
    }
  }
  return {
    googleMapKey: state.settings.googleMapKey,
    story:
      ownProps.tdecktype === 'search' && ownProps.checkInSearch
        ? typeof state.search.searchStories[ownProps.id] !== 'undefined'
          ? state.search.searchStories[ownProps.id]
          : null
        : ownProps.tdecktype === 'savedsearch'
          ? ownProps.SavedId &&
            ownProps.id &&
            typeof state.search.searchSavedStories[ownProps.SavedId][ownProps.id] !== 'undefined'
            ? state.search.searchSavedStories[ownProps.SavedId][ownProps.id]
            : null
          : ownProps.tdecktype === 'notify'
            ? typeof state.feeds.BreakingStories[ownProps.id] !== 'undefined'
              ? state.feeds.BreakingStories[ownProps.id]
              : null
            : ownProps.tdecktype === 'analyzed'
              ? typeof state.feeds.AnalysedStories[ownProps.id] !== 'undefined'
                ? state.feeds.AnalysedStories[ownProps.id]
                : null
              : ownProps.tdecktype === 'tmd'
                ? typeof state.trendingMentions.tmDetails.TickerEventList[ownProps.id] !== 'undefined'
                  ? state.trendingMentions.tmDetails.TickerEventList[ownProps.id]
                  : null

                : ownProps.tdecktype === 'tmcd'
                  ? typeof state.trendingMentions.tmDetailPreferencesDataStore[`${ownProps.SecurityName}${srcfilter}`] !== 'undefined' && typeof state.trendingMentions.tmDetailPreferencesDataStore[`${ownProps.SecurityName}${srcfilter}`].TickerEventList[ownProps.id] !== 'undefined'
                    ? state.trendingMentions.tmDetailPreferencesDataStore[`${ownProps.SecurityName}${srcfilter}`].TickerEventList[ownProps.id]
                    : null
                  : ownProps.tdecktype === 'event'
                    ? ownProps.currentEvent
                      ? ownProps.currentEvent
                      : null
                    : typeof state.feeds.Stories[ownProps.id] !== 'undefined'
                      ? state.feeds.Stories[ownProps.id]
                      : null,
    feedDetails: state.feeds.feedDetails,
    searchData: state.search.searchData,
    searchRequest: state.search.searchRequest,
    NaceUpdated: state.app_preferences.UserPreferenceUpdated,
    UserPreferenceUpdatedCategory: state.app_preferences.UserPreferenceUpdatedCategory,
    preferencesSecuritiesLine: state.app_preferences.preferences.event_filters.Securities_Line,
    preferencesLocationRelatedCountries: state.app_preferences.preferences.event_filters.Locations_Related_Countries,
    StoryRequestState: state.feeds.feedReqData.RequestState,
    Theme: state.app_preferences.preferences.user_interface.Theme,
    render: state.settings.render,
    iframenav: state.settings.iframenav,
    iframeasset: state.settings.iframeasset,
    savedSearchStoryRequestState:
      typeof state.search.searchSaveFeedReqData[ownProps.SavedId] !== 'undefined'
        ? state.search.searchSaveFeedReqData[ownProps.SavedId].RequestState
        : false,
    withBlackUpdated:
      typeof state.search.withBlackUpdated[ownProps.SavedId] !== 'undefined'
        ? state.search.withBlackUpdated[ownProps.SavedId]
        : 0,
    withCuratedUpdated:
      typeof state.search.withCuratedUpdated[ownProps.SavedId] !== 'undefined'
        ? state.search.withCuratedUpdated[ownProps.SavedId]
        : 0,
    Event_Collapsed_View: state.app_preferences.preferences.user_interface.Event_Collapsed_View,
    initDataRequest: state.settings.initDataRequest,
    feedOpenClose:
      typeof state.feeds.feedOpenCloseList[ownProps.id] !== 'undefined'
        ? state.feeds.feedOpenCloseList[ownProps.id]
        : true,
    TimeZoneOffset: state.settings.TimeZoneOffset,
    Timezone: state.app_preferences.preferences.localisation_preferences.Timezone,
    // SecuritiesTickerIdList: state.filters.SecuritiesTickerIdList,
    tmDetailsRequest: state.trendingMentions.tmDetails.TickerDetailsFeedReqData.RequestState,
    coindetailcolumnRequest:
      typeof state.trendingMentions.tmDetailPreferencesDataStore[`${ownProps.SecurityName}${srcfilter}`] !== 'undefined' ?
        typeof state.trendingMentions.tmDetailPreferencesDataStore[`${ownProps.SecurityName}${srcfilter}`].TickerDetailsFeedReqData !== 'undefined' ?
          state.trendingMentions.tmDetailPreferencesDataStore[`${ownProps.SecurityName}${srcfilter}`].TickerDetailsFeedReqData.RequestState : false
        : false,
    lang: state.settings.lang,
    feedDetailsRequests: state.feeds.feedDetailsRequests,
  };
};

const mapDispatchToProps = {
  ...feedActionCreators,
  activateGlobalTooltipRemover,
  resetTipHandles,
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleStory);
