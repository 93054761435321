import { Component } from 'react';
import  { connect } from 'react-redux';
import * as tmActionCreators from '../../actions/trendingmentions.action';
import { EventsUpdateHandler,TrendingMentionsHelper } from '../../modules/helper.module';
import * as moment from 'moment';

class TMDetailsContextHandle extends Component {
    constructor(props) {
        super();
        this.state = {
            TickerViewDetails: props.TickerViewDetails,
            TickerData: props.TickerData,
            TickerEventIndex: [],
            TickerSearchData: {},
            tmDetailEventsReqData: {
                InitialFeedRequest: '',
                LastFeedRequest: '',
                LastFeedRequestEnd: '',
                InitialFeedResponse: '',
                LastFeedResponse: '',
                RequestState:false
            },
            thisInstance: this,
            updateUseCase: 0,
            utcOffset: 0,
            postponeupdate: props.postponeupdate
        }
        this.tmDetailsFeedUpdateHandle = {};
        this.tmDetailsFeedUpdateInterval = {};
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        // console.log(prevState.thisInstance.props.TickerViewDetails, nextProps.TickerViewDetails)
        if(prevState.thisInstance.props.postponeupdate !== nextProps.postponeupdate) {
            return { postponeupdate: nextProps.postponeupdate };
        }
        if(prevState.thisInstance.props.TickerViewDetails !== nextProps.TickerViewDetails) {
            return {
                TickerViewDetails : nextProps.TickerViewDetails
            }
        } else if(JSON.stringify(prevState.thisInstance.props.TickerData) !== JSON.stringify(nextProps.TickerData)) {
            
            return {
                TickerData: nextProps.TickerData
            }
        } else if(prevState.thisInstance.props.TickerEventIndex !== nextProps.TickerEventIndex) {
            
            let tmFeedReqData = prevState.tmDetailEventsReqData
            if(prevState.thisInstance.tmDetailsFeedUpdateHandle.UpdateReqData) {
                let error = nextProps.req_error.rootFeeds;
                tmFeedReqData = prevState.thisInstance.tmDetailsFeedUpdateHandle.UpdateReqData(prevState.TickerEventIndex, nextProps.TickerEventIndex, error);
            }
            return {
                TickerEventIndex: nextProps.TickerEventIndex,
                tmDetailEventsReqData: tmFeedReqData.reqData,
                updateUseCase: tmFeedReqData.useCase
            }
        }
        
        return null;
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log(this.props.TickerViewDetails, prevProps.TickerViewDetails)
        // console.log(this.state.TickerViewDetails, prevState.TickerViewDetails)
        if((this.props.TickerViewDetails && this.props.TickerViewDetails !== prevProps.TickerViewDetails) || 
        (this.props.TickerViewDetails && this.props.TickerData.SecurityTicker !== prevProps.TickerData.SecurityTicker) || 
        (this.props.TickerResearch !== prevProps.TickerResearch && this.props.TickerResearch === true) || (this.props.securities !== prevProps.securities)) {
            // console.log('load ticker events')
            clearTimeout(this.tmDetailsFeedUpdateInterval)
            this.loadTickerEvents()
        } else if(this.state.TickerEventIndex !== prevState.TickerEventIndex && !this.state.postponeupdate) {
            // console.log('ticker events updated')
            this.updateTickerEventsRequest(this.state.updateUseCase)
        } else {
            if(prevState.postponeupdate !== this.state.postponeupdate && !this.state.postponeupdate) {
                clearTimeout(this.tmDetailsFeedUpdateInterval)
            }
        }
    }

    updateTickerEventsRequest = (useCase) => {
        this.props.setTickerDetailsFeedReqData(this.state.tmDetailEventsReqData);
        // let tkIndex = this.props.TickerEventIndex.map(index => { return index.FeedId })
        clearTimeout(this.tmDetailsFeedUpdateInterval)
        switch(useCase) {
            case 1:
                        
                this.tmDetailsFeedUpdateInterval = setTimeout(() => {
                    
                    this.updateTickerEvents(this.tmDetailsFeedUpdateHandle.LastFeedRequest);
                    clearTimeout(this.tmDetailsFeedUpdateInterval)
                    
                },30000)

                break;
            case 4:
                clearTimeout(this.tmDetailsFeedUpdateInterval);
                this.loadTickerEvents(this.state.TickerSearchData);
                break;
            case 3:
                clearTimeout(this.tmDetailsFeedUpdateInterval);
                // this.loadTickerEvents(this.state.TickerSearchData);
                break;
            default:
                this.tmDetailsFeedUpdateInterval = setTimeout(() => {
                    this.updateTickerEvents(this.tmDetailsFeedUpdateHandle.LastFeedRequest);
                    clearTimeout(this.tmDetailsFeedUpdateInterval)
                },30000)
                break;
        }
    }

    loadTickerEvents = () => {
        // console.log(this.props.securities)
        if(this.props.TickerViewDetails) {
            let security = this.props.securities.filter(item => { return item.Name.trim().toLowerCase() === this.props.TickerData.SecurityName.trim().toLowerCase() })[0];
            // console.log(security)
            // this.props.resetTickerEvents()
            let searchData = {
                sec : [`${this.props.TickerData.SecurityName}`],
                startDate : moment().utcOffset(this.state.utcOffset).subtract(7,'days').format('YYYY-MM-DD HH:mm:ss'),
                endDate : TrendingMentionsHelper.FreeSubscriptionFilter() ? moment().utcOffset(this.state.utcOffset).subtract(15,'minutes').format('YYYY-MM-DD HH:mm:ss') : moment().utcOffset(this.state.utcOffset).format('YYYY-MM-DD HH:mm:ss'),
                withCurated : this.props.Search_with_gray,
                withBlack : this.props.Search_with_black,
                withYellow : this.props.Search_with_yellow,
                withOrange : this.props.Search_with_orange,
                withRed : this.props.Search_with_red
            }
            // if(typeof security !== 'undefined') {
            //     if(security.children.length > 0) {
            //         this.props.securities.forEach(sec => {
            //             if(security.children.indexOf(sec.SecurityID) > -1) {
            //                 searchData.sec.push(sec.Name)
            //             }
            //         })
            //     }
            // }
            // console.log(searchData)
            let TickerFeedReqData = {
                RequestState: true, 
                InitialFeedRequest: searchData.startDate, 
                LastFeedRequest: searchData.endDate,
                LastFeedRequestEnd: searchData.endDate,
                InitialFeedResponse: searchData.endDate,
                LastFeedResponse: '', 
            }
            this.tmDetailsFeedUpdateHandle = new EventsUpdateHandler(TickerFeedReqData);
            this.props.setTickerDetailsFeedReqData(TickerFeedReqData);
            
            this.setState({
                ...this.state,
                TickerSearchData: searchData,
                tmDetailEventsReqData: TickerFeedReqData
            },() => {
                this.props.searchTickerEvents(searchData);
            })
        }
        

    }

    updateTickerEvents = (LastFeedRequest) => {
        let startDate = moment(LastFeedRequest).utcOffset(this.state.utcOffset).format('MM-DD-YYYY HH:mm:ss');
        let nextStartDate = this.props.userData.userPackage.toLowerCase() === 'bronze' ? moment().utcOffset(this.state.utcOffset).subtract(15,'minutes').format() : moment().utcOffset(this.state.utcOffset).format();
        let endDate = moment(nextStartDate).utcOffset(this.state.utcOffset).format('MM-DD-YYYY HH:mm:ss');
        let { TickerSearchData } = this.state
        TickerSearchData.startDate = startDate
        TickerSearchData.endDate = endDate
        this.setState({
            ...this.state,
            tmDetailEventsReqData: {
                ...this.state.tmDetailEventsReqData,
                RequestState: true,
                LastFeedRequestEnd: nextStartDate,
                LastFeedRequest: LastFeedRequest
            }
        },() => {
            this.tmDetailsFeedUpdateHandle.RequestState = true;
            this.tmDetailsFeedUpdateHandle.LastFeedRequest = LastFeedRequest;
            this.tmDetailsFeedUpdateHandle.LastFeedRequestEnd = nextStartDate;
            this.props.setTickerDetailsFeedReqData(this.state.tmDetailEventsReqData);
            this.props.searchTickerEvents(TickerSearchData, true);
            this.props.TMViewDetails(this.props.TickerData, true)
        })
        
    }

    render() {
        // console.log(this.state.tmIndex);
        return ('')
    }

}


const mapStateToComponent = (state) => {
    
    return {
        postponeupdate: state.settings.postponeupdate,
        TickerViewDetails: state.trendingMentions.tmDetails.TickerViewDetails,
        TickerData: state.trendingMentions.tmDetails.TickerData,
        securities: state.filters.securities,
        Search_with_gray: state.app_preferences.preferences.coindetailsearch_preferences.CDSearch_with_gray,
        Search_with_black: state.app_preferences.preferences.coindetailsearch_preferences.CDSearch_with_black,
        Search_with_yellow: state.app_preferences.preferences.coindetailsearch_preferences.CDSearch_with_yellow,
        Search_with_orange: state.app_preferences.preferences.coindetailsearch_preferences.CDSearch_with_orange,
        Search_with_red: state.app_preferences.preferences.coindetailsearch_preferences.CDSearch_with_red,
        TickerEventIndex: state.trendingMentions.tmDetails.TickerEventIndex,
        TickerResearch: state.trendingMentions.tmDetails.TickerResearch,
        req_error: state.error.rootFeeds
    }
}
const mapDispatchToProps = {
    ...tmActionCreators
}

export default connect(mapStateToComponent, mapDispatchToProps, null, {forwardRef : true})(TMDetailsContextHandle)