import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { APPPATH } from '../../../modules/helper.module';
import PremiumButton from '../headbar/PremiumButton';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';

class TopTrendingPremiumBanner extends Component {
  constructor() {
    super();
    this.state = {
      show: true,
    };
  }

  closeBanner = () => {
      this.setState({...this.state, show: false})
  }

  render() {
    console.log(this.props.freeTrial, this.props.daystogo)
    let trialState = 0;
    let bannerText = '';
    if(!this.props.freeTrial && this.props.daystogo === 0) {
      trialState = 0;
    } else if(this.props.freeTrial && this.props.daystogo > 0) {
      trialState = 1;
    } else if(this.props.freeTrial && this.props.daystogo <= 0) {
      trialState = 2;
    }
    switch(trialState) {
      case 0:
        bannerText = this.props.ColType === 'tm' ? 'For more trending coins, go Premium' : 'For all updates also from the past 15 minutes, go Premium';
        break;
      case 1:
        bannerText = `${this.props.daystogo} more days to go`;
        break;
      case 2:
        bannerText = this.props.text ? `For all updates also from the past 15 minutes` : 'For more trending coins';
        break;
      default:
        bannerText = 'For faster real-time arrival of data for thousands of coins';
        break;
    }
    return (
      <Col
        lg={12}
        sm={12}
        xs={12}
        md={12}
        xl={12}
        className={`tm-card trending-banner-card ${!this.state.show ? 'nodisp' : ''}`}
        style={{ backgroundColor: 'rgb(207, 221, 255)', color: 'rgb(0, 0, 0)', padding: isMobile ? '5px 0px 5px 0px !important' : '7px 0px 7px 0px !important'}}
      >
        
        <div className="">
          <Row className="marg-0 h-100">
            <Col 
              lg={6} 
              sm={6} 
              xs={6} 
              md={6} 
              xl={6} 
             className="banner-text-left nopad banner-text STop"
             style={{textAlign: `left`}}>
              <span style={{ fontSize: 13, fontWeight: 'bold', paddingLeft: 0, marginBottom: '0px !important' }}>
                { bannerText }
              </span>
              {/* <span className={isMobile ? 'nodisp' : ''}>
                {
                  trialState === 0 ? (
                    <span className="btn tryforfree-btn" onClick={() => {this.props.toggleConfirmPopup(true)}} >
                      <img src={`${APPPATH.ImageUrl}/button-premium-small-noArrow.svg`} alt="CrowdSense.ai" style={{marginTop: '-1px'}} /><span style={{marginLeft: '-3px', padding: '5px 5px 0px 0px'}}>Try for Free</span>
                    </span>
                  ) : (
                    <PremiumButton
                      noTitle={false}
                      align={true}
                      beta={false}
                      {...this.props}
                      minify={true}
                      isCalledFromTrendingColumn={true}
                    />  
                  )
                }
              </span> */}
            </Col>
            <Col lg={5} sm={5} xs={5} md={5} xl={5} className={`align-self-center nopad banner-text `}>
              {
                trialState === 0 ? (
                  <span className="btn tryforfree-btn" onClick={() => {this.props.showModal('VerifyEmail')}} >
                    <img src={`${APPPATH.ImageUrl}/button-premium-small-noArrow.svg`} alt="CrowdSense.ai" style={{marginTop: '-1px'}} /><span style={{marginLeft: '-3px', padding: '5px 5px 0px 0px'}}>Try for Free</span>
                  </span>
                ) : (
                  <PremiumButton
                    noTitle={false}
                    align={true}
                    beta={false}
                    {...this.props}
                    minify={true}
                    isCalledFromTrendingColumn={true}
                  />  
                )
              }
              
            </Col>
            <Col lg={1} sm={1} xs={1} md={1} xl={1} className=" nopad banner-text pointer">
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 16.458 15.828" onClick={this.closeBanner}>
                <g transform="translate(1.414 1.414)">
                  <path
                    className="a"
                    d="M1560.6,103.5l-13.63,13"
                    transform="translate(-1546.97 -103.5)"
                    fill="none"
                    stroke={'#000'}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2px"
                  />
                  <g transform="translate(-1546.97 -103.5)">
                    <path
                      className="a"
                      d="M1546.97,103.5l13.63,13"
                      fill="none"
                      stroke={'#000'}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2px"
                    />
                  </g>
                </g>
              </svg>
            </Col>
          </Row>
        </div>
      </Col>
    );
  }
}

// export default TopTrendingPremiumBanner;
const mapStateToApp = (state) => {
  return {
    freeTrial: state.settings.freeTrial,
    daystogo: state.settings.freeTrialDetails.daystogo
  };
};

const mapDispatchToProps = {
  
};

export default connect(mapStateToApp, mapDispatchToProps)(TopTrendingPremiumBanner);
