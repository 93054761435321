import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as appSettingsActionCreators from '../actions/settings.action';
import { SpanishTranslations, PortugueseTranslations, JapaneseTranslations, ArabicTranslations } from '../static_data/translations';

class Feedback extends Component {
  constructor() {
    super();
    this.state = {
      title: '',
      description: '',
      titleError: false,
      descriptionError: false,
      feedbackStatus: false
    }
  }

  componentWillMount() {
    this.setState({
      ...this.state,
      feedbackStatus: this.props.feedbackStatus
    })
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      ...this.state,
      title: '',
      description: '',
      titleError: false,
      descriptionError: false,
      feedbackStatus: nextProps.feedbackStatus
    }, () => {
      if (nextProps.feedbackStatus) {
        this.flashTimeout = setTimeout(function () {
          nextProps.finishSendFeedback()
        }, 5000)
      }
    })
  }

  validate = (value = false, stateName = false) => {
    let resData = {}
    if (value !== '') {
      resData.status = true;
      resData.message = '';
    } else {
      resData.status = false;
      resData.message = 'This is a required field.';
    }
    return resData;
  }

  handleChange = (e) => {
    let changedInput = e.target.id;
    let resData = this.validate(e.target.value, changedInput);
    if (resData.status) {
      this.setState({
        ...this.state,
        [`${changedInput}`]: e.target.value,
        [`${changedInput}Error`]: false
      })
    } else {
      this.setState({
        ...this.state,
        [`${changedInput}`]: e.target.value,
        [`${changedInput}Error`]: resData.message
      })
    }
  }
  submitFb = () => {
    let data = {
      Title: this.state.title,
      Message: this.state.description,
      UserName: this.props.userData.username
    }
    this.setState({
      ...this.state,
      title: '',
      description: ''
    }, () => {
      if (!this.state.titleError && !this.state.descriptionError) {
        this.props.sendFeedback(data);
      }
    })
  }

  render() {
    return (
      <Row className=" marg-0 feedback-wrap ">
        <Col lg={12} sm={12} xs={12} md={12} xl={12}>
          <Row className="justify-content-center  ">
            <Col lg={11} sm={11} xs={11} md={11} xl={11} className={`nopad feedbackhead justify-content-left ${this.props.lang && this.props.lang === 'ara' ? 'text-right' : 'text-left'}`}>
              <i className="fa fa-wpforms"></i><span>

                {
                  this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['Feedback']
                    : this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['Feedback']
                      : this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['Feedback']
                        : this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['Feedback']
                          : 'Feedback'
                }
              </span>
            </Col>
            <div className={`flash-message changepass ${this.state.feedbackStatus ? '' : 'nodisp'} `}>
              {`${this.state.feedbackStatus !== '' ? this.state.feedbackStatus : ''}`}
            </div>
          </Row>
          <Row className="justify-content-center  ">
            <Col lg={11} sm={11} xs={11} md={11} xl={11} className="feedbackbody justify-content-left text-left">
              <div className="bodypanel">
                <div className="bodypanel-head">

                  {
                    this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['Please share your feedback or idea with us']
                      : this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['Please share your feedback or idea with us']
                        : this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['Please share your feedback or idea with us']
                          : this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['Please share your feedback or idea with us']
                            : 'Please share your feedback or idea with us'
                  }
                </div>
                <div className="bodypanel-body">
                  <div className="fb-form">
                    <Row className="marg-0 fb-form-section">
                      <Col lg={12} sm={12} xs={12} md={12} xl={12} className={`${this.props.lang && this.props.lang === 'ara' ? 'text-right' : 'text-left'}`}>
                        <label>

                          {
                            this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['Title']
                              : this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['Title']
                                : this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['Title']
                                  : this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['Title']
                                    : 'Title'
                          }
                        </label>
                        <input type="text" name="title" id="title" placeholder={


                          this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['Add your title']
                            : this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['Add your title']
                              : this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['Add your title']
                                : this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['Add your title']
                                  : 'Add your title'

                        } onChange={this.handleChange} value={this.state.title} />

                        <label className={`hasError ${this.state.titleError ? '' : 'nodisp'}`}>{this.state.titleError !== false ? this.state.titleError : ''}</label>
                      </Col>
                    </Row>
                    <Row className="marg-0 fb-form-section">
                      <Col lg={12} sm={12} xs={12} md={12} xl={12} className={`${this.props.lang && this.props.lang === 'ara' ? 'text-right' : 'text-left'}`}>
                        <label>

                          {
                            this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['Description']
                              : this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['Description']
                                : this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['Description']
                                  : this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['Description']
                                    : 'Description'
                          }
                        </label>
                        <textarea name="description" id="description" placeholder={


                          this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['Add your feedback']
                            : this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['Add your feedback']
                              : this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['Add your feedback']
                                : this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['Add your feedback']
                                  : 'Add your feedback'

                        } onChange={this.handleChange} value={this.state.description} className="" ></textarea>

                        <label className={`hasError ${this.state.descriptionError ? '' : 'nodisp'}`}>{this.state.descriptionError !== false ? this.state.descriptionError : ''}</label>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center  ">
            <Col lg={11} sm={11} xs={11} md={11} xl={11} className="feedbackfoot">
              <div><button type="button" style={{ marginLeft: 5, marginRight: 5 }} className={`btn btn-fb ${this.props.lang && this.props.lang === 'ara' ? 'float-left' : 'float-right'} `} onClick={this.submitFb}>

                {
                  this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['Send']
                    : this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['Send']
                      : this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['Send']
                        : this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['Send']
                          : 'Send'
                }
              </button></div>
              {/* <div><button type="button" className="btn btn-cp" onClick={this.cancelChange}>Cancel</button></div> */}
            </Col>
          </Row>

        </Col>
      </Row>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    feedbackStatus: state.settings.feedbackStatus,
    lang: state.settings.lang,
  }
}

const mapDispatchToProps = {
  ...appSettingsActionCreators
}

export default connect(mapStateToProps, mapDispatchToProps)(Feedback)