import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateSecuritiesFilter } from '../../actions/filters.action';
import ConfirmationPopUp from '../common/confirmationPopUp';
import { TrendingMentionsHelper } from '../../modules/helper.module';

class FavCoin extends Component {
  constructor(props) {
    super();
    this.state = {
      SF: props.securitiesFilter,
      alertMessage: () => {
        return 'Maximum coins of 200 reached. Please delete coins to add new coins';
      },
      isFav: props.securitiesFilter.length && props.securitiesFilter.indexOf(props.tickerName) > -1 ? true : false,
      tickerName: props.tickerName,
    };
    this.OverlayRef = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      ...this.state,
      SF: nextProps.securitiesFilter,
      isFav:
        nextProps.securitiesFilter.length && nextProps.securitiesFilter.indexOf(nextProps.tickerName) > -1
          ? true
          : false,
      tickerName: nextProps.tickerName,
    });
  }

  addOrRemoveAsFav = (e, ActionType) => {
    e.preventDefault();
    e.stopPropagation();
    let { SF, tickerName, isFav } = this.state;
    let doNothing = false;
    if (ActionType) {
      if (SF.indexOf(tickerName) === -1) {
        if(TrendingMentionsHelper.FreeSubscriptionFilter()) {
          if(SF.length < 20 ) { //&& TrendingMentionsHelper.FreeCoinFilter({SecurityName : tickerName}, 50)
            SF.unshift(tickerName);
            isFav = true;
          } else {
            this.props.toggleConfirmPopup(true, true, 'Remove the limit of number of coins in your watchlist');
            doNothing = true;
          }
          
        } else if (SF.length < 200 && this.props.userRole !== 'admin') {
          SF.unshift(tickerName);
          isFav = true;
        } else if (this.props.userRole === 'admin') {
          SF.unshift(tickerName);
          isFav = true;
        } else {
          this.setState({
            ...this.state,
            showSweetAlert: true,
          });
        }
      }
    } else {
      if (SF.indexOf(tickerName) > -1) {
        SF.splice(SF.indexOf(tickerName), 1);
        isFav = false;
      }
    }
    if(!doNothing) {
      this.setState({
        ...this.state,
        SF,
        isFav,
      }, () => {
          let data = {
            UserName: this.props.userName,
            FilterString: this.state.SF.join(';'),
          };
          this.props.updateSecuritiesFilter(data);
        }
      );
    }
    
  };
  onCancelOfAlert = () => {
    this.setState({
      ...this.state,
      showSweetAlert: false,
    });
  };
  render() {
    const { isFav, showSweetAlert } = this.state;
    let ToolTipStyleData = {
      Arrow: {
        marginLeft: '0px',
      },
      ToolTip: {
        align: 'center',
      },
    };
    return (
      <span key={this.props.tickerName}>
        <button className="tooltip-button " style={{ color: '#96a2ab' }}>
          <span
            ref={this.OverlayRef}
            onMouseEnter={(e) =>
              this.props.toggleOverlay(
                true,
                this.OverlayRef,
                'Click to add/remove the coin in watchlist',
                ToolTipStyleData
              )
            }
            onMouseLeave={(e) => this.props.toggleOverlay(false)}
          >
            <span className="pointer" id={`span-for-tooltip-${this.props.tickerName}`}>
              {!isFav && (
                <span onClick={(e) => this.addOrRemoveAsFav(e, true)}>
                  <i className={`fa fa-regular fa-star-o`} style={{ fontSize: 14, color: this.props.Theme === 'light' ? '#747474' : '#96a2ab' }}></i>
                </span>
              )}
              {isFav && (
                <span onClick={(e) => this.addOrRemoveAsFav(e, false)}>
                  <i style={{ color: this.props.Theme === 'light' ? '#007bff' : '#50c0ff', fontSize: 14 }} className="fa fa-regular fa-star"></i>
                </span>
              )}
            </span>
          </span>
        </button>
        <span>
          {showSweetAlert && (
            <ConfirmationPopUp
              showOnlyOk={true}
              alertMessage={this.state.alertMessage}
              onConfirm={this.onCancelOfAlert}
              onCancel={this.onCancelOfAlert}
            />
          )}
        </span>
      </span>
    );
  }
}
const mapStateToProps = (state) => {
  // console.log(state.filters.securitiesFilter)
  return {
    userPackage: state.settings.userData.userPackage.toLowerCase(),
    securitiesFilter: state.filters.securitiesFilter,
    userRole: state.settings.userData.userRole.toLowerCase(),
    Theme: state.app_preferences.preferences.user_interface.Theme,
  };
};
const mapDispatchToProps = {
  updateSecuritiesFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(FavCoin);
