import React from 'react';
// import { Row, Col } from 'react-bootstrap';
import Toast from 'react-bootstrap/Toast'

const Alerts = ({
  AlertShow = false, 
  AlertTitle = '', 
  AlertContent = '', 
  AlertHideDelay = 30000, 
  AlertAutoHide = true, 
  AlertAnimate = true,
  ToggleAlert
}) => {
  return(
    <div className={`cralert`}>
      <Toast show={AlertShow} delay={AlertHideDelay} onClose={() => ToggleAlert(false)} animation={AlertAnimate} autohide={AlertAutoHide}>
        <Toast.Header><span className="mr-auto">{ AlertTitle }</span></Toast.Header>
        <p className="pad10 bold">
          { AlertContent }
        </p>
      </Toast>
    </div>
  )
}

export default Alerts;