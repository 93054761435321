import { APICONFIG, ErrorHandleHelper, PreferenceHandler } from '../modules/helper.module';
// import * as moment from 'moment';
import { NotifyFeedsHandler } from '../modules/helper.module';
import * as logactions from '../actions/logAction.action';
import Store from '../store';


async function callStoriesByLanguage(lang) {
  try {
    // let freeTrial = Store.getState().settings.freeTrial;
    // let daystogo = Store.getState().settings.freeTrialDetails.daystogo;
    // let userPackage = APICONFIG.apiLoggedInUserPackage();
    // if( userPackage.toLowerCase() === 'bronze' && freeTrial && daystogo > 0 ) {
    //   userPackage = 'Premium';
    // }
    let response = await fetch(
      `${APICONFIG.apiBaseUrl}/api/alerts/getalertsbylanguage?language=${lang}&Count=100`,
      {
        method: 'GET',
        headers: APICONFIG.apiHeaders(),
      }
    );
    let result = await response.json();
    // console.log(result)
    return result;
  } catch (err) {
    console.log(err);
    throw err;
  }
}
export function iframesearchevents(searchsavedata, searchdata) {
  return (dispatch) => {
    // console.log(searchdata, searchsavedata)
    let lang = Store.getState().settings.lang
    if (lang !== false) {
      callStoriesByLanguage(lang).then((result) => {
        // console.log(result)
        let searchFeedsList = {};
        let searchFeedsIndexedRef = [];
        if (result.length) {
          let magfilteredresult = NotifyFeedsHandler.filterMagnitude_Iframe(result)
          let correctedresult = NotifyFeedsHandler.fixeventmissingprops(magfilteredresult);
          // console.log(correctedresult)
          let downgradedFilterList = NotifyFeedsHandler.FormatandDowngradeEvents(correctedresult);
          // console.log(downgradedFilterList)
          let algolocationfilteredresult = NotifyFeedsHandler.FormatByAlgolocationFilter(downgradedFilterList)
          // console.log(algolocationfilteredresult)
          let searchedassetfilteredresult = NotifyFeedsHandler.FormatBySearchedAssetfilter(algolocationfilteredresult, searchdata.sec.join(';'))
          // console.log(searchedassetfilteredresult)
          let eventtypeformattedresult = NotifyFeedsHandler.FormatEventtypes(searchedassetfilteredresult);
          let { rootFeedsList, rootFeedsIndexedRef } = NotifyFeedsHandler.FormatEventsBySecuritiesFilter(eventtypeformattedresult);
          searchFeedsList = rootFeedsList
          searchFeedsIndexedRef = rootFeedsIndexedRef
          let respo = {
            eventResult: result,
            searchStories: searchFeedsList,
            searchStoriesIndex: searchFeedsIndexedRef,
          };
          respo.savedsearchData = {
            saveSearchId: searchsavedata.UserSavedDataID,
            searchString: searchdata.sec.join(';'),
            activeSearchTrackingStatus: true,
            startDate: searchdata.startDate,
            endDate: searchdata.endDate,
          };
          dispatch({
            type: 'GET_SAVED_SEARCH_FILTERED_STORIES',
            payload: respo,
          });
          // dispatch({
          //   type: 'DISABLE_APPLOADING',
          // });
        }
      })
    }
    // dispatch({
    //   type: 'UPDATE_PINNED_SEARCH_COUNT',
    // });
  }
}

export function updatepinnedSearchCount() {
  return (dispatch) => {
    dispatch({
      type: 'UPDATE_PINNED_SEARCH_COUNT',
    });
  }
}
export function saveCurrentSearch_LLU(searchData) {
  return (dispatch) => {
    try {
      dispatch({
        type: 'SET_SAVED_SEARCH',
        payload: {
          [`${searchData.UserSavedDataID}`]: searchData,
        },
      });

    } catch (err) {
      console.log(err)
    }

  };
}
export function saveCurrentSearch(searchData) {
  return (dispatch) => {
    try {
      fetch(`${APICONFIG.apiBaseUrl}/api/userpreferences/savesearchforuser`, {
        method: 'POST',
        headers: APICONFIG.apiHeaders(),
        body: JSON.stringify(searchData),
      })
        .then((response) => response.json())
        .then((result) => {
          if (result !== 0) {
            searchData.UserSavedDataID = result;

            dispatch({
              type: 'SET_SAVED_SEARCH',
              payload: {
                [`${result}`]: searchData,
              },
            });
          }
        })
        .catch((err) => {
          console.log('error in saveCurrentSearch', err)
          // logactions.logger({Message: 'error in saveCurrentSearch'+ err})
          // throw new Error('error in  saveCurrentSearch' + err);
          let data = {
            username: searchData.userName,
            action: `Saving Search ${searchData.SavedDataTitle}`,
            url: `${APICONFIG.apiBaseUrl}/api/userpreferences/savesearchforuser`,
            severity: 'high'
          };
          dispatch({
            type: 'REQUEST_ERROR',
            payload: ErrorHandleHelper.generateErrorString(err, 'SaveCurrentSearch'),
            data: data,
          });
        });
    } catch (err) {
      // logactions.addLogAction(err)
    }

  };
}

export function initSearchSave(status, searchTitle = '') {
  return (dispatch) => {
    try {
      dispatch({
        type: 'INIT_SEARCH_SAVE',
        payload: status,
        title: searchTitle,
      });
    } catch (err) {
      // logactions.logger({Message: 'error in initSearchSave'+ err})
      // logactions.addLogAction('error in initSearchSave :' + err)
    }

  };
}

export function initPinnedSearchSave(status, listkey = "") {
  return (dispatch) => {
    try {
      dispatch({
        type: 'INIT_PINNED_SEARCH_SAVE',
        payload: status,
        listkey
      });
    } catch (err) {
      // logactions.logger({Message: 'error in initSearchSave'+ err})
      // logactions.addLogAction('error in initSearchSave :' + err)
    }

  };
}

export function resetSearchData() {
  return (dispatch) => {
    try {
      dispatch({
        type: 'RESET_SEARCH_DATA',
        payload: {},
      });
    } catch (err) {
      // logactions.logger({Message: 'error in resetSearchData'+ err})
      // logactions.addLogAction('error in resetSearchData :' + err)
    }

  };
}

export function searchEvents(
  all,
  event,
  securityTypes,
  securities,
  startDate,
  endDate,
  freeText,
  sourcefilters,
  searchedString,
  searchType = 'EventSearch',
  saveSearchId = false,
  withCurated = true,
  withBlack = true,
  withWhite = true,
  withYellow = true,
  withOrange = true,
  withRed = true,
  updateHandle = null
) {
  // console.log('called here')
  // export function searchEvents(all,event,location,securityTypes, securities, mentionTypes, startDate, endDate, freeText, searchedString, searchType = 'EventSearch', saveSearchId = false, withCurated = true, withBlack = true, updateHandle = null) {
  // console.log(startDate, endDate)
  return (dispatch) => {
    try {
      fetch(
        `${APICONFIG.apiBaseUrl
        }/api/alerts/getalertsbysearch?all=${false}&userName=${APICONFIG.apiLoggedInUser()}&eventType=${event}&securityTypes=${securityTypes}&securities=${securities}&startDate=${startDate}&endDate=${endDate}&freeText=${freeText}${all ? '&update=true' : '&update=false'
        }${'&withCurated=' + withCurated}${'&withBlack=' + withBlack}${'&withWhite=' + withWhite}${'&withYellow=' + withYellow}${'&withOrange=' + withOrange
        }${'&withRed=' + withRed}${'&userPackage=' + APICONFIG.apiLoggedInUserPackage()}`,
        {
          //&locations=${''}, attributes=${''} fetch(`${APICONFIG.apiBaseUrl}/api/alerts/getalertsbysearch?all=${false}&userName=${APICONFIG.apiLoggedInUser()}&eventType=${event}&locations=${location}&securityTypes=${securityTypes}&securities=${securities}&attributes=${mentionTypes}&startDate=${startDate}&endDate=${endDate}&freeText=${freeText}${all ? '&update=true' : '&update=false'}${'&withCurated='+withCurated}${'&withBlack='+withBlack}`,{
          method: 'GET',
          headers: APICONFIG.apiHeaders(),
        }
      )
        .then((response) => response.json())
        .then((result) => {
          // console.log(result)
          let searchFeedsList = {};
          let searchFeedsIndexedRef = [];
          if (result.length) {
            // let feedIds = result.map(rs => (rs.FeedId))
            // console.log(feedIds.indexOf(35997044) > -1)
            // let uniqueFeedIds = Array.from(new Set(feedIds))
            // console.log(result, sourcefilters)
            let filteredResult = []
            if (sourcefilters !== '') {
              filteredResult = NotifyFeedsHandler.FilterEventsBySourceInLocation(result, sourcefilters);
            } else {
              filteredResult = result;
            }
            // console.log('from search',withWhite);
            // console.log('search filters')
            // console.log('WIth Black', withBlack)
            // console.log('WIth Gray', withCurated)
            // console.log('WIth White', withWhite)
            // console.log('WIth Yellow', withYellow)
            // console.log('WIth orange', withOrange)
            // console.log('WIth red', withRed)
            let downgradefilteredresult = NotifyFeedsHandler.FormatandDowngradeEvents(filteredResult);
            // if(downgradefilteredresult.length) {
            //   downgradefilteredresult.forEach(rs => {
            //     if(rs.FeedId === 49311266) {
            //       console.log(rs)
            //     }
            //   })
            // }
            let filtObj = {
              withBlack,
              withGray: withCurated,
              withWhite,
              withYellow,
              withOrange,
              withRed
            }
            let filteredoutdowngradedevents = NotifyFeedsHandler.FilterMagnitudeSearch(downgradefilteredresult, filtObj);
            // if(filteredoutdowngradedevents.length) {
            //   filteredoutdowngradedevents.forEach(rs => {
            //     if(rs.FeedId === 49311266) {
            //       console.log(rs)
            //     }
            //   })
            // }

            // console.log(filteredResult)
            let algolocationfilteredresult = NotifyFeedsHandler.FormatByAlgolocationFilter(filteredoutdowngradedevents)
            // console.log(securities)
            // console.log(algolocationfilteredresult)
            let searchedassetfilteredresult = NotifyFeedsHandler.FormatBySearchedAssetfilter(algolocationfilteredresult, securities)
            let { rootFeedsList, rootFeedsIndexedRef } = NotifyFeedsHandler.FormatEventsBySecuritiesFilter(searchedassetfilteredresult);
            // console.log(rootFeedsList, rootFeedsIndexedRef)
            searchFeedsList = rootFeedsList
            searchFeedsIndexedRef = rootFeedsIndexedRef
            // result.map((rs) => {
            //   if (rs) {
            //     // if(uniqueFeedIds.indexOf(rs.FeedId) > -1) {
            //     // console.log(result)
            //       // if(NotifyFeedsHandler.EventSecurityFilterPass(rs)) {
            //       //   if(NotifyFeedsHandler.EventTypeFilterPass(rs)) {
            //       //     if(NotifyFeedsHandler.FilterMagnitude(rs)) {
            //             searchFeedsList[`${rs.FeedId}`] = NotifyFeedsHandler.EventTimeToLocal(rs);
            //             // searchFeedsList[`${rs.FeedId}`].EventReadStatus = true;
            //             searchFeedsIndexedRef.push({
            //               FeedId: rs.FeedId,
            //               SortByDate: rs.SortByDate,
            //               notifyEvent: rs.NotifyEvent,
            //               magnitudeRate: rs.MagnitudeRate,
            //             });
            //       //     }
            //       //   }
            //       // }
            //     // }
            //   }
            //   return null;
            // });
          }
          // console.log(result)
          // console.log('from search')
          // console.log(NotifyFeedsHandler.FormatEventsByPackage(result))
          // let { rootFeedsList, rootFeedsIndexedRef } = NotifyFeedsHandler.FormatEventsByPackage(result);
          // console.log(rootFeedsList, rootFeedsIndexedRef)
          let respo = {
            eventResult: result,
            searchStories: searchFeedsList,
            searchStoriesIndex: searchFeedsIndexedRef,
          };
          if (searchType === 'EventSearch') {
            respo.searchData = {
              searchString: searchedString,
              activeStatus: true,
              event: event,
              // location: location.split(';'),
              startDate: startDate,
              endDate: endDate,
            };
            dispatch({
              type: 'GET_SEARCH_FILTERED_STORIES',
              payload: respo,
            });
          } else if (searchType === 'SavedSearch') {
            respo.savedsearchData = {
              saveSearchId: saveSearchId,
              searchString: searchedString,
              activeSearchTrackingStatus: true,
              startDate: startDate,
              endDate: endDate,
            };
            if (all) {
              // console.log("we are at the searchEvents")
              //console.log(result)
              // console.log('here update')
              respo.updateHandle = updateHandle;
              dispatch({
                type: 'UPDATE_SAVED_SEARCH_FILTERED_STORIES',
                payload: respo,
              });
            } else {
              // console.log('here loading search')
              dispatch({
                type: 'GET_SAVED_SEARCH_FILTERED_STORIES',
                payload: respo,
              });
            }
          }
        })
        .catch((err) => {
          console.log('error in searchEvents', err)
          // logactions.logger({Message: 'error in searchEvents'+ err})
          // throw new Error('error in  searchEvents' + err);
          // console.log(err.toString())
          let data = {
            username: APICONFIG.apiLoggedInUser(),
            action: 'Search Events',
            severity: 'high',
            url: `${APICONFIG.apiBaseUrl
              }/api/alerts/getalertsbysearch?all=${all}&userName=${APICONFIG.apiLoggedInUser()}&eventType=${event}&locations=${''}&securityTypes=${securityTypes}&securities=${securities}&attributes=${''}&startDate=${startDate}&endDate=${endDate}`,
            // url: `${APICONFIG.apiBaseUrl}/api/alerts/getalertsbysearch?all=${all}&userName=${APICONFIG.apiLoggedInUser()}&eventType=${event}&locations=${location}&securityTypes=${securityTypes}&securities=${securities}&attributes=${mentionTypes}&startDate=${startDate}&endDate=${endDate}`
          };
          dispatch({
            type: 'REQUEST_ERROR',
            payload: ErrorHandleHelper.generateErrorString(err, 'SearchEvents'),
            data: data,
          });
        });
    } catch (err) {
      // logactions.addLogAction(err)
    }

  };
}

export function singlecoinsearched() {
  return (dispatch) => {
    dispatch({
      type: 'SEARCH_COUNT_INCREMENT_ON_COIN',
      payload: true,
    });
  }
}

export function loadSavedSearch() {
  return (dispatch) => {
    try {
      fetch(
        `${APICONFIG.apiBaseUrl}/api/userpreferences/getsavedsearchesforuser?userName=${APICONFIG.apiLoggedInUser()}`,
        {
          method: 'GET',
          headers: APICONFIG.apiHeaders(),
        }
      )
        .then((response) => response.json())
        .then((result) => {
          let SavedSearchData = {};
          if (result.length) {
            result.forEach((dt) => {
              SavedSearchData[`${dt.UserSavedDataID}`] = dt;
            });
          }
          dispatch({
            type: 'SET_SAVED_SEARCH',
            payload: SavedSearchData,
          });
          // console.log('Loaded Saved Search - data to reducer')
          // console.log(SavedSearchData)
          // let columnorderlist = Store.getState().settings.columnorderlist;
          //     console.log('column order list');
          //     console.log(columnorderlist);
          //     console.log('Saved search data');
          //     console.log(SavedSearchData);
          // let {
          //   columnOrder,
          //   SearchSaveData,
          //   savedsearchcleanupdata
          // } = PreferenceHandler.CleanupSavedSearchColumnOrder(columnorderlist, SavedSearchData);
          // // return;
          // dispatch ({
          //   type : 'SET_COLUMN_ORDER_LIST',
          //   payload : columnOrder
          // })
          // dispatch({
          //   type: 'SET_SAVED_SEARCH',
          //   payload: SearchSaveData,
          // });

          // if(savedsearchcleanupdata.length) {
          //   console.log('calling delete saved search for clean up')
          //   savedsearchcleanupdata.forEach(async(data) => {
          //     await cleanupsavedsearch(data)
          //   })
          // }
        });
    } catch (err) {
      console.log(err)

      // logactions.logger({Message: 'error in loadSavedSearch'+ err})
      // logactions.addLogAction('error in loadSavedSearch: ' +err)
    }

  };
}

export function syncsavedsearchcolumnordersettings() {
  return (dispatch) => {
    let columnorderlist = Store.getState().settings.columnorderlist;
    let SavedSearchData = Store.getState().search.searchSaveData;

    // console.log('column order list');
    // console.log(columnorderlist);
    // console.log('Saved search data');
    // console.log(SavedSearchData);
    let {
      columnOrder,
      SearchSaveData,
      savedsearchcleanupdata
    } = PreferenceHandler.CleanupSavedSearchColumnOrder(columnorderlist, SavedSearchData);

    // console.log(columnOrder, SearchSaveData, savedsearchcleanupdata)

    // return;
    dispatch({
      type: 'SET_COLUMN_ORDER_LIST',
      payload: columnOrder
    })
    dispatch({
      type: 'SET_SAVED_SEARCH',
      payload: SearchSaveData,
    });

    if (savedsearchcleanupdata.length) {
      console.log('calling delete saved search for clean up')
      savedsearchcleanupdata.forEach(async (data) => {
        await cleanupsavedsearch(data)
      })
    }
  }
}

export function setSaveSearchFeedReqData(feedreqdata, saveid) {
  return (dispatch) => {
    try {
      dispatch({
        type: 'SET_SAVESEARCH_FEEDREQDATA',
        payload: feedreqdata,
        saveid,
      });
    } catch (err) {
      // logactions.logger({Message: 'error in setSaveSearchFeedReqData'+ err})
      // logactions.addLogAction('error in setSaveSearchFeedReqData: ' +err)
    }


  };
}

async function cleanupsavedsearch(saveData) {
  try {
    if (typeof saveData !== 'undefined') {
      let response = await fetch(`${APICONFIG.apiBaseUrl}/api/userpreferences/deletesavedsearcheforuser`, {
        method: 'POST',
        headers: APICONFIG.apiHeaders(),
        body: JSON.stringify(saveData),
      })
      if (response.status === 200 && response.ok) {
        return true;
      }
      throw new Error(`Message: Delete Saved Search Failed Saved Search id: ${saveData.UserSavedDataID}`)
    }
  } catch (err) {
    // logactions.addLogAction(err)
    console.log(err)
    throw err;
  }
}

export function deleteSavedSearch_LLU(saveData) {
  return (dispatch) => {
    try {
      dispatch({
        type: 'DELETE_USER_SEARCH',
        payload: saveData.UserSavedDataID,
      });

    } catch (err) {
      console.log(err)
      // logactions.addLogAction(err)
    }

  };
}

export function deleteSavedSearch(saveData) {
  console.log(saveData)
  return (dispatch) => {
    try {
      if (typeof saveData !== 'undefined') {
        fetch(`${APICONFIG.apiBaseUrl}/api/userpreferences/deletesavedsearcheforuser`, {
          method: 'POST',
          headers: APICONFIG.apiHeaders(),
          body: JSON.stringify(saveData),
        })
          .then((result) => {
            // console.log(result)

            dispatch({
              type: 'DELETE_USER_SEARCH',
              payload: saveData.UserSavedDataID,
            });
          })
          .catch((err) => {
            console.log('error in deleteSavedSearch', err)
            // logactions.logger({Message: 'error in deleteSavedSearch'+ err})
            // throw new Error('error in  deleteSavedSearch' + err);
            let data = {
              username: saveData.userName,
              action: `Saving Search ${saveData.SavedDataTitle}`,
              url: `${APICONFIG.apiBaseUrl}/api/userpreferences/deletesavedsearcheforuser`,
              severity: 'low'
            };
            dispatch({
              type: 'REQUEST_ERROR',
              payload: ErrorHandleHelper.generateErrorString(err, 'DeleteSavedSearch'),
              data: data,
            });
          });
      }

    } catch (err) {
      // logactions.addLogAction(err)
    }

  };
}


export function updateSavedSearch_LLU(saveData, updatedKey) {
  return (dispatch) => {
    try {

      dispatch({
        type: 'UPDATE_USER_SEARCH',
        payload: saveData,
        searchid: saveData.UserSavedDataID,
        updatedKey: updatedKey,
      });

    } catch (err) {
      console.log(err)
      // logactions.addLogAction(err)
    }

  };
}

export function updateSavedSearch(saveData, updatedKey) {
  return (dispatch) => {
    try {
      fetch(`${APICONFIG.apiBaseUrl}/api/userpreferences/updatesavedsearcheforuser`, {
        method: 'POST',
        headers: APICONFIG.apiHeaders(),
        body: JSON.stringify(saveData),
      })
        .then((result) => {
          // console.log(saveData);

          dispatch({
            type: 'UPDATE_USER_SEARCH',
            payload: saveData,
            searchid: saveData.UserSavedDataID,
            updatedKey: updatedKey,
          });
        })
        .catch((err) => {
          console.log('error in updateSavedSearch', err)
          // logactions.logger({Message: 'error in updateSavedSearch'+ err})
          // throw new Error('error in  updateSavedSearch' + err);
          let data = {
            username: saveData.userName,
            action: `Updating Search ${saveData.SavedDataTitle}`,
            url: `${APICONFIG.apiBaseUrl}/api/userpreferences/updatesavedsearcheforuser`,
            severity: 'low'
          };
          dispatch({
            type: 'REQUEST_ERROR',
            payload: ErrorHandleHelper.generateErrorString(err, 'UpdateSavedSearch'),
            data: data,
          });
        });
    } catch (err) {
      // logactions.addLogAction(err)
    }

  };
}

export function ReSearch(val) {
  return (dispatch) => {
    try {
      dispatch({
        type: 'RE_SEARCH',
        payload: val,
      });
    } catch (err) {
      logactions.logger({ Message: 'error in ReSearch' + err })
      // logactions.addLogAction('error in ReSearch: ' +err)
    }

  };
}

export function searchEventsOnFilters(secTypes, secs, startDate, endDate, withCurated) {
  return (dispatch) => {
    try {
      fetch(
        `${APICONFIG.apiBaseUrl
        }/api/alerts/getalertsbysearch?all=${false}&userName=${APICONFIG.apiLoggedInUser()}&eventType=${''}&locations=${''}&securityTypes=${secTypes}&securities=${secs}&attributes=${''}&startDate=${startDate}&endDate=${endDate}&freeText=${''}&update=false${'&withCurated=' + withCurated
        }${'&withBlack=false'}`,
        {
          method: 'GET',
          headers: APICONFIG.apiHeaders(),
        }
      )
        .then((response) => response.json())
        .then((result) => {
          // console.log(result)
          if (result.length) {
            const { rootFeedsList, rootFeedsIndexedRef } = NotifyFeedsHandler.FormatEventsByPackage(result);
            dispatch({
              type: 'GET_STORIES_BY_PACKAGE',
              payload: result,
              Stories: rootFeedsList,
              StoriesIndex: rootFeedsIndexedRef,
            });
          }
        })
        .catch((err) => {
          console.log('error in searchEventsOnFilters', err)
          // logactions.logger({Message: 'error in searchEventsOnFilters'+ err})
          // throw new Error('error in  searchEventsOnFilters' + err);
          // console.log('Error while searching events on asset filters', err);
          let data = {
            username: APICONFIG.apiLoggedInUser(),
            action: `searchEventsOnFilters`,
            url: `${APICONFIG.apiBaseUrl}/api/alerts/getalertsbysearch?all=${false}&userName=${APICONFIG.apiLoggedInUser()}&eventType=${''}&locations=${''}&securityTypes=${secTypes}&securities=${secs}&attributes=${''}&startDate=${startDate}&endDate=${endDate}&freeText=${''}&update=false${'&withCurated=' + withCurated}${'&withBlack=false'}`,
            severity: 'low'
          };
          dispatch({
            type: 'REQUEST_ERROR',
            payload: ErrorHandleHelper.generateErrorString(err, 'searchEventsOnFilters'),
            data: data,
          });
        });
    } catch (err) {
      // logactions.addLogAction(err)
    }

  };
}

export function getTickerDetailsOnSingleSearch(TM) {
  return (dispatch) => {
    try {
      fetch(`${APICONFIG.apiBaseUrl}/api/summeraization/getcoindata?securityTicker=${TM}`, {
        method: 'GET',
        headers: APICONFIG.apiHeaders(),
      })
        .then((response) => response.json())
        .then((result) => {
          dispatch({
            type: 'SEARCHED_COIN_DETAILS',
            payload: result,
          });
        })
        .catch((err) => {
          console.log('error in getTickerDetailsOnSingleSearch', err)
          // logactions.logger({Message: 'error in getTickerDetailsOnSingleSearch'+ err})
          // throw new Error('error in  getTickerDetailsOnSingleSearch' + err);
          // console.log('ErroroCoinDetails', err);
          let data = {
            username: APICONFIG.apiLoggedInUser(),
            action: 'Coin Data',
            url: `${APICONFIG.apiBaseUrl}/api/summeraization/getcoindata?securityTicker=${TM.SecurityTicker || TM}`,
            severity: 'low'
          };
          dispatch({
            type: 'REQUEST_ERROR',
            payload: ErrorHandleHelper.generateErrorString(err, 'getTickerDetailsOnSingleSearch'),
            data: data,
          });
        });
    } catch (err) {
      // logactions.addLogAction(err)
    }

  };
}
