import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { APPPATH } from '../../../../modules/helper.module';
import Logo from '../../headbar/logo';
import { connect } from 'react-redux';
import { TrendingMentionsHelper } from '../../../../modules/helper.module';


class WelcomeScreen extends Component {
  constructor(props) {
    super();
  }
  render() {
    return (
      <div className={`watchlist-content justify-content-center text-center ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>
        <div className="btn tradeWindowClose text-white nopad marg-0" onClick={() => {
          this.props.welcomemodeon(false);
          this.props.modalClose(true, 2)
        }}>
          <span className="pad10">
            <img className="tradeWindowClose-closeicon" src={`${APPPATH.ImageUrl}/button-close.svg`} alt="" />
          </span>
        </div>
        <Row className="marg-0 storymenuoptions-tabheader">
          <Col lg={12} sm={12} xs={12} md={12} xl={12} className="nopad text-center align-items-to-center margin">
            <Logo noTitle={false} align={true} beta={false} userpackage={this.props.userData.userPackage.toLowerCase()} infreetrial={TrendingMentionsHelper.FreeSubscriptionFilter()} />
          </Col>
          <Col lg={12} sm={12} xs={12} md={12} xl={12} className="nopad" style={{marginTop: '-20px', marginBottom: '10px'}}>
            <div>
              <span className={`welcomecaption ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>
                {
                  (this.props.userData.usertype !== 'None' && (this.props.userData.usertype !== 'WUser' && this.props.userData.usertype !== 'WAdmin')) ? (
                    'All the information about your assets in one place'
                  ) : (
                    'All the information about your assets in one place'
                  )
                }
                
              </span>
            </div>
          </Col>
          <Col lg={12} sm={12} xs={12} md={12} xl={12} className="nopad">
            <div>
              <span className={`WelcomeTitle ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>Let's Get Started!</span>
            </div>
            {/* <div>
              <span className="WelcomeTitle-A">Follow these steps to personalize your experience</span>
            </div> */}
          </Col>
          <Col lg={12} sm={12} xs={12} md={12} xl={12} className="nopad">
            <Row className="align-items-to-center">
              <Col lg={6} sm={6} xs={12} md={6} xl={6} className="nopad text-left margin align-items-to-center">
                <ul className="WelcomeActionList">
                  <li className="WelcomeActionItem">
                    <span className={`WelcomeActionItemnumber ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>1</span> Update your Watchlist
                  </li>
                  <li className="WelcomeActionItem">
                    <span className={`WelcomeActionItemnumber ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>2</span> Chose your Event Filters
                  </li>
                  <li className="WelcomeActionItem">
                    <span className={`WelcomeActionItemnumber ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>3</span> Set up your Preferences
                  </li>
                  {
                    (this.props.userData.usertype === 'None' || this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin') ? (
                      <li className="WelcomeActionItem">
                        <span className={`WelcomeActionItemnumber ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>4</span> Watch welcome video
                      </li>
                    ) : ('')
                  }
                  
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className={`marg-0 justify-content-center  pad5 text-center`}>
          <Col lg={3} sm={3} xs={3} md={3} xl={3} className="text-left watchlistNavigatorButtons ">
            {/* <span
              className="pointer"
              onClick={() => {
                window.Mixpanel.MixpanelProps.UserName = this.props.userdata.username;
                window.Mixpanel.actions.identify(this.props.userdata.username);
                window.Mixpanel.actions
                  .track('Clicking back to Intro video', window.Mixpanel.MixpanelProps)
                  .then((data) => {
                    window.Mixpanel.actions.people.set({
                      UserName: this.props.userdata.username,
                    });
                  });
                this.props.changeView('IntroVideo');
              }}
            >
              Back
            </span> */}
          </Col>
          <Col lg={6} sm={6} xs={6} md={6} xl={6} className="text-center pad5 ">
            <div className={``}>
              <span className={`wizard-step-dot ${this.props.Theme === 'light' ? 'lightmode' : ''} active`}></span>
              <span className={`wizard-step-dot ${this.props.Theme === 'light' ? 'lightmode' : ''}`}></span>
              <span className={`wizard-step-dot ${this.props.Theme === 'light' ? 'lightmode' : ''}`}></span>
              <span className={`wizard-step-dot ${this.props.Theme === 'light' ? 'lightmode' : ''}`}></span>
              {
                (this.props.userdata.usertype === 'None' || this.props.userdata.usertype === 'WUser' || this.props.userdata.usertype === 'WAdmin') ? (
                  <span className={`wizard-step-dot ${this.props.Theme === 'light' ? 'lightmode' : ''}`}></span>
                ) : ('')
              }
              
            </div>
          </Col>
          <Col lg={3} sm={3} xs={3} md={3} xl={3} className={`text-right watchlistNavigatorButtons ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>
            <span
              className="pointer"
              onClick={() => {
                window.Mixpanel.MixpanelProps.UserName = this.props.userdata.username;
                window.Mixpanel.actions.identify(this.props.userdata.username);
                window.Mixpanel.actions
                  .track('Watchlist in welcome screen', window.Mixpanel.MixpanelProps)
                  .then((data) => {
                    window.Mixpanel.actions.people.set({
                      UserName: this.props.userdata.username,
                    });
                  });
                this.props.changeView('WatchList');
              }}
            >
              Next
            </span>
          </Col>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userData: state.settings.userData,
    username: state.settings.userData.username,
    Theme: state.app_preferences.preferences.user_interface.Theme,
  }
}


export default connect(mapStateToProps, null)(WelcomeScreen);

