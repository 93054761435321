import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { APPPATH } from '../../../../modules/helper.module';
import { isMobile } from 'react-device-detect';
import moment from 'moment';

class IntroVideo extends Component {
  constructor(props) {
    super();
    this.state = {
      thumbnail: true
    };
  }

  toggleThumbnail = () => {
    this.setState({
      ...this.state,
      thumbnail : !this.state.thumbnail
    })
  }

  finishWelcomLoginMixpanel() {
    window.Mixpanel.MixpanelProps.UserName = this.props.userdata.username;
    window.Mixpanel.actions.identify(this.props.userdata.username);

    window.Mixpanel.actions.track(`End of welcome process`, window.Mixpanel.MixpanelProps).then((data) => {
      window.Mixpanel.actions.people.set({
        UserName: this.props.userdata.username,
      });
    });
  }

  render() {
    const { thumbnail } = this.state;
    const { SecuritiesChecklist, EventTypesCheckList, welcomemode } = this.props;
    const condition =
      !(SecuritiesChecklist && EventTypesCheckList);
      // console.log(condition)
    return (
      <div className={`watchlist-content ${this.props.Theme === 'light' ? 'lightmode' : ''} justify-content-center text-center ${isMobile ? 'h-mobilevhintro' : ''}`}>
      <div className="btn tradeWindowClose text-white nopad marg-0" onClick={() => {
        
        this.props.welcomemodeon(false)
        this.props.modalClose(condition, 1)
      }}>
        <span className="pad10">
          <img className="tradeWindowClose-closeicon" src={`${APPPATH.ImageUrl}/button-close.svg`} alt="" />
        </span>
      </div>

        {(condition || welcomemode) && (
          <Row className="marg-0 storymenuoptions-tabheader">
            <Col className="nopad">
              <div className={`nav nav-tabs watchlist nav-fill ${this.props.Theme === 'light' ? 'lightmode' : ''} `}>
                <div className={`nav-item font-video active`}>
                  <span>Welcome!</span>
                </div>
              </div>
            </Col>
            <Col lg={12} sm={12} xs={12} md={12} xl={12} className="nopad">
              <div>
                <span className={`WelcomeTitle-A ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>Watch this quick video to know how to use CrowdSense!</span>
              </div>
            </Col>
          </Row>
        )}
        <Row className="marg-0 justify-content-center  pad5 text-center">
          <Col lg={12} sm={12} xs={12} md={12} xl={12} className={` text-left ${isMobile ? 'h-mobilevhminintro' : 'h-intro'}`}>
            <div className={`iframe-introvid-thumbnail-container  ${thumbnail ? '' : 'nodisp'} `}  style={{marginTop: isMobile ? '50%' : '15%', marginLeft: isMobile? '40%' : '45%'}}>
              <i className="fa fa-spinner fa-2x fa-spin storyloadindicator"></i>
            </div>
           
            <div className={`iframe-introvid-container  ${!thumbnail ? '' : 'nodisp'} ${isMobile ? 'intro' : ''}`}>
              {
                isMobile ? (
                   
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/kfpKBmae3Gg?mute=1" autoplay="autoplay" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" onLoad={this.toggleThumbnail} allowFullScreen></iframe>                 
                ) : (
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/Z4u-Id70HyY?mute=1" autoplay="autoplay"  title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" onLoad={this.toggleThumbnail}  allowFullScreen></iframe>
                  
                )
              }
              
              
            </div>
          </Col>
        </Row>
        <Row className={`marg-0 justify-content-center  pad5 text-center`}>
          <Col lg={3} sm={3} xs={3} md={3} xl={3} className={`text-left watchlistNavigatorButtons ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>
            <span
              className="pointer"
              onClick={() => {
                window.Mixpanel.MixpanelProps.UserName = this.props.userdata.username;
                window.Mixpanel.actions.identify(this.props.userdata.username);
                window.Mixpanel.actions
                  .track('Clicking back to Intro video', window.Mixpanel.MixpanelProps)
                  .then((data) => {
                    window.Mixpanel.actions.people.set({
                      UserName: this.props.userdata.username,
                    });
                  });
                this.props.changeView('UserPreferenceList');
              }}
            >
              Back
            </span>
          </Col>
          <Col lg={6} sm={6} xs={6} md={6} xl={6} className="text-center pad5 ">
            <div className={`${(!SecuritiesChecklist && !EventTypesCheckList) || welcomemode ? '' : 'nodisp'}`}>
              <span className={`wizard-step-dot ${this.props.Theme === 'light' ? 'lightmode' : ''}`}></span>
              <span className={`wizard-step-dot ${this.props.Theme === 'light' ? 'lightmode' : ''}`}></span>
              <span className={`wizard-step-dot ${this.props.Theme === 'light' ? 'lightmode' : ''}`}></span>
              <span className={`wizard-step-dot ${this.props.Theme === 'light' ? 'lightmode' : ''}`}></span>
              <span className={`wizard-step-dot ${this.props.Theme === 'light' ? 'lightmode' : ''} active`}></span>
            </div>
          </Col>
          <Col lg={3} sm={3} xs={3} md={3} xl={3} className={`text-right watchlistNavigatorButtons ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>
            <span
              className="pointer"
              onClick={() => {
                window.Mixpanel.MixpanelProps.UserName = this.props.userdata.username;
                window.Mixpanel.MixpanelProps.WelcomefinishedAt = moment().format('DD/MM/YYYY HH:mm:s A');
                window.Mixpanel.actions.identify(this.props.userdata.username);

                window.Mixpanel.actions.track(`Welcome Finished`, window.Mixpanel.MixpanelProps).then((data) => {
                  window.Mixpanel.actions.people.set({
                    UserName: this.props.userdata.username,
                  });
                  this.finishWelcomLoginMixpanel();
                  this.props.hardRefreshSystem();
                });
                // if (condition || welcomemode) {
                  
                //   this.props.modalClose();
                //   window.Mixpanel.MixpanelProps.UserName = this.props.userdata.username;
                //   window.Mixpanel.actions.identify(this.props.userdata.username);
                //   window.Mixpanel.actions
                //     .track('List of Steps on Welcome', window.Mixpanel.MixpanelProps)
                //     .then((data) => {
                //       window.Mixpanel.actions.people.set({
                //         UserName: this.props.userdata.username,
                //       });
                //     });
                //   this.props.WelcomeScreen();
                // } else {
                //   this.props.modalClose();
                // }
              }}
            >
              {/* {!condition ? 'Ok' : 'Continue'} */}
              {'Confirm'}
            </span>
          </Col>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    welcomemode: state.settings.welcomemode,
    SecuritiesChecklist: state.settings.checkListStatus.SecuritiesCheckList,
    EventTypesCheckList: state.settings.checkListStatus.EventTypesCheckList,
    Theme: state.app_preferences.preferences.user_interface.Theme,
  };
};

export default connect(mapStateToProps, {})(IntroVideo);
