import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';

// import * as moment from 'moment';
import { TMViewDetails } from '../../actions/trendingmentions.action';
import { connect } from 'react-redux';
import { APPPATH, TrendingMentionsHelper } from '../../modules/helper.module';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import { isMobile } from 'react-device-detect';
import GraphInCoinCol from './GraphInCoinCol';
import NavbarCollapse from 'react-bootstrap/esm/NavbarCollapse';
// import LinesEllipsis from 'react-lines-ellipsis'

class TickerDetails extends Component {
  constructor(props) {
    super();

    this.state = {
      TM: props.TM,
      modalData: {
        TM: props.TM,
        templateType: props.tdecktype === 'tmcd' ? typeof props.metric !== 'undefined' && props.metric !== '' ? props.metric : 'Sentiment' : 'Sentiment',
      },
      fullDescription: false,
      DescriptionButtonHover: false,
    };

    this.OverlayRef = React.createRef();
    this.OverlayRef4 = React.createRef();

    this.OverlayRef5 = React.createRef();
    this.OverlayRef6 = React.createRef();
    this.OverlayRef7 = React.createRef();
    this.OverlayRef8 = React.createRef();
    this.OverlayRef9 = React.createRef();
    this.OverlayRef10 = React.createRef();
    this.OverlayRef901 = React.createRef();
    this.OverlayRef902 = React.createRef();
    this.OverlayRef903 = React.createRef();
    this.OverlayRefEmotions = React.createRef();
    this.OverlayRefSentimentScore = React.createRef();
    this.OverlayRefSentimentChange = React.createRef();
    this.OverlayRefMentionsChange = React.createRef();
    this.OverlayRefReachChange = React.createRef();
    this.OverlayRefPriceChange = React.createRef();
  }

  componentDidMount() {
    
  }

  componentWillReceiveProps(nextProps) {
    // if (JSON.stringify(this.props.TM.SecurityName) !== JSON.stringify(nextProps.TM.SecurityName)) {
    // console.log('this.props', this.props.TM.SecurityName);
    // console.log('prevState', nextProps.TM.SecurityName);
    this.setState({
      TM: nextProps.TM,
      modalData: {
        TM: nextProps.TM,
        // templateType:
        //   JSON.stringify(this.props.TM.SecurityName) !== JSON.stringify(nextProps.TM.SecurityName)
        //     ? 'Sentiment'
        //     : this.state.modalData.templateType,
        templateType: nextProps.tdecktype === 'tmcd' ? typeof nextProps.metric !== 'undefined' && nextProps.metric !== '' ? nextProps.metric : 'Sentiment' : JSON.stringify(this.props.TM.SecurityName) !== JSON.stringify(nextProps.TM.SecurityName) ? 'Sentiment' : this.state.modalData.templateType,
      },
    });
    // }
  }
  formatFeedText = (text) => {
    const ResponsiveEllipsis = responsiveHOC()(HTMLEllipsis);
    return <ResponsiveEllipsis unsafeHTML={text} maxLine={1} ellipsisHTML={`...`} basedOn="words" />;
  };

  toggleDescription = () => {
    this.setState({
      ...this.state,
      fullDescription: !this.state.fullDescription,
    });
  };

  hoverDescExpandButton = (e, status) => {
    this.setState({
      ...this.state,
      DescriptionButtonHover: status,
    });
  };

  showGraphPopup = (TM, templateType) => {
    window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
    window.Mixpanel.MixpanelProps.CoinName = TM.SecurityName;
    window.Mixpanel.MixpanelProps.GraphType = templateType;
    window.Mixpanel.actions.identify(this.props.userData.username);
    window.Mixpanel.actions.track(`${templateType} Graph Clicked`, window.Mixpanel.MixpanelProps).then((data) => {
      window.Mixpanel.actions.people.set({
        UserName: this.props.userData.username,
      });
      window.Mixpanel.actions.resetProp('CoinName');
      window.Mixpanel.actions.resetProp('GraphType');
    });
    const ChartType = 'RelationChart';
    this.props.showModal(ChartType, { TM, templateType });
  };

  updateTmType = (templateType) => {
    this.setState({
      modalData: {
        // TM: this.props.TM,
        ...this.state.modalData,
        templateType: templateType,
      },
    }, () => {
      if(this.props.tdecktype === 'tmcd') {
        this.props.coindetailcolumnmetricchange(templateType, this.props.coinColumnIndex, this.props.listkey)
      }
      
    });
  };
  entering = (e) => {
    e.children[1].style.backgroundColor = '#37aaf8';
  };

  render() {
    const { TM, fullDescription, DescriptionButtonHover } = this.state;
    let clampedDescription =
      TM.CoinDescription !== null ? this.formatFeedText(`<div>${TM.CoinDescription}</div>`) : null;
    // console.log(TM)
    let SentimentEmotionTip = '';
    let SentimentEmotionIcon = '';
    if((TM.Positive / 10) < 2) {
      SentimentEmotionTip = 'Very negative daily sentiment score for the coin';
      SentimentEmotionIcon = (<img className="tickDet-icon-x" src={`${APPPATH.ImageUrl}/worstemotion.svg`} alt="" />);
    } else if((TM.Positive / 10) >= 2 && (TM.Positive / 10) < 4) {
      SentimentEmotionTip = 'Negative daily sentiment score for the coin';
      SentimentEmotionIcon = (<img className="tickDet-icon-x" src={`${APPPATH.ImageUrl}/bademotion.svg`} alt="" />);
    } else if((TM.Positive / 10) >= 4 && (TM.Positive / 10) < 6) {
      SentimentEmotionTip = 'Neutral daily sentiment score for the coin';
      SentimentEmotionIcon = (<img className="tickDet-icon-x" src={`${APPPATH.ImageUrl}/goodemotion.svg`} alt="" />);
    } else if((TM.Positive / 10) >= 6 && (TM.Positive / 10) < 8) {
      SentimentEmotionTip = 'Positive daily sentiment score for the coin';
      SentimentEmotionIcon = (<img className="tickDet-icon-x" src={`${APPPATH.ImageUrl}/betteremotion.svg`} alt="" />);
    } else if((TM.Positive / 10) >= 8 && (TM.Positive / 10) < 10) {
      SentimentEmotionTip = 'Very positive daily sentiment score for the coin';
      SentimentEmotionIcon = (<img className="tickDet-icon-x" src={`${APPPATH.ImageUrl}/bestemotion.svg`} alt="" />);
    }
    
    return (
      <Row className={`marg-0 nopad margb3`}>
        {Object.keys(TM).length ? (
          <Col lg={12} sm={12} xs={12} md={12} xl={12} className={`nopad tmCategories padlr10 text-left ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>
            {TM.CoinDescription !== null && (
              <Row className={`marg-0 nopad brdr-bot-gray`}>
                {fullDescription ? (
                  <Col lg={11} sm={11} xs={11} md={11} xl={11} className={`nopad  align-self-center `}>
                    <p className="tickDet-desc ">{TM.CoinDescription}</p>
                  </Col>
                ) : (
                  <Col lg={11} sm={11} xs={11} md={11} xl={11} className={`nopad  align-self-center `}>
                    <div className="tickDet-desc ">{clampedDescription}</div>
                  </Col>
                )}

                <Col
                  lg={1}
                  sm={1}
                  xs={1}
                  md={1}
                  xl={1}
                  className={`nopad padt5 pointer`}
                  onMouseEnter={(e) => this.hoverDescExpandButton(e, true)}
                  onMouseLeave={(e) => this.hoverDescExpandButton(e, false)}
                  onClick={this.toggleDescription}
                >
                  <span
                    ref={this.OverlayRef}
                    style={{ position: 'relative', marginLeft: '5px' }}
                    onMouseEnter={(e) => {
                      let ToolTipStyleData = {
                        Arrow: {
                          marginLeft: '20px',
                        },
                        ToolTip: {
                          align: 'right',
                        },
                      };
                      this.props.toggleOverlay(
                        true,
                        this.OverlayRef,
                        'Click to show or hide coin description',
                        ToolTipStyleData
                      );
                    }}
                    onMouseLeave={(e) => this.props.toggleOverlay(false)}
                  >
                    <img
                      style={{ width: '15px' }}
                      src={`${
                        DescriptionButtonHover
                          ? `${APPPATH.ImageUrl}/storyexpandiconblue.svg`
                          : `${APPPATH.ImageUrl}/storyexpandicon.svg`
                      }`}
                      className={`storyExpandIcon ${fullDescription ? 'transform180anticlock' : ''} `}
                      alt=""
                    />
                  </span>
                </Col>
              </Row>
            )}
            {
              (this.props.userData.usertype === 'None' || this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin') &&(
                <Row className={`marg-0 nopad brdr-bot-gray`}>
                  <Col lg={4} sm={4} xs={4} md={4} xl={4} className={`nopad text-center coinmetriccolumn1`}>
                    <div className="tickDet-Title fssm12"
                      ref={this.OverlayRef5}
                      onMouseEnter={(e) => {
                        let ToolTipStyleData = {
                          Arrow: {
                            marginLeft: '60px',
                          },
                          ToolTip: {
                            align: 'left',
                          },
                        };
                        this.props.toggleOverlay(true, this.OverlayRef5, 'USD price for this coin', ToolTipStyleData);
                      }}
                      onMouseLeave={(e) => this.props.toggleOverlay(false)}
                    >
                      <span className="padright5">
                        <img className="tickDet-icon" src={`${APPPATH.ImageUrl}/icon_price-selected-min.svg`} alt="" />
                      </span>
                      <span>Price</span>
                    </div>
                    <div className={`tickDet-data fssm15-13`} 
                      ref={this.OverlayRef903}
                      onMouseEnter={(e) => {
                        let ToolTipStyleData = {
                          Arrow: {
                            marginLeft: '60px',
                          },
                          ToolTip: {
                            align: 'left',
                          },
                        };
                        this.props.toggleOverlay(true, this.OverlayRef903, 'USD price for this coin', ToolTipStyleData);
                      }}
                      onMouseLeave={(e) => this.props.toggleOverlay(false)}
                    >
                      {parseFloat(TM.SecurityPrice) > 0
                        ? parseFloat(TM.SecurityPrice) < 0.0001
                          ? '< $0.0001'
                          : `${TrendingMentionsHelper.currencyFormat(TM.SecurityPrice, '$')}`
                        : '--'}
                      {/* {isMobile ? <br /> : <span>&nbsp;&nbsp;</span>} */}
                      {parseFloat(TM.SecurityPrice) > 0 && (
                        <div
                          className={`tickDet-data-change fssm11 ${
                            TM.Change < 0 ? 'text-red' : TM.Change > 0 ? 'text-green' : ''
                          } `}
                          ref={this.OverlayRefPriceChange}
                          onMouseEnter={(e) => {
                            let ToolTipStyleData = {
                              Arrow: {
                                marginLeft: '60px',
                              },
                              ToolTip: {
                                align: 'left',
                              },
                            };
                            this.props.toggleOverlay(true, this.OverlayRefPriceChange, 'Change in coin price within the past 24 hours', ToolTipStyleData);
                          }}
                          onMouseLeave={(e) => this.props.toggleOverlay(false)}
                        >
                          {TM.Change < 0 ? <>&nbsp;&nbsp;</> : ''}
                          {`${!isNaN(parseFloat(TM.Change)) ? (parseFloat(TM.Change) > 0 ? '+' : '') : ''}${
                            !isNaN(parseFloat(TM.Change)) ? parseFloat(TM.Change).toFixed(2) : '0.00'
                          }%`}
                          
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg={4} sm={4} xs={4} md={4} xl={4} className={`nopad text-center`}>
                    <div className="tickDet-Title fssm12">
                      <span className="padright5">
                        <img className="tickDet-icon" src={`${APPPATH.ImageUrl}/icon_market_cap-min.svg`} alt="" />
                      </span>
                      <span>Market Cap</span>
                    </div>
                    <p
                      className={`tickDet-data fssm15-13`}
                      ref={this.OverlayRef6}
                      // style={{ position: 'relative' }}
                      onMouseEnter={(e) => {
                        let ToolTipStyleData = {
                          Arrow: {
                            marginLeft: '90px',
                          },
                          ToolTip: {
                            align: 'left',
                          },
                        };
                        this.props.toggleOverlay(true, this.OverlayRef6, 'Market cap for this coin', ToolTipStyleData);
                      }}
                      onMouseLeave={(e) => this.props.toggleOverlay(false)}
                    >
                      {parseFloat(TM.MarketCapUsd) > 0 && parseFloat(TM.SecurityPrice) > 0
                        ? `$${TrendingMentionsHelper.abbreviateNumber(TM.MarketCapUsd)}`
                        : '--'}
                    </p>
                  </Col>
                  <Col lg={4} sm={4} xs={4} md={4} xl={4} className={`nopad text-center coinmetriccolumn3`}>
                    <div className="tickDet-Title fssm12">
                      <span className="padright5">
                        <img className="tickDet-icon" src={`${APPPATH.ImageUrl}/icon_volume-selected-min.svg`} alt="" />
                      </span>
                      <span>Volume</span>
                    </div>
                    <p
                      className={`tickDet-data fssm15-13`}
                      ref={this.OverlayRef7}
                      style={{ position: 'relative' }}
                      onMouseEnter={(e) => {
                        let ToolTipStyleData = {
                          Arrow: {
                            marginLeft: '80px',
                          },
                          ToolTip: {
                            align: 'slightleft',
                          },
                        };
                        this.props.toggleOverlay(
                          true,
                          this.OverlayRef7,
                          'Trading volume in the past 24 hours for this coin',
                          ToolTipStyleData
                        );
                      }}
                      onMouseLeave={(e) => this.props.toggleOverlay(false)}
                    >
                      {parseFloat(TM.Volume24hUsd) > 1 && parseFloat(TM.SecurityPrice) > 0
                        ? `$${TrendingMentionsHelper.abbreviateNumber(TM.Volume24hUsd)}`
                        : '0'}
                    </p>
                  </Col>
                </Row>
              )
            }
            
            <Row className={`marg-0 nopad brdr-bot-gray`}>
              <Col
                onClick={() => this.updateTmType('Sentiment')}
                lg={this.props.showtopreach ? 4 : 6}
                sm={this.props.showtopreach ? 4 : 6}
                xs={this.props.showtopreach ? 4 : 6}
                md={this.props.showtopreach ? 4 : 6}
                xl={this.props.showtopreach ? 4 : 6}
                className={`nopad text-center pointer ${this.props.showtopreach ? 'coinmetriccolumn1' : ''} ${
                  this.state.modalData && this.state.modalData.templateType === 'Sentiment' ? 'bottom-border' : ''
                }`}
              >
                <div className="tickDet-Title fssm12"
                  ref={this.OverlayRef8}
                  // style={{ position: 'relative' }}
                  onMouseEnter={(e) => {
                    let ToolTipStyleData = {
                      Arrow: {
                        marginLeft: '90px',
                      },
                      ToolTip: {
                        align: 'left',
                      },
                    };
                    this.props.toggleOverlay(
                      true,
                      this.OverlayRef8,
                      'Social media sentiment in the past 24 hours for this coin (0-3 is negative, 3-7 is neutral, and 7-10 is positive)',
                      ToolTipStyleData
                    );
                  }}
                  onMouseLeave={(e) => this.props.toggleOverlay(false)}
                >
                  <span className="padright5">
                    {
                      this.state.modalData && this.state.modalData.templateType === 'Sentiment' ? 
                        this.props.Theme === 'light' ? 
                        (
                          <img className="tickDet-icon" src={`${APPPATH.ImageUrl}/icon-positive-selected-bluelightmode.svg`} alt="" />
                        )
                        :
                        (
                          <img className="tickDet-icon" src={`${APPPATH.ImageUrl}/icon-positive-selected-blue.svg`} alt="" />
                        )
                      : 
                        this.props.Theme === 'light' ? 
                        (
                          <img className="tickDet-icon" src={`${APPPATH.ImageUrl}/icon-positive-selectedlightmode.svg`} alt="" />
                        ) 
                        :
                        (
                          <img className="tickDet-icon" src={`${APPPATH.ImageUrl}/icon-positive-selected.svg`} alt="" />
                        )
                    }
                    
                  </span>
                  <span className={this.state.modalData && this.state.modalData.templateType === 'Sentiment' ? 'text-blue-primary bold' : ''}>
                    Sentiment
                  </span>
                </div>
                <div className={`tickDet-data fssm15-13`} style={{display: 'flex', justifyContent: 'center'}}>
                  <div 
                      className=''
                      ref={this.OverlayRefEmotions}
                      onMouseEnter={(e) => {
                        let ToolTipStyleData = {
                          Arrow: {
                            marginLeft: '10px',
                          },
                          ToolTip: {
                            align: 'left',
                          },
                        };
                        this.props.toggleOverlay(
                          true,
                          this.OverlayRefEmotions,
                          SentimentEmotionTip,
                          ToolTipStyleData
                        );
                      }}
                      onMouseLeave={(e) => this.props.toggleOverlay(false)}
                    >
                      <div style={{marginTop: TM.PositiveChange !== 0 ? '5px' : '0px', paddingRight: '5px'}} >
                        { SentimentEmotionIcon }
                      </div>
                  </div>
                  <div className=''>
                      <div
                        ref={this.OverlayRefSentimentScore}
                        onMouseEnter={(e) => {
                          let ToolTipStyleData = {
                            Arrow: {
                              marginLeft: '20px',
                            },
                            ToolTip: {
                              align: 'left',
                            },
                          };
                          this.props.toggleOverlay(
                            true,
                            this.OverlayRefSentimentScore,
                            'Daily sentiment score of the coin from 0/10 (very negative) to 10/10 (very positive)',
                            ToolTipStyleData
                          );
                        }}
                        onMouseLeave={(e) => this.props.toggleOverlay(false)}
                      >
                        {`${TM.Positive / 10} / 10`}
                      </div>
                    {/* {isMobile ? <br /> : <span>&nbsp;&nbsp;</span>} */}
                    {
                      TM.PositiveChange !== 0 && (
                        <div
                          style={{marginLeft: '-5px'}}
                          className={`tickDet-data-change fssm11 ${
                            TM.PositiveChange < 0 ? 'text-red' : TM.PositiveChange > 0 ? 'text-green' : ''
                          } `}
                          ref={this.OverlayRefSentimentChange}
                          onMouseEnter={(e) => {
                            let ToolTipStyleData = {
                              Arrow: {
                                marginLeft: '20px',
                              },
                              ToolTip: {
                                align: 'left',
                              },
                            };
                            this.props.toggleOverlay(
                              true,
                              this.OverlayRefSentimentChange,
                              'Change in coin daily sentiment score vs the average sentiment in the past 14 days',
                              ToolTipStyleData
                            );
                          }}
                          onMouseLeave={(e) => this.props.toggleOverlay(false)}
                        >
                          
                          {TM.PositiveChange < 0 ? <>&nbsp;&nbsp;</> : ''}
                          {`${!isNaN(parseFloat(TM.PositiveChange)) ? (parseFloat(TM.PositiveChange) > 0 ? '+' : '') : ''}${
                            !isNaN(parseFloat(TM.PositiveChange)) ? parseFloat(TM.PositiveChange).toFixed(2) : '0.00'
                          }%`}
                        </div>
                      )
                    }
                      
                  </div>
                  
                </div>
              </Col>
              <Col
                onClick={() => this.updateTmType('Mentions')}
                lg={this.props.showtopreach ? 4 : 6}
                sm={this.props.showtopreach ? 4 : 6}
                xs={this.props.showtopreach ? 4 : 6}
                md={this.props.showtopreach ? 4 : 6}
                xl={this.props.showtopreach ? 4 : 6}
                className={`nopad text-center pointer ${
                  this.state.modalData && this.state.modalData.templateType === 'Mentions' ? 'bottom-border' : ''
                }`}
              >
                <div className="tickDet-Title fssm12"
                  ref={this.OverlayRef9}
                  onMouseEnter={(e) => {
                    let ToolTipStyleData = {
                      Arrow: {
                        marginLeft: '60px',
                      },
                      ToolTip: {
                        align: 'left',
                      },
                    };
                    this.props.toggleOverlay(
                      true,
                      this.OverlayRef9,
                      'Total mentions on social media in the past 24 hours for this coin',
                      ToolTipStyleData
                    );
                  }}
                  onMouseLeave={(e) => this.props.toggleOverlay(false)}
                >
                  <span className="padright5">
                    {
                      this.state.modalData && this.state.modalData.templateType === 'Mentions' ? 
                        this.props.Theme === 'light' ? (
                          <img className="tickDet-icon" src={`${APPPATH.ImageUrl}/icon-trending-selected-bluelightmode.svg`} alt="" />
                        )
                        :
                        (
                          <img className="tickDet-icon" src={`${APPPATH.ImageUrl}/icon-trending-selected-blue.svg`} alt="" />
                        ) 
                      :
                        this.props.Theme === 'light' ? (
                          <img className="tickDet-icon" src={`${APPPATH.ImageUrl}/icon-trending-selectedlightmode.svg`} alt="" />
                        ) 
                        : 
                        (
                          <img className="tickDet-icon" src={`${APPPATH.ImageUrl}/icon-trending-selected.svg`} alt="" />
                        )
                    }
                    
                  </span>
                  <span className={this.state.modalData && this.state.modalData.templateType === 'Mentions' ? 'text-blue-primary bold' : ''}>
                    Mentions
                  </span>
                </div>
                <div
                  className={`tickDet-data fssm15-13`}
                  
                >
                  <div
                    ref={this.OverlayRef901}
                    onMouseEnter={(e) => {
                      let ToolTipStyleData = {
                        Arrow: {
                          marginLeft: '60px',
                        },
                        ToolTip: {
                          align: 'left',
                        },
                      };
                      this.props.toggleOverlay(
                        true,
                        this.OverlayRef901,
                        'Total mentions on social media in the past 24 hours for this coin',
                        ToolTipStyleData
                      );
                    }}
                    onMouseLeave={(e) => this.props.toggleOverlay(false)}
                  >{TrendingMentionsHelper.abbreviateNumber(TM.Mentions)}</div>
                  {/* {isMobile ? <br /> : <span>&nbsp;&nbsp;</span>} */}
                  {
                    TM.MentionsChange !== 0 && (
                      <div
                        className={`tickDet-data-change fssm11 ${
                          TM.MentionsChange < 0 ? 'text-red' : TM.MentionsChange > 0 ? 'text-green' : ''
                        } `}
                        ref={this.OverlayRefMentionsChange}
                        onMouseEnter={(e) => {
                          let ToolTipStyleData = {
                            Arrow: {
                              marginLeft: '60px',
                            },
                            ToolTip: {
                              align: 'left',
                            },
                          };
                          this.props.toggleOverlay(
                            true,
                            this.OverlayRefMentionsChange,
                            'Change in the number of social media feeds mentioning the coin in the past day vs the average in the past 14 days',
                            ToolTipStyleData
                          );
                        }}
                        onMouseLeave={(e) => this.props.toggleOverlay(false)}
                      >
                        
                        {TM.MentionsChange < 0 ? <>&nbsp;&nbsp;</> : ''}
                        {`${!isNaN(parseFloat(TM.MentionsChange)) ? (parseFloat(TM.MentionsChange) > 0 ? '+' : '') : ''}${
                          !isNaN(parseFloat(TM.MentionsChange)) ? parseFloat(TM.MentionsChange).toFixed(2) : '0.00'
                        }%`}
                      </div>
                    )
                  }
                  
                </div>
              </Col>
              {
                this.props.showtopreach && (
                  <Col
                    onClick={() => this.updateTmType('Followers')}
                    lg={4}
                    sm={4}
                    xs={4}
                    md={4}
                    xl={4}
                    className={`nopad text-center pointer coinmetriccolumn3 ${
                      this.state.modalData && this.state.modalData.templateType === 'Followers' ? 'bottom-border' : ''
                    }`}
                  >
                    <div className="tickDet-Title fssm12"
                      ref={this.OverlayRef10}
                      onMouseEnter={(e) => {
                        let ToolTipStyleData = {
                          Arrow: {
                            marginLeft: '60px',
                          },
                          ToolTip: {
                            align: 'slightleft',
                          },
                        };
                        this.props.toggleOverlay(
                          true,
                          this.OverlayRef10,
                          'Total followers reach in social media in the past 24 hours for this coin',
                          ToolTipStyleData
                        );
                      }}
                      onMouseLeave={(e) => this.props.toggleOverlay(false)}
                    >
                      <span className="padright5">
                        {
                          this.state.modalData && this.state.modalData.templateType === 'Followers' ? 
                            this.props.Theme ? 
                            (
                              <img className="tickDet-icon" src={`${APPPATH.ImageUrl}/icon-mentions-selected-bluelightmode.svg`} alt="" />
                            ) 
                            : 
                            (
                              <img className="tickDet-icon" src={`${APPPATH.ImageUrl}/icon-mentions-selected-blue.svg`} alt="" />
                            ) 
                          : 
                            this.props.Theme === 'light' ? 
                            (
                              <img className="tickDet-icon" src={`${APPPATH.ImageUrl}/icon-mentions-selectedlightmode.svg`} alt="" />
                            ) 
                            : 
                            (
                            <img className="tickDet-icon" src={`${APPPATH.ImageUrl}/icon-mentions-selected.svg`} alt="" />
                            )
                        }
                        
                      </span>
                      <span className={this.state.modalData && this.state.modalData.templateType === 'Followers' ? 'text-blue-primary bold' : ''}>
                        Reach
                      </span>
                    </div>
                    <div
                      className={`tickDet-data fssm15-13`}
                      
                    >
                      <div
                        ref={this.OverlayRef902}
                        onMouseEnter={(e) => {
                          let ToolTipStyleData = {
                            Arrow: {
                              marginLeft: '60px',
                            },
                            ToolTip: {
                              align: 'slightleft',
                            },
                          };
                          this.props.toggleOverlay(
                            true,
                            this.OverlayRef902,
                            'Total followers reach in social media in the past 24 hours for this coin',
                            ToolTipStyleData
                          );
                        }}
                        onMouseLeave={(e) => this.props.toggleOverlay(false)}
                      >{TrendingMentionsHelper.abbreviateNumber(TM.Reach)}</div>
                      {/* {isMobile ? <br /> : <span>&nbsp;&nbsp;</span>} */}
                      {
                        TM.ReachChange !== 0 && (
                          <div
                            className={`tickDet-data-change fssm11 ${
                              TM.ReachChange < 0 ? 'text-red' : TM.ReachChange > 0 ? 'text-green' : ''
                            } `}
                            ref={this.OverlayRefReachChange}
                            onMouseEnter={(e) => {
                              let ToolTipStyleData = {
                                Arrow: {
                                  marginLeft: '60px',
                                },
                                ToolTip: {
                                  align: 'slightleft',
                                },
                              };
                              this.props.toggleOverlay(
                                true,
                                this.OverlayRefReachChange,
                                'Change in the number of social media followers for the feeds mentioning the coin in the past day vs the average in the past 14 days',
                                ToolTipStyleData
                              );
                            }}
                            onMouseLeave={(e) => this.props.toggleOverlay(false)}
                          >
                            
                            {TM.ReachChange < 0 ? <>&nbsp;&nbsp;</> : ''}
                            {`${!isNaN(parseFloat(TM.ReachChange)) ? (parseFloat(TM.ReachChange) > 0 ? '+' : '') : ''}${
                              !isNaN(parseFloat(TM.ReachChange)) ? parseFloat(TM.ReachChange).toFixed(2) : '0.00'
                            }%`}
                          </div>
                        )
                      }
                      
                    </div>
                  </Col>
                )
              }
              
            </Row>
            <Row className={`marg-0 nopad brdr-bot-gray`}>
              {this.state.modalData && (
                <GraphInCoinCol
                  data={this.state.modalData}
                  toggleOverlay={this.props.toggleOverlay}
                  showGraphPopup={() => this.showGraphPopup(TM, this.state.modalData.templateType)}
                  userRole={this.props.userData.userRole !== null ? this.props.userData.userRole : ''}
                  tdecktype={this.props.tdecktype}
                  coinColumnIndex={this.props.tdecktype === 'tmcd' ? this.props.coinColumnIndex : null}
                  SecurityName={this.props.tdecktype === 'tmcd' ? this.props.SecurityName !== null  ? this.props.SecurityName  : null : null}
                  SourceFilter={this.props.tdecktype === 'tmcd' ? this.props.SourceFilter && this.props.SourceFilter !== '' ?  this.props.SourceFilter : '' : ''}
                  Theme={this.props.Theme}
                />
              )}
            </Row>
            {
              this.props.userData.usertype==='None' && (
                <Row className={`marg-0 nopad`}>
                  <Col lg={12} sm={12} xs={12} md={12} xl={12} className={`nopad text-center`}>
                    <div className="tradeButtonWrap">
                      <button
                        className="btn btn-trade"
                        onClick={() => {
                          window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
                          window.Mixpanel.MixpanelProps.CoinName = TM.SecurityName;
                          window.Mixpanel.actions.identify(this.props.userData.username);
                          window.Mixpanel.actions.track(`Trade Button Clicked`, window.Mixpanel.MixpanelProps).then((data) => {
                            window.Mixpanel.actions.people.set({
                              UserName: this.props.userData.username,
                            });
                            window.Mixpanel.actions.resetProp('CoinName');
                          });
                          this.props.showModal('TradeExchanges', TM)
                        }}
                        ref={this.OverlayRef4}
                        onMouseEnter={(e) => {
                          let ToolTipStyleData = {
                            Arrow: {
                              marginLeft: '0px',
                            },
                            ToolTip: {
                              align: 'center',
                            },
                          };
                          this.props.toggleOverlay(
                            true,
                            this.OverlayRef4,
                            'Click to select the prices across crypto exchanges that list this coin',
                            ToolTipStyleData
                          );
                        }}
                        onMouseLeave={(e) => this.props.toggleOverlay(false)}
                      >
                        {/* Where To Buy */}
                        Trade
                      </button>
                    </div>
                  </Col>
                </Row>
              )
            }
            
          </Col>
        ) : (
          <Col lg={12} sm={12} xs={12} md={12} xl={12} className={`nopad tmCategories ${this.props.Theme === 'light' ? 'lightmode' : ''} padlr10`}>
            <div>
              <i className="fa fa-spinner fa-2x fa-spin storyloadindicator"></i>
            </div>
          </Col>
        )}
      </Row>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    TM: ownProps.tdecktype === 'tmd' ? state.trendingMentions.tmDetails.TickerData : 
        ownProps.tdecktype === 'tmcd' ? ownProps.SecurityName !== null ? state.trendingMentions.tmDetailPreferencesDataStore[`${ownProps.SecurityName}${ownProps.SourceFilter}`].TickerData : null : null,
    showtopreach: state.settings.showtopreach,
    Theme: state.app_preferences.preferences.user_interface.Theme,
    //   TMRequestState: state.trendingMentions.tmReqData.RequestState,
    //   packageName: state.trendingMentions.packageName
  };
};

const mapDispatchToProps = {
  TMViewDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(TickerDetails);
