import React, { Component } from 'react'; 
import { Row, Col } from 'react-bootstrap';
import  { connect } from 'react-redux';
import { APPPATH } from '../../../modules/helper.module';

class DesktopAppNotificationBanner extends Component {
  constructor() {
    super();
    this.state = {
      Desktop_App_Stat: false
    }
  }
  componentWillMount() {
    // console.log(this.props.Install_Desktop_App)
    this.setState({
      ...this.state,
      Desktop_App_Stat: this.props.Install_Desktop_App
    })
  }
  componentWillReceiveProps(nextProps) {
    // console.log(nextProps.Install_Desktop_App)
    if(this.props.Install_Desktop_App !== nextProps.Install_Desktop_App) {
      this.setState({
        ...this.state,
        Desktop_App_Stat: nextProps.Install_Desktop_App
      })
    }
  }
  render() {
    const { Desktop_App_Stat } = this.state
    
    return(
      <Row className={`justify-content-center text-center marg-0 ${!Desktop_App_Stat ? 'nodisp': ''}`} >
        <Col lg={11} sm={11} xs={11} md={11} xl={11} >
          <div className="dTopAppNotifBanner">
            {/* <span>
              <i className="fa fa-times"></i>
            </span> */}
            <img src={`${APPPATH.ImageUrl}/logo.png`} className="text-center banner-image dtop" alt=""/>
            <Row className="marg-0 h-100">
              <Col lg={3} sm={3} xs={3} md={3} xl={3} className="nopad " >
                
              </Col>
              <Col lg={5} sm={5} xs={5} md={5} xl={5} className="align-self-center banner-text dtop">
                <p>WE RECOMMEND TO DOWNLOAD OUR DESKTOP APPLICATION FOR A BETTER EXPERIENCE.</p>
              </Col>
              <Col lg={4} sm={4} xs={4} md={4} xl={4} className="align-self-center">
                <div className="btn btn-lg btn-dtop btn-primary">Download</div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    )
  }
}

const mapStateToApp = (state) => {
  return {
    Install_Desktop_App: state.filters.Install_Desktop_App,
  }
}

const mapDispatchToProps = {
}

export default connect(mapStateToApp, mapDispatchToProps)(DesktopAppNotificationBanner)