import React, { Component } from 'react';
// import ReactDOM from 'react-dom';
import { Row } from 'react-bootstrap';
import StoryHeadLineText from './story_headlinetext';
import { connect } from 'react-redux';

class StoryHeadLineWrap extends Component {
  constructor() {
    super();
    this.state = {};
  }
  getcorels = (correlations, locations) => {
    // console.log(this.props.securities)
    // locations = locations + ';Sentiment Asset Label:EURUSD';
    // console.log(correlations, locations);
    let rearrangedcorels = [];
    if(locations !== "") {
      let locationparts = locations.split(';');
      if(locationparts.length > 0) {
        locationparts.forEach(element => {
          let locationpartsvalues = element.split(':');
          
          if(locationpartsvalues[0] === 'Sentiment Asset Label') {
            let SecurityObj = this.props.securities.filter(itm => itm.Symbol === locationpartsvalues[1].trim());
            let SecurityName = SecurityObj.length > 0 ? SecurityObj[0].Name : locationpartsvalues[1].trim();
            
            let corelelem = {
              Description: null,
              Impact: 'Direct',
              Link: null,
              SecutiryType: null,
              Security: `${SecurityName}`,
              Symbol: `${locationpartsvalues[1].trim()}`
            }
            rearrangedcorels.push(corelelem);
          }
        });
      }
      if(correlations.length > 0) {
        correlations.forEach(ele => {
          let corelExists = rearrangedcorels.filter(itm => itm.Security === ele.Security || itm.Symbol === ele.Symbol)
          if(!corelExists.length)  {
            rearrangedcorels.push(ele)
          }
        })
      }
      return rearrangedcorels;
    }
  }

  ExtractSentiment_Source = (story, type = 'Sentiment') => {
    let LocationString = story.Location
    if(LocationString !== null) {
      let items = LocationString.split(';')
      let SentimentTotal = null;
      let Sources = [];
      if(items.length) {
        items.forEach((el, i) => {
          // console.log(el)
          let key = el.split(':')[0]
          let value = el.split(':')[1]
          if(key === 'SentimentTotal') {
            SentimentTotal = value
          } else if(key === 'Source') {
            Sources = value.split(',')
          }
        })
      }
      if(type === 'Sentiment') {
        if(typeof SentimentTotal === 'undefined' || isNaN(SentimentTotal)) {
          SentimentTotal = null
        }
        if(SentimentTotal !== null) {
          return parseFloat(SentimentTotal);
        } else {
          return SentimentTotal;
        }
        
      } else if(type === 'Source') {
        return Sources;
      }
      // console.log(Sources, SentimentTotal)
      // return { Source : Sources, Sentiment: SentimentTotal }
    }
    

  }

  render() {
    // console.log(this.props.story);
    // if(this.props.story.FeedId === 49280756) {
    //   console.log(this.props.story.CorrelationResults)
    //   console.log(this.props.story.Location)
    // }
    let newcorels = this.props.story.CorrelationResults;//this.getcorels(, this.props.story.Location);
    let Sentiment = this.ExtractSentiment_Source(this.props.story)
    let SentimentIndicator = ''
    // if(this.props.story.FeedId === 49289572) {
    //   console.log(newcorels)
    //   console.log(Sentiment)
    // }
    if(Sentiment !== null && typeof Sentiment !== 'undefined' && !isNaN(Sentiment) && parseInt(this.props.story.MagnitudeRate) > 0) {
      if(Sentiment <= 3.5) {
        SentimentIndicator = 'neg_sentiment.png'
      } else if(Sentiment >= 6.5) {
        SentimentIndicator = 'pos_semtiment.png'
      }
    }
    // console.log(newcorels);
    return (
      <div className="nopad">
        <Row className="marg-0" key={`story-headline-${this.props.story.FeedId}`}>
          <StoryHeadLineText
            {...this.props}
            feedId={this.props.story.FeedId}
            corels={newcorels}
            StorySentiment={Sentiment}
            StorySentimentIndicator={SentimentIndicator}
            notifyEvent={this.props.story.NotifyEvent}
            rootChangeLogList={this.props.story.RootChangeLogList}
            headlineText={this.props.lang === false ? this.props.story.HeadlineText : this.props.story.HeadlineTextAdmin}
            magnitudeRate={this.props.story.MagnitudeRate}
            userData={this.props.userData}
            handleCollapse={this.props.handleCollapse}
            EventReadStatus={this.props.EventReadStatus}
            TDeckColWidth={this.props.TDeckColWidth}
            EventActive={this.props.story.Active}
            EventCurated={this.props.story.EventCurated}
            LastUpdateTime={this.props.story.LastUpdateTime}
            FeedTime={this.props.story.FeedTime}
            togglePopup={this.props.togglePopup}
            Event_Collapsed_View={this.props.Event_Collapsed_View}
            SecuritySymbol={this.props.SecuritySymbol}
            Theme={this.props.Theme}
            render={this.props.render}
            lang={this.props.lang}
          />
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    securities: state.filters.securities,
    Theme: state.app_preferences.preferences.user_interface.Theme,
    render: state.settings.render,
    lang: state.settings.lang
  }
}

export default  connect(mapStateToProps, null)(StoryHeadLineWrap);
