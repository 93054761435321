import React, { Component } from 'react'; 

class AttributeListItem extends Component {
  constructor() {
    super();
    this.state = {
      active: false,
      selected: false,
      mention: {}
    }
  }

  componentWillMount() {
    this.setState({
      ...this.state,
      mention: this.props.mention,
      selected: this.props.selected
    })
  }

  componentWillReceiveProps(nextProps) {
    let should = this.props.selected !== nextProps.selected
    if(should) {
      this.setState({
        ...this.state,
        selected: nextProps.selected,
        mention: nextProps.mention
      })
    }
  }

  handleChange = () => {
    this.setState({
      ...this.state,
      selected: !this.state.selected
      
    },() => {
      window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
      window.Mixpanel.MixpanelProps.Setting = 'Topics';
      
      window.Mixpanel.MixpanelProps.MentionTypeID = this.state.mention.MentionTypeID;
      window.Mixpanel.MixpanelProps.MentionName = this.state.mention.Name;
      window.Mixpanel.actions.identify(this.props.userData.username);
      let actionTrackerText = this.state.selected ? `Selected ${this.state.mention.Name}` : `Unselected ${this.state.mention.Name}`
      window.Mixpanel.actions.track(actionTrackerText, window.Mixpanel.MixpanelProps).then(data => {
        window.Mixpanel.actions.people.set({
          UserName: this.props.userData.username
        });
        delete window.Mixpanel.MixpanelProps.MentionTypeID
        delete window.Mixpanel.MixpanelProps.MentionName
      })
      this.props.managefilter(this.state.selected, this.state.mention)
    })
  }

  handleClick = (e) => {
    this.setState({
      ...this.state,
      active: !this.state.active
    },() => {
      window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
      window.Mixpanel.MixpanelProps.Setting = 'Topics';
      window.Mixpanel.MixpanelProps.MentionTypeID = this.state.mention.MentionTypeID;
      window.Mixpanel.MixpanelProps.MentionName = this.state.mention.Name;
      window.Mixpanel.actions.identify(this.props.userData.username);
      let actionTrackerText = this.state.active ? `Expanded ${this.state.mention.Name}` : `Minimised ${this.state.mention.Name}`
      window.Mixpanel.actions.track(actionTrackerText, window.Mixpanel.MixpanelProps).then(data => {
        window.Mixpanel.actions.people.set({
          UserName: this.props.userData.username
        });
      })
    })
  }
  directChildStatus = (childArr, types, childStatusArr = []) => {
    
    // let childStatusArr = [];
    if(types.length) {
      types.forEach((mntp) => {
        if(childArr.indexOf(mntp.MentionTypeID) > -1) {
          childStatusArr.push(mntp.selected)
          if(mntp.children.length) {
            this.directChildStatus(mntp.children, types, childStatusArr);
          }
        }
      })
      
    }
    return childStatusArr;
  }
  recursiveChildStatusCount = (mention, types, response = {childCount:0,selectedCount:0}) => {
    
    if(mention.children.length) {
      types.forEach((typ) => {
        if(mention.children.indexOf(typ.MentionTypeID) > -1) {
          if(!typ.children.length) {
            response.childCount++;
          
            if(typ.selected) {
              
                response.selectedCount++;
              
              
            }
          }
          if(typ.children.length) {
            response = this.recursiveChildStatusCount(typ, types, response)
          }
        }
        
        
      })
    }
    return response
  }
  recursiveChildren = (mention, types, response) => {
    
   
    let MentionTypesChildren = mention.children || [];
    let mnttypeviews = []; let mnttypeviewsArr = [];
    let childStatusArr =  []; let recursiveStatusCount = {}; let childStatus = '';
    if(MentionTypesChildren.length) {
      childStatusArr = this.directChildStatus(MentionTypesChildren, types)
      
      if(childStatusArr.indexOf(true) === -1) {
        childStatus = 'ALLFALSE';
      } else if(childStatusArr.indexOf(false) === -1) {
        childStatus = 'ALLTRUE';
      } else {
        childStatus = 'ALLMIXED';
      }
      
      recursiveStatusCount = this.recursiveChildStatusCount(mention, types)
      for(let Type of types) {
        if(mention.children.indexOf(Type.MentionTypeID) > -1) {
          mnttypeviewsArr.push(Type)
          mnttypeviews.push(<AttributeListItem mention={Type} groupindex={this.props.groupIndex} akey={`mntpTyp${Type.MentionTypeID}`}  indexChange={this.props.indexchange} key={`mntTyp${Type.MentionTypeID}`} managefilter={this.props.managefilter} selected={Type.selected} mentionTypes={this.props.mentionTypes}  userData={this.props.userData}  />
          )
        }
      }
    }
    response = { 'mnttypeviews' : mnttypeviews, 'childStatus': childStatus, 'statusCount' : recursiveStatusCount }
    return response
  }
  
  render() {
    const { mention, mentionTypes } = this.props
    const { active, selected } = this.state
    let detailTree = []; let detailTreeArr = {}; let childStatus = ''; let statusCount = ''
    if(mention.children && mention.children.length) {
      detailTreeArr = this.recursiveChildren(mention, mentionTypes, detailTreeArr);
      detailTree = detailTreeArr.mnttypeviews;
      childStatus = detailTreeArr.childStatus;
      statusCount = `${detailTreeArr.statusCount.selectedCount} / ${detailTreeArr.statusCount.childCount}`;

    }
    return (
      <div className={`settings-list-item`}>
        <div className={`settings-item-title marg-0  ${active ? 'selected' : ''}`}>
          {
            detailTree.length ? (
              <i className={`fa ${!active ? 'fa-caret-down' : 'fa-caret-up selected'} float-left open-but`} onClick={this.handleClick}></i>
            ) : ('')
          }
          {
            detailTree.length ? (
              <span className={`sm-fs12 ${!mention.children.length ? 'padl25':'' } `} onClick={this.handleClick}>{ mention.Name.trim() }</span>
            ) : (
              <span className={`sm-fs12 ${!mention.children.length ? 'padl25':'' } `} >{ mention.Name.trim() }</span>
            )
          }
          {
            detailTree.length ? (
              <span className='selectedFilterStatus' onClick={this.handleClick} >{detailTree.length ? ` (${statusCount} selected)` : ''}</span>
            ) : (
              <span className='selectedFilterStatus' >{detailTree.length ? ` (${statusCount} selected)` : ''}</span>
            )
          }
          <span className="float-right">
            <label className="sec-switch float-right">
              <input type="checkbox" checked={`${selected? 'checked': ''}`} onChange={this.handleChange} value={mention.MentionTypeID} />
              <span className={`sec-slider round ${childStatus === 'ALLMIXED'? 'mixedSelect' : ''}`}></span>
            </label>
          </span>
        </div>
        {
          detailTree.length? (
            <div className={`settings-item-content ${!active ? 'nodisp' : '' }`}>
              <div className="securityItem" >
                { detailTree }
              </div>
            </div>
          ) : ('')
        }
      </div>
    )
  }
  
}
export default AttributeListItem