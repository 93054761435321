import React, { useState, useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import PropTypes from 'prop-types';

function MiniChart({ options }) {
  options.grid = {
    bottom: '-5%',
    containLabel: true,
    left: '0%',
    right: '0%',
    top: '5%',
  };
  delete options.dataZoom;
  const TickerChart = useRef(null);
  useEffect(() => {
    const chart = echarts.init(TickerChart.current);
    chart.setOption(options);
    window.onresize = function () {
      chart.resize();
    };
  }, [options]);
  return <div ref={TickerChart} style={{ width: '100%', height: '70%' }} className=""></div>;
}

MiniChart.propTypes = {
  options: PropTypes.any,
};

export default MiniChart;
