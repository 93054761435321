
import { APICONFIG, ErrorHandleHelper } from '../modules/helper.module';

export function logger(LogData) {
    console.log(typeof LogData.Message)
    let Error = {
      LogData : LogData.Message,
      username: APICONFIG.apiLoggedInUser()
    }
    fetch(`${APICONFIG.apiBaseUrl}/api/logs/logmessage`,{
      method: 'POST',
      body: JSON.stringify(Error),
      headers: APICONFIG.apiHeaders()
    })
    .then((result) => {
      console.log(result)
    })
}

export function addLogAction(LogData) {
  
  return dispatch => {
    console.log(LogData)
    let Error = {
      Message : LogData.Message,
      username: APICONFIG.apiLoggedInUser()
    }
    fetch(`${APICONFIG.apiBaseUrl}/api/logs/logmessage`,{
      method: 'POST',
      body: JSON.stringify(Error),
      headers: APICONFIG.apiHeaders()
    })
    // .then((response) => response.json())
    .then((result) => {
      
      // console.log(result)
      // dispatch({
      //   type: 'LOGGED_AN_ERROR',
      //   payload: LogData
      // })
    })
    .catch((err) => {
      // let data = {
      //   username: APICONFIG.apiLoggedInUser(),
      //   action: 'Add Error Log',
      //   url : `${APICONFIG.apiBaseUrl}/api/logs/logmessage`
      // };
      // dispatch({
      //   type: 'REQUEST_ERROR',
      //   payload: ErrorHandleHelper.generateErrorString(err, 'AddErrorLog'),
      //   data: data
      // })
    })
  }
}

export function resetAppError() {
  return dispatch => {
    dispatch({
      type: 'RESET_APP_ERROR'
    })
  }
}