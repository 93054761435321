import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { APPPATH } from '../../../../modules/helper.module';

export default class HelpVideo extends Component {
  render() {
    return (
      <div className={`watchlist-content justify-content-center text-center ${isMobile ? 'h-mobilevh' : ''}`}>
        <div className="btn tradeWindowClose text-white nopad marg-0" onClick={() => this.props.modalClose()}>
          <span className="pad10">
            <img className="tradeWindowClose-closeicon" src={`${APPPATH.ImageUrl}/button-close.svg`} alt="" />
          </span>
        </div>
        <Row className={`marg-0 justify-content-center  pad5 text-center  `}>
          <Col lg={12} sm={12} xs={12} md={12} xl={12} className={` text-left ${isMobile ? 'h-mobilevhmin' : ''}`}>
            <div className={` iframe-introvid-container ${isMobile ? 'mob' : ''}`}>
              {
                isMobile ? (
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/De3YiHANIuI?mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                ) : (
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/wS7XWXkZWGo?mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                )
              }
            </div>
          </Col>
          {
          isMobile ? (
            <Col lg={12} sm={12} xs={12} md={12} xl={12} className={` text-left`} >
              <Row className={`marg-0 justify-content-center  pad5 text-center`}>
                <Col lg={4} sm={4} xs={4} md={4} xl={4} className="text-left watchlistNavigatorButtons disabled "></Col>
                <Col lg={4} sm={4} xs={4} md={4} xl={4} className="text-center pad5 "></Col>
                <Col lg={4} sm={4} xs={4} md={4} xl={4} className="text-right watchlistNavigatorButtons">
                  <span
                    className="pointer"
                    onClick={() => {
                      this.props.modalClose();
                    }}
                  >
                    Ok
                  </span>
                </Col>
              </Row>
            </Col>
          ) : ('')

          }
        </Row>
        <Row className={`marg-0 justify-content-center  pad5 text-center ${isMobile ? 'nodisp' : ''}`}>
          <Col lg={4} sm={4} xs={4} md={4} xl={4} className="text-left watchlistNavigatorButtons disabled "></Col>
          <Col lg={4} sm={4} xs={4} md={4} xl={4} className="text-center pad5 "></Col>
          <Col lg={4} sm={4} xs={4} md={4} xl={4} className="text-right watchlistNavigatorButtons">
            <span
              className="pointer"
              onClick={() => {
                this.props.modalClose();
              }}
            >
              Ok
            </span>
          </Col>
        </Row>
      </div>
    );
  }
}
