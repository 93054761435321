import React, { Component } from 'react';
import { APICONFIG } from '../../modules/helper.module';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { APPPATH } from '../../modules/helper.module';
import ColorPreferenceSlider from '../shared/preferenceRangeSlider/preferenceslider';

class SearchAndEventSepratorInSearch extends Component {
  constructor(props) {
    super();
    this.state = {
      collapseHover: false,
    };
  }
  toggleHover = () => {
    this.setState({
      ...this.state,
      collapseHover: !this.state.collapseHover,
    });
  };
  searchPreferenceUpdateAction = (color, preference) => {
    if (this.props.searchFlag === 'search') {
      this.props.updateSearchPreference(color, preference, 'search');
    } else if (this.props.searchFlag === 'savedsearch') {
      switch (color) {
        case '#000000':
          this.props.updateSavedSearchPreference({ withBlack: !preference }, this.props.userSavedDataID);
          break;
        case '#787878':
          this.props.updateSavedSearchPreference({ withCurated: !preference }, this.props.userSavedDataID);
          break;
        case '#ffcf95':
          this.props.updateSavedSearchPreference({ withYellow: !preference }, this.props.userSavedDataID);
          break;
        case '#ff7f27':
          this.props.updateSavedSearchPreference({ withOrange: !preference }, this.props.userSavedDataID);
          break;
        case '#d24242':
          this.props.updateSavedSearchPreference({ withRed: !preference }, this.props.userSavedDataID);
          break;
        default:
          break;
      }
    }
  };
  getColorCode = () => {
    const { storyState, searchFlag, savedSearchDataDetails } = this.props;
    let currentColorCode = {
      [`#000000`]: {
        CheckStatus: storyState.Search_with_black,
        ColorStartPerc: 0,
        ColorStopPerc: 20,
        tooltip: 'Include crowd chatter related to this coin',
      },
      [`#787878`]: {
        CheckStatus: storyState.Search_with_gray,
        ColorStartPerc: 21,
        ColorStopPerc: 40,
        tooltip: 'Include all credible events regardless of their size or followers reach',
      },
      [`#ffcf95`]: {
        CheckStatus: storyState.Search_with_yellow,
        ColorStartPerc: 41,
        ColorStopPerc: 60,
        tooltip: 'Include yellow events with more than 100,000 followers reach or regular social media spikes',
      },
      [`#ff7f27`]: {
        CheckStatus: storyState.Search_with_orange,
        ColorStartPerc: 61,
        ColorStopPerc: 80,
        tooltip: 'Include orange events with more than 1 million followers reach or large social media spikes',
      },
      [`#d24242`]: {
        CheckStatus: storyState.Search_with_red,
        ColorStartPerc: 81,
        ColorStopPerc: 100,
        tooltip: 'Include red events with more than 10 million followers reach or huge social media spikes',
      },
    };
    if (searchFlag === 'savedSearch') {
      const searchData = JSON.parse(savedSearchDataDetails);
      currentColorCode = {
        [`#000000`]: {
          CheckStatus: searchData.withBlack,
          ColorStartPerc: 0,
          ColorStopPerc: 20,
          tooltip: 'Include crowd chatter related to this coin',
        },
        [`#787878`]: {
          CheckStatus: searchData.withCurated,
          ColorStartPerc: 21,
          ColorStopPerc: 40,
          tooltip: 'Include all credible events regardless of their size or followers reach',
        },
        [`#ffcf95`]: {
          CheckStatus: searchData.withYellow,
          ColorStartPerc: 41,
          ColorStopPerc: 60,
          tooltip: 'Include yellow events with more than 100,000 followers reach or regular social media spikes',
        },
        [`#ff7f27`]: {
          CheckStatus: searchData.withOrange,
          ColorStartPerc: 61,
          ColorStopPerc: 80,
          tooltip: 'Include orange events with more than 1 million followers reach or large social media spikes',
        },
        [`#d24242`]: {
          CheckStatus: searchData.withRed,
          ColorStartPerc: 81,
          ColorStopPerc: 100,
          tooltip: 'Include red events with more than 10 million followers reach or huge social media spikes',
        },
      };
    }
    return currentColorCode;
  };

  render() {
    const { TM } = this.props;
    const { collapseHover } = this.state;
    return (
      <>
        {TM && (
          <>
            <Row className={`marg-0 nopad TDeck-window-head margb3`}>
              <Col className={`nopad TDeck-window-head-content-title align-self-center`}>
                <span className="padl10">
                  <img
                    src={`${APPPATH.CoinIconUrl}/${TM.SecurityName.toLowerCase().trim().replace(/ /g, '_')}.png`}
                    className="tmRowTickerIcon imground"
                    alt=""
                  />
                </span>
                <span className={`padl10 tabSubHead`}>
                  ${TM.SecurityTicker} {'Events'}
                </span>
                <span className="float-right padr10 pointer">
                  <ColorPreferenceSlider
                    updateAction={(color, preference) => this.searchPreferenceUpdateAction(color, preference)}
                    colorCodeValue={this.getColorCode()}
                    toggleOverlay={this.props.toggleOverlay}
                    username={this.props.userData.username}
                  />
                  <span className="dispinb posrelative">
                    <span
                      onMouseEnter={this.toggleHover}
                      onMouseLeave={this.toggleHover}
                      onClick={this.props.columnCollapseHandle}
                      style={{ marginLeft: 5 }}
                    >
                      <img
                        style={{ width: '15px' }}
                        src={`${
                          collapseHover
                            ? `${APPPATH.ImageUrl}/storyexpandiconblue.svg`
                            : `${APPPATH.ImageUrl}/storyexpandicon.svg`
                        }`}
                        className={`storyExpandIcon ${!this.props.columnCollapseFlag ? '' : 'transform180anticlock'}`}
                        alt=""
                      />
                    </span>
                  </span>
                </span>
              </Col>
            </Row>
          </>
        )}
      </>
    );
  }
}

export default SearchAndEventSepratorInSearch;
