export const IforexInstrumentId = {
    'Gold': 13103,
    'USDJPY': 12055,
    'Bitcoin': 35631,
    'BTC': 35631,
    'EURUSD': 3631,
    'Crude Oil WTI': 24623,
    'Natural Gas': 27951,
    'US 100': 25391,
    'US 30': 21807,
    'US 500': 25135,
    'GBPUSD': 4143,
    'GBPJPY': 4119,
    'EURJPY': 3607,
    'JP 225': 34839,
    'Ethereum': 12335,
    'ETH': 12335,
    'Silver': 12847,
    'AUDUSD': 303,
    'Nifty 50': 3119,
    'DAX': 26894,
    'Solana': 62515,
    'SOL': 62515,
    'AUDJPY': 279,
    'DogeCoin': 63025,
    'DOGE': 63025,
    'Shiba INU': 7731,
    'SHIB': 7731,
    'USDCAD': 12035,
    'USDCHF': 12036,
    'Bitcoin Cash': 56623,
    'BCH': 56623,
    'Internet Computer': 24112,
    'ICP': 24112,
    'NZDUSD': 7727,
    'USDMXN': 12056,
    'Tesla': 60463,
    'Nvidia': 55600,
    'Apple': 32559,
    'Microsoft': 33583,
}
export const IforexInstrumentorderandsymbol = {
    'Gold': 'Gold',
    'USDJPY': 'USD/JPY',
    'Bitcoin': 'Bitcoin',
    'BTC': 'Bitcoin',
    'EURUSD': 'EUR/USD',
    'Crude Oil WTI': 'WTI Oil',
    'Natural Gas': 'Natural Gas',
    'US 100': 'US Tech 100',
    'US 30': 'US 30',
    'US 500': 'US 500',
    'GBPUSD': 'GBP/USD',
    'GBPJPY': 'GBP/JPY',
    'EURJPY': 'EUR/JPY',
    'JP 225': 'Japan 225 Yen',
    'Ethereum': 'Ethereum',
    'ETH': 'Ethereum',
    'Silver': 'Silver',
    'AUDUSD': 'AUD/USD',
    'Nifty 50': 'NIFTY 50',
    'DAX': 'Germany 40',
    'Solana': 'Solana',
    'SOL': 'Solana',
    'AUDJPY': 'AUD/JPY',
    'DogeCoin': 'Dogecoin',
    'DOGE': 'Dogecoin',
    'Shiba INU': 'SHIBA INU (1000)',
    'SHIB': 'SHIBA INU (1000)',
    'USDCAD': 'USD/CAD',
    'USDCHF': 'USD/CHF',
    'Bitcoin Cash': 'Bitcoin Cash',
    'BCH': 'Bitcoin Cash',
    'Internet Computer': 'Internet Computer',
    'ICP': 'Internet Computer',
    'NZDUSD': 'NZD/USD',
    'USDMXN': 'USD/MXN',
    'Tesla': 'Tesla',
    'Nvidia': 'nVIDIA',
    'Apple': 'Apple',
    'Microsoft': 'Microsoft',
}
export const IforexInstrumentorderandsymbolJapanese = {
    'Gold': '金',
    'USDJPY': '米ドル/円',
    'Bitcoin': 'ビットコイン',
    'BTC': 'ビットコイン',
    'EURUSD': 'ユーロ/米ドル',
    'Crude Oil WTI': 'WTI原油',
    'Natural Gas': '天然ガス',
    'US 100': '米国テック100',
    'US 30': '米国30',
    'US 500': '米国500',
    'GBPUSD': 'ポンド/米ドル',
    'GBPJPY': 'ポンド/円',
    'EURJPY': 'ユーロ/円',
    'JP 225': '日本225円',
    'Ethereum': 'イーサリアム',
    'ETH': 'イーサリアム',
    'Silver': '銀',
    'AUDUSD': '豪ドル/米ドル',
    'Nifty 50': 'NIFTY 50',
    'DAX': 'ドイツ40',
    'Solana': 'ソラナ',
    'SOL': 'ソラナ',
    'AUDJPY': '豪ドル/円',
    'DogeCoin': 'ドージコイン',
    'DOGE': 'ドージコイン',
    'Shiba INU': '柴犬コイン (1000)',
    'SHIB': '柴犬コイン (1000)',
    'USDCAD': '米ドル/カナダドル',
    'USDCHF': '米ドル/スイス',
    'Bitcoin Cash': 'ビットコインキャッシュ',
    'BCH': 'ビットコインキャッシュ',
    'Internet Computer': 'インターネットコンピューター',
    'ICP': 'インターネットコンピューター',
    'NZDUSD': 'NZドル/米ドル',
    'USDMXN': '米ドル/メキシコペソ',
    'Tesla': 'テスラ',
    'Nvidia': 'エヌビディア',
    'Apple': 'アップル',
    'Microsoft': 'マイクロソフト',
}
export const IforexInstrumentorderandsymbolSpanish = {
    'Gold': 'Oro',
    'USDJPY': 'USD/JPY',
    'Bitcoin': 'Bitcoin',
    'BTC': 'Bitcoin',
    'EURUSD': 'EUR/USD',
    'Crude Oil WTI': 'Petróleo WTI',
    'Natural Gas': 'Gas natural',
    'US 100': 'US Tech 100',
    'US 30': 'US 30',
    'US 500': 'US 500',
    'GBPUSD': 'GBP/USD',
    'GBPJPY': 'GBP/JPY',
    'EURJPY': 'EUR/JPY',
    'JP 225': 'Japan 225 Yen',
    'Ethereum': 'Ethereum',
    'ETH': 'Ethereum',
    'Silver': 'Plata',
    'AUDUSD': 'AUD/USD',
    'Nifty 50': 'NIFTY 50',
    'DAX': 'Germany 40',
    'Solana': 'Solana',
    'SOL': 'Solana',
    'AUDJPY': 'AUD/JPY',
    'DogeCoin': 'Dogecoin',
    'DOGE': 'Dogecoin',
    'Shiba INU': 'SHIBA INU (1000)',
    'SHIB': 'SHIBA INU (1000)',
    'USDCAD': 'USD/CAD',
    'USDCHF': 'USD/CHF',
    'Bitcoin Cash': 'Bitcoin Cash',
    'BCH': 'Bitcoin Cash',
    'Internet Computer': 'Internet Computer',
    'ICP': 'Internet Computer',
    'NZDUSD': 'NZD/USD',
    'USDMXN': 'USD/MXN',
    'Tesla': 'Tesla',
    'Nvidia': 'nVIDIA',
    'Apple': 'Apple',
    'Microsoft': 'Microsoft',
}
export const IforexInstrumentorderandsymbolPortuguese = {
    'Gold': 'Ouro',
    'USDJPY': 'USD/JPY',
    'Bitcoin': 'Bitcoin',
    'BTC': 'Bitcoin',
    'EURUSD': 'EUR/USD',
    'Crude Oil WTI': 'Petróleo WTI',
    'Natural Gas': 'Gás Natural',
    'US 100': 'US Tech 100',
    'US 30': 'US 30',
    'US 500': 'US 500',
    'GBPUSD': 'GBP/USD',
    'GBPJPY': 'GBP/JPY',
    'EURJPY': 'EUR/JPY',
    'JP 225': 'Japan 225 Yen',
    'Ethereum': 'Ethereum',
    'ETH': 'Ethereum',
    'Silver': 'Prata',
    'AUDUSD': 'AUD/USD',
    'Nifty 50': 'NIFTY 50',
    'DAX': 'Germany 40',
    'Solana': 'Solana',
    'SOL': 'Solana',
    'AUDJPY': 'AUD/JPY',
    'DogeCoin': 'Dogecoin',
    'DOGE': 'Dogecoin',
    'Shiba INU': 'SHIBA INU (1000)',
    'SHIB': 'SHIBA INU (1000)',
    'USDCAD': 'USD/CAD',
    'USDCHF': 'USD/CHF',
    'Bitcoin Cash': 'Bitcoin Cash',
    'BCH': 'Bitcoin Cash',
    'Internet Computer': 'Internet Computer',
    'ICP': 'Internet Computer',
    'NZDUSD': 'NZD/USD',
    'USDMXN': 'USD/MXN',
    'Tesla': 'Tesla',
    'Nvidia': 'nVIDIA',
    'Apple': 'Apple',
    'Microsoft': 'Microsoft',
}
export const IforexInstrumentorderandsymbolArabic = {
    'Gold': 'الذهب',
    'USDJPY': 'USD/JPY',
    'Bitcoin': 'Bitcoin',
    'BTC': 'Bitcoin',
    'EURUSD': 'EUR/USD',
    'Crude Oil WTI': 'النفط الخام WTI',
    'Natural Gas': 'الغاز الطبيعي',
    'US 100': 'US Tech 100',
    'US 30': 'US 30',
    'US 500': 'US 500',
    'GBPUSD': 'GBP/USD',
    'GBPJPY': 'GBP/JPY',
    'EURJPY': 'EUR/JPY',
    'JP 225': 'Nikkei 225',
    'Ethereum': 'Ethereum',
    'ETH': 'Ethereum',
    'Silver': 'الفضة',
    'AUDUSD': 'AUD/USD',
    'Nifty 50': 'NIFTY 50',
    'DAX': 'Germany 40',
    'Solana': 'Solana',
    'SOL': 'Solana',
    'AUDJPY': 'AUD/JPY',
    'DogeCoin': 'Dogecoin',
    'DOGE': 'Dogecoin',
    'Shiba INU': 'SHIBA INU (1000)',
    'SHIB': 'SHIBA INU (1000)',
    'USDCAD': 'USD/CAD',
    'USDCHF': 'USD/CHF',
    'Bitcoin Cash': 'Bitcoin Cash',
    'BCH': 'Bitcoin Cash',
    'Internet Computer': 'Internet Computer',
    'ICP': 'Internet Computer',
    'NZDUSD': 'NZD/USD',
    'USDMXN': 'USD/MXN',
    'Tesla': 'Tesla',
    'Nvidia': 'nVIDIA',
    'Apple': 'Apple',
    'Microsoft': 'Microsoft',
}