import React from 'react';
// import { Link } from 'react-router-dom';
import { APPPATH } from '../../../modules/helper.module';
import { useSelector } from 'react-redux';

const PremiumButton = ({ noTitle, align, beta = true, toggleConfirmPopup, popup }) => {
  let userName = useSelector(({settings}) => settings.userData.username)
  // console.log(userName)
  return (
    <div className={`logo-wrap premium-icon add-premium ${align ? 'fleft' : ''}`}>
      <div
        className="logo-link pointer"
        onClick={() => {
          if (popup) {
            toggleConfirmPopup(true);
          } else {
            pushTo(userName);
          }
        }}
      >
        <span>
          <img src={`${APPPATH.ImageUrl}/button-premium-small.svg`} alt="CrowdSense.ai" />
        </span>
      </div>
    </div>
  );
};
let pushTo = (userName) => {
  if(typeof userName === 'undefined') { userName = ''; }
  window.open(`${APPPATH.premiumPaymentUrl}?email=${userName}`, '_blank');
};
export default PremiumButton;
