import React, { Component } from 'react';
// import { Row, Col } from 'react-bootstrap';
import IntegrityIndicator from '../shared/indicators/intergrity_indicator';
import { connect } from 'react-redux';
import * as appSettingsActionCreators from '../../actions/settings.action';

class StoryResource extends Component {
  constructor() {
    super();
    this.state = {
      feedid: '',
      resourceTip: false,
      Resources: '',
      Reports: '',
      DetailsLevel: '',
      feedDetails: '',
      resourceTipHandle: false,
      isClickedInstance: false,
    };
    this.ttHandle = '';
    this.resourceWrap = '';
  }
  componentWillMount() {
    // console.log(this.props)
    // let DetailsLevel = this.FeedDetailsLevel(
    //   this.props.Active,
    //   this.props.EventCurated,
    //   this.props.Source,
    //   this.props.EventType
    // );
    let DetailsLevel = this.FeedMagnitudeLevel(
      this.props.Active,
      this.props.EventCurated,
      this.props.MagnitudeRate
    );
    this.setState({
      ...this.state,
      feedid: this.props.FeedId,
      resourceTip: false,
      Resources: this.props.Resources,
      Reports: this.props.Reports,
      DetailsLevel: DetailsLevel,
      feedDetails: this.props.feedDetails,
      resourceTipHandle: this.props.resourceToolTipHandler,
    });
  }
  componentWillReceiveProps(nextProps) {
    // let DetailsLevel = this.FeedDetailsLevel(
    //   nextProps.Active,
    //   nextProps.EventCurated,
    //   nextProps.Source,
    //   nextProps.EventType
    // );
    let DetailsLevel = this.FeedMagnitudeLevel(
      nextProps.Active,
      nextProps.EventCurated,
      nextProps.MagnitudeRate
    );
    this.setState({
      ...this.state,
      feedid: nextProps.FeedId,
      Resources: nextProps.Resources,
      Reports: nextProps.Reports,
      DetailsLevel: DetailsLevel,
      feedDetails: nextProps.feedDetails,
    });
  }
  FeedMagnitudeLevel = (Active, Curated, MagnitudeRate) => {
    let DetailsLevel = '';
    if (!Active && Curated) {
      DetailsLevel = 'gray';
    } else if (!Active && !Curated) {
      DetailsLevel = 'black';
    } else {
      switch (MagnitudeRate) {
        case 1:
          DetailsLevel = 'yellow';
          // magnitudeBg = !EventReadStatus && BlinkNotifyEvent ? 'blink-bg-medium' : 'mag-bg-medium';
          break;
        case 2:
          DetailsLevel = 'orange';
          // magnitudeBg = !EventReadStatus && BlinkNotifyEvent ? 'blink-bg-high' : 'mag-bg-high';
          break;
        case 3:
          DetailsLevel = 'red';
          // magnitudeBg = !EventReadStatus && BlinkNotifyEvent ? 'blink-bg-high' : 'mag-bg-high';
          break;
        default:
          DetailsLevel = 'white';
          // magnitudeBg = !EventReadStatus && BlinkNotifyEvent ? 'blink-bg-default' : 'mag-bg-default';
          break;
      }
    }

    return DetailsLevel;
  };
  FeedDetailsLevel = (Active, Curated, Source, EventType) => {
    let DetailsLevel = 0;
    if (EventType && EventType.toLowerCase().trim().includes('followers post')) {
      DetailsLevel = 4;
    } else {
      if (!Active && Curated) {
        if (this.newsFeedSource(Source)) {
          DetailsLevel = 3; // not active but from News
        } else {
          DetailsLevel = 2; // curated gray
        }
      } else if (!Active && !Curated) {
        if (this.newsFeedSource(Source)) {
          DetailsLevel = 3; // not active but from News
        } else {
          DetailsLevel = 1; // Black Events
        }
      } else {
        DetailsLevel = 4; // Analysed Events
      }
    }

    return DetailsLevel > 0 ? DetailsLevel : 1;
  };
  newsFeedSource = (source) => {
    let sourceType = source.split('|')[1] ? source.split('|')[1] : source;
    let newsFeed = false;
    switch (sourceType) {
      case 'Local News English':
        newsFeed = true;
        break;
      case 'International News English':
        newsFeed = true;
        break;
      case 'Financial News':
        newsFeed = true;
        break;
      case 'Financial News Aggregator':
        newsFeed = true;
        break;
      case 'Bloomberg':
        newsFeed = true;
        break;
      case 'Reuters':
        newsFeed = true;
        break;
      case 'Reddit':
        newsFeed = true;
        break;
      case 'Telegram':
        newsFeed = true;
        break;
      case 'Local News Local Language':
        newsFeed = true;
        break;
      case 'WebSites':
        newsFeed = true;
        break;
      default:
        newsFeed = false;
        break;
    }
    return newsFeed;
  };

  // toggleTipClicked = (mousePosition) => {
  //   this.props.togglePopup(false)
  //   if(this.ttHandle) {
  //     clearTimeout(this.ttHandle)
  //   }
  //   const { Resources, Reports } = this.state;
  //   let resTip = !this.state.resourceTip;
  //   let content = (
  //     <Row className={`marg-0 pad10`}>
  //       <Col xs={12} sm={12} md={12} xl={12} lg={12} className="tipHead" >Story Integrity</Col>
  //       <Col xs={6} sm={6} md={6} xl={6} lg={6} className="tipItem bg-content-2 brdrRT">
  //         <div>
  //           {Reports}<br /><label>Feeds</label>
  //         </div>
  //       </Col>
  //       <Col xs={6} sm={6} md={6} xl={6} lg={6} className="tipItem bg-content-2 brdrLT">
  //         <div>
  //           {Resources} <br /><label>Sources</label>
  //         </div>
  //       </Col>
  //     </Row>
  //   )
  //   let containerStyleClass = 'tooltipMenu'
  //   this.props.togglePopup(resTip, content, mousePosition, containerStyleClass)

  //   this.setState({
  //     ...this.state,
  //     resourceTip: resTip,
  //     isClickedInstance: true,
  //   }, () => {

  //     if(resTip) {
  //       window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
  //       window.Mixpanel.MixpanelProps.EventId = this.props.FeedId;
  //       window.Mixpanel.MixpanelProps.Headline = this.props.HeadlineText;
  //       window.Mixpanel.actions.identify(this.props.userData.username);
  //       window.Mixpanel.actions.track(`Clicked to see story integrity`, window.Mixpanel.MixpanelProps).then(data => {
  //         window.Mixpanel.actions.people.set({
  //           UserName: this.props.userData.username
  //         });
  //         window.Mixpanel.actions.resetProp('EventId');
  //         window.Mixpanel.actions.resetProp('Headline');
  //       })
  //     }

  //   })
  // }
  // toggletip = (event) => {
  //   event.preventDefault();
  //   let ContainerRect = this.resourceWrap.getBoundingClientRect()
  //   let mousePosition = {
  //     left: ContainerRect.left,
  //     top: ContainerRect.bottom + 5
  //   }
  //   if(!this.props.EventReadStatusMain) {
  //     this.props.storyClicked().then(() => {
  //       this.toggleTipClicked(mousePosition)
  //     })
  //   } else {
  //     this.toggleTipClicked(mousePosition)
  //   }

  // }
  toggletooltip = (status) => {
    const { resourceTip } = this.state;
    let ContainerRect = this.resourceWrap.getBoundingClientRect();
    let Pos = {
      left: ContainerRect.left -15,
      top: ContainerRect.bottom + 8,
    };
    let tipText = 'Event sources credibility/reach level';
    const containerStyleClass = `infoTip normal-left blue ${this.props.Theme === 'light' ? 'lightmode' : ''}`;
    if (!resourceTip) {
      if (this.ttHandle) {
        clearTimeout(this.ttHandle);
      }
      if (status) {
        this.ttHandle = setTimeout(() => {
          this.props.togglePopup(status, tipText, Pos, containerStyleClass);
          window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
          window.Mixpanel.MixpanelProps.EventId = this.props.FeedId;
          window.Mixpanel.MixpanelProps.Headline = this.props.HeadlineText;
          window.Mixpanel.MixpanelProps.ToolTipType = 'Resource Indicator';
          window.Mixpanel.actions.identify(this.props.userData.username);
          window.Mixpanel.actions.track(`Tooltip shown`, window.Mixpanel.MixpanelProps).then((data) => {
            window.Mixpanel.actions.people.set({
              UserName: this.props.userData.username,
            });
            window.Mixpanel.actions.resetProp('EventId');
            window.Mixpanel.actions.resetProp('Headline');
            window.Mixpanel.actions.resetProp('ToolTipType');
          });
        }, 1500);
      } else {
        this.props.togglePopup(status);
      }
    }
  };
  render() {
    const { DetailsLevel } = this.state;

    // let reportsStr = Math.abs(Reports) > 999 ? Math.sign(Reports)*((Math.abs(Reports)/1000).toFixed(1)) + 'k' : Math.sign(Reports)*Math.abs(Reports)

    // console.log(DetailsLevel)
    return (
      <div
        className={`story-resource `}
        /*onClick={this.toggletip}*/ onMouseEnter={() => this.toggletooltip(true)}
        onMouseLeave={() => this.toggletooltip(false)}
        ref={(el) => (this.resourceWrap = el)}
      >
        <span className="">
          <IntegrityIndicator integrity={DetailsLevel} applyDefaultFill={true} backFill={`${this.props.Theme === 'light' ? '#eeeeee' : '#111111'}`} />
        </span>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.settings.userData,
    feedDetails: state.feeds.feedDetails,
    Theme: state.app_preferences.preferences.user_interface.Theme,
  };
};

const mapDispatchToProps = {
  ...appSettingsActionCreators,
};

export default connect(mapStateToProps, mapDispatchToProps)(StoryResource);
