import { APICONFIG } from '../modules/helper.module';
import Store from '../store';
// import * as moment from 'moment';
import { NotifyFeedsHandler, ErrorHandleHelper, TrendingMentionsHelper } from '../modules/helper.module';
import * as logactions from '../actions/logAction.action';

async function callTrendingPackages(timeFrame, packageName, maxLimit = false) {
  try {
    // console.log(APICONFIG.apiLoggedInUserType() !== 'None', maxLimit);
    let company = '';
    if(APICONFIG.apiLoggedInUserType() !== 'None' && (APICONFIG.apiLoggedInUserType() !== 'WUser' && APICONFIG.apiLoggedInUserType() !== 'WAdmin')) {
      company = 'company'
    }
    // console.log(company)
    let response = await fetch(
      `${APICONFIG.apiBaseUrl}/api/summeraization/getpackage?timeFrame=${timeFrame}&packageName=${`${company}${packageName}`}&userPackage=${
        !maxLimit ? 
          APICONFIG.apiLoggedInUserPackage() : 
        maxLimit <= 50 ? 
          'Bronze' : 'Premium'
        // !TrendingMentionsHelper.FreeSubscriptionFilter() ? 
        //   'Premium' : 
        //   APICONFIG.apiLoggedInUserPackage()
      }`,
      {
        method: 'GET',
        headers: APICONFIG.apiHeaders(),
      }
    );
    // console.log(response)
    return response;
  } catch (err) {
    throw err;
  }
}
async function callTMDetails(TM) {
  try {
    let response = await fetch(
      `${APICONFIG.apiBaseUrl}/api/summeraization/getcoindata?securityName=${TM.SecurityName}&securityTicker=${TM.SecurityTicker}`,
      {
        method: 'GET',
        headers: APICONFIG.apiHeaders(),
      }
    );
    return response;
  } catch (err) {
    throw err;
  }
}

function getActiveTimeFrame() {
  try {
    let ActiveTFrame = '';
    let TimeFrame = Store.getState().trendingMentions.tmFrame;
    Object.keys(TimeFrame).map((frame) => {
      if (TimeFrame[frame]) {
        ActiveTFrame = frame;
      }
      return null;
    });
    return ActiveTFrame;
  } catch (err) {
    logactions.addLogAction('error in getActiveTimeFrame :' + err);
  }
}

export function getCoinData() {
  return (dispatch) => {
    let DefaultTickerData = null;
    let TMDetails = {};
    let coindetail = window.localStorage.getItem('SavedCoinDetail');
    if (coindetail !== null) {
      DefaultTickerData = JSON.parse(coindetail);
      callTMDetails(DefaultTickerData)
        .then(async (DetRes) => {
          if (DetRes.status === 200 && DetRes.ok) {
            TMDetails = await DetRes.json();
            // console.log(TMDetails)
            dispatch({
              type: 'COIN_DETAIL_COLUMN_SET',
              payload: TMDetails
            });
          }
        })
      // console.log(DefaultTickerData)
      // let DetRes = await callTMDetails(DefaultTickerData);
      // if (DetRes.status === 200 && DetRes.ok) {
      //   TMDetails = await DetRes.json();
      //   // console.log(TMDetails)
      // }
    }
  }
}

export function getTrendingMentions(columnIndex = 0) {
  // console.log(Store.getState().trendingMentions.coin_column_preferences[columnIndex])
  return (dispatch) => {
    try {
      let packageName = Store.getState().trendingMentions.coin_column_preferences[columnIndex].packageName;
      let maxLimit = parseInt(Store.getState().trendingMentions.coin_column_preferences[columnIndex].tmtotalmaxcoincount);
      let tmFromWatchlist = Store.getState().trendingMentions.coin_column_preferences[columnIndex].tmFromWatchlist === 'true';
      let prefObj = Store.getState().trendingMentions.coin_column_preferences[columnIndex];
      // console.log(packageName, maxLimit, tmFromWatchlist)
      // let packageName = Store.getState().trendingMentions.packageName;
      // let SuscriptionStatus = Store.getState().settings.subscriptionStatus;
      // let maxLimit = Store.getState().trendingMentions.tmtotalmaxcoincount;
      // let tmFromWatchlist = Store.getState().trendingMentions.tmFromWatchlist;
      // console.log('from watchlist:', tmFromWatchlist)
      let timeFrame = getActiveTimeFrame();
      let DefaultTickerData = null;
      let TMDetails = {};
      let coindetail = window.localStorage.getItem('SavedCoinDetail');
      callTrendingPackages(timeFrame, packageName, maxLimit)
        .then(async (response) => {
          let SortingColumn = Store.getState().trendingMentions.coin_column_preferences[columnIndex].tmSortedColumn;
          let SortingType = parseInt(Store.getState().trendingMentions.coin_column_preferences[columnIndex].tmSortingType);
          if (response.status === 200 && response.ok) {
            await response.json().then(async (result) => {
              // console.log(result)
              // let modifiedResult = result; 
              // let modifiedDump = result;
              let modData = TrendingMentionsHelper.FormatCoinlist(result);

              // console.log(modData)
              
              if(tmFromWatchlist) {
                modData = TrendingMentionsHelper.HandleForWatchlist(modData);
              //   modifiedDump = modData.modifiedDump
              //   modifiedResult = modData.modifiedResult
              //   // 
              }
              // let FilteredResult = TrendingMentionsHelper.FreeTopTrendingOnlyFilter(modData, prefObj);
              let FilteredResult = modData;
              // console.log(FilteredResult)
              // if (coindetail !== null) {
              //   DefaultTickerData = JSON.parse(coindetail);
              //   let DetRes = await callTMDetails(DefaultTickerData);
              //   if (DetRes.status === 200 && DetRes.ok) {
              //     TMDetails = await DetRes.json();
              //   }
              // }
              // console.log(TMDetails)
              dispatch({
                type: 'RESET_TMSORT',
              });
              dispatch({
                type: 'TM_SET',
                payload: FilteredResult,
                coinsDump: result,
                SortingColumn,
                SortingType,
                packageName,
                // DefaultTickerData: TMDetails,
                index: columnIndex
              });
              window.Mixpanel.MixpanelProps.UserName = APICONFIG.apiLoggedInUser();
              window.Mixpanel.actions.identify(APICONFIG.apiLoggedInUser());
              window.Mixpanel.actions
                .track('Top Opportunities call completed successfully', window.Mixpanel.MixpanelProps)
                .then((data) => {
                  window.Mixpanel.actions.people.set({
                    UserName: APICONFIG.apiLoggedInUser(),
                  });
                });
            });
          } else {
            await response.json().then((result) => {
              //   console.log(result)
              throw new Error(`${response.status} : ${response.statusText} - ${result.Message}`);
            });
          }
        })
        .catch((err) => {
          console.log('error in getTrendingMentions', err);
          // logactions.logger({Message: 'error in getTrendingMentions'+ err})
          // throw new Error('error in  getTrendingMentions' + err);
          // console.log(err);
          window.Mixpanel.MixpanelProps.UserName = APICONFIG.apiLoggedInUser();
          window.Mixpanel.MixpanelProps.Error = err;
          window.Mixpanel.actions.identify(APICONFIG.apiLoggedInUser());
          window.Mixpanel.actions.track('Top Opportunities call Failed', window.Mixpanel.MixpanelProps).then((data) => {
            window.Mixpanel.actions.people.set({
              UserName: APICONFIG.apiLoggedInUser(),
            });
            window.Mixpanel.actions.resetProp('Error');
          });
          let data = {
            username: APICONFIG.apiLoggedInUser(),
            action: 'Get Trending Mentions',
            url: `${
              APICONFIG.apiBaseUrl
            }/api/summeraization/getpackage?timeFrame=${timeFrame}&packageName=${packageName}&userPackage=${APICONFIG.apiLoggedInUserPackage()}`,
            severity: 'high',
          };
          dispatch({
            type: 'REQUEST_ERROR',
            payload: ErrorHandleHelper.generateErrorString(err, 'getTrendingMentions'),
            data: data,
          });
        });
    } catch (err) {
      // logactions.addLogAction(err)
    }
  };
}

export function setTmReqData(tmreqdata, cc_index = 0) {
  return (dispatch) => {
    try {
      dispatch({
        type: 'SET_TMREQDATA',
        payload: tmreqdata,
        index: cc_index
      });
    } catch (err) {
      // logactions.logger({Message: 'error in setTmReqData'+ err})
      // logactions.addLogAction('error in setTmReqData :' +err)
    }
  };
}

export function updateTrendingMentions(columnIndex = 0) {
  // console.log('intm actions')
  return (dispatch) => {
    try {
      let packageName = Store.getState().trendingMentions.coin_column_preferences[columnIndex].packageName;
      let maxcoincount = parseInt(Store.getState().trendingMentions.coin_column_preferences[columnIndex].tmListMaxCoinCount);
      let maxLimit = parseInt(Store.getState().trendingMentions.coin_column_preferences[columnIndex].tmtotalmaxcoincount);
      let SortingColumn = Store.getState().trendingMentions.coin_column_preferences[columnIndex].tmSortedColumn;
      let SortingType = parseInt(Store.getState().trendingMentions.coin_column_preferences[columnIndex].tmSortingType);
      let tmFromWatchlist = Store.getState().trendingMentions.coin_column_preferences[columnIndex].tmFromWatchlist === 'true';
      let prefObj = Store.getState().trendingMentions.coin_column_preferences[columnIndex];
      // let packageName = Store.getState().trendingMentions.packageName;
      // let maxcoincount = Store.getState().trendingMentions.tmListMaxCoinCount;
      // let maxLimit = Store.getState().trendingMentions.tmtotalmaxcoincount;
      let subscriptionStatus = Store.getState().settings.userData.userPackage;
      let timeFrame = getActiveTimeFrame();
      // let SortingColumn = Store.getState().trendingMentions.tmSortedColumn;
      // let SortingType = Store.getState().trendingMentions.tmSortingType;
      // let tmFromWatchlist = Store.getState().trendingMentions.tmFromWatchlist;
      // console.log('from watchlist:', tmFromWatchlist)
      callTrendingPackages(timeFrame, packageName, maxLimit)
        .then(async (response) => {
          if (response.status === 200 && response.ok) {
            await response.json().then((result) => {
              // console.log('updated results TM')
              // console.log(result)
              // let modifiedResult = result;
              // if(tmFromWatchlist) {
                
              //   modifiedResult = TrendingMentionsHelper.HandleForWatchlist(result);
              // }
              // let FilteredResult = TrendingMentionsHelper.FreeTopTrendingOnlyFilter(modifiedResult);

              // let modifiedResult = result; let modifiedDump = result;
              let modData = TrendingMentionsHelper.FormatCoinlist(result);
              
              
              if(tmFromWatchlist) {
                modData = TrendingMentionsHelper.HandleForWatchlist(modData);
              //   modifiedDump = modData.modifiedDump
              //   modifiedResult = modData.modifiedResult
              //   // 
              }
              // let FilteredResult = TrendingMentionsHelper.FreeTopTrendingOnlyFilter(modData, prefObj);
              let FilteredResult = modData;

              dispatch({
                type: 'TM_UPDATE',
                payload: FilteredResult,
                coinsDump: result,
                SortingColumn,
                SortingType,
                packageName,
                subscriptionStatus,
                maxcoincount,
                index: columnIndex
              });
            });
          } else {
            await response.json().then((result) => {
              //   console.log(result)
              throw new Error(`${response.status} : ${response.statusText} - ${result.Message}`);
            });
          }
        })
        .catch((err) => {
          console.log('error in updateTrendingMentions', err);
          // logactions.logger({Message: 'error in updateTrendingMentions'+ err})
          // throw new Error('error in  updateTrendingMentions' + err);
          // console.log(err);
          let data = {
            username: APICONFIG.apiLoggedInUser(),
            action: 'Update Trending Mentions',
            url: `${
              APICONFIG.apiBaseUrl
            }/api/summeraization/getpackage?timeFrame=${timeFrame}&packageName=${packageName}&userPackage=${APICONFIG.apiLoggedInUserPackage()}`,
            severity: 'low',
          };
          dispatch({
            type: 'REQUEST_ERROR',
            payload: ErrorHandleHelper.generateErrorString(err, 'updateTrendingMentions'),
            data: data,
          });
        });
    } catch (err) {
      // logactions.addLogAction(err)
    }
  };
}
export function updateTMAutoRefresh(status) {
  return (dispatch) => {
    try {
      dispatch({
        type: 'UPDATE_TM_AUTOREFRESH',
        payload: status,
      });
    } catch (err) {
      // logactions.logger({Message: 'error in updateTMAutoRefresh'+ err})
      // logactions.addLogAction('error in updateTMAutoRefresh :' +err)
    }
  };
}

export function getTMFrame() {
  return (dispatch) => {
    try {
      let TMFrame = window.sessionStorage.getItem('TMFrame');
      let StateTMFrame = Store.getState().trendingMentions.tmFrame;
      if (TMFrame) {
        Object.keys(StateTMFrame).map((frame) => {
          if (frame === TMFrame) {
            StateTMFrame[frame] = true;
          } else {
            StateTMFrame[frame] = false;
          }
          return null;
        });
      }
      dispatch({
        type: 'SET_TMFRAME',
        payload: StateTMFrame,
      });
    } catch (err) {
      // logactions.logger({Message: 'error in getTMFrame'+ err})
      // logactions.addLogAction('error in getTMFrame :' +err)
    }
  };
}

export function setTMFrame(Frame) {
  return (dispatch) => {
    try {
      let StateTMFrame = Store.getState().trendingMentions.tmFrame;

      Object.keys(StateTMFrame).map((frm) => {
        if (frm === Frame) {
          StateTMFrame[frm] = true;
        } else {
          StateTMFrame[frm] = false;
        }
        return null;
      });

      dispatch({
        type: 'SET_TMFRAME',
        payload: StateTMFrame,
      });
    } catch (err) {
      // logactions.logger({Message: 'error in setTMFrame'+ err})
      // logactions.addLogAction('error in setTMFrame :' +err)
    }
  };
}

export function SetTMSort(activeTab, sortedColumn, sortingType, columnChange, prefObj, index) {
  return (dispatch) => {
    // console.log(activeTab, sortedColumn, sortingType, columnChange)
    try {
      let PackageName = prefObj.packageName;
      // let newPackageName = '';
      // let PackageName = Store.getState().trendingMentions.packageName;
      let maxLimit = parseInt(prefObj.tmtotalmaxcoincount);
      // let maxLimit = Store.getState().trendingMentions.tmtotalmaxcoincount;
      let coinsDump = Store.getState().trendingMentions.coinsDump;
      // let tmFromWatchlist = Store.getState().trendingMentions.tmFromWatchlist;
      let tmFromWatchlist = prefObj.tmFromWatchlist === 'true';
      // console.log('from watchlist:', tmFromWatchlist)
      let timeFrame = getActiveTimeFrame();
      let CallPackageFlag = false;
      if (!columnChange) {
        if (prefObj.activeTab === 'TopMentions' || prefObj.activeTab === 'TopReach') {
          // dispatch({
          //   type: 'TM_SETPACKAGE',
          //   payload: PackageName,
          // });
          // dispatch({
          //   type: 'SET_ACTIVECOLUMNS',
          //   payload: {
          //     sortedColumn: prefObj.tmSortedColumn,
          //     sortingType: parseInt(prefObj.tmSortingType),
          //   },
          // });
          let CoinsDump = JSON.parse(JSON.stringify(coinsDump));
          
          let SortingColumn = TrendingMentionsHelper.getSortedColumnKey(prefObj.tmSortedColumn, PackageName);
         
          let modData = TrendingMentionsHelper.SortonSortingType(CoinsDump, SortingColumn, parseInt(prefObj.tmSortingType));
          let modifiedResult = [];
          if(tmFromWatchlist) {
            modifiedResult = TrendingMentionsHelper.HandleForWatchlist(modData);
          } else {
            modifiedResult = modData;
          }
          
          // let FilteredList = TrendingMentionsHelper.FreeTopTrendingOnlyFilter(modifiedResult, prefObj);
          let FilteredList = modifiedResult;
          
          dispatch({
            type: 'TM_SET_ONLY',
            payload: FilteredList,
            coinsDump: modData,
            SortingColumn: prefObj.tmSortedColumn,
            SortingType: parseInt(prefObj.tmSortingType),
            packageName: PackageName,
            index
            // DefaultTickerData: TMDetails,
          });
          // dispatch({
          //   type: 'SET_TMSORT',
          //   payload: {
          //     activeTab,
          //     sortedColumn,
          //     sortingType,
          //     FilteredList,
          //     reversedCoinsDump: modData
          //   },
          // });
          return;
        } else {
          dispatch({
            type: 'RESET_TM',
            payload: index
          });
          dispatch({
            type: 'CHANGE_COL_TAB_TM',
            payload: true,
            index
          });
          CallPackageFlag = true;
        }
        // else if(activeTab === 'TopVolume') {
        //   // switch (PackageName) {
        //   //   case 'volumepositive':
        //   //     newPackageName = 'volumenegative';
        //   //     sortingType = -1;
        //   //     break;
        //   //   case 'volumenegative':
        //   //     newPackageName = 'volumepositive';
        //   //     sortingType = 1;
        //   //     break;
        //   //   default:
        //   //     newPackageName = PackageName;
        //   //     break;
        //   // }
        //   dispatch({
        //     type: 'RESET_TM',
        //   });
        //   dispatch({
        //     type: 'CHANGE_COL_TAB_TM',
        //     payload: true,
        //   });
        //   CallPackageFlag = true;
        // } else if (activeTab === 'TopSentiment') {
        //   // switch (PackageName) {
        //   //   case 'mentionspositive':
        //   //     newPackageName = 'mentionsnegative';
        //   //     sortingType = -1;
        //   //     break;
        //   //   case 'mentionsnegative':
        //   //     newPackageName = 'mentionspositive';
        //   //     sortingType = 1;
        //   //     break;
        //   //   case 'mentionschangepositive':
        //   //     newPackageName = 'mentionschangenegative';
        //   //     sortingType = -1;
        //   //     break;
        //   //   case 'mentionschangenegative':
        //   //     newPackageName = 'mentionschangepositive';
        //   //     sortingType = 1;
        //   //     break;
        //   //   default:
        //   //     newPackageName = PackageName;
        //   //     break;
        //   // }
        //   dispatch({
        //     type: 'RESET_TM',
        //   });
        //   dispatch({
        //     type: 'CHANGE_COL_TAB_TM',
        //     payload: true,
        //   });
        //   CallPackageFlag = true;
        // } else if (activeTab === 'TopPrice') {
        //   // switch (PackageName) {
        //   //   case 'pricechangepositive':
        //   //     newPackageName = 'pricechangenegative';
        //   //     sortingType = -1;
        //   //     break;
        //   //   case 'pricechangenegative':
        //   //     newPackageName = 'pricechangepositive';
        //   //     sortingType = 1;
        //   //     break;
        //   //   case 'pricepositive':
        //   //     newPackageName = 'pricenegative';
        //   //     sortingType = -1;
        //   //     break;
        //   //   case 'pricenegative':
        //   //     newPackageName = 'pricepositive';
        //   //     sortingType = 1;
        //   //     break;
        //   //   default:
        //   //     newPackageName = PackageName;
        //   //     break;
        //   // }
        //   dispatch({
        //     type: 'RESET_TM',
        //   });
        //   dispatch({
        //     type: 'CHANGE_COL_TAB_TM',
        //     payload: true,
        //   });
        //   CallPackageFlag = true;
        // }
      } else {
        // if (activeTab === 'TopMentions') {
        //   if (sortedColumn === 'Mentions Score') {
        //     newPackageName = 'mentions';
        //   } else if (sortedColumn === 'Mentions Change') {
        //     newPackageName = 'mentionschange';
        //   }
        // } else if (activeTab === 'TopReach') {
        //   if (sortedColumn === 'Reach Score') {
        //     newPackageName = 'exposure';
        //   } else if (sortedColumn === 'Reach Change') {
        //     newPackageName = 'exposurechange';
        //   }
        // } else if (activeTab === 'TopSentiment') {
        //   if (sortedColumn === 'Sentiment') {
        //     newPackageName = 'mentionspositive';
        //   } else if (sortedColumn === 'Sentiment Change') {
        //     newPackageName = 'mentionschangepositive';
        //   }
        // } else if (activeTab === 'TopPrice') {
        //   if (sortedColumn === 'Price Change') {
        //     newPackageName = 'pricechangepositive';
        //   } else if (sortedColumn === 'Price') {
        //     newPackageName = 'pricepositive';
        //   }
        // }
        dispatch({
          type: 'RESET_TM',
          payload: index
        });
        dispatch({
          type: 'CHANGE_COL_TAB_TM',
          payload: true,
          index
        });
        CallPackageFlag = true;
      }
      // console.log(newPackageName)
      if (CallPackageFlag) {
        callTrendingPackages(timeFrame, PackageName, maxLimit)
          .then(async (response) => {
            if (response.status === 200 && response.ok) {
              await response.json().then(async (result) => {

                // dispatch({
                //   type: 'TM_SETPACKAGE',
                //   payload: PackageName,
                // });
                // dispatch({
                //   type: 'SET_ACTIVECOLUMNS',
                //   payload: {
                //     sortedColumn: prefObj.tmSortedColumn,
                //     sortingType: parseInt(prefObj.tmSortingType),
                //   },
                // });
                let SortingColumn = TrendingMentionsHelper.getSortedColumnKey(prefObj.tmSortedColumn, PackageName);
         
                let modData = TrendingMentionsHelper.SortonSortingType(result, SortingColumn, parseInt(prefObj.tmSortingType));
                let modifiedResult = [];
                if(tmFromWatchlist) {
                  modifiedResult = TrendingMentionsHelper.HandleForWatchlist(modData);
                } else {
                  modifiedResult = modData;
                }
                
                // let FilteredResult = TrendingMentionsHelper.FreeTopTrendingOnlyFilter(modifiedResult, prefObj);
                let FilteredResult = modifiedResult;
                // let DefaultTickerData = null;
                // let TMDetails = {};
                // let coindetail = window.localStorage.getItem('SavedCoinDetail');
                // if (coindetail !== null) {
                //   DefaultTickerData = JSON.parse(coindetail);
                //   let DetRes = await callTMDetails(DefaultTickerData);
                //   if (DetRes.status === 200 && DetRes.ok) {
                //     TMDetails = await DetRes.json();
                //     // console.log(TMDetails)
                //   }
                // }
                
                dispatch({
                  type: 'CHANGE_COL_TAB_TM',
                  payload: false,
                  index
                });
                dispatch({
                  type: 'TM_SET_ONLY',
                  payload: FilteredResult,
                  coinsDump: modData,
                  SortingColumn: prefObj.tmSortedColumn,
                  SortingType: parseInt(prefObj.tmSortingType),
                  packageName: PackageName,
                  index
                  // DefaultTickerData: TMDetails,
                });

                // dispatch({
                //     type: 'RESET_TMSORT'
                // })
              });
            } else {
              await response.json().then((result) => {
                //   console.log(result)
                throw new Error(`${response.status} : ${response.statusText} - ${result.Message}`);
              });
            }
          })
          .catch((err) => {
            // throw err;
            console.log('error in SetTMSort', err);
            // logactions.logger({Message: 'error in SetTMSort'+ err})
            // throw new Error('error in  SetTMSort' + err);
            let data = {
              username: APICONFIG.apiLoggedInUser(),
              action: 'SetTMSort',
              url: `${
                APICONFIG.apiBaseUrl
              }/api/summeraization/getpackage?timeFrame=${timeFrame}&packageName=${PackageName}&userPackage=${APICONFIG.apiLoggedInUserPackage()}`,
              severity: 'low',
            };
            dispatch({
              type: 'REQUEST_ERROR',
              payload: ErrorHandleHelper.generateErrorString(err, 'SetTMSort'),
              data: data,
            });
          });
      }
    } catch (err) {
      // logactions.addLogAction(err)
    }
  };
}

export function resetTM(index = 0) {
  return (dispatch) => {
    try {
      dispatch({
        type: 'RESET_TM',
        payload: index
      });
    } catch (err) {
      logactions.logger({ Message: 'error in resetTM' + err });
      // logactions.addLogAction('error in resetTM :' +err)
    }
  };
}

export function TabColumnChangeRequest(state = false, index = 0) {
  return (dispatch) => {
    try {
      dispatch({
        type: 'CHANGE_COL_TAB_TM',
        payload: state,
        index
      });
    } catch (err) {
      // logactions.logger({Message: 'error in TabColumnChangeRequest'+ err})
      // logactions.addLogAction('error in TabColumnChangeRequest :' +err)
    }
  };
}

export function ChangeTMActiveTab(tab, prefObj, cc_index) {
  return (dispatch) => {
    console.log(prefObj)
    try {
      let PackageName = prefObj.packageName;
      let sortedColumn = prefObj.tmSortedColumn;
      let tmFromWatchlist = prefObj.tmFromWatchlist === 'true';
      let maxLimit = parseInt(prefObj.tmtotalmaxcoincount);
      let SortingType = parseInt(prefObj.tmSortingType);
      // let PackageName = 'mentionschange';
      // let sortedColumn = 'Mentions Change';
      // let currentSortedColumn = Store.getState().trendingMentions.tmSortedColumn;
      // let tmFromWatchlist = Store.getState().trendingMentions.tmFromWatchlist;
      // let columnToStay = (currentSortedColumn === 'Mentions Score' || currentSortedColumn === 'Reach Score' || currentSortedColumn === 'Sentiment' || currentSortedColumn === 'Price' || currentSortedColumn === 'Volume') ? 'score' : 'change'
      // let maxLimit = Store.getState().trendingMentions.tmtotalmaxcoincount;
      // let SortingType = Store.getState().trendingMentions.tmSortingType;
      // switch (tab) {
      //   case 'TopMentions':
      //     if(columnToStay === 'change') {
      //       PackageName = 'mentionschange';
      //       sortedColumn = 'Mentions Change';
      //       break;
      //     } else {
      //       PackageName = 'mentions';
      //       sortedColumn = 'Mentions Score';
      //       break;
      //     }
          
      //   case 'TopReach':
      //     if(columnToStay === 'change') {
      //       PackageName = 'exposurechange';
      //       sortedColumn = 'Reach Change';
      //       break;
      //     } else {
      //       PackageName = 'exposure';
      //       sortedColumn = 'Reach Score';
      //       break;
      //     }
          
      //   case 'TopSentiment':
      //     if(SortingType === 1) {
      //       if(columnToStay === 'change') {
      //         PackageName = 'mentionschangepositive';
      //         sortedColumn = 'Sentiment Change';
      //         break;
      //       } else {
      //         PackageName = 'mentionspositive';
      //         sortedColumn = 'Sentiment';
      //         break;
      //       }
      //     } else {
      //       if(columnToStay === 'change') {
      //         PackageName = 'mentionschangenegative';
      //         sortedColumn = 'Sentiment Change';
      //         break;
      //       } else {
      //         PackageName = 'mentionsnegative';
      //         sortedColumn = 'Sentiment';
      //         break;
      //       }
      //     }
          
          
      //   case 'TopPrice':
      //     if(SortingType === 1) {
      //       if(columnToStay === 'change') {
      //         PackageName = 'pricechangepositive';
      //         sortedColumn = 'Price Change';
      //         break;
      //       } else {
      //         PackageName = 'pricepositive';
      //         sortedColumn = 'Price';
      //         break;
      //       }
      //     } else {
      //       if(columnToStay === 'change') {
      //         PackageName = 'pricechangenegative';
      //         sortedColumn = 'Price Change';
      //         break;
      //       } else {
      //         PackageName = 'pricenegative';
      //         sortedColumn = 'Price';
      //         break;
      //       }
      //     }
      //   case 'TopVolume':
      //     if(SortingType === 1) {
      //       PackageName = 'volumepositive';
      //       sortedColumn = 'Volume';
      //       break;
      //     } else {
      //       PackageName = 'volumenegative';
      //       sortedColumn = 'Volume';
      //       break;
      //     }
      //   default:
      //     break;
      // }
      // dispatch({
      //   type: 'SET_ACTIVETAB',
      //   payload: prefObj.activeTab,
      // });
      let timeFrame = getActiveTimeFrame();
      callTrendingPackages(timeFrame, PackageName, maxLimit)
        .then(async (response) => {
          if (response.status === 200 && response.ok) {
            await response.json().then(async (result) => {
              console.log(result)
              // dispatch({
              //   type: 'TM_SETPACKAGE',
              //   payload: PackageName,
              // });

              // dispatch({
              //   type: 'SET_ACTIVECOLUMNS',
              //   payload: {
              //     sortedColumn: sortedColumn,
              //     sortingType: SortingType,
              //   },
              // });
              let SortingColumn = TrendingMentionsHelper.getSortedColumnKey(sortedColumn, PackageName);
         
              let modData = TrendingMentionsHelper.SortonSortingType(result, SortingColumn, SortingType);
              let modifiedResult = [];
              if(tmFromWatchlist) {
                modifiedResult = TrendingMentionsHelper.HandleForWatchlist(modData);
              } else {
                modifiedResult = modData;
              }
              
              
              // let FilteredResult = TrendingMentionsHelper.FreeTopTrendingOnlyFilter(modifiedResult, prefObj);
              let FilteredResult = modifiedResult;
              console.log(FilteredResult)
              // let DefaultTickerData = null;
              // let TMDetails = {};
              // let coindetail = window.localStorage.getItem('SavedCoinDetail');
              // if (coindetail !== null) {
              //   DefaultTickerData = JSON.parse(coindetail);
              //   let DetRes = await callTMDetails(DefaultTickerData);
              //   if (DetRes.status === 200 && DetRes.ok) {
              //     TMDetails = await DetRes.json();
              //     console.log(TMDetails);
              //   }
              // }
              
              dispatch({
                type: 'CHANGE_COL_TAB_TM',
                payload: false,
                index: cc_index
              });
              dispatch({
                type: 'TM_SET_ONLY',
                coinsDump: modData,
                payload: FilteredResult,
                SortingColumn: sortedColumn,
                SortingType: SortingType,
                packageName: PackageName,
                index: cc_index
                // DefaultTickerData: TMDetails,
              });
              // dispatch ({
              //     type: 'CLOSE_TM_DETAILS',
              // })

              // dispatch({
              //     type: 'RESET_TMSORT'
              // })
            });
          } else {
            await response.json().then((result) => {
              //   console.log(result)
              throw new Error(`${response.status} : ${response.statusText} - ${result.Message}`);
            });
          }
        })
        .catch((err) => {
          console.log('error in ChangeTMActiveTab', err);
          // logactions.logger({Message: 'error in ChangeTMActiveTab'+ err})
          // throw new Error('error in  ChangeTMActiveTab' + err);
          let data = {
            username: APICONFIG.apiLoggedInUser(),
            action: 'ChangeTMActiveTab',
            url: `${
              APICONFIG.apiBaseUrl
            }/api/summeraization/getpackage?timeFrame=${'1H'}&packageName=${PackageName}&userPackage=${APICONFIG.apiLoggedInUserPackage()}`,
            severity: 'low',
          };
          dispatch({
            type: 'REQUEST_ERROR',
            payload: ErrorHandleHelper.generateErrorString(err, 'ChangeTMActiveTab'),
            data: data,
          });
        });
    } catch (err) {
      // logactions.addLogAction(err)
    }

    // dispatch({
    //     type: 'RESET_TMSORT'
    // })
  };
}

export function ResetTMSort() {
  return (dispatch) => {
    try {
      dispatch({
        type: 'RESET_TMSORT',
      });
    } catch (err) {
      logactions.logger({ Message: 'error in ResetTMSort' + err });
      // logactions.addLogAction('error in ResetTMSort :' +err)
    }
  };
}


export function loadtickerdata(securityname, securityticker, sourceFilter, index, updateMode = false) {
  return (dispatch) => {
    try {
      fetch(
        `${APICONFIG.apiBaseUrl}/api/summeraization/getcoindata?securityName=${securityname}&securityTicker=${securityticker}`,
        {
          method: 'GET',
          headers: APICONFIG.apiHeaders(),
        }
      )
      .then((response) => response.json())
      .then((result) => {
        // console.log(securityname, result)
        // return;
        if(updateMode) {
          dispatch({
            type: 'COINDETAILCOLUMN_TICKERDATA_UPDATE',
            payload: result,
            index,
            SecurityName : securityname,
            SourceFilter : sourceFilter
          });
        } else {
          dispatch({
            type: 'COINDETAILCOLUMN_TICKERDATA',
            payload: result,
            index,
            SecurityName : securityname,
            SourceFilter : sourceFilter
          });
        }
        
        
      })
      .catch((err) => {
        console.log('error in loadtickerdata', err);
        // logactions.logger({Message: 'error in TMViewDetails'+ err})
        // throw new Error('error in  TMViewDetails' + err);
        // console.log('ErroroCoinDetails', err);
        window.Mixpanel.MixpanelProps.UserName = APICONFIG.apiLoggedInUser();
        window.Mixpanel.actions.identify(APICONFIG.apiLoggedInUser());
        window.Mixpanel.actions
          .track('Coin Column Tickerdata loading failed', window.Mixpanel.MixpanelProps)
          .then((data) => {
            window.Mixpanel.actions.people.set({
              UserName: APICONFIG.apiLoggedInUser(),
            });
            window.Mixpanel.actions.resetProp('Error');
          });
        let data = {
          username: APICONFIG.apiLoggedInUser(),
          action: 'Coin Data',
          url: `${APICONFIG.apiBaseUrl}/api/summeraization/getcoindata?securityName=${securityname}&securityTicker=${securityticker}`,
          severity: 'low',
        };
        dispatch({
          type: 'REQUEST_ERROR',
          payload: ErrorHandleHelper.generateErrorString(err, 'loadtickerdata'),
          data: data,
        });
      });
    } catch (err) {
      console.log('error in loadtickerdata', err);
      // logactions.logger({Message: 'error in TMViewDetails'+ err})
      // throw new Error('error in  TMViewDetails' + err);
      // console.log('ErroroCoinDetails', err);
      let data = {
        username: APICONFIG.apiLoggedInUser(),
        action: 'Ticker Data',
        url: `${APICONFIG.apiBaseUrl}/api/summeraization/getcoindata?securityName=${securityname}&securityTicker=${securityticker}`,
        severity: 'low',
      };
      dispatch({
        type: 'REQUEST_ERROR',
        payload: ErrorHandleHelper.generateErrorString(err, 'loadtickerdata'),
        data: data,
      });
    }
  }
}

export function TMViewDetails(TM, updateMode = false, updateSearch = false, isSingleCoinSearch = false) {
  return (dispatch) => {
    try {
      if (TM.SecurityName !== null && typeof TM.SecurityName !== 'undefined') {
        let columnlist = Store.getState().settings.columnlist;
        let Tickerdata = Store.getState().trendingMentions.tmDetails.TickerData;
        let tmDetailPreferences = Store.getState().trendingMentions.tmDetailPreferences;
        let coincolumnsecuritynameArr = tmDetailPreferences.length ? tmDetailPreferences.map((item) => item.SecurityName).filter((itm) => itm) : []
        let columnExist = false;
        let existingColumnName = '';
        let switchfocus = false;
        if(TM.SecurityName === Tickerdata.SecurityName) { columnExist = true; existingColumnName = 'Trending Mentions Details' }
        if(coincolumnsecuritynameArr.indexOf(TM.SecurityName) > -1) { 
          columnExist = true; 
          existingColumnName = `Coin_Column_details__${coincolumnsecuritynameArr.indexOf(TM.SecurityName)}` 
        }
        if(!updateMode && columnExist) { switchfocus = true; }
        if(!switchfocus) {
          fetch(`${APICONFIG.apiBaseUrl}/api/summeraization/getcoindata?securityName=${TM.SecurityName}&securityTicker=${TM.SecurityTicker}`,
            {
              method: 'GET',
              headers: APICONFIG.apiHeaders(),
            }
          )
          .then((response) => response.json())
          .then((result) => {
            // console.log(result, TM);
            // return;
            // if (result.SecurityName !== null) {
              if (!updateMode) {
                dispatch({
                  type: 'VIEW_TM_DETAILS',
                  payload: {
                    TM: result,
                    ViewDetailsState: true,
                  },
                });
                if (updateSearch) {
                  dispatch({
                    type: 'SEARCH_COUNT_INCREMENT_ON_COIN',
                    payload: isSingleCoinSearch,
                  });
                }
              } else {
                dispatch({
                  type: 'VIEW_TMTICKER_DETAILS_UPDATE',
                  payload: {
                    TM: result,
                  },
                });
              }
              window.Mixpanel.MixpanelProps.UserName = APICONFIG.apiLoggedInUser();
              window.Mixpanel.actions.identify(APICONFIG.apiLoggedInUser());
              window.Mixpanel.actions
                .track('Coin Column Events loaded successfully', window.Mixpanel.MixpanelProps)
                .then((data) => {
                  window.Mixpanel.actions.people.set({
                    UserName: APICONFIG.apiLoggedInUser(),
                  });
                  window.Mixpanel.actions.resetProp('Error');
                });
            // } else {
            //   throw new Error('result.SecurityName is null');
            // }
          })
          .catch((err) => {
            console.log('error in TMViewDetails', err);
            // logactions.logger({Message: 'error in TMViewDetails'+ err})
            // throw new Error('error in  TMViewDetails' + err);
            // console.log('ErroroCoinDetails', err);
            window.Mixpanel.MixpanelProps.UserName = APICONFIG.apiLoggedInUser();
            window.Mixpanel.actions.identify(APICONFIG.apiLoggedInUser());
            window.Mixpanel.actions
              .track('Coin Column Events loading failed', window.Mixpanel.MixpanelProps)
              .then((data) => {
                window.Mixpanel.actions.people.set({
                  UserName: APICONFIG.apiLoggedInUser(),
                });
                window.Mixpanel.actions.resetProp('Error');
              });
            let data = {
              username: APICONFIG.apiLoggedInUser(),
              action: 'Coin Data',
              url: `${APICONFIG.apiBaseUrl}/api/summeraization/getcoindata?securityName=${TM.SecurityName}&securityTicker=${TM.SecurityTicker}`,
              severity: 'low',
            };
            dispatch({
              type: 'REQUEST_ERROR',
              payload: ErrorHandleHelper.generateErrorString(err, 'TMViewDetails'),
              data: data,
            });
          });
        } else {
          dispatch({
            type: 'SWITCH_COLUMN_FOCUS',
            payload: existingColumnName,
          });
          if (updateSearch) {
            dispatch({
              type: 'SEARCH_COUNT_INCREMENT_ON_COIN',
              payload: isSingleCoinSearch,
            });
          }
        }
        // console.log(TM.SecurityName, columnlist, Tickerdata.SecurityName, coincolumnsecuritynameArr)
        
      } else {
        // throw new Error('result.SecurityName is null');
      }
    } catch (err) {
      console.log('error in TMViewDetails', err);
      // logactions.logger({Message: 'error in TMViewDetails'+ err})
      // throw new Error('error in  TMViewDetails' + err);
      // console.log('ErroroCoinDetails', err);
      let data = {
        username: APICONFIG.apiLoggedInUser(),
        action: 'Coin Data',
        url: `${APICONFIG.apiBaseUrl}/api/summeraization/getcoindata?securityName=${TM.SecurityName}&securityTicker=${TM.SecurityTicker}`,
        severity: 'low',
      };
      dispatch({
        type: 'REQUEST_ERROR',
        payload: ErrorHandleHelper.generateErrorString(err, 'TMViewDetails'),
        data: data,
      });
    }
  };
}



export function getEventDetails(eventId, userName) {
  //  /api/alerts/getalertbyeventid?eventId=${data.EventId}&userName=${username}

  return (dispatch) => {
    try {
      fetch(`${APICONFIG.apiBaseUrl}/api/alerts/getalertbyeventid?eventId=${eventId}&userName=${userName}`, {
        method: 'GET',
        headers: APICONFIG.apiHeaders(),
      })
        .then((response) => response.json())
        .then((result) => {
          console.log(result)
          dispatch({
            type: 'EVENT_DETAILS',
            payload: {
              Event: result,
            },
          });
        })
        .catch((err) => {
          console.log('error in getEventDetails', err);
          // logactions.logger({Message: 'error in getEventDetails'+ err})
          // throw new Error('error in  getEventDetails' + err);
          // console.log('ErroroCoinDetails', err);
          let data = {
            username: APICONFIG.apiLoggedInUser(),
            action: 'Coin Data',
            url: `${APICONFIG.apiBaseUrl}/api/alerts/getalertbyeventid?eventId=${eventId}&userName=${userName}`,
            severity: 'low',
          };
          dispatch({
            type: 'REQUEST_ERROR',
            payload: ErrorHandleHelper.generateErrorString(err, 'GETEventDetails'),
            data: data,
          });
        });
    } catch (err) {
      // logactions.addLogAction(err)
    }
  };
}

export function setTickerDetailsFeedReqDataCoinColumn(feedreqdata, index, SecurityName, sourceFilter) {
  return (dispatch) => {
    try {
      dispatch({
        type: 'SET_TICKERDETAILS_FEEDREQDATA_COINCOLUMN',
        payload: feedreqdata,
        index,
        SecurityName,
        sourceFilter
      });
    } catch (err) {
      // logactions.logger({Message: 'error in setTickerDetailsFeedReqData'+ err})
      // logactions.addLogAction('error in setTickerDetailsFeedReqData:' +err)
    }
  };
}
export function setTickerDetailsFeedReqData(feedreqdata) {
  return (dispatch) => {
    try {
      dispatch({
        type: 'SET_TICKERDETAILS_FEEDREQDATA',
        payload: feedreqdata,
      });
    } catch (err) {
      // logactions.logger({Message: 'error in setTickerDetailsFeedReqData'+ err})
      // logactions.addLogAction('error in setTickerDetailsFeedReqData:' +err)
    }
  };
}

export function searchCoinDetailTickerEvents(searchData, index, SecurityName, update = false) {
  return (dispatch) => {
    // console.log(searchData, index, SecurityName, update)
    // if(typeof searchData.sec === 'undefined') {
    //   searchData.sec = [`${SecurityName}`]
    // }
    try {
      fetch(
        `${
          APICONFIG.apiBaseUrl
        }/api/alerts/getalertsbysearch?all=${false}&userName=${APICONFIG.apiLoggedInUser()}&eventType=${''}&securityTypes=${''}&securities=${
          searchData.sec
        }&startDate=${searchData.startDate}&endDate=${searchData.endDate}&freeText=${''}${
          update ? '&update=true' : '&update=false'
        }${'&withCurated=' + searchData.withCurated}${'&withBlack=' + searchData.withBlack}${'&withWhite=' + searchData.withWhite}${
          '&withYellow=' + searchData.withYellow
        }${'&withOrange=' + searchData.withOrange}${'&withRed=' + searchData.withRed}${
          '&userPackage=' + APICONFIG.apiLoggedInUserPackage()
        }`,
        {
          method: 'GET',
          headers: APICONFIG.apiHeaders(),
        }
      )
        .then((response) => response.json())
        .then((result) => {
          // console.log(SecurityName, result)
          // console.log('search filters')
          // console.log('WIth Black', searchData.withBlack)
          // console.log('WIth Gray', searchData.withCurated)
          // console.log('WIth White', searchData.withWhite)
          // console.log('WIth Yellow', searchData.withYellow)
          // console.log('WIth orange', searchData.withOrange)
          // console.log('WIth red', searchData.withRed)
          // if(update) {
          //   return;
          // }
          let TickerFeedsList = {};
          let TickerFeedsIndexedRef = [];
          if (result.length) {
            // console.log(searchData.sourceFilters)
            let SourceFilters = typeof searchData.sourceFilters === undefined || !searchData.sourceFilters ? '' : searchData.sourceFilters
            let filteredResult = []
            if(SourceFilters !== '') {
              filteredResult = NotifyFeedsHandler.FilterEventsBySourceInLocation(result, SourceFilters, '**');
            } else {
              filteredResult = result;
            }
            // console.log(filteredResult)
            let downgradefilteredresult = NotifyFeedsHandler.FormatandDowngradeEvents(filteredResult);
            if(downgradefilteredresult.length) {
              downgradefilteredresult.forEach(rs => {
                if(rs.FeedId === 49313295) {
                  console.log(rs)
                }
              })
            }
            let filtObj = {
              withBlack: searchData.withBlack,
              withGray: searchData.withCurated,
              withWhite: searchData.withWhite,
              withYellow: searchData.withYellow,
              withOrange: searchData.withOrange,
              withRed: searchData.withRed
            }
            let filteredoutdowngradedevents = NotifyFeedsHandler.FilterMagnitudeSearch(downgradefilteredresult, filtObj);
            if(filteredoutdowngradedevents.length) {
              filteredoutdowngradedevents.forEach(rs => {
                if(rs.FeedId === 49313295) {
                  console.log(rs)
                }
              })
            }
            let algolocationfilteredresult = NotifyFeedsHandler.FormatByAlgolocationFilter(filteredoutdowngradedevents)
            
            let searchedassetfilteredresult = NotifyFeedsHandler.FormatBySearchedAssetfilter(algolocationfilteredresult, searchData.sec.join(';'))
            searchedassetfilteredresult.map((rs) => {
              if (rs) {
                if(NotifyFeedsHandler.EventTypeFilterPass(rs)) {
                  // console.log('here')
                  TickerFeedsList[`${rs.FeedId}`] = NotifyFeedsHandler.EventTimeToLocal(rs);
                
                  TickerFeedsIndexedRef.push({
                    FeedId: rs.FeedId,
                    SortByDate: rs.SortByDate,
                    notifyEvent: rs.NotifyEvent,
                    magnitudeRate: rs.MagnitudeRate,
                  });
                }
                
              }
              return null;
            });
          }
          if (!update) {
            dispatch({
              type: 'SET_TICKER_EVENTS_COINCOLUMN',
              payload: {
                TickerFeedsList,
                TickerFeedsIndexedRef,
                searchData,
                index,
                SecurityName
              },
            });
          } else {
            dispatch({
              type: 'UPDATE_TICKER_EVENTS_COINCOLUMN',
              payload: {
                TickerFeedsList,
                TickerFeedsIndexedRef,
                searchData,
                index,
                SecurityName
              },
            });
          }
        })
        .catch((err) => {
          console.log('error in searchTickerEvents', err);
          // logactions.logger({Message: 'error in searchTickerEvents'+ err})
          let data = {
            username: APICONFIG.apiLoggedInUser(),
            action: 'searchTickerEvents',
            url: `${
              APICONFIG.apiBaseUrl
            }/api/alerts/getalertsbysearch?all=${false}&userName=${APICONFIG.apiLoggedInUser()}&eventType=${''}&securityTypes=${''}&securities=${
              searchData.sec
            }&startDate=${searchData.startDate}&endDate=${searchData.endDate}&freeText=${''}${
              update ? '&update=true' : '&update=false'
            }${'&withCurated=' + searchData.withCurated}${'&withBlack=' + searchData.withBlack}${
              '&withYellow=' + searchData.withYellow
            }${'&withOrange=' + searchData.withOrange}${'&withRed=' + searchData.withRed}${
              '&userPackage=' + APICONFIG.apiLoggedInUserPackage()
            }`,
            severity: 'low',
          };
          dispatch({
            type: 'REQUEST_ERROR',
            payload: ErrorHandleHelper.generateErrorString(err, 'searchTickerEvents'),
            data: data,
          });
        });
    } catch (err) {
      // logactions.logger({Message: 'error in searchTickerEvents'+ err})
      // logactions.addLogAction('error in searchTickerEvents :' +err)
    }
  };
}

export function searchTickerEvents(searchData, update = false) {
  return (dispatch) => {
    console.log(searchData)
    try {
      fetch(
        `${
          APICONFIG.apiBaseUrl
        }/api/alerts/getalertsbysearch?all=${false}&userName=${APICONFIG.apiLoggedInUser()}&eventType=${''}&securityTypes=${''}&securities=${
          searchData.sec.join(';')
        }&startDate=${searchData.startDate}&endDate=${searchData.endDate}&freeText=${''}${
          update ? '&update=true' : '&update=false'
        }${'&withCurated=' + searchData.withCurated}${'&withBlack=' + searchData.withBlack}${
          '&withYellow=' + searchData.withYellow
        }${'&withOrange=' + searchData.withOrange}${'&withRed=' + searchData.withRed}${
          '&userPackage=' + APICONFIG.apiLoggedInUserPackage()
        }`,
        {
          method: 'GET',
          headers: APICONFIG.apiHeaders(),
        }
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result)
          let TickerFeedsList = {};
          let TickerFeedsIndexedRef = [];
          if (result.length) {
            // console.log('from cc')
            let downgradefilteredresult = NotifyFeedsHandler.FormatandDowngradeEvents(result);
            let filtObj = {
              withBlack: searchData.withBlack,
              withGray: searchData.withCurated,
              withWhite: typeof searchData.withWhite !== 'undefined' ? searchData.withWhite : false,
              withYellow: searchData.withYellow,
              withOrange: searchData.withOrange,
              withRed: searchData.withRed
            }
            let filteredoutdowngradedevents = NotifyFeedsHandler.FilterMagnitudeSearch(downgradefilteredresult, filtObj);
            let algolocationfilteredresult = NotifyFeedsHandler.FormatByAlgolocationFilter(filteredoutdowngradedevents)
            let searchedassetfilteredresult = NotifyFeedsHandler.FormatBySearchedAssetfilter(algolocationfilteredresult, searchData.sec.join(';'))
            searchedassetfilteredresult.map((rs) => {
              if (rs) {
                if(NotifyFeedsHandler.EventTypeFilterPass(rs)) {
                  TickerFeedsList[`${rs.FeedId}`] = NotifyFeedsHandler.EventTimeToLocal(rs);
                  // searchFeedsList[`${rs.FeedId}`].EventReadStatus = true;
                  TickerFeedsIndexedRef.push({
                    FeedId: rs.FeedId,
                    SortByDate: rs.SortByDate,
                    notifyEvent: rs.NotifyEvent,
                    magnitudeRate: rs.MagnitudeRate,
                  });
                }
              }
              return null;
            });
          }
          if (!update) {
            dispatch({
              type: 'SET_TICKER_EVENTS',
              payload: {
                TickerFeedsList,
                TickerFeedsIndexedRef,
                searchData
              },
            });
          } else {
            dispatch({
              type: 'UPDATE_TICKER_EVENTS',
              payload: {
                TickerFeedsList,
                TickerFeedsIndexedRef,
                searchData
              },
            });
          }
        })
        .catch((err) => {
          console.log('error in searchTickerEvents', err);
          // logactions.logger({Message: 'error in searchTickerEvents'+ err})
          let data = {
            username: APICONFIG.apiLoggedInUser(),
            action: 'searchTickerEvents',
            url: `${
              APICONFIG.apiBaseUrl
            }/api/alerts/getalertsbysearch?all=${false}&userName=${APICONFIG.apiLoggedInUser()}&eventType=${''}&securityTypes=${''}&securities=${
              searchData.sec
            }&startDate=${searchData.startDate}&endDate=${searchData.endDate}&freeText=${''}${
              update ? '&update=true' : '&update=false'
            }${'&withCurated=' + searchData.withCurated}${'&withBlack=' + searchData.withBlack}${
              '&withYellow=' + searchData.withYellow
            }${'&withOrange=' + searchData.withOrange}${'&withRed=' + searchData.withRed}${
              '&userPackage=' + APICONFIG.apiLoggedInUserPackage()
            }`,
            severity: 'low',
          };
          dispatch({
            type: 'REQUEST_ERROR',
            payload: ErrorHandleHelper.generateErrorString(err, 'searchTickerEvents'),
            data: data,
          });
        });
    } catch (err) {
      // logactions.logger({Message: 'error in searchTickerEvents'+ err})
      // logactions.addLogAction('error in searchTickerEvents :' +err)
    }
  };
}

export function resetTickerEvents() {
  return (dispatch) => {
    try {
      dispatch({
        type: 'RESET_TICKER_EVENTS',
      });
    } catch (err) {
      // logactions.logger({Message: 'error in resetTickerEvents'+ err})
      // logactions.addLogAction('error in resetTickerEvents :' +err)
    }
  };
}

export function CloseTMDetails() {
  return (dispatch) => {
    try {
      dispatch({
        type: 'CLOSE_TM_DETAILS',
      });
    } catch (err) {
      // logactions.logger({Message: 'error in CloseTMDetails'+ err})
      // logactions.addLogAction('error in CloseTMDetails :' +err)
    }
  };
}
export function CloseCoinDetailsColumn(columnIndex, SecurityName) {
  return (dispatch) => {
    try {
      dispatch({
        type: 'CLOSE_COIN_DETAILS_COLUMN',
        payload: columnIndex,
        SecurityName
      });
    } catch (err) {
      // logactions.logger({Message: 'error in CloseTMDetails'+ err})
      // logactions.addLogAction('error in CloseTMDetails :' +err)
    }
  };
}
export function getCoinDetailColumnChartFeedFromTicker(security, dataType, columnIndex, SecurityName, SourceFilter) {
  // console.log(security, dataType, columnIndex)
  
  return (dispatch) => {
      // console.log(dataType)
      // return
      if(security !== null) {
        return fetch(`${APICONFIG.apiBaseUrl}/api/securities/getgraphdata?securityName=${security.toLowerCase()}&dataType=${dataType}&timeframe=${`1D`}`,{
          method: 'GET',
          headers: APICONFIG.apiHeaders()
        })
        .then(async (response) => {
            if(response.status === 200 && response.ok) {
                await response.json().then((result) => {
                    // console.log('chart feeds',result)
                    let lastDayFilteredResults = [];
                    let NullFilledDataSet = [];
                    let FormattedResult = [];
                    if(result.length) {
                      if(APICONFIG.apiLoggedInUserType() === 'None' || APICONFIG.apiLoggedInUserType() === 'WUser' || APICONFIG.apiLoggedInUserType() === 'WAdmin') {
                        if(TrendingMentionsHelper.FreeSubscriptionFilter()) {
                            lastDayFilteredResults = NotifyFeedsHandler.filterInLastDayData(result)
                            FormattedResult = NotifyFeedsHandler.FormatMissingGraphData(lastDayFilteredResults);
                        } else {
                            FormattedResult = NotifyFeedsHandler.FormatMissingGraphData(result);
                        }
                      } else {
                        FormattedResult = NotifyFeedsHandler.FormatMissingGraphData(result);
                      }
                        
                        // console.log(NotifyFeedsHandler.FormatMissingGraphData(result))
                        
                        // let newResultArray = [];
                        // result.forEach(rs => {
                        //     newResultArray.push(NotifyFeedsHandler.GraphTimeToLocal(rs))
                        // })
                        // console.log('LINE 11',newResultArray)
                        // dispatch({
                        //     type: 'TICKERFEEDS',
                        //     payload: result
                        //     // payload: newResultArray
                        // })
                    }
                    dispatch({
                        type: 'COINDETAILCOLUMN_CHART_TICKERFEEDS',
                        // payload: lastDayFilteredResults
                        // payload: result
                        payload: FormattedResult,
                        columnIndex,
                        SecurityName,
                        SourceFilter
                        // payload: newResultArray
                    })
                    
                })
            
            } else {
            await response.json().then((result) => {
                console.log('LINE 17',result)
                throw new Error(`${response.status} : ${response.statusText} - ${result.Message}`)
            })
            }
        })
        .catch((err) => {
            console.log(err)
            let data = {
                username : APICONFIG.apiLoggedInUser(),
                action: 'Get Coin Detail Column Chart Feeds',
                url : `${APICONFIG.apiBaseUrl}/api/securities/getgraphdata?securityName=${security.toLowerCase()}&dataType=${dataType}&timeframe=${`1D`}`,
                severity: 'low'
            }
            dispatch({
                type: 'REQUEST_ERROR',
                payload: ErrorHandleHelper.generateErrorString(err, 'GetChartFeeds'),
                data: data
            })
        })
      }
      
  }
}

export function reSearchTickerEvents(state) {
  return (dispatch) => {
    try {
      dispatch({
        type: 'RE_SEARCH_TICKER_EVENTS',
        payload: state,
      });
    } catch (err) {
      // logactions.logger({Message: 'error in reSearchTickerEvents'+ err})
      // logactions.addLogAction('error in reSearchTickerEvents :' +err)
    }
  };
}

export function RequestExchangesAndRates(status) {
  // console.log('here', status)
  return (dispatch) => {
    try {
      dispatch({
        type: 'EXCH_RATE_REQ',
        payload: status,
      });
    } catch (err) {
      // logactions.logger({Message: 'error in RequestExchangesAndRates'+ err})
      // logactions.addLogAction('error in RequestExchangesAndRates :' +err)
    }
  };
}
export function getExchangesAndRates(data) {
  // console.log(data)
  return (dispatch) => {
    try {
      fetch(
        `${APICONFIG.apiBaseUrl}/api/securities/getpricesfromexchanges?coinTicker=${
          data.SecurityTicker
        }&userName=${APICONFIG.apiLoggedInUser()}`,
        {
          method: 'GET',
          headers: APICONFIG.apiHeaders(),
        }
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          let sortedNewResult = result.sort((a, b) => {
            return parseFloat(a.Price) - parseFloat(b.Price);
          });
          dispatch({
            type: 'SET_EXCH_RATE',
            payload: sortedNewResult,
          });
          dispatch({
            type: 'EXCH_RATE_REQ',
            payload: false,
          });
        })
        .catch((err) => {
          console.log('error in getExchangesAndRates', err);
          // logactions.logger({Message: 'error in getExchangesAndRates'+ err})
          let data = {
            username: APICONFIG.apiLoggedInUser(),
            action: 'searchTickerEvents',
            url: `${APICONFIG.apiBaseUrl}/api/securities/getpricesfromexchanges?coinTicker=${
              data.SecurityTicker
            }&userName=${APICONFIG.apiLoggedInUser()}`,
            severity: 'low',
          };
          dispatch({
            type: 'REQUEST_ERROR',
            payload: ErrorHandleHelper.generateErrorString(err, 'getExchangesAndRates'),
            data: data,
          });
        });
    } catch (err) {
      // logactions.addLogAction('error in getExchangesAndRates :' +err)
    }
  };
}

export function changeTopTrendingCoinLimit(limit = 10, maxLimit = 50, watchlist = false, cc_preferences = false, cc_index = false) {
  // console.log(cc_preferences)
  return (dispatch) => {
    // dispatch({
    //   type: 'CHANGE_TM_LIMIT',
    //   payload: { limit, maxLimit, watchlist },
    // });
    // let packageName = Store.getState().trendingMentions.packageName;
    let packageName = cc_preferences.packageName;
    let SuscriptionStatus = Store.getState().settings.subscriptionStatus;
    // let tmFromWatchlist = Store.getState().trendingMentions.tmFromWatchlist;
    let tmFromWatchlist = cc_preferences.tmFromWatchlist === 'true' || cc_preferences.tmFromWatchlist;
    let timeFrame = getActiveTimeFrame();
    // let DefaultTickerData = null;
    let TMDetails = {};
    // let coindetail = window.localStorage.getItem('SavedCoinDetail');
    // callTrendingPackages(timeFrame, packageName, maxLimit)
    callTrendingPackages(timeFrame, packageName, parseInt(cc_preferences.tmtotalmaxcoincount))
      .then(async (response) => {
        // let SortingColumn = Store.getState().trendingMentions.tmSortedColumn;
        let SortingColumn = cc_preferences.tmSortedColumn;
        // let SortingType = Store.getState().trendingMentions.tmSortingType;
        let SortingType = parseInt(cc_preferences.tmSortingType);
        if (response.status === 200 && response.ok) {
          await response.json().then(async (result) => {
            // console.log(result);
            // console.log(SortingColumn, SortingType);
            // if(SortingType === -1) {
            //   result = result.reverse();
            // }
            // let modifiedResult = result; let modifiedDump = result;
            let modData = TrendingMentionsHelper.FormatCoinlist(result);
            if(tmFromWatchlist) {
              modData = TrendingMentionsHelper.HandleForWatchlist(modData);
            //   modifiedResult = modData.modifiedResult;
            //   modifiedDump = modData.modifiedDump;
            //   // modifiedResult = TrendingMentionsHelper.HandleForWatchlist(result);
            }
            // let FilteredResult = TrendingMentionsHelper.FreeTopTrendingOnlyFilter(modData, cc_preferences);
            let FilteredResult = modData;
            // console.log(FilteredResult);
            // if (coindetail !== null) {
            //   DefaultTickerData = JSON.parse(coindetail);
            //   // console.log(DefaultTickerData)
            //   let DetRes = await callTMDetails(DefaultTickerData);
            //   if (DetRes.status === 200 && DetRes.ok) {
            //     TMDetails = await DetRes.json();
            //     // console.log(TMDetails)
            //   }
            // }
            // console.log(TMDetails)
            // dispatch({
            //   type: 'RESET_TMSORT',
            // });
            dispatch({
              type: 'TM_SET_ONLY',
              payload: FilteredResult,
              coinsDump: result,
              SortingColumn,
              SortingType,
              packageName,
              index: cc_index
              // DefaultTickerData: TMDetails,
            });
            window.Mixpanel.MixpanelProps.UserName = APICONFIG.apiLoggedInUser();
            window.Mixpanel.actions.identify(APICONFIG.apiLoggedInUser());
            window.Mixpanel.actions
              .track('Top Opportunities call completed successfully', window.Mixpanel.MixpanelProps)
              .then((data) => {
                window.Mixpanel.actions.people.set({
                  UserName: APICONFIG.apiLoggedInUser(),
                });
              });
          });
        } else {
          await response.json().then((result) => {
            //   console.log(result)
            throw new Error(`${response.status} : ${response.statusText} - ${result.Message}`);
          });
        }
      })
      .catch((err) => {
        console.log('error in getTrendingMentions', err);
        // logactions.logger({Message: 'error in getTrendingMentions'+ err})
        // throw new Error('error in  getTrendingMentions' + err);
        // console.log(err);
        window.Mixpanel.MixpanelProps.UserName = APICONFIG.apiLoggedInUser();
        window.Mixpanel.MixpanelProps.Error = err;
        window.Mixpanel.actions.identify(APICONFIG.apiLoggedInUser());
        window.Mixpanel.actions.track('Top Opportunities call Failed', window.Mixpanel.MixpanelProps).then((data) => {
          window.Mixpanel.actions.people.set({
            UserName: APICONFIG.apiLoggedInUser(),
          });
          window.Mixpanel.actions.resetProp('Error');
        });
        let data = {
          username: APICONFIG.apiLoggedInUser(),
          action: 'Get Trending Mentions',
          url: `${
            APICONFIG.apiBaseUrl
          }/api/summeraization/getpackage?timeFrame=${timeFrame}&packageName=${packageName}&userPackage=${APICONFIG.apiLoggedInUserPackage()}`,
          severity: 'high',
        };
        dispatch({
          type: 'REQUEST_ERROR',
          payload: ErrorHandleHelper.generateErrorString(err, 'getTrendingMentions'),
          data: data,
        });
      });
  };
}

export function updatepinnedcoincolumn() {
  return (dispatch) => {
    try {
      dispatch({
        type: 'UPDATE_PINNED_COINCOLUMN',
      });
    } catch (err) {
      // logactions.logger({Message: 'error in reSearchTickerEvents'+ err})
      // logactions.addLogAction('error in reSearchTickerEvents :' +err)
    }
  }
}
export function updatepinnedcoindetailcolumn() {
  return (dispatch) => {
    try {
      dispatch({
        type: 'UPDATE_PINNED_COINDETAILCOLUMN',
      });
    } catch (err) {
      // logactions.logger({Message: 'error in reSearchTickerEvents'+ err})
      // logactions.addLogAction('error in reSearchTickerEvents :' +err)
    }
  }
}


