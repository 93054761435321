import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as appSettingsActionCreators from '../actions/settings.action';
import { APPPATH } from '../modules/helper.module';
import * as moment from 'moment';
import { SpanishTranslations, PortugueseTranslations, JapaneseTranslations, ArabicTranslations } from '../static_data/translations';


class Changepassword extends Component {
  constructor() {
    super();
    this.state = {
      oldpass: '',
      newpass: '',
      confpass: '',
      oldpassview: false,
      oldpassError: false,
      newpassview: false,
      newpassError: false,
      confpassview: false,
      confpassError: false,
      status: '',
      customMessage: false,
      onSubmitClickError: false
    }
    this.flashTimeout = '';
  }

  componentWillMount() {
    this.setState({
      ...this.state,
      oldpass: '',
      newpass: '',
      confpass: '',
      oldpassview: false,
      oldpassError: false,
      newpassview: false,
      newpassError: false,
      confpassview: false,
      confpassError: false,
      status: this.props.changePassword.status,
      customMessage: this.props.changePassword.customMessage
    })
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      ...this.state,
      oldpass: '',
      newpass: '',
      confpass: '',
      oldpassview: false,
      oldpassError: false,
      newpassview: false,
      newpassError: false,
      confpassview: false,
      confpassError: false,
      status: nextProps.changePassword.status,
      customMessage: nextProps.changePassword.customMessage,
      onSubmitClickError: false
    }, () => {
      if (nextProps.changePassword.tried) {
        if (nextProps.changePassword.successFlag) {
          if (typeof nextProps.initialReset !== 'undefined' && nextProps.initialReset) {
            // nextProps.finishChangePass()
            nextProps.finishInitialChangePass()
          } else {
            // this.flashTimeout = setTimeout(function() {
            //   nextProps.finishChangePass()
            // },15000)
          }
        } else {
          // this.flashTimeout = setTimeout(function() {
          //   nextProps.finishChangePass()

          // },15000)
        }
      }

    })
  }

  componentWillUnmount() {
    clearTimeout(this.flashTimeout);
  }

  componentDidMount() {
    window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
    window.Mixpanel.actions.identify(this.props.userData.username);
    window.Mixpanel.actions.track(`Change password page`, window.Mixpanel.MixpanelProps).then(data => {
      window.Mixpanel.actions.people.set({
        UserName: this.props.userData.username
      });

    })
  }

  validate = (value, stateName) => {
    let resData = {}
    if (value !== '') {
      if (stateName === 'newpass') {
        if (value.length >= 6) {
          resData.status = true;
          resData.message = '';
        } else {
          resData.status = false;
          resData.message = 'Password must have 6 or more characters.';
        }

      } else if (stateName === 'oldpass') {
        if (value !== '') {
          resData.status = true;
          resData.message = '';
        } else {
          resData.status = false;
          resData.message = 'This is a required field.';
        }

      } else if (stateName === 'confpass') {
        if (value === this.state.newpass) {
          resData.status = true;
          resData.message = '';
        } else {
          resData.status = false;
          resData.message = 'Does not match with new password';
        }

      }
    } else {
      resData.status = false;
      resData.message = 'This is a required field.';
    }

    return resData;

  }

  handleChange = (e) => {
    let changedInput = e.target.id;
    let resData = this.validate(e.target.value, changedInput);
    if (resData.status) {
      this.setState({
        ...this.state,
        [`${changedInput}`]: e.target.value,
        [`${changedInput}Error`]: false
      })
    } else {
      this.setState({
        ...this.state,
        [`${changedInput}`]: e.target.value,
        [`${changedInput}Error`]: resData.message
      })
    }

  }

  handleBlur = (e) => {
    let changedInput = e.target.id;
    let resData = this.validate(e.target.value, changedInput);
    if (!resData.status) {
      this.setState({
        ...this.state,
        [`${changedInput}Error`]: resData.message
      })
    }
  }

  toggleviewers = (e) => {
    let tosee = e.currentTarget.dataset.view;
    this.setState({
      ...this.state,
      [`${tosee}view`]: !this.state[`${tosee}view`]
    })
  }

  submitChange = () => {
    // let toValidate = {
    //   oldpass: this.state.oldpass,
    //   newpass: this.state.newpass,
    //   confpass: this.state.confpass
    // }
    // let resData = this.validate(e.target.value, changedInput);
    // console.log(this.state)
    if (!this.state.oldpassError && !this.state.newpassError && (this.state.newpass === this.state.confpass)) {

      let data = {
        OldPassword: this.state.oldpass,
        NewPassword: this.state.newpass,
        ConfirmPassword: this.state.confpass
      }
      this.setState({
        ...this.state,
        oldpass: '',
        newpass: '',
        confpass: '',
        oldpassview: false,
        oldpassError: false,
        newpassview: false,
        newpassError: false,
        confpassview: false,
        confpassError: false,
      }, () => {
        this.props.changePass(data);
        window.Mixpanel.MixpanelProps.UserName = this.props.username;
        window.Mixpanel.MixpanelProps.SubmittedAt = moment().format('DD/MM/YYYY HH:mm:s A');
        window.Mixpanel.actions.identify(this.props.username);

        window.Mixpanel.actions.track(`Password Change Submit`, window.Mixpanel.MixpanelProps).then(data => {
          window.Mixpanel.actions.people.set({
            UserName: this.props.username
          });
        })


      })
    } else {
      this.setState({
        ...this.state,
        onSubmitClickError: true
      })
    }
  }

  cancelChange = () => {

    this.props.finishChangePass()
    if (typeof this.props.initialReset !== 'undefined' && this.props.initialReset) {
      this.props.finishInitialChangePass()
    } else {
      window.location.href = APPPATH.AppUrl;
    }
  }

  getErrorIfHasError = (incomingKey) => {

    let customErrorMessage;
    if (this.state.customMessage === 'PASSMUSTHAVESERROR' && incomingKey === 'PASSMUSTHAVESERROR') {
      let keys = [
        'non letter or digit character',
        'at least one digit',
        'at least one uppercase',
        'at least one lowercase',
      ]
      customErrorMessage = keys.map(key => {
        if (this.state.status.toLowerCase().includes(key)) {
          switch (key) {
            case 'non letter or digit character':
              return <label key={key} className={`hasError dispb fs12 marg-0`}>Passwords must have at least one special character</label>
            case 'at least one digit':
              return <label key={key} className={`hasError dispb fs12 marg-0`}>Passwords must have at least one digit ('0'-'9')</label>
            case 'at least one uppercase':
              return <label key={key} className={`hasError dispb fs12 marg-0`}>Passwords must have at least one uppercase ('A'-'Z')</label>
            case 'at least one lowercase':
              return <label key={key} className={`hasError dispb fs12 marg-0`}>Passwords must have at least one lowercase ('a'-'z')</label>
          }
        }
      })
      return customErrorMessage;
    } else if (this.state.customMessage === 'INCORRECTPASSERROR' && incomingKey === 'INCORRECTPASSERROR') {
      return <label className={`hasError dispb fs12 marg-0`}>Your old password is incorrect</label>
    }
  }

  render() {
    // console.log(this.state.status, this.state.customMessage)
    // let customErrorMessage = this.getErrorIfHasError()

    return (
      <Row className=" marg-0 changepassword-wrap ">
        <Col lg={12} sm={12} xs={12} md={12} xl={12}>

          <Row className="justify-content-center">
            <Col lg={11} sm={11} xs={11} md={11} xl={11} className={`nopad passchangehead justify-content-left ${this.props.lang && this.props.lang === 'ara' ? 'text-right' : 'text-left'}`}>
              <i className="fa fa-lock"></i><span>

                {
                  this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['Change Password']
                    : this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['Change Password']
                      : this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['Change Password']
                        : this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['Change Password']
                          : 'Change Password'
                }
              </span>
            </Col>

          </Row>
          <Row className="justify-content-center  ">
            <Col lg={11} sm={11} xs={11} md={11} xl={11} className="passchangebody justify-content-left text-left">
              <div className="bodypanel">
                <div className="bodypanel-head">

                  {
                    this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['To change your password, please fill in the below your old and new passwords']
                      : this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['To change your password, please fill in the below your old and new passwords']
                        : this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['To change your password, please fill in the below your old and new passwords']
                          : this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['To change your password, please fill in the below your old and new passwords']
                            : 'To change your password, please fill in the below your old and new passwords'
                  }
                </div>
                <div className="bodypanel-body">
                  <div className="cp-form">
                    <Row className="marg-0 cp-form-section">
                      <Col lg={12} sm={12} xs={12} md={12} xl={12} className={`${this.props.lang && this.props.lang === 'ara' ? 'text-right' : 'text-left'}`}>
                        <label>

                          {
                            this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['Old Password']
                              : this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['Old Password']
                                : this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['Old Password']
                                  : this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['Old Password']
                                    : 'Old Password'
                          }*
                        </label>
                        <input type={`${this.state.oldpassview ? 'text' : 'password'}`} name="oldpass" id="oldpass" placeholder={

                          this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['Old Password']
                            : this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['Old Password']
                              : this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['Old Password']
                                : this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['Old Password']
                                  : 'Old Password'

                        } onChange={this.handleChange} onBlur={this.handleBlur} value={this.state.oldpass} />
                        <i data-view="oldpass" className={`fa fa-eye passviewer ${this.props.lang && this.props.lang === 'ara' ? 'arabic' : ''} ${this.state.oldpassview ? 'active' : ''}`} onClick={this.toggleviewers}></i>
                        <label className={`hasError dispb fs12 marg-0 ${this.state.oldpassError && this.state.onSubmitClickError ? '' : 'nodisp'}`}>{this.state.oldpassError !== false ? this.state.oldpassError : ''}</label>
                        {this.getErrorIfHasError('INCORRECTPASSERROR')}
                        {/* <label className={`hasError ${this.state.oldpassError && this.state.onSubmitClickError ? '' : 'nodisp'}`}>{this.state.oldpassError !== false ? this.state.oldpassError : ''}</label> */}
                      </Col>
                    </Row>
                    <Row className="marg-0 cp-form-section">
                      <Col lg={12} sm={12} xs={12} md={12} xl={12} className={`${this.props.lang && this.props.lang === 'ara' ? 'text-right' : 'text-left'}`}>
                        <label>
                          {
                            this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['New Password']
                              : this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['New Password']
                                : this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['New Password']
                                  : this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['New Password']
                                    : 'New Password'
                          }*
                        </label>
                        <input type={`${this.state.newpassview ? 'text' : 'password'}`} name="newpass" id="newpass" placeholder={

                          this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['New Password']
                            : this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['New Password']
                              : this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['New Password']
                                : this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['New Password']
                                  : 'New Password'

                        } onChange={this.handleChange} onBlur={this.handleBlur} value={this.state.newpass} />
                        <i data-view="newpass" className={`fa fa-eye passviewer ${this.props.lang && this.props.lang === 'ara' ? 'arabic' : ''} ${this.state.newpassview ? 'active' : ''}`} onClick={this.toggleviewers}></i>
                        <label className={`textinput-helpertext`}>

                          {
                            this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['Use 8 to 20 characters with a numeric digit and at least one lowercase letter']
                              : this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['Use 8 to 20 characters with a numeric digit and at least one lowercase letter']
                                : this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['Use 8 to 20 characters with a numeric digit and at least one lowercase letter']
                                  : this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['Use 8 to 20 characters with a numeric digit and at least one lowercase letter']
                                    : 'Use 8 to 20 characters with a numeric digit and at least one lowercase letter'
                          }
                        </label>
                        <label className={`hasError dispb fs12 marg-0 ${this.state.newpassError && this.state.onSubmitClickError ? '' : 'nodisp'}`}>{this.state.newpassError !== false ? this.state.newpassError : ''}</label>
                        {this.getErrorIfHasError('PASSMUSTHAVESERROR')}

                      </Col>
                    </Row>
                    <Row className="marg-0 cp-form-section">
                      <Col lg={12} sm={12} xs={12} md={12} xl={12} className={`${this.props.lang && this.props.lang === 'ara' ? 'text-right' : 'text-left'}`}>
                        <label>
                          {
                            this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['Confirm New Password']
                              : this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['Confirm New Password']
                                : this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['Confirm New Password']
                                  : this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['Confirm New Password']
                                    : 'Confirm New Password'
                          }*
                        </label>
                        <input type={`${this.state.confpassview ? 'text' : 'password'}`} name="confpass" id="confpass" placeholder={

                          this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['Confirm New Password']
                            : this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['Confirm New Password']
                              : this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['Confirm New Password']
                                : this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['Confirm New Password']
                                  : 'Confirm New Password'

                        } onChange={this.handleChange} onBlur={this.handleBlur} value={this.state.confpass} />
                        <i data-view="confpass" className={`fa fa-eye passviewer ${this.props.lang && this.props.lang === 'ara' ? 'arabic' : ''} ${this.state.confpassview ? 'active' : ''}`} onClick={this.toggleviewers}></i>
                        <label className={`hasError dispb fs12 marg-0 ${this.state.confpassError && this.state.onSubmitClickError ? '' : 'nodisp'}`}>{this.state.confpassError !== false ? this.state.confpassError : ''}</label>

                      </Col>
                    </Row>
                    <Row className={`justify-content-center ${!this.state.customMessage && this.state.status !== '' ? '' : 'nodisp'}`}>
                      <Col lg={11} sm={11} xs={11} md={11} xl={11} className={`flash-message changepass justify-content-center text-center`}>
                        {this.state.status}
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center  ">
            <Col lg={11} sm={11} xs={11} md={11} xl={11} className="passchangefoot">
              <div><button type="button" className="btn btn-cp" onClick={this.submitChange}>
                {
                  this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['Confirm']
                    : this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['Confirm']
                      : this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['Confirm']
                        : this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['Confirm']
                          : 'Confirm'
                }
              </button></div>
              <div><button type="button" className="btn btn-cp" onClick={() => {

                window.Mixpanel.MixpanelProps.UserName = this.props.username;
                window.Mixpanel.actions.identify(this.props.username);

                window.Mixpanel.actions.track(`Password Reset Cancelled`, window.Mixpanel.MixpanelProps).then(data => {
                  window.Mixpanel.actions.people.set({
                    UserName: this.props.username
                  });
                  this.cancelChange();
                })
              }}>
                {
                  this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['Cancel']
                    : this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['Cancel']
                      : this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['Cancel']
                        : this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['Cancel']
                          : 'Cancel'
                }
              </button></div>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    changePassword: state.settings.changePassword,
    username: state.settings.userData.username,
    lang: state.settings.lang,
  }
}

const mapDispatchToProps = {
  ...appSettingsActionCreators
}

export default connect(mapStateToProps, mapDispatchToProps)(Changepassword)