import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import * as moment from 'moment';
import Tooltip from '../shared/tooltip/generaltooltip';
import { connect } from 'react-redux';
import * as appSettingsActionCreators from '../../actions/settings.action';
// import { APPPATH } from '../../modules/helper.module';
import { isMobile } from "react-device-detect";

class ReputationIndicator extends Component {
  constructor() {
    super();
    this.state = {
      reputationTip: false,
      reputationToolTip: false,
      userinfoTipHandle :false,
      isClickedInstance: false,
      hoverState: false
    }
    this.ttHandle = '';
    
  }

  componentWillMount() {
    this.setState({
      ...this.state,
      userinfoTipHandle: this.props.userinfoToolTipHandler
    })
  }

  componentWillReceiveProps(nextProps) {
    let restip = false;
    if(this.props.userinfoToolTipHandler !== nextProps.userinfoToolTipHandler) {
      if(!nextProps.userinfoToolTipHandler || (nextProps.userinfoToolTipHandler !== nextProps.alertDetailId)) {
        restip = false
        
        this.setState({
          ...this.state,
          reputationTip: restip,
          userinfoTipHandle :nextProps.userinfoToolTipHandler
        })
        
      }
      
    } else {
      this.setState({
        ...this.state,
        userinfoTipHandle: nextProps.userinfoToolTipHandler
      })
    }
  }

  toggletip = (event) => {
    const { reputation, repData, publisher } = this.props;
    const { userVerified, ReputationData, repDataItems, credibilityText } = this.repIndicatorValues(repData, reputation);
    
    let mnthDate;let year;let dtstr;
    let CreatedDateArr = ReputationData.CreatedDate ? ReputationData.CreatedDate.split(',') : [];
    if(CreatedDateArr.length > 1) {
      mnthDate = CreatedDateArr[1].trim().split(' ');
      year = CreatedDateArr[2] ? CreatedDateArr[2].trim() : '';
      dtstr = `${moment(year,'YYYY').format('YYYY')}-${moment(mnthDate[0],'MMMM').format('MM')}-${moment(mnthDate[1], 'D').format('DD')}T00:00-0000`;
    }
    let FormattedCreatedDate = moment.utc(dtstr).format()
   
    let DateString = moment(FormattedCreatedDate).fromNow(true);
    console.log(DateString)
    let DT = DateString.split(' ');
    DT[0] = `<b ><span class="repDetColor">${ isNaN(parseInt(DT[0])) ? DT[2] === 'seconds'  ? '0' : '1' : DT[0].toUpperCase()}</span></b>`;
    DateString = `${DT[1].charAt(0).toUpperCase()}${DT[1].slice(1)} Running ${DT[0]}`;
    let content = repDataItems.length > 1 ? (
      <Row className={`marg-0 `}>
        {/* { userVerified ? ('(Verified)') : ('')} */}
        <Col xs={12} sm={12} md={12} xl={12} lg={12} className="tipHead" >{ publisher } </Col>
        <Col xs={12} sm={12} md={12} xl={12} lg={12} className="tipItem text-center fs12"><b>{ credibilityText }</b></Col>
        <Col xs={12} sm={12} md={12} xl={12} lg={12} className="tipItem" dangerouslySetInnerHTML={{__html:` &#9672; Posts to date <b ><span class="repDetColor">${ReputationData.Tweets ? ReputationData.Tweets : 0 }</span></b> `}}></Col>
        <Col xs={12} sm={12} md={12} xl={12} lg={12} className="tipItem " dangerouslySetInnerHTML={{__html:` &#9672; Followers <b ><span class="repDetColor">${ReputationData.Followers ? ReputationData.Followers : 0}</span></b>`}}></Col>
        <Col xs={12} sm={12} md={12} xl={12} lg={12} className="tipItem " dangerouslySetInnerHTML={{__html:` &#9672; ${DateString}`}}></Col>
      </Row>
      
    ) : (
      <Row className={`marg-0 `}>
        <Col xs={12} sm={12} md={12} xl={12} lg={12} className="tipHead" >
          { publisher  }
          {/* { userVerified ? ('(Verified)') : ('')} */}
        </Col>
        <Col xs={12} sm={12} md={12} xl={12} lg={12} className="tipItem text-center fs12" ><b>{ credibilityText }</b></Col>
        <Col xs={12} sm={12} md={12} xl={12} lg={12} className="tipItem"></Col>
        <Col xs={12} sm={12} md={12} xl={12} lg={12} className="tipItem "></Col>
      </Row>
      
    )
    let containerClass = 'rep-tooltipMenu';

    let resTip = !this.state.reputationTip;
    const containerWidth = 170;
    let mousePosition = {
      left: event.clientX - containerWidth +20,
      top: event.clientY + 20
    }
    
    if(this.ttHandle) {
      clearTimeout(this.ttHandle)
    }
    // console.log(resTip)
    this.setState({
      ...this.state,
      reputationTip: resTip,
      isClickedInstance: true,
      reputationToolTip: false,
      hoverState: resTip
    },() => {
      this.props.togglePopup(resTip, content, mousePosition, containerClass)
      // if(resTip) {
      //   this.props.tipHandle(resTip, this.props.alertDetailId, 'userinfoToolTipHandler')
      // } 
      window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
      window.Mixpanel.MixpanelProps.FeedId = this.props.alertDetailId;
      window.Mixpanel.MixpanelProps.EventId = this.props.rootId;
      window.Mixpanel.MixpanelProps.Publisher = this.props.Publisher;
      window.Mixpanel.MixpanelProps.Headline = this.props.HeadlineText;
      window.Mixpanel.actions.identify(this.props.userData.username);
      window.Mixpanel.actions.track(`Clicked to see user info`, window.Mixpanel.MixpanelProps).then(data => {
        window.Mixpanel.actions.people.set({
          UserName: this.props.userData.username
        });
        window.Mixpanel.actions.resetProp('EventId');
        window.Mixpanel.actions.resetProp('Publisher');
        window.Mixpanel.actions.resetProp('Headline');
      })
    })
  }
  toggletooltip = (status) => {
    // console.log(status)
    // const { reputationTip } = this.state;
    // if(!reputationTip) {
      if(this.ttHandle) {
        clearTimeout(this.ttHandle)
      }
      // if(!isMobile) {
        this.setState({
          ...this.state,
          hoverState: !isMobile ? status : this.state.hoverState
        },() => {
          if(status) {
            this.ttHandle = setTimeout(() => {
              this.setState({
                ...this.state,
                reputationToolTip: status
              },() => {
                if(status) {
                  window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
                  window.Mixpanel.MixpanelProps.EventId = this.props.rootId;
                  window.Mixpanel.MixpanelProps.Headline = this.props.HeadlineText;
                  window.Mixpanel.MixpanelProps.Publisher = this.props.Publisher;
                  window.Mixpanel.MixpanelProps.FeedId = this.props.alertDetailId;
                  window.Mixpanel.MixpanelProps.ToolTipType = 'User Info';
                  window.Mixpanel.actions.identify(this.props.userData.username);
                  window.Mixpanel.actions.track(`Tooltip shown`, window.Mixpanel.MixpanelProps).then(data => {
                    window.Mixpanel.actions.people.set({
                      UserName: this.props.userData.username
                    });
                    window.Mixpanel.actions.resetProp('EventId');
                    window.Mixpanel.actions.resetProp('Headline');
                    window.Mixpanel.actions.resetProp('ToolTipType');
                    window.Mixpanel.actions.resetProp('Publisher');
                    window.Mixpanel.actions.resetProp('FeedId');
                  })
                }
                
              })
            }, 1500)
          } else {
            this.setState({
              ...this.state,
              reputationToolTip: status
            })
          }
        })
      // }
        
        
      
      
    // }
    
  }

  repIndicatorValues = (repData, reputation) => {
    let ReputationData = {}
    let repDataItems = repData ? repData.split(';') : [];
    let userVerified = false;
    if(repDataItems.length) {
      repDataItems.forEach(element => {
        let KeyValArr = element.split(':')
        ReputationData[`${KeyValArr[0]}`] = KeyValArr.length > 1 ? KeyValArr[1] : ''
        if((`${KeyValArr[0]}` === 'Verified' ||`${KeyValArr[0]}` === 'CrowdsenseVerified')  && ReputationData[`${KeyValArr[0]}`] !== 'False' && ReputationData[`${KeyValArr[0]}`] !== 'false') {
          userVerified = true;
        }
      });
    }

    let bgRepIndic
    let userDetailsToolTip
    let credibilityText = '';
    if(userVerified) {
      bgRepIndic = '#37aaf8';//"bg-rep-verified";
      userDetailsToolTip = "Verified user. Click for details";
      credibilityText = "Verified Source";
    } else {
      switch(parseInt(reputation)) {
        case 1:
          bgRepIndic = '#FF0000';//"bg-rep-red";
          userDetailsToolTip = "Low user credibility. Click for details";
          credibilityText = "Low Credibility Source";
          break;
        case 2:
          bgRepIndic = '#02A702';//"bg-rep-yellow";
          userDetailsToolTip = "Medium user credibility. Click for details";
          credibilityText = "Medium Credibility Source";
          break;
        default:
          bgRepIndic = '#02A702';//"bg-rep-green";
          userDetailsToolTip = "High user credibility. Click for details";
          credibilityText = "High Credibility Source";
          break;
        }
    }

    return { bgRepIndic, userDetailsToolTip, userVerified, ReputationData, repDataItems, credibilityText }
  }

  hoverStateToggle = (stat) => {
    console.log(stat)
    this.setState({
      ...this.state,
      hoverState: !this.state.hoverState
    },() => {
      console.log(this.state.hoverState)
    })
  }
 
  render(){
    const {reputation, repData } = this.props;
    let { reputationToolTip, hoverState } = this.state;
    const { bgRepIndic, userDetailsToolTip } = this.repIndicatorValues(repData, reputation)
    // console.log(hoverState)
    

      return (
        
          <div className="repindicator dispinb">
            {/* <div className={`user-rep ${bgRepIndic} indic1`} onClick={this.toggletip} onMouseEnter={() => this.toggletooltip(true)} onMouseLeave={() => this.toggletooltip(false)} > */}
              {/* <img src={`${APPPATH.ImageUrl}/uservectoricon.svg`} className="" alt="CrowdSense.ai"  /> */}

              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 27 27" onMouseEnter={() => this.toggletooltip(true)} onMouseLeave={() => this.toggletooltip(false)} onClick={this.toggletip}>
                <g transform="translate(0.5 0.5)">
                  <ellipse className="a" cx="13" cy="13" rx="13" ry="13" fill={`${hoverState ? bgRepIndic : 'none'}`} stroke={`${hoverState ? 'none' : '#707070'}`}/>
                  <g transform="translate(7.789 6.074)">
                    <path className="b" d="M5.211,0h0a5.211,5.211,0,0,1,5.211,5.211V7.394a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V5.211A5.211,5.211,0,0,1,5.211,0Z" transform="translate(0 6.458)" fill={`${hoverState ? '#000000' : bgRepIndic}`} />
                    <ellipse className="b" cx="2.749" cy="2.749" rx="2.749" ry="2.749" transform="translate(2.337)" fill={`${hoverState ? '#000000' : bgRepIndic}`}/>
                  </g>
                </g>
              </svg>
              {
                // userVerified ? (<i className="fa fa-user"></i>) : (<i className="fa fa-user"></i>)

              }
                
                  <Tooltip view={`${userDetailsToolTip}`}  show={reputationToolTip} type={`left`} custom={`reputation`} />
                
            {/* </div>   */}
          </div>
      )
  }
  
}

const mapStateToProps = (state) => {
  return {
    userData: state.settings.userData,
    userinfoToolTipHandler : state.settings.toolTipGlobals.userinfoToolTipHandler
  }
}

const mapDispatchToProps = {
  ...appSettingsActionCreators
};
export default connect(mapStateToProps, mapDispatchToProps)(ReputationIndicator)