import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
// import { createStore, applyMiddleware } from 'redux';
import Store from './store';
import { Provider } from 'react-redux';
// import thunk from 'redux-thunk';
// import rootReducer from './reducers/rootReducer';

const appStore = Store;

ReactDOM.render(<Provider store = {appStore}><BrowserRouter><App /></BrowserRouter></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
