import React, { Component } from 'react'; 
import { Col } from 'react-bootstrap';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import { APICONFIG , APPPATH } from '../../modules/helper.module';

class RequestError extends Component {
  render() {
    const { appError } = this.props.error;
    // console.log(appError)
    // var urlRegex =/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;
    // let formattedText = appError.replace(urlRegex, function(url) {
    //   return `<a href="${url}" target="_blank">Click here</a> to sign up or clean your browser history for Crowdsense.ai`;
    // });
    // console.log(formattedText)
    // formattedText.replace(/Google Login/gi, function(text) {
    //   return `<a href="https://signin.crowdsense.ai/api/Account/ExternalLogin?provider=Google&response_type=token&client_id=self&redirect_uri=${encodeURIComponent(APPPATH.AppUrl)}%2F&state=CHArdfGXncDrhlsWlitp8vCtpaCGPBLI2FtiG0dbFJg1" target="_blank">${text}</a>`
    // })
    // console.log(formattedText)
    // formattedText.replace(/Facebook Login/gi, `<a href="https://signin.crowdsense.ai/api/Account/ExternalLogin?provider=Facebook&response_type=token&client_id=self&redirect_uri=${encodeURIComponent(APPPATH.AppUrl)}%2F&state=CHArdfGXncDrhlsWlitp8vCtpaCGPBLI2FtiG0dbFJg1" target="_blank">Facebook Login</a>`)
    // console.log(formattedText)
    // formattedText.replace(/Signup/gi, `<a href="https://crowdsense.ai/signup" target="_blank">Signup</a>`)
    // console.log(formattedText)
    const ResponsiveEllipsis = responsiveHOC()(HTMLEllipsis)
    let ErrorMsg = <ResponsiveEllipsis unsafeHTML={appError} maxLine={`10`} ellipsisHTML='' basedOn='words'  />
    
    return (
      <Col lg={12} sm={12} xs={12} md={12} xl={12} >
        <div className="request-error1">
          {/* {`${error} : ${error_description}`} */}
          {/* {`${appError}`} */}
          { ErrorMsg }
        </div>
      </Col>
    )
  }
}

export default RequestError