var searchTextChange =  new CustomEvent('searchtextchange', {
    detail : undefined,
    bubbles: true,
    cancelable: true
})
  
var searchWidthChange =  new CustomEvent('searchwidthchange', {
    detail : undefined,
    bubbles: true,
    cancelable: true
})

export const CustomSearchEvents = {
    searchTextChange,
    searchWidthChange
}

var mousestop =  new CustomEvent('mousestop', {
    detail : undefined,
    bubbles: true,
    cancelable: true
})

export const CustomMouseEvent = {
    mousestop
}

