import React, { Component } from 'react';
import { APPPATH } from '../../modules/helper.module';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';

class HelperVideo extends Component {
  constructor(props) {
    super();
    this.state = {
      youtubeLink: '',
      toolTipText: '',
    };
    this.OverlayRef = React.createRef();
  }
  
  componentDidMount = () => {
    switch (this.props.callFor) {
      case 'search':
        this.setState({
          youtubeLink: 'https://www.youtube.com/watch?v=pu3PqKPKw0c',
          toolTipText: 'Watch search results video tutorial',
        });
        break;
      case 'top-daily-coins':
        this.setState({
          youtubeLink: 'https://www.youtube.com/watch?v=lD9oKmt1jIk',
          toolTipText: 'Watch top trending coins video tutorial',
        });
        break;
      case 'coin-column':
        this.setState({
          // youtubeLink: 'https://www.youtube.com/watch?v=HNXhRwqEAhc',
          youtubeLink: 'https://youtu.be/c2wziHUjm8Y',
          toolTipText: 'Watch coin details video tutorial',
        });
        break;
      default:
        break;
    }
  };
  entering = (e) => {
    e.children[1].style.backgroundColor = '#37aaf8';
  };
  render() {
    let ToolTipStyleData = {
      Arrow: {
        marginLeft: '13px'
      },
      ToolTip: {
        align: 'center'
      }
    }
    return (
      <span>
        <button className="tooltip-button " style={{position: 'relative'}}>
          <span 
            ref={this.OverlayRef}
            onMouseEnter={(e) =>
              this.props.toggleOverlay(true, this.OverlayRef, this.state.toolTipText, ToolTipStyleData)
            }
            onMouseLeave={(e) =>
              this.props.toggleOverlay(false)
            }
            
          >
            <img
              
              style={{ marginRight: 5 }}
              src={`${APPPATH.ImageUrl}/video-help.png`}
              className="helper-image"
              alt=""
              onClick={() => this.props.showModal(this.props.callFor)}
              
            />
          </span>
        </button>
      </span>
      
    );
  }
}

export default HelperVideo;
