import { APICONFIG, ErrorHandleHelper, TrendingMentionsHelper } from '../modules/helper.module';
import { extrasecurities, OrgEventTypeFilter } from '../static_data/extrasecurities';
import * as logactions from '../actions/logAction.action';
import Store from '../store';

export function getEventTypes() {
  return (dispatch) => {
    try {
      fetch(`${APICONFIG.apiBaseUrl}/api/events/geteventtypes`, {
        method: 'GET',
        headers: APICONFIG.apiHeaders(),
      })
        .then(async (response) => {
          // console.log(response);
          if (response.status === 200 && response.ok) {
            await response.json().then((result) => {
              let userdata = Store.getState().settings.userData
              let render = Store.getState().settings.render
              // console.log('eVENTTYPEs')
              // console.log(result, userdata, render, OrgEventTypeFilter)
              let filteredeventtypes = [];
              if (render === 'iframe' && userdata.loginlessuser !== false) {
                if (result.length) {
                  result.forEach(evntype => {
                    evntype.selected = false;
                    evntype.children = [];
                    result.forEach((evntchild) => {
                      if (evntchild.EventTypeParent === evntype.EventTypeID) {
                        evntype.children.push(evntchild.EventTypeID);
                      }
                    });
                  })
                  OrgEventTypeFilter.forEach(evntel => {
                    let orgevent = result.filter((el) => el.Name === evntel)
                    // console.log(orgevent)
                    if (orgevent.length) {
                      filteredeventtypes.push(orgevent[0])
                      if (orgevent[0].children.length) {

                        orgevent[0].children.forEach(ch => {
                          filteredeventtypes.push(result.filter((el) => el.EventTypeID === ch)[0])
                        })
                      }

                    }



                  })

                }
              } else {
                result.forEach((res) => {
                  res.selected = false;
                  res.children = [];
                  result.forEach((elem) => {
                    if (elem.EventTypeParent === res.EventTypeID) {
                      res.children.push(elem.EventTypeID);
                    }
                  });
                });
                filteredeventtypes = JSON.parse(JSON.stringify(result));
              }

              // console.log(filteredeventtypes)
              dispatch({
                type: 'STORE_EVENTTYPES',
                payload: filteredeventtypes,
              });
            });
          } else {
            await response.json().then((result) => {
              // console.log(result)
              throw new Error(`${response.status} : ${response.statusText} - ${result.Message}`);
            });
          }
        })
        .catch((err) => {
          console.log('error in getEventTypes', err)
          // logactions.logger({Message: 'error in getEventTypes'+ err})
          // throw new Error('error in getEventTypes' + err);
          let data = {
            username: APICONFIG.apiLoggedInUser(),
            action: 'Get Event Types',
            url: `${APICONFIG.apiBaseUrl}/api/events/geteventtypes`,
            severity: 'high'
          };
          dispatch({
            type: 'REQUEST_ERROR',
            payload: ErrorHandleHelper.generateErrorString(err, 'getEventTypes'),
            data: data,
          });
        });
    } catch (err) {
      // logactions.addLogAction(err) 
    }

  };
}

export function getLocations() {
  return (dispatch) => {
    try {
      fetch(`${APICONFIG.apiBaseUrl}/api/locations/getlocations`, {
        method: 'GET',
        headers: APICONFIG.apiHeaders(),
      })
        .then(async (response) => {
          // console.log(response);
          if (response.status === 200 && response.ok) {
            await response.json().then((result) => {
              // console.log(result)
              dispatch({
                type: 'STORE_LOCATIONS',
                payload: result,
              });
            });
          } else {
            await response.json().then((result) => {
              // console.log(result)
              throw new Error(`${response.status} : ${response.statusText} - ${result.Message}`);
            });
          }
        })
        .catch((err) => {
          console.log('error in getLocations', err)
          // logactions.logger({Message: 'error in getLocations'+ err})
          // throw new Error('error in getLocations' + err);
          let data = {
            username: APICONFIG.apiLoggedInUser(),
            action: 'Get Locations',
            url: `${APICONFIG.apiBaseUrl}/api/locations/getlocations`,
            severity: 'low'
          };
          dispatch({
            type: 'REQUEST_ERROR',
            payload: ErrorHandleHelper.generateErrorString(err, 'getLocations'),
            data: data,
          });
        });
    } catch (err) {
      // logactions.addLogAction(err) 
    }

  };
}

export function getEventTypeFilter() {
  return (dispatch) => {
    try {
      fetch(`${APICONFIG.apiBaseUrl}/api/events/geteventtypesfilter?userName=${APICONFIG.apiLoggedInUser()}`, {
        method: 'GET',
        headers: APICONFIG.apiHeaders(),
      })
        .then(async (response) => {
          // console.log(response);
          if (response.status === 200 && response.ok) {
            await response.json().then((result) => {
              // console.log('eVENTTYPE FILTER')
              // console.log(result.split(';'))
              let filterArr = result.split(';');
              // let eventtypes = Store.getState().filters.eventTypes;
              // console.log(eventtypes)
              // let formattedEventTypes = eventtypes.map(itm => itm.Name.toLowerCase()).filter((itm)=>itm)
              // let removefilterindex = [];
              // filterArr.forEach((itm, i) => {
              //   if(formattedEventTypes.indexOf(itm.toLowerCase()) === -1) {
              //     removefilterindex.push(i)
              //   }
              // })
              // console.log(formattedEventTypes)
              // console.log(removefilterindex)
              // if(removefilterindex.length) {
              //   removefilterindex.forEach(index => {
              //     
              //     filterArr.splice(index, 1);
              //   })
              // }
              // console.log(filterArr)
              dispatch({
                type: 'GET_EVENT_FILTERS',
                payload: filterArr,
              });
            });
          } else {
            await response.json().then((result) => {
              // console.log(result)
              throw new Error(`${response.status} : ${response.statusText} - ${result.Message}`);
            });
          }
        })
        .catch((err) => {
          console.log('error in getEventTypeFilter', err)
          // logactions.logger({Message: 'error in getEventTypeFilter'+ err})
          // throw new Error('error in getEventTypeFilter' + err);
          let data = {
            username: APICONFIG.apiLoggedInUser(),
            action: 'Get Event Type Filter',
            url: `${APICONFIG.apiBaseUrl}/api/events/geteventtypesfilter?userName=${APICONFIG.apiLoggedInUser()}`,
            severity: 'high'
          };
          dispatch({
            type: 'REQUEST_ERROR',
            payload: ErrorHandleHelper.generateErrorString(err, 'getEventTypeFilter'),
            data: data,
          });
        });
    } catch (err) {
      // logactions.addLogAction(err) 
    }

  };
}

export function getLocationsFilter() {
  return (dispatch) => {
    try {
      fetch(`${APICONFIG.apiBaseUrl}/api/locations/getlocationsfilter?userName=${APICONFIG.apiLoggedInUser()}`, {
        method: 'GET',
        headers: APICONFIG.apiHeaders(),
      })
        .then(async (response) => {
          // console.log(response);
          if (response.status === 200 && response.ok) {
            await response.json().then((result) => {
              // console.log(result)
              let filterArr = [];
              if (result.length > 0) {
                filterArr = result.split(';');
              }
              // console.log(filterArr)
              dispatch({
                type: 'GET_LOCATIONS_FILTERS',
                payload: filterArr,
              });
            });
          } else {
            await response.json().then((result) => {
              // console.log(result)
              throw new Error(`${response.status} : ${response.statusText} - ${result.Message}`);
            });
          }
        })
        .catch((err) => {
          console.log('error in getLocationsFilter', err)
          // logactions.logger({Message: 'error in getLocationsFilter'+ err})
          // throw new Error('error in getLocationsFilter' + err);
          let data = {
            username: APICONFIG.apiLoggedInUser(),
            action: 'Get Locations Filter',
            url: `${APICONFIG.apiBaseUrl}/api/locations/getlocationsfilter?userName=${APICONFIG.apiLoggedInUser()}`,
            severity: 'low'
          };
          dispatch({
            type: 'REQUEST_ERROR',
            payload: ErrorHandleHelper.generateErrorString(err, 'getLocationsFilter'),
            data: data,
          });
        });
    } catch (err) {
      // logactions.addLogAction(err) 
    }

  };
}

export function getLocationPairs() {
  return (dispatch) => {
    try {
      fetch(`${APICONFIG.apiBaseUrl}/api/locations/getlocationspairs`, {
        method: 'GET',
        headers: APICONFIG.apiHeaders(),
      })
        .then(async (response) => {
          // console.log(response);
          if (response.status === 200 && response.ok) {
            await response.json().then((result) => {
              let FilteredRegions = {};
              let LocationFilteredRegions = {};
              if (result.length) {
                result.forEach((locPair) => {
                  let PairArr = locPair.split('~');
                  if (FilteredRegions[`${PairArr[1]}`]) {
                    if (FilteredRegions[`${PairArr[1]}`].indexOf(PairArr[0]) === -1) {
                      FilteredRegions[`${PairArr[1]}`].push(PairArr[0]);
                    }
                  } else {
                    FilteredRegions[`${PairArr[1]}`] = [];
                    FilteredRegions[`${PairArr[1]}`].push(PairArr[0]);
                  }
                });
                Object.keys(FilteredRegions).map((cntry) => {
                  LocationFilteredRegions[cntry] = [];
                  FilteredRegions[cntry].map((region) => {
                    LocationFilteredRegions[cntry].push({
                      selected: false,
                      name: region,
                      type: 'children',
                      parent: cntry,
                    });
                    return null;
                  });
                  return null;
                });
              }
              // console.log(LocationFilteredRegions)
              dispatch({
                type: 'GET_LOCATIONS_FILTERED_REGIONS',
                payload: LocationFilteredRegions,
              });
            });
          } else {
            await response.json().then((result) => {
              // console.log(result)
              throw new Error(`${response.status} : ${response.statusText} - ${result.Message}`);
            });
          }
        })
        .catch((err) => {
          console.log('error in getLocationPairs', err)
          // logactions.logger({Message: 'error in getLocationPairs'+ err})
          // throw new Error('error in getLocationPairs' + err);
          let data = {
            username: APICONFIG.apiLoggedInUser(),
            action: 'Get Locations Filter',
            url: `${APICONFIG.apiBaseUrl}/api/locations/getlocationsfilter?userName=${APICONFIG.apiLoggedInUser()}`,
            severity: 'low'
          };
          dispatch({
            type: 'REQUEST_ERROR',
            payload: ErrorHandleHelper.generateErrorString(err, 'getLocationPairs'),
            data: data,
          });
        });
    } catch (err) {
      // logactions.addLogAction(err) 
    }

  };
}

export function getSecuritiesRaw() {
  return (dispatch) => {
    try {
      fetch(`${APICONFIG.apiBaseUrl}/api/securities/getsecuritytypes?userName=${APICONFIG.apiLoggedInUser()}`, {
        method: 'GET',
        headers: APICONFIG.apiHeaders(),
      })
        .then(async (response) => {
          // console.log(response);
          if (response.status === 200 && response.ok) {
            await response.json().then((result) => {
              // console.log(result)
              result.push({
                Link: null,
                Name: 'None',
                Securities: null,
                SecurityTypeID: `none${result.length}`,
                SecurityTypeParent: 0,
              });
              result.forEach((element) => {
                element.selected = false;
                element.children = [];
                result.forEach((elem) => {
                  if (elem.SecurityTypeParent === element.SecurityTypeID) {
                    element.children.push(elem.SecurityTypeID);
                  }
                });
                // console.log(element)
              });
              // console.log(result)
              dispatch({
                type: 'GET_SETTING_SECURITIES',
                payload: result,
              });
            });
          } else {
            await response.json().then((result) => {
              // console.log(result)
              throw new Error(`${response.status} : ${response.statusText} - ${result.Message}`);
            });
          }
        })
        .catch((err) => {
          console.log('error in getSecuritiesRaw', err)
          // logactions.logger({Message: 'error in getSecuritiesRaw'+ err})
          // throw new Error('error in getSecuritiesRaw' + err);
          let data = {
            username: APICONFIG.apiLoggedInUser(),
            action: 'Getting Security Types',
            url: `${APICONFIG.apiBaseUrl}/api/securities/getsecuritytypes?userName=${APICONFIG.apiLoggedInUser()}`,
            severity: 'high'
          };
          dispatch({
            type: 'REQUEST_ERROR',
            payload: ErrorHandleHelper.generateErrorString(err, 'getsecuritytypes'),
            data: data,
          });
        });
    } catch (err) {
      // logactions.addLogAction(err) 
    }

  };
}

export function getSecurityTypeFilter() {
  return (dispatch) => {
    try {
      fetch(`${APICONFIG.apiBaseUrl}/api/securities/getsecuritytypesfilter?userName=${APICONFIG.apiLoggedInUser()}`, {
        method: 'GET',
        headers: APICONFIG.apiHeaders(),
      })
        .then(async (response) => {
          // console.log(response);
          if (response.status === 200 && response.ok) {
            await response.json().then((result) => {
              // console.log(result)
              let filterArr = [];
              if (result.length > 0) {
                filterArr = result.split(';');
              }
              // console.log(filterArr)
              dispatch({
                type: 'GET_SECURITY_FILTERS',
                payload: filterArr,
              });
            });
          } else {
            await response.json().then((result) => {
              // console.log(result)
              throw new Error(`${response.status} : ${response.statusText} - ${result.Message}`);
            });
          }
        })
        .catch((err) => {
          console.log('error in getSecurityTypeFilter', err)
          // logactions.logger({Message: 'error in getSecurityTypeFilter'+ err})
          // throw new Error('error in getSecurityTypeFilter' + err);
          let data = {
            username: APICONFIG.apiLoggedInUser(),
            action: 'Getting Security Type Filter',
            url: `${APICONFIG.apiBaseUrl}/api/securities/getsecuritytypesfilter?userName=${APICONFIG.apiLoggedInUser()}`,
            severity: 'high'
          };
          dispatch({
            type: 'REQUEST_ERROR',
            payload: ErrorHandleHelper.generateErrorString(err, 'getSecurityTypesFilter'),
            data: data,
          });
        });
    } catch (err) {
      // logactions.addLogAction(err) 
    }

  };
}

// export function setSecuritiesFiltersRequestState(state) {
//   return dispatch => {
//     dispatch({
//       type: 'SET_SEC_FILT_REQ',
//       payload: state
//     })
//   }
// }

export function getSecurities() {
  return (dispatch) => {
    try {
      // console.log(APICONFIG.apiLoggedInUserType(), APICONFIG.apiLoggedInUser())
      fetch(`${APICONFIG.apiBaseUrl}/api/securities/getsecurities?userName=${(APICONFIG.apiLoggedInUserType() === 'None' || APICONFIG.apiLoggedInUserType() === 'WUser' || APICONFIG.apiLoggedInUserType() === 'WAdmin') ? APICONFIG.apiLoggedInUser() : 'company'}`, {
        method: 'GET',
        headers: APICONFIG.apiHeaders(),
      })
        .then(async (response) => {
          // console.log(response);
          if (response.status === 200 && response.ok) {
            await response.json().then((result) => {
              let secs = Store.getState().filters.securities;
              // console.log(result, extrasecurities, secs)
              let TickerIDList = {};
              let userdata = Store.getState().settings.userData
              let render = Store.getState().settings.render
              let filteredSecurities = []
              if (render === 'iframe' && userdata.loginlessuser !== false) {
                filteredSecurities = secs
              } else {
                filteredSecurities = result
              }
              //extrasecurities.forEach(item => {
              //   // console.log(item)
              //   result.push(item)
              //});
              // console.log(result)
              // let forexsecs = [];
              // let stockssecs = [];
              // let commoditiessecs = [];
              // let indicessecs = [];
              // let cryptosecs = [];
              filteredSecurities.forEach((element) => {
                if (element.SecurityID !== 718) {
                  if (element.Scope === null) {
                    element.Scope = 10000000;
                  }
                }
                element.selected = false;

                TickerIDList[`${element.Symbol}`] = element.SecurityID;
              });

              let newSortedSecs = TrendingMentionsHelper.SortSecuritiesByMarketCapitalisation(filteredSecurities);
              // console.log( newSortedSecs)
              dispatch({
                type: 'GET_SECURITIES',
                payload: newSortedSecs,
                TickerIDList,
              });
            });
          } else {
            await response.json().then((result) => {
              // console.log(result)
              throw new Error(`${response.status} : ${response.statusText} - ${result.Message}`);
            });
          }
        })
        .catch((err) => {
          console.log('error in getSecurities', err)
          // logactions.logger({Message: 'error in getSecurities'+ err})
          // throw new Error('error in getSecurities' + err);
          let data = {
            username: APICONFIG.apiLoggedInUser(),
            action: 'Getting Securities',
            url: `${APICONFIG.apiBaseUrl}/api/securities/getsecurities?userName=${APICONFIG.apiLoggedInUser()}`,
            severity: 'high'
          };
          dispatch({
            type: 'REQUEST_ERROR',
            payload: ErrorHandleHelper.generateErrorString(err, 'getSecurities'),
            data: data,
          });
        });
    } catch (err) {
      // logactions.addLogAction(err) 
    }

  };
}

export function getSecuritiesFilter() {
  return (dispatch) => {
    try {
      fetch(`${APICONFIG.apiBaseUrl}/api/securities/getsecuritiesfilter?userName=${APICONFIG.apiLoggedInUser()}`, {
        method: 'GET',
        headers: APICONFIG.apiHeaders(),
      })
        .then(async (response) => {
          // console.log(response);
          if (response.status === 200 && response.ok) {
            await response.json().then((result) => {
              // console.log(result)
              let filterArr = [];
              if (result.length > 0) {
                filterArr = result.split(';');
              }
              // console.log(filterArr)
              dispatch({
                type: 'GET_SECURITIES_FILTERS',
                payload: filterArr,
              });
            });
          } else {
            await response.json().then((result) => {
              // console.log(result)
              throw new Error(`${response.status} : ${response.statusText} - ${result.Message}`);
            });
          }
        })
        .catch((err) => {
          console.log('error in getSecuritiesFilter', err)
          // logactions.logger({Message: 'error in getSecuritiesFilter'+ err})
          let data = {
            username: APICONFIG.apiLoggedInUser(),
            action: 'Getting Securities Filter',
            url: `${APICONFIG.apiBaseUrl}/api/securities/getsecuritiesfilter?userName=${APICONFIG.apiLoggedInUser()}`,
            severity: 'high'
          };
          dispatch({
            type: 'REQUEST_ERROR',
            payload: ErrorHandleHelper.generateErrorString(err, 'getSecuritiesFilter'),
            data: data,
          });
        });
    } catch (err) {
      // logactions.addLogAction(err) 
    }

  };
}

export function getsecuritiesresults() {
  return (dispatch) => {
    try {
      fetch(`${APICONFIG.apiBaseUrl}/api/securities/getsecuritiesresults`, {
        method: 'GET',
        headers: APICONFIG.apiHeaders(),
      })
        .then(async (response) => {
          if (response.status === 200 && response.ok) {
            await response.json().then((result) => {
              // console.log(result)
              dispatch({
                type: 'GET_SECURITIES_RESULTS',
                payload: result,
              });
            });
          } else {
            await response.json().then((result) => {
              // console.log(result)
              throw new Error(`${response.status} : ${response.statusText} - ${result.Message}`);
            });
          }
        })
        .catch((err) => {
          console.log('error in getsecuritiesresults', err)
          // logactions.logger({Message: 'error in getsecuritiesresults'+ err})
          // throw new Error('error in getsecuritiesresults' + err);
          let data = {
            username: APICONFIG.apiLoggedInUser(),
            action: 'Getting Security Type Filter',
            url: `${APICONFIG.apiBaseUrl}/api/securities/getsecuritiesresults`,
            severity: 'low'
          };
          dispatch({
            type: 'REQUEST_ERROR',
            payload: ErrorHandleHelper.generateErrorString(err, 'getSecuritiesResults'),
            data: data,
          });
        });
    } catch (err) {
      // logactions.addLogAction(err) 
    }

  };
}

export function getMentionTypes() {
  return (dispatch) => {
    try {
      fetch(`${APICONFIG.apiBaseUrl}/api/mentions/getmentiontypes?userName=${APICONFIG.apiLoggedInUser()}`, {
        method: 'GET',
        headers: APICONFIG.apiHeaders(),
      })
        .then(async (response) => {
          // console.log(response);
          if (response.status === 200 && response.ok) {
            await response.json().then((result) => {
              // console.log(result)
              result.forEach((res) => {
                // if(res.Name === 'Antofagasta Plc') {
                //   console.log(res)
                // }
                res.selected = false;
                res.children = [];
                result.forEach((elem) => {
                  if (
                    elem.MentionTypeParent === res.MentionTypeID ||
                    (elem.VirtualParents !== null && elem.VirtualParents.split(';').indexOf(res.MentionTypeID) > -1)
                  ) {
                    res.children.push(elem.MentionTypeID);
                  }
                });
              });
              dispatch({
                type: 'SET_MENTIONTYPES',
                payload: result,
              });
            });
          } else {
            await response.json().then((result) => {
              // console.log(result)
              throw new Error(`${response.status} : ${response.statusText} - ${result.Message}`);
            });
          }
        })
        .catch((err) => {
          console.log('error in getMentionTypes', err)
          // logactions.logger({Message: 'error in getMentionTypes'+ err})
          // throw new Error('error in getMentionTypes' + err);
          let data = {
            username: APICONFIG.apiLoggedInUser(),
            action: 'Getting Mention Types',
            url: `${APICONFIG.apiBaseUrl}/api/mentions/getmentiontypes?userName=${APICONFIG.apiLoggedInUser()}`,
            severity: 'low'
          };
          dispatch({
            type: 'REQUEST_ERROR',
            payload: ErrorHandleHelper.generateErrorString(err, 'getMentionTypes'),
            data: data,
          });
        });
    } catch (err) {
      // logactions.addLogAction(err) 
    }

  };
}

export function getMentionTypeFilter() {
  return (dispatch) => {
    try {
      fetch(`${APICONFIG.apiBaseUrl}/api/mentions/getmentiontypesfilter?userName=${APICONFIG.apiLoggedInUser()}`, {
        method: 'GET',
        headers: APICONFIG.apiHeaders(),
      })
        .then(async (response) => {
          // console.log(response);
          if (response.status === 200 && response.ok) {
            await response.json().then((result) => {
              // console.log(result)
              let filterArr = [];
              if (result.length > 0) {
                filterArr = result.split(';');
              }
              dispatch({
                type: 'SET_MENTIONTYPE_FILTERS',
                payload: filterArr,
              });
            });
          } else {
            await response.json().then((result) => {
              // console.log(result)
              throw new Error(`${response.status} : ${response.statusText} - ${result.Message}`);
            });
          }
        })
        .catch((err) => {
          console.log('error in getMentionTypeFilter', err)
          // logactions.logger({Message: 'error in getMentionTypeFilter'+ err})
          // throw new Error('error in getMentionTypeFilter' + err);
          let data = {
            username: APICONFIG.apiLoggedInUser(),
            action: 'Getting Mention Types filters',
            url: `${APICONFIG.apiBaseUrl}/api/mentions/getmentiontypesfilter?userName=${APICONFIG.apiLoggedInUser()}`,
            severity: 'low'
          };
          dispatch({
            type: 'REQUEST_ERROR',
            payload: ErrorHandleHelper.generateErrorString(err, 'getMentiontypesFilter'),
            data: data,
          });
        });
    } catch (err) {
      // logactions.addLogAction(err) 
    }

  };
}

export function setLocationContinents(Continents) {
  // console.log(Continents)
  return (dispatch) => {
    try {
      dispatch({
        type: 'GET_SETTING_LOCATION_CONTINENTS',
        payload: Continents,
      });
    } catch (err) {
      // logactions.logger({Message: 'error in setLocationContinents'+ err})
      // logactions.addLogAction(err) 
    }

  };
}

export function setLocationByContinents(CFL) {
  // console.log(CFL)
  return (dispatch) => {
    try {
      dispatch({
        type: 'SET_SETTING_LOCATION_BY_CONTINENTS',
        payload: CFL,
      });
    } catch (err) {
      // logactions.logger({Message: 'error in setLocationByContinents'+ err})
      // logactions.addLogAction('error in setLocationByContinents :' + err) 
    }

  };
}

export function updateEventSecurityFilter(data) {
  return (dispatch) => {
    try {
      fetch(`${APICONFIG.apiBaseUrl}/api/events/posteventtypesfilter`, {
        method: 'POST',
        body: JSON.stringify({
          UserName: data.UserName,
          FilterString: data.FilterString,
        }),
        headers: APICONFIG.apiHeaders(),
      })
        .then((result) => {
          // console.log(result);
          if (result.ok) {
            let DispatchPayload = {
              response: result,
              newFilterString: data.FilterString,
            };
            dispatch({
              type: 'UPDATE_EVENT_FILTERS',
              payload: DispatchPayload,
            });
          }
        })
        .catch((err) => {
          console.log('error in updateEventSecurityFilter', err)
          // logactions.logger({Message: 'error in updateEventSecurityFilter'+ err})
          // throw new Error('error in updateEventSecurityFilter' + err);
          let Data = {
            username: data.UserName,
            action: 'Update Event Type Filter',
            url: `${APICONFIG.apiBaseUrl}/api/events/posteventtypesfilter`,
            severity: 'high'
          };
          dispatch({
            type: 'REQUEST_ERROR',
            payload: ErrorHandleHelper.generateErrorString(err, 'UpdateEventSecurityFilter'),
            data: Data,
          });
        });
    } catch (err) {
      // logactions.addLogAction(err) 
    }

  };
}

export function updateLocationFilter(data) {
  return (dispatch) => {
    try {
      fetch(`${APICONFIG.apiBaseUrl}/api/locations/setlocationsfilter`, {
        method: 'POST',
        body: JSON.stringify({
          UserName: data.UserName,
          FilterString: data.FilterString,
        }),
        headers: APICONFIG.apiHeaders(),
      })
        .then((result) => {
          if (result.ok) {
            let DispatchPayload = {
              response: result,
              newFilterString: data.FilterString,
            };
            dispatch({
              type: 'UPDATE_LOCATION_FILTERS',
              payload: DispatchPayload,
            });
          }
        })
        .catch((err) => {
          console.log('error in updateLocationFilter', err)
          // logactions.logger({Message: 'error in updateLocationFilter'+ err})
          // throw new Error('error in updateLocationFilter' + err);
          let Data = {
            username: data.UserName,
            action: 'Update Locations Filter',
            url: `${APICONFIG.apiBaseUrl}/api/locations/setlocationsfilter`,
            severity: 'low'
          };
          dispatch({
            type: 'REQUEST_ERROR',
            payload: ErrorHandleHelper.generateErrorString(err, 'UpdateLocationsFilter'),
            data: Data,
          });
        });
    } catch (err) {
      // logactions.addLogAction(err) 
    }

  };
}

export function updateMentionTypesFilter(data) {
  return (dispatch) => {
    try {
      fetch(`${APICONFIG.apiBaseUrl}/api/mentions/setmentiontypesfilter`, {
        method: 'POST',
        body: JSON.stringify({
          UserName: data.UserName,
          FilterString: data.FilterString,
        }),
        headers: APICONFIG.apiHeaders(),
      })
        .then((result) => {
          // console.log(result);
          let filterArr = [];
          if (result.length > 0) {
            filterArr = result.split(';');
          }
          if (result.ok) {
            dispatch({
              type: 'SET_MENTIONTYPE_FILTERS',
              payload: filterArr,
            });
          }
        })
        .catch((err) => {
          console.log('error in updateMentionTypesFilter', err)
          // logactions.logger({Message: 'error in updateMentionTypesFilter'+ err})
          // throw new Error('error in updateMentionTypesFilter' + err);
          let Data = {
            username: data.UserName,
            action: 'Update Mention type Filter',
            url: `${APICONFIG.apiBaseUrl}/api/mentions/setmentiontypesfilter`,
            severity: 'low'
          };
          dispatch({
            type: 'REQUEST_ERROR',
            payload: ErrorHandleHelper.generateErrorString(err, 'UpdateMentiontypesFilter'),
            data: Data,
          });
        });
    } catch (err) {
      // logactions.addLogAction(err) 
    }

  };
}

export function updateSecuritiesFilter(data) {
  return (dispatch) => {
    try {
      fetch(`${APICONFIG.apiBaseUrl}/api/securities/setsecuritiesfilter`, {
        method: 'POST',
        body: JSON.stringify({
          UserName: data.UserName,
          FilterString: data.FilterString,
        }),
        headers: APICONFIG.apiHeaders(),
      })
        .then((result) => {
          if (result.ok) {
            let DispatchPayload = {
              response: result,
              newFilterString: data.FilterString,
            };
            dispatch({
              type: 'UPDATE_SECURITIES_FILTERS',
              payload: DispatchPayload,
            });
            dispatch({
              type: 'RESET_STORIES',
            });
            let prefObj = Store.getState().trendingMentions.coin_column_preferences
            let cc_indexes = [];
            if (prefObj.length) {
              prefObj.forEach((pref, i) => {
                if (pref.tmFromWatchlist === 'true') {
                  cc_indexes.push(i)
                }
              })
              cc_indexes.forEach(index => {
                dispatch({
                  type: 'RESET_TM',
                  payload: index
                });
                dispatch({
                  type: 'TM_AUTOCALL',
                  payload: index
                });
              })
            }

          }
        })
        .catch((err) => {
          console.log('error in updateSecuritiesFilter', err)
          // logactions.logger({Message: 'error in updateSecuritiesFilter'+ err})
          // throw new Error('error in updateSecuritiesFilter' + err);
          let Data = {
            username: data.UserName,
            action: 'Updating Securities Filter',
            url: `${APICONFIG.apiBaseUrl}/api/securities/setsecuritiesfilter`,
            severity: 'high'
          };
          dispatch({
            type: 'REQUEST_ERROR',
            payload: ErrorHandleHelper.generateErrorString(err, 'UpdateSecuritiesFilter'),
            data: Data,
          });
        });
    } catch (err) {
      // logactions.addLogAction(err) 
    }

  };
}

export function updateSecurityFilter(data) {
  return (dispatch) => {
    try {
      fetch(`${APICONFIG.apiBaseUrl}/api/securities/setsecuritytypesfilter`, {
        method: 'POST',
        body: JSON.stringify({
          UserName: data.UserName,
          FilterString: data.FilterString,
        }),
        headers: APICONFIG.apiHeaders(),
      })
        .then((result) => {
          if (result.ok) {
            let DispatchPayload = {
              response: result,
              newFilterString: data.FilterString,
            };
            dispatch({
              type: 'UPDATE_SECURITY_FILTERS',
              payload: DispatchPayload,
            });
          }
        })
        .catch((err) => {
          console.log('error in updateSecurityFilter', err)
          // logactions.logger({Message: 'error in updateSecurityFilter'+ err})
          // throw new Error('error in updateSecurityFilter' + err);
          let Data = {
            username: data.UserName,
            action: 'Updating Security Types Filter',
            url: `${APICONFIG.apiBaseUrl}/api/securities/setsecuritytypesfilter`,
            severity: 'low'
          };
          dispatch({
            type: 'REQUEST_ERROR',
            payload: ErrorHandleHelper.generateErrorString(err, 'UpdateSecurityTypesFilter'),
            data: Data,
          });
        });
    } catch (err) {
      // logactions.addLogAction(err) 
    }

  };
}

export function getLocationContinents() {
  return (dispatch) => {
    try {
      fetch(`${APICONFIG.apiBaseUrl}/api/locations/getcontinents`, {
        method: 'GET',
        headers: APICONFIG.apiHeaders(),
      })
        .then((response) => response.json())
        .then((result) => {
          let respo = [];
          let unique = [];
          result.forEach((element) => {
            // console.log(element)
            if (unique.indexOf(element) === -1) {
              let re = {
                selected: false,
                name: element,
                type: 'parent',
                parent: '',
              };
              respo.push(re);
              unique.push(element);
            }
          });
          // console.log(respo)
          dispatch({
            type: 'GET_SETTING_LOCATION_CONTINENTS',
            payload: respo,
          });
        })
        .catch((err) => {
          console.log('error in getLocationContinents', err)
          // logactions.logger({Message: 'error in getLocationContinents'+ err})
          // throw new Error('error in getLocationContinents' + err);
          let data = {
            username: APICONFIG.apiLoggedInUser(),
            action: 'get location continents',
            url: `${APICONFIG.apiBaseUrl}/api/locations/getcontinents`,
            severity: 'low'
          };
          dispatch({
            type: 'REQUEST_ERROR',
            payload: ErrorHandleHelper.generateErrorString(err, 'getLocationsContinents'),
            data: data,
          });
        });
    } catch (err) {
      // logactions.addLogAction(err) 
    }

  };
}

export function getLocationByContinents(continent) {
  return (dispatch) => {
    try {
      fetch(`${APICONFIG.apiBaseUrl}/api/locations/getcountriesbycontinent?continent=${continent}`, {
        method: 'GET',
        headers: APICONFIG.apiHeaders(),
      })
        .then((response) => response.json())
        .then((result) => {
          let respo = [];
          let unique = [];
          result.forEach((element) => {
            if (unique.indexOf(element) === -1) {
              let re = {
                selected: false,
                name: element,
                type: 'children',
                parent: continent,
              };
              respo.push(re);
              unique.push(element);
            }
          });
          // console.log(respo)
          dispatch({
            type: 'GET_SETTING_LOCATION_BY_CONTINENTS',
            payload: respo,
            onContinent: continent,
          });
        })
        .catch((err) => {
          console.log('error in getLocationByContinents', err)
          // logactions.logger({Message: 'error in getLocationByContinents'+ err})
          // throw new Error('error in getLocationByContinents' + err);
          let data = {
            username: APICONFIG.apiLoggedInUser(),
            action: 'get location by continents',
            url: `${APICONFIG.apiBaseUrl}/api/locations/getcountriesbycontinent?continent=${continent}`,
            severity: 'low'
          };
          dispatch({
            type: 'REQUEST_ERROR',
            payload: ErrorHandleHelper.generateErrorString(err, 'getLocationsByContinents'),
            data: data,
          });
        });
    } catch (err) {
      // logactions.addLogAction(err) 
    }

  };
}
