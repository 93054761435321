import React, { Component } from 'react';
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import { APICONFIG, APPPATH, TrendingMentionsHelper } from '../../modules/helper.module';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import SearchAndEventSepratorInSearch from './SearchAndEventSepratorInSearch';
import { isMobile } from 'react-device-detect';

class SearchedTickerDetails extends Component {
  constructor(props) {
    super();
    this.state = {
      TM: props.searchCoinDetails,
      fullDescription: false,
      DescriptionButtonHover: false,
      count: 0,
    };
    this.OverlayRef = React.createRef();
  }

  toggleDescription = () => {
    this.setState({
      ...this.state,
      fullDescription: !this.state.fullDescription,
    });
  };

  hoverDescExpandButton = (e, status) => {
    this.setState({
      ...this.state,
      DescriptionButtonHover: status,
    });
  };

  checkSearchStringHaveSingleCoin = (flag) => {
    if (flag === 'search') {
      let searchString = this.props.searchData.searchString;
      // console.log(searchString);
      if (searchString && !this.props.colName.includes('saved')) {
        // if temp have value
        var count = (searchString.match(/,/g) || []).length;
        this.setState({
          count: count,
          searchFlag: flag,
        });
        if (count === 1) {
          // if temp have single value
          //get the ticker symbol to get the ticker details
          this.getTickerDetailsOnSingleSearch(
            searchString.substring(searchString.indexOf('(') + 1, searchString.lastIndexOf(')')),
            flag
          );
          // get the ticker details
          // show the ticker details in the search
        } else {
          this.setState(
            {
              TM: undefined,
            },
            this.props.hidePreferenceInSearch(false)
          );
        }
      }
    } else if (flag === 'savedsearch') {
      // console.log(this.props.savedSearchDataDetails);
      if (
        JSON.parse(this.props.savedSearchDataDetails).sec.length === 1 &&
        !JSON.parse(this.props.savedSearchDataDetails).secTyp.length &&
        !JSON.parse(this.props.savedSearchDataDetails).event.length &&
        JSON.parse(this.props.savedSearchDataDetails).startDate === '' &&
        JSON.parse(this.props.savedSearchDataDetails).endDate === '' &&
        JSON.parse(this.props.savedSearchDataDetails).freeText === ''
      ) {
        this.setState(
          {
            ...this.state,
            searchFlag: flag,
            count: JSON.parse(this.props.savedSearchDataDetails).sec.length,
          },
          () => {
            this.getTickerDetailsOnSingleSearch(JSON.parse(this.props.savedSearchDataDetails).ticker[0], flag);
          }
        );
      } else {
        this.setState(
          {
            ...this.state,
            TM: undefined,
          },
          () => {
            this.props.hidePreferenceInSavedSearch(this.props.userSavedDataID, false);
          }
        );
      }
    }
  };

  getTickerDetailsOnSingleSearch = (TM, flag) => {
    if (TM === '') {
      return;
    }
    fetch(`${APICONFIG.apiBaseUrl}/api/summeraization/getcoindata?securityTicker=${TM}`, {
      method: 'GET',
      headers: APICONFIG.apiHeaders(),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.SecurityName) {
          var tm = result;
          const {
            Mentions,
            MentionsChange,
            Reach,
            ReachChange,
            Positive,
            PositiveChange,
            Negative,
            NegativeChange,
            Virality,
            SecurityPrice,
            Change,
            Volume,
          } = TrendingMentionsHelper.TMExtract(tm);
          tm[`Mentions`] = Mentions;
          tm[`MentionsChange`] = MentionsChange;
          tm[`MentionsTrend`] = 0;
          tm[`Reach`] = Reach;
          tm[`ReachChange`] = ReachChange;
          tm[`ReachTrend`] = 0;
          tm[`Positive`] = Positive;
          tm[`PositiveChange`] = PositiveChange;
          tm[`PositiveTrend`] = 0;
          tm[`Negative`] = Negative;
          tm[`NegativeChange`] = NegativeChange;
          tm[`NegativeTrend`] = 0;
          tm[`Virality`] = Virality;
          tm[`SecurityPrice`] = SecurityPrice;
          tm[`SecurityPriceTrend`] = 0;
          tm[`Change`] = Change;
          tm[`ChangeTrend`] = 0;
          tm[`Volume`] = Volume;
          this.setState(
            {
              ...this.state,
              TM: tm,
            },
            () => {
              if (flag === 'search') {
                this.props.hidePreferenceInSearch(true);
              } else if (flag === 'savedsearch') {
                this.props.hidePreferenceInSavedSearch(this.props.userSavedDataID, true);
              }
            }
          );
        } else {
          this.setState(
            {
              ...this.state,
              TM: undefined,
            },
            () => {
              if (flag === 'search') {
                this.props.hidePreferenceInSearch(false);
              } else if (flag === 'savedsearch') {
                this.props.hidePreferenceInSavedSearch(this.props.userSavedDataID, false);
              }
            }
          );
        }
      });
  };

  formatFeedText = (text) => {
    const ResponsiveEllipsis = responsiveHOC()(HTMLEllipsis);
    return <ResponsiveEllipsis unsafeHTML={text} maxLine={1} ellipsisHTML={`...`} basedOn="words" />;
  };

  componentDidMount = () => {
    this.checkSearchStringHaveSingleCoin(this.props.ColType);
  };

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.searchData) !== JSON.stringify(prevProps.searchData) &&
      !this.props.colName.toLowerCase().includes('saved')
    ) {
      this.checkSearchStringHaveSingleCoin('search');
    }
  }

  getColumnCollapseFlag = () => {
    let colType = this.props.ColType;
    if (colType === 'search') {
      return this.props.searchColumnCollapse;
    } else if (colType === 'savedsearch') {
      return JSON.parse(this.props.savedSearchDataDetails).columnCollapsePreference;
    }
  };

  render() {
    const { TM, fullDescription, DescriptionButtonHover, count, searchFlag } = this.state;
    let clampedDescription =
      TM && TM.CoinDescription !== null ? this.formatFeedText(`<div>${TM.CoinDescription}</div>`) : null;

    return (
      <>
        {TM && count < 2 && (
          <>
            <Row className={`marg-0 nopad margb3`}>
              <Col lg={12} sm={12} xs={12} md={12} xl={12} className={`nopad tmCategories padlr10`}>
                {TM.CoinDescription !== null && (
                  <Row className={`marg-0 nopad brdr-bot-gray `}>
                    {fullDescription ? (
                      <Col lg={11} sm={11} xs={11} md={11} xl={11} className={`nopad text-left align-self-center `}>
                        <p className="tickDet-desc ">{TM.CoinDescription}</p>
                      </Col>
                    ) : (
                      <Col lg={11} sm={11} xs={11} md={11} xl={11} className={`nopad text-left align-self-center `}>
                        <div className="tickDet-desc ">{clampedDescription}</div>
                      </Col>
                    )}

                    <Col
                      lg={1}
                      sm={1}
                      xs={1}
                      md={1}
                      xl={1}
                      className={`nopad padt5 pointer`}
                      onMouseEnter={(e) => this.hoverDescExpandButton(e, true)}
                      onMouseLeave={(e) => this.hoverDescExpandButton(e, false)}
                      onClick={this.toggleDescription}
                    >
                      <img
                        style={{ width: '15px' }}
                        src={`${
                          DescriptionButtonHover
                            ? `${APPPATH.ImageUrl}/storyexpandiconblue.svg`
                            : `${APPPATH.ImageUrl}/storyexpandicon.svg`
                        }`}
                        className={`storyExpandIcon ${fullDescription ? 'transform180anticlock' : ''} `}
                        alt=""
                      />
                      {/*  */}
                    </Col>
                  </Row>
                )}

                <Row className={`marg-0 nopad brdr-bot-gray`}>
                  <Col lg={4} sm={4} xs={4} md={4} xl={4} className={`nopad text-left`}>
                    <p className="tickDet-Title fssm11">
                      <span className="padright5">
                        <img className="tickDet-icon" src={`${APPPATH.ImageUrl}/icon_price-selected.svg`} alt="" />
                      </span>
                      <span>Price</span>
                    </p>
                    <p className={`tickDet-data fssm9`}>
                      {parseFloat(TM.SecurityPrice) > 0
                        ? `${TrendingMentionsHelper.currencyFormat(TM.SecurityPrice, '$')}`
                        : '--'}
                      {parseFloat(TM.SecurityPrice) > 0 && (
                        <span
                          className={`tickDet-data-change fssm9 ${
                            TM.Change < 0 ? 'text-red' : TM.Change > 0 ? 'text-green' : ''
                          } `}
                        >
                          &nbsp;&nbsp;
                          {`${!isNaN(parseFloat(TM.Change)) ? (parseFloat(TM.Change) > 0 ? '+' : '') : ''}${
                            !isNaN(parseFloat(TM.Change)) ? parseFloat(TM.Change).toFixed(2) : '0.00'
                          }%`}
                        </span>
                      )}
                    </p>
                  </Col>
                  <Col lg={4} sm={4} xs={4} md={4} xl={4} className={`nopad text-left`}>
                    <p className="tickDet-Title fssm10">
                      <span className="padright5">
                        <img className="tickDet-icon" src={`${APPPATH.ImageUrl}/icon_market_cap.svg`} alt="" />
                      </span>
                      <span>Market Cap</span>
                    </p>
                    <p className={`tickDet-data fssm9`}>
                      {parseFloat(TM.MarketCapUsd) > 0 && parseFloat(TM.SecurityPrice) > 0
                        ? `$ ${TrendingMentionsHelper.abbreviateNumber(TM.MarketCapUsd)}`
                        : '--'}
                    </p>
                  </Col>
                  <Col lg={4} sm={4} xs={4} md={4} xl={4} className={`nopad text-left`}>
                    <p className="tickDet-Title fssm10">
                      <span className="padright5">
                        <img className="tickDet-icon" src={`${APPPATH.ImageUrl}/icon_volume-selected.svg`} alt="" />
                      </span>
                      <span>Volume</span>
                    </p>
                    <p className={`tickDet-data fssm9`}>
                      {parseFloat(TM.Volume24hUsd) > 1 && parseFloat(TM.SecurityPrice) > 0
                        ? `$ ${TrendingMentionsHelper.abbreviateNumber(TM.Volume24hUsd)}`
                        : '0'}
                    </p>
                  </Col>
                </Row>
                <Row className={`marg-0 nopad brdr-bot-gray`}>
                  <Col lg={4} sm={4} xs={4} md={4} xl={4} className={`nopad text-left`}>
                    <p className="tickDet-Title fssm10">
                      <span className="padright5">
                        <img className="tickDet-icon" src={`${APPPATH.ImageUrl}/icon-positive-selected.svg`} alt="" />
                      </span>
                      <span>Sentiment</span>
                    </p>
                    <p className={`tickDet-data fssm9`}>
                      {`${TM.PositiveSentimentPrec / 10}/10`}
                      <span
                        className={`tickDet-data-change fssm9 ${
                          TM.PositiveChange < 0 ? 'text-red' : TM.PositiveChange > 0 ? 'text-green' : ''
                        } `}
                      >
                        &nbsp;&nbsp;
                        {`${
                          !isNaN(parseFloat(TM.PositiveChange)) ? (parseFloat(TM.PositiveChange) > 0 ? '+' : '') : ''
                        }${!isNaN(parseFloat(TM.PositiveChange)) ? parseFloat(TM.PositiveChange).toFixed(2) : '0.00'}%`}
                      </span>
                    </p>
                  </Col>
                  <Col lg={4} sm={4} xs={4} md={4} xl={4} className={`nopad text-left`}>
                    <p className="tickDet-Title fssm10">
                      <span className="padright5">
                        <img className="tickDet-icon" src={`${APPPATH.ImageUrl}/icon-trending-selected.svg`} alt="" />
                      </span>
                      <span>Mentions</span>
                    </p>
                    <p className={`tickDet-data fssm9`}>
                      {TrendingMentionsHelper.abbreviateNumber(TM.Mentions)}
                      <span
                        className={`tickDet-data-change fssm9 ${
                          TM.MentionsChange < 0 ? 'text-red' : TM.MentionsChange > 0 ? 'text-green' : ''
                        } `}
                      >
                        &nbsp;&nbsp;
                        {`${
                          !isNaN(parseFloat(TM.MentionsChange)) ? (parseFloat(TM.MentionsChange) > 0 ? '+' : '') : ''
                        }${!isNaN(parseFloat(TM.MentionsChange)) ? parseFloat(TM.MentionsChange).toFixed(2) : '0.00'}%`}
                      </span>
                    </p>
                  </Col>
                  <Col lg={4} sm={4} xs={4} md={4} xl={4} className={`nopad text-left`}>
                    <p className="tickDet-Title fssm10">
                      <span className="padright5">
                        <img className="tickDet-icon" src={`${APPPATH.ImageUrl}/icon-mentions-selected.svg`} alt="" />
                      </span>
                      <span>Reach</span>
                    </p>
                    <p className={`tickDet-data fssm9`}>
                      {TrendingMentionsHelper.abbreviateNumber(TM.Reach)}
                      <span
                        className={`tickDet-data-change fssm9 ${
                          TM.ReachChange < 0 ? 'text-red' : TM.ReachChange > 0 ? 'text-green' : ''
                        } `}
                      >
                        &nbsp;&nbsp;
                        {`${!isNaN(parseFloat(TM.ReachChange)) ? (parseFloat(TM.ReachChange) > 0 ? '+' : '') : ''}${
                          !isNaN(parseFloat(TM.ReachChange)) ? parseFloat(TM.ReachChange).toFixed(2) : '0.00'
                        }%`}
                      </span>
                    </p>
                  </Col>
                </Row>
                <Row className={`marg-0 nopad`}>
                  <Col lg={12} sm={12} xs={12} md={12} xl={12} className={`nopad `}>
                    {!isMobile ? (
                      <span
                        ref={this.OverlayRef}
                        className="tooltip-button brdr"
                        style={{ color: 'white' }}
                        onMouseEnter={(e) =>
                          this.props.toggleOverlay(
                            true,
                            this.OverlayRef,
                            'Click to select the prices across crypto exchanges that list this coin'
                          )
                        }
                        onMouseLeave={(e) =>
                          this.props.toggleOverlay(
                            false,
                            this.OverlayRef,
                            'Click to select the prices across crypto exchanges that list this coin'
                          )
                        }
                      >
                        <div className="tradeButtonWrap">
                          <button className="btn btn-trade" onClick={() => this.props.showModal('TradeExchanges', TM)}>
                            Trade
                          </button>
                        </div>
                      </span>
                    ) : (
                      <div className="tradeButtonWrap">
                        <button className="btn btn-trade" onClick={() => this.props.showModal('TradeExchanges', TM)}>
                          Trade
                        </button>
                      </div>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
            <SearchAndEventSepratorInSearch
              TM={TM}
              {...this.props}
              columnCollapseFlag={this.getColumnCollapseFlag()}
              columnCollapseHandle={(e) => {
                if (this.props.ColType === 'search') {
                  this.props.toggleSearchColumnCollapse(e);
                } else if (this.props.ColType === 'savedsearch') {
                  this.props.toggleSavedSearchCollapse();
                }
              }}
              userSavedDataID={this.props.userSavedDataID}
              searchFlag={searchFlag}
              savedSearchDataDetails={this.props.savedSearchDataDetails}
            />
          </>
        )}
      </>
    );
  }
}

const mapStateToComponent = (state, parentProps) => {
  var savedSearch = state.search.searchSaveData;
  let obj;
  if (savedSearch) {
    obj = savedSearch[parentProps.colName.replace('SavedSearch', '')];
  }
  return {
    searchData: state.search.searchData,
    userSavedDataID: obj && obj.UserSavedDataID,
    savedSearchDataDetails: obj && obj.SavedDataDetails,
    savedTickerList: obj && JSON.parse(obj.SavedDataDetails).ticker,
    searchCoinDetails: state.search.searchCoinDetails,
  };
};

export default connect(mapStateToComponent, {})(SearchedTickerDetails);
