import React, { Component } from 'react';

class GoogleAds extends Component {

    constructor(props) {
        super();
        this.state = {
            SlotId : props.SlotId,
            width: props.width,
            height: props.height,
            // count: props.count
        }
    }

    componentDidMount() {
        try {
            (window.adsbygoogle = window.adsbygoogle || []).push({}) 
        } catch(ex) {
            console.log(ex);
        }
        window.addEventListener("message", this.adsenseMessagebroadcast)
        
    }
    adsenseMessagebroadcast =  (event) => {
        const { SlotId } = this.state;
        let El = document.getElementById(`${SlotId}0`);
        
        if(typeof El.dataset.adStatus !== 'undefined' && El.dataset.adStatus !== 'filled') {
            console.log(El.parentNode)
        //     El.parentNode.style.display = 'none';
           
        }
        // if(this.props.resizeColumn) {
        //     setTimeout(() => {
        //         this.props.resizeHeightFromAdSlots();
        //     },200)
            
        // }
        

    }
    
    componentWillUnmount() {
        window.removeEventListener('message', this.adsenseMessagebroadcast);
    }

    render() {
        const { SlotId, width, height } = this.state;
        return (
            // <ins className="adsbygoogle adsense-1-dimensions"
            // style={{display:'block'}}
            // data-ad-client="ca-pub-8523528160500226"
            // data-ad-slot={SlotId}
            // // data-ad-slot="4430532505"
            // data-ad-format="auto"
            // data-full-width-responsive="true"></ins>
            // <div  className='story-card-ad' >
                <ins 
                    id={`${SlotId}0`}
                    className="adsbygoogle"
                    style={{display:'inline-block',width: width,height:height}}
                    data-ad-client="ca-pub-8523528160500226"
                    data-ad-slot={SlotId}>
                    
                </ins>
            // </div>
        );
    }

}

export default GoogleAds