import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import LogoIframe from './logo_iframe';
// import PremiumButton from './PremiumButton';
// import PremiumButtonMobile from './PremiumButtonMobile';
// import PremiumTag from './PremiumTag';
// import Logmenu from './logmenu';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { APPPATH, TrendingMentionsHelper } from '../../../modules/helper.module';

class HeadbarIframe extends Component {
  constructor() {
    super();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let theme = false;
    if(urlParams.has('theme')) {
      theme = urlParams.get('theme')
    }
    
    this.state = {
        Theme: theme
    };


    
  }

  render() {
    let headerTheme = this.state.Theme || this.props.UserTheme
    return (
      <Row className={`bg-headbar-iframe  marg-0 pad5 justify-content-center text-center ${headerTheme === 'light' ? 'lightmode' : 'darkmode'}`}>
        <Col
          lg={12}
          sm={12}
          xs={12}
          md={12}
          xl={12}
          className={` text-center padl3 padright0`}
        >
          <LogoIframe />
          
          
        </Col>

        
      </Row>
    );
  }
}

const mapStateToApp = (state) => {
  return {
    UserTheme: state.app_preferences.preferences.user_interface.Theme,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToApp, mapDispatchToProps)(HeadbarIframe);
