import React, { Component } from 'react';
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import { APPPATH, TrendingMentionsHelper, PreferenceHandler } from '../../../modules/helper.module';
import * as preferencesActionCreator from '../../../actions/apppreferences.action';
import * as settingsActionCreators from '../../../actions/settings.action';
import * as tmActionCreators from '../../../actions/trendingmentions.action';
import { isMobile } from 'react-device-detect';

class Logmenu extends Component {
  constructor() {
    super();
    this.state = {
      logoutTip: false,
      searchToggler: true,
      userData: {},
      ismobile: false,
      theme: 'dark'
    };
    this.OverlayRef = React.createRef();
    this.OverlayRef1 = React.createRef();
    this.OverlayRef2 = React.createRef();
    this.OverlayRef3 = React.createRef();
    this.OverlayRef4 = React.createRef();
    this.OverlayRef5 = React.createRef();
    this.OverlayRef6 = React.createRef();
    this.OverlayRef7 = React.createRef();
    this.OverlayRef8 = React.createRef();
    this.OverlayRef9 = React.createRef();
    this.OverlayRef10 = React.createRef();
    this.OverlayRef11 = React.createRef();
    this.OverlayRef12 = React.createRef();
    this.OverlayRefNewTopCoin = React.createRef();
    this.OverlayRefMyRecentUpdates = React.createRef();
  }
  componentWillMount() {
    this.setState({
      ...this.state,
      logoutTip: this.props.logoutTip,
      searchToggler: this.props.searchToggler,
      userData: this.props.userData,
      ismobile: isMobile,
      theme: this.props.Theme
    });
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      ...this.state,
      logoutTip: nextProps.logoutTip,
      searchToggler: nextProps.searchToggler,
      userData: nextProps.userData,
      theme: nextProps.Theme,
      ismobile: isMobile,
    });
  }

  redirect = (e) => {
    let target = e.currentTarget.dataset.to;
    this.props.redirector(target);
  };

  RedirectToggleLogout = (e) => {
    switch (e.currentTarget.dataset.to) {
      case 'DOWNLOAD':
        // window.open('http://staging.crowdsense.ai/Files/CrowdSenseInstaller_V2_3.exe');
        this.props.toggleLogout();
        break;
      default:
        break;
    }
  };
  entering = (e) => {
    e.children[1].style.backgroundColor = '#37aaf8';
  };

  newTopcoins = () => {
    let { coin_column_preferences, tmDetailPreferences, columnlist } = this.props;
    let preferences = JSON.parse(JSON.stringify(this.props.preferences));
    if (preferences.user_interface.HasColumnOrderSettings === false) {
      preferences.user_interface.HasColumnOrderSettings = true;
    }
    let columnorderlist = JSON.parse(JSON.stringify(this.props.columnorderlist))
    let status = false;
    if (!coin_column_preferences.length) { status = true }
    // if(status) {
    let cc_prefs = JSON.parse(JSON.stringify(coin_column_preferences));
    let newPrefObj = TrendingMentionsHelper.newTopCoinPreference()
    if (!status) {
      cc_prefs.splice(0, 1);
      columnorderlist.splice(0, 1)
    } else {
      cc_prefs[cc_prefs.length] = newPrefObj;
      columnorderlist.unshift(newPrefObj)
    }
    let PreferenceStrings = PreferenceHandler.CreatePreferencesStrings(preferences);
    let CoinCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(cc_prefs);
    let CoinDetailCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(tmDetailPreferences, 'coindetails');
    let ColumnOrderStrings = PreferenceHandler.CreateColumnOrderString(columnorderlist)
    let newPrefStr = `${PreferenceStrings.userPrefData.UserPreferencesString}${CoinCoulmnStrings}${CoinDetailCoulmnStrings}${ColumnOrderStrings}`
    // console.log(newPrefStr);
    PreferenceStrings.userPrefData.UserPreferencesString = newPrefStr;
    PreferenceStrings.userPrefData.UserName = this.props.userData.username;
    if (status) {
      this.props.updatepinnedcoincolumn()
    } else {
      this.props.updateclosedpinnedcolumn()
    }
    if (typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser) {
      this.props.setUserPreferences_LLU(PreferenceStrings.userPrefData);
    } else {
      this.props.setUserPreferences(PreferenceStrings.userPrefData);
    }

    // }
    this.props.toggleLogout();

  }
  toggleRecentUpdates = (status) => {
    let preferences = { ...this.props.preferences };
    let { coin_column_preferences, tmDetailPreferences, columnlist } = this.props;
    let columnorderlist = JSON.parse(JSON.stringify(this.props.columnorderlist))
    if (preferences.user_interface.HasColumnOrderSettings === false) {
      preferences.user_interface.HasColumnOrderSettings = true;
    }
    if (status) {
      columnorderlist.push({
        columntype: 'Main Feeds'
      })
    } else {
      let removeAtIndex = columnlist.indexOf('Main Feeds')
      columnorderlist.splice(removeAtIndex, 1)
    }

    preferences.user_interface.RecentUpdateView = status;
    let PreferenceStrings = PreferenceHandler.CreatePreferencesStrings(preferences);
    let CoinCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(coin_column_preferences);
    let CoinDetailCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(tmDetailPreferences, 'coindetails');
    let ColumnOrderStrings = PreferenceHandler.CreateColumnOrderString(columnorderlist)
    Object.keys(PreferenceStrings).map((StrType) => {
      PreferenceStrings[`${StrType}`].UserName = this.props.userData.username;
      return null;
    });
    // console.log(PreferenceStrings.userPrefData.UserPreferencesString);
    PreferenceStrings.userPrefData.UserPreferencesString = `${PreferenceStrings.userPrefData.UserPreferencesString}${CoinCoulmnStrings}${CoinDetailCoulmnStrings}${ColumnOrderStrings}`
    window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
    window.Mixpanel.MixpanelProps.Category = 'User Interface';
    window.Mixpanel.MixpanelProps.RecentUpdateView = status;
    window.Mixpanel.actions.identify(this.props.userData.username);
    window.Mixpanel.actions.track(`Close Recent Updates Column`, window.Mixpanel.MixpanelProps).then((data) => {
      window.Mixpanel.actions.people.set({
        UserName: this.props.userData.username,
      });
      window.Mixpanel.actions.resetProp('Category');
      window.Mixpanel.actions.resetProp('RecentUpdateView');
    });
    if (status) {
      this.props.updatepinnedmainfeeds()
    } else {
      this.props.updateclosedpinnedcolumn()
    }
    if (typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser) {
      this.props.setUserPreferences_LLU(PreferenceStrings.userPrefData);
    } else {
      this.props.setUserPreferences(PreferenceStrings.userPrefData);
    }
    // this.props.switchcolumnfocus('Main Feeds')
    this.props.toggleLogout();//
  }

  themechange = () => {
    this.setState({
      ...this.state,
      theme: this.state.theme === 'dark' ? 'light' : 'dark'
    }, () => {
      this.props.closeTMWatchlistMenu(true);
      this.props.closeEventImportanceFilterMenu(true);
      let preferences = { ...this.props.preferences };
      let { coin_column_preferences, tmDetailPreferences, columnlist } = this.props;
      let columnorderlist = JSON.parse(JSON.stringify(this.props.columnorderlist))

      preferences.user_interface.Theme = this.state.theme;
      window.localStorage.setItem(`${APPPATH.AppUrl}${this.props.userData.username}Theme`, this.state.theme)

      let PreferenceStrings = PreferenceHandler.CreatePreferencesStrings(preferences);
      let CoinCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(coin_column_preferences);
      let CoinDetailCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(tmDetailPreferences, 'coindetails');
      let ColumnOrderStrings = PreferenceHandler.CreateColumnOrderString(columnorderlist)
      Object.keys(PreferenceStrings).map((StrType) => {
        PreferenceStrings[`${StrType}`].UserName = this.props.userData.username;
        return null;
      });
      // console.log(PreferenceStrings.userPrefData.UserPreferencesString);
      PreferenceStrings.userPrefData.UserPreferencesString = `${PreferenceStrings.userPrefData.UserPreferencesString}${CoinCoulmnStrings}${CoinDetailCoulmnStrings}${ColumnOrderStrings}`
      window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
      window.Mixpanel.MixpanelProps.Category = 'User Interface';
      window.Mixpanel.MixpanelProps.Theme = preferences.user_interface.Theme;
      window.Mixpanel.actions.identify(this.props.userData.username);
      window.Mixpanel.actions.track(`Close Recent Updates Column`, window.Mixpanel.MixpanelProps).then((data) => {
        window.Mixpanel.actions.people.set({
          UserName: this.props.userData.username,
        });
        window.Mixpanel.actions.resetProp('Category');
        window.Mixpanel.actions.resetProp('Theme');
      });
      if (typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser) {
        this.props.setUserPreferences_LLU(PreferenceStrings.userPrefData);
      } else {
        this.props.setUserPreferences(PreferenceStrings.userPrefData);
      }
    })

    // console.log(preferences)

  }

  render() {
    let ToolTipStyleData = {
      Arrow: {
        marginLeft: '15px',
      },
      ToolTip: {
        align: 'left',
      },
    };
    const urlSearchParams = new URLSearchParams(this.props.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let FromMobile = params && Object.keys(params).length !== 0 && Object.getPrototypeOf(params) === Object.prototype && typeof params.mobileapp !== 'undefined' && params.mobileapp === 'true';
    const { theme } = this.state;
    return (
      <div className="logout-tooltip" >
        <Row
          className={`marg-0 logout-tooltipMenu ${!this.state.logoutTip ? 'nodisp' : ''} ${!this.state.searchToggler ? 'searchon' : ''
            } `}
        >
          <Col xs={12} sm={12} md={12} xl={12} lg={12} className={`tipHead ${theme === 'light' ? 'lightmode' : ''}`}>
            <Row className="h-100 marg-0">
              <Col xs={1} sm={1} md={1} xl={1} lg={1} className="logout-usericon">
                <i className="fa fa-user-circle"></i>
              </Col>
              <Col className="logout-username">
                <span>
                  {
                    typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser ?
                      this.props.userData.loginlessuser : this.props.userData.username
                  }
                </span>
              </Col>
              <Col xs={1} sm={1} md={1} xl={1} lg={1} className="logout-close" onClick={(e) => { e.stopPropagation(); this.props.toggleLogout() }}>
                <i className="fa fa-times"></i>
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={12} md={12} xl={12} lg={12} className={`tipItem ${theme === 'light' ? 'lightmode' : ''}`}>
            <ul className="log-menu">

              <li>
                <div>
                  <i className="fa fa-picture-o log-menu-icon"></i>
                  <span className="log-menu-title">
                    Bright / Dark
                  </span>
                  <span className="float-right">
                    <label className="sec-switch theme" onClick={(e) => e.stopPropagation()}>
                      <input type="checkbox" checked={theme === 'dark' ? true : false} onChange={() => { this.themechange() }} value={1} />
                      <span className={`sec-slider theme  round`}></span>
                    </label>
                  </span>
                </div>
              </li>
              <li className=" menu-divider" ></li>
              {
                this.props.showtoptrending && (
                  <li>
                    <div
                      ref={this.OverlayRefNewTopCoin}
                      style={{ position: 'relative' }}
                      onMouseEnter={(e) =>
                        this.props.toggleOverlay(
                          true,
                          this.OverlayRefNewTopCoin,
                          'Add new Top Coin Column',
                          ToolTipStyleData
                        )
                      }
                      onMouseLeave={(e) => this.props.toggleOverlay(false)}
                      data-to="New-TopCoins"
                      onClick={(e) => { e.stopPropagation(); this.newTopcoins() }}
                    >
                      <i className="fa fa-server log-menu-icon"></i>
                      <span className="log-menu-title">
                        {
                          !this.props.coin_column_preferences.length ?
                            (this.props.userData.usertype !== 'None' && (this.props.userData.usertype !== 'WUser' && this.props.userData.usertype !== 'WAdmin')) ? 'Show Top Trending' : 'Show Top Trending'
                            : (this.props.userData.usertype !== 'None' && (this.props.userData.usertype !== 'WUser' && this.props.userData.usertype !== 'WAdmin')) ? 'Hide Top Trending' : 'Hide Top Trending'
                        }

                      </span>
                    </div>
                  </li>
                )
              }

              <li>
                <div
                  ref={this.OverlayRefMyRecentUpdates}
                  style={{ position: 'relative' }}
                  onMouseEnter={(e) =>
                    this.props.toggleOverlay(
                      true,
                      this.OverlayRefMyRecentUpdates,
                      'Show My Recent Updates',
                      ToolTipStyleData
                    )
                  }
                  onMouseLeave={(e) => this.props.toggleOverlay(false)}
                  data-to="New-TopCoins"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (this.props.HasColumnOrderSettings) {
                      this.toggleRecentUpdates(this.props.RecentUpdateView && this.props.mainfeedcolumn.length ? false : true)
                    } else {
                      this.toggleRecentUpdates(this.props.RecentUpdateView ? false : true)
                    }

                  }
                  }
                >
                  <i className="fa fa-th-list log-menu-icon"></i>
                  {
                    this.props.HasColumnOrderSettings ? (
                      <span className="log-menu-title">
                        {
                          this.props.RecentUpdateView && this.props.mainfeedcolumn.length ? 'Hide Smart Inbox' : 'Show Smart Inbox'
                          // this.props.RecentUpdateView ? 'Hide Updates Column' : 'Show Updates Column'
                        }
                      </span>
                    ) : (
                      <span className="log-menu-title">
                        {
                          this.props.RecentUpdateView ? 'Hide Smart Inbox' : 'Show Smart Inbox'
                          // this.props.RecentUpdateView ? 'Hide Updates Column' : 'Show Updates Column'
                        }
                      </span>
                    )
                  }

                </div>
              </li>
              {
                typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser ? ('') : (
                  <li className=" menu-divider" ></li>
                )
              }

              {
                typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser ? ('') : (
                  <li>
                    <div
                      ref={this.OverlayRef10}
                      style={{ position: 'relative' }}
                      onMouseEnter={(e) =>
                        this.props.toggleOverlay(
                          true,
                          this.OverlayRef10,
                          'Welcome Settings',
                          ToolTipStyleData
                        )
                      }
                      onMouseLeave={(e) => this.props.toggleOverlay(false)}
                      data-to="Welocme-Settings"
                      onClick={(e) => {
                        e.stopPropagation();
                        this.props.welcomemodeon(true);
                        this.props.showWelcomeScreen();
                        this.props.toggleLogout();
                      }}
                    >
                      <i className="fa fa-flag-checkered log-menu-icon"></i>
                      <span className="log-menu-title">Welcome Settings</span>
                    </div>
                  </li>
                )
              }

              {
                typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser ? ('') :
                  !FromMobile && isMobile ? (
                    <li className="">
                      <a
                        rel="noopener noreferrer"
                        href="https://play.google.com/store/apps/details?id=com.crowdsense.xamarin_app1&hl=en-US&ah=PGxIjINWj-Kl_TQnl3aw2xw66Q8"
                        data-to="PrivacyPolicy"
                        target="_blank"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (FromMobile) {
                            window.location.href = "https://play.google.com/store/apps/details?id=com.crowdsense.xamarin_app1&hl=en-US&ah=PGxIjINWj-Kl_TQnl3aw2xw66Q8";
                          } else {
                            window.open("https://play.google.com/store/apps/details?id=com.crowdsense.xamarin_app1&hl=en-US&ah=PGxIjINWj-Kl_TQnl3aw2xw66Q8", '_blank');
                          }
                          this.props.toggleLogout()
                        }}
                        ref={this.OverlayRef11}
                        style={{ position: 'relative' }}
                        onMouseEnter={(e) =>
                          this.props.toggleOverlay(true, this.OverlayRef11, 'Get mobile app from play store', ToolTipStyleData)
                        }
                        onMouseLeave={(e) => this.props.toggleOverlay(false)}
                      >
                        <i className="fa fa-th-large log-menu-icon"></i>
                        <span className="log-menu-title">Install/Run App</span>
                      </a>
                    </li>
                  ) : ('')
              }
              {
                typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser ? ('') :
                  this.state.userData.userPackage.toLowerCase() === 'bronze' ? (
                    <li className="">
                      <a
                        rel="noopener noreferrer"
                        // href="https://www.crowdsense.ai/payment"
                        data-to="PrivacyPolicy"
                        // target="_blank"
                        onClick={(e) => {
                          e.stopPropagation();
                          if (FromMobile) {
                            window.location.href = "https://www.crowdsense.ai/payment";
                          } else {
                            window.open("https://www.crowdsense.ai/payment", '_blank');
                          }
                          this.props.toggleLogout()
                        }}
                        ref={this.OverlayRef12}
                        style={{ position: 'relative' }}
                        onMouseEnter={(e) =>
                          this.props.toggleOverlay(true, this.OverlayRef12, 'Get all pricing plans', ToolTipStyleData)
                        }
                        onMouseLeave={(e) => this.props.toggleOverlay(false)}
                      >
                        {/* <i className="fa fa-usd log-menu-icon"></i> */}
                        <img src={`${APPPATH.ImageUrl}/badge-premium.svg`} className="menuimageiconmargin" alt="CrowdSense.ai" />
                        <span className="log-menu-title">Go Premium</span>
                      </a>
                    </li>
                  ) : ('')
              }
              {
                typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser ? ('') : (
                  <li className=" menu-divider" ></li>
                )
              }
              {
                typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser ? ('') : (
                  <li>
                    <div
                      ref={this.OverlayRef}
                      style={{ position: 'relative' }}
                      onMouseEnter={(e) =>
                        this.props.toggleOverlay(
                          true,
                          this.OverlayRef,
                          'Add or remove coins from your watchlist',
                          ToolTipStyleData
                        )
                      }
                      onMouseLeave={(e) => this.props.toggleOverlay(false)}
                      onClick={(e) => {
                        e.stopPropagation();
                        this.props.checkWatchList();
                        this.props.toggleLogout();
                      }}
                    >
                      <i className="fa fa-eye log-menu-icon"></i>
                      <span className="log-menu-title">Update Watchlist</span>
                    </div>
                  </li>
                )
              }
              {
                typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser ? ('') : (
                  <li>
                    <div
                      ref={this.OverlayRef2}
                      style={{ position: 'relative' }}
                      onMouseEnter={(e) =>
                        this.props.toggleOverlay(
                          true,
                          this.OverlayRef2,
                          'Choose the events to see news in your Crypto News Widget ',
                          ToolTipStyleData
                        )
                      }
                      onMouseLeave={(e) => this.props.toggleOverlay(false)}
                      onClick={(e) => {
                        e.stopPropagation();
                        this.props.EventTypeList();
                        this.props.toggleLogout();
                      }}
                    >
                      <i className="fa fa-podcast log-menu-icon"></i>
                      <span className="log-menu-title">Update Events Filter</span>
                    </div>
                  </li>
                )
              }
              {
                typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser ? ('') : (
                  <li>
                    <div
                      ref={this.OverlayRef3}
                      style={{ position: 'relative' }}
                      onMouseEnter={(e) =>
                        this.props.toggleOverlay(
                          true,
                          this.OverlayRef3,
                          'Change your User Details and Email Notifications',
                          ToolTipStyleData
                        )
                      }
                      onMouseLeave={(e) => this.props.toggleOverlay(false)}
                      data-to="User-Preferences"
                      onClick={(e) => {
                        e.stopPropagation();
                        this.props.PreferenceList();
                        this.props.toggleLogout();
                      }}
                    >
                      <i className="fa fa-linode log-menu-icon"></i>
                      <span className="log-menu-title">Setup Preferences</span>
                    </div>
                  </li>
                )
              }

              <li className=" menu-divider" ></li>
              {
                typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser ? ('') :
                  (this.props.userData.usertype === 'None' || this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin') ? (
                    <li>
                      <div
                        ref={this.OverlayRef1}
                        style={{ position: 'relative' }}
                        onMouseEnter={(e) =>
                          this.props.toggleOverlay(
                            true,
                            this.OverlayRef1,
                            'Watch all the Crowdsense Tutorial Videos',
                            ToolTipStyleData
                          )
                        }
                        onMouseLeave={(e) => this.props.toggleOverlay(false)}
                      >
                        <a
                          rel="noopener noreferrer"
                          href="https://www.youtube.com/channel/UCGxtWwi79fXLTy8mLAhsTJw"
                          data-to="FAQ"
                          target="_blank"
                          onClick={(e) => { e.stopPropagation(); this.props.toggleLogout() }}
                          className={`videoLink`}
                        >
                          <i className="fa fa-youtube-play log-menu-icon"></i>
                          <span className="log-menu-title">Help Videos</span>
                        </a>
                      </div>
                    </li>
                  ) : ('')
              }

              {
                // typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser ? ('') : 
                (this.props.userData.usertype === 'None' || this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin') ? (
                  <li className="">
                    <a
                      ref={this.OverlayRef5}
                      style={{ position: 'relative' }}
                      onMouseEnter={(e) =>
                        this.props.toggleOverlay(true, this.OverlayRef5, 'Frequently Asked Question', ToolTipStyleData)
                      }
                      onMouseLeave={(e) => this.props.toggleOverlay(false)}
                      rel="noopener noreferrer"
                      href={typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser ? 'https://www.algense.com/commonquestions' : 'https://www.crowdsense.ai/product-faq'}
                      data-to="FAQ"
                      target="_blank"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (FromMobile) {
                          window.location.href = `${typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser ? 'https://www.algense.com/commonquestions' : 'https://www.crowdsense.ai/product-faq'}`;
                        } else {
                          window.open(`${typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser ? 'https://www.algense.com/commonquestions' : 'https://www.crowdsense.ai/product-faq'}`, '_blank');
                        }
                        this.props.toggleLogout()
                      }}
                    >
                      <i className="fa fa-question log-menu-icon"></i>
                      <span className="log-menu-title">Common Questions</span>
                    </a>
                  </li>
                ) : ('')
              }
              {
                typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser ? ('') :
                  (this.props.userData.usertype === 'None' || this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin') ? (
                    <li className=" menu-divider" ></li>
                  ) : ('')
              }



              <li className="">
                <a
                  rel="noopener noreferrer"
                  href={this.props.userData.usertype === 'None' ? "https://www.crowdsense.ai/toc" : (this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin') ? "http://algense.com/toc" : "https://www.algense.com/toc"}
                  data-to="TermsAndConditions"
                  target="_blank"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (FromMobile) {
                      window.location.href = this.props.userData.usertype === 'None' ? "https://www.crowdsense.ai/toc" : (this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin') ? "http://algense.com/toc" : "https://www.algense.com/toc";
                    } else {
                      window.open(this.props.userData.usertype === 'None' ? "https://www.crowdsense.ai/toc" : (this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin') ? "http://algense.com/toc" : "https://www.algense.com/toc", '_blank');
                    }
                    this.props.toggleLogout()
                  }}
                  ref={this.OverlayRef6}
                  style={{ position: 'relative' }}
                  onMouseEnter={(e) =>
                    this.props.toggleOverlay(true, this.OverlayRef6, 'Check our Terms and Conditions', ToolTipStyleData)
                  }
                  onMouseLeave={(e) => this.props.toggleOverlay(false)}
                >
                  <i className="fa fa-trello log-menu-icon"></i>
                  <span className="log-menu-title">Terms & Conditions</span>
                </a>
              </li>
              {
                typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser ? ('') : (
                  <li className="">
                    <a
                      rel="noopener noreferrer"
                      href={this.props.userData.usertype === 'None' ? "https://www.crowdsense.ai/privacy" : (this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin') ? "https://www.algense.com/privacy" : "https://www.algense.com/privacy"}
                      data-to="PrivacyPolicy"
                      target="_blank"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (FromMobile) {
                          window.location.href = this.props.userData.usertype === 'None' ? "https://www.crowdsense.ai/privacy" : (this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin') ? "https://www.algense.com/privacy" : "https://www.algense.com/privacy";
                        } else {
                          window.open(this.props.userData.usertype === 'None' ? "https://www.crowdsense.ai/privacy" : (this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin') ? "https://www.algense.com/privacy" : "https://www.algense.com/privacy", '_blank');
                        }
                        this.props.toggleLogout()
                      }}
                      ref={this.OverlayRef7}
                      style={{ position: 'relative' }}
                      onMouseEnter={(e) =>
                        this.props.toggleOverlay(true, this.OverlayRef7, 'Check our Privacy Policy', ToolTipStyleData)
                      }
                      onMouseLeave={(e) => this.props.toggleOverlay(false)}
                    >
                      <i className="fa fa-rub log-menu-icon"></i>
                      <span className="log-menu-title">Privacy Policy</span>
                    </a>
                  </li>
                )
              }
              {
                typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser ? ('') : (
                  <li className=" menu-divider" ></li>
                )
              }


              {/* <li className={`padtb5 ${this.state.ismobile ? 'nodisp' : ''}`}>
                <a href={`${APPPATH.DesktopAppUrl}/CrowdSenseSetup.msi`} data-to='DOWNLOAD'  onClick={this.RedirectToggleLogout} rel="noopener noreferrer" download>
                  <i className="fa fa-bolt log-menu-icon"></i>
                  <span className="log-menu-title">Install Desktop App</span>
                </a>
              </li> */}
              {
                typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser ? ('') : (
                  <li>
                    <div
                      ref={this.OverlayRef8}
                      style={{ position: 'relative' }}
                      onMouseEnter={(e) =>
                        this.props.toggleOverlay(true, this.OverlayRef8, 'Change your Password', ToolTipStyleData)
                      }
                      onMouseLeave={(e) => this.props.toggleOverlay(false)}
                      data-to="Change-Password"
                      onClick={(e) => { e.stopPropagation(); this.redirect() }}
                    >
                      <i className="fa fa-unlock-alt log-menu-icon "></i>
                      <span className="log-menu-title">Change Password</span>
                    </div>
                  </li>
                )
              }

              <li>
                <div
                  ref={this.OverlayRef4}
                  style={{ position: 'relative' }}
                  onMouseEnter={(e) =>
                    this.props.toggleOverlay(
                      true,
                      this.OverlayRef4,
                      'Please share your feedback or idea with us',
                      ToolTipStyleData
                    )
                  }
                  onMouseLeave={(e) => this.props.toggleOverlay(false)}
                  data-to="Feedbacks"
                  onClick={(e) => { e.stopPropagation(); this.redirect() }}
                >
                  <i className="fa fa-wpforms log-menu-icon"></i>
                  <span className="log-menu-title">Send Feedback</span>
                </div>
              </li>
              {
                typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser ? ('') : (
                  <li>
                    <div
                      ref={this.OverlayRef9}
                      style={{ position: 'relative' }}
                      onMouseEnter={(e) => this.props.toggleOverlay(true, this.OverlayRef9, 'Sign out', ToolTipStyleData)}
                      onMouseLeave={(e) => this.props.toggleOverlay(false)}
                      data-to="Change-Password"
                      // onClick={this.redirect}
                      onClick={(e) => { e.stopPropagation(); this.props.logout() }}
                    >
                      <i className="fa fa-sign-out log-menu-icon"></i>
                      <span className="log-menu-title">Sign Out</span>
                    </div>
                  </li>
                )
              }

            </ul>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapDispatchToProps = {
  ...preferencesActionCreator,
  ...settingsActionCreators,
  ...tmActionCreators
}
const mapStateToApp = (state) => {
  return {
    userData: state.settings.userData,
    columnorderlist: state.settings.columnorderlist,
    columnlist: state.settings.columnlist,
    preferences: state.app_preferences.preferences,
    Theme: state.app_preferences.preferences.user_interface.Theme,
    coin_column_preferences: state.trendingMentions.coin_column_preferences,
    tmDetailPreferences: state.trendingMentions.tmDetailPreferences,
    RecentUpdateView: state.app_preferences.preferences.user_interface.RecentUpdateView,
    HasColumnOrderSettings: state.app_preferences.preferences.user_interface.HasColumnOrderSettings,
    mainfeedcolumn: state.settings.columnorderlist.filter(itm => itm.columntype === 'Main Feeds').filter(itm => (itm)),
    showtoptrending: state.settings.showtoptrending
  };
};

export default connect(mapStateToApp, mapDispatchToProps)(Logmenu);
