import React, { useState, useEffect, useRef } from 'react';

import MiniChart from '../../components/shared/echarts/miniChart';
import Relationline from '../shared/echarts/templates/relationlineforCoinCol';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getChartFeedFromTicker } from '../../actions/chart.action';
import { getCoinDetailColumnChartFeedFromTicker } from '../../actions/trendingmentions.action';
import { APPPATH } from '../../modules/helper.module';

function GraphInCoinCol({ ...props }) {
  // console.log(props)
  const dispatch = useDispatch();
  const [ChartOptions, setChartOptions] = useState({});
  const [ChartMinifyMode, setChartMinifyMode] = useState(false);
  const [ChartReqMode, setChartReqMode] = useState(true);
  const [HideChart, setHideChart] = useState(false);
  let Chartfeeds = useSelector(({ charts, trendingMentions }) => {
    if(props.tdecktype === 'tmcd') {
      if(props.SecurityName !== null) {
        return trendingMentions.tmDetailPreferencesDataStore[`${props.SecurityName}${props.SourceFilter}`].ChartFeeds
      } else {
        return [];
      }
      
    }else if(props.tdecktype === 'tmd') {
      return charts.ChartFeeds
    }
    
  });

  useEffect(() => {
    setChartMinifyMode(true);
    setChartReqMode(true);
    setHideChart(false);
    if(props.tdecktype === 'tmcd') {
      // console.log(props.data.TM.SecurityName)
      if(typeof props.data.TM.SecurityName !== 'undefined') {
        dispatch(getCoinDetailColumnChartFeedFromTicker(props.data.TM.SecurityName, props.data.templateType, props.coinColumnIndex, props.SecurityName, props.SourceFilter));
      }
    } else if(props.tdecktype === 'tmd') {
      dispatch(getChartFeedFromTicker(props.data.TM.SecurityName, props.data.templateType));
    }
    
  }, [props.data.templateType, props.data.TM.SecurityName]);
  useEffect(() => {
    // console.log(Chartfeeds)
    if(Chartfeeds.length) {
      setChartOptions(
        Relationline(
          'CoinSaveTime',
          'SecurityPrice',
          props.data.templateType,
          props.data.TM,
          Chartfeeds,
          ChartMinifyMode,
          props.userRole,
          props.Theme
        )
      );
      setTimeout(() => {
        setChartReqMode(false);
        setHideChart(false);
      }, 2000);
    } else {
      setTimeout(() => {
        setChartReqMode(false);
        setHideChart(true);
      }, 2000);
    }
    
    
  }, [Chartfeeds]);
  if(!HideChart) {
    return (
      <div style={{ width: '100%', height: '50%' }} className="graph-content justify-content-center text-center">
        <span style={{ float: 'left' }}>
          {/* <span style={{ color: '#50C0FF', margin: 10 }}>
            <span className="blue-rectangle">
              {props.data.templateType === 'Sentiment' && (
                <img className="tickDet-icon" src={`${APPPATH.ImageUrl}/icon-positive-selected-blue.svg`} alt="" />
              )}
              {props.data.templateType === 'Mentions' && (
                <img className="tickDet-icon" src={`${APPPATH.ImageUrl}/icon-trending-selected-blue.svg`} alt="" />
              )}
              {props.data.templateType === 'Followers' && (
                <img className="tickDet-icon" src={`${APPPATH.ImageUrl}/icon-mentions-selected-blue.svg`} alt="" />
              )}
            </span>
  
            {props.data.templateType === 'Followers' ? 'Reach' : props.data.templateType}
          </span>
          <span style={{ fontWeight: 'bold' }} className="black-rectangle">
            -
          </span> */}
          {/* {props.userRole.toLowerCase() === 'admin' && (
            <span style={{ color: '#D797F5', margin: 10 }}>
              <span className="purple-rectangle">
                <img className="tickDet-icon" src={`${APPPATH.ImageUrl}/icon_price-selected-min-purple.svg`} alt="" />
              </span>
              {'Price'}
            </span>
          )} */}
        </span>
        <span style={{ float: 'right' }}>
          <button className="tooltip-button" onClick={() => props.showGraphPopup()}>
            <span className="graphbtn" style={{ fontSize: 12 }}>
              <i className="fa fa-external-link"></i> <span >Open Chart</span>
            </span>
          </button>
        </span>
        <Row style={{ width: '109%', marginLeft: -19 }} className="marg-0 ">
          {ChartReqMode && (
            <Col lg={12} sm={12} xs={12} md={12} xl={12}>
              <div className="" style={{ height: '90px' }}>
                <i
                  className="fa fa-spinner fa-2x fa-spin storyloadindicator align-self-center"
                  style={{ marginTop: '4%' }}
                ></i>
              </div>
            </Col>
          )}
          {!ChartReqMode && (
            <Col lg={12} sm={12} xs={12} md={12} xl={12} className="" >
              <MiniChart options={ChartOptions} />
            </Col>
          )}
        </Row>
      </div>
    );
  } else {
    return '';
  }
  
}

export default GraphInCoinCol;
