export const AppUrl = `${window.location.protocol}//${window.location.hostname}${window.location.hostname === 'localhost' ? ':3000' : ''}`;
export const ImageUrl = `${window.location.protocol}//${window.location.host}/assets/images`;

export const NotifUrl = `${window.location.protocol}//${window.location.hostname}${window.location.hostname === 'localhost' ? ':3000' : ''}/assets/notification-sounds`;

export const DesktopAppUrl = `${window.location.protocol}//${window.location.hostname}${window.location.hostname === 'localhost' ? ':3000' : ''}/assets/downloads/Desktop_App`;

export const CoinIconUrl = 'https://coiniconsbyname.s3.eu-west-2.amazonaws.com';
// export const CoinIconUrl = 'https://coinsicons.s3.eu-west-2.amazonaws.com'
export const ExchangeIconUrl = 'https://exchangesicons.s3.eu-west-2.amazonaws.com';

export const premiumPaymentUrl = 'https://www.crowdsense.ai/payment'
