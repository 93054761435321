import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as tmActionCreators from '../../actions/trendingmentions.action';
import TMDetailsColumnContextHandle from './tmdetailscolumnupdater';
import * as moment from 'moment';

class TMDetailsHandleWrap extends Component {
  constructor(props) {
    super();
    this.state = {
        cc_preferences: props.coindetail_column_preferences
    }
    this.tmupdaterHandle = [];
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      cc_preferences: nextProps.coindetail_column_preferences
    };
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log(prevState.cc_preferences, this.state.cc_preferences)
    if(prevState.cc_preferences.length !== this.state.cc_preferences.length) {
        this.tmupdaterHandle = [];
        this.state.cc_preferences.forEach((itm,i) => {
            this.tmupdaterHandle.push(React.createRef());
        })
        if(this.state.cc_preferences.length > prevState.cc_preferences.length) {
          // console.log(this.state.cc_preferences)
          this.props.loadtickerdata(this.state.cc_preferences[0].SecurityName, this.state.cc_preferences[0].SecurityTicker, this.state.cc_preferences[0].sourceFilter, 0);
          let searchData = {
            sec : [`${this.state.cc_preferences[0].SecurityName}`],
            startDate : moment().utcOffset(0).subtract(7,'days').format('YYYY-MM-DD HH:mm:ss'),
            endDate : moment().utcOffset(0).format('YYYY-MM-DD HH:mm:ss'),
            withCurated : this.state.cc_preferences[0].CDSearch_with_gray,
            withBlack : this.state.cc_preferences[0].CDSearch_with_black,
            withWhite : this.state.cc_preferences[0].CDSearch_with_white,
            withYellow : this.state.cc_preferences[0].CDSearch_with_yellow,
            withOrange : this.state.cc_preferences[0].CDSearch_with_orange,
            withRed : this.state.cc_preferences[0].CDSearch_with_red,
            sourceFilters: this.state.cc_preferences[0].sourceFilter ? this.state.cc_preferences[0].sourceFilter : '',
          }
          this.props.searchCoinDetailTickerEvents(searchData, 0, this.state.cc_preferences[0].SecurityName);
        }
    }
    
  }

  render() {
    const { cc_preferences } = this.state;
    // console.log(cc_preferences)
    let updaterArr = [];
    cc_preferences.forEach((prefObj, i) => {
        updaterArr.push(
            <TMDetailsColumnContextHandle 
                showModal={this.props.showModal} 
                urlParams={this.props.urlParams} 
                ref={this.tmupdaterHandle[i]} 
                userData={this.props.userData}
                columnindex={i}
                SecurityName={prefObj.SecurityName}
                SecurityTicker={prefObj.SecurityTicker}
                Search_with_gray={prefObj.CDSearch_with_gray}
                Search_with_black={prefObj.CDSearch_with_black}
                Search_with_white={prefObj.CDSearch_with_white}
                Search_with_yellow={prefObj.CDSearch_with_yellow}
                Search_with_orange={prefObj.CDSearch_with_orange}
                Search_with_red={prefObj.CDSearch_with_red}
                sourceFilters={prefObj.sourceFilter ? prefObj.sourceFilter : ''}
                key={`detailsupdaterhandle${i}`}
            />
        )
    })
    return (<>{updaterArr}</>);
  }
}

const mapStateToComponent = (state) => {
    
    return {
      coindetail_column_preferences: state.trendingMentions.tmDetailPreferences
    };
  };
  const mapDispatchToProps = {
    ...tmActionCreators,
  };
  
export default connect(mapStateToComponent, mapDispatchToProps, null, { forwardRef: true })(TMDetailsHandleWrap);