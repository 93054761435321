export const watchlistLevels = [
  {
    count: 10,
    text: 'Add Top Forex',
    type: 'forex'
  },{
    count: 10,
    text: 'Add Top Crypto',
    type: 'crypto'
  },{
    count: 10,
    text: 'Add Top Stocks',
    type: 'stocks'
  },{
    count: 10,
    text: 'Add Top Commodities',
    type: 'commodities'
  },{
    count: 10,
    text: 'Add Top Indices',
    type: 'indices'
  },
  // {
  //   count: 20,
  //   text: 'Add Top 20 Crypto',
  //   type: 'crypto'
  // },
  // {
  //   count: 50,
  //   text: 'Add Top 50 Crypto',
  //   type: 'crypto'
  // },
  // {
  //   count: 100,
  //   text: 'Add Top 100 Crypto',
  //   type: 'crypto'
  // },
  {
    count: 0,
    text: 'Remove all',
    type: ''
  },  
];
