import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { APPPATH } from '../../../modules/helper.module';
// import PremiumButton from '../headbar/PremiumButton';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';

class MoreWatchlist extends Component {
  constructor() {
    super();
    this.state = {
      show: true,
      clientWidth:  document.documentElement.clientWidth
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
  }

  closeBanner = () => {
    this.setState({ ...this.state, show: false });
    //update the setting showPremiumBanner to false

    // setTimeout(() => {
    //   this.props.closeMobileAppInstallBanner();
    // }, 2);
  };

  
  resize = () => {
    this.setState({
      ...this.state,
      clientWidth: document.documentElement.clientWidth
    })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  render() {
    // let hasAppInstalled = this.props.appInstalledData.length ? true : false;
    // console.log(isMobile && (document.documentElement.clientWidth < 350))
    let smallScreenCondition = isMobile && (document.documentElement.clientWidth < 350)
    return (
        <Row 
            className={`marg-0 text-left ${!this.state.show ? 'nodisp' : ''}`} 
            style={{backgroundColor: 'rgb(207, 221, 255)',color: 'rgb(0, 0, 0)', margin: '5px 0px', borderRadius: '5px', border: '1px solid transparent', padding: '12px 0px'}}
        >
        
            
            <Col lg={8} sm={7} xs={7} md={7} xl={8} className=" align-self-center">
              {
                smallScreenCondition ? (
                  <div className="bannerspan1 fs14 fs13-sm">
                    <span style={{ fontSize: 13, fontWeight: 'bold', paddingLeft: 0, marginBottom: '0px !important' }}>No more top coins from my watchlist. To add more coins</span>
                    {/* <span> Click Here</span> */}
                </div>
                ) : (
                  <div className="bannerspan1 fs14 fs13-sm">
                    <span  style={{ fontSize: 13, fontWeight: 'bold', paddingLeft: 0, marginBottom: '0px !important' }}>No more top coins from my watchlist. To add more coins</span>
                    {/* <span> Click Here</span> */}
                </div>
                  // <>
                  //   <div className="bannerspan1 fs14 fs13-sm">Switch to our mobile app</div>
                  //   <div className="bannerspan1 fs14 fs13-sm">for faster experience</div>
                  //   <div className="bannerspan1 fs14 fs13-sm">and personalized alerts</div>
                  // </>
                )
              }
              
              
            </Col> 
            <Col lg={4} sm={5} xs={5} md={5} xl={4} className="text-left  align-self-center">
              {
               
                <span className='btn tryforfree-btn' style={{padding: '5px'}} onClick={() => this.props.showModal('WatchList')}>
                    Click Here
                  
                </span>
              }
                
            </Col>
            {/* <div className={` pointer sm-loco`} style={{position: 'absolute'}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 16.458 15.828" onClick={this.closeBanner}>
                    <g transform="translate(1.414 1.414)">
                    <path
                        className="a"
                        d="M1560.6,103.5l-13.63,13"
                        transform="translate(-1546.97 -103.5)"
                        fill="none"
                        stroke={'#000'}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2px"
                    />
                    <g transform="translate(-1546.97 -103.5)">
                        <path
                        className="a"
                        d="M1546.97,103.5l13.63,13"
                        fill="none"
                        stroke={'#000'}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2px"
                        />
                    </g>
                    </g>
                </svg>
            </div> */}
              {/* <span>
                <img
                    src={`${APPPATH.ImageUrl}/applestore.png`}
                    className={`mobileAppButtonImage`}
                    alt="CrowdSense.ai"
                />
              </span> */}
            
      
        
      </Row>
      
    );
  }
}


const mapStateToApp = (state) => {
  return {
    // appInstalledData: state.settings.installedAppData,
  };
};

const mapDispatchToProps = {
  
};

export default connect(mapStateToApp, mapDispatchToProps)(MoreWatchlist);
