import React, { Component } from 'react'; 
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import * as logActionCreator from '../../actions/logAction.action';

import Headbar from '../shared/headbar/headbar';

class ErrorGlobalBoundary extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      hasError: false,
      logType : 'Error'
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: error ? true : false };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if(prevState.hasError !== nextProps.general_error) {
      return { hasError: nextProps.general_error };
    }
    return null;
  }

  componentDidCatch(error, info) {
    console.log(info, error)
    this.HandleErrorProcedure(error);
  }

  componentDidUpdate(prevProps, prevState) {
    
    if(!prevState.hasError && this.state.hasError) {
     this.HandleErrorProcedure(this.props.appError);
    } else if(prevState.hasError && !this.state.hasError) {
      this.props.history.push('/');
    }
  }

  redirecthome = (e) => {
    this.props.resetAppError()
    
  }

  HandleErrorProcedure = (incomingError) => {
    const ErrorData = {
      type : this.state.logType
    };
    try {
      let TError
      try {
        // console.log(incomingError, this.props.errorData)
        TError = incomingError.toString().split(':');
        ErrorData.name = TError[0];
        ErrorData.Message = incomingError.toString();
        // ErrorData.stackTrace = info.componentStack;
      }
      catch(ex) {
        ErrorData.name = ex.name;
        ErrorData.Message = ex.message;
        // ErrorData.stackTrace = ex.stack;
      }
    } catch(error) {
      ErrorData.name = error.name;
      ErrorData.Message = error.message;
      // ErrorData.stackTrace = error.stack;
    } finally {
      if(this.props.userData.username !== '') {
        window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
        window.Mixpanel.MixpanelProps.ErrorName = ErrorData.name;
        window.Mixpanel.MixpanelProps.ErrorMessage = ErrorData.Message;
        window.Mixpanel.MixpanelProps.Enviornment = process.env.REACT_APP_ENV;
        window.Mixpanel.actions.identify(this.props.userData.username);
        window.Mixpanel.actions.track('Error Log', window.Mixpanel.MixpanelProps).then(data => {
          window.Mixpanel.actions.people.set({
            UserName: this.props.userData.username
          });
        })
        ErrorData.UserName = this.props.userData.username;
        this.props.addLogAction(ErrorData);
      } else if(this.props.errorData.username !== ''){
        window.Mixpanel.MixpanelProps.UserName = this.props.errorData.username;
        window.Mixpanel.MixpanelProps.ErrorName = ErrorData.name;
        window.Mixpanel.MixpanelProps.ErrorMessage = ErrorData.Message;
        window.Mixpanel.MixpanelProps.Enviornment = process.env.REACT_APP_ENV;
        window.Mixpanel.actions.identify(this.props.errorData.username);
        window.Mixpanel.actions.track('Error Log', window.Mixpanel.MixpanelProps).then(data => {
          window.Mixpanel.actions.people.set({
            UserName: this.props.errorData.username
          });
        })
      }
      

    }
  }

  render() {
    if(this.props.fallbackError) {
      return (
        <Row className="justify-content-center text-center">
          <Col lg={9} sm={12} xs={12} md={9} xl={9}  >
            <div className="auth-wrap">
            
              <Headbar page={'error'} {...this.props} />
              <Row className="justify-content-center text-center marg-0 error-wrap">
                <Col lg={12} sm={12} xs={12} md={12} xl={12} className="" >
                  <i className="fa fa-wrench fa-2x pad15 float-left"></i>
                  <span className="error-head-title">Oops.. we are at work !</span>
                </Col>
                <Col lg={12} sm={12} xs={12} md={12} xl={12} className="padl25 text-left" >
                  <div className="pad5">
                    { this.props.appError }
                  </div>
                </Col>
                
              </Row>
            </div>
          </Col>
        </Row>
      )
    } else {
      if (this.state.hasError && (process.env.REACT_APP_ENV === 'development' || process.env.REACT_APP_ENV === 'qa')) {
        if(this.props.errorData.severity === 'high') {
          return (
            <Row className="justify-content-center text-center">
              <Col lg={9} sm={12} xs={12} md={9} xl={9}  >
                <div className="auth-wrap">
                
                  <Headbar page={'error'} {...this.props} />
                  <Row className="justify-content-center text-center marg-0 error-wrap">
                    <Col lg={12} sm={12} xs={12} md={12} xl={12} className="" >
                      <i className="fa fa-wrench fa-2x pad15 float-left"></i>
                      <span className="error-head-title">Oops.. we are at work !</span>
                    </Col>
                    <Col lg={12} sm={12} xs={12} md={12} xl={12} className="padl25 text-left" >
                      <div className="">
                        { this.props.appError }
                      </div>
                    </Col>
                    <Col lg={12} sm={12} xs={12} md={12} xl={12} className="" >
                      
                      <button type="button" className="btn btn-error-redirect error-refresh" onClick={this.redirecthome}>
                        Click continue while we handle it.
                      </button>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          )
        }
      } else if (this.state.hasError && (process.env.REACT_APP_ENV === 'production' ||  process.env.REACT_APP_ENV === 'staging')) {
        if(Object.keys(this.props.errorData).length && this.props.errorData.severity === 'high') {
          return (
            <Row className="justify-content-center text-center">
              <Col lg={9} sm={12} xs={12} md={9} xl={9}  >
                <div className="auth-wrap">
                
                  <Headbar page={'error'} {...this.props} />
                  <Row className="justify-content-center text-center marg-0 error-wrap">
                    <Col lg={12} sm={12} xs={12} md={12} xl={12} className="" >
                      <i className="fa fa-wrench fa-2x pad15 float-left"></i>
                      <span className="error-head-title">Oops.. we are at work !</span>
                    </Col>
                    <Col lg={12} sm={12} xs={12} md={12} xl={12} className="padl25 text-left" >
                      <div className="">
                        { this.props.appError }
                      </div>
                    </Col>
                    <Col lg={12} sm={12} xs={12} md={12} xl={12} className="" >
                      
                      <button type="button" className="btn btn-error-redirect error-refresh" onClick={this.redirecthome}>
                        Click continue while we handle it.
                      </button>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          )
        } else {
          // return this.props.children;
        }
        // this.redirecthome()
    }
    

    }

    return this.props.children; 
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.settings.userData,
    general_error : state.error.general,
    appError: state.error.appError,
    fallbackError: state.error.fallbackError,
    errorData: state.error.data
  }
}

export default withRouter(connect(mapStateToProps, logActionCreator)(ErrorGlobalBoundary))