import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { APPPATH } from '../../modules/helper.module';

class EachSecurity extends Component {
  constructor() {
    super();
    this.state = {
      hovered :false,
      sectooltip: false
    }
  }
  hover = () => {
    this.setState({
      ...this.state,
      hovered :!this.state.hovered
    })
  }
  hoverClick = () => {
    this.setState({
      ...this.state,
      hovered :false
    },() => {
      this.props.handleCollapse()
    })
  }

  toggleToolTip = (stat) => {
    this.setState({
      ...this.state,
      sectooltip: stat
    })
  }
  render() {
    const { secData } = this.props;
    // console.log(secData)
    let title = secData.Security ? secData.Security : secData.SecutiryType
    let symbol = secData.Symbol ? `(${secData.Symbol})` : ``
    let dsec1 = secData.Link ? (<a rel="noopener noreferrer" target="_blank" href={`${secData.Link}`} class="sec-symbol-link" onMouseEnter={() => this.toggleToolTip(true)} onMouseLeave={() => this.toggleToolTip(false)} onPointerOut={() => this.toggleToolTip(false)} >{`${title}${symbol}`}</a>) :  (<div className="sec-symbol-link passive" onMouseEnter={() => this.toggleToolTip(true)} onMouseLeave={() => this.toggleToolTip(false)} onPointerOut={() => this.toggleToolTip(false)} >{`${title}${symbol}`}</div>)
    

    let secEachUI = this.props.clickable ? (
      <Row className=" marg-0 security-detail-item nopad"  >
        <Col lg={1} sm={1} xs={1} md={1} xl={1} className=" feed-security-image text-left" onClick={this.hoverClick} onMouseEnter={this.hover} onMouseLeave={this.hover} >
          
              
              {
                secData.Impact === 'Direct'? (
                  <span className=""><img src={`${APPPATH.ImageUrl}/fullcircle.png`} className="timelineIndicator " alt="CrowdSense.ai"  /></span>
                ) : secData.Impact === 'Indirect'? (
                  <span className=""><img src={`${APPPATH.ImageUrl}/halfcircle.png`} className="timelineIndicator " alt="CrowdSense.ai" /></span>
                ) : (
                  <span className=""></span>
                )
              }
           
          
        </Col>
        <Col lg={9} sm={9} xs={9} md={9} xl={9} className="feed-security-list nopad " onClick={this.hoverClick}  >
       
              {/* <div className="sec-detail" onMouseEnter={() => this.toggleToolTip(true)} onMouseLeave={() => this.toggleToolTip(false)} onPointerOut={() => this.toggleToolTip(false)} dangerouslySetInnerHTML={{__html : display}} /> */}
              {
                  secData.Description ? (
                    <div className="sec-detail">
                      { dsec1 }
                      <div className="gen-tooltip top eachsec" >
                        <div className={`gentooltipMenu ${!this.state.sectooltip ? 'nodisp': ''} md-lg-screen`}>
                          { secData.Description }
                        </div>
                      </div>
                    </div>
                  ):(
                    <div className="sec-detail">
                      { dsec1 }
                    </div>
                  )
                }

        </Col>
          
        <Col lg={2} sm={2} xs={2} md={2} xl={2} className="feed-security-impact " onClick={this.hoverClick}  >
        </Col>
        
      </Row>
    ) : (
      <Row className=" marg-0 security-detail-item bg-sec-det  nopad" >
        <Col lg={1} sm={1} xs={1} md={1} xl={1} className=" feed-security-image nopointer text-left"  >
          
            
            {
              secData.Impact === 'Direct'? (
                <span className=""><img src={`${APPPATH.ImageUrl}/circle_full.svg`} className="timelineIndicator " alt="CrowdSense.ai"  /></span>
              ) : secData.Impact === 'Indirect'? (
                <span className=""><img src={`${APPPATH.ImageUrl}/circle_half.svg`} className="timelineIndicator " alt="CrowdSense.ai" /></span>
              ) : (
                <span className=""></span>
              )
            }
           
          
        </Col>
        <Col lg={9} sm={9} xs={9} md={9} xl={9} className="feed-security-list nopad nopointer "  >
       
              {/* <div className="sec-detail" onMouseEnter={() => this.toggleToolTip(true)} onMouseLeave={() => this.toggleToolTip(false)} onPointerOut={() => this.toggleToolTip(false)} dangerouslySetInnerHTML={{__html : display}} /> */}
              
                {
                  secData.Description ? (
                    <div className="sec-detail">
                      { dsec1 }
                      <div className="gen-tooltip top eachsec" >
                        <div className={`gentooltipMenu ${!this.state.sectooltip ? 'nodisp': ''} md-lg-screen`}>
                          { secData.Description }
                        </div>
                      </div>
                    </div>
                  ):(
                    <div className="sec-detail">
                      { dsec1 }
                    </div>
                  )
                }
              
        </Col>
          
        <Col lg={2} sm={2} xs={2} md={2} xl={2} className="text-justify feed-security-impact nopointer "  >
        </Col>
        
      </Row>
    )
    return (
      <Col lg={12} sm={12} xs={12} md={12} xl={12} className="story-feed-security nopad"  >
      { 
        secEachUI
      }
      </Col>
    )
  }
}
export default EachSecurity