import React, { Component } from 'react'; 
import { Row, Col } from 'react-bootstrap';
import './crinput.css';



class CRInput extends Component {
  constructor() {
    super();
    this.state = {
      value: '',
      type: '',
      placeholder: '',
      readonly: false,
      valueError: false,
      unsavedStatus: false,
      pristine: true
    }
    
  }

  componentWillMount() {
    this.inputstate(this.props)
  }
  componentWillReceiveProps(nextProps) {
    this.inputstate(nextProps)
  }
  

 inputstate = (data) => {
   
    this.setState({
      ...this.state,
      value: data.value,
      type: data.type,
      placeholder: data.placeholder,
      readonly: data.readonly
    })
 }

 getRegexPatterns = (key) => {
    let pattern = {
      email: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    }
    if(pattern[key]) {
      return pattern[key]
    }
    return false;
 }

 validate = () => {
  const { value, type } = this.state;
  let res = {
    error: false
  }
  let mailRegex = this.getRegexPatterns(type);
  if(!value.match(mailRegex)) {
    res.error = true
  }
  return res;
 }

  

 

  handleInput = (e) => {
    let input = e.target.value;    
    this.setState({
      ...this.state,
      value: input,
      unsavedStatus: input === this.props.value ? false : true,
      pristine: false
    },() => {
      let validated = this.validate();
      this.setState({
        ...this.state,
        valueError: validated.error
      })
    })
  }

  saveValue = () => {
    const { value, valueError } = this.state
    if(!valueError) {
      this.setState({
        ...this.state,
        unsavedStatus: false,
      },() => {
        this.props.inputAction(value)
      })
      
    }
    
  }

  

  render() {
    const { value, type, placeholder, readonly, unsavedStatus, pristine } = this.state;
    
    return (
      <Row>
        
        <Col lg={12} sm={12} xs={12} md={12} xl={12} className="">
          <input type={`${type}`} name={`${type}`} id={`${type}`} value={value} placeholder={placeholder} className="form-control cpinput" onChange={this.handleInput} readOnly={readonly} />
          <i className={`fa ${unsavedStatus ? 'fa-floppy-o' : 'fa-check-circle-o'} save-icon ${!pristine ? '' : 'nodisp'} `} onClick={this.saveValue}></i>
          <span className={`hasError ${this.state.valueError ? '' : 'nodisp'}`}>
            Please enter a valid {`${type}`}
          </span>
        </Col>
      </Row>
    )
  }
}

export default CRInput