import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { APPPATH } from '../../../modules/helper.module';

class PremiumButton extends Component {
  constructor() {
    super();
    this.OverlayRef = React.createRef();
  }
  render() {
    const { noTitle, align, beta = true } = this.props;
    return (
      <div className={`logo-wrap premium-icon   ${align ? 'fleft' : ''}`}>
        <div className="logo-link margin-for-btn-logo-in-header premium-tag">
          <span>
            <img src={`${APPPATH.ImageUrl}/badge-premium.svg`} className="logo" alt="CrowdSense.ai" />
          </span>
        </div>
      </div>
    );
  }
}
export default PremiumButton;
