import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
// import Tooltip from '../shared/tooltip/generaltooltip';
import * as appSettingsActionCreators from '../../actions/settings.action';
import { APPPATH } from '../../modules/helper.module';

class LikeEvent extends Component {
  constructor(props) {
    super();

    this.state = {
      feedBack: Object.keys(props.userFeedback).length && parseInt(props.userFeedback.Like) === 1 ? true : false,
      like: props.story ? parseInt(props.story.NumberOfLikes) : 0,
    };
    this.OverlayTarget = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    // if(nextProps.userFeedback !== {} && parseInt(nextProps.userFeedback.Like) === 1) {
    //    console.log(nextProps.story.NumberOfLikes, this.state.like)
    // }
    if (nextProps.story) {
      this.setState({
        ...this.state,
        feedBack: Object.keys(nextProps.userFeedback).length && parseInt(nextProps.userFeedback.Like) === 1 ? true : false,
        like:
          parseInt(nextProps.userFeedback.Like) === 1 && parseInt(nextProps.story.NumberOfLikes) < this.state.like
            ? nextProps.story.NumberOfLikes + 1
            : nextProps.story.NumberOfLikes,
      });
    }
  }

  setFeedBack = (actionType = false) => {
    // console.log(this.props)
    let { like } = this.state;
    let feedBackData = {};
    if (actionType) {
      // like = like + 1;
      feedBackData = {
        UserName: this.props.userData.username,
        Vote: 1,
        EventID: this.props.storyID,
        Categories: '',
        OtherMessage: '',
      };
    } else {
      // like = like - 1;
      feedBackData = {
        UserName: this.props.userData.username,
        Vote: 0,
        EventID: this.props.storyID,
        Categories: '',
        OtherMessage: '',
      };
    }
    this.setState(
      {
        feedBack: actionType,
        // like
      },
      () => {
        this.props.setEventFeedBackWithReason(feedBackData, this.props.SecurityName);

        window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
        window.Mixpanel.MixpanelProps.EventId = this.props.storyID;
        window.Mixpanel.MixpanelProps.Headline = this.props.story.HeadlineText;
        window.Mixpanel.MixpanelProps.Vote = feedBackData.Vote;
        window.Mixpanel.actions.identify(this.props.userData.username);
        window.Mixpanel.actions.track(`Event Liked`, window.Mixpanel.MixpanelProps).then((data) => {
          window.Mixpanel.actions.people.set({
            UserName: this.props.userData.username,
          });
          window.Mixpanel.actions.resetProp('EventId');
          window.Mixpanel.actions.resetProp('Headline');
          window.Mixpanel.actions.resetProp('Vote');
        });
      }
    );
  };
  render() {
    const { feedBack, like } = this.state;
    // if(this.props.userFeedback) {
    //   console.log(this.props.userFeedback, this.props.story)
    // }
    
    return (
      <div className="dispinb">
        <div className="dispinb">
          <span
            style={{ position: 'relative', top: "1px", left: '0px' }}
            ref={this.OverlayTarget}
            onMouseEnter={(e) => {
              let ToolTipStyleData = {
                Arrow: {
                  marginLeft: '10px',
                },
                ToolTip: {
                  align: 'left',
                },
              };
              this.props.toggleOverlay(true, this.OverlayTarget, 'Click if you like this event', ToolTipStyleData);
            }}
            onMouseLeave={(e) => {
              this.props.toggleOverlay(false);
            }}
            onClick={() => this.setFeedBack(!feedBack)}
          >
            <i
              className={`fa ${feedBack ? 'fa-thumbs-up' : 'fa-thumbs-o-up'} fs17`}
              style={{ color: feedBack ? '#02A702' : '#6F7987' }}
            />
          </span>
        </div>
        {/* {like > 0 && (
          <span
            className="fs12 dispinb"
            style={{
              // position: 'absolute',
              // top: 6,
              // right: '37%',
              color: feedBack ? '#02A702' : '#b3b3b3',
              marginLeft: '10px'
            }}
          >
            {like}
          </span>
        )} */}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let feedBacks = state.settings.userFeedbacks;
  // console.log(ownProps.storyID)
  let EventFeedBack = {};
  if (feedBacks.length > 0) {
    feedBacks.forEach((fb) => {
      if (fb.EventID === ownProps.storyID) {
        EventFeedBack = fb;
        // console.log(EventFeedBack)
      }
    });
  }
  return {
    userData: state.settings.userData,
    userFeedback: EventFeedBack,
  };
};

const mapDispatchToProps = {
  ...appSettingsActionCreators,
};

export default connect(mapStateToProps, mapDispatchToProps)(LikeEvent);
