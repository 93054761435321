import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as appSettingsActionCreators from '../../actions/settings.action';
// import Tooltip from '../shared/tooltip/generaltooltip';
import { APPPATH } from '../../modules/helper.module';

class StoryMessage extends Component {
  constructor() {
    super();
    this.state = {
      orgusers: [],
      tooltip: false,
      popuptip: false,
      selectedUsers: [],
      FeedId: '',
      messageTipHandle: false,
    };
    this.ttHandle = '';
  }
  componentWillMount() {
    this.setState({
      ...this.state,
      orgusers: this.props.OrgUsers,
      FeedId: this.props.FeedId,
      messageTipHandle: this.props.messageToolTipHandler,
    });
  }
  componentWillReceiveProps(nextProps) {
    let poptip = false;
    if (this.props.messageToolTipHandler !== nextProps.messageToolTipHandler) {
      if (!nextProps.messageToolTipHandler || nextProps.messageToolTipHandler !== nextProps.FeedId) {
        poptip = false;
        if (this.state.popuptip) {
          this.setState({
            ...this.state,
            popuptip: poptip,
            messageTipHandle: nextProps.messageToolTipHandler,
          });
        }
      } else {
        window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
        window.Mixpanel.MixpanelProps.EventId = this.props.FeedId;
        window.Mixpanel.MixpanelProps.Headline = this.props.HeadlineText;
        window.Mixpanel.actions.identify(this.props.userData.username);
        window.Mixpanel.actions.track(`Send Message Initiated`, window.Mixpanel.MixpanelProps).then((data) => {
          window.Mixpanel.actions.people.set({
            UserName: this.props.userData.username,
          });
          window.Mixpanel.actions.resetProp('EventId');
          window.Mixpanel.actions.resetProp('Headline');
        });
      }
    } else {
      this.setState({
        ...this.state,
        orgusers: nextProps.OrgUsers,
        FeedId: nextProps.FeedId,
      });
    }
  }
  toggleTipClicked = () => {
    let popTip = !this.state.popuptip;
    if (this.ttHandle) {
      clearTimeout(this.ttHandle);
    }
    this.setState(
      {
        ...this.state,
        popuptip: !this.state.popuptip,
        tooltip: false,
      },
      () => {
        this.props.modalClose();
        if (popTip) {
          this.props.tipHandle(popTip, this.props.FeedId, 'messageToolTipHandler');
        }
      }
    );
  };
  toggletip = (event) => {
    event.preventDefault();
    if (!this.props.EventReadStatusMain) {
      this.props.storyClicked().then(() => {
        this.toggleTipClicked();
      });
    } else {
      this.toggleTipClicked();
    }
  };
  toggletooltip = (status) => {
    const { popuptip } = this.state;
    if (!popuptip) {
      if (this.ttHandle) {
        clearTimeout(this.ttHandle);
      }
      if (status) {
        this.ttHandle = setTimeout(() => {
          this.setState(
            {
              ...this.state,
              tooltip: status,
            },
            () => {
              // if(status) {
              window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
              window.Mixpanel.MixpanelProps.EventId = this.props.FeedId;
              window.Mixpanel.MixpanelProps.Headline = this.props.HeadlineText;
              window.Mixpanel.MixpanelProps.ToolTipType = 'Message Notification';
              window.Mixpanel.actions.identify(this.props.userData.username);
              window.Mixpanel.actions.track(`Tooltip shown`, window.Mixpanel.MixpanelProps).then((data) => {
                window.Mixpanel.actions.people.set({
                  UserName: this.props.userData.username,
                });
                window.Mixpanel.actions.resetProp('EventId');
                window.Mixpanel.actions.resetProp('Headline');
                window.Mixpanel.actions.resetProp('ToolTipType');
              });
              // }
            }
          );
        }, 1500);
      } else {
        this.setState({
          ...this.state,
          tooltip: status,
        });
      }
    }
  };
  selectOrgUsers = (user) => {
    let newSelectedUsers = this.state.selectedUsers;
    if (newSelectedUsers.indexOf(user) > -1) {
      newSelectedUsers.splice(newSelectedUsers.indexOf(user), 1);
    } else {
      newSelectedUsers.push(user);
    }
    this.setState({
      ...this.state,
      selectedUsers: newSelectedUsers,
    });
  };
  confirmmessageAction = () => {
    let userString = this.state.selectedUsers.join(';');
    let FeedId = this.props.FeedId;
    let data = {
      UserName: this.props.userData.username,
      EventID: FeedId,
      UsersList: userString,
    };
    window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
    window.Mixpanel.MixpanelProps.EventId = FeedId;
    window.Mixpanel.MixpanelProps.UserList = this.state.selectedUsers.join(',');
    window.Mixpanel.actions.identify(this.props.userData.username);
    window.Mixpanel.actions.track('Sending Message', window.Mixpanel.MixpanelProps).then((data) => {
      window.Mixpanel.actions.people.set({
        UserName: this.props.userData.username,
      });
      window.Mixpanel.actions.resetProp('EventId');
      window.Mixpanel.actions.resetProp('UserList');
    });
    this.props.sendMessage(data);
    this.setState(
      {
        ...this.state,
        selectedUsers: [],
        tooltip: false,
        popuptip: false,
      },
      () => {
        this.props.modalClose();
      }
    );
  };
  render() {
    let tiplist = this.state.orgusers.length
      ? this.state.orgusers.map((usr, i) => {
          return (
            <Col xs={2} sm={2} md={2} xl={2} lg={2} className="avatarItem" key={i}>
              <div className="avatarImageWrap">
                <img
                  src={`${APPPATH.ImageUrl}/defaultavatar1.svg`}
                  className="letteravatar pointer"
                  onClick={() => this.selectOrgUsers(usr)}
                  alt=""
                />
                {this.state.selectedUsers.indexOf(usr) > -1 && (
                  <span className="avatarmask" onClick={() => this.selectOrgUsers(usr)}>
                    <img src={`${APPPATH.ImageUrl}/oktickicon.svg`} className="avatarmaskImage" alt="" />
                  </span>
                )}
              </div>
              <div>
                <span className="wrapword"> {usr}</span>
              </div>
              {/* <Row className="marg-0" key={i}>
              <Col xs={1} sm={1} md={1} xl={1} lg={1} className="align-self-center " >
                <input type="checkbox" value={usr} checked={this.state.selectedUsers.indexOf(usr) > -1 ? true:false } onChange={()=>this.selectOrgUsers(usr)} />
              </Col>
              <Col xs={10} sm={10} md={10} xl={10} lg={10} className="align-self-center pad5 text-left" >
                <span className="wrapword"> { usr }</span>
              </Col>
            </Row> */}
            </Col>
          );
        })
      : '';
    return (
      <Row className={`marg-0 padlr25 padb15`}>
        <Col xs={12} sm={12} md={12} xl={12} lg={12} className="muteunmute-tipItem">
          <Row className="marg-0">
            <Col className="storyOptionTitle">
              <span>Share with your colleagues</span>
            </Col>
          </Row>
          <Row className={`marg-0 avatarWrap`}>{tiplist}</Row>
        </Col>
        <Col xs={12} sm={12} md={12} xl={12} lg={12} className="muteunmute-tipItem padright10">
          <button
            type="button"
            className="btn btn-crowdsense-prime float-right marglr5"
            onClick={this.confirmmessageAction}
          >
            Confirm
          </button>
          <button
            type="button"
            className="btn btn-crowdsense-border float-right marglr5"
            onClick={this.toggleTipClicked}
          >
            Cancel
          </button>
        </Col>
      </Row>

      //     </div>
      //   </Row>
      // </Col>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.settings.userData,
    OrgUsers: state.settings.OrgUsers,
    messageToolTipHandler: state.settings.toolTipGlobals.messageToolTipHandler,
  };
};

const mapDispatchToProps = {
  ...appSettingsActionCreators,
};

export default connect(mapStateToProps, mapDispatchToProps)(StoryMessage);
