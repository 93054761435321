import React, { Component } from 'react';

class GooglePopupAds extends Component {

    constructor(props) {
        super();
        this.state = {
            SlotId : props.SlotId
        }
    }

    componentDidMount() {
        setTimeout(() => {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        }, 1000);
        // setTimeout(() => {
        //     this.props.resize();
        //   }, 2000);
    }
    // componentDidUpdate() {
    //     setTimeout(() => {
    //         this.props.resize();
    //       }, 2000);
    // }

    render() {
        const { SlotId } = this.state;
        return (
            
            <ins className="adsbygoogle"
                style={{display:'block'}}
                data-ad-client="ca-pub-8523528160500226"
                data-ad-slot={SlotId}
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
        );
    }

}

export default GooglePopupAds