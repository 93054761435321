import React, { Component } from 'react';

class InFeedAd extends Component {

    constructor(props) {
        super();
        this.state = {
            SlotId : props.SlotId,
            LayoutKey: props.LayoutKey,
            count: props.count
        }
        // this.props.resize();
    }

    componentDidMount() {
        try {
            (window.adsbygoogle = window.adsbygoogle || []).push({}) 
            
        } catch(ex) {
            console.log(ex);
        }
        window.addEventListener("message", this.adsenseMessagebroadcast)
        
    }
    adsenseMessagebroadcast =  (event) => {
        const { SlotId, count } = this.state;
        let El = document.getElementById(`${SlotId}${count}`);
        
        if(typeof El.dataset.adStatus !== 'undefined' && El.dataset.adStatus !== 'filled') {
            // console.log(El.parentNode)
            El.parentNode.style.display = 'none';
            
        }
        if(this.props.resizeColumn) {
            // setTimeout(() => {
                this.props.resizeHeightFromAdSlots();
            // },200)
            
        }

    }

    componentWillUnmount() {
        window.removeEventListener('message', this.adsenseMessagebroadcast);
    }

    render() {
        const { SlotId, LayoutKey, count } = this.state;
        return (
            <div  className='story-card-ad' >
                <ins className="adsbygoogle"
                    id={`${SlotId}${count}`}
                    style={{display:'block'}}
                    data-ad-format="fluid"
                    data-ad-layout-key={LayoutKey}
                    data-ad-client="ca-pub-8523528160500226"
                    data-ad-slot={SlotId}>
                </ins>
            </div>
        );
    }

}

export default InFeedAd