import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { APPPATH } from '../../modules/helper.module';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';

class TweetDeckColumnBody extends Component {
  constructor(props) {
    super();
    this.state = {};
  }

  render() {
    // console.log(this.props.homeviewport.height)
    // let TMHeightCorrector = isMobile ? 165 : 165
    let tmheightcorrection = !this.props.isPro ? 33 : 0
    return (
      <div
        className={`${this.props.Theme === 'light' ? 'lightmode' : ''} ${this.props.ColType !== 'tmd' && this.props.ColType !== 'tmd' ? 'cScroll' : ''} ${isMobile ? 'native' : ''} ${this.props.render === 'iframe' ? 'iframe' : ''} ${this.props.ColType === 'tm' ? 'tm' : ''} ${this.props.lang && this.props.lang === 'ara' ? 'arabic' : ''} `}
        style={{
          // height: `${this.props.ColType === 'tm' ? this.props.homeviewport.height  - 130
          //     : this.props.ColType === 'tmd' ? 
          //         this.props.coinDetailsCollapse ? 
          //             this.props.homeviewport.height - 90
          //         : this.props.TickerData.CoinDescription !== null ? 
          //             this.props.homeviewport.height - 300
          //         : this.props.homeviewport.height - 200
          //     : this.props.homeviewport.height - 50
          // }px`,
          height: `${this.props.ColType === 'tm' ? this.props.homeviewport.height - (190 - tmheightcorrection)
            : this.props.ColType === 'tmd' || (this.props.ColType === 'tmcd' && this.props.showtoptrending) ?
              'auto'
              : this.props.render === 'iframe' && this.props.iframelogo ? this.props.homeviewport.height - 75 : this.props.render === 'iframe' && !this.props.iframelogo ? this.props.homeviewport.height - 7 : this.props.homeviewport.height - 50
            }px`,
          minHeight: this.props.ColType === 'tm' ? this.props.homeviewport.height - (190 - tmheightcorrection) : this.props.render === 'iframe' && this.props.iframelogo ? this.props.homeviewport.height - 75 : this.props.render === 'iframe' && !this.props.iframelogo ? this.props.homeviewport.height - 7 : this.props.homeviewport.height - 50,
          overflow: this.props.ColType === 'tmd' || (this.props.ColType === 'tmcd' && this.props.showtoptrending) ? 'hidden' : 'auto',
          backgroundColor: this.props.ColType === 'tm' ? '#13181e' : '',
          paddingLeft: this.props.ColType === 'tm' ? 10 : '',
        }}
        onScroll={this.props.handleScroll}
        id={`${this.props.ColType}CScroll`}
      >

        {this.props.children}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {

  return {
    Theme: state.app_preferences.preferences.user_interface.Theme,
    render: state.settings.render,
    iframelogo: state.settings.iframelogo,
    lang: state.settings.lang,
    showtoptrending: state.settings.showtoptrending
  };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(TweetDeckColumnBody);
