import { Component } from 'react';
import { connect } from 'react-redux';
import * as tmActionCreators from '../../actions/trendingmentions.action';
import { UpdateInitialColumnLoadCounter } from '../../actions/settings.action';

class TMContextHandle extends Component {
  constructor(props) {
    super();
    this.state = {
      tmReqData: {
        InitialFeedRequest: '',
        LastFeedRequest: '',
        LastFeedRequestEnd: '',
        InitialFeedResponse: '',
        LastFeedResponse: '',
        RequestState: false,
      },
      securities: [],
      TMFrame: props.tmFrame,
      tmIndex: [],
      thisInstance: this,
      utcOffset: 0,
      mouseMoveOverTM: false,
      autoRefresh: props.autoRefresh,
      tm_autocall: props.tm_autocall,
      topcoinsinitialpreference: props.topcoinsinitialpreference,
      columnindex: props.columnindex,
      postponeupdate: props.postponeupdate
    };
    this.tmUpdateHandle = {};
    this.tmUpdateInterval = {};

    
    // const params = props.urlParams;
    // const flag = params && Object.keys(params).length === 0 && Object.getPrototypeOf(params) === Object.prototype;
    // if (!flag && params.SecurityName) {
    //   window.localStorage.setItem('SavedCoinDetail', JSON.stringify(params));
    //   window.localStorage.setItem('CoinDetailColumnShowState', true);
    // }
  }

  componentDidMount() {
    this.GetTrendingMentions();
  }

  GetTrendingMentions = () => {
    // const { coin_column_preferences } = this.state;
    // let ReqdataArr = [];
    // coin_column_preferences.forEach((preference, i) => {
    //   const reqdata = {
    //     ...this.state.tmReqDataTemplate,
    //     RequestState: true,
    //   }
    //   ReqdataArr.push(reqdata)
    //   this.props.setTmReqData(reqdata, i);
    // })
    const reqData = {
      ...this.state.tmReqData,
      RequestState: true,
    };
    this.props.setTmReqData(reqData, this.state.columnindex);
    this.setState(
      {
        ...this.state,
        tmReqData: reqData,
      },() => {
        
          this.props.getTrendingMentions(this.state.columnindex);
        
        
      }
    );
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    // console.log(nextProps.tmFrame)
    let tmReqData = prevState.tmReqData;
    if (prevState.thisInstance.props.tmIndex !== nextProps.tmIndex) {
      tmReqData.RequestState = false;
    }
    return {
      tmIndex: nextProps.tmIndex,
      autoRefresh: nextProps.autoRefresh,
      TMFrame: nextProps.tmFrame,
      tmReqData,
      securities: nextProps.Securities,
      tm_autocall: nextProps.tm_autocall,
      topcoinsinitialpreference: nextProps.topcoinsinitialpreference,
      columnindex: nextProps.columnindex,
      postponeupdate: nextProps.postponeupdate
    };
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log(this.props.autoRefresh)
    if (prevState.tmIndex !== this.state.tmIndex  && !this.state.postponeupdate) {
      // console.log('here')
      // console.log(prevState.tmIndex, this.state.tmIndex)
      // console.log(prevState.tmIndex === this.state.tmIndex)
      clearTimeout(this.tmUpdateInterval);
      this.props.setTmReqData(this.state.tmReqData);
      this.updateTMData();
      if(!prevState.tmIndex.length && this.state.tmIndex.length) {
        // this.props.UpdateInitialColumnLoadCounter()
      }
    } else {
      if(prevState.postponeupdate !== this.state.postponeupdate && !this.state.postponeupdate) {
        clearTimeout(this.tmUpdateInterval);
        this.props.setTmReqData(this.state.tmReqData);
        this.updateTMData();
      } else if(prevState.postponeupdate !== this.state.postponeupdate && this.state.postponeupdate) {
        clearTimeout(this.tmUpdateInterval);
      }
    }
    
    // if (!prevState.topcoinsinitialpreference && this.state.topcoinsinitialpreference) {
    //   clearTimeout(this.tmUpdateInterval);
    //   this.GetTrendingMentions();
    // }
    if (prevState.tm_autocall !== this.state.tm_autocall) {
      // console.log('calling trending mentions')
      clearTimeout(this.tmUpdateInterval);
      this.GetTrendingMentions();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.tmUpdateInterval);
  }

  updateTMData = () => {
    // console.log('updateing timer')
    // console.log(this.state.autoRefresh)
    this.tmUpdateInterval = setTimeout(() => {
      // if (this.state.autoRefresh) {
        const reqData = {
          ...this.state.tmReqData,
          RequestState: true,
        };
        this.props.setTmReqData(reqData);
        this.setState(
          {
            ...this.state,
            tmReqData: reqData,
          },
          () => {
            this.updateTM();
          }
        );
      // }
    }, 60000);
  };

  updateTM = () => {
    // console.log('calling trending mentions update')
    this.props.updateTrendingMentions(this.state.columnindex);
  };

  render() {
    // console.log(this.state.tmIndex);
    return '';
  }
}

const mapStateToComponent = (state, ownProps) => {
  let ActiveTFrame = '';
  Object.keys(state.trendingMentions.tmFrame).map((frame) => {
    if (state.trendingMentions.tmFrame[frame]) {
      ActiveTFrame = frame;
    }
    return null;
  });
  let Securities = state.filters.securities;
  // Securities =
  //   Securities.length > 0
  //     ? Securities.sort((a, b) => {
  //         return parseFloat(b.Scope) - parseFloat(a.Scope);
  //       })
  //     : [];
  // console.log(typeof state.trendingMentions.coin_column_data[ownProps.columnindex])
  return {
    postponeupdate: state.settings.postponeupdate,
    tmIndex: typeof state.trendingMentions.coin_column_data[ownProps.columnindex] !== 'undefined' ? state.trendingMentions.coin_column_data[ownProps.columnindex].tmIndex : [],
    // tmIndex: state.trendingMentions.tmIndex,
    // autoRefresh: typeof state.trendingMentions.coin_column_data[ownProps.columnindex] !== 'undefined' ? state.trendingMentions.coin_column_data[ownProps.columnindex].autoRefresh : false,
    tmFrame: ActiveTFrame,
    Securities,
    // tm_autocall: state.trendingMentions.tm_autocall[ownProps.columnindex],
    topcoinsinitialpreference: state.trendingMentions.topcoinsinitialpreference,
    // coin_column_preferences: state.trendingMentions.coin_column_preferences
    // packageName: state.trendingMentions.packageName
    // req_error: state.error.rootFeeds
  };
};
const mapDispatchToProps = {
  ...tmActionCreators,
  UpdateInitialColumnLoadCounter
};

export default connect(mapStateToComponent, mapDispatchToProps, null, { forwardRef: true })(TMContextHandle);
