import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as preferencesActionCreators from '../actions/apppreferences.action';
import PreferenceCategory from '../components/preferences/preferencecategory';

class Userpreferences extends Component {
  constructor() {
    super();
    this.state = {
      preferences: {},
    };
  }

  componentWillMount() {
    // console.log(this.props.preferences)
    this.setState({
      ...this.state,
      preferences: this.props.preferences,
    });
  }

  componentDidMount() {
    window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
    window.Mixpanel.actions.identify(this.props.userData.username);
    window.Mixpanel.actions.track(`Opened Preferences`, window.Mixpanel.MixpanelProps).then((data) => {
      window.Mixpanel.actions.people.set({
        UserName: this.props.userData.username,
      });
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState(
      {
        ...this.state,
        preferences: nextProps.preferences,
      },
      () => {
        // console.log(this.state.preferences)
      }
    );
  }
  set_event_importance_filters = (category, title, value) => {
    let preferences = this.state.preferences;
    let CategoryList;
    switch (category) {
      case 'Red_Colored_Events':
        CategoryList = Object.keys(preferences.event_importance_filters[category]);
        break;
      case 'Yellow_Colored_Events':
        CategoryList = Object.keys(preferences.event_importance_filters[category]);
        break;
      case 'Gray_Colored_Events':
        CategoryList = Object.keys(preferences.event_importance_filters[category]);
        break;
      case 'Non_Active_Curated_Events':
        CategoryList = Object.keys(preferences.event_importance_filters[category]);
        break;
      default:
        CategoryList = Object.keys(preferences[category]);
        break;
    }
    // let CategoryList = Object.keys(preferences[category]);
    // console.log(category, CategoryList, title, value)
    if (CategoryList.indexOf(title) > -1) {
      CategoryList.forEach((cat, i) => {
        switch (title) {
          case 'Show_Gray':
            if (cat === title) {
              preferences.event_importance_filters[category][title] = value;
              if (!value) {
                preferences.event_importance_filters[category].Desktop_Notifications_Gray = value;
                preferences.event_importance_filters[category].Desktop_Notify_Breaking_Only_Gray = value;
                preferences.event_importance_filters[category].Email_Notifications_Gray = value;
                preferences.event_importance_filters[category].Email_Notify_Breaking_Only_Gray = value;
                preferences.event_importance_filters[category].SMS_Notifications_Gray = value;
                preferences.event_importance_filters[category].Speech_Notifications_Gray = value;
              }
            }
            break;
          case 'Show_Yellow':
            if (cat === title) {
              preferences.event_importance_filters[category][title] = value;
              if (!value) {
                preferences.event_importance_filters[category].Desktop_Notifications_Yellow = value;
                preferences.event_importance_filters[category].Desktop_Notify_Breaking_Only_Yellow = value;
                preferences.event_importance_filters[category].Email_Notifications_Yellow = value;
                preferences.event_importance_filters[category].Email_Notify_Breaking_Only_Yellow = value;
                preferences.event_importance_filters[category].SMS_Notifications_Yellow = value;
                preferences.event_importance_filters[category].Speech_Notifications_Yellow = value;
              }
            }
            break;
          case 'Show_Red':
            if (cat === title) {
              preferences.event_importance_filters[category][title] = value;
              if (!value) {
                preferences.event_importance_filters[category].Desktop_Notifications_Red = value;
                preferences.event_importance_filters[category].Desktop_Notify_Breaking_Only_Red = value;
                preferences.event_importance_filters[category].Email_Notifications_Red = value;
                preferences.event_importance_filters[category].Email_Notify_Breaking_Only_Red = value;
                preferences.event_importance_filters[category].SMS_Notifications_Red = value;
                preferences.event_importance_filters[category].Speech_Notifications_Red = value;
              }
            }
            break;
          case 'Show_NACE':
            if (cat === title) {
              preferences.event_importance_filters[category][title] = value;
            }
            break;
          case 'Desktop_Notifications_NACE':
            if (cat === title) {
              preferences.event_importance_filters[category][title] = value;
            }
            break;
          case 'Desktop_Notifications_Gray':
            if (cat === title) {
              if (value) {
                preferences.event_importance_filters[category].Show_Gray = value;
                preferences.event_importance_filters[category][title] = value;
              } else {
                preferences.event_importance_filters[category][title] = value;
                preferences.event_importance_filters[category].Desktop_Notify_Breaking_Only_Gray = value;
              }
            }
            break;
          case 'Desktop_Notifications_Yellow':
            if (cat === title) {
              if (value) {
                preferences.event_importance_filters[category].Show_Yellow = value;
                preferences.event_importance_filters[category][title] = value;
              } else {
                preferences.event_importance_filters[category][title] = value;
                preferences.event_importance_filters[category].Desktop_Notify_Breaking_Only_Yellow = value;
              }
            }
            break;
          case 'Desktop_Notifications_Red':
            if (cat === title) {
              if (value) {
                preferences.event_importance_filters[category].Show_Red = value;
                preferences.event_importance_filters[category][title] = value;
              } else {
                preferences.event_importance_filters[category][title] = value;
                preferences.event_importance_filters[category].Desktop_Notify_Breaking_Only_Red = value;
              }
            }
            break;
          case 'Email_Notifications_Gray':
            if (cat === title) {
              if (value) {
                preferences.event_importance_filters[category].Show_Gray = value;
                preferences.event_importance_filters[category][title] = value;
              } else {
                preferences.event_importance_filters[category][title] = value;
                preferences.event_importance_filters[category].Email_Notify_Breaking_Only_Gray = value;
              }
            }
            break;
          case 'Email_Notifications_Yellow':
            if (cat === title) {
              if (value) {
                preferences.event_importance_filters[category].Show_Yellow = value;
                preferences.event_importance_filters[category][title] = value;
              } else {
                preferences.event_importance_filters[category][title] = value;
                preferences.event_importance_filters[category].Email_Notify_Breaking_Only_Yellow = value;
              }
            }
            break;
          case 'Email_Notifications_Red':
            if (cat === title) {
              if (value) {
                preferences.event_importance_filters[category].Show_Red = value;
                preferences.event_importance_filters[category][title] = value;
              } else {
                preferences.event_importance_filters[category][title] = value;
                preferences.event_importance_filters[category].Email_Notify_Breaking_Only_Red = value;
              }
            }
            break;
          case 'Desktop_Notify_Breaking_Only_Gray':
            if (cat === title) {
              if (value) {
                preferences.event_importance_filters[category].Show_Gray = value;
                // preferences.event_importance_filters[category].Desktop_Notifications_Gray = value;
                preferences.event_importance_filters[category][title] = value;
              } else {
                preferences.event_importance_filters[category][title] = value;
              }
            }
            break;
          case 'Desktop_Notify_Breaking_Only_Yellow':
            if (cat === title) {
              if (value) {
                preferences.event_importance_filters[category].Show_Yellow = value;
                // preferences.event_importance_filters[category].Desktop_Notifications_Yellow = value;
                preferences.event_importance_filters[category][title] = value;
              } else {
                preferences.event_importance_filters[category][title] = value;
              }
            }
            break;
          case 'Desktop_Notify_Breaking_Only_Red':
            if (cat === title) {
              if (value) {
                preferences.event_importance_filters[category].Show_Red = value;
                // preferences.event_importance_filters[category].Desktop_Notifications_Red = value;
                preferences.event_importance_filters[category][title] = value;
              } else {
                preferences.event_importance_filters[category][title] = value;
              }
            }
            break;
          case 'Email_Notify_Breaking_Only_Red':
            if (cat === title) {
              if (value) {
                preferences.event_importance_filters[category].Show_Red = value;
                // preferences.event_importance_filters[category].Desktop_Notifications_Red = value;
                preferences.event_importance_filters[category][title] = value;
              } else {
                preferences.event_importance_filters[category][title] = value;
              }
            }
            break;
          case 'Email_Notify_Breaking_Only_Yellow':
            if (cat === title) {
              if (value) {
                preferences.event_importance_filters[category].Show_Yellow = value;
                // preferences.event_importance_filters[category].Desktop_Notifications_Red = value;
                preferences.event_importance_filters[category][title] = value;
              } else {
                preferences.event_importance_filters[category][title] = value;
              }
            }
            break;
          case 'Email_Notify_Breaking_Only_Gray':
            if (cat === title) {
              if (value) {
                preferences.event_importance_filters[category].Show_Gray = value;
                // preferences.event_importance_filters[category].Desktop_Notifications_Red = value;
                preferences.event_importance_filters[category][title] = value;
              } else {
                preferences.event_importance_filters[category][title] = value;
              }
            }
            break;
          case 'SMS_Notifications_Red':
            if (cat === title) {
              if (value) {
                preferences.event_importance_filters[category].Show_Red = value;
                preferences.event_importance_filters[category][title] = value;
              } else {
                preferences.event_importance_filters[category][title] = value;
              }
            }
            break;
          case 'SMS_Notifications_Yellow':
            if (cat === title) {
              if (value) {
                preferences.event_importance_filters[category].Show_Yellow = value;
                preferences.event_importance_filters[category][title] = value;
              } else {
                preferences.event_importance_filters[category][title] = value;
              }
            }
            break;
          case 'SMS_Notifications_Gray':
            if (cat === title) {
              if (value) {
                preferences.event_importance_filters[category].Show_Gray = value;
                preferences.event_importance_filters[category][title] = value;
              } else {
                preferences.event_importance_filters[category][title] = value;
              }
            }
            break;
          case 'Speech_Notifications_Red':
            if (cat === title) {
              if (value) {
                preferences.event_importance_filters[category].Show_Red = value;
                preferences.event_importance_filters[category][title] = value;
              } else {
                preferences.event_importance_filters[category][title] = value;
              }
            }
            break;
          case 'Speech_Notifications_Yellow':
            if (cat === title) {
              if (value) {
                preferences.event_importance_filters[category].Show_Yellow = value;
                preferences.event_importance_filters[category][title] = value;
              } else {
                preferences.event_importance_filters[category][title] = value;
              }
            }
            break;
          case 'Speech_Notifications_Gray':
            if (cat === title) {
              if (value) {
                preferences.event_importance_filters[category].Show_Gray = value;
                preferences.event_importance_filters[category][title] = value;
              } else {
                preferences.event_importance_filters[category][title] = value;
              }
            }
            break;
          case 'Mainstream_News':
            if (cat === title) {
              preferences[category][cat] = value;
            }
            break;
          case 'Local_News':
            if (cat === title) {
              preferences[category][cat] = value;
            }
            break;
          case 'Securities_Line':
            if (cat === title) {
              preferences[category][cat] = value;
            }
            break;
          case 'Tab_Mode':
            if (cat === title) {
              preferences[category][cat] = value;
            }
            break;
          case 'Early_Events':
            if (cat === title) {
              preferences[category][cat] = value;
            }
            break;
          case 'Locations_Related_Countries':
            if (cat === title) {
              preferences[category][cat] = value;
            }
            break;
          case 'Analyzed_Events':
            if (cat === title) {
              preferences[category][cat] = value;
            }
            break;
          case 'Early_In_Analyzed_Events':
            if (cat === title) {
              preferences[category][cat] = value;
            }
            break;
          case 'Trending_Mentions':
            if (cat === title) {
              preferences[category][cat] = value;
            }
            break;
          case 'Event_Collapsed_View':
            if (cat === title) {
              preferences[category][cat] = value;
            }
            break;
          case 'Search_with_gray':
            if (cat === title) {
              if (value) {
                preferences[category][cat] = value;
                preferences[category][`Search_with_yellow`] = value;
                preferences[category][`Search_with_orange`] = value;
                preferences[category][`Search_with_red`] = value;
              } else {
                preferences[category][cat] = value;
                preferences[category]['Search_with_black'] = value;
              }
            }
            break;
          case 'Search_with_black':
            if (cat === title) {
              if (value) {
                preferences[category][cat] = value;
                preferences[category][`Search_with_gray`] = value;
                preferences[category][`Search_with_yellow`] = value;
                preferences[category][`Search_with_orange`] = value;
                preferences[category][`Search_with_red`] = value;
              } else {
                preferences[category][cat] = value;
              }
            }
            break;
          case 'Search_with_yellow':
            if (cat === title) {
              if (value) {
                preferences[category][cat] = value;
                preferences[category][`Search_with_orange`] = value;
                preferences[category][`Search_with_red`] = value;
              } else {
                preferences[category][cat] = value;
                preferences[category][`Search_with_gray`] = value;
                preferences[category]['Search_with_black'] = value;
              }
            }
            break;
          case 'Search_with_orange':
            if (cat === title) {
              if (value) {
                preferences[category][cat] = value;
                preferences[category][`Search_with_red`] = value;
              } else {
                preferences[category][cat] = value;
                preferences[category][`Search_with_yellow`] = value;
                preferences[category][`Search_with_gray`] = value;
                preferences[category]['Search_with_black'] = value;
              }
            }
            break;
          case 'Search_with_red':
            if (cat === title) {
              if (value) {
                preferences[category][cat] = value;
              } else {
                preferences[category][`Search_with_yellow`] = value;
                preferences[category][`Search_with_orange`] = value;
                preferences[category][`Search_with_gray`] = value;
                preferences[category]['Search_with_black'] = value;
              }
            }
            break;
          case 'CDSearch_with_gray':
            if (cat === title) {
              if (value) {
                preferences[category][cat] = value;
                preferences[category][`CDSearch_with_yellow`] = value;
                preferences[category][`CDSearch_with_orange`] = value;
                preferences[category][`CDSearch_with_red`] = value;
              } else {
                preferences[category][cat] = value;
                preferences[category]['CDSearch_with_black'] = value;
              }
            }
            break;
          case 'CDSearch_with_black':
            if (cat === title) {
              if (value) {
                preferences[category][cat] = value;
                preferences[category][`CDSearch_with_gray`] = value;
                preferences[category][`CDSearch_with_yellow`] = value;
                preferences[category][`CDSearch_with_orange`] = value;
                preferences[category][`CDSearch_with_red`] = value;
              } else {
                preferences[category][cat] = value;
              }
            }
            break;
          case 'CDSearch_with_yellow':
            if (cat === title) {
              if (value) {
                preferences[category][cat] = value;
                preferences[category][`CDSearch_with_orange`] = value;
                preferences[category][`CDSearch_with_red`] = value;
              } else {
                preferences[category][cat] = value;
                preferences[category][`CDSearch_with_gray`] = value;
                preferences[category]['CDSearch_with_black'] = value;
              }
            }
            break;
          case 'CDSearch_with_orange':
            if (cat === title) {
              if (value) {
                preferences[category][cat] = value;
                preferences[category][`CDSearch_with_red`] = value;
              } else {
                preferences[category][cat] = value;
                preferences[category][`CDSearch_with_yellow`] = value;
                preferences[category][`CDSearch_with_gray`] = value;
                preferences[category]['CDSearch_with_black'] = value;
              }
            }
            break;
          case 'CDSearch_with_red':
            if (cat === title) {
              if (value) {
                preferences[category][cat] = value;
              } else {
                preferences[category][`CDSearch_with_yellow`] = value;
                preferences[category][`CDSearch_with_orange`] = value;
                preferences[category][`CDSearch_with_gray`] = value;
                preferences[category]['CDSearch_with_black'] = value;
              }
            }
            break;
          default:
            break;
        }
      });
      return preferences;
    }
    return preferences;
  };
  set_notification_filters = (category, title, value) => {
    let preferences = this.state.preferences;
    let CategoryList = Object.keys(preferences[category]);
    // console.log(category, CategoryList, preferences)
    if (CategoryList.indexOf(title) > -1) {
      preferences[category][title] = value;
    }
    return preferences;
  };

  handleUserPreferences = (type, title, value, category) => {
    // console.log(type, title, value, category)

    let preferences = this.state.preferences;
    // console.log(preferences)
    let preferenceCategories = Object.keys(preferences);
    // console.log(preferenceCategories)
    Object.keys(preferences).map((cat) => {
      Object.keys(preferences[cat]).map((scat) => {
        if (Object.keys(preferences[cat][scat]).length && typeof preferences[cat][scat] !== 'string') {
          preferenceCategories.push(scat);
        }
        return null;
      });
      return null;
    });
    // console.log(preferenceCategories)
    let newPreferences = {};
    if (preferenceCategories.indexOf(category) > -1) {
      switch (category) {
        case 'event_importance_filters':
          newPreferences = this.set_event_importance_filters(category, title, value);
          break;
        case 'Red_Colored_Events':
          newPreferences = this.set_event_importance_filters(category, title, value);
          break;
        case 'Yellow_Colored_Events':
          newPreferences = this.set_event_importance_filters(category, title, value);
          break;
        case 'Gray_Colored_Events':
          newPreferences = this.set_event_importance_filters(category, title, value);
          break;
        case 'Non_Active_Curated_Events':
          newPreferences = this.set_event_importance_filters(category, title, value);
          break;
        case 'notification':
          newPreferences = this.set_notification_filters(category, title, value);
          break;
        case 'email_preferences':
          newPreferences = this.set_notification_filters(category, title, value);
          break;
        case 'search_preferences':
          newPreferences = this.set_event_importance_filters(category, title, value);
          break;
        case 'coindetailsearch_preferences':
          newPreferences = this.set_event_importance_filters(category, title, value);
          break;
        case 'event_filters':
          newPreferences = this.set_event_importance_filters(category, title, value);
          break;
        case 'localisation_preferences':
          newPreferences = this.set_notification_filters(category, title, value);
          break;
        case 'user_interface':
          newPreferences = this.set_event_importance_filters(category, title, value);
          break;
        default:
          break;
      }
    }

    // console.log(this.state.preferences, newPreferences)
    this.setState(
      {
        ...this.state,
        preferences: newPreferences,
      },
      () => {
        // let preferenceCat = Object.keys(this.state.preferences)
        let preferenceCat = Object.keys(newPreferences);

        let eventImpFiltStr = '';
        let NotifFiltStr = '';
        let localisationStr = '';
        let NotifFiltStrObj = { DesktopNotifications: '', PhoneNotifications: '', EmailNotifications: '' };
        let phoneStr = '';
        let tmpDskTpNtfcns = [];
        let tmpPhnNtfcns = [];
        let tmpEmlNtfcns = [];
        preferenceCat.forEach((cat) => {
          let titles = Object.keys(newPreferences[cat]);

          if (
            cat === 'event_importance_filters' ||
            cat === 'email_preferences' ||
            cat === 'search_preferences' ||
            cat === 'coindetailsearch_preferences' ||
            cat === 'localisation_preferences' ||
            cat === 'user_interface' ||
            cat === 'event_filters'
          ) {
            titles.forEach((tl, i) => {
              // console.log(tl, titles.length)
              // console.log(Object.keys(newPreferences[cat][tl]).length)
              if (Object.keys(newPreferences[cat][tl]).length && typeof newPreferences[cat][tl] !== 'string') {
                let subtitles = Object.keys(newPreferences[cat][tl]);
                subtitles.forEach((stl, y) => {
                  // console.log(stl, subtitles.length)
                  switch (stl) {
                    case 'Show_Gray':
                      eventImpFiltStr = `${eventImpFiltStr}Low_Importance:${newPreferences[cat][tl][stl]};`;
                      break;
                    case 'Show_Yellow':
                      eventImpFiltStr = `${eventImpFiltStr}Medium_Importance:${newPreferences[cat][tl][stl]};`;
                      break;
                    case 'Show_Red':
                      eventImpFiltStr = `${eventImpFiltStr}High_Importance:${newPreferences[cat][tl][stl]};`;
                      break;
                    case 'Show_NACE':
                      eventImpFiltStr = `${eventImpFiltStr}Show_Curated:${newPreferences[cat][tl][stl]};`;
                      break;
                    case 'Desktop_Notifications_NACE':
                      tmpDskTpNtfcns.push(`Curated=${newPreferences[cat][tl][stl] ? 'On' : 'Off'}`);
                      // NotifFiltStrObj.DesktopNotifications = `${NotifFiltStrObj.DesktopNotifications}Low=${newPreferences[cat][tl][stl] ? 'On':'Off'}`
                      // if(y<subtitles.length-1) {
                      //   NotifFiltStrObj.DesktopNotifications = `${NotifFiltStrObj.DesktopNotifications},`;
                      // }
                      break;
                    case 'Desktop_Notifications_Gray':
                      tmpDskTpNtfcns.push(`Low=${newPreferences[cat][tl][stl] ? 'On' : 'Off'}`);
                      // NotifFiltStrObj.DesktopNotifications = `${NotifFiltStrObj.DesktopNotifications}Low=${newPreferences[cat][tl][stl] ? 'On':'Off'}`
                      // if(y<subtitles.length-1) {
                      //   NotifFiltStrObj.DesktopNotifications = `${NotifFiltStrObj.DesktopNotifications},`;
                      // }
                      break;
                    case 'Desktop_Notifications_Yellow':
                      tmpDskTpNtfcns.push(`Medium=${newPreferences[cat][tl][stl] ? 'On' : 'Off'}`);
                      // NotifFiltStrObj.DesktopNotifications = `${NotifFiltStrObj.DesktopNotifications}Medium=${newPreferences[cat][tl][stl] ? 'On':'Off'}`
                      // if(y<subtitles.length-1) {
                      //   NotifFiltStrObj.DesktopNotifications = `${NotifFiltStrObj.DesktopNotifications},`;
                      // }
                      break;
                    case 'Desktop_Notifications_Red':
                      tmpDskTpNtfcns.push(`High=${newPreferences[cat][tl][stl] ? 'On' : 'Off'}`);
                      // NotifFiltStrObj.DesktopNotifications = `${NotifFiltStrObj.DesktopNotifications}High=${newPreferences[cat][tl][stl] ? 'On':'Off'}`
                      // if(y<subtitles.length-1) {
                      //   NotifFiltStrObj.DesktopNotifications = `${NotifFiltStrObj.DesktopNotifications},`;
                      // }
                      break;
                    case 'Email_Notifications_Gray':
                      tmpEmlNtfcns.push(`EN_Low=${newPreferences[cat][tl][stl]}`);

                      break;
                    case 'Email_Notifications_Yellow':
                      tmpEmlNtfcns.push(`EN_Medium=${newPreferences[cat][tl][stl]}`);

                      break;
                    case 'Email_Notifications_Red':
                      tmpEmlNtfcns.push(`EN_High=${newPreferences[cat][tl][stl]}`);

                      break;
                    case 'Desktop_Notify_Breaking_Only_Gray':
                      tmpDskTpNtfcns.push(`Low_BreakingOnly=${newPreferences[cat][tl][stl] ? 'On' : 'Off'}`);
                      // NotifFiltStrObj.DesktopNotifications = `${NotifFiltStrObj.DesktopNotifications}Low_BreakingOnly=${newPreferences[cat][tl][stl] ? 'On':'Off'}`
                      // if(y<subtitles.length-1) {
                      //   NotifFiltStrObj.DesktopNotifications = `${NotifFiltStrObj.DesktopNotifications},`;
                      // }
                      break;
                    case 'Desktop_Notify_Breaking_Only_Yellow':
                      tmpDskTpNtfcns.push(`Medium_BreakingOnly=${newPreferences[cat][tl][stl] ? 'On' : 'Off'}`);
                      // NotifFiltStrObj.DesktopNotifications = `${NotifFiltStrObj.DesktopNotifications}Medium_BreakingOnly=${newPreferences[cat][tl][stl] ? 'On':'Off'}`
                      // if(y<subtitles.length-1) {
                      //   NotifFiltStrObj.DesktopNotifications = `${NotifFiltStrObj.DesktopNotifications},`;
                      // }
                      break;
                    case 'Desktop_Notify_Breaking_Only_Red':
                      tmpDskTpNtfcns.push(`High_BreakingOnly=${newPreferences[cat][tl][stl] ? 'On' : 'Off'}`);
                      // NotifFiltStrObj.DesktopNotifications = `${NotifFiltStrObj.DesktopNotifications}High_BreakingOnly=${newPreferences[cat][tl][stl] ? 'On':'Off'}`
                      // if(y<subtitles.length-1) {
                      //   NotifFiltStrObj.DesktopNotifications = `${NotifFiltStrObj.DesktopNotifications},`;
                      // }
                      break;
                    case 'Email_Notify_Breaking_Only_Gray':
                      tmpEmlNtfcns.push(`EN_Low_BreakingOnly=${newPreferences[cat][tl][stl]}`);

                      break;
                    case 'Email_Notify_Breaking_Only_Yellow':
                      tmpEmlNtfcns.push(`EN_Medium_BreakingOnly=${newPreferences[cat][tl][stl]}`);

                      break;
                    case 'Email_Notify_Breaking_Only_Red':
                      tmpEmlNtfcns.push(`EN_High_BreakingOnly=${newPreferences[cat][tl][stl]}`);

                      break;
                    case 'SMS_Notifications_Yellow':
                      tmpPhnNtfcns.push(`Medium=${newPreferences[cat][tl][stl] ? 'On' : 'Off'}`);
                      // NotifFiltStrObj.PhoneNotifications = `${NotifFiltStrObj.PhoneNotifications}Medium=${newPreferences[cat][tl][stl] ? 'On':'Off'}`

                      // if(i<titles.length-1) {
                      //   NotifFiltStrObj.PhoneNotifications = `${NotifFiltStrObj.PhoneNotifications},`;
                      // }
                      break;
                    case 'SMS_Notifications_Red':
                      tmpPhnNtfcns.push(`High=${newPreferences[cat][tl][stl] ? 'On' : 'Off'}`);
                      // NotifFiltStrObj.PhoneNotifications = `${NotifFiltStrObj.PhoneNotifications}High=${newPreferences[cat][tl][stl] ? 'On':'Off'}`
                      // if(i<titles.length-1) {
                      //   NotifFiltStrObj.PhoneNotifications = `${NotifFiltStrObj.PhoneNotifications},`;
                      // }
                      break;
                    case 'SMS_Notifications_Gray':
                      tmpPhnNtfcns.push(`Low=${newPreferences[cat][tl][stl] ? 'On' : 'Off'}`);
                      // NotifFiltStrObj.PhoneNotifications = `${NotifFiltStrObj.PhoneNotifications}Low=${newPreferences[cat][tl][stl] ? 'On':'Off'}`
                      // if(i<titles.length-1) {
                      //   NotifFiltStrObj.PhoneNotifications = `${NotifFiltStrObj.PhoneNotifications},`;
                      // }
                      break;
                    case 'Speech_Notifications_Red':
                      eventImpFiltStr = `${eventImpFiltStr}High_Speech:${newPreferences[cat][tl][stl]};`;
                      break;
                    case 'Speech_Notifications_Yellow':
                      eventImpFiltStr = `${eventImpFiltStr}Medium_Speech:${newPreferences[cat][tl][stl]};`;
                      break;
                    case 'Speech_Notifications_Gray':
                      eventImpFiltStr = `${eventImpFiltStr}Low_Speech:${newPreferences[cat][tl][stl]};`;
                      break;
                    default:
                      break;
                  }
                });
              } else {
                // console.log(tl)
                if (cat === 'email_preferences') {
                  switch (tl) {
                    case 'Daily_Notifications':
                      tmpEmlNtfcns[1] = `Daily=${newPreferences[cat][tl]}`;
                      // NotifFiltStrObj.EmailNotifications = `${NotifFiltStrObj.EmailNotifications}Daily=${newPreferences[cat][tl]}`;
                      // if(i<titles.length-1) {
                      //   NotifFiltStrObj.EmailNotifications = `${NotifFiltStrObj.EmailNotifications},`;
                      // }
                      break;
                    case 'Weekly_Notifications':
                      tmpEmlNtfcns[2] = `Weekly=${newPreferences[cat][tl]}`;
                      // tmpEmlNtfcns.push(`Weekly=${newPreferences[cat][tl]}`)
                      // NotifFiltStrObj.EmailNotifications = `${NotifFiltStrObj.EmailNotifications}Weekly=${newPreferences[cat][tl]}`;
                      // if(i<titles.length-1) {
                      //   NotifFiltStrObj.EmailNotifications = `${NotifFiltStrObj.EmailNotifications},`;
                      // }
                      break;
                    default:
                      if (newPreferences[cat][tl]) {
                        tmpEmlNtfcns[0] = `RealTime=${tl}`;
                      }
                      break;
                  }
                } else if (cat === 'localisation_preferences') {
                  // console.log(tl)
                  switch (tl) {
                    case 'Email':
                      eventImpFiltStr = `${eventImpFiltStr}UserEmail:${newPreferences[cat][tl]};`;
                      break;
                    case 'Timezone':
                      localisationStr = `${this.state.preferences[cat][tl]}`;
                      break;
                    case 'Phone':
                      phoneStr = `${this.state.preferences[cat][tl]}`;
                      break;
                    default:
                      break;
                  }
                } else if (cat === 'search_preferences') {
                  switch (tl) {
                    case 'Search_with_gray':
                      eventImpFiltStr = `${eventImpFiltStr}Search_with_gray:${newPreferences[cat][tl]};`;
                      break;
                    case 'Search_with_black':
                      eventImpFiltStr = `${eventImpFiltStr}Search_with_black:${newPreferences[cat][tl]};`;
                      break;
                    case 'Search_with_yellow':
                      eventImpFiltStr = `${eventImpFiltStr}Search_with_yellow:${newPreferences[cat][tl]};`;
                      break;
                    case 'Search_with_orange':
                      eventImpFiltStr = `${eventImpFiltStr}Search_with_orange:${newPreferences[cat][tl]};`;
                      break;
                    case 'Search_with_red':
                      eventImpFiltStr = `${eventImpFiltStr}Search_with_red:${newPreferences[cat][tl]};`;
                      break;
                    default:
                      break;
                  }
                } else if (cat === 'coindetailsearch_preferences') {
                  switch (tl) {
                    case 'CDSearch_with_gray':
                      eventImpFiltStr = `${eventImpFiltStr}CDSearch_with_gray:${newPreferences[cat][tl]};`;
                      break;
                    case 'CDSearch_with_black':
                      eventImpFiltStr = `${eventImpFiltStr}CDSearch_with_black:${newPreferences[cat][tl]};`;
                      break;
                    case 'CDSearch_with_yellow':
                      eventImpFiltStr = `${eventImpFiltStr}CDSearch_with_yellow:${newPreferences[cat][tl]};`;
                      break;
                    case 'CDSearch_with_orange':
                      eventImpFiltStr = `${eventImpFiltStr}CDSearch_with_orange:${newPreferences[cat][tl]};`;
                      break;
                    case 'CDSearch_with_red':
                      eventImpFiltStr = `${eventImpFiltStr}CDSearch_with_red:${newPreferences[cat][tl]};`;
                      break;
                    default:
                      break;
                  }
                } else if (tl !== 'Recurring_Events' && tl !== 'Planned_Scheduled_Events') {
                  eventImpFiltStr = `${eventImpFiltStr}${tl}:${this.state.preferences[cat][tl]};`;
                }
              }
            });
            // console.log(eventImpFiltStr);
          }
        });
        NotifFiltStrObj.DesktopNotifications = tmpDskTpNtfcns.join(',');
        NotifFiltStrObj.PhoneNotifications = tmpPhnNtfcns.join(',');
        NotifFiltStrObj.EmailNotifications = tmpEmlNtfcns.join(',');

        Object.keys(NotifFiltStrObj).map((notifkey, i) => {
          // console.log(notifkey)
          NotifFiltStr = `${NotifFiltStr}${notifkey}:${NotifFiltStrObj[notifkey]}`;
          if (i < Object.keys(NotifFiltStrObj).length - 1) {
            NotifFiltStr = `${NotifFiltStr};`;
          }
          return null;
        });
        // console.log(eventImpFiltStr, NotifFiltStr, localisationStr);
        // return;
        let userPrefData = {
          UserName: this.props.userData.username,
          UserPreferencesString: eventImpFiltStr,
        };
        console.log(userPrefData);
        let notificationData = {
          UserName: this.props.userData.username,
          UserPreferencesString: NotifFiltStr,
        };
        let localisationData = {
          UserName: this.props.userData.username,
          UserPreferencesString: localisationStr,
        };
        let phoneData = {
          UserName: this.props.userData.username,
          UserPreferencesString: phoneStr,
        };
        window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
        window.Mixpanel.MixpanelProps.Category = category;
        window.Mixpanel.MixpanelProps.Preference = title;
        window.Mixpanel.MixpanelProps.Status = value === true ? 'ON' : value === false ? 'OFF' : value;
        window.Mixpanel.actions.identify(this.props.userData.username);
        window.Mixpanel.actions.track(`Changed Preferences`, window.Mixpanel.MixpanelProps).then((data) => {
          window.Mixpanel.actions.people.set({
            UserName: this.props.userData.username,
          });
          window.Mixpanel.actions.resetProp('Category');
          window.Mixpanel.actions.resetProp('Preference');
          window.Mixpanel.actions.resetProp('Status');
        });
        if (category === 'localisation_preferences') {
          switch (title) {
            case 'Email':
              this.props.setUserPreferences(userPrefData);
              break;
            case 'Timezone':
              this.props.setLocalisationPreferences(localisationData);
              break;
            case 'Phone':
              this.props.setPhonePreferences(phoneData);
              break;
            default:
              break;
          }
        } else {
          this.props.setUserPreferences(userPrefData);
          this.props.setNotificationPreferences(notificationData);
        }
      }
    );
  };

  render() {
    const { preferences } = this.state;
    // console.log(preferences)
    let prefCategories;
    if (preferences) {
      prefCategories = Object.keys(preferences).map((key) => {
        // console.log(preferences[key])
        return (
          <PreferenceCategory
            {...this.props}
            categoryData={preferences[key]}
            category={key}
            key={key}
            handleUserPreferences={this.handleUserPreferences}
            TimeZoneList={this.props.TimeZoneList}
          />
        );
      });
    }

    return (
      <Row className=" marg-0 userpref-wrap ">
        <Col lg={12} sm={12} xs={12} md={12} xl={12}>
          <Row className="justify-content-center  ">
            <Col lg={11} sm={11} xs={11} md={11} xl={11} className="nopad userprefhead justify-content-left text-left">
              <i className="fa fa-linode"></i>
              <span>User Preferences</span>
            </Col>
          </Row>
          <Row className="justify-content-center  ">
            <Col lg={11} sm={11} xs={11} md={11} xl={11} className="userprefbody justify-content-left text-left">
              <div className="userpref-list">{prefCategories}</div>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    preferences: state.app_preferences.preferences,
    TimeZoneList: state.settings.TimeZoneList,
  };
};

const mapDispatchToProps = {
  ...preferencesActionCreators,
};

export default connect(mapStateToProps, mapDispatchToProps)(Userpreferences);
