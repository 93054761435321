import { Component } from 'react';
import { connect } from 'react-redux';
import { NotifyFeedsHandler } from '../../modules/helper.module';
import { UpdateInitialColumnLoadCounter } from '../../actions/settings.action';

class StoryListHandle extends Component {
  constructor(props) {
    super()
    this.state = {
      tweetList: { Index: [] },
      ActivelistIndex: props.listIndex,
      ActiveIndexLimit: 20,
      ActiveMaxIndexes: 3
    }
  }

  componentDidMount() {
    // console.log(this.props.tweetList)
    this.setState({
      ...this.state,
      tweetList: this.props.tweetList
    })
  }

  componentWillReceiveProps(nextProps, prevState) {
    // console.log(this.props.tweetList, nextProps.tweetList)
    if (JSON.stringify(this.props.tweetList.Index) !== JSON.stringify(nextProps.tweetList.Index)) {
      if (!this.props.tweetList.Index.length && nextProps.tweetList.Index.length) {
        this.props.UpdateInitialColumnLoadCounter()
      }
      this.setState({
        ...this.state,
        tweetList: nextProps.tweetList,
        // ActivelistIndex: [1]
      }, () => {
        this.updateActiveTweetList()
      })
    }

  }

  updateActiveTweetList = (tm_showmore) => {
    let ActivetweetList = {}
    if (this.state.tweetList.Index.length) {
      let StartIndex = (parseInt(this.state.ActivelistIndex[0]) - 1) * parseInt(this.state.ActiveIndexLimit);
      let EndIndex = ((parseInt(this.state.ActivelistIndex[this.state.ActivelistIndex.length - 1]) - 1) * parseInt(this.state.ActiveIndexLimit)) + (parseInt(this.state.ActiveIndexLimit) - 1);
      if (this.props.listkey.startsWith('Trending Mentions_')) {
        // console.log(StartIndex, EndIndex, this.state.tweetList.Index.length)

      }
      // console.log(StartIndex, EndIndex)
      ActivetweetList = this.getTweetList(StartIndex, EndIndex);
      // console.log(ActivetweetList)
    } else {
      ActivetweetList = this.state.tweetList
    }
    if (tm_showmore) {
      this.props.updateTweetList(ActivetweetList, this.state.tweetList.Index.length, this.state.ActivelistIndex.length)
    } else {
      this.props.updateTweetList(ActivetweetList, this.state.tweetList.Index.length)
    }


  }

  getTweetList = (start, stop) => {
    try {
      const { Index } = this.state.tweetList
      if (Index.length < (stop + 1)) {
        stop = Index.length - 1
      }
      let newIndex = this.extractFromArray(Index, start, stop);
      let newTweetList = {
        Index: newIndex
      }
      return newTweetList;
    } catch (err) {
      console.log(err)
    }


  }

  extractFromArray = (array, start, stop) => {
    if (array.length) {
      let extractedArray = array.map((item, index) => {
        if (index >= parseInt(start) && index <= parseInt(stop)) {
          return item
        }
        return null;
      }).filter(itm => (itm));
      return extractedArray;
    }
    return array
  }

  setActiveListIndex = (listIndex = [1], force = false, tm_showmore = false) => {
    let ActListIndx = force ? listIndex : this.state.ActivelistIndex.length < listIndex.length ? listIndex : this.state.ActivelistIndex
    // console.log(this.state.ActivelistIndex.length, listIndex.length)
    // console.log(ActListIndx)
    this.setState({
      ...this.state,
      ActivelistIndex: ActListIndx,
      tweetList: this.props.tweetList,
      ActiveIndexLimit: this.props.listkey.startsWith('Trending Mentions_') ? 10 : this.state.ActiveIndexLimit
    }, () => {
      this.updateActiveTweetList(tm_showmore)
    })
  }

  tm_showMore = () => {
    let oldListIndex = JSON.stringify(this.state.ActivelistIndex);
    let ActivelistIndex = JSON.parse(oldListIndex);

    ActivelistIndex.push(ActivelistIndex[ActivelistIndex.length - 1] + 1)
    console.log(ActivelistIndex)
    if (JSON.stringify(ActivelistIndex) !== oldListIndex) {
      this.setActiveListIndex(ActivelistIndex, false, true)
    }
  }

  CheckScrollAndHandleList = (el) => {
    let col = el;

    let oldListIndex = JSON.parse(JSON.stringify(this.state.ActivelistIndex));
    let ActivelistIndex = JSON.parse(JSON.stringify(oldListIndex));
    // console.log(col.scrollHeight, col.scrollTop, col.clientHeight, oldListIndex)
    // console.log(parseInt(col.scrollHeight-col.scrollTop), col.clientHeight)
    if (parseInt(col.scrollHeight - col.scrollTop) <= Math.round(col.clientHeight)) {

      ActivelistIndex.push(ActivelistIndex[ActivelistIndex.length - 1] + 1)
      // this.setActiveListIndex(ActivelistIndex, true)
      // console.log(ActivelistIndex)
    } else if (parseInt(col.scrollHeight - col.scrollTop) > Math.round(col.clientHeight)) {

      if (ActivelistIndex.length > 1) {

        let ScrollHeightForEachIndex = col.scrollHeight / ActivelistIndex.length;
        let ScrollTopDifferenceFromDocBottom = (col.scrollHeight - col.scrollTop) - col.clientHeight
        let RemoveIndexLength = 0;
        if (ScrollTopDifferenceFromDocBottom > (ScrollHeightForEachIndex + 50)) {
          RemoveIndexLength = Math.round(ScrollTopDifferenceFromDocBottom / ScrollHeightForEachIndex);
        }
        if (RemoveIndexLength === ActivelistIndex.length) { --RemoveIndexLength; }
        if (RemoveIndexLength < ActivelistIndex.length) {
          ActivelistIndex.splice(ActivelistIndex.length - RemoveIndexLength, RemoveIndexLength)
          // this.setActiveListIndex(ActivelistIndex, true)
        }
      }

    }
    // console.log(ActivelistIndex.length, oldListIndex.length)
    if (ActivelistIndex.length !== oldListIndex.length) {
      // console.log('here')
      this.setActiveListIndex(ActivelistIndex, true)
    }
  }

  render() {
    return ('')
  }
}

const mapStateToProps = (state, ownProps) => {
  let tweetKey = ownProps.listkey;
  let TweetIndex = [];
  let TweetList = {};
  let lastNBEventDate = '';
  let storedlistIndex = [1];
  // console.log(state.appSettings.preferences.user_interface.Early_In_Analyzed_Events)
  // console.log(tweetKey)
  switch (tweetKey) {
    case 'Breaking Feeds':
      TweetIndex = state.feeds.BreakingStoriesIndex.length ? (
        state.feeds.BreakingStoriesIndex.map(breaking => ({ FeedId: breaking.FeedId })).filter(itm => (itm))
      ) : ([]);
      break;
    case 'Analyzed Feeds':

      for (let i = state.feeds.AnalysedStoriesIndex.length - 1; i >= 0; i--) {
        if (!state.feeds.AnalysedStoriesIndex[i].notifyEvent) {
          lastNBEventDate = state.feeds.AnalysedStoriesIndex[i].SortByDate
          break;
        }
      }
      TweetIndex = state.feeds.AnalysedStoriesIndex.length ? (
        state.feeds.AnalysedStoriesIndex.map(analyzed => {
          if (state.app_preferences.preferences.user_interface.Early_In_Analyzed_Events) {
            // if(NotifyFeedsHandler.AllEventsFilter(lastNBEventDate, analyzed.notifyEvent, analyzed.SortByDate)) {
            return { FeedId: analyzed.FeedId }
            // }
          } else {
            if (!NotifyFeedsHandler.BreakingEventStatus(analyzed.notifyEvent, analyzed.magnitudeRate)) {
              return { FeedId: analyzed.FeedId }
            }
          }
          return null;
        })
      ) : ([]);
      // console.log(TweetIndex)
      break;
    case 'Main Feeds':
      // console.log(state.feeds.StoriesIndex)
      for (let i = state.feeds.StoriesIndex.length - 1; i >= 0; i--) {
        if (!state.feeds.StoriesIndex[i].notifyEvent) {
          lastNBEventDate = state.feeds.StoriesIndex[i].SortByDate
          break;
        }
      }
      TweetIndex = state.feeds.StoriesIndex.length ? (
        state.feeds.StoriesIndex.map(regular => {
          if (state.app_preferences.preferences.user_interface.Mainstream_News) {
            if (NotifyFeedsHandler.AllEventsFilter(lastNBEventDate, regular.notifyEvent, regular.SortByDate)) {
              return { FeedId: regular.FeedId }
            }
          } else {
            if (!NotifyFeedsHandler.BreakingEventStatus(regular.notifyEvent, regular.magnitudeRate)) {
              return { FeedId: regular.FeedId }
            }
          }
          return null;
        }).filter(itm => (itm))
      ) : ([]);
      break;
    case 'Searched Feeds':
      TweetIndex = state.search.searchStoriesIndex.length ? (
        state.search.searchStoriesIndex.map(search => ({ FeedId: search.FeedId }))
      ) : ([]);
      break;
    // case 'Trending Mentions':
    //   TweetIndex = state.trendingMentions.coin_column_data[ownProps.tm_colindex].tmIndex.length ? (
    //     state.trendingMentions.coin_column_data[ownProps.tm_colindex].tmIndex.map(tm => ({FeedId : tm.SummarizationItemId}))
    //   ) : ([]);

    //   break;
    case 'Trending Mentions Details':
      // console.log(state.trendingMentions.tmDetails)
      TweetIndex = state.trendingMentions.tmDetails.TickerEventIndex.length ? (
        state.trendingMentions.tmDetails.TickerEventIndex.map(tkrEvnts => ({ FeedId: tkrEvnts.FeedId }))
      ) : ([]);
      break;
    default:
      if (tweetKey.startsWith('SavedSearch')) {
        let saveId = tweetKey.replace('SavedSearch', '');
        TweetIndex = state.search.searchSavedStoriesIndex[saveId] && state.search.searchSavedStoriesIndex[saveId].length ? (
          state.search.searchSavedStoriesIndex[saveId].map(savedSearch => ({ FeedId: savedSearch.FeedId }))
        ) : ([])
        // TweetList = {searchSaveData: ownProps.searchSaveData[saveId]}
      } else if (tweetKey.startsWith('Trending Mentions_')) {
        // console.log(ownProps.tm_colindex)
        TweetIndex = typeof ownProps.tm_colindex !== 'undefined' ? state.trendingMentions.coin_column_data[ownProps.tm_colindex].tmIndex.length ? (
          state.trendingMentions.coin_column_data[ownProps.tm_colindex].tmIndex.map(tm => ({ FeedId: tm.SummarizationItemId }))
        ) : ([]) : ([]);
        if (typeof ownProps.tm_colindex !== 'undefined') {
          storedlistIndex = [];
          for (let i = 0; i < parseInt(state.trendingMentions.coin_column_preferences[ownProps.tm_colindex].listIndex); i++) {
            storedlistIndex.push(i + 1)
          }
        }
        // console.log(storedlistIndex)
        // storedlistIndex = typeof ownProps.tm_colindex !== 'undefined' ? state.trendingMentions.coin_column_data[ownProps.tm_colindex].listIndex : 1
      }
      else if (tweetKey.startsWith('Coin_Column_details_')) {
        TweetIndex = typeof state.trendingMentions.tmDetailPreferencesDataStore[`${ownProps.tmcd_SecurityName}${ownProps.tmcd_SourceFilter}`] !== 'undefined' ? state.trendingMentions.tmDetailPreferencesDataStore[`${ownProps.tmcd_SecurityName}${ownProps.tmcd_SourceFilter}`].TickerEventIndex.length ? (
          state.trendingMentions.tmDetailPreferencesDataStore[`${ownProps.tmcd_SecurityName}${ownProps.tmcd_SourceFilter}`].TickerEventIndex.map(tmcd => ({ FeedId: tmcd.FeedId }))
        ) : ([]) : ([]);
      }
      break;
  }
  TweetList = { ...TweetList, Index: TweetIndex };

  return {
    tweetList: TweetList,
    listIndex: storedlistIndex
  }
}

const mapDispatchToProps = {
  UpdateInitialColumnLoadCounter
};



export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(StoryListHandle)