import React, { Component } from 'react'; 
import { connect } from 'react-redux';
import * as filtersActionCreators from '../../actions/filters.action';
import SecurityListItem from './security-list-item';

class SecuritySetting extends Component {
  constructor() {
    super();
    this.state= {
      parentText: '',
      parentActive: false,
      securityHeirarchialState: [],
      securityFilter:[],
      securitiesFilter: []
    }
  }

  componentWillMount() {
    let securityHeirarchialState = this.readSecs(this.props)
    this.setState({
      ...this.state,
      parentActive: this.props.active,
      parentText: this.props.text,
      securityFilter: this.props.securityFilter,
      securitiesFilter: this.props.securitiesFilter,
      securityHeirarchialState
    }, () => {
      
      // if(!this.props.securitiesRawCollection.length) { 
      //   this.props.getSecuritiesRaw();
      // }
      // if(!this.props.securityFilter.length) { 
      //   this.props.getSecurityTypeFilter();
      // }
      // if(!this.props.securities.length) {
      //   this.props.getSecurities();
      // }
      // if(!this.props.securitiesFilter.length) {
      //   this.props.getSecuritiesFilter();
      // }
      
    })
    
  }

  readSecs = (props) => {
    let SecurityHeirarchialState = [];
    console.log(props.securitiesRawCollection, props.securitiesResults)
    if(props.securitiesRawCollection.length && props.securityFilter.length) {
      
      let ParentState = [];
      let filters = props.securityFilter
      let secFilters = props.securitiesFilter
      props.securitiesRawCollection.forEach(element => {
        element.Securities = [];
        if(filters.length && filters.indexOf(element.Name) > -1) {
          element.selected = true
        }
        let srArr = [];
        props.securitiesResults.forEach(sr => {
          if(element.Name === sr.SecurityType || (sr.VirtualSecurityTypes !== null && sr.VirtualSecurityTypes.split(';').indexOf(element.Name) > -1)) {
            srArr.push(sr.Security);
          }
        })
        props.securities.forEach((sec, i) => {
          if(srArr.indexOf(sec.Name) > -1) {
            element.Securities.push(sec.SecurityID);
          }
        })
        if(element.Securities.length) {
          props.securities.forEach((sec, i) => {
            if(secFilters.length && secFilters.indexOf(sec.Name) > -1) {
              sec.selected = true
            }
          })
        }
        if(element.SecurityTypeParent === 0) { 
          ParentState.push(element.SecurityTypeID)
        }
      })
      SecurityHeirarchialState.push(ParentState)
      // console.log(SecurityHeirarchialState)
      
    }
    return SecurityHeirarchialState; 
  }

  componentWillReceiveProps(nextProps) {
    let securityHeirarchialState = this.readSecs(nextProps)
    if(this.props.active !== nextProps.active) {
      this.setState({
        ...this.state,
        parentActive: nextProps.active,
        parentText: nextProps.text,
        securityFilter: nextProps.securityFilter,
        securitiesFilter: nextProps.securitiesFilter,
        securityHeirarchialState
      })
    }
  }

  SecListManage = (activeState, key, groupindex) => {
    let HS = this.state.securityHeirarchialState;
    let newHS = [];
    if(activeState) {
      
      
      if(HS[groupindex].indexOf(key) > -1) {
        for(let i=0;i<=HS.length;i++) {
          if(i===groupindex) { newHS[i] = [key]; }
          else if(i < groupindex) { newHS[i] = HS[i] }
        }
        let selsec = this.props.securitiesRawCollection.filter(coll => {
          return coll.SecurityTypeID === key
        })
        
        newHS.push(selsec[0].children)
      }
      
      this.setState({
        ...this.state,
        securityHeirarchialState: newHS
      })    
    } else {
      if(HS[groupindex].indexOf(key) > -1) {
        for(let i=0;i<=HS.length;i++) {
          if(i===groupindex) {
            if(i !== 0) {
              let parent = HS[i-1];
              let parentsec = this.props.securitiesRawCollection.filter(coll => {
                return coll.SecurityTypeID === parent[0]
              })
              newHS[i] = parentsec[0].children;
            } else {
              let ParentState = [];
              this.props.securitiesRawCollection.forEach(element => {
                if(element.SecurityTypeParent === 0) { 
                  ParentState.push(element.SecurityTypeID)
                }
              });
              newHS[i] = ParentState
            }
            
            
          } else if(i < groupindex) {
            newHS[i] = HS[i]
          }
        }
      }
      this.setState({
        ...this.state,
        securityHeirarchialState: newHS
      }) 
    }
      
  }
  getRecursiveChildren = (bucketArr, keyArr, secArr, resp, sec) => {
    this.props.securities.forEach(item => {
      if(secArr && secArr.length) {
        if(secArr.indexOf(item.SecurityID) > -1) {
          sec.push(item.SecurityID)
        }
      }
    })
    if(keyArr.length) {
      bucketArr.forEach(ele => {
        if(keyArr.indexOf(ele.SecurityTypeID) > -1) {
          resp.push(ele.SecurityTypeID)
          if(ele.Securities.length) {
            this.props.securities.forEach(item => {
              if(ele.Securities.indexOf(item.SecurityID) > -1) {
                sec.push(item.SecurityID)
              }
            })
          }
          
          if(ele.children.length) {
            this.getRecursiveChildren(bucketArr, ele.children, ele.Security, resp, sec)
          }
        }
      })
    }
    return {securityTypes: resp, securities: sec};
  }
  getRecursiveParent = (key,selectedChild, bucketArr, stateFilter, resp, type = 'type' , iterationCounts = 0) => {
    
    if(key !== 0) {
      bucketArr.forEach(ele => {
        if(ele.SecurityTypeID === key) {
          
          let chST = []
          bucketArr.forEach((cele,i) => {
            if(ele.children.indexOf(cele.SecurityTypeID) > -1) {
              
              if(type === 'type') {
                if(iterationCounts === 0) {
                  if(cele.SecurityTypeID !== selectedChild) {
                    chST.push(cele.selected)
                  }
                } else {
                  if(cele.SecurityTypeID === selectedChild) {
                    if(resp.indexOf(selectedChild) > -1) {
                      if(cele.selected !== stateFilter) {

                      } else {
                        chST.push(cele.selected)
                      }
                    } else {
                      chST.push(cele.selected)
                    }
                  } else {
                    chST.push(cele.selected)
                  }
                    
                  
                } 
              } else {
                if(type === 'sec') {
                  chST.push(cele.selected)
                }
              }
            }
          })
          this.props.securities.forEach(secs => {
            if(ele.Securities && ele.Securities.indexOf(secs.SecurityID) > -1) {
              if(type === 'sec') {
                if(iterationCounts === 0) {
                  if(secs.SecurityID !== selectedChild) {
                    chST.push(secs.selected)
                  }
                } else {
                   chST.push(secs.selected)
                  
                }
              } else {
                if(type === 'type') {
                  chST.push(secs.selected)
                }
              } 
            }
          })
          
          if(stateFilter) {
            if(chST.indexOf(stateFilter) === -1) {
              // resp.push(ele.SecurityTypeID)
            } else if(chST.indexOf(!stateFilter) === -1) {
              resp.push(ele.SecurityTypeID)
            } 
          } else {
            resp.push(ele.SecurityTypeID)
            if(chST.indexOf(!stateFilter) === -1) {
              // resp.push(ele.SecurityTypeID) 
            } 
          }
           
          if(ele.SecurityTypeParent > 0) {
            iterationCounts++;
            this.getRecursiveParent(ele.SecurityTypeParent,ele.SecurityTypeID,bucketArr,stateFilter,resp, 'type', iterationCounts)
          }
        }
      })
    }
    return resp
  }
  getRecursiveParentStage = (security, bucketArr, stateFilter, resp) => {
    
    if(!security.SecurityID) {
      let parents = this.getRecursiveParent(security.SecurityTypeParent, security.SecurityTypeID, bucketArr, stateFilter, resp, 'type');
      return parents;
    } else {
      for(let row of bucketArr) {
        if(row.Securities && row.Securities.indexOf(security.SecurityID) > -1) {
          let parents = this.getRecursiveParent(row.SecurityTypeID, security.SecurityID, bucketArr, stateFilter, resp, 'sec');
          
          return parents;
        }
      }
    }
  }
  
  
  manageFilter = (stateFilter, security) => {
    let SF = []
    let SecF = [] 
    let rawcoll = this.props.securitiesRawCollection
    let affectedChildren = !security.SecurityID ? this.getRecursiveChildren(rawcoll, security.children, security.Securities, [], []) : {securityTypes: [], securities: []}
    let affectedParents = this.getRecursiveParentStage(security, rawcoll, stateFilter, [])

    this.props.securitiesRawCollection.forEach((element,x) => {
      if(affectedChildren.securityTypes.length) {
        if(affectedChildren.securityTypes.indexOf(element.SecurityTypeID) > -1) {
          this.props.securitiesRawCollection[x].selected = stateFilter;
        }
      }
      if(affectedParents.length) {
        if(affectedParents.indexOf(element.SecurityTypeID) > -1) {
          this.props.securitiesRawCollection[x].selected = stateFilter;
        }
      }
      
    })
    this.props.securities.forEach((secu,i) => {
      if(affectedChildren.securities.length) {
        if(affectedChildren.securities.indexOf(secu.SecurityID) > -1) {
          this.props.securities[i].selected = stateFilter
        }
      }
    })
    if(!security.SecurityID) {
      this.props.securitiesRawCollection.forEach((element,x) => {
        if(element.SecurityTypeID === security.SecurityTypeID) {
          element.selected = stateFilter
        }
      })
    } else {
      this.props.securities.forEach((secu,i) => {
        if(secu.SecurityID === security.SecurityID) {
          secu.selected = stateFilter
        }
      })
    }
    this.props.securitiesRawCollection.forEach(el => {
      if(el.selected) {
        SF.push(el.Name);
      }
    })
    // console.log(SF)
    this.props.securities.forEach(se => {
      if(se.selected) {
        SecF.push(se.Name);
      }
    })
    let data = {
      "UserName": this.props.userData.username,
      "FilterString": SF.join(';')
    }

    let sdata = {
      "UserName": this.props.userData.username,
      "FilterString": SecF.join(';')
    }
    this.props.updateSecurityFilter(data);
    this.props.updateSecuritiesFilter(sdata)
    this.setState({
      ...this.state,
      securityFilter: SF,
      securitiesFilter: SecF
    })
    
    
  }

  render() {
    const { parentActive, parentText } = this.state;
    let securitiesList = [];
    if(parentText === 'Assets' && parentActive) {
      
      const { securityHeirarchialState } = this.state;
     
      let grpind = 0;
      securityHeirarchialState.forEach((eachGroup, groupIndex) => {
        
        
        let groupSecurityList = []
        if(this.props.securitiesRawCollection.length) {
          let secnt = 0;
          
          this.props.securitiesRawCollection.forEach((item) => {
            
            let indexchange = false
            
            if(eachGroup.indexOf(item.SecurityTypeID) > -1) {
              if(groupIndex !== grpind) { indexchange = true; grpind = groupIndex }
              groupSecurityList[secnt] = <SecurityListItem security={item} groupindex={groupIndex} akey={item.SecurityTypeID} itemmanage={this.SecListManage} indexChange={indexchange} key={item.SecurityTypeID} managefilter={this.manageFilter} selected={item.selected} rawCollection={this.props.securitiesRawCollection} secResults={this.props.securitiesResults} secs={this.props.securities} secFilter={this.props.securitiesFilter} userData={this.props.userData} />
              secnt++;
            }
          })
        }
        
        securitiesList.push(groupSecurityList);
      })
      
    }
    return (
      <div className={`securityItem`}>
        { parentText === 'Assets' ? securitiesList : '' }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.settings.userData,
    securities: state.filters.securities,
    securitiesRawCollection: state.filters.securitiesRawCollection,
    securityFilter: state.filters.securityFilter,
    securitiesResults: state.filters.securitiesResults,
    securitiesFilter: state.filters.securitiesFilter,
  }
}

export default connect(mapStateToProps, filtersActionCreators)(SecuritySetting)