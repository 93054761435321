import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import SubscriptionPopUp from './subscriptionpopuplayout';

class ConfirmationPopUp extends Component {

  render() {
    console.log(this.props)
    if(this.props.subscirptionPopup) {
      return (<SubscriptionPopUp onConfirm={() => this.props.onConfirm()} message={this.props.alertMessage()} />)
    } else {
      return (
        <SweetAlert
          title={<span style={{ fontSize: 16 }}>{this.props.alertMessage()}</span>}
          style={{ backgroundColor: '#4e4e4e', color: 'white' }}
          showCancel={!this.props.showOnlyOk}
          reverseButtons={true}
          customButtons={
            // && this.props.userPackage !== 'bronze'
            typeof this.props.userPackage !== 'undefined' ? (
              <React.Fragment>
                <>
                  {!this.props.showOnlyOk && (
                    <span
                      className="pointer custom-sweet-alert-button"
                      style={{ margin: 20 }}
                      onClick={this.props.onConfirm}
                    >
                      Confirm
                    </span>
                  )}
                  {
                    <span className="pointer custom-sweet-alert-button" onClick={this.props.onCancel}>
                      {!this.props.showOnlyOk ? 'Cancel' : 'Ok'}
                    </span>
                  }
                  {}
                </>
              </React.Fragment>
            ) : (
              <span className="pointer custom-sweet-alert-button" onClick={this.props.onConfirm}>
                Ok
              </span>
            )
          }
        />
      );
    }
    
  }
}

export default ConfirmationPopUp;
