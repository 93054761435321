import { hotjar } from 'react-hotjar';

export default class HotjarHelper {
  constructor() {
    
    this.hjid = window.location.host.includes('data.algense.com') ? 3378343 : 1655772;
    this.hjsv = 6;
    this._init();
  }

  _init = () => {
    hotjar.initialize(this.hjid, this.hjsv);
  }
}