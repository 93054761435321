import React from 'react';
// import { Link } from 'react-router-dom';
import { APPPATH } from '../../../modules/helper.module';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';

const PremiumButton = ({
  noTitle,
  align,
  beta = true,
  notLogo,
  toggleConfirmPopup,
  popup,
  minify,
  isCalledFromTrendingColumn,
}) => {
  let userName = useSelector(({ settings }) => settings.userData.username);
  let FromMobile = useSelector(({ settings }) => settings.isMobileApp);
  // console.log(userName)
  return (
    <span
      className="premium-btn-span pointer"
      
    >
      {
        <span className={`premium-btn ${!isMobile ? 'desk' : ''} ${isCalledFromTrendingColumn ? 'footer-premium' : ''} pointer`}>
          <img
            onClick={() => {
              // console.log('here')
              if (popup) {
                toggleConfirmPopup(true);
              } else {
                pushTo(userName, FromMobile);
              }
            }}
            src={`${APPPATH.ImageUrl}/btn-gopremium@2x.png`}
            className={`${notLogo ? '' : 'logo-in-premium-button'} `}
            alt="CrowdSense.ai"
          />
          {/* <button>
            <span>
              <img
                src={`${APPPATH.ImageUrl}/inner-badge-premium.svg`}
                className={`${notLogo ? '' : 'logo-in-premium-button'} ${minify ? 'premuimBtnDimension' : ''}`}
                alt="CrowdSense.ai"
              />
            </span>
            <span className="premuimbtntext">Go Premium</span>
          </button> */}
        </span>
      }
    </span>
  );
};
let pushTo = (userName, FromMobile) => {
  if (typeof userName === 'undefined') {
    userName = '';
  }
  window.Mixpanel.MixpanelProps.UserName = userName;
  window.Mixpanel.actions.identify(userName);
  window.Mixpanel.actions.track('Purchase Premium Clicked', window.Mixpanel.MixpanelProps).then((data) => {
    window.Mixpanel.actions.people.set({
      UserName: userName,
    });
    // window.open(`${APPPATH.premiumPaymentUrl}?email=${userName}`, '_blank');
    if(FromMobile) {
      window.location.href = `${APPPATH.premiumPaymentUrl}?email=${userName}`;
    } else {
      window.open(`${APPPATH.premiumPaymentUrl}?email=${userName}`, '_blank');
    }
    
  });
};
export default PremiumButton;
