import React, { Component } from 'react'; 
import { connect } from 'react-redux';
import { deactivateGlobalTooltipRemover } from '../../../actions/settings.action';

class Tooltip extends Component {
  constructor() {
    super();
    this.state = {
      showState : false,
      view :'',
      type: 'normal',
      custom: ''
    }
    this.updateTimeout = '';
  }
  componentWillMount() {
    this.setState({
      ...this.state,
      // showState : this.props.show,
      view: this.props.view,
      type: this.props.type,
      custom: this.props.custom
    })
  }
  componentWillReceiveProps(nextProps) {
    this.updateProps(nextProps)
    
  }

  updateProps = (nextProps) => {
    // console.log(nextProps)
    this.setState({
      ...this.state,
      showState : nextProps.GlobalToolTipRemover === true ? false : nextProps.show,
      view: nextProps.view,
      type: nextProps.type,
      custom: nextProps.custom
    })
  }

  componentDidUpdate(prevState, prevProps) {
    if(this.props.GlobalToolTipRemover !== prevProps.GlobalToolTipRemover) {
      if(this.props.GlobalToolTipRemover === true) {
        setTimeout(() => {
          this.props.deactivateGlobalTooltipRemover()
        }, 1500)
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.updateTimeout);
    
  }

  

  render() {
    const { view, type, custom } = this.state;
    return (

      <div className=
                  {`gen-tooltip 
                    ${type === 'normal-left'? 'normal-left' : ''} 
                    ${type === 'normal-right'? 'normal-right' : ''} 
                    ${type === 'normal-Lright'? 'normal-Lright' : ''} 
                    ${type === 'right'? 'right' : ''} 
                    ${type === 'top'? 'top' : ''} 
                    ${type === 'top-left'? 'top-left' : ''} 
                    ${type === 'left'? 'left' : ''} 
                    ${custom === 'reputation'? 'reputation' : ''} 
                  `}   
      >
      <div className={`gentooltipMenu ${!this.state.showState ? 'nodisp': ''} md-lg-screen`}>
        { view }
      </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    GlobalToolTipRemover : state.settings.toolTipGlobalRemover
  }
}

const mapDispatchToProps = {
  deactivateGlobalTooltipRemover
};

export default connect(mapStateToProps, mapDispatchToProps)(Tooltip)