import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as searchActionCreators from '../../actions/search.action';
import * as filterActionCreators from '../../actions/filters.action';
import { TMViewDetails, CloseTMDetails, searchCoinDetailTickerEvents, updatepinnedcoindetailcolumn } from '../../actions/trendingmentions.action';
import { setUserPreferences } from '../../actions/apppreferences.action';
import { switchcolumnfocus } from '../../actions/settings.action';
import 'bootstrap-daterangepicker/daterangepicker.css';
import * as moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { CustomSearchEvents } from '../../appEvents';
import { APPPATH, PreferenceHandler, TrendingMentionsHelper } from '../../modules/helper.module';
import UpgrageToPremiumPopUp from '../common/upgradeToPremium';
import { isMobile } from 'react-device-detect';

class Search extends Component {
  constructor(props) {
    super();

    this.state = {
      page: '',
      searchText: '',
      searchTextTypes: '',
      newSearchText: '',
      newSearchTextPrev: '',
      searchStartDate: '',
      searchEndDate: '',
      searchStartTime: '',
      searchEndTime: '',
      maxDate: '',
      minDate: '',
      events_lookup: [],
      // locations_lookup: [],
      searchSuggestion: [],
      searchSuggestionCursor: -1,
      searchRequest: false,
      searchListAreaWidth: '',
      searchTagLimit: -1,
      searchDropdownHandle: false,
      utcOffset: 0,
      dateRangeTipView: false,
      advancedSearchState: false,
      viewPortWidth: '',
      searchSaveInit: false,
      searchSaveTitle: '',
      SearchForEventsFromSecFilters: props.SearchForEventsFromSecFilters,
      showThePopUp: false,
    };
    this.searchBoxWrap = React.createRef();
    this.searchInput = React.createRef();
    this.tmpSearchInput = React.createRef();
    this.searchTagContainer = React.createRef();
    this.searchButton = React.createRef();
    this.searchResetButton = React.createRef();
    this.searchCalendarButton = React.createRef();
    this.searchCalendarContainer = React.createRef();
    this.searchListTags = [];
    this.searchTagSizes = [];
    this.searchListTagsDropdown = [];
    this.searchTagSizesDropdown = [];
    this.OverlayRef = React.createRef();
  }

  componentWillMount() {
    // console.log(this.props.userData)
    this.setState({
      ...this.state,
      page: this.props.page,
      searchStartDate: moment().subtract(7, 'days'),
      searchEndDate:
        this.props.userData.userPackage.toLowerCase() === 'bronze' ? moment().subtract(15, 'minutes') : moment(),
      maxDate: this.props.userData.userPackage.toLowerCase() === 'bronze' ? moment().subtract(15, 'minutes') : moment(),
      minDate: moment().subtract(28, 'days'),
      searchStartTime:
        this.props.userData.userPackage.toLowerCase() === 'bronze'
          ? moment().subtract(15, 'minutes').format('hh:mm A')
          : moment().format('hh:mm A'),
      searchEndTime:
        this.props.userData.userPackage.toLowerCase() === 'bronze'
          ? moment().subtract(15, 'minutes').format('hh:mm A')
          : moment().format('hh:mm A'),
      viewPortWidth: this.props.homeviewport.width,
    });
  }

  componentDidMount() {
    document.getElementById('searchtagcontainer').addEventListener('searchtextchange', (e) => {
      this.handleSearchListTags(e);
    });
    document.getElementById('searchtagcontainer').addEventListener('searchwidthchange', (e) => {
      this.handleSearchWidthChange(e);
    });
    this.setState({
      ...this.state,
      searchListAreaWidth: this.tmpSearchInput.current.clientWidth + this.searchTagContainer.current.clientWidth,
    });
  }

  handleSearchListTags = (e) => {
    let allowedTagWidth = parseInt(this.state.searchListAreaWidth) * (75 / 100);
    let searchTagsSizes = this.searchListTags.filter((tagEl) => tagEl).map((tagEl) => tagEl.offsetWidth);
    let searchTagsSizesDropdown = this.searchListTagsDropdown
      .filter((tagEl) => tagEl)
      .map((tagEl) => tagEl.offsetWidth);
    let allTagsSizes = searchTagsSizes.concat(searchTagsSizesDropdown);
    let actualTagWidth = 0;
    let srchTagLimit = -1;
    allTagsSizes.forEach((size, i) => {
      actualTagWidth = actualTagWidth + parseInt(size);

      if (actualTagWidth < allowedTagWidth) {
        srchTagLimit = i;
      }
    });
    this.setState({
      ...this.state,
      searchTagLimit: srchTagLimit,
    });
  };
  handleSearchWidthChange = (e) => {
    this.setState(
      {
        ...this.state,
        searchListAreaWidth: this.searchBoxWrap.current.clientWidth - (this.searchButton.current.clientWidth + this.searchResetButton.current.clientWidth ),//this.searchCalendarButton.current.clientWidth
      },
      () => {
        this.handleSearchListTags(e);
      }
    );
  };
  handleSearchSaveInit = () => {
    const { searchText, searchTextTypes, searchStartDate, searchEndDate, searchSaveInit, searchSaveTitle } = this.state;
    if (searchSaveInit) {
      let searchData = this.extractSearchContent(searchText, searchTextTypes);
      
      // console.log(searchData)
      // console.log(this.props.Search_with_gray)
      // console.log(this.props.Search_with_black)
      // console.log(this.props.Search_with_white)
      // console.log(this.props.Search_with_yellow)
      // console.log(this.props.Search_with_orange)
      // console.log(this.props.Search_with_red)
      // return
      searchData.startDate = moment(searchStartDate).format('YYYY-MM-DD HH:mm:ss');
      searchData.endDate = moment(searchEndDate).format('YYYY-MM-DD HH:mm:ss');
      searchData.freeText = this.state.newSearchText;
      searchData.withCurated = false;//this.props.Search_with_gray;
      searchData.withBlack = false;//this.props.Search_with_black;
      searchData.withWhite = false;//this.props.Search_with_white;
      searchData.withYellow = true;//this.props.Search_with_yellow;
      searchData.withOrange = true;//this.props.Search_with_orange;
      searchData.withRed = true;//this.props.Search_with_red;
      searchData.columnCollapsePreference = this.props.SearchColumnCollapsePreference;
      let savedSearchCount = Object.keys(this.props.searchSaveData).length;
      // if (savedSearchCount < 2) {
        let Data = {
          UserSavedDataID: savedSearchCount + 1,
          SavedDataTitle: searchSaveTitle !== '' ? searchSaveTitle : `Saved Search ${savedSearchCount + 1}`,
          SavedDataDetails: JSON.stringify(searchData),
          UserName: this.props.userData.username,
          columntype: 'SavedSearch',
          columnFilter: 'Search'
        };
        
        window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
        window.Mixpanel.MixpanelProps.SavedSearchData = JSON.stringify(searchData);
        window.Mixpanel.actions.identify(this.props.userData.username);
        window.Mixpanel.actions.track('Search Saved', window.Mixpanel.MixpanelProps).then((data) => {
          window.Mixpanel.actions.people.set({
            UserName: this.props.userData.username,
          });
          window.Mixpanel.actions.resetProp('SavedSearchData');
        });
        console.log(Data)
        this.props.saveCurrentSearch(Data);
        
      // } else {
      //   this.props.initSearchSave(false);
      //   this.props.toggleAlert(
      //     true,
      //     'Limit Warning',
      //     'We can save up to two searches only. Delete one search to save again'
      //   );
      //   window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
      //   window.Mixpanel.actions.identify(this.props.userData.username);
      //   window.Mixpanel.actions
      //     .track('Search Saved Failed. Limit warning issued', window.Mixpanel.MixpanelProps)
      //     .then((data) => {
      //       window.Mixpanel.actions.people.set({
      //         UserName: this.props.userData.username,
      //       });
      //     });
      // }
    }
  };

  searchEventsFromFilters = () => {
    console.log(this.props.securityTypesFilter, this.props.securitiesFilter, this.props.userpreference_NACE);
    // securityTypesFilter: state.filters.securityFilter,
    // securitiesFilter: state.filters.securitiesFilter,
    let stDate = moment(this.state.searchStartDate).utcOffset(this.state.utcOffset).format('YYYY-MM-DD HH:mm:ss');
    let ndDate = moment(this.state.searchEndDate).utcOffset(this.state.utcOffset).format('YYYY-MM-DD HH:mm:ss');
    this.props.searchEventsOnFilters(
      this.props.securityTypesFilter.join(';'),
      this.props.securitiesFilter.join(';'),
      stDate,
      ndDate,
      this.props.userpreference_NACE
    );
  };

  componentWillReceiveProps(nextProps) {
    // console.log(nextProps.singleCoinSearch, nextProps.searchCount)
    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.setState(
        {
          ...this.state,
          searchText: nextProps.searchtext,
          searchTextTypes: '',
          page: nextProps.page,
        },
        () => {
          this.props.resetSearchData();
        }
      );
    } else if (Object.keys(nextProps.searchData).length && nextProps.searchData.activeStatus) {
      if (nextProps.reSearch) {
        this.search();
        this.props.ReSearch(false);
        return;
      }
      this.setState(
        {
          ...this.state,
          searchRequest: false,
        },
        () => {
          window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
          window.Mixpanel.MixpanelProps.BeginDate = moment(this.state.searchStartDate).format('DD/MM/YYYY HH:mm:ss A');
          window.Mixpanel.MixpanelProps.EndDate = moment(this.state.searchEndDate).format('DD/MM/YYYY HH:mm:ss A');
          window.Mixpanel.MixpanelProps.SearchText = this.state.searchText.trim();
          window.Mixpanel.actions.identify(this.props.userData.username);
          window.Mixpanel.actions.track('Search Completed', window.Mixpanel.MixpanelProps).then((data) => {
            window.Mixpanel.actions.people.set({
              UserName: this.props.userData.username,
            });
            window.Mixpanel.actions.resetProp('BeginDate');
            window.Mixpanel.actions.resetProp('EndDate');
            window.Mixpanel.actions.resetProp('SearchText');
          });
        }
      );
    } else if (nextProps.singleCoinSearch) {
      this.setState(
        {
          ...this.state,
          searchRequest: false,
        },
        () => {
          window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
          window.Mixpanel.MixpanelProps.BeginDate = moment(this.state.searchStartDate).format('DD/MM/YYYY HH:mm:ss A');
          window.Mixpanel.MixpanelProps.EndDate = moment(this.state.searchEndDate).format('DD/MM/YYYY HH:mm:ss A');
          window.Mixpanel.MixpanelProps.SearchText = this.state.searchText.trim();
          window.Mixpanel.actions.identify(this.props.userData.username);
          window.Mixpanel.actions.track('Single asset Search Completed', window.Mixpanel.MixpanelProps).then((data) => {
            window.Mixpanel.actions.people.set({
              UserName: this.props.userData.username,
            });
            window.Mixpanel.actions.resetProp('BeginDate');
            window.Mixpanel.actions.resetProp('EndDate');
            window.Mixpanel.actions.resetProp('SearchText');
          });
        }
      );
    } else if (this.props.homeviewport.width !== nextProps.homeviewport.width) {
      this.setState({
        ...this.state,
        viewPortWidth: nextProps.homeviewport.width,
      });
    }
    if (this.props.searchSaveInit !== nextProps.searchSaveInit) {
      this.setState({
        ...this.state,
        searchSaveInit: nextProps.searchSaveInit,
        searchSaveTitle: nextProps.searchSaveTitle,
      });
    }
    if (
      !this.props.SearchForEventsFromSecFilters &&
      this.props.SearchForEventsFromSecFilters !== nextProps.SearchForEventsFromSecFilters
    ) {
      this.setState({
        ...this.state,
        SearchForEventsFromSecFilters: nextProps.SearchForEventsFromSecFilters,
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.searchText !== this.state.searchText) {
      document.getElementById('searchtagcontainer').dispatchEvent(CustomSearchEvents.searchTextChange);
    }
    if (prevState.viewPortWidth !== this.state.viewPortWidth) {
      document.getElementById('searchtagcontainer').dispatchEvent(CustomSearchEvents.searchWidthChange);
    }
    if (prevState.searchSaveInit !== this.state.searchSaveInit) {
      if (this.state.searchSaveInit) {
        this.handleSearchSaveInit();
      }
    }
    if (prevState.SearchForEventsFromSecFilters !== this.state.SearchForEventsFromSecFilters) {
      if (this.state.SearchForEventsFromSecFilters) {
        this.searchEventsFromFilters();
      }
    }
    if (
      prevProps.securities !== this.props.securities &&
      this.state.newSearchText !== '' &&
      typeof this.state.doneWithSearch === 'undefined'
    ) {
      this.onSearchTextChange(this.state.newSearchText);
    }
  }
  componentWillUnmount() {
    document.getElementById('searchtagcontainer').removeEventListener('searchtextchange', this.handleSearchListTags);
    document
      .getElementById('searchtagcontainer')
      .removeEventListener('searchwidthchange', this.handleSearchWidthChange);
  }
  onSearchTextChange = (text) => {
    window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
    window.Mixpanel.actions.identify(this.props.userData.username);
    window.Mixpanel.actions.track(`Search Term Initiated`, window.Mixpanel.MixpanelProps).then((data) => {
      window.Mixpanel.actions.people.set({
        UserName: this.props.userData.username,
      });
    });

    // console.log('this is search item e.target.value: ' + e.target.value);
    let srchs = text;
    let oldtext = text;

    // let searchData = this.extractSearchContent(this.searchInput.current.value, this.state.searchTextTypes)
    let eventTypes = this.props.eventTypes; /*.map(item => { return item.Name.toString().trim().toLowerCase() })*/
    // let locations = this.props.Locations/*.map(item => { return item.toString().trim().toLowerCase() })*/;
    let securityTypes = this.props.securityTypes; /*.map(item => { return item.toString().trim().toLowerCase() })*/
    let securities = this.props.securities; /*.map(item => { return item.toString().trim().toLowerCase() })*/
    let FeedSources = this.props.FeedSources;

    let evntTypeCategories = eventTypes.length ? eventTypes.filter((evntTyp) => evntTyp.EventTypeParent === 0) : [];
    // console.log(securities)
    let toSuggest = srchs ? srchs : '';
    let suggestionArrayTmp = [];
    let suggestionArray = [];
    if (srchs.length > 2) {
      if (toSuggest !== '') {
        evntTypeCategories.forEach((evnt) => {
          
          if(this.props.userData.usertype === 'None'  || this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin') {
            if(evnt.Name === 'Whale Trades' || evnt.Name === 'Chain Updates'){
              if (evnt.Name.toString().trim().toLowerCase().includes(toSuggest.toString().trim().toLowerCase())) {
                if (suggestionArrayTmp.indexOf(evnt.Name.toString().trim()) === -1) {
                  suggestionArrayTmp.push(evnt.Name.toString().trim());
                  suggestionArray.push({
                    name: evnt.Name.toString().trim(),
                    type: 'event',
                  });
                }
              }
            } else if(parseInt(evnt.Radius) % 2 > 0 || parseInt(evnt.Radius) === 0) {
              if (evnt.Name.toString().trim().toLowerCase().includes(toSuggest.toString().trim().toLowerCase())) {
                if (suggestionArrayTmp.indexOf(evnt.Name.toString().trim()) === -1) {
                  suggestionArrayTmp.push(evnt.Name.toString().trim());
                  suggestionArray.push({
                    name: evnt.Name.toString().trim(),
                    type: 'event',
                  });
                }
              }
            }
          } else {
            if(evnt.Name === 'Influencers') {
              if (evnt.Name.toString().trim().toLowerCase().includes(toSuggest.toString().trim().toLowerCase())) {
                if (suggestionArrayTmp.indexOf(evnt.Name.toString().trim()) === -1) {
                  suggestionArrayTmp.push(evnt.Name.toString().trim());
                  suggestionArray.push({
                    name: evnt.Name.toString().trim(),
                    type: 'event',
                  });
                }
              }
            } else if(parseInt(evnt.Radius) % 2 === 0 ) {
              
              if(evnt.Name !== 'Whale Trades' && evnt.Name !== 'Chain Updates') {
                if (evnt.Name.toString().trim().toLowerCase().includes(toSuggest.toString().trim().toLowerCase())) {
                  if (suggestionArrayTmp.indexOf(evnt.Name.toString().trim()) === -1) {
                    suggestionArrayTmp.push(evnt.Name.toString().trim());
                    suggestionArray.push({
                      name: evnt.Name.toString().trim(),
                      type: 'event',
                    });
                  }
                }
              } 
              
            }
          }
          
        });
      }
      if(this.props.userData.usertype === 'None'  || this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin') {
        securityTypes.forEach((secTyp) => {
          if (secTyp.Name.toString().trim().toLowerCase().includes(toSuggest.toString().trim().toLowerCase())) {
            if (suggestionArrayTmp.indexOf(secTyp.Name.toString().trim()) === -1) {
              suggestionArrayTmp.push(secTyp.Name.toString().trim());
              suggestionArray.push({
                name: secTyp.Name.toString().trim(),
                type: 'securitytype',
              });
            }
          }
        });
      }
      if (securities) {
        let secArr = [];
        securities.forEach((sec) => {
          if (
            sec.Name.toString().trim().toLowerCase().includes(toSuggest.toString().trim().toLowerCase()) ||
            sec.Symbol.toString().trim().toLowerCase().includes(toSuggest.toString().trim().toLowerCase())
          ) {
            if (suggestionArrayTmp.indexOf(sec.Name.toString().trim()) === -1) {
              suggestionArrayTmp.push(sec.Name.toString().trim());
              let parent = '';
              if(sec.SecurityParent > 0) {
                securities.forEach((secP) => {
                  if(secP.SecurityID === sec.SecurityParent) {
                    parent = secP.Name;
                  }
                })
              }
              // suggestionArray.push({
              secArr.push({
                name: `${sec.Name.toString().trim()} (${sec.Symbol.toString()})`,
                type: 'securities',
                parent: parent
              });
              securities.forEach((secChild) => {
                if (secChild.SecurityParent === sec.SecurityID) {
                  if (suggestionArrayTmp.indexOf(secChild.Name.toString().trim()) === -1) {
                    suggestionArrayTmp.push(secChild.Name.toString().trim());
                    // suggestionArray.push({
                    secArr.push({
                      name: `${secChild.Name.toString().trim()} (${secChild.Symbol.toString()})`,
                      type: 'securities',
                      parent: sec.Name
                    });
                  }
                }
              })
            }
          }
        });
        // console.log(secArr)
        // secArr.reverse();
        suggestionArray = suggestionArray.concat(secArr);
        // console.log(suggestionArray)
      }
      if(FeedSources.length) {
        FeedSources.forEach((FD) => {
          if (FD.toString().trim().toLowerCase().includes(toSuggest.toString().trim().toLowerCase())) {
            if (suggestionArrayTmp.indexOf(FD.toString().trim()) === -1) {
              suggestionArrayTmp.push(FD.toString().trim());
              suggestionArray.push({
                name: FD.toString().trim(),
                type: 'source',
              });
            }
          }
        })
      }
      // if (toSuggest !== '') {
      //   eventTypes.forEach((evnt) => {
          // if (evnt.Name.toString().trim().toLowerCase().includes(toSuggest.toString().trim().toLowerCase())) {
          //   if (suggestionArrayTmp.indexOf(evnt.Name.toString().trim()) === -1) {
          //     suggestionArrayTmp.push(evnt.Name.toString().trim());
          //     suggestionArray.push({
          //       name: evnt.Name.toString().trim(),
          //       type: 'event',
          //     });
          //   }
          // }
      //   });
      // }
    }
    // console.log(suggestionArray)
    this.setState({
      ...this.state,
      newSearchText: srchs,
      newSearchTextPrev: oldtext,
      searchSuggestion: suggestionArray,
      //searchRequest: true,
      doneWithSearch: srchs.length > 2 && securities.length <= 0 ? true : false,
    });
  };
  handleSearchText = (itemVal, itemType, actionType) => {
    // console.log(itemVal, itemType, actionType)
    let searchArr = this.state.searchText ? this.state.searchText.split(',') : [];
    // console.log(searchArr)
    let securities = this.props.securities;
    let searchTypeArr = this.state.searchTextTypes ? this.state.searchTextTypes.split(',') : [];
    let advancedDateTimeResetFlag = false;
    if (actionType === 'add') {
      window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
      window.Mixpanel.MixpanelProps.SearchTerm = itemVal;
      window.Mixpanel.actions.identify(this.props.userData.username);
      window.Mixpanel.actions.track(`Search Term Selected`, window.Mixpanel.MixpanelProps).then((data) => {
        window.Mixpanel.actions.people.set({
          UserName: this.props.userData.username,
        });
        window.Mixpanel.actions.resetProp('SearchTerm');
      });
      if (searchArr.indexOf(itemVal) === -1) {
        searchArr.push(itemVal);
        searchTypeArr.push(itemType);
      }
    } else {
      if (searchArr.indexOf(itemVal) > -1 && searchTypeArr[searchArr.indexOf(itemVal)] === itemType) {
        if (itemVal.split(':').length === 3) {
          advancedDateTimeResetFlag = true;
        }
        searchArr.splice(searchArr.indexOf(itemVal), 1);
        searchTypeArr.splice(searchTypeArr.indexOf(itemType), 1);
      }
    }
    let srchStartDate = advancedDateTimeResetFlag ? moment().subtract(7, 'days') : this.state.searchStartDate;
    let srchEndDate = advancedDateTimeResetFlag
      ? this.props.userData.userPackage.toLowerCase() === 'bronze'
        ? moment().subtract(15, 'minutes')
        : moment()
      : this.state.searchEndDate;
    let searchStartTime = advancedDateTimeResetFlag
      ? this.props.userData.userPackage.toLowerCase() === 'bronze'
        ? moment().subtract(15, 'minutes').format('hh:mm A')
        : moment().format('hh:mm A')
      : this.state.searchStartTime;
    let searchEndTime = advancedDateTimeResetFlag
      ? this.props.userData.userPackage.toLowerCase() === 'bronze'
        ? moment().subtract(15, 'minutes').format('hh:mm A')
        : moment().format('hh:mm A')
      : this.state.searchEndTime;
    this.setState(
      {
        ...this.state,
        searchText: searchArr.join(','),
        searchTextTypes: searchTypeArr.join(','),
        newSearchText: '',
        searchSuggestion: [],
        searchSuggestionCursor: -1,
        searchStartDate: srchStartDate,
        searchEndDate: srchEndDate,
        searchStartTime: searchStartTime,
        searchEndTime: searchEndTime,
        searchTagLimit: searchArr.length ? searchArr.length - 1 : -1,
      },
      () => {
        // console.log(this.state.searchText, this.state.searchTextTypes, this.props.securities)
        this.tmpSearchInput.current.focus();
        // this.search();
      }
    );
  };
  resetSearch = (e) => {
    window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
    window.Mixpanel.MixpanelProps.BeginDate = moment(this.state.searchStartDate).format('DD/MM/YYYY HH:mm:ss A');
    window.Mixpanel.MixpanelProps.EndDate = moment(this.state.searchEndDate).format('DD/MM/YYYY HH:mm:ss A');
    window.Mixpanel.MixpanelProps.SearchText = this.state.searchText.trim();
    window.Mixpanel.actions.identify(this.props.userData.username);
    window.Mixpanel.actions.track('Search Reset', window.Mixpanel.MixpanelProps).then((data) => {
      window.Mixpanel.actions.people.set({
        UserName: this.props.userData.username,
      });
      window.Mixpanel.actions.resetProp('BeginDate');
      window.Mixpanel.actions.resetProp('EndDate');
      window.Mixpanel.actions.resetProp('SearchText');
    });
    this.setState(
      {
        ...this.state,
        searchText: '',
        searchTextTypes: '',
        newSearchText: '',
        searchSuggestion: [],
        searchRequest: false,
        searchStartDate: moment().subtract(7, 'days'),
        searchEndDate:
          this.props.userData.userPackage.toLowerCase() === 'bronze' ? moment().subtract(15, 'minutes') : moment(),
        maxDate:
          this.props.userData.userPackage.toLowerCase() === 'bronze' ? moment().subtract(15, 'minutes') : moment(),
        minDate: moment().subtract(28, 'days'),
        searchStartTime:
          this.props.userData.userPackage.toLowerCase() === 'bronze'
            ? moment().subtract(15, 'minutes').format('hh:mm A')
            : moment().format('hh:mm A'),
        searchEndTime:
          this.props.userData.userPackage.toLowerCase() === 'bronze'
            ? moment().subtract(15, 'minutes').format('hh:mm A')
            : moment().format('hh:mm A'),
      },
      () => {
        const { page } = this.state;
        switch (page) {
          case 'stories':
            if (Object.keys(this.props.searchData).length && this.props.searchData.activeStatus) {
            }

            break;
          case 'securities':
            break;
          case 'settings':
            break;
          default:
            break;
        }

        //this.props.resetSearchData();
        // this.props.togglesearch()
      }
    );
  };

  checkIfTickerInTop50 = (tickerArray) => {
    const { userPackage } = this.props;
    var found = true;
    if (userPackage !== 'bronze') {
      return true;
    } else {
      //check if item is in top 50
      for (var j = 0; j < tickerArray.length; j++) {
        found = found && this.tickerExists(tickerArray[j]);
      }
    }
    return found;
  };

  tickerExists = (tickerTag) => {
    const { securities } = this.props;
    const top50Coins = securities.slice(0, 50);
    // console.log(top50Coins)
    return top50Coins.some(function (el) {
      return el.Name === tickerTag;
    });
  };
  orderTheSecurityFilterAsPerMentions = (stringArray) => {
    // console.log(this.props.securities, stringArray)
    var newRequiredArray = [];
    stringArray.forEach((item) => {
      // console.log(item)
      // console.log(this.props.securities.find((ticker) => ticker.Name === item))
      if(item === 'Binance Coin') { item = 'BNB'; }
      let RawTickerData = this.props.securities.find((ticker) => ticker.Name === item);
      if(typeof RawTickerData !== 'undefined') {
        newRequiredArray.push({
          tickerName: item,
          Scope: RawTickerData.Scope,
        });
      }
      
    });

    newRequiredArray = newRequiredArray.sort((a, b) => {
      return parseFloat(b.Scope) - parseFloat(a.Scope);
    });

    var newArray = [];
    newRequiredArray.forEach((item) => {
      newArray.push(item.tickerName);
    });

    return newArray;
  };
  addSecuritiesFilters = (securitiesdata) => {
    let addSec = false;
    let securitiesFilter = this.props.securitiesFilter.map(itm => itm).filter(itm => itm);
    if(securitiesdata.length) {
      securitiesdata.forEach((sec, i) => {
        if(securitiesFilter.indexOf(sec) === -1) {
          if(this.props.securities.length) {
            this.props.securities.forEach(itm => {
              if(itm.Name === sec) {
                addSec = true;
                securitiesFilter.push(itm.Name)
              }
            })
          }
        }
      })
    }
    // console.log(securitiesFilter.join(';'))
    if(addSec) {
      let data = {
        UserName: this.props.userData.username,
        FilterString: this.orderTheSecurityFilterAsPerMentions(securitiesFilter).join(';'),
      };
      // console.log(data.FilterString)
      this.props.updateSecuritiesFilter(data);
      
    }
    // console.log(addSec)
  }
  filterEventTypeTree = (eventTypeChildren, eventTypes, action, eventFilter) => {
    // console.log(eventTypeChildren, eventTypes, action, eventFilter)
    eventTypes.forEach((event) => {
      if (eventTypeChildren.indexOf(event.EventTypeID) > -1) {
        event.selected = action === 'remove' ? false : true;
        if (action === 'remove') {
          if (eventFilter.indexOf(event.Name.toLowerCase()) > -1) {
            eventFilter.splice(eventFilter.indexOf(event.Name), 1);
          }
        } else {
          if (eventFilter.indexOf(event.Name) === -1) {
            eventFilter.push(event.Name);
          }
        }
        if (event.children.length > 0) {
          eventFilter = this.filterEventTypeTree(event.children, eventTypes, action, eventFilter);
        }
      }
    });
    return eventFilter;
  };
  addEventtypeFilters = (eventtypedata) => {
    let addvnt = false;
    let eventtypesFilter = this.props.eventFilter.map(itm => itm).filter(itm => itm);
    let tmpevnttype = [];
    if(eventtypedata.length) {
      eventtypedata.forEach(evt => {
        if(eventtypesFilter.length) {
          eventtypesFilter.forEach(itm => {
            if(itm.toLowerCase() === evt.toLowerCase()) {
              console.log('in here', itm)
              tmpevnttype.push(itm);
            }
          })
        }
      })
      if(!tmpevnttype.length) {
        addvnt = true;
      }
      if(addvnt) {
        eventtypedata.forEach(evt => {
          this.props.eventTypes.forEach(event => {
            if(event.Name.toLowerCase() === evt.toLowerCase()) {
              eventtypesFilter.push(event.Name)
              if(event.children.length > 0) {
                eventtypesFilter = this.filterEventTypeTree(event.children, this.props.eventTypes, 'add', eventtypesFilter);
              }
            }
          })
        })
        // console.log(eventtypesFilter.join(';'))
        let data = {
          UserName: this.props.userData.username,
          FilterString: eventtypesFilter.join(';'),
        };
        this.props.updateEventSecurityFilter(data);
      }
      // console.log(addvnt)
    }
  }
  search = () => {
    if (this.state.searchText !== '' || this.state.newSearchText) {
      const { searchText, searchTextTypes, page, searchStartDate, searchEndDate } = this.state;
      let freeText = this.state.newSearchText;
      let searchData = this.extractSearchContent(searchText, searchTextTypes);
      searchData.freetext = freeText;
      // console.log(searchData)
      // console.log(searchData.sec, searchData.event);
      this.addSecuritiesFilters(searchData.sec);
      this.addEventtypeFilters(searchData.event);
      // return;
      // console.log('SearchText:',this.state.searchText,'newSearchText:', this.state.newSearchText)
      //check if ticker in top 50
      // if (freeText !== '') {
        let stDate = moment(searchStartDate).utcOffset(this.state.utcOffset).format('YYYY-MM-DD HH:mm:ss');
        let ndDate =
          searchTextTypes.split(',').indexOf('DateTime') > -1
            ? moment(searchEndDate).utcOffset(this.state.utcOffset).format('YYYY-MM-DD HH:mm:ss')
            : this.props.userData.userPackage.toLowerCase() === 'bronze'
            ? moment().utcOffset(this.state.utcOffset).subtract(15, 'minutes').format('YYYY-MM-DD HH:mm:ss')
            : moment().utcOffset(this.state.utcOffset).format('YYYY-MM-DD HH:mm:ss');
        this.setState(
          {
            ...this.state,
            searchRequest: true,
            searchSuggestion: [],
            doneWithSearch: false,
          },
          () => {
            switch (page) {
              case 'stories':
                window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
                window.Mixpanel.MixpanelProps.BeginDate = moment(this.state.searchStartDate).format(
                  'DD/MM/YYYY HH:mm:ss A'
                );
                window.Mixpanel.MixpanelProps.EndDate = moment(this.state.searchEndDate).format(
                  'DD/MM/YYYY HH:mm:ss A'
                );
                window.Mixpanel.MixpanelProps.SearchText = searchText.trim();
                window.Mixpanel.actions.identify(this.props.userData.username);
                window.Mixpanel.actions.track('Search Invoked', window.Mixpanel.MixpanelProps).then((data) => {
                  window.Mixpanel.actions.people.set({
                    UserName: this.props.userData.username,
                  });
                  window.Mixpanel.actions.resetProp('BeginDate');
                  window.Mixpanel.actions.resetProp('EndDate');
                  window.Mixpanel.actions.resetProp('SearchText');
                });
                if(searchData.src.length && !searchData.sec.length && !searchData.secTyp.length && !searchData.event.length && searchData.freetext === '' && searchTextTypes.split(',').indexOf('DateTime') === -1) {
                  this.props.showModal('InvalidSearch', 'Please add more seach terms such as topics, securities, event types, or free text.')
                  this.setState({
                    ...this.state,
                    searchRequest: false,
                    searchSuggestion: [],
                  })
                } else if (searchData.sec.length === 1 && !searchData.secTyp.length && !searchData.event.length && searchData.freetext === '' && searchTextTypes.split(',').indexOf('DateTime') === -1) { //if only coin to search
                  let MarketCap = (this.props.userData.usertype !== 'None' && (this.props.userData.usertype !== 'WUser' && this.props.userData.usertype !== 'WAdmin')) ? 0 : parseInt(this.props.securities.filter(itm => itm.Name === searchData.sec[0])[0].Scope);
                  // console.log(this.props.userData.usertype, MarketCap, searchData, parseInt(this.props.securities.filter(itm => itm.Name === searchData.sec[0])[0].Scope));
                  // console.log((this.props.userData.usertype !== 'None' && (this.props.userData.usertype !== 'WUser' && this.props.userData.usertype !== 'WAdmin')))
                  // return;
                    if(this.props.userData.usertype !== 'None' && (this.props.userData.usertype !== 'WUser' && this.props.userData.usertype !== 'WAdmin')) {
                      // this.props.TMViewDetails({
                      //   SecurityTicker: searchData.ticker[0],
                      //   SecurityName: searchData.sec[0],
                      // }, false, true, true);
                      
                      let listkey = this.props.columnlist[this.props.columnlist.length -1]
                      this.pinCoinColumn({
                        SecurityTicker: searchData.ticker[0],
                        SecurityName: searchData.sec[0],
                        SourceFilter: searchData.src && searchData.src.length ? searchData.src.join('**') : ''
                      }, listkey);
                      
                      this.setState({
                        ...this.state,
                        searchRequest: true,
                        searchSuggestion: [],
                        doneWithSearch: false,
                      },() => {
                        this.props.singlecoinsearched();
                      });
                    } else if(MarketCap > 10000000) { //market cap is greater show coin column
                      // this.props.TMViewDetails({
                      //   SecurityTicker: searchData.ticker[0],
                      //   SecurityName: searchData.sec[0],
                      // }, false, true, true);
                      let listkey = this.props.columnlist[this.props.columnlist.length -1]
                      console.log(searchData.src, listkey)
                      // return;
                      this.pinCoinColumn({
                        SecurityTicker: searchData.ticker[0],
                        SecurityName: searchData.sec[0],
                        SourceFilter: searchData.src && searchData.src.length ? searchData.src.join('**') : ''
                      }, listkey);
                      this.setState({
                        ...this.state,
                        searchRequest: true,
                        searchSuggestion: [],
                        doneWithSearch: false,
                      },() => {
                        this.props.singlecoinsearched();
                      });
                    } else { // market cap is less show regular search
                      const searchsavetitle = 'Search Results'
                      const saveInit = true;
                      this.props.searchTitleSave()
                      this.setState({
                        ...this.state,
                        searchRequest: false,
                      })
                      // this.props.initSearchSave(saveInit, searchsavetitle);
                      // this.props.searchEvents(
                      //   false,
                      //   searchData.event.join(';'),
                      //   searchData.secTyp.join(';'),
                      //   searchData.sec.join(';'),
                      //   stDate,
                      //   ndDate,
                      //   freeText,
                      //   `${searchText},${freeText}`,
                      //   'EventSearch',
                      //   false,
                      //   this.props.Search_with_gray,
                      //   this.props.Search_with_black,
                      //   this.props.Search_with_yellow,
                      //   this.props.Search_with_orange,
                      //   this.props.Search_with_red
                      // );
                    }
                    
                  // }
                  
                } else { // if more than one coin or other items to search show regular search
                    const searchsavetitle = 'Search Results'
                    const saveInit = true;
                    // this.props.initSearchSave(saveInit, searchsavetitle);
                    this.props.searchTitleSave()
                    this.setState({
                      ...this.state,
                      searchRequest: false,
                    })
                  // this.props.searchEvents(
                  //   false,
                  //   searchData.event.join(';'),
                  //   searchData.secTyp.join(';'),
                  //   searchData.sec.join(';'),
                  //   stDate,
                  //   ndDate,
                  //   freeText,
                  //   `${searchText},${freeText}`,
                  //   'EventSearch',
                  //   false,
                  //   this.props.Search_with_gray,
                  //   this.props.Search_with_black,
                  //   this.props.Search_with_yellow,
                  //   this.props.Search_with_orange,
                  //   this.props.Search_with_red
                  // );
                } 
                break;
              case 'securities':
                break;
              case 'settings':
                break;
              default:
                break;
            }
          }
        );
      // }

      
      
    }
  };
  extractSearchContent = (Text, Type) => {
    let textArr = Text.split(',');
    let typeArr = Type.split(',');
    let evntArr = [];
    let secTypArr = [];
    let secArr = [];
    let secTickerArr = [];
    let srcArr = [];

    textArr.forEach((ele, i) => {
      switch (typeArr[i]) {
        case 'event':
          evntArr.push(ele.trim());
          break;
        case 'securitytype':
          secTypArr.push(ele.trim());
          break;
        case 'securities':
          var ticker = ele.substring(ele.indexOf('('), ele.indexOf(')') + 1);
          secTickerArr.push(ticker.substring(1, ticker.length - 1));
          secArr.push(ele.replace(ele.substring(ele.indexOf('('), ele.indexOf(')') + 1), '').trim());
          break;
        case 'source':
          srcArr.push(ele.trim());
          break;
        default:
          break;
      }
    });
    return { event: evntArr, secTyp: secTypArr, sec: secArr, ticker: secTickerArr, src: srcArr };
  };
  onApply = (event, picker) => {
    let stDate = moment(picker.startDate);
    let endDate = moment(picker.endDate);
    // console.log(picker.startDate, picker.endDate)
    let sr_startDate = moment(picker.startDate).format('DD/MM/YYYY');
    let sr_endDate = moment(picker.endDate).format('DD/MM/YYYY');
    let sr_startTime = moment(picker.startDate).format('hh:mm A');
    let sr_endTime = moment(picker.endDate).format('hh:mm A');
    // console.log(sr_startDate, sr_endDate, sr_startTime, sr_endTime)
    // console.log(this.state.searchText);
    let srchDatatags = this.makeSearchDateTag(sr_startDate, sr_endDate, sr_startTime, sr_endTime);
    let searchTextArr = srchDatatags.srchtxtarr;
    let searchTextTypeArr = srchDatatags.srchtxttyparr;

    this.setState(
      {
        ...this.state,
        searchStartDate: stDate,
        searchEndDate: endDate,
        searchText: searchTextArr.length > 1 ? searchTextArr.join(',') : searchTextArr[0],
        searchTextTypes: searchTextTypeArr.length > 1 ? searchTextTypeArr.join(',') : searchTextTypeArr[0],
        searchTagLimit: searchTextArr.length ? searchTextArr.length - 1 : -1,
      },
      () => {
        window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
        window.Mixpanel.MixpanelProps.BeginDate = moment(this.state.searchStartDate).format('DD/MM/YYYY HH:mm:ss A');
        window.Mixpanel.MixpanelProps.EndDate = moment(this.state.searchEndDate).format('DD/MM/YYYY HH:mm:ss A');
        window.Mixpanel.MixpanelProps.SearchText = this.state.searchText;

        window.Mixpanel.actions.identify(this.props.userData.username);
        window.Mixpanel.actions.track(`Search Date Time Selected`, window.Mixpanel.MixpanelProps).then((data) => {
          window.Mixpanel.actions.people.set({
            UserName: this.props.userData.username,
          });
          window.Mixpanel.actions.resetProp('BeginDate');
          window.Mixpanel.actions.resetProp('EndDate');
          window.Mixpanel.actions.resetProp('SearchText');
        });
      }
    );
  };
  onShow = (event, picker) => {
    if (this.props.userData.userPackage.toLowerCase() === 'bronze') {
      let minutes = picker.container.find('.drp-calendar.right .minuteselect').children();
      let endminute = moment(picker.endDate).format('mm');
      minutes.filter(`:gt(${parseInt(endminute)})`).remove();
    }
  };
  onApplyDate = (evnt, picker, dateState) => {
    let stDate = dateState === 'start_Date' ? moment(picker.startDate) : this.state.searchStartDate;
    let endDate = dateState === 'end_Date' ? moment(picker.endDate) : this.state.searchEndDate;
    let searchTextArr = this.makeSearchDateTag(stDate, endDate, this.state.searchStartTime, this.state.searchEndTime);
    // console.log(stDate, endDate)

    this.setState(
      {
        ...this.state,
        searchStartDate: stDate,
        searchEndDate: endDate,
        searchText: searchTextArr.length > 1 ? searchTextArr.join(',') : searchTextArr[0],
      },
      () => {
        // console.log(this.state)
      }
    );
  };

  onApplyTime = (time, selector) => {
    // console.log(time, selector)
    let stTime = selector === 'start_Time' ? time : this.state.searchStartTime;
    let endTime = selector === 'end_Time' ? time : this.state.searchEndTime;
    let searchTextArr = this.makeSearchDateTag(this.state.searchStartDate, this.state.searchEndDate, stTime, endTime);
    this.setState({
      ...this.state,
      searchStartTime: stTime,
      searchEndTime: endTime,
      searchText: searchTextArr.length > 1 ? searchTextArr.join(',') : searchTextArr[0],
    });
  };
  makeSearchDateTag = (searchStartDate, searchEndDate, searchStartTime, searchEndTime) => {
    let searchTextArr = this.state.searchText ? this.state.searchText.split(',') : [];
    let searchTypesArr = this.state.searchTextTypes ? this.state.searchTextTypes.split(',') : [];
    // console.log(`${moment(searchStartDate).format('DD/MM/YYYY')} ${searchStartTime}`)
    let newSearchString = `${searchStartDate} ${searchStartTime} - ${searchEndDate} ${searchEndTime}`;
    if (searchTextArr.length) {
      if (searchTextArr[0].split(':').length === 3) {
        searchTextArr[0] = newSearchString;
        searchTypesArr[0] = 'DateTime';
      } else {
        searchTextArr.unshift(newSearchString);
        searchTypesArr.unshift('DateTime');
      }
    } else {
      searchTextArr.push(newSearchString);
      searchTypesArr.push('DateTime');
    }

    return { srchtxtarr: searchTextArr, srchtxttyparr: searchTypesArr };
  };
  showDateRangeTip = () => {
    this.setState({
      ...this.state,
      searchStartDate: moment().subtract(7, 'days'),
      searchEndDate:
        this.props.userData.userPackage.toLowerCase() === 'bronze' ? moment().subtract(15, 'minutes') : moment(),
      maxDate: this.props.userData.userPackage.toLowerCase() === 'bronze' ? moment().subtract(15, 'minutes') : moment(),
      minDate: moment().subtract(28, 'days'),
      searchStartTime:
        this.props.userData.userPackage.toLowerCase() === 'bronze'
          ? moment().subtract(15, 'minutes').format('hh:mm A')
          : moment().format('hh:mm A'),
      searchEndTime:
        this.props.userData.userPackage.toLowerCase() === 'bronze'
          ? moment().subtract(15, 'minutes').format('hh:mm A')
          : moment().format('hh:mm A'),
      dateRangeTipView: true,
    });
  };
  hideDateRangeTip = () => {
    this.setState(
      {
        ...this.state,
        searchStartDate: moment().subtract(7, 'days'),
        searchEndDate:
          this.props.userData.userPackage.toLowerCase() === 'bronze' ? moment().subtract(15, 'minutes') : moment(),
        maxDate:
          this.props.userData.userPackage.toLowerCase() === 'bronze' ? moment().subtract(15, 'minutes') : moment(),
        minDate: moment().subtract(28, 'days'),
        searchStartTime:
          this.props.userData.userPackage.toLowerCase() === 'bronze'
            ? moment().subtract(15, 'minutes').format('hh:mm A')
            : moment().format('hh:mm A'),
        searchEndTime:
          this.props.userData.userPackage.toLowerCase() === 'bronze'
            ? moment().subtract(15, 'minutes').format('hh:mm A')
            : moment().format('hh:mm A'),
        dateRangeTipView: false,
      },
      () => {
        window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
        window.Mixpanel.MixpanelProps.BeginDate = moment(this.state.searchStartDate).format('DD/MM/YYYY HH:mm:ss A');
        window.Mixpanel.MixpanelProps.EndDate = moment(this.state.searchEndDate).format('DD/MM/YYYY HH:mm:ss A');
        window.Mixpanel.MixpanelProps.SearchText = this.state.searchText;

        window.Mixpanel.actions.identify(this.props.userData.username);
        window.Mixpanel.actions.track(`Search Date Time Initiated`, window.Mixpanel.MixpanelProps).then((data) => {
          window.Mixpanel.actions.people.set({
            UserName: this.props.userData.username,
          });
          window.Mixpanel.actions.resetProp('BeginDate');
          window.Mixpanel.actions.resetProp('EndDate');
          window.Mixpanel.actions.resetProp('SearchText');
        });
      }
    );
  };

  advancedSearch = () => {
    this.setState({
      ...this.state,
      advancedSearchState: !this.state.advancedSearchState,
    });
  };

  applyAdvSrch = () => {
    let srchDatatag = this.makeSearchDateTag(
      this.state.searchStartDate,
      this.state.searchEndDate,
      this.state.searchStartTime,
      this.state.searchEndTime
    );
    let searchTextArr = srchDatatag.srchtxtarr;
    let searchTextTypeArr = srchDatatag.srchtxttyparr;
    this.setState(
      {
        ...this.state,
        searchText: searchTextArr.length > 1 ? searchTextArr.join(',') : searchTextArr[0],
        searchTextTypes: searchTextTypeArr.length > 1 ? searchTextTypeArr.join(',') : searchTextTypeArr[0],
        advancedSearchState: false,
      },
      () => {
        // console.log(this.state)
      }
    );
  };

  ifKeyActionInit = (e) => {
    // console.log(e.keyCode)
    if (e.keyCode === 8) {
      // console.log(this.state.newSearchText)
      if (e.target.value === '') {
        let searchTextArr = this.state.searchText !== '' ? this.state.searchText.split(',') : [];
        let searchTextTypesArr = this.state.searchTextTypes !== '' ? this.state.searchTextTypes.split(',') : [];
        let itemVal = searchTextArr[searchTextArr.length - 1];
        let itemType = searchTextTypesArr[searchTextArr.length - 1];
        this.handleSearchText(itemVal, itemType, 'remove');
      }
    } else if (e.keyCode === 38) {
      if (this.state.newSearchText !== '') {
        if (this.state.searchSuggestion.length) {
          if (this.state.searchSuggestionCursor >= 0) {
            this.setState(
              {
                ...this.state,
                searchSuggestionCursor: this.state.searchSuggestionCursor - 1,
              },
              () => {
                if (this.state.searchSuggestionCursor > -1) {
                  ReactDOM.findDOMNode(this.refs[`suggitem${this.state.searchSuggestionCursor}`]).focus();
                }
              }
            );
          }
        }
      }
    } else if (e.keyCode === 40) {
      if (this.state.newSearchText !== '') {
        if (this.state.searchSuggestion.length) {
          if (this.state.searchSuggestionCursor < this.state.searchSuggestion.length - 1) {
            this.setState(
              {
                ...this.state,
                searchSuggestionCursor: this.state.searchSuggestionCursor + 1,
              },
              () => {
                ReactDOM.findDOMNode(this.refs[`suggitem${this.state.searchSuggestionCursor}`]).focus();
              }
            );
          }
        }
      }
    } else if (e.keyCode === 27) {
      this.setState({
        enterPressed: true,
      });
      if (this.state.newSearchText === '') {
        if (this.state.searchText === '') {
          this.resetSearch();
        } else {
          this.setState({
            ...this.state,
            searchSuggestion: [],
            searchSuggestionCursor: -1,
            searchRequest: false,
          });
        }
      } else {
        this.setState({
          ...this.state,
          searchSuggestion: [],
          searchSuggestionCursor: -1,
          searchRequest: false,
        });
      }
    } else {
      // console.log(e.keyCode)
    }
  };

  SearchFormSubmit = (e, ssc) => {
    // console.log(ssc)
    // console.log(e)
    e.preventDefault();
    e.stopPropagation();
    if (this.state.newSearchText === '') {
      if (this.state.searchText !== '') {
        this.search();
      }
    } else {
      // console.log(this.state.newSearchText, this.state.searchSuggestion.length, this.state.searchSuggestionCursor)
      if (this.state.searchSuggestion.length) {
        if (this.state.searchSuggestionCursor > -1) {
          // console.log(this.state.newSearchText, this.state.searchSuggestion.length, this.state.searchSuggestionCursor)
          let itemVal = this.state.searchSuggestion[this.state.searchSuggestionCursor].name;
          let itemType = this.state.searchSuggestion[this.state.searchSuggestionCursor].type;
          this.handleSearchText(itemVal, itemType, 'add');
        } else {
          this.search();
        }
      } else {
        this.search();
      }
    }
  };

  toggleSearchDropdownToggle = () => {
    this.setState({
      ...this.state,
      searchDropdownHandle: !this.state.searchDropdownHandle,
    });
  };
  entering = (e) => {
    e.children[1].style.backgroundColor = '#37aaf8';
  };

  CloseCoinDetailColumn = (columnIndex, listkey) => {
    let { tmDetailPreferences, coin_column_preferences, columnlist  } = this.props;
    let preferences = JSON.parse(JSON.stringify(this.props.preferences));
    if(preferences.user_interface.HasColumnOrderSettings === false) {
      preferences.user_interface.HasColumnOrderSettings = true;
    }
    let columnorderlist = JSON.parse(JSON.stringify(this.props.columnorderlist))
    let removeAtIndex = columnlist.indexOf(listkey)

    let tm_prefs = JSON.parse(JSON.stringify(tmDetailPreferences));
    tm_prefs.splice(columnIndex, 1);

    columnorderlist.splice(removeAtIndex, 1)

    let PreferenceStrings = PreferenceHandler.CreatePreferencesStrings(preferences);
    let CoinCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(coin_column_preferences);
    let CoinDetailCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(tm_prefs, 'assetdetails');
    let ColumnOrderStrings = PreferenceHandler.CreateColumnOrderString(columnorderlist)
    let newPrefStr = `${PreferenceStrings.userPrefData.UserPreferencesString}${CoinCoulmnStrings}${CoinDetailCoulmnStrings}${ColumnOrderStrings}`
    // console.log(newPrefStr);
    PreferenceStrings.userPrefData.UserPreferencesString = newPrefStr;
    PreferenceStrings.userPrefData.UserName = this.props.userData.username;
    // this.props.CloseCoinDetailsColumn(columnIndex, SecurityName)
    if(typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser) {
      this.props.setUserPreferences_LLU(PreferenceStrings.userPrefData);
    } else {
      this.props.setUserPreferences(PreferenceStrings.userPrefData);
    }
    
  }

  pinCoinColumn = (TickerData, listkey) => {
    // console.log('Pin coin column starts')
    // console.log(TickerData, listkey)
    // console.log(this.props.columnlist)

    // return
    try {
      this.props.showModal('Loader');
      let { tmDetailPreferences, coin_column_preferences, columnlist } = this.props;
      // console.log(this.props.columnorderlist, tmDetailPreferences)
      let preferences = JSON.parse(JSON.stringify(this.props.preferences));
      let columnorderlist = JSON.parse(JSON.stringify(this.props.columnorderlist))
      
      let tm_prefs = JSON.parse(JSON.stringify(tmDetailPreferences));
      
      if(tm_prefs.length) {
        // check if columnfilter variable is defined and assigned to settings
        let hascolumnFilter = true
        tm_prefs.forEach(itm => {
          if(typeof itm.columnFilter === 'undefined' || itm.columnFilter === '') {
            hascolumnFilter = false
          }
        })
        //columnfilter variable  is not defined and assigned to settings
        // assigning column filter to column order settings and column data settings
        if(!hascolumnFilter) {
          let columnFilter = '';
          columnorderlist.forEach((column, i) => {
            if(column.columntype === 'tm') {
              columnFilter = 'Trending'
            } else if(column.columntype === 'tmcd') {
              column.columnFilter = columnFilter
              tm_prefs.forEach(itm => {
                if(itm.SecurityName === column.SecurityName && itm.SecurityTicker === column.SecurityTicker) {
                  itm.columnFilter = columnFilter
                }
              })
            } else if(column.columntype === 'Main Feeds') {
              columnFilter = 'Inbox'
            } else if(column.columntype === 'SavedSearch') {
              columnFilter = 'Search'
            }
          })
        }
      }
      
      
      if(preferences.user_interface.HasColumnOrderSettings === false) {
        preferences.user_interface.HasColumnOrderSettings = true;
      }
      let newPrefObj = TrendingMentionsHelper.newCoinDetailColumnPreference(TickerData);
      newPrefObj.columnFilter = 'Search'
      // console.log(newPrefObj, tm_prefs, columnorderlist)
      // return
      let samesectionduplicateindex = ''
      if(columnorderlist.length) {
        columnorderlist.forEach((col, i) => {
          if(col.columntype === 'tmcd' && col.SecurityName === newPrefObj.SecurityName && col.SecurityTicker === newPrefObj.SecurityTicker && col.columnFilter === newPrefObj.columnFilter && (col.sourceFilter && newPrefObj.sourceFilter && col.sourceFilter === newPrefObj.sourceFilter)) {
            let listkey = columnlist[i];
            let tm_pref_id = listkey.split('__')[1];
            if(tm_pref_id !== '') {
              
              if(tm_prefs[tm_pref_id].SecurityName === newPrefObj.SecurityName && tm_prefs[tm_pref_id].SecurityTicker === newPrefObj.SecurityTicker && tm_prefs[tm_pref_id].columnFilter === newPrefObj.columnFilter && (tm_prefs[tm_pref_id].sourceFilter && newPrefObj.sourceFilter && tm_prefs[tm_pref_id].sourceFilter === newPrefObj.sourceFilter)) {
                samesectionduplicateindex = tm_pref_id
              }
            }
          }
        })
      }
      // if(tm_prefs.length) {
      //   tm_prefs.forEach((itm, i) => {
      //     if(itm.SecurityName === newPrefObj.SecurityName && itm.SecurityTicker === newPrefObj.SecurityTicker && itm.columnFilter === newPrefObj.columnFilter) {
      //       samesectionduplicateindex = i
      //     }
      //   })
      // }
      // console.log('same section duplicte', samesectionduplicateindex)
      // return
      if(samesectionduplicateindex !== '') {
        let existingColumnName = `Coin_Column_details__${samesectionduplicateindex}` 
        // console.log(SecNameArr, existingColumnName)
        // console.log('duplicate columns in tmdetailspreferences')
        setTimeout(() => {
          // console.log('calling modal close, same column exists')
          // this.props.SetPostponeUpdate(false);
          this.props.modalClose();
        }, 1500)
        this.props.switchcolumnfocus(existingColumnName)
        
      } else {
        // console.log('new preference', newPrefObj, tm_prefs, columnlist, columnorderlist, listkey)
        // return
        tm_prefs.push(newPrefObj)
        let insertAfterIndex = columnlist.indexOf(listkey)
        columnorderlist.splice(insertAfterIndex +1, 0, newPrefObj)
        // console.log(columnorderlist)
        // check if column filter category has more than the specified amount of column
        let new_columnFilter = newPrefObj.columnFilter
        let new_columnFilter_count = 0
        columnorderlist.forEach((column, i) => {
          if(new_columnFilter === 'Search') {
            if(column.columntype === 'SavedSearch') {
              new_columnFilter_count++;
            } else if(column.columntype === 'tmcd' && column.columnFilter === 'Search') {
              new_columnFilter_count++;
            }
            // console.log('for search filter count', new_columnFilter_count)
          } else {
            if(column.columntype === 'tmcd') {
              if(column.columnFilter === new_columnFilter) {
                new_columnFilter_count++;
              }
            }
          }
          
        })
        
        // console.log(tm_prefs, this.props.tmDetailPreferences, columnorderlist, columnlist)
        // return
        // if column filter category has more than the specified amount of column remove the oldest one in the category
        if(new_columnFilter === 'Search') {
          if(new_columnFilter_count > 4) {
            let remove_search_index = false;
            let removingsearchcolumntype = ''
            columnorderlist.forEach((column, rm_indx) => {
              if(column.columntype === 'SavedSearch') {
                if(!remove_search_index) {
                  remove_search_index = rm_indx
                  removingsearchcolumntype = 'SavedSearch'
                }
                
              } else if(column.columntype === 'tmcd' && column.columnFilter === 'Search') {
                if(!remove_search_index) {
                  remove_search_index = rm_indx
                  removingsearchcolumntype = 'tmcd'
                }
              }
            })
            if(removingsearchcolumntype === 'SavedSearch') {
              let saveid = columnorderlist[remove_search_index].UserSavedDataID
              let deletedData = this.props.searchSaveData[saveid];
              // console.log(deletedData)
              if(typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser) {
                this.props.deleteSavedSearch_LLU(deletedData);
              } else {
                this.props.deleteSavedSearch(deletedData);
              }
              
              columnorderlist.splice(remove_search_index, 1)
            } else if(removingsearchcolumntype === 'tmcd') {
              let remove_tmpref_index = false
              tm_prefs.forEach((itm, ind) => {
                if(itm.SecurityName === columnorderlist[remove_search_index].SecurityName && itm.SecurityTicker === columnorderlist[remove_search_index].SecurityTicker && (itm.sourceFilter && columnorderlist[remove_search_index].sourceFilter && itm.sourceFilter === columnorderlist[remove_search_index].sourceFilter)) {
                  if(remove_tmpref_index === false) {
                    remove_tmpref_index = ind
                  }
                }
              })
              if(remove_tmpref_index !== false) {
                let settingstoremove = tm_prefs[remove_tmpref_index]
                let removelistkey = `Coin_Column_details__${remove_tmpref_index}`
                let orderremoveAtIndex = false//columnlist.indexOf(removelistkey)
                columnorderlist.forEach((column, index) => {
                  if(column.columntype === 'tmcd') {
                    if(column.SecurityName === settingstoremove.SecurityName && column.SecurityTicker === settingstoremove.SecurityTicker && column.columnFilter === new_columnFilter && (column.sourceFilter && settingstoremove.sourceFilter && column.sourceFilter === settingstoremove.sourceFilter)) {
                      if(orderremoveAtIndex === false) {
                        orderremoveAtIndex = index
                      }
                    }
                  }
                })
                let tmp_columnorderlist = JSON.parse(JSON.stringify(columnorderlist))
                tmp_columnorderlist.splice(orderremoveAtIndex, 1)
                columnorderlist = JSON.parse(JSON.stringify(tmp_columnorderlist))

                let tmp_tm_prefs = JSON.parse(JSON.stringify(tm_prefs))
                tm_prefs.splice(remove_tmpref_index, 1)
                tm_prefs = JSON.parse(JSON.stringify(tmp_tm_prefs))
              }
            }
            
          }
        } else {
          if(new_columnFilter_count > 3) {
            let remove_tmpref_index = false
            tm_prefs.forEach((itm, indx) => {
              if(itm.columnFilter === new_columnFilter) {
                if(remove_tmpref_index === false) {
                  remove_tmpref_index = indx
                }
              }
            })
            
            if(remove_tmpref_index !== false) {
              
              let settingstoremove = tm_prefs[remove_tmpref_index]
              // console.log(remove_tmpref_index, settingstoremove)
              let removelistkey = `Coin_Column_details__${remove_tmpref_index}`
              let orderremoveAtIndex = false//columnlist.indexOf(removelistkey)
              columnorderlist.forEach((column, index) => {
                if(column.columntype === 'tmcd') {
                  if(column.SecurityName === settingstoremove.SecurityName && column.SecurityTicker === settingstoremove.SecurityTicker && column.columnFilter === new_columnFilter ) {
                    if(orderremoveAtIndex === false) {
                      orderremoveAtIndex = index
                    }
                  }
                }
              })
              // console.log(tm_prefs, remove_tmpref_index, columnorderlist, orderremoveAtIndex)
              let tmp_columnorderlist = JSON.parse(JSON.stringify(columnorderlist))
              tmp_columnorderlist.splice(orderremoveAtIndex, 1)
              columnorderlist = JSON.parse(JSON.stringify(tmp_columnorderlist))

              let tmp_tm_prefs = JSON.parse(JSON.stringify(tm_prefs))
              tmp_tm_prefs.splice(remove_tmpref_index, 1)
              tm_prefs = JSON.parse(JSON.stringify(tmp_tm_prefs))
              // console.log(tmp_tm_prefs, remove_tmpref_index, tmp_columnorderlist, orderremoveAtIndex)
              
            }
            
          }
        }
        //update settings 
        let searchData = {
          sec : [`${TickerData.SecurityName}`],
          startDate : moment().utcOffset(0).subtract(7,'days').format('YYYY-MM-DD HH:mm:ss'),
          endDate : moment().utcOffset(0).format('YYYY-MM-DD HH:mm:ss'),
          withCurated : newPrefObj.CDSearch_with_gray,
          withBlack : newPrefObj.CDSearch_with_black,
          withWhite : newPrefObj.CDSearch_with_white,
          withYellow : newPrefObj.CDSearch_with_yellow,
          withOrange : newPrefObj.CDSearch_with_orange,
          withRed : newPrefObj.CDSearch_with_red,
          sourceFilters: newPrefObj.sourceFilter
        }
        let TickerFeedReqData = {
          RequestState: true, 
          InitialFeedRequest: searchData.startDate, 
          LastFeedRequest: searchData.endDate,
          LastFeedRequestEnd: searchData.endDate,
          InitialFeedResponse: searchData.endDate,
          LastFeedResponse: '', 
        }
        // console.log(tm_prefs, columnorderlist)
      
        let PreferenceStrings = PreferenceHandler.CreatePreferencesStrings(preferences);
        let CoinCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(coin_column_preferences);
        let CoinDetailCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(tm_prefs, 'coindetails');
        let ColumnOrderStrings = PreferenceHandler.CreateColumnOrderString(columnorderlist)
        
        let newPrefStr = `${PreferenceStrings.userPrefData.UserPreferencesString}${CoinCoulmnStrings}${CoinDetailCoulmnStrings}${ColumnOrderStrings}`
      
        
        PreferenceStrings.userPrefData.UserPreferencesString = newPrefStr;
        PreferenceStrings.userPrefData.UserName = this.props.userData.username;
        // console.log(CoinDetailCoulmnStrings)
        // return
        this.props.CloseTMDetails()

        this.props.updatepinnedcoindetailcolumn()
        if(typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser) {
          this.props.setUserPreferences_LLU(PreferenceStrings.userPrefData, false, false, 'tmcd', '', false, TickerData);
        } else {
          this.props.setUserPreferences(PreferenceStrings.userPrefData, false, false, 'tmcd', '', false, TickerData);
        }
        
        // this.props.loadtickerdata(TickerData.SecurityName, TickerData.SecurityTicker, tm_prefs.length -1);
        this.props.searchCoinDetailTickerEvents(searchData, tm_prefs.length -1, TickerData.SecurityName);
      }
    } catch(err) {
      this.props.showModal('ErrorMsg', 'There seems to be a temporary error. Please refresh or try later. If the problem persists, ');
    }
    

    
  }

  feedSourceIcon = (source) => {
    let sourceType = source
    let feedIcon = '';
    // console.log(sourceType)
    switch (sourceType) {
      case 'Local News English':
        feedIcon = <img src={`${APPPATH.ImageUrl}/local_news_english.svg`} className="timelineIndicator margright2" alt="CrowdSense.ai" />
        break;
      case 'International News English':
        feedIcon = <img src={`${APPPATH.ImageUrl}/international_news_english.svg`} className="timelineIndicator margright2" alt="CrowdSense.ai" />;
        break;
      case 'Financial News':
        feedIcon = <img src={`${APPPATH.ImageUrl}/financial_news_1.svg`} className="timelineIndicator margright2" alt="CrowdSense.ai" />;
        break;
      case 'Financial News Aggregator':
        feedIcon = <img src={`${APPPATH.ImageUrl}/financial_news_1.svg`} className="timelineIndicator margright2" alt="CrowdSense.ai" />;
        break;
      case 'Bloomberg':
        feedIcon = <img src={`${APPPATH.ImageUrl}/financial_news_1.svg`} className="timelineIndicator margright2" alt="CrowdSense.ai" />;
        break;
      case 'Reuters':
        feedIcon = <img src={`${APPPATH.ImageUrl}/financial_news_1.svg`} className="timelineIndicator margright2" alt="CrowdSense.ai" />;
        break;
      case 'Reddit':
        feedIcon = <img src={`${APPPATH.ImageUrl}/reddit_feed_source.svg`} className="timelineIndicator margright2" alt="CrowdSense.ai" />;
        break;
      case 'Telegram':
        feedIcon = <img src={`${APPPATH.ImageUrl}/telegram_feed_source.svg`} className="timelineIndicator margright2" alt="CrowdSense.ai" />;
        break;
      case 'Local News Local Language':
        feedIcon = <img src={`${APPPATH.ImageUrl}/local_news_local_language.svg`} className="timelineIndicator margright2" alt="CrowdSense.ai" />;
        break;
      case 'WebSites':
        feedIcon = <img src={`${APPPATH.ImageUrl}/general_feed_source1_1.svg`} className="timelineIndicator margright2" alt="CrowdSense.ai" />;
        break;
      case 'TwitterScraping':
        feedIcon = <img src={`${APPPATH.ImageUrl}/twitter.svg`} className="timelineIndicator margright2" alt="CrowdSense.ai" />;
        break;
      case 'Twitter':
        feedIcon = <img src={`${APPPATH.ImageUrl}/twitter.svg`} className="timelineIndicator margright2" alt="CrowdSense.ai" />;
        break;
      case 'TwitterScraping|Financial News':
        feedIcon = <img src={`${APPPATH.ImageUrl}/financial_news_1.svg`} className="timelineIndicator margright2" alt="CrowdSense.ai" />;
        break;
      case 'Twitter Financial News':
        feedIcon = <img src={`${APPPATH.ImageUrl}/financial_news_1.svg`} className="timelineIndicator margright2" alt="CrowdSense.ai" />;
        break;
      case 'Twitter Financial User':
        feedIcon = <img src={`${APPPATH.ImageUrl}/financial_news_1.svg`} className="timelineIndicator margright2" alt="CrowdSense.ai" />;
        break;
      case 'Telegram Financial News':
        feedIcon = <img src={`${APPPATH.ImageUrl}/financial_news_1.svg`} className="timelineIndicator margright2" alt="CrowdSense.ai" />;
        break;
      case 'Telegram Financial User':
        feedIcon = <img src={`${APPPATH.ImageUrl}/financial_news_1.svg`} className="timelineIndicator margright2" alt="CrowdSense.ai" />;
        break;
      case 'Telegram|Financial User':
        feedIcon = <img src={`${APPPATH.ImageUrl}/financial_news_1.svg`} className="timelineIndicator margright2" alt="CrowdSense.ai" />;
        break;
      default:
        feedIcon = <img src={`${APPPATH.ImageUrl}/general_feed_source_1.svg`} className="timelineIndicator margright2" alt="CrowdSense.ai" />;
        break;
    }
    // if(source.toLowerCase().includes('twitter')) {
    //   feedIcon = 'twitter';
    // }
    return feedIcon;
  };

  

  render() {
    const {
      searchSuggestion,
      searchSuggestionCursor,
      searchRequest,
      searchStartDate,
      searchEndDate,
      maxDate,
      minDate,
    } = this.state;
    // console.log(searchStartDate, searchEndDate)
    // console.log(maxDate, minDate)
    let searchItemsArr = this.state.searchText.split(',');
    let searchItemTypesArr = this.state.searchTextTypes.split(',');
    let suggestionList = searchSuggestion.length
      ? searchSuggestion.map((item, count) => {
          let itemType;
          let AddRemoveType;
          // console.log(item.type)
          switch (item.type) {
            case 'event':
              itemType = <i className={`fa fa-podcast`}></i>;
              break;
            case 'securitytype':
              itemType = <i className={`fa fa-line-chart`}></i>;
              break;
            case 'securities':
              itemType = <i className={`fa fa-line-chart`}></i>;
              break;
            case 'source':
              itemType = this.feedSourceIcon(item.name);
              break;
            default:
              break;
          }
          AddRemoveType =
            searchItemsArr.indexOf(item.name) > -1 &&
            searchItemTypesArr[searchItemsArr.indexOf(item.name)] === item.type
              ? 'remove'
              : 'add';

          return (
            <div
              className={`suggestion-items row marg-0 h-100 ${searchSuggestionCursor === count ? 'active' : ''}`}
              key={`item.name${count}`}
              onClick={() => {
                this.handleSearchText(item.name, item.type, AddRemoveType);
              }}
              ref={`suggitem${count}`}
            >
              <div className={`suggestiontype col-1 align-self-center ${this.props.Theme === 'light' ? 'lightmode' : ''}`} style={{ display: 'inline-block' }}>
                {/* <i className={`fa ${itemType}`}></i> */}
                { itemType }
              </div>
              <div className="suggestionname col-9 align-self-center" style={{ display: 'inline-block' }}>
                {
                  typeof item.parent !== 'undefined' && item.parent !== '' ? (
                    <span>{`${item.parent}`}<span style={{position: 'absolute', fontSize: 20}}>&nbsp;{`>`}</span></span>
                  ) : ('')
                }
                <span>{item.name}</span>
              </div>
              <div className="suggestionaction col-2 align-self-center" style={{ display: 'inline-block' }}>
                <button
                  type="button"
                  className="btn btn-suggestionAdd"
                  onClick={() => {
                    this.handleSearchText(item.name, item.type, AddRemoveType);
                  }}
                >
                  {AddRemoveType === 'add' ? (
                    'Add'
                  ) : AddRemoveType === 'remove' ? (
                    <i className="fa fa-times"></i>
                  ) : (
                    'Add'
                  )}
                </button>
              </div>
            </div>
          );
        })
      : '';

    let searchList = [];
    let searchListDropDown = [];
    let searchDropDownHandle = '';
    this.searchListTags = [];
    this.searchListTagsDropdown = [];
    if (this.state.searchText !== '') {
      this.state.searchText.split(',').forEach((srch, i) => {
        let itemType;
        let AddRemoveType;

        switch (this.state.searchTextTypes.split(',')[i]) {
          case 'event':
            itemType = <i className={`fa fa-podcast`}></i>;
            break;
          case 'securitytype':
            itemType = <i className={`fa fa-line-chart`}></i>;
            break;
          case 'securities':
            itemType = <i className={`fa fa-line-chart`}></i>;
            break;
          case 'source':
            itemType = this.feedSourceIcon(srch);
            break;
          default:
            break;
        }

        if (srch.split(':').length === 3) {
          itemType = 'fa-calendar';
        }
        AddRemoveType = 'remove';
        // if (i <= this.state.searchTagLimit) {
          searchList.push(
            <span
              className={`searchTags ${this.props.Theme === 'light' ? 'lightmode' : ''}`}
              key={srch}
              ref={(el) => {
                this.searchListTags.push(el);
              }}
            >
              {/* <i className={`fa ${itemType}`}></i> */}
              { itemType }
              <span style={{ marginTop: -1 }}>{srch}</span>
              <i
                className="fa fa-times"
                onClick={() => {
                  this.handleSearchText(
                    srch,
                    this.state.searchTextTypes.split(',')[i],
                    this.state.searchTextTypes.split(',')[i],
                    AddRemoveType
                  );
                }}
              ></i>
            </span>
          );
        // } else {
        //   searchListDropDown.push(
        //     <div className="searchTags-dropdown-item" key={srch}>
        //       <span
        //         className="searchTags-dropdown"
        //         ref={(el) => {
        //           this.searchListTagsDropdown.push(el);
        //         }}
        //       >
        //         <i className={`fa ${itemType}`}></i>
        //         {srch}
        //         <i
        //           className="fa fa-times"
        //           onClick={() => {
        //             this.handleSearchText(
        //               srch,
        //               this.state.searchTextTypes.split(',')[i],
        //               this.state.searchTextTypes.split(',')[i],
        //               AddRemoveType
        //             );
        //           }}
        //         ></i>
        //       </span>
        //     </div>
        //   );
        // }
      });
    }
    if (searchListDropDown.length) {
      searchDropDownHandle = (
        <span className="searchTags pointer" key={'srchDrpDwnHndle'}>
          <span className="searchHandlebadge">+{searchListDropDown.length}</span>
          <i
            className={`fa fa-forward ${this.state.searchDropdownHandle ? 'transform90n' : 'transform90'}`}
            onClick={this.toggleSearchDropdownToggle}
          ></i>
          <div className={`searchlistdropdown ${this.state.searchDropdownHandle ? '' : 'vishid'}`}>
            {searchListDropDown}
          </div>
        </span>
      );
    }

    return (
      <>
        <div className="search-wrap">
          <Row className="marg-0">
            <Col lg={12} sm={12} xs={12} md={12} xl={12} className="nopad ">
              <div className="searchActionBox ">
                <Row className="marg-0 nopad">
                  <Col lg={12} sm={12} xs={12} md={12} xl={12} className="nopad text-left">
                    <form className="nopad marg-0" onSubmit={this.SearchFormSubmit}>
                      <div className="input-group nopad my-input-group " ref={this.searchBoxWrap}>
                        {!searchRequest ? (
                          // <i className="fa fa-search search-init fs" onClick={this.search} ref={this.searchButton}></i>
                          <img
                            src={`${APPPATH.ImageUrl}/searchNew.svg`}
                            className=" search-init-srchicon"
                            alt=""
                            onClick={this.search}
                            ref={this.searchButton}
                          />
                        ) : (
                          <i
                            className={`fa  fa-asterisk fa-spin search-init-spin ${searchRequest ? 'nodisp' : ''}`}
                            ref={this.searchButton}
                          ></i>
                        )}
                        <div className={`padt4 `} ref={this.searchTagContainer} id="searchtagcontainer">
                          {searchList}
                          {searchDropDownHandle}
                        </div>
                        <input
                          type="text"
                          name="home-search"
                          id="home-search"
                          className={`form-control my-form-control home-search margt2p ${this.props.Theme === 'light' ? 'lightmode' : ''}`}
                          placeholder={`${this.state.searchText ? '' : (this.props.userData.usertype !== 'None' && (this.props.userData.usertype !== 'WUser' && this.props.userData.usertype !== 'WAdmin')) ? 'Type topic, symbol, event, or text' : 'Type asset, event, or text'}`}
                          aria-label="Search"
                          onChange={(e) => this.onSearchTextChange(e.target.value)}
                          onKeyDown={this.ifKeyActionInit}
                          value={this.state.newSearchText}
                          ref={this.tmpSearchInput}
                          autoComplete="off"
                        />

                        <input
                          type="hidden"
                          name="home-search-actual"
                          id="home-search-actual"
                          placeholder="Type asset, event, or text"
                          aria-label="Search"
                          value={this.state.searchText}
                          ref={this.searchInput}
                        />

                        {this.state.newSearchText !== '' ? (
                          <span
                            className=""
                            /* onMouseEnter={(e) => window.createRipple(e, {left: 1.7, top: 3})}*/ style={{
                              paddingTop: 3,
                              paddingRight: 4,
                              position: 'relative',
                            }}
                          >
                            <img
                              src={`${APPPATH.ImageUrl}/button-close.svg`}
                              className=" float-right search-init"
                              onClick={this.resetSearch}
                              ref={this.searchResetButton}
                              alt=""
                            />
                          </span>
                        ) : (
                          <img
                            src={`${APPPATH.ImageUrl}/button-close.svg`}
                            className=" float-right search-init hide-search"
                            ref={this.searchResetButton}
                            alt=""
                          />
                        )}

                        {/* <DateRangePicker
                          startDate={searchStartDate}
                          endDate={searchEndDate}
                          maxDate={maxDate}
                          minDate={minDate}
                          onApply={this.onApply}
                          onShow={this.onShow}
                          autoApply={true}
                          timePicker={true}
                          opens={'left'}
                          showDropdowns
                        >
                          <span
                            className=""
                            style={{ paddingBottom: 8, paddingRight: 10, paddingLeft: 5, position: 'relative' }}
                            onMouseEnter={(e) => {
                               this.showDateRangeTip();
                            }}
                            onMouseLeave={this.hideDateRangeTip}
                            onClick={this.hideDateRangeTip}
                            ref={this.searchCalendarContainer}
                          >
                            <img
                              src={`${APPPATH.ImageUrl}/button-calendar.svg`}
                              className=" pointer"
                              ref={this.searchCalendarButton}
                              alt=""
                            />
                          </span>
                          <div>
                            <Row className={`marg-0 date-range-tip ${!this.state.dateRangeTipView ? 'nodisp' : ''}`}>
                              <Col xs={12} sm={12} md={12} xl={12} lg={12} className="tipHead">
                                <Row className="marg-0">
                                  <Col xs={6} sm={6} md={6} xl={6} lg={6}>
                                    Start Date
                                  </Col>
                                  <Col xs={6} sm={6} md={6} xl={6} lg={6}>
                                    End Date
                                  </Col>
                                </Row>
                              </Col>
                              <Col xs={12} sm={12} md={12} xl={12} lg={12} className="tipItem ">
                                <Row className="marg-0">
                                  <Col className="brdr-rt-blue pad5" xs={6} sm={6} md={6} xl={6} lg={6}>
                                    {moment(searchStartDate).format('DD/MM/YYYY HH:mm:ss A')}
                                  </Col>
                                  <Col className="brdr-rt-blue pad5" xs={6} sm={6} md={6} xl={6} lg={6}>
                                    {moment(searchEndDate).format('DD/MM/YYYY HH:mm:ss A')}
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        </DateRangePicker> */}
                        {
                          (this.props.userData.usertype === 'None'  || this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin') && (
                            <span
                              ref={this.OverlayRef}
                              style={{ position: 'relative', padding: 5 }}
                              onMouseEnter={(e) => {
                                let ToolTipStyleData = {
                                  Arrow: {
                                    marginLeft: '5px',
                                  },
                                  ToolTip: {
                                    align: 'right',
                                  },
                                };
                                this.props.toggleOverlay(
                                  true,
                                  this.OverlayRef,
                                  'Watch search bar video tutorial',
                                  ToolTipStyleData
                                );
                              }}
                              onMouseLeave={(e) => this.props.toggleOverlay(false)}
                            >
                              <img
                                style={{ marginRight: 5, marginLeft: -5 }}
                                src={`${APPPATH.ImageUrl}/video-help.png`}
                                className="helper-image"
                                alt=""
                                onClick={() => this.props.showModal('search')}
                              />
                            </span>
                          )
                        }
                        
                      </div>
                    </form>
                    {this.state.doneWithSearch && (
                      <Row className="justify-content-center" style={{ textAlign: 'center' }}>
                        <div className={`searchTip padding ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>
                          <div className="suggestion-wrap" style={{ top: '50%', left: '50%' }}>
                            <i className="fa fa-spinner fa-2x fa-spin"></i>
                          </div>
                        </div>
                      </Row>
                    )}

                    <div className={`searchTip cScroll ${suggestionList ? '' : 'nodisp'} ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>
                      <div className="suggestion-wrap">{suggestionList}</div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
        {/* {this.state.showThePopUp && (
          <UpgrageToPremiumPopUp
            onConfirm={() =>
              this.setState({
                showThePopUp: false,
              })
            }
          />
        )} */}
      </>
    );
  }
}

const mapStateToComponent = (state) => {
  let Securities = state.filters.securities;
  // Securities =
  //   Securities.length > 0 &&
  //   Securities.sort((a, b) => {
  //     return parseFloat(b.Scope) - parseFloat(a.Scope);
  //   });
  return {
    userData: state.settings.userData,
    eventTypes: state.filters.eventTypes,
    userPackage: state.settings.userData.userPackage.toLowerCase(),
    searchData: state.search.searchData,
    eventFilter: state.filters.eventFilter,
    securityTypes: state.filters.securitiesRawCollection,
    securityTypesFilter: state.filters.securityFilter,
    securitiesFilter: state.filters.securitiesFilter,
    FeedSources: state.filters.FeedSources,
    securities: Securities,
    searchSaveInit: state.search.searchSaveInit,
    searchSaveTitle: state.search.searchSaveTitle,
    searchSaveData: state.search.searchSaveData,
    Search_with_gray: state.app_preferences.preferences.search_preferences.Search_with_gray,
    Search_with_black: state.app_preferences.preferences.search_preferences.Search_with_black,
    Search_with_white: state.app_preferences.preferences.search_preferences.Search_with_white,
    Search_with_yellow: state.app_preferences.preferences.search_preferences.Search_with_yellow,
    Search_with_orange: state.app_preferences.preferences.search_preferences.Search_with_orange,
    Search_with_red: state.app_preferences.preferences.search_preferences.Search_with_red,
    reSearch: state.search.reSearch,
    SearchForEventsFromSecFilters: state.feeds.SearchForEventsFromSecFilters,
    userpreference_NACE: state.app_preferences.preferences.event_importance_filters.Non_Active_Curated_Events.Show_NACE,
    SearchColumnCollapsePreference: state.app_preferences.preferences.user_interface.Search_Collapsed_View,
    singleCoinSearch: state.search.singleCoinSearch,
    searchCount: state.search.searchCount,
    tmDetailPreferences: state.trendingMentions.tmDetailPreferences,
    coin_column_preferences: state.trendingMentions.coin_column_preferences,
    preferences: state.app_preferences.preferences,
    columnorderlist: state.settings.columnorderlist,
    columnlist: state.settings.columnlist,
    Theme: state.app_preferences.preferences.user_interface.Theme,
  };
};

const mapDispatchToProps = {
  ...searchActionCreators,
  ...filterActionCreators,
  TMViewDetails,
  CloseTMDetails, 
  searchCoinDetailTickerEvents,
  setUserPreferences,
  switchcolumnfocus,
  updatepinnedcoindetailcolumn
};
export default connect(mapStateToComponent, mapDispatchToProps)(Search);
