import React, { Component } from 'react'; 
import { Row, Col } from 'react-bootstrap';
import html2canvas from 'html2canvas';
import { APPPATH } from '../../../../modules/helper.module';
import Logo from '../../headbar/logo';
import { isMobile } from "react-device-detect";



class StoryShareMenuIndex extends Component {
    constructor(props) {
        super();
        this.state = {
            story: props.data.Story,
            FeedId: props.data.FeedId,
            imageLoading: true
        }
        this.StoryCaptureWrap = React.createRef();
        this.StoryImage = React.createRef();
        if(navigator.clipboard) {
            navigator.permissions.query({ name: 'clipboard-write' })
          
        }
    }

    componentDidMount() {
        const { FeedId } = this.state
        
        
        this.makeImage(FeedId)
        
    }
    // parseImageData = (img) => {
    //     const canvas = document.createElement('canvas');
    //     const ctx = canvas.getContext('2d');
    //     canvas.width = img.width;
    //     canvas.height = img.height;
    //     ctx.drawImage(img, 0, 0);
    //     console.log('hhere')
    //     return canvas.toDataURL('image/png');
    // }

    // cacheImages = (html) => {
    //     html.querySelectorAll('img').forEach(img => {
    //         let src = img.getAttribute('src');
    //         if(src.startsWith('https://coinsicons.s3.eu-west-2.amazonaws.com')) {
    //             img.setAttribute('crossorigin', 'anonymous')
    //             let imageData = this.parseImageData(img);
    //             console.log(imageData)
    //             img.setAttribute('src', imageData)
    //             console.log(img)
    //         }
    //     });
    //     console.log(html)
    //     return html;
    // }

    removeCoinImages = (html) => {
        console.log('here')
            html.querySelectorAll('img').forEach(img => {
                let src = img.getAttribute('src');
                if(src.startsWith('https://coinsicons.s3.eu-west-2.amazonaws.com')) {
                    img.style.display = 'none';
                }
            });
            console.log(html)
            return html;
    }

    reAddCoinImages = (html) => {
        html.querySelectorAll('img').forEach(img => {
            let src = img.getAttribute('src');
            if(src.startsWith('https://coinsicons.s3.eu-west-2.amazonaws.com')) {
                img.style.display = 'inline-block';
            }
        });
        console.log(html)
    }

    makeImage = (FeedId) => {
        let StoryHtml = document.getElementById(`${FeedId}`);
        // let processedHtml = this.cacheImages(StoryHtml)
        let processedHtml = this.removeCoinImages(StoryHtml)
        html2canvas(processedHtml, {
            dpi: 288,
            // scale: 2,
            useCORS: true,
            onclone: (documentClone) => {
                documentClone.querySelector('.storyShareButton').style.display = 'none';
                documentClone.querySelector('.optionMenuButton').style.display = 'none';
                documentClone.querySelector('.storyExpandIcon').style.display = 'none';
                documentClone.querySelectorAll('.repindicator').forEach(element => {
                    element.style.display = 'none';
                });
                documentClone.querySelectorAll('.feedlinkicon').forEach(element => {
                    element.style.display = 'none';
                });
            }
        }).then((canvas) => {
            let img = canvas.toDataURL('image/png');
            this.StoryImage.setAttribute('src', img)
            this.StoryImage.setAttribute('width', `${isMobile ? '240px' : '600px'}`)
            this.StoryImage.style.borderRadius = '5px';
            this.StoryImage.style.border = '0px transparent';
            document.getElementById('scrimgLoader').style.display = 'none';
            this.reAddCoinImages(processedHtml);
            this.setState({
                ...this.state,
                imageLoading:  false
            })
        })
    }

    

    copyImage = () => {
        
            let imageHtml = document.getElementById(`storyWrapCapture`);
            
            html2canvas(imageHtml, {
                dpi: 288,
                // scale: 2,
                useCORS: true,
                onclone: (documentClone) => {
                    documentClone.querySelector('#storyWrapCapture').style.backgroundColor = '#1B1B25';
                }
                
            }).then((canvas) => {
                navigator.permissions.query({ name: 'clipboard-write' }).then(result => {
                    if(result.state === 'granted') {
                        const { ClipboardItem } = window;
                        canvas.toBlob(blob => navigator.clipboard.write([new ClipboardItem({[`${blob.type}`]: blob})]))
                        
                    }
                })
            })
    }
    copyText = () => {
        let content = this.state.story.HeadlineText
        const el = document.createElement('textarea');
        el.value = content;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    }

    

    render() {

        
        return(
            <div className="storyshare-content pad10 text-center">
                <div className="btn storymenuoption-close text-white nopad marg-0"  onClick={this.props.modalClose}>
                    <span className="pad5" ><img  className="storymenuoption-closeicon" src={`${APPPATH.ImageUrl}/modalcloseicon.svg`} alt="" /></span>
                </div> 
                <Row className="marg-0 justify-content-center  pad10 text-center">
                    <Col lg={10} sm={10} xs={10} md={10} xl={10} className="nopad text-center bg-body">
                        
                        <Row className={`marg-0 justify-content-center  text-center pad10`} id="scrimgLoader">
                            <Col>
                                <i className="fa fa-spinner fa-pulse fa-fw feed-detail-spinner"></i>
                            </Col>
                        </Row>
                        <Row className="marg-0 justify-content-center  text-center pad10 " id="storyWrapCapture">
                            
                            
                            <Col lg={12} sm={12} xs={12} md={12} xl={12}className="nopad text-center ">
                                <img src="" ref={(el) => this.StoryImage = el} className="" alt="" />
                            </Col>
                            <div className="pad3"></div>
                            <Col lg={12} sm={12} xs={12} md={12} xl={12}className="nopad text-right padr10">
                                <div className="dispinb fs10 padt10 bold text-offWhitealertfeed">Source :&nbsp;</div>
                                <div className="dispinb"><Logo noTitle={false} align={false} beta={false} /></div>
                                
                            </Col>
                        </Row>
                        
                    </Col>
                </Row>
                <Row className="marg-0 justify-content-center  pad10 text-center">
                    <Col lg={10} sm={10} xs={10} md={10} xl={10} className="nopad text-center">
                        <Row className="marg-0 justify-content-center  pad10 text-center">
                            <Col className="nopad text-center">
                                <button type="button" className="btn btn-sm btn-crowdsense-border" onClick={this.copyImage} disabled={this.state.imageLoading}>Copy event as image</button>
                            </Col>
                            <Col className="nopad text-center">
                                <button type="button" className="btn btn-sm btn-crowdsense-border" onClick={this.copyText} disabled={this.state.imageLoading}>Copy event as text</button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default StoryShareMenuIndex