import React, { Component } from 'react';
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import { APPPATH, TrendingMentionsHelper, PreferenceHandler } from '../../../modules/helper.module';
import * as preferencesActionCreator from '../../../actions/apppreferences.action';
import * as settingsActionCreators from '../../../actions/settings.action';
import * as tmActionCreators from '../../../actions/trendingmentions.action';
import { isMobile } from 'react-device-detect';
import { SpanishTranslations, PortugueseTranslations, JapaneseTranslations, ArabicTranslations } from '../../../static_data/translations';

class LogmenuIframe extends Component {
    constructor() {
        super();
        this.state = {
            logoutTip: false,
            searchToggler: true,
            userData: {},
            ismobile: false,
            theme: 'dark'
        };
        this.OverlayRef = React.createRef();
        // this.OverlayRef1 = React.createRef();
        this.OverlayRef2 = React.createRef();
        // this.OverlayRef3 = React.createRef();
        this.OverlayRef4 = React.createRef();
        // this.OverlayRef5 = React.createRef();
        this.OverlayRef6 = React.createRef();
        this.OverlayRef7 = React.createRef();
        this.OverlayRef8 = React.createRef();
        this.OverlayRef9 = React.createRef();
        // this.OverlayRef10 = React.createRef();
        // this.OverlayRef11 = React.createRef();
        // this.OverlayRef12 = React.createRef();
        // this.OverlayRefNewTopCoin = React.createRef();
        // this.OverlayRefMyRecentUpdates = React.createRef();
    }
    componentWillMount() {
        this.setState({
            ...this.state,
            userData: this.props.userData,
            theme: this.props.Theme
        });
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.closemainmenuiframe !== nextProps.closemainmenuiframe && nextProps.closemainmenuiframe === true) {
            this.setState({
                ...this.state,
                logoutTip: false,
                userData: nextProps.userData,
                theme: nextProps.Theme,
            }, () => {
                this.props.closeMainMenu_Iframe();
            });
        } else {
            this.setState({
                ...this.state,
                userData: nextProps.userData,
                theme: nextProps.Theme,
            });
        }

    }
    toggleLogout = () => {
        this.setState({
            ...this.state,
            logoutTip: !this.state.logoutTip,
        });
    }

    themechange = () => {
        this.setState({
            ...this.state,
            theme: this.state.theme === 'dark' ? 'light' : 'dark'
        }, () => {
            this.props.closeTMWatchlistMenu(true);
            this.props.closeEventImportanceFilterMenu(true);
            this.props.updateforcetheme(false);
            let preferences = { ...this.props.preferences };
            // console.log(preferences)
            // return;
            let { coin_column_preferences, tmDetailPreferences } = this.props;
            let columnorderlist = JSON.parse(JSON.stringify(this.props.columnorderlist))

            preferences.user_interface.Theme = this.state.theme;
            window.localStorage.setItem(`${APPPATH.AppUrl}${this.props.userData.username}Theme`, this.state.theme)

            let PreferenceStrings = PreferenceHandler.CreatePreferencesStrings(preferences);
            let CoinCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(coin_column_preferences);
            let CoinDetailCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(tmDetailPreferences, 'coindetails');
            let ColumnOrderStrings = PreferenceHandler.CreateColumnOrderString(columnorderlist)
            Object.keys(PreferenceStrings).map((StrType) => {
                PreferenceStrings[`${StrType}`].UserName = this.props.userData.username;
                return null;
            });
            // console.log(PreferenceStrings.userPrefData.UserPreferencesString);
            PreferenceStrings.userPrefData.UserPreferencesString = `${PreferenceStrings.userPrefData.UserPreferencesString}${CoinCoulmnStrings}${CoinDetailCoulmnStrings}${ColumnOrderStrings}`
            window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
            window.Mixpanel.MixpanelProps.Category = 'User Interface';
            window.Mixpanel.MixpanelProps.Theme = preferences.user_interface.Theme;
            window.Mixpanel.actions.identify(this.props.userData.username);
            window.Mixpanel.actions.track(`Close Recent Updates Column`, window.Mixpanel.MixpanelProps).then((data) => {
                window.Mixpanel.actions.people.set({
                    UserName: this.props.userData.username,
                });
                window.Mixpanel.actions.resetProp('Category');
                window.Mixpanel.actions.resetProp('Theme');
            });
            if (typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser) {
                this.props.setUserPreferences_LLU(PreferenceStrings.userPrefData);
            } else {
                this.props.setUserPreferences(PreferenceStrings.userPrefData);
            }
        })

        // console.log(preferences)

    }

    redirector = (e) => {
        let target = e.currentTarget.dataset.to;
        this.props.redirect(target);
    }

    render() {
        let ToolTipStyleData = {};
        if (this.props.lang && this.props.lang === 'ara') {
            ToolTipStyleData = {
                Arrow: {
                    marginRight: '15px',
                },
                ToolTip: {
                    align: 'right',
                },
            }
        } else {
            ToolTipStyleData = {
                Arrow: {
                    marginLeft: '15px',
                },
                ToolTip: {
                    align: 'left',
                },
            };
        }
        return (
            <Col xs={1} sm={1} md={1} xl={1} lg={1} className={`nopad TDeck-window-head-content-title align-self-center ${this.props.Theme === 'light' ? 'lightmode ' : ''} ${this.props.iframenav || this.props.iframefilters ? 'iframenav' : ''} ${this.props.lang && this.props.lang === 'ara' ? 'arabic text-left' : 'text-center'} dispinb`}>
                <i className={`fa fa-align-justify pointer`}
                    onClick={(e) => {
                        e.stopPropagation();
                        this.toggleLogout();
                    }}
                ></i>
                <div className={`logout-tooltip ${!this.state.logoutTip ? 'nodisp' : ''}`} >
                    <Row className={`marg-0 logout-tooltipMenu ${this.props.lang && this.props.lang === 'ara' ? 'arabic' : ''} ${!this.state.logoutTip ? 'nodisp' : ''}`}>
                        <Col xs={12} sm={12} md={12} xl={12} lg={12} className={`tipHead ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>
                            <Row className="h-100 marg-0">
                                <Col xs={1} sm={1} md={1} xl={1} lg={1} className="logout-usericon">
                                    <i className="fa fa-user-circle"></i>
                                </Col>
                                <Col className="logout-username">
                                    <span>
                                        {
                                            typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser ?
                                                this.props.userData.loginlessuser : this.props.userData.username
                                        }
                                    </span>
                                </Col>
                                <Col xs={1} sm={1} md={1} xl={1} lg={1} className="logout-close" onClick={(e) => { e.stopPropagation(); this.toggleLogout() }}>
                                    <i className="fa fa-times"></i>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} sm={12} md={12} xl={12} lg={12} className={`tipItem ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>
                            <ul className="log-menu">
                                <li>
                                    <div>
                                        <i className="fa fa-picture-o log-menu-icon"></i>
                                        <span className="log-menu-title">
                                            {
                                                this.props.lang && this.props.lang === 'spa' ? `${SpanishTranslations['Bright']} / ${SpanishTranslations['Dark']}`
                                                    : this.props.lang && this.props.lang === 'por' ? `${PortugueseTranslations['Bright']} / ${PortugueseTranslations['Dark']}`
                                                        : this.props.lang && this.props.lang === 'jap' ? `${JapaneseTranslations['Bright']} / ${JapaneseTranslations['Dark']}`
                                                            : this.props.lang && this.props.lang === 'ara' ? `${ArabicTranslations['Bright']} / ${ArabicTranslations['Dark']}`
                                                                : 'Bright / Dark'
                                            }
                                        </span>
                                        <span className="float-right">
                                            <label className="sec-switch theme" onClick={(e) => e.stopPropagation()}>
                                                <input type="checkbox" checked={this.props.Theme === 'dark' ? true : false} onChange={() => { this.themechange() }} value={1} />
                                                <span className={`sec-slider theme  round`}></span>
                                            </label>
                                        </span>
                                    </div>
                                </li>
                                <li>
                                    <div
                                        ref={this.OverlayRef}
                                        style={{ position: 'relative' }}
                                        onMouseEnter={(e) =>
                                            this.props.toggleOverlay(
                                                true,
                                                this.OverlayRef,
                                                this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['Add or remove coins from your watchlist']
                                                    : this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['Add or remove coins from your watchlist']
                                                        : this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['Add or remove coins from your watchlist']
                                                            : this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['Add or remove coins from your watchlist']
                                                                : 'Add or remove coins from your watchlist',
                                                ToolTipStyleData
                                            )
                                        }
                                        onMouseLeave={(e) => this.props.toggleOverlay(false)}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            this.props.checkWatchList();
                                            this.toggleLogout();
                                        }}
                                    >
                                        <i className="fa fa-eye log-menu-icon"></i>
                                        <span className="log-menu-title">

                                            {
                                                this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['Update Watchlist']
                                                    : this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['Update Watchlist']
                                                        : this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['Update Watchlist']
                                                            : this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['Update Watchlist']
                                                                : 'Update Watchlist'
                                            }
                                        </span>
                                    </div>
                                </li>
                                <li>
                                    <div
                                        ref={this.OverlayRef2}
                                        style={{ position: 'relative' }}
                                        onMouseEnter={(e) =>
                                            this.props.toggleOverlay(
                                                true,
                                                this.OverlayRef2,
                                                this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['Choose the events to see news in your Crypto News Widget']
                                                    : this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['Choose the events to see news in your Crypto News Widget']
                                                        : this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['Choose the events to see news in your Crypto News Widget']
                                                            : this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['Choose the events to see news in your Crypto News Widget']
                                                                : 'Choose the events to see news in your Crypto News Widget',
                                                ToolTipStyleData
                                            )
                                        }
                                        onMouseLeave={(e) => this.props.toggleOverlay(false)}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            this.props.EventTypeList();
                                            this.toggleLogout();
                                        }}
                                    >
                                        <i className="fa fa-podcast log-menu-icon"></i>
                                        <span className="log-menu-title">
                                            {
                                                this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['Update Events Filter']
                                                    : this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['Update Events Filter']
                                                        : this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['Update Events Filter']
                                                            : this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['Update Events Filter']
                                                                : 'Update Events Filter'
                                            }
                                        </span>
                                    </div>
                                </li>
                                <li className=" menu-divider" ></li>
                                <li className="">
                                    <a
                                        rel="noopener noreferrer"
                                        href={this.props.userData.usertype === 'None' ? "https://www.algense.com/toc" : (this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin') ? "https://www.algense.com/toc" : "https://www.algense.com/toc"}
                                        data-to="TermsAndConditions"
                                        target="_blank"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            // window.open(this.props.userData.usertype === 'None' ? "https://www.crowdsense.ai/toc" : (this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin') ? "http://algense.com/toc" : "https://www.algense.com/toc", '_blank');
                                            this.toggleLogout()
                                        }}
                                        ref={this.OverlayRef6}
                                        style={{ position: 'relative' }}
                                        onMouseEnter={(e) =>
                                            this.props.toggleOverlay(true, this.OverlayRef6,
                                                this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['Check our Terms and Conditions']
                                                    : this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['Check our Terms and Conditions']
                                                        : this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['Check our Terms and Conditions']
                                                            : this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['Check our Terms and Conditions']
                                                                : 'Check our Terms and Conditions',
                                                ToolTipStyleData)
                                        }
                                        onMouseLeave={(e) => this.props.toggleOverlay(false)}
                                    >
                                        <i className="fa fa-trello log-menu-icon"></i>
                                        <span className="log-menu-title">
                                            {
                                                this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['Terms & Conditions']
                                                    : this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['Terms & Conditions']
                                                        : this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['Terms & Conditions']
                                                            : this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['Terms & Conditions']
                                                                : 'Terms & Conditions'
                                            }
                                        </span>
                                    </a>
                                </li>
                                <li className="">
                                    <a
                                        rel="noopener noreferrer"
                                        href={this.props.userData.usertype === 'None' ? "https://www.algense.com/privacy" : (this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin') ? "https://www.algense.com/privacy" : "https://www.algense.com/privacy"}
                                        data-to="PrivacyPolicy"
                                        target="_blank"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            // window.open(this.props.userData.usertype === 'None' ? "https://www.crowdsense.ai/privacy" : (this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin') ? "https://www.algense.com/privacy" : "https://www.algense.com/privacy", '_blank');
                                            this.toggleLogout()
                                        }}
                                        ref={this.OverlayRef7}
                                        style={{ position: 'relative' }}
                                        onMouseEnter={(e) =>
                                            this.props.toggleOverlay(true, this.OverlayRef7,
                                                this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['Check our Privacy Policy']
                                                    : this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['Check our Privacy Policy']
                                                        : this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['Check our Privacy Policy']
                                                            : this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['Check our Privacy Policy']
                                                                : 'Check our Privacy Policy',
                                                ToolTipStyleData)
                                        }
                                        onMouseLeave={(e) => this.props.toggleOverlay(false)}
                                    >
                                        <i className="fa fa-rub log-menu-icon"></i>
                                        <span className="log-menu-title">
                                            {
                                                this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['Privacy Policy']
                                                    : this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['Privacy Policy']
                                                        : this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['Privacy Policy']
                                                            : this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['Privacy Policy']
                                                                : 'Privacy Policy'
                                            }
                                        </span>
                                    </a>
                                </li>
                                <li className=" menu-divider" ></li>
                                {
                                    this.props.userData.loginlessuser === false && (
                                        <li>
                                            <div
                                                ref={this.OverlayRef8}
                                                style={{ position: 'relative' }}
                                                onMouseEnter={(e) =>
                                                    this.props.toggleOverlay(true, this.OverlayRef8,
                                                        this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['Change your Password']
                                                            : this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['Change your Password']
                                                                : this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['Change your Password']
                                                                    : this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['Change your Password']
                                                                        : 'Change your Password',
                                                        ToolTipStyleData)
                                                }
                                                onMouseLeave={(e) => this.props.toggleOverlay(false)}
                                                data-to="Change-Password"
                                                onClick={(e) => { e.stopPropagation(); this.redirector(e); }}
                                            >
                                                <i className="fa fa-unlock-alt log-menu-icon "></i>
                                                <span className="log-menu-title">

                                                    {
                                                        this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['Change Password']
                                                            : this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['Change Password']
                                                                : this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['Change Password']
                                                                    : this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['Change Password']
                                                                        : 'Change Password'
                                                    }
                                                </span>
                                            </div>
                                        </li>
                                    )
                                }

                                <li>
                                    <div
                                        ref={this.OverlayRef4}
                                        style={{ position: 'relative' }}
                                        onMouseEnter={(e) =>
                                            this.props.toggleOverlay(
                                                true,
                                                this.OverlayRef4,
                                                this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['Please share your feedback or idea with us']
                                                    : this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['Please share your feedback or idea with us']
                                                        : this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['Please share your feedback or idea with us']
                                                            : this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['Please share your feedback or idea with us']
                                                                : 'Please share your feedback or idea with us',
                                                ToolTipStyleData
                                            )
                                        }
                                        onMouseLeave={(e) => this.props.toggleOverlay(false)}
                                        data-to="Feedbacks"
                                        onClick={(e) => { e.stopPropagation(); this.redirector(e); }}
                                    >
                                        <i className="fa fa-wpforms log-menu-icon"></i>
                                        <span className="log-menu-title">
                                            {
                                                this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['Send Feedback']
                                                    : this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['Send Feedback']
                                                        : this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['Send Feedback']
                                                            : this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['Send Feedback']
                                                                : 'Send Feedback'
                                            }
                                        </span>
                                    </div>
                                </li>
                                {
                                    this.props.userData.loginlessuser === false && (
                                        <li>
                                            <div
                                                ref={this.OverlayRef9}
                                                style={{ position: 'relative' }}
                                                onMouseEnter={(e) => this.props.toggleOverlay(true, this.OverlayRef9,
                                                    this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['Sign Out']
                                                        : this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['Sign Out']
                                                            : this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['Sign Out']
                                                                : this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['Sign Out']
                                                                    : 'Sign Out',
                                                    ToolTipStyleData)}
                                                onMouseLeave={(e) => this.props.toggleOverlay(false)}
                                                data-to="Change-Password"
                                                // onClick={this.redirect}
                                                onClick={(e) => { e.stopPropagation(); this.props.logout(); }}
                                            >
                                                <i className="fa fa-sign-out log-menu-icon"></i>
                                                <span className="log-menu-title">

                                                    {
                                                        this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['Sign Out']
                                                            : this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['Sign Out']
                                                                : this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['Sign Out']
                                                                    : this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['Sign Out']
                                                                        : 'Sign Out'
                                                    }
                                                </span>
                                            </div>
                                        </li>
                                    )
                                }

                            </ul>
                        </Col>
                    </Row>
                </div>
            </Col>
        )
    }
}

const mapDispatchToProps = {
    ...preferencesActionCreator,
    ...settingsActionCreators,
    ...tmActionCreators
}
const mapStateToApp = (state) => {
    return {
        userData: state.settings.userData,
        columnorderlist: state.settings.columnorderlist,
        columnlist: state.settings.columnlist,
        preferences: state.app_preferences.preferences,
        Theme: state.app_preferences.preferences.user_interface.Theme,
        iframenav: state.settings.iframenav,
        iframefilters: state.settings.iframefilters,
        lang: state.settings.lang,
        coin_column_preferences: state.trendingMentions.coin_column_preferences,
        tmDetailPreferences: state.trendingMentions.tmDetailPreferences,
        closemainmenuiframe: state.settings.closemainmenuiframe
        // RecentUpdateView: state.app_preferences.preferences.user_interface.RecentUpdateView,
        // HasColumnOrderSettings: state.app_preferences.preferences.user_interface.HasColumnOrderSettings,
        // mainfeedcolumn: state.settings.columnorderlist.filter(itm => itm.columntype === 'Main Feeds').filter(itm => (itm)),
        // showtoptrending: state.settings.showtoptrending
    };
};

export default connect(mapStateToApp, mapDispatchToProps)(LogmenuIframe)