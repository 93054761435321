import React, { Component } from 'react'; 

class SecurityListItem extends Component {

  constructor() {
    super()
    this.state = {
      active: false,
      selected: false,
      security: {}
    }
  }
  componentWillMount() {
    
    if(this.props.security) {
      this.setState({
        ...this.state,
        selected: this.props.selected,
        security: this.props.security

      })
    }
  }
  componentWillReceiveProps(nextProps) {
    let should = this.props.selected !== nextProps.selected
    if(should) {
      this.setState({
        ...this.state,
        selected: nextProps.selected,
        security: nextProps.security
      })
    }
  }
  
  handleClick = (e) => {
    this.setState({
      ...this.state,
      active: !this.state.active
    }, () => {
      // console.log(this.props.security)
      window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
      window.Mixpanel.MixpanelProps.Setting = 'Assets';
      window.Mixpanel.MixpanelProps.SecurityTypeID = this.props.security.SecurityTypeID;
      window.Mixpanel.MixpanelProps.AssetsName = this.props.security.Name;
      window.Mixpanel.actions.identify(this.props.userData.username);
      let actionTrackerText = this.state.active ? `Expanded ${this.props.security.Name}` : `Minimised ${this.props.security.Name}`
      window.Mixpanel.actions.track(actionTrackerText, window.Mixpanel.MixpanelProps).then(data => {
        window.Mixpanel.actions.people.set({
          UserName: this.props.userData.username
        });
      })
    })
  }
  handleFilterChange = (e) => {
   
    // return;
    this.setState({
      ...this.state,
      selected: !this.state.selected
    },() => {
      // console.log(this.state.security)
      window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
      window.Mixpanel.MixpanelProps.Setting = 'Assets';
      if(typeof this.state.security.SecurityID === 'undefined') {
        
        window.Mixpanel.MixpanelProps.SecurityTypeID = this.state.security.SecurityTypeID;
        delete window.Mixpanel.MixpanelProps.SecurityID
      } else {
        window.Mixpanel.MixpanelProps.SecurityID = this.state.security.SecurityID;
        delete window.Mixpanel.MixpanelProps.SecurityTypeID
      }
      
      window.Mixpanel.MixpanelProps.AssetsName = this.state.security.Name;
      window.Mixpanel.actions.identify(this.props.userData.username);
      let actionTrackerText = this.state.selected ? `Selected ${this.state.security.Name}` : `Unselected ${this.state.security.Name}`
      window.Mixpanel.actions.track(actionTrackerText, window.Mixpanel.MixpanelProps).then(data => {
        window.Mixpanel.actions.people.set({
          UserName: this.props.userData.username
        });
        delete window.Mixpanel.MixpanelProps.SecurityTypeID
        delete window.Mixpanel.MixpanelProps.SecurityID
        delete window.Mixpanel.MixpanelProps.AssetsName
      })
      this.props.managefilter(this.state.selected, this.state.security)
    })
    
  }

  recursiveSecurityChildren = (security, securities, response) => {
    
    let SecuritiesChildren = security.Securities || [];
    let secviews = []; let secviewsArr = [];
    let childStatusArr =  []; let recursiveStatusCount = {}; let childStatus = '';
    if(SecuritiesChildren.length) {
      childStatusArr = this.directChildStatus(SecuritiesChildren, securities)
      
      if(childStatusArr.indexOf(true) === -1) {
        childStatus = 'ALLFALSE';
      } else if(childStatusArr.indexOf(false) === -1) {
        childStatus = 'ALLTRUE';
      } else {
        childStatus = 'ALLMIXED';
      }
      recursiveStatusCount = this.recursiveChildStatusCount(security, securities, true);
      // console.log(security.Name, recursiveStatusCount)
      if(this.state.active) {
        for(let securitee of securities) {
          if(security.Securities.indexOf(securitee.SecurityID) > -1) {
            secviewsArr.push(securitee)
            secviews.push(<SecurityListItem security={securitee} groupindex={this.props.groupIndex} akey={`sec${securitee.SecurityID}`} itemmanage={this.props.itemmanage} indexChange={this.props.indexchange} key={`sec${securitee.SecurityID}`} managefilter={this.props.managefilter} selected={securitee.selected} rawCollection={this.props.rawCollection} secResults={this.props.secResults} secs={this.props.secs} secFilter={this.props.secFilter} userData={this.props.userData} />)
          }
        }
      }
    }
    response = { 'secviews' : secviews, 'childStatus': childStatus, 'statusCount' : recursiveStatusCount }
    // response = secviews
    return response
  }
  directChildStatus = (childArr, types, childStatusArr = []) => {
    
    // let childStatusArr = [];
    if(types.length) {
      types.forEach((sectp) => {
        if(sectp.SecurityID) {
          if(childArr.indexOf(sectp.SecurityID) > -1) {
            childStatusArr.push(sectp.selected)
          }
        } else {
          if(childArr.indexOf(sectp.SecurityTypeID) > -1) {
            childStatusArr.push(sectp.selected)
            if(sectp.children.length) {
              this.directChildStatus(sectp.children, types, childStatusArr);
            }
            if(sectp.Securities && sectp.Securities.length) {
              this.directChildStatus(sectp.Securities, this.props.secs, childStatusArr);
            }
          }
        }
        
      })
      
    }
    return childStatusArr;
  }
  recursiveChildStatusCount = (security, types, securities = false, response = {childCount:0,selectedCount:0}) => {
    if(security.SecurityID) {
      types.forEach((sectp) => {
        if(security.Securities.indexOf(sectp.SecurityID) > -1) {
          response.childCount++;
          if(sectp.selected) { 
            response.selectedCount++;
          }
        }
      })
    } else {
      // if(security.children.length) {
        types.forEach((sectp) => {
          if(securities) {
            if(security.Securities.indexOf(sectp.SecurityID) > -1) {
              // if(!sectp.children.length) {
                response.childCount++;
              
                if(sectp.selected) {
                  
                    response.selectedCount++;
                  
                  
                }
              // }
            }
          } else {
            if(security.children.indexOf(sectp.SecurityTypeID) > -1) {
              if(!sectp.children.length && sectp.Securities && !sectp.Securities.length) {
                response.childCount++;
              
                if(sectp.selected) {
                  
                    response.selectedCount++;
                  
                  
                }
              }
              if(sectp.children.length) {
                response = this.recursiveChildStatusCount(sectp, types, false, response)
              } else if(sectp.Securities.length) { 
                response = this.recursiveChildStatusCount(sectp, this.props.secs, true, response)
              }
            }
          }
          
          
          
        })
      // }
    }
    
    return response
  }
  recursiveChildren = (security, types, response) => {
    
   
    let SecurityTypesChildren = security.children || [];
    let sectypeviews = []; let sectypeviewsArr = [];
    let childStatusArr =  []; let recursiveStatusCount = {}; let childStatus = '';
    if(SecurityTypesChildren.length) {
      childStatusArr = this.directChildStatus(SecurityTypesChildren, types)
      
      if(childStatusArr.indexOf(true) === -1) {
        childStatus = 'ALLFALSE';
      } else if(childStatusArr.indexOf(false) === -1) {
        childStatus = 'ALLTRUE';
      } else {
        childStatus = 'ALLMIXED';
      }
      // console.log(security.Name, childStatusArr, childStatus)
      recursiveStatusCount = this.recursiveChildStatusCount(security, types)
      if(this.state.active) {
        for(let Type of types) {
          if(security.children.indexOf(Type.SecurityTypeID) > -1) {
            sectypeviewsArr.push(Type)
            sectypeviews.push(<SecurityListItem security={Type} groupindex={this.props.groupIndex} akey={`secTyp${Type.SecurityTypeID}`} itemmanage={this.props.itemmanage} indexChange={this.props.indexchange} key={`secTyp${Type.SecurityTypeID}`} managefilter={this.props.managefilter} selected={Type.selected} rawCollection={this.props.rawCollection} secResults={this.props.secResults} secs={this.props.secs} secFilter={this.props.secFilter} userData={this.props.userData} />)
          }
        }
      }
      
    }
    
    response = { 'sectypeviews' : sectypeviews, 'childStatus': childStatus, 'statusCount' : recursiveStatusCount }
    return response
  }

  render() {
    const { security, rawCollection, secs } = this.props
    const { active, selected } = this.state
    
    // console.log('here in list item in security settings')
    // console.log(security)
    
    let childStatus = '';let statusCount = ''
    let detailTree = []; let detailTreeArr = {'sectypeviews' : [], 'childStatus': '', 'statusCount' : ''};
    let SecTree = []; let SecTreeArr = {'secviews' : [], 'childStatus': '', 'statusCount' : ''};
    if(security.children && security.children.length) {
      
      detailTreeArr = this.recursiveChildren(security, rawCollection, detailTree);
      
      detailTree = detailTreeArr.sectypeviews;
      
      
    } 
    if(security.Securities && security.Securities.length) {
      SecTreeArr = this.recursiveSecurityChildren(security, secs, SecTree);

      SecTree = SecTreeArr.secviews;
      
    }
    
    if(detailTreeArr.childStatus && SecTreeArr.childStatus) {
      if(detailTreeArr.childStatus === 'ALLTRUE' && SecTreeArr.childStatus === 'ALLTRUE') {
        childStatus = 'ALLTRUE';
      } else if(detailTreeArr.childStatus === 'ALLFALSE' && SecTreeArr.childStatus === 'ALLFALSE') {
        childStatus = 'ALLFALSE';
      } else {
        childStatus = 'ALLMIXED';
      }
    } else if(detailTreeArr.childStatus) {
      childStatus = detailTreeArr.childStatus;
    } else if(SecTreeArr.childStatus) {
      childStatus = SecTreeArr.childStatus;
    }
    
    
    if(detailTreeArr.statusCount && SecTreeArr.statusCount) {
      statusCount = `${(detailTreeArr.statusCount.selectedCount+ SecTreeArr.statusCount.selectedCount)} / ${(detailTreeArr.statusCount.childCount+SecTreeArr.statusCount.childCount)}`;
    } else if(detailTreeArr.statusCount) {
      statusCount = `${detailTreeArr.statusCount.selectedCount} / ${detailTreeArr.statusCount.childCount}`;
    } else if(SecTreeArr.statusCount) {
      statusCount = `${SecTreeArr.statusCount.selectedCount} / ${SecTreeArr.statusCount.childCount}`;
    }
    
    
    return(
      <div className={` settings-list-item ${security.Name === 'None' ? 'nodisp' : ''}`}>
        <div className={`settings-item-title marg-0  ${active ? 'selected' : ''}`} >
          
          
          {
            detailTreeArr.statusCount.childCount || SecTreeArr.statusCount.childCount ? (
              <i className={`fa ${!active ? 'fa-caret-down' : 'fa-caret-up selected'} float-left open-but `} onClick={this.handleClick}></i>
            ) : (
              <i className={`fa ${!active ? 'fa-caret-down' : 'fa-caret-up selected'} float-left open-but vishid `} ></i>
            )
          }
          {
            detailTreeArr.statusCount.childCount || SecTreeArr.statusCount.childCount ? (
              <span className={` sm-fs12  `} onClick={this.handleClick} >{ security.Name !== 'None' ? security.Name.trim() : 'See Events With No Assets' }</span>
            ) : (
              <span className={` sm-fs12  `} >{ security.Name !== 'None' ? security.Name.trim() : 'See Events With No Assets' }</span>
            ) 
          }
          {
            detailTreeArr.statusCount.childCount || SecTreeArr.statusCount.childCount ? (
              <span className='selectedFilterStatus' onClick={this.handleClick}>{detailTreeArr.statusCount.childCount || SecTreeArr.statusCount.childCount ? ` (${statusCount} selected)` : ''}</span>
            ) : (
              <span className='selectedFilterStatus' >{detailTreeArr.statusCount.childCount || SecTreeArr.statusCount.childCount ? ` (${statusCount} selected)` : ''}</span>
            )
          }
          
          <span className="float-right">
            <label className="sec-switch float-right">
              <input type="checkbox" checked={`${selected || childStatus === 'ALLMIXED'? 'checked': ''}`} onChange={this.handleFilterChange} value={security.SecurityID ? security.SecurityID : security.SecurityTypeID} />
              <span className={`sec-slider ${childStatus === 'ALLMIXED'? 'mixedSelect' : ''}  round`}></span>
            </label>
          </span>
          
        </div>
        {
          active && (
            detailTree.length || SecTree.length ? (
              <div className={`settings-item-content `}>
                <div className="securityItem" >
                  { detailTree }
                  { SecTree }
                </div>
                
              </div>
            ) : ('')
          )
          
        }
        
      </div>
    )
  }
}

export default SecurityListItem