import React, { Component } from 'react'; 

class EventListItem extends Component {
  constructor() {
    super();
    this.state = {
      active: false,
      selected: false,
      event: {}
    }
  }

  componentWillMount() {
    this.setState({
      ...this.state,
      event: this.props.event,
      selected: this.props.selected
    })
  }

  componentWillReceiveProps(nextProps) {
    let should = this.props.selected !== nextProps.selected
    if(should) {
      this.setState({
        ...this.state,
        selected: nextProps.selected,
        event: nextProps.event
      })
    }
  }

  handleClick = (e) => {
    this.setState({
      ...this.state,
      active: !this.state.active
    },() => {
      // console.log(this.state.event)
      window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
      window.Mixpanel.MixpanelProps.Setting = 'Events';
      window.Mixpanel.MixpanelProps.EventTypeID = this.state.event.EventTypeID;
      window.Mixpanel.MixpanelProps.EventName = this.state.event.Name;
      window.Mixpanel.actions.identify(this.props.userData.username);
      let actionTrackerText = this.state.active ? `Expanded ${this.state.event.Name}` : `Minimised ${this.state.event.Name}`
      window.Mixpanel.actions.track(actionTrackerText, window.Mixpanel.MixpanelProps).then(data => {
        window.Mixpanel.actions.people.set({
          UserName: this.props.userData.username
        });
      })
    })
  }

  

  handleChange = () => {
    this.setState({
      ...this.state,
      selected: !this.state.selected
      
    },() => {
      // console.log(this.state.event)
      window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
      window.Mixpanel.MixpanelProps.Setting = 'Events';
      
      window.Mixpanel.MixpanelProps.EventTypeID = this.state.event.EventTypeID;
      window.Mixpanel.MixpanelProps.EventName = this.state.event.Name;
      window.Mixpanel.actions.identify(this.props.userData.username);
      let actionTrackerText = this.state.selected ? `Selected ${this.state.event.Name}` : `Unselected ${this.state.event.Name}`
      window.Mixpanel.actions.track(actionTrackerText, window.Mixpanel.MixpanelProps).then(data => {
        window.Mixpanel.actions.people.set({
          UserName: this.props.userData.username
        });
        delete window.Mixpanel.MixpanelProps.EventTypeID
        delete window.Mixpanel.MixpanelProps.EventName
      })
      this.props.managefilter(this.state.selected, this.state.event)
    })
  }
  directChildStatus = (childArr, types, childStatusArr = []) => {
    
    // let childStatusArr = [];
    if(types.length) {
      types.forEach((evnt) => {
        if(childArr.indexOf(evnt.EventTypeID) > -1) {
          childStatusArr.push(evnt.selected)
          if(evnt.children.length) {
            this.directChildStatus(evnt.children, types, childStatusArr);
          }
        }
      })
      
    }
    return childStatusArr;
  }
  recursiveChildStatusCount = (event, types, response = {childCount:0,selectedCount:0}) => {
    
    if(event.children.length) {
      types.forEach((typ) => {
        if(event.children.indexOf(typ.EventTypeID) > -1) {
          if(!typ.children.length) {
            response.childCount++;
          
            if(typ.selected) {
              
                response.selectedCount++;
              
              
            }
          }
          if(typ.children.length) {
            response = this.recursiveChildStatusCount(typ, types, response)
          }
        }
        
        
      })
    }
    return response
  }
  recursiveChildren = (event, types, response) => {
    
   
    let EventTypesChildren = event.children || [];
    let evttypeviews = []; let evttypeviewsArr = [];
    let childStatusArr =  []; let recursiveStatusCount = {}; let childStatus = '';
    if(EventTypesChildren.length) {
      childStatusArr = this.directChildStatus(EventTypesChildren, types)
      
      if(childStatusArr.indexOf(true) === -1) {
        childStatus = 'ALLFALSE';
      } else if(childStatusArr.indexOf(false) === -1) {
        childStatus = 'ALLTRUE';
      } else {
        childStatus = 'ALLMIXED';
      }

      recursiveStatusCount = this.recursiveChildStatusCount(event, types)
      if(this.state.active) {
        for(let Type of types) {
          if(event.children.indexOf(Type.EventTypeID) > -1) {
            evttypeviewsArr.push(Type)
            evttypeviews.push(<EventListItem event={Type} groupindex={this.props.groupIndex} akey={`evntTyp${Type.EventTypeID}`}  indexChange={this.props.indexchange} key={`secTyp${Type.EventTypeID}`} managefilter={this.props.managefilter} selected={Type.selected} eventTypes={this.props.eventTypes} userData={this.props.userData}  />
            )
          }
        }
      }
      
    }
    
    response = { 'evttypeviews' : evttypeviews, 'childStatus': childStatus, 'statusCount' : recursiveStatusCount }
    return response
  }
  render() {
    const { event, eventTypes } = this.props
    const { active, selected } = this.state
    let detailTree = []; let detailTreeArr = {'evttypeviews' : [], 'childStatus': '', 'statusCount' : ''}; 
    let childStatus = ''; let statusCount = ''
    
      // detailTree = this.recursiveChildren(event, eventTypes, detailTree);
      if(event.children && event.children.length) {
        detailTreeArr = this.recursiveChildren(event, eventTypes, detailTreeArr);
        detailTree = detailTreeArr.evttypeviews;
        childStatus = detailTreeArr.childStatus;
        statusCount = `${detailTreeArr.statusCount.selectedCount} / ${detailTreeArr.statusCount.childCount}`;
  
      }
    
    return (
      <div className={`settings-list-item ${!event.children.length && event.Name === 'None' ? 'nodisp':'' } `}>
        <div className={`settings-item-title marg-0  ${active ? 'selected' : ''}`}>
          
          {
            detailTreeArr.statusCount.childCount ? (
              <i className={`fa ${!active ? 'fa-caret-down' : 'fa-caret-up selected'} float-left open-but`} onClick={this.handleClick}></i>
            ) : (
              <i className={`fa ${!active ? 'fa-caret-down' : 'fa-caret-up selected'} float-left open-but vishid`}></i>
            )
          }
          {
            detailTreeArr.statusCount.childCount ? (
              <span className={` sm-fs12  `} onClick={this.handleClick}>{ event.Name.trim() }</span>
            ) : (
              <span className={` sm-fs12  `} >{ event.Name.trim() }</span>
            )
          }
          {
            detailTreeArr.statusCount.childCount ? (
              <span className='selectedFilterStatus' onClick={this.handleClick} >{detailTreeArr.statusCount.childCount ? ` (${statusCount} selected)` : ''}</span>
            ) : (
              <span className='selectedFilterStatus' >{detailTreeArr.statusCount.childCount ? ` (${statusCount} selected)` : ''}</span>
            )
          }
          
          <span className="float-right">
            <label className="sec-switch float-right">
              <input type="checkbox" checked={`${selected || childStatus === 'ALLMIXED'? 'checked': ''}`} onChange={this.handleChange} value={event.EventTypeID} />
              <span className={`sec-slider round ${childStatus === 'ALLMIXED'? 'mixedSelect' : ''}`}></span>
            </label>
          </span>
           
        </div>
        {
          detailTree.length? (
            <div className={`settings-item-content ${!active ? 'nodisp' : '' }`}>
              <div className="securityItem" >
                { detailTree }
              </div>
              
            </div>
          ) : ('')
        }
      </div>
    )
  }
}

export default EventListItem