import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { APPPATH } from '../../../../modules/helper.module';
import * as preferencesActionCreators from '../../../../actions/apppreferences.action';
import PreferenceCategory from '../../../preferences/preferencecategory';
import { PreferenceHandler } from '../../../../modules/helpers/preference_helper';
import { TrendingMentionsHelper } from '../../../../modules/helper.module';
import * as moment from 'moment';
import { welcomemodeon } from '../../../../actions/settings.action';

class UserPreferenceIndex extends Component {
  constructor(props) {
    super();
    this.state = {
      _this: this,
      preferences: props.preferences,
      config: {
        userDetails: {
          Email: props.preferences.localisation_preferences.Email || props.userdata.username,
          Timezone: props.preferences.localisation_preferences.Timezone,
          Phone: props.preferences.localisation_preferences.Phone,
        },
        
        emailUpdates: this.makeEmailPreferences(props.preferences.email_preferences),
        
      },
    };
  }

  makeEmailPreferences = (emailPrefs) => {
    return {
      RealTimeEmails: {
        Professional: emailPrefs.Professional,
        Standard: emailPrefs.Standard,
        Lite: emailPrefs.Lite,
        None: emailPrefs.None ? true : false,
        // None:  !emailPrefs.Daily_Notifications && !emailPrefs.Weekly_Notifications && emailPrefs.None ? true : false,
      },
      SummaryEmails: {
        // Daily_Weekly_Notifications: emailPrefs.Daily_Notifications && emailPrefs.Weekly_Notifications ? true : false,
        Daily_notifications: emailPrefs.Daily_Notifications ? true : false,
        Weekly_notifications: emailPrefs.Weekly_Notifications ? true : false,
        No_notifications: !emailPrefs.Daily_Notifications && !emailPrefs.Weekly_Notifications? true : false
      },
    };
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      config: {
        userDetails: {
          Email:
            nextProps.preferences.localisation_preferences.Email !== ''
              ? nextProps.preferences.localisation_preferences.Email
              : prevState.config.userDetails.Email,
          Timezone: nextProps.preferences.localisation_preferences.Timezone,
          Phone: nextProps.preferences.localisation_preferences.Phone,
        },
        // cryptoEvents : {
        //     Show_Red: nextProps.preferences.event_importance_filters.Red_Colored_Events.Show_Red,
        //     Show_Yellow: nextProps.preferences.event_importance_filters.Yellow_Colored_Events.Show_Yellow,
        //     Show_Gray: nextProps.preferences.event_importance_filters.Gray_Colored_Events.Show_Gray,
        //     Show_NACE: nextProps.preferences.event_importance_filters.Non_Active_Curated_Events.Show_NACE,
        // },
        emailUpdates: prevState._this.makeEmailPreferences(nextProps.preferences.email_preferences),
        // emailUpdates: {
        //     Email_Notifications_Red: nextProps.preferences.event_importance_filters.Red_Colored_Events.Email_Notifications_Red,
        //     Email_Notifications_Yellow: nextProps.preferences.event_importance_filters.Yellow_Colored_Events.Email_Notifications_Yellow,
        //     Daily_Notifications: nextProps.preferences.email_preferences.Daily_Notifications,
        //     // Weekly_Notifications: true
        // },
        // telegramUpdates: {
        //     Telegram_Messages_Red: true,
        //     Telegram_Messages_Yellow: true,
        // }
      },
    };
  }
  getActualCategories = (title) => {
    let ActualCategory;
    switch (title) {
      case 'Email':
        ActualCategory = 'localisation_preferences';
        break;
      case 'Timezone':
        ActualCategory = 'localisation_preferences';
        break;
      case 'Phone':
        ActualCategory = 'localisation_preferences';
        break;
      case 'Show_Red':
        ActualCategory = 'Red_Colored_Events';
        break;
      case 'Show_Yellow':
        ActualCategory = 'Yellow_Colored_Events';
        break;
      case 'Show_Gray':
        ActualCategory = 'Gray_Colored_Events';
        break;
      case 'Show_NACE':
        ActualCategory = 'Non_Active_Curated_Events';
        break;
      case 'Email_Notifications_Red':
        ActualCategory = 'Red_Colored_Events';
        break;
      case 'Email_Notifications_Yellow':
        ActualCategory = 'Yellow_Colored_Events';
        break;
      case 'None':
        ActualCategory = 'email_preferences';
        break;
      case 'Lite':
        ActualCategory = 'email_preferences';
        break;
      case 'Standard':
        ActualCategory = 'email_preferences';
        break;
      case 'Professional':
        ActualCategory = 'email_preferences';
        break;
      case 'Daily_Weekly_Notifications':
        ActualCategory = 'email_preferences';
        break;
      case 'Daily_notifications':
        ActualCategory = 'email_preferences';
        break;
      case 'Weekly_notifications':
        ActualCategory = 'email_preferences';
        break;
      case 'No_notifications':
        ActualCategory = 'email_preferences';
        break;
      default:
        break;
    }
    return ActualCategory;
  };
  handleUserPreferences = (type, title, value, category) => {
    // console.log(type, title, value, category)
    
    let ActualCategory = this.getActualCategories(title);
    // console.log(ActualCategory)

    let preferences = this.props.preferences;
    // console.log(preferences)  
    let { coin_column_preferences, tmDetailPreferences, columnorderlist } = this.props;
    let Preferences = PreferenceHandler.UpdateUserPreferences(preferences, title, value, ActualCategory);
    // console.log(Preferences)
    let PreferenceData = PreferenceHandler.CreatePreferencesStrings(Preferences);
    let CoinCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(coin_column_preferences);
    let CoinDetailCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(tmDetailPreferences, 'coindetails');
    let ColumnOrderStrings = PreferenceHandler.CreateColumnOrderString(columnorderlist)
    PreferenceData.userPrefData.UserPreferencesString = `${PreferenceData.userPrefData.UserPreferencesString}${CoinCoulmnStrings}${CoinDetailCoulmnStrings}${ColumnOrderStrings}`
    // console.log(PreferenceData)
    // return;
    if (category === 'userDetails') {
      switch (title) {
        case 'Email':
          PreferenceData.userPrefData.UserName = this.props.userdata.username;
          this.props.setUserPreferences(PreferenceData.userPrefData);
          break;
        case 'Timezone':
          PreferenceData.localisationData.UserName = this.props.userdata.username;
          // PreferenceData.localisationData.reducerData = value;
          this.props.setLocalisationPreferences(PreferenceData.localisationData);
          break;
        case 'Phone':
          PreferenceData.phoneData.UserName = this.props.userdata.username;
          this.props.setPhonePreferences(PreferenceData.phoneData);
          break;
        default:
          break;
      }
    } else if (category === 'emailUpdates') {
      PreferenceData.notificationData.UserName = this.props.userdata.username;
      this.props.setNotificationPreferences(PreferenceData.notificationData);
    } else {
      PreferenceData.userPrefData.UserName = this.props.userdata.username;
      PreferenceData.notificationData.UserName = this.props.userdata.username;
      this.props.setUserPreferences(PreferenceData.userPrefData);
      this.props.setNotificationPreferences(PreferenceData.notificationData);
    }
  };
  
  render() {
    const { SecuritiesChecklist, EventTypesCheckList, welcomemode } = this.props;
    const { config } = this.state;
    const conditionCheck = !(SecuritiesChecklist && EventTypesCheckList);
    // console.log(Object.keys(config))
    let prefCategories = Object.keys(config).map((key) => {
      // console.log(key)
      return (
        <PreferenceCategory
          {...this.props}
          categoryData={config[key]}
          category={key}
          key={key}
          handleUserPreferences={this.handleUserPreferences}
          TimeZoneList={this.props.TimeZoneList}
        />
      );
    });

    return (
      <div className={`watchlist-content justify-content-center text-center ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>
        <div
          className="btn tradeWindowClose text-white nopad marg-0"
          onClick={() => {
            this.props.welcomemodeon(false);
            this.props.modalClose(conditionCheck, 5)
          }}
        >
          <span className="pad10">
            <img className="tradeWindowClose-closeicon" src={`${APPPATH.ImageUrl}/button-close.svg`} alt="" />
          </span>
        </div>

        <Row className="marg-0 storymenuoptions-tabheader">
          <Col className="nopad">
            <div className={`nav nav-tabs watchlist nav-fill  ${this.props.Theme === 'light' ? 'lightmode' : ''} `}>
              <div className={`nav-item fs16 active`}>
                <span>User Preferences</span>
              </div>
            </div>
          </Col>
        </Row>

        <Row
          className="cScroll scroll-notrack marg-0 justify-content-center  pad5 text-center"
          style={{ maxHeight: 300 }}
        >
          <Col lg={11} sm={11} xs={11} md={11} xl={11} className={`userprefbody justify-content-left text-left ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>
            <div className="userpref-list">{prefCategories}</div>
          </Col>
        </Row>
        <Row className={` marg-0 justify-content-center  pad5 text-center `}>
          <Col lg={3} sm={3} xs={3} md={3} xl={3} className={`text-left watchlistNavigatorButtons ${this.props.Theme === 'light' ? 'lightmode' : ''} `}>
            <span
              className={`${(!SecuritiesChecklist && !EventTypesCheckList) || welcomemode ? '' : 'nodisp'} pointer`}
              onClick={() => {
                window.Mixpanel.MixpanelProps.UserName = this.props.userdata.username;
                window.Mixpanel.actions.identify(this.props.userdata.username);
                window.Mixpanel.actions
                  .track('Clicking back to Event list modal', window.Mixpanel.MixpanelProps)
                  .then((data) => {
                    window.Mixpanel.actions.people.set({
                      UserName: this.props.userdata.username,
                    });
                  });
                this.props.changeView('EventTypeList');
              }}
            >
              Back
            </span>
          </Col>
          <Col lg={6} sm={6} xs={6} md={6} xl={6} className="text-center pad5 ">
            <div className={`${(!SecuritiesChecklist && !EventTypesCheckList) || welcomemode ? '' : 'nodisp'}`}>
              <span className={`wizard-step-dot ${this.props.Theme === 'light' ? 'lightmode' : ''}`}></span>
              <span className={`wizard-step-dot ${this.props.Theme === 'light' ? 'lightmode' : ''}`}></span>
              <span className={`wizard-step-dot ${this.props.Theme === 'light' ? 'lightmode' : ''}`}></span>
              <span className={`wizard-step-dot active ${this.props.Theme === 'light' ? 'lightmode' : ''}`}></span>
              {
                (this.props.userdata.usertype === 'None' || this.props.userdata.usertype === 'WUser' || this.props.userdata.usertype === 'WAdmin') ? (
                  <span className={`wizard-step-dot ${this.props.Theme === 'light' ? 'lightmode' : ''}`}></span>
                ) : ('')
              }
            </div>
          </Col>
          <Col lg={3} sm={3} xs={3} md={3} xl={3} className={`text-right watchlistNavigatorButtons ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>
            <span
              className="pointer"
              onClick={() => {
                if ((!SecuritiesChecklist && !EventTypesCheckList) || welcomemode) {
                  
                  
                  if(this.props.userdata.usertype === 'None' || this.props.userdata.usertype === 'WUser' || this.props.userdata.usertype === 'WAdmin') {
                    window.Mixpanel.MixpanelProps.UserName = this.props.userdata.username;
                    window.Mixpanel.actions.identify(this.props.userdata.username);
                    window.Mixpanel.actions
                      .track('Welcome process Preference list continued', window.Mixpanel.MixpanelProps)
                      .then((data) => {
                        window.Mixpanel.actions.people.set({
                          UserName: this.props.userdata.username,
                        });
                      });
                    this.props.changeView('IntroVideo');
                  } else {
                    window.Mixpanel.MixpanelProps.UserName = this.props.userdata.username;
                    window.Mixpanel.actions.identify(this.props.userdata.username);
                    window.Mixpanel.actions
                      .track('Companies Welcome process Completed', window.Mixpanel.MixpanelProps)
                      .then((data) => {
                        window.Mixpanel.actions.people.set({
                          UserName: this.props.userdata.username,
                        });
                      });
                    this.props.hardRefreshSystem();
                  }
                  
                } else {
                  this.props.hardRefreshSystem();
                }
                
              }}
            >
              {(!SecuritiesChecklist && !EventTypesCheckList) || welcomemode ? (this.props.userdata.usertype === 'None' || this.props.userdata.usertype === 'WUser' || this.props.userdata.usertype === 'WAdmin') ? 'Next' : 'Confirm' : 'Confirm'}
            </span>
          </Col>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    preferences: state.app_preferences.preferences,
    TimeZoneList: state.settings.TimeZoneList,
    welcomemode: state.settings.welcomemode,
    SecuritiesChecklist: state.settings.checkListStatus.SecuritiesCheckList,
    EventTypesCheckList: state.settings.checkListStatus.EventTypesCheckList,
    coin_column_preferences: state.trendingMentions.coin_column_preferences,
    tmDetailPreferences: state.trendingMentions.tmDetailPreferences,
    columnorderlist: state.settings.columnorderlist,
    columnlist: state.settings.columnlist,
    Theme: state.app_preferences.preferences.user_interface.Theme,
  };
};

const mapDispatchToProps = {
  ...preferencesActionCreators,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserPreferenceIndex);
