import React, { Component } from 'react'; 
// import { Row, Col, Modal, Button } from 'react-bootstrap';

class Popups extends Component {
  constructor(props) {
    super();
    this.state = {
      showState : props.show,
      view :props.content,
      pos: props.position,
      containerClass: props.ContainerClass
    }
  }

  correctPosition = (pos) => {
    let KeyArr = Object.keys(pos);
    if(KeyArr.indexOf('left') > -1) {
        pos.left = pos.left - 10
    }
    if(KeyArr.indexOf('right') > -1) {
        pos.right = pos.right + 10
    }
    if(KeyArr.indexOf('top') > -1) {
        pos.top = pos.top + 20
    }
    return pos;
  }
  
  componentWillReceiveProps(nextProps) {
    this.setState({
        ...this.state,
        showState : nextProps.show,
        view :nextProps.content,
        pos: nextProps.position,
        containerClass: nextProps.ContainerClass
    })
  }

  

  render() {
    const { showState, view, pos, containerClass } = this.state;
    // console.log(containerClass)
    return (
        <div className={`marg-0 ${containerClass} ${!showState ? 'nodisp' : ''}`} style={{...pos}}>
            { view }
        </div>
    )
  }
}

export default Popups