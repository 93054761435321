import React, { Component } from 'react'; 
import { isMobile } from "react-device-detect";
import { connect } from 'react-redux';

class PreferenceCheckBox extends Component {
    constructor(props) {
        super();
        this.state = {
            thisInstance: this,
            color: props.fillColor,
            preference: props.checkStatus,
            tooltipText: props.tooltipText,
            disabled: props.disabled
        }
        this.componentWrap = '';
        this.ttHandle = '';
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            color: nextProps.fillColor,
            preference: nextProps.checkStatus,
            tooltipText: nextProps.tooltipText,
            disabled: nextProps.disabled
        }
    }

    toggleToolTip = (e, showState) => {
        const { tooltipText } = this.state;
        
            let ContainerRect = this.componentWrap.getBoundingClientRect();
        
            let Pos = {
                left: ContainerRect.left - (150 * 0.85),
                top: ContainerRect.bottom + 10,
                width: 150
            }
            
            if(this.ttHandle) {
                clearTimeout(this.ttHandle)
            }
            const containerStyleClass = `infoTip normal-right ${this.props.Theme === 'light' ? 'lightmode' : ''}`;
            if(showState) {
                if(!isMobile) {
                    this.ttHandle = setTimeout(() => {
                        this.props.togglePopup(showState, tooltipText, Pos, containerStyleClass);
                        window.Mixpanel.MixpanelProps.UserName = this.props.username;
                        window.Mixpanel.MixpanelProps.ToolTipType = 'Event Importance Preference Tip';
                        window.Mixpanel.MixpanelProps.ToolTipText = tooltipText;
                        window.Mixpanel.actions.identify(this.props.username);
                        window.Mixpanel.actions.track(`Tooltip shown`, window.Mixpanel.MixpanelProps).then(data => {
                            window.Mixpanel.actions.people.set({
                                UserName: this.props.username
                            });
                            window.Mixpanel.actions.resetProp('ToolTipType');
                            window.Mixpanel.actions.resetProp('ToolTipText');
                        })
                    }, 1000)
                }
            } else {
                this.props.togglePopup(showState);
            }
        
        
    
    }

    render() {
        const { color, preference, disabled } = this.state;
        return (
            <span 
                className={`ripplecontainer bgHover `}
                onMouseEnter={(e) => this.toggleToolTip(e, true)} 
                onMouseLeave={(e) => this.toggleToolTip(e, false)}  
                onClick={() => { !disabled && this.props.updateAction(color, preference)}}
                ref={(el) => this.componentWrap = el }
            >
            
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" style={{marginTop: -3}} >
                    <g id="icon-tick" transform="translate(16066 -11481)">
                        <g id="Rectangle_408" data-name="Rectangle 408" transform="translate(-16066 11481)" fill={`${color}`} stroke="#96a2ab" strokeWidth="1.5">
                        <rect width="22" height="22" rx="11" stroke="none"/>
                        <rect x="0.75" y="0.75" width="20.5" height="20.5" rx="10.25" fill="none"/>
                        </g>
                        {
                            preference && (
                                <path id="Path_387" data-name="Path 387" d="M2153.123,1499.219l3.933,3.932,7.061-7.061" transform="translate(-18213.621 9992.379)" fill="none" stroke="#fff" strokeWidth="2"/>
                            )
                        }
                    </g>
                </svg> 
                
            </span>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
      Theme: state.app_preferences.preferences.user_interface.Theme,
    }
  }
  
export default  connect(mapStateToProps, null)(PreferenceCheckBox);