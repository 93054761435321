import * as moment from 'moment';
import { graphic } from 'echarts';
import { TrendingMentionsHelper, NotifyFeedsHandler } from '../../../../modules/helper.module';
export default function Relationline (fixed = 'CoinSaveTime', base = 'SecurityPrice', variant, ticker = {}, feeds = [], minify, usertype, Theme, TimeFrame = 14) {
   
    // let MaxMinRange = (AxisProp, type) => {
    let MaxMinRange = (AxisProp, type, lx) => {
        let min = 0; let max = 100000; let intervalScale = 10;
        let NewFeeds = JSON.parse(JSON.stringify(feeds))
        let bufferPerc = AxisProp === 'SecurityPrice' ? 0.025 : 0.15
        let NullFilteredFeeds = NewFeeds.filter(feed => (feed[`${AxisProp}`]));
        let sorted = NullFilteredFeeds.sort((a,b) => {
            return a[`${AxisProp}`] - b[`${AxisProp}`]
        });
        // console.log(lx, AxisProp, bufferPerc)
        // console.log(type)
        // console.log('max',sorted[sorted.length -1], 'min',sorted[0])
        if(sorted.length > 0) {
            // console.log(sorted[0][`${AxisProp}`], sorted[sorted.length -1][`${AxisProp}`])
            if(sorted[sorted.length -1][`${AxisProp}`] < intervalScale && sorted[sorted.length -1][`${AxisProp}`] >= (intervalScale/2)) {
                intervalScale = 1
            } else if(sorted[sorted.length -1][`${AxisProp}`] < (intervalScale/2) && sorted[sorted.length -1][`${AxisProp}`] >= 1) {
                intervalScale = 0.25
            } else if(sorted[sorted.length -1][`${AxisProp}`] < 1 && sorted[sorted.length -1][`${AxisProp}`] >= 0.5) {
                intervalScale = 0.00001
            } else if(sorted[sorted.length -1][`${AxisProp}`] < 0.5) {
                intervalScale = 0.0000001
            }
            // console.log(intervalScale)

            // console.log(Math.round((sorted[0][`${AxisProp}`] - sorted[0][`${AxisProp}`] * bufferPerc)/10)*10)
            // console.log(Math.round((sorted[sorted.length -1][`${AxisProp}`] + sorted[sorted.length -1][`${AxisProp}`] * bufferPerc)/10)*10)

            min = AxisProp === 'SecurityPrice' ? ( ( (sorted[0][`${AxisProp}`]) - ( sorted[0][`${AxisProp}`] * bufferPerc ) )/intervalScale) *intervalScale : Math.round( ( (sorted[0][`${AxisProp}`]) - ( sorted[0][`${AxisProp}`] * bufferPerc ) )/intervalScale) *intervalScale

            max = AxisProp === 'SecurityPrice' ? ( (sorted[sorted.length -1][`${AxisProp}`] + ( sorted[sorted.length -1][`${AxisProp}`] * bufferPerc ) )/intervalScale) *intervalScale : Math.round( (sorted[sorted.length -1][`${AxisProp}`] + ( sorted[sorted.length -1][`${AxisProp}`] * bufferPerc ) )/intervalScale) *intervalScale
            // min = Math.round( ( (sorted[0][`${AxisProp}`]) - ( sorted[0][`${AxisProp}`] * bufferPerc ) )/intervalScale) *intervalScale
            // max = Math.round( (sorted[sorted.length -1][`${AxisProp}`] + ( sorted[sorted.length -1][`${AxisProp}`] * bufferPerc ) )/intervalScale) *intervalScale
            // console.log(AxisProp, min, max)
            if(AxisProp === 'ComparedValue' && lx === 'Sentiment' && max > 100) {
                max = 100;
            }
        }
        if(type === 'min') {
            return min
        } else {
            return max;
        }
    }
        
    let dataSet = (A, B) => {
        let dataSet = [[A, B]];
        // let { offsetMins } = NotifyFeedsHandler.TimeZoneOffsetSettings();
        feeds.forEach((feed) => {
            // console.log(feed[`${A}`])
            // console.log(moment.utc(feed[`${A}`]).format())
            // let data = [moment.utc(feed[`${A}`]).format(), feed[`${B}`]]
            let data = [feed[`${A}`], feed[`${B}`]]
            // let data = [NotifyFeedsHandler.GraphTimeToLocal(feed[`${A}`]), feed[`${B}`]]
            dataSet.push(data)
        })
        return dataSet;
    }

    let setYAxis = () => {
        if(usertype === 'None'  || usertype === 'WUser' || usertype === 'WAdmin') {
            return [{
                type: 'value',
                name: variant,
                nameLocation: 'end',
                // min: variant === 'Sentiment' ? MaxMinRange('ComparedValue', 'min') : MaxMinRange('ComparedValue', 'min'),
                // max:  variant === 'Sentiment' ? MaxMinRange('ComparedValue', 'max') : MaxMinRange('ComparedValue', 'max'),
                min: variant === 'Sentiment' ? MaxMinRange('ComparedValue', 'min', variant) : MaxMinRange('ComparedValue', 'min', variant),
                max:  variant === 'Sentiment' ? MaxMinRange('ComparedValue', 'max', variant) : MaxMinRange('ComparedValue', 'max', variant),
                nameTextStyle: {
                    color: '#50C0FF',
                    align: minify ? 'left' : 'right'
                },
                axisLabel: {
                    formatter: (function(value) {
                        if(variant === 'Sentiment') {
                            return `${value/10}/10`
                        } else if(variant === 'Mentions') {
                            return `${TrendingMentionsHelper.abbreviateNumber(parseInt(value))}`
                        } else if(variant === 'Followers') {
                            return `${TrendingMentionsHelper.abbreviateNumber(parseInt(value))}`
                        }
                    }),
                    textStyle: {
                        color: '#50C0FF',
                        fontSize: minify ? 9 : 12
                    }
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        width :0.5,
                        color: '#666666'
                    }
                },
                
            },
            {
                type: 'value',
                boundaryGap: ['20%', '20%'],
                name: 'Price',
                nameLocation: 'end',
                // min: MaxMinRange(base, 'min'),
                // max: MaxMinRange(base, 'max'),
                min: MaxMinRange(base, 'min', 'Price'),
                max: MaxMinRange(base, 'max', 'Price'),
                nameTextStyle: {
                    color: '#CA82FF',
                    align: minify ? 'right' : 'left'
                },
                axisLabel: {
                    interval: 0,
                    formatter: (function(value) {
                        return `${TrendingMentionsHelper.currencyFormat(value, '$')}`
                    }),
                    textStyle: {
                        color: '#CA82FF',
                        fontSize: minify ? 9 : 12
                    }
                },
                splitLine: {
                    show: false,
                    lineStyle: {
                        width :0.5,
                        color: '#666666'
                    }
                }
            }]
        } else {
            return [{
                type: 'value',
                name: variant,
                nameLocation: 'end',
                // min: variant === 'Sentiment' ? MaxMinRange('ComparedValue', 'min') : MaxMinRange('ComparedValue', 'min'),
                // max:  variant === 'Sentiment' ? MaxMinRange('ComparedValue', 'max') : MaxMinRange('ComparedValue', 'max'),
                min: variant === 'Sentiment' ? MaxMinRange('ComparedValue', 'min', variant) : MaxMinRange('ComparedValue', 'min', variant),
                max:  variant === 'Sentiment' ? MaxMinRange('ComparedValue', 'max', variant) : MaxMinRange('ComparedValue', 'max', variant),
                nameTextStyle: {
                    color: '#50C0FF',
                    align: minify ? 'left' : 'right'
                },
                axisLabel: {
                    formatter: (function(value) {
                        if(variant === 'Sentiment') {
                            return `${value/10}/10`
                        } else if(variant === 'Mentions') {
                            return `${TrendingMentionsHelper.abbreviateNumber(parseInt(value))}`
                        } else if(variant === 'Followers') {
                            return `${TrendingMentionsHelper.abbreviateNumber(parseInt(value))}`
                        }
                    }),
                    textStyle: {
                        color: '#50C0FF',
                        fontSize: minify ? 9 : 12
                    }
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        width :0.5,
                        color: '#666666'
                    }
                },
                
            }]
        }
    }

    let setYSeries = () => {
        if(usertype === 'None'  || usertype === 'WUser' || usertype === 'WAdmin') {
            return [
                {
                  type: 'line',
                  connectNulls: false,
                  color: '#50C0FF',
                  data: dataSet(fixed, 'ComparedValue'),
                  showSymbol: false,
                  encode: {
                    x: fixed,
                    y: 'ComparedValue',
                    itemName: variant,
                    tooltip: [variant]
                  },
                  lineStyle: {
                      width: 2
                  },
                  areaStyle: {
                    color: new graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: 'rgb(63,120,161)'
                        },
                        {
                            offset: 1,
                            color: 'rgba(63,120,161, 0)'
                        }
                    ])
                  }
                },
                {
                  type: 'line',
                  connectNulls: false,
                  color: '#CA82FF',
                  data: dataSet(fixed, base),
                  yAxisIndex: 1,
                  showSymbol: false,
                  encode: {
                    x: fixed,
                    y: base,
                    itemName: base,
                    tooltip: [base]
                  },
                  lineStyle: {
                    width: 2
                  }
                }
            ]
        } else {
            return [
                {
                  type: 'line',
                  connectNulls: false,
                  color: '#50C0FF',
                  data: dataSet(fixed, 'ComparedValue'),
                  showSymbol: false,
                  encode: {
                    x: fixed,
                    y: 'ComparedValue',
                    itemName: variant,
                    tooltip: [variant]
                  },
                  lineStyle: {
                      width: 2
                  },
                  areaStyle: {
                    color: new graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: 'rgb(63,120,161)'
                        },
                        {
                            offset: 1,
                            color: 'rgba(63,120,161, 0)'
                        }
                    ])
                  }
                }
            ]
        }
    }
    return {
        responsive: true,
        // title: {
        //     text: `${ticker.SecurityName} (${ticker.SecurityTicker})`
        // },
        textStyle: {
            fontFamily: 'DM Sans',
            fontSize: minify ? 12 : 14
        },
        tooltip: {
            trigger: 'axis',
            confine: true,
            formatter: function (params) {
                // console.log(params)
                let Date = (usertype === 'None'  || usertype === 'WUser' || usertype === 'WAdmin') ? moment.utc(params[1].value[0]).format('DD-MM-YYYY') : moment.utc(params[0].value[0]).format('DD-MM-YYYY')
                let Time =  (usertype === 'None'  || usertype === 'WUser' || usertype === 'WAdmin') ? NotifyFeedsHandler.GraphTimeToLocal(params[1].value[0]) : NotifyFeedsHandler.GraphTimeToLocal(params[0].value[0])
                
                // let timeTip = `<div style="color: #DDDDDD;">
                //     <span>${moment(params[1].value[0]).format('DD-MM-YYYY')}</span> &nbsp;&nbsp; <span>${moment(params[1].value[0]).format('HH:mm')}</span>
                // </div>`;
                let timeTip = `<div style="color: #DDDDDD;">
                    <span>${Date}</span> &nbsp;&nbsp; <span>${Time}</span>
                </div>`;
                let baseTip = (usertype === 'None'  || usertype === 'WUser' || usertype === 'WAdmin') ? `<div style="color: #CA82FF;">
                    <span>Price</span> : <span>${TrendingMentionsHelper.currencyFormat(params[1].value[1], '$')}</span>
                </div>` : ``;
                let MetricTip = `<div style="color: #50C0FF;">
                    <span>${variant}</span> : <span>${variant === 'Sentiment' ? (params[0].value[1]/10)+'/10' : TrendingMentionsHelper.abbreviateNumber(parseInt(params[0].value[1]))}</span>
                </div>`;
                return `${timeTip}${MetricTip}${baseTip}`;
            },
            // backgroundColor: '#14161E'
            backgroundColor: Theme === 'light' ? '#FFFFFF' : '#14161E',
        },
        backgroundColor: Theme === 'light' ? '#FFFFFF' : '#14161E',
        // backgroundColor: '#14161E',
        dataZoom: [
            {
                type: 'slider',
                show: true,
                start: 0,
                end: 100,
                labelFormatter: function (value, valueStr) {
                    return `${moment(value).format('DD/MM/YYYY')}\n${NotifyFeedsHandler.GraphTimeToLocal(value)}`;
                },
                textStyle: {
                    color: '#FFFFFF',
                    // color: '#50C0FF',
                    fontSize: 11,
                    fontWeight: 'bold'
                }
            },
            {
                type: 'inside',
                start: 0,
                end: 100
            }
        ],
        grid: {
            left: '10%',
            right: '10%',
            bottom: '10%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'time',
                axisLabel: {
                    rotate: 30,
                    formatter: (function(value){
                        if(moment(value).format('DD') === '01') {
                            return moment(value).format('MMM');
                        } else if(moment(value).format('HH:mm') === '00:00') {
                            return moment(value).format('MMM DD');
                        } else {
                            return NotifyFeedsHandler.GraphTimeToLocal(value);
                        }

                        
                    })
                },
                splitLine: {show: false}
            }
        ],
        yAxis:  setYAxis(),
        series: setYSeries()
    }
}