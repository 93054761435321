import React, { Component } from 'react';
// import { Row, Col } from 'react-bootstrap';
import PreferenceItem from './preferenceitem';
import PreferenceCategory from './preferencecategory';
// import { connect } from 'react-redux';
// import * as appSettingsActionCreators from '../../actions/appSettingsAction';
// import * as moment from 'moment';

class Preferencelist extends Component {
  constructor() {
    super();
    this.state = {
      category: '',
      categoryData: '',
      view: false,
    };
  }

  componentWillMount() {
    this.setState({
      ...this.state,
      category: this.props.category,
      categoryData: this.props.categoryData,
      view: this.props.itemView,
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      ...this.state,
      category: nextProps.category,
      categoryData: nextProps.categoryData,
      view: nextProps.itemView,
    });
  }

  render() {
    const { category, categoryData } = this.state;
    // console.log(categoryData)
    let list = Object.keys(categoryData).map((key) => {
      // console.log(key, categoryData[key])
      if (categoryData[key] && Object.keys(categoryData[key]).length && typeof categoryData[key] !== 'string') {
        return (
          <PreferenceCategory
            {...this.props}
            TimeZoneList={this.props.TimeZoneList}
            categoryData={categoryData[key]}
            category={key}
            key={key}
            handleUserPreferences={this.props.handleUserPreferences}
          />
        );
      } else {
        return (
          <PreferenceItem
            {...this.props}
            TimeZoneList={this.props.TimeZoneList}
            title={key}
            value={categoryData[key]}
            key={key}
            category={category}
            handleUserPreferences={this.props.handleUserPreferences}
          />
        );
      }
    });
    return <div className={`bodypanel-body ${!this.state.view ? 'nodisp' : ''}`}>{list}</div>;
  }
}

export default Preferencelist;
