import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Row, Col } from 'react-bootstrap';
import { APPPATH } from '../../../../modules/helper.module';
import GoogleMultiplexAds from '../../../googleAdsense/multiplexad';
import GooglePopupAds from '../../../googleAdsense/googlepopupad';
import GoogleAds from '../../../googleAdsense/googleads';
import { isMobile } from 'react-device-detect';

class AdModal extends Component {
  render() {
    // style={{width: '100%', height: isMobile? 300 : 450}}
    

    return (
      <div>
        <div className="btn tradeWindowClose text-white nopad marg-0" onClick={this.props.modalClose} style={{position: 'fixed', top: '10px', marginRight: '10px'}}>
          <span className="pad10">
            <img className="tradeWindowClose-closeicon" src={`${APPPATH.ImageUrl}/button-close-bold.svg`} alt="" />
          </span>
        </div>
        <div className="watchlist-content-ad justify-content-center text-center" >
          
          <Row className="marg-0 ">
            <Col xs={12} sm={12} md={12} xl={12} lg={12} className="nopad ">
              <div  className="" style={{marginLeft: '0px', padding: '0px 5px 0px 5px'}}>
              {/* margin: isMobile ? '40% 6%' : '6%' */}
                {/* <GoogleMultiplexAds resize={this.props.resize} SlotId={`6841152970`} /> */}
                <GooglePopupAds resize={this.props.resize} SlotId={`7609926277`} />
                {/* <GoogleAds count={0} SlotId={isMobile ? '2248421401' : '7572884366'} resize={this.props.resize} width={isMobile ? '250px' : '728px'} height={isMobile ? '300px' : '450px'} resizeColumn={false}/> */}
              </div>
              
            </Col>
          </Row>

          
        </div>
      </div>
    );
  }
}

export default AdModal;
