import React, { Component } from 'react'; 
import { connect } from 'react-redux';
import * as filtersActionCreators from '../../actions/filters.action';
import EventListItem from './event-list-item';

class EventSetting extends Component {
  constructor() {
    super();
    this.state= {
      parentText: '',
      parentActive: false,
      eventFilter:[],
      eventTypes: [],
      heirarchialState: []
    }
  }
 
  componentWillMount() {
    let eventTypes = this.props.eventTypes;
    let eventFilter = this.props.eventFilter;
    this.setEventTypes(eventTypes, eventFilter)
  }

  componentWillReceiveProps(nextProps) {
    if(this.props.active !== nextProps.active) {
      let eventTypes = nextProps.eventTypes;
      let eventFilter = nextProps.eventFilter;
      this.setEventTypes(eventTypes, eventFilter, nextProps)
    }
  }

  setEventTypes = (eventTypes, eventFilter, nextProps = false) => {
    let parentState = [];
    let HeirarchialState = [];
    if(eventTypes.length) {
      eventTypes.forEach((evnt,i) => {
        if(eventFilter.length) {
          eventFilter.forEach(filt => {
            if(evnt.Name.toString().trim().toLowerCase() === filt.toString().trim().toLowerCase()) {
              evnt.selected = true;
            }
          })
        }
        if(evnt.EventTypeParent === 0) {
          parentState.push(evnt.EventTypeID);
        }
      })
      HeirarchialState.push(parentState);
    }
    this.setState({
      ...this.state,
      parentActive: nextProps? nextProps.active : this.props.active,
      parentText: nextProps? nextProps.text : this.props.text,
      eventFilter: nextProps? nextProps.eventFilter : this.props.eventFilter,
      eventTypes: nextProps? nextProps.eventTypes : this.props.eventTypes,
      heirarchialState: HeirarchialState
    })
  }

  getRecursiveChildren = (bucketArr, keyArr, resp) => {
    
    if(keyArr.length) {
      bucketArr.forEach(ele => {
        if(keyArr.indexOf(ele.EventTypeID) > -1) {
          resp.push(ele.EventTypeID)
          
          
          if(ele.children.length) {
            this.getRecursiveChildren(bucketArr, ele.children, resp)
          }
        }
      })
    }
    return {eventTypes: resp};
  }
  getRecursiveParent = (key,selectedChild, bucketArr, stateFilter, resp, iterationCounts = 0) => {
    if(key !== 0) {
      bucketArr.forEach(ele => {
        if(ele.EventTypeID === key) {
          let chST = []
          bucketArr.forEach((cele,i) => {
            if(ele.children.indexOf(cele.EventTypeID) > -1) {
              if(iterationCounts === 0) {
                if(cele.EventTypeID !== selectedChild) {
                  chST.push(cele.selected)
                }
              } else {
                chST.push(cele.selected)
              }
            }
          })
          if(stateFilter) {
            if(chST.indexOf(stateFilter) === -1) {
              // resp.push(ele.EventTypeID)
            } else if(chST.indexOf(!stateFilter) === -1) {
              resp.push(ele.EventTypeID)
            } 
          } else {
            resp.push(ele.EventTypeID) 
            if(chST.indexOf(!stateFilter) === -1) {
              // resp.push(ele.EventTypeID) 
            } 
          }
          // if((stateFilter && chST.indexOf(stateFilter) > -1 && chST.indexOf(!stateFilter) === -1) || (!stateFilter && chST.indexOf(!stateFilter) > -1 && chST.indexOf(stateFilter) === -1)) {
          //   resp.push(ele.EventTypeID)
          // } 
          if(ele.EventTypeParent > 0) {
            iterationCounts++;
            this.getRecursiveParent(ele.EventTypeParent,ele.EventTypeID,bucketArr,stateFilter,resp)
          }
        }
      })
    }
    return resp
  }
  getRecursiveParentStage = (event, bucketArr, stateFilter, resp) => {
    
    
      let parents = this.getRecursiveParent(event.EventTypeParent, event.EventTypeID, bucketArr, stateFilter, resp);
      return parents;
  }

  manageFilter = (stateFilter, Event) => {
    let EF = [];
    let rawevents = this.props.eventTypes;
    let affectedChildren = this.getRecursiveChildren(rawevents, Event.children, [])
    let affectedParents = this.getRecursiveParentStage(Event, rawevents, stateFilter, [])
    this.props.eventTypes.forEach((element,x) => {
      if(affectedChildren.eventTypes.length) {
        if(affectedChildren.eventTypes.indexOf(element.EventTypeID) > -1) {
          this.props.eventTypes[x].selected = stateFilter;
        }
      }
      if(affectedParents.length) {
        if(affectedParents.indexOf(element.EventTypeID) > -1) {
          this.props.eventTypes[x].selected = stateFilter;
        }
      }
    })
    this.props.eventTypes.forEach((element,x) => {
      if(element.EventTypeID === Event.EventTypeID) {
        element.selected = stateFilter
      }
    })
    this.props.eventTypes.forEach(el => {
      if(el.selected) {
        EF.push(el.Name);
      }
    })
    let data = {
      "UserName": this.props.userData.username,
      "FilterString": EF.join(';')
    }
    this.props.updateEventSecurityFilter(data)
    this.setState({
      ...this.state,
      eventFilter: EF
    })
  }

  render() {
    
    const { parentActive, parentText, heirarchialState } = this.state
    let eventList = [];
    if(parentText === 'Events' && parentActive) {
      let grpind = 0;
      heirarchialState.forEach((eachGroup, groupIndex) => {
        let groupEventList = []
        if(this.props.eventTypes.length) {
          let evntcnt = 0;
          this.props.eventTypes.forEach((item) => {
            let indexchange = false;
            if(eachGroup.indexOf(item.EventTypeID) > -1) {
              if(groupIndex !== grpind) { indexchange = true; grpind = groupIndex }
              groupEventList[evntcnt] = <EventListItem event={item} groupindex={groupIndex} akey={item.EventTypeID} indexChange={indexchange} key={item.EventTypeID} managefilter={this.manageFilter} selected={item.selected} eventTypes={this.props.eventTypes} userData={this.props.userData} />
              evntcnt++;
            }
          })
        }
        eventList.push(groupEventList)
      })
    }
    
    return (
      
      <div className={`securityItem`}>
        { parentText === 'Events' ? eventList : '' }
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    userData: state.settings.userData,
    eventTypes: state.filters.eventTypes,
    eventFilter: state.filters.eventFilter
  }
}
export default connect(mapStateToProps, filtersActionCreators)(EventSetting)