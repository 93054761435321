import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import './appLoaderSpinner.css';
import { APPPATH } from '../../../../modules/helper.module';
import { connect } from 'react-redux';
import APPLoader from '../iforexloader/Iforexloader';

class SpinnerLoader extends Component {
    constructor(props) {
        super();
        this.state = {
            //   show: true
            viewSize: props.viewSize || 'sm'
        }
        this.close = '';
    }
    componentDidMount() {
        // console.log(this.state.viewSize)
        if (this.state.viewSize === 'sm') {
            this.close = setTimeout(() => {
                //     window.location.href = APPPATH.AppUrl;
                this.props.modalClose();
            }, 31000)
        }

    }

    componentWillUnmount() {
        clearTimeout(this.close);
    }


    render() {
        let Theme = this.props.forcetheme || this.props.Theme
        // console.log(Theme)
        return (

            <div className={`full-width-height ${Theme}`} style={{ position: 'relative', backgroundColor: Theme === 'dark' ? 'rgba(34,34,34,0)' : 'rgba(255,255,255,0)', left: '0px', marginTop: "20px", minHeight: this.props.render === 'iframe' && this.state.viewSize === 'sm' ? '200px' : 'unset' }}>
                <div className={`loader full-width-height ${this.state.viewSize}`} style={{ marginTop: this.props.render === 'iframe' && this.state.viewSize === 'sm' ? '35vh' : '0px' }}></div>
            </div>

        );
    }

}




const mapStateToProps = (state, ownProps) => {
    return {
        Theme: state.app_preferences.preferences.user_interface.Theme,
        forcetheme: state.settings.forcetheme,
        render: state.settings.render
    };
};

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(SpinnerLoader);