import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import StoryDetailsEach from './story_details_each';
import { isMobile } from 'react-device-detect';
import SpinnerLoader from '../shared/loaders/apploaderspinner/appLoaderSpinner';

class StoryDetails extends Component {
  constructor() {
    super();
    this.state = {
      story: {},
      feedDetails: [],
    };
  }
  componentWillMount() {
    this.setState({
      ...this.state,
      story: this.props.story,
      feedDetails: this.props.feedDetails,
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      ...this.state,
      story: nextProps.story,
      feedDetails: nextProps.feedDetails,
    });
  }

  feedScroll = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (isMobile) {
      this.props.lockSwipe();
    }
    this.props.togglePopup(false);
    window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
    window.Mixpanel.actions.identify(this.props.userData.username);
    window.Mixpanel.actions.track('Scrolling Feed Details', window.Mixpanel.MixpanelProps).then((data) => {
      window.Mixpanel.actions.people.set({
        UserName: this.props.userData.username,
      });
    });
  };

  render() {
    const { collapse, collapsesection, feedDetailsRequest, Reports } = this.props;
    const { story, feedDetails, DomRect } = this.state;
    let magnitudeBg;
    switch (story.MagnitudeRate) {
      case 1:
        magnitudeBg = 'medium';
        break;
      case 2:
        magnitudeBg = 'high';
        break;
      default:
        magnitudeBg = 'low';
        break;
    }
    let feedDetailList;
    if (!collapse) {
      let filteredFeedDetail = feedDetails[`${story.FeedId}`] || [];
      let reducedFeedDetail = [];
      let reversedFeedDetail = [];
      if(filteredFeedDetail.length > 0) {
        filteredFeedDetail.forEach((element, i) => {
          if(i < Reports) {
            reducedFeedDetail.push(element)
          }
        });
      }
      if(reducedFeedDetail.length) {
        for(let i = reducedFeedDetail.length-1; i >=0; i--) {
          reversedFeedDetail.push(reducedFeedDetail[i])
        }
      }
      feedDetailList = reversedFeedDetail.length ? (
        reversedFeedDetail.map((eachFeed, i) => {
          // console.log(eachFeed)
          return (
            <StoryDetailsEach
              eachFeed={eachFeed}
              key={i}
              akey={i}
              handleCollapse={this.props.handleCollapse}
              clickable={false}
              rootId={story.FeedId}
              lang={this.props.lang}
              lastFeed={i === reversedFeedDetail.length -1}
              {...this.props}
            />
          );
        })
      ) : feedDetailsRequest ? (
        <Col lg={12} sm={12} xs={12} md={12} xl={12} className="story-feed nofeed nopad">
          <Row className="marg-0 align-items-center" style={{padding: 10}}>
            <Col lg={12} sm={12} xs={12} md={12} xl={12} className="">
              <div>
                <SpinnerLoader {...this.props} viewSize={'xs'}  />;
                {/* <i className="fa fa-spinner fa-pulse fa-fw feed-detail-spinner"></i> */}
              </div>
            </Col>
          </Row>
        </Col>
      ) : (
        <Col lg={12} sm={12} xs={12} md={12} xl={12} className="story-feed nofeed nopad">
          <Row className="marg-0 align-items-center" style={{padding: 10}}>
            <Col lg={12} sm={12} xs={12} md={12} xl={12} className="">
              <div>No feeds to display</div>
            </Col>
          </Row>
        </Col>
      );
    }
    const LastFeedDetail = (
      <StoryDetailsEach
        eachFeed={story}
        key={story.FeedId}
        handleCollapse={this.props.handleCollapse}
        clickable={true}
        lastFeedDetail={true}
        akey={0}
        rootId={story.FeedId}
        {...this.props}
        FeedDetailBound={DomRect}
        render={this.props.render}
        lang={this.props.lang}
        Theme={this.props.Theme}
      />
    );

    return (
      <Row
        className={`marg-0  justify-content-center story-feeds noScrollWrapper ${
          !collapse ? 'overflow-feeds' : ''
        } ${(magnitudeBg === 'low' || magnitudeBg === 'medium' || magnitudeBg === 'high') && collapse ? 'nodisp' : ''}`}
        onScroll={this.feedScroll}
        ref={`feedcont`}
      >
        
        {!collapse && collapsesection === 'feed' ? feedDetailList : LastFeedDetail}
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    feedDetails: state.feeds.feedDetails,
    render: state.settings.render,
    lang: state.settings.lang,
    Theme: state.app_preferences.preferences.user_interface.Theme,
  };
};

export default connect(mapStateToProps, null)(StoryDetails);
