import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import HeadBar from '../shared/headbar/headbar';
import HeadbarIframe from '../shared/headbar/headbar_iframe';
import MenuFilter from '../shared/headbar/menu_filter';
import PageLoader from '../shared/toolbar/pageloader';
import { connect } from 'react-redux';
// import * as feedActionCreators from '../../actions/feedsAction';
import * as settingsActionCreators from '../../actions/settings.action';
import * as filtersActionCreator from '../../actions/filters.action';
import * as preferencesActionCreator from '../../actions/apppreferences.action';
import { getTMFrame, TMViewDetails, getCoinData } from '../../actions/trendingmentions.action';
// import * as logActionCreators from '../../actions/logAction.action';
import * as moment from 'moment';
import NotifModule from '../../modules/notifications.module';
import Alerts from '../shared/alert/cralerts';
import Popups from '../shared/popups/popupWrap';
import DesktopAppNotificationBanner from '../shared/banners/desktopAppNotificationBanner';
import { APPPATH, TrendingMentionsHelper } from '../../modules/helper.module';
// import fbApp from '../../firebase';
import Search from '../search/search';
import SearchSaveContextHandle from '../search/searchSaveUpdater';
import StoryContextHandle from '../stories/storyUpdater';
// import TMContextHandle from '../trendingmentions/tmUpdater';
import TMContextHandleWrap from '../trendingmentions/tmUpdaterWrap';
import TMDetailsContextHandle from '../trendingmentions/tmDetailsUpdater';
import TMDetailsHandleWrap from '../trendingmentions/tmdetailscolumnupdaterwrap';
import OverlayWrap from '../shared/popups/overlayWrap';

import Modals from '../shared/modal/modals';
import Swipeable from '../swipeable/swipeable';
import { isMobile } from 'react-device-detect';
import Scrollable from '../scrollable/scrollable';
import ConfirmationPopUp from '../common/confirmationPopUp';
// import UpgrageToPremiumPopUp from '../common/confirmationPopUp';
import GoPremiumBanner from '../shared/banners/gopremiumbanner';
// import { getMessaging, getToken } from 'firebase/messaging';

import GoogleAds from '../googleAdsense/googleads';
import InFeedAd from '../googleAdsense/infeedAdComponent';
//  import ErrorGlobalBoundary from '../error/error-global';
import MobileAppBanner from '../shared/banners/mobileAppinstall';

class Home extends Component {
  constructor(props) {
    super();
    const urlSearchParams = new URLSearchParams(props.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const flag = params && Object.keys(params).length === 0 && Object.getPrototypeOf(params) === Object.prototype;
    if (!flag && params.SecurityName) {
      // window.localStorage.setItem('SavedCoinDetail', JSON.stringify(params));
      // window.localStorage.setItem('CoinDetailColumnShowState', true);
    }
    this.state = {
      popupad: false,
      urlParams: params,
      page: '',
      utcOffset: 0,
      systemTime: props.systemTime,
      prevPath: props.location.pathname,
      pageLoaders: {
        stories: false,
        securities: false,
        settings: false,
        changepassword: false,
        contactus: false,
        feedbacks: false,
        userpreferences: false,
      },
      viewport: {
        height: '',
        width: '',
      },
      messageNotifications: props.messageNotifications,
      AlertShow: false,
      AlertTitle: '',
      AlertContent: '',
      modalShow: false,
      modalContent: '',
      modalData: {},
      popupShow: false,
      popupContent: '',
      popupPos: {
        left: 0,
        top: 0,
      },
      popupContainerClass: {},
      overlayShow: false,
      overlayTarget: '',
      overlayTip: '',
      overlayStyleData: {},
      viewBronzeBanner: props.userData.userPackage.toLowerCase() === 'bronze' ? true : false,
      viewVerifyEmailBanner: this.verfyEmailBanner(props),
      viewVerifyEmailButton: false,
      viewPremiumBanner: false,
      viewPremiumButton: false,
      showconfirmpopup: false,
      showconfirmonlyok: false,
      showconfirmmessage: '',
      mobileAppInstallBanner: false,
      randomBannerState: 0
    };

    this.locationHandle = '';
    this.timeInterval = '';
    this.holidayTimeInterval = '';

    this.refreshInterval = '';
    this.stryTimeout = '';
    this.TTipTimeout = '';

    this.storyHandle = React.createRef();
    this.searchSaveHandle = React.createRef();
    this.tmHandle = React.createRef();
    this.tmDetailsHandle = React.createRef();
    this.tmDetailsHandlesWrap = React.createRef();
    if (props.render !== 'iframe') {
      props.getTimeZoneList();
      props.getdesktopapppreferences();
      props.getnotificationpreferences();
      props.getphonepreferences();
    }

    props.getSystemTime();
    // 
    props.getuserpreferences();
    // 
    props.getlocalisationpreferences();
    // 
    props.getTMFrame();
    props.reportLogin();

    props.getEventTypeFilter();
    props.getSecurityTypeFilter();
    props.getSecuritiesFilter();
    // props.getTickerIconsList(`${APPPATH.ImageUrl}/iconsforcoins/coinslist.txt`)

    window.HandlePress = (text) => {
      // console.log(text)
      const el = document.createElement('textarea');
      el.value = text;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.toggleAlert(true, 'Headline copied to clipboard', text);
    };
    window.createRipple = (e, options) => {
      let target = e.currentTarget;
      // console.log(target, this.searchCalendarContainer)
      const circle = document.createElement('span');
      const diameter = Math.max(target.offsetWidth, target.offsetHeight);
      // console.log(diameter)

      const radius = diameter / 2;

      circle.style.width = circle.style.height = `${diameter}px`;
      circle.style.left = `${radius - target.offsetWidth / ((options && options.left) || 2)}px`;
      circle.style.top = `${-target.offsetTop + ((options && options.top) || 2)}px`;
      circle.classList.add('ripple');
      const ripple = target.getElementsByClassName('ripple')[0];
      if (ripple) {
        ripple.remove();
      }
      target.appendChild(circle);
    };

    window.imgError = (e) => {
      e.currentTarget.style.display = 'none';
    };
    // window.oncontextmenu = (e) => {
    //   e.preventDefault();
    //   e.stopPropagation(); // not necessary in my case, could leave in case stopImmediateProp isn't available? 
    //   e.stopImmediatePropagation();
    //   return false;
    // }
  }

  closeBronzeBanner = () => {
    let showPremiumButton = true;
    if (this.props.userData.userPackage.toLowerCase() === 'premium' || !TrendingMentionsHelper.FreeSubscriptionFilter()) {
      showPremiumButton = false;
    }
    this.setState(
      {
        ...this.state,
        // viewBronzeBanner: false,
        viewPremiumBanner: false,
        viewPremiumButton: showPremiumButton,
      },
      () => {
        this.resize();
      }
    );
  };
  closeMobileAppInstallBanner = () => {
    this.setState({
      ...this.state,
      mobileAppInstallBanner: false
    }, () => {
      this.resize();
    });
  };

  closeEmailVerificationBanner = () => {
    // this.props.closeVerifyEmailBox(true);
    this.setState(
      {
        ...this.state,
        viewVerifyEmailBanner: false,
        viewVerifyEmailButton: true,
      },
      () => {
        this.resize();
      }
    );
  };

  toggleOverlay = (status, target = false, text = false, styleData = false) => {
    // console.log(status, target, text)
    if (this.props.userData.usertype === 'None' || this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin') {
      clearTimeout(this.TTipTimeout);

      // this.TTipTimeout = setTimeout(() => {
      this.setState({
        ...this.state,
        overlayShow: status,
        overlayTarget: target || '',
        overlayTip: text || '',
        overlayStyleData: styleData || {},
      });
      // }, 1000);
    }

  };

  toggleAlert = (status, title = false, content = false) => {
    this.setState({
      ...this.state,
      AlertShow: status,
      AlertTitle: title || '',
      AlertContent: content || '',
    });
  };

  togglePopup = (status, content = '', mousePos = {}, containerClass = '') => {
    this.setState({
      ...this.state,
      popupShow: status,
      popupContent: content,
      popupPos: mousePos,
      popupContainerClass: containerClass,
    });
  };
  componentDidMount() {

    const urlSearchParams = new URLSearchParams(this.props.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    const mobileAppFlag = params && Object.keys(params).length !== 0 && Object.keys(params).length === 1 && Object.getPrototypeOf(params) === Object.prototype && typeof params.mobileapp !== 'undefined' && params.mobileapp === 'true';

    let flag = params && Object.keys(params).length === 0 && Object.getPrototypeOf(params) === Object.prototype;
    if (!flag && mobileAppFlag) { flag = true; }

    // console.log(TrendingMentionsHelper.FreeSubscriptionFilter() , flag , params)
    if (TrendingMentionsHelper.FreeSubscriptionFilter() && flag) {
      let popupad = this.state.popupad;
      if (popupad === true) {
        popupad = false;
        this.showModal('PopupAd');
      }
    }
    if (this.props.maintenancemodeon) {
      this.showModal('UnderMaintenance');
    }

    if (!this.props.userData.isLoggedIn) {
      this.props.history.push('/');
      // window.location.href = APPPATH.AppUrl;
    } else {
      // console.log(this.props.userData.loginlessuser, this.props.render, this.props.Theme, this.props.lang)
      let User = this.props.userData;
      window.Mixpanel.MixpanelProps.org = User.loginlessuser;
      window.Mixpanel.MixpanelProps.render = this.props.render;
      window.Mixpanel.MixpanelProps.theme = this.props.Theme;
      window.Mixpanel.MixpanelProps.lang = this.props.lang;

      window.Mixpanel.MixpanelProps.LoginTime = User.loginTime;
      window.Mixpanel.MixpanelProps.ExpiryTime = User.expiryTime;
      window.Mixpanel.MixpanelProps.UserName = User.username;
      window.Mixpanel.MixpanelProps.LoginType = User.autologin ? 'Auto' : 'Manual';
      window.Mixpanel.actions.identify(User.username);
      let loginStatustext = User.passlessLogin ? 'Successfull login without password' : 'Successfull login'
      window.Mixpanel.actions.track(loginStatustext, window.Mixpanel.MixpanelProps).then((data) => {
        window.Mixpanel.actions.people.set({
          UserName: User.username,
        });

        let viewPort = this.setHomeBodyStyle(true, this.props.page);
        // let showVerifyEmailBanner = this.verfyEmailBanner(this.props);
        let randomBannerState = 0;
        let showPremuimBanner = this.verfyPremiumBanner(this.props);
        let showPremiumButton = !showPremuimBanner;
        if (User.userPackage.toLowerCase() === 'premium' || !TrendingMentionsHelper.FreeSubscriptionFilter()) {
          showPremiumButton = false;
        }
        let mobileAppInstallBanner = false;
        let FromMobile = params && Object.keys(params).length !== 0 && Object.getPrototypeOf(params) === Object.prototype && typeof params.mobileapp !== 'undefined' && params.mobileapp === 'true';
        this.props.setMobileApp(FromMobile)
        if (this.props.VerifiedEmail && isMobile && !FromMobile) {
          // !showPremuimBanner && 
          mobileAppInstallBanner = true;
        }
        if ((showPremuimBanner && !showPremiumButton) && (mobileAppInstallBanner)) {
          let randomBucket = [1, 2];

          randomBannerState = randomBucket[Math.floor(Math.random() * randomBucket.length)];

          console.log(randomBannerState, isMobile)
        } else if ((!mobileAppInstallBanner)) {
          randomBannerState = 1
        } else if (!(showPremuimBanner && !showPremiumButton)) {
          randomBannerState = 2
        }
        this.setState(
          {
            ...this.state,
            viewport: viewPort,
            viewPremiumBanner: showPremuimBanner,
            viewPremiumButton: showPremiumButton,
            mobileAppInstallBanner,
            randomBannerState
          },
          () => {
            // this.props.setSecuritiesFiltersRequestState()
            // console.log(mobileflag)
            // this.props.setMobileApp(mobileflag);
            this.props.getEventTypes();
            // this.props.getLocations();
            // this.props.getEventTypeFilter();
            // this.props.getLocationsFilter();
            this.props.getSecuritiesRaw();
            // this.props.getSecurityTypeFilter();
            this.props.getSecurities();
            // this.props.getSecuritiesFilter();
            // this.props.getsecuritiesresults();
            // this.props.getUserFeedBack(
            //   moment().subtract(1, 'd').utcOffset(this.state.utcOffset).format('MM-DD-YYYY HH:mm:ss'),
            //   moment().utcOffset(this.state.utcOffset).format('MM-DD-YYYY HH:mm:ss')
            // );

            // this.props.getMentionTypes();
            // this.props.getMentionTypeFilter();
            // this.props.getOrgUsers();
            // this.props.getCoinData();

            // const messaging = getMessaging();
            // return getToken(messaging, {
            //   vapidKey: 'BHcE69rfi8OWFZapo-t1e2pmpWbJ6uNMgAveA0MouyWWsKcGKiWMxDqjI4Npa6l7LpQ1Ths1vcjTyZ0RD7bJRFg',
            // }).then((currentToken) => {
            //   if (currentToken) {
            //     // console.log(currentToken);
            //     this.props.setNotificationId(currentToken);
            //   } else {
            //     // messaging
            //     console.log('request permission');
            //   }
            // });

          }
        );
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  setHomeBodyStyle = (status, page = false, props = false) => {
    let HeadbarContainerHeight = ReactDOM.findDOMNode(this.refs['headbar']).getBoundingClientRect().height;

    let ToolbarContainerHeight = ReactDOM.findDOMNode(this.refs['toolbar']).getBoundingClientRect().height;

    let SearchbarContainerHeight = props.MenuFilter === 'Search' ? 54 : 0
    //ReactDOM.findDOMNode(this.refs['searchbar']).getBoundingClientRect().height;
    // console.log('menufilter:-', props.MenuFilter)
    // console.log('Search bar height:-',SearchbarContainerHeight)
    let showVerifyEmail = false;
    if (props !== false) {
      showVerifyEmail = this.verfyEmailBanner(props);
    }
    let messageBannerContainerHeight = showVerifyEmail
      ? ReactDOM.findDOMNode(this.refs['messagebanner']).getBoundingClientRect().height
      : 0;

    let desktopBannerContainerHeight = ReactDOM.findDOMNode(this.refs['desktopbanner']).getBoundingClientRect().height;
    let premiumBannerContainerHeight = ReactDOM.findDOMNode(this.refs['premiumbanner']).getBoundingClientRect().height;
    let mobileAppbannerContainerHeight = ReactDOM.findDOMNode(this.refs['mobileAppbanner']).getBoundingClientRect().height;
    let menuContainerHeight = ReactDOM.findDOMNode(this.refs['menubar']).getBoundingClientRect().height;

    let viewPortHeight = document.documentElement.clientHeight;

    let viewPortWidth = document.documentElement.clientWidth;

    let HomeBodyHeight = '';
    if (status) {
      window.addEventListener('resize', this.resize);
    }
    // console.log('viewport height:', viewPortHeight)
    // console.log('headbar height:', HeadbarContainerHeight)
    // console.log('toolbar height:', ToolbarContainerHeight)
    // console.log('searchbar height:', SearchbarContainerHeight)
    // console.log('messagebanner height:', messageBannerContainerHeight)
    // console.log('desktopbanner height:', desktopBannerContainerHeight)
    HomeBodyHeight =
      viewPortHeight -
      (HeadbarContainerHeight +
        ToolbarContainerHeight +
        SearchbarContainerHeight +
        messageBannerContainerHeight +
        premiumBannerContainerHeight +
        mobileAppbannerContainerHeight +
        menuContainerHeight +
        desktopBannerContainerHeight);

    HomeBodyHeight = page === 'changepassword' ? HomeBodyHeight - 3 : this.props.render === 'iframe' && this.props.iframelogo ? HomeBodyHeight - 10 : this.props.render === 'iframe' && !this.props.iframelogo ? HomeBodyHeight - 8 : HomeBodyHeight - 8;
    // console.log('homebody', HomeBodyHeight)
    let viewport = {
      ...this.state.viewport,
      height: HomeBodyHeight,
      width: viewPortWidth,
    };
    return viewport;
  };
  resize = () => {
    // console.log('in resize')
    let viewPort = this.setHomeBodyStyle(false);
    this.setState(
      {
        ...this.state,
        viewport: JSON.parse(JSON.stringify(viewPort)),
      },
      () => { }
    );
  };
  verfyEmailBanner = (props) => {
    if (
      (!props.VerifiedEmail && props.userData.isLoggedIn) ||
      (!isMobile &&
        !props.VerifiedEmail &&
        props.userData.userPackage.toLowerCase() === 'bronze' &&
        props.userData.isLoggedIn)
    ) {
      return true;
    }
    return false;
  };
  verfyPremiumBanner = (props) => {
    if (props.VerifiedEmail && props.userData.userPackage.toLowerCase() === 'bronze' && props.userData.isLoggedIn) {
      return true;
    }
    return false;
  };
  componentWillReceiveProps(nextProps) {
    let viewPort = this.setHomeBodyStyle(false, nextProps.page, nextProps);
    let showVerifyEmailBanner = this.verfyEmailBanner(nextProps);
    if (this.props.Theme !== nextProps.Theme) {
      if (nextProps.Theme === 'light') {
        if (nextProps.render === 'iframe') {
          document.body.style.backgroundColor = '#ffffff';
          document.body.style.color = '#000000';
        } else {
          document.body.style.backgroundColor = '#d5d5d5';
          document.body.style.color = '#000000';
        }

      } else {
        document.body.style.backgroundColor = '#1b1b25';
        document.body.style.color = '#ffffff';
      }

    }
    this.setState(
      {
        ...this.state,
        systemTime: nextProps.systemTime,
        prevPath: this.props.location.pathname,
        viewVerifyEmailBanner: showVerifyEmailBanner,
        viewport: {
          ...this.state.viewport,
          width: viewPort.width,
          height: viewPort.height,
        },
      },
      () => {
        // if (!this.timeInterval && this.state.systemTime !== {}) {
        //   this.timeInterval = setInterval(() => {
        //     this.timeCounter(this.state.systemTime);
        //   }, 60000);
        // }
        // if (
        //   !this.holidayTimeInterval &&
        //   (this.state.systemTime.IsWeekend === true || this.state.systemTime.IsSpecialClose === true)
        // ) {
        //   this.holidayTimeInterval = setInterval(() => {
        //     this.refreshSysTime();
        //   }, 60000 * 60);
        // }
        // !this.state.modalShow && 
        if (!nextProps.SecuritiesChecklist && localStorage.getItem('tnc_accepted') && (typeof this.props.userData.loginlessuser === 'undefined' || !this.props.userData.loginlessuser)) {
          localStorage.removeItem('tnc_accepted');
          // setTimeout(() => {
          // this.showIntroVideo();
          this.WelcomeScreen();
          // }, 20000);
          //this.WelcomeScreen()
        }
      }
    );
  }

  timeCounter = (systemTime) => {
    let timeRemaining = systemTime.TimeToNextState ? systemTime.TimeToNextState - 1 : 0;

    if (timeRemaining) {
      this.setState({
        ...this.state,
        systemTime: {
          ...this.state.systemTime,
          TimeToNextState: timeRemaining,
        },
      });
    } else {
      this.refreshSysTime();
    }
  };

  refreshSysTime = () => {
    this.props.getSystemTime();
  };

  userLogout = () => {
    // let User = this.props.userData;

    window.Mixpanel.MixpanelProps.LogoutTime = moment().format();
    window.Mixpanel.MixpanelProps.LoggedInStatus = false;
    window.localStorage.removeItem('userData');
    window.sessionStorage.setItem('userLogout', true);
    window.sessionStorage.setItem('logoutData', JSON.stringify(window.Mixpanel.MixpanelProps));
    const queryString = window.location.search;
    // console.log(APPPATH.AppUrl, queryString)
    if (queryString !== '') {
      window.location.href = `${APPPATH.AppUrl}/${queryString}`;
    } else {
      window.location.href = APPPATH.AppUrl;
    }
    // window.location.href = APPPATH.AppUrl;
  };
  redirect = (target = '') => {
    this.props.history.push(`/${target}`);
  };

  togglePageLoader = (page, status) => {
    this.setState({
      ...this.state,
      pageLoaders: {
        ...this.state.pageLoaders,
        [`${page}`]: status,
      },
    });
  };

  changeModalContent = (content) => {
    this.setState({
      ...this.state,
      modalContent: content,
    });
  };

  showModal = (Page, modalData = {}) => {
    // console.log(Page)
    this.setState({
      ...this.state,
      modalShow: true,
      modalContent: Page,
      modalData,
    });
  };

  WelcomeScreen = () => {
    this.setState({
      ...this.state,
      modalShow: true,
      modalContent: 'WelcomeScreen',
    });
  };
  checkWatchList = () => {
    this.setState({
      ...this.state,
      modalShow: true,
      modalContent: 'WatchList',
    });
  };
  showIntroVideo = () => {
    this.setState({
      ...this.state,
      modalShow: true,
      modalContent: 'IntroVideo',
    });
  };
  EventTypeList = () => {
    this.setState({
      ...this.state,
      modalShow: true,
      modalContent: 'EventTypeList',
    });
  };
  PreferenceList = () => {
    this.setState({
      ...this.state,
      modalShow: true,
      modalContent: 'UserPreferenceList',
    });
  };

  searchTitleSave = () => {
    this.setState({
      ...this.state,
      modalShow: true,
      modalContent: 'SearchTitleSave',
    });
  };

  storyOptionsMenu = (Story) => {
    this.setState({
      ...this.state,
      modalShow: true,
      modalContent: 'StoryOptionsMenu',
      modalData: Story,
    });
  };
  storyShareMenu = (Story, FeedId) => {
    this.setState({
      ...this.state,
      modalShow: true,
      modalContent: 'StoryShareMenu',
      modalData: {
        Story,
        FeedId,
      },
    });
  };

  modalClose = () => {
    this.setState({
      ...this.state,
      modalShow: false,
      modalContent: '',
      modalData: {},
    });
  };
  MouseMoveOverStory = (status) => {
    // this.searchSaveHandle.current.captureMouseMoveOverStoryFromHome(status)
    // this.storyHandle.current.captureMouseMoveOverStoryFromHome(status)
  };
  pauseStoryUpdateTimer = () => {
    this.storyHandle.current.capturePauseStoryUpdateFromHome(true);
  };

  getSavedSearch = (savedData = false) => {
    if (savedData) {
      this.searchSaveHandle.current.getSavedSearch(savedData);
    }
  };

  handleMouseEnter = (e) => {
    if (this.props.userData.isLoggedIn && this.props.userData.TNC) {
      // console.log(e.srcElement.body)
      window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
      window.Mixpanel.actions.identify(this.props.userData.username);
      window.Mixpanel.actions.track('Got Focus', window.Mixpanel.MixpanelProps).then((data) => {
        window.Mixpanel.actions.people.set({
          UserName: this.props.userData.username,
        });
      });
    }
  };

  hardRefreshSystem = () => {
    // window.sessionStorage.setItem('CancelUserRoleCheck', true);
    const queryString = window.location.search;
    // console.log(APPPATH.AppUrl, queryString)
    if (queryString !== '') {
      window.location.href = `${APPPATH.AppUrl}/${queryString}`;
    } else {
      window.location.href = APPPATH.AppUrl;
    }
    // if (typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser) {
    //   window.location.href = `${APPPATH.AppUrl}/${queryString}`;
    // } else {
    //   window.location.href = APPPATH.AppUrl;
    // }

  };

  toggleConfirmPopup = (status, onlyok = false, message = '') => {
    // console.log(message);
    this.setState({
      ...this.state,
      showconfirmpopup: status,
      showconfirmonlyok: onlyok,
      showconfirmmessage: message,
    });
  };

  render() {
    const {
      systemTime,
      prevPath,
      AlertShow,
      AlertTitle,
      AlertContent,
      modalContent,
      modalShow,
      modalData,
      popupShow,
      popupContent,
      popupPos,
      popupContainerClass,
      overlayShow,
      overlayTarget,
      overlayTip,
      overlayStyleData,
      viewBronzeBanner,
      viewVerifyEmailBanner,
      viewVerifyEmailButton,
      viewPremiumBanner,
      viewPremiumButton,
      mobileAppInstallBanner,
      randomBannerState
    } = this.state;

    // console.log(this.props.iframeasset)
    // let inTime = systemTime.TimeToNextState ? moment.utc(systemTime.TimeToNextState*60*1000).format('HH:mm') : '0:0'
    // let inTimeBits = inTime.split(':');
    // console.log(inTimeBits)
    // let SysTimeCountDownShown = false;
    // if((parseInt(inTimeBits[0]) <= 23 && parseInt(inTimeBits[0]) > 0) || parseInt(inTimeBits[1]) > 0) {
    //   SysTimeCountDownShown = true;
    // }


    let renderedView;

    let page = this.props.page;
    let injectedProps = {};
    const { children } = this.props;
    React.Children.forEach(children, (element) => {
      if (!React.isValidElement(element)) return;
      // page = this.props.page;
      switch (page) {
        case 'stories':
          injectedProps = {
            homeviewport: this.state.viewport,
            prevPath: prevPath,
            MouseMoveOverStory: this.MouseMoveOverStory,
            captureTitle: this.searchTitleSave,
            openStoryOptionsMenu: this.storyOptionsMenu,
            openStoryShareMenu: this.storyShareMenu,
            toggleAlert: this.toggleAlert,
            togglePopup: this.togglePopup,
            pauseStoryUpdateTimer: this.pauseStoryUpdateTimer,
            getSavedSearch: this.getSavedSearch,
            checkWatchList: this.checkWatchList,
            showIntroVideo: this.showIntroVideo,
            EventTypeList: this.EventTypeList,
            showModal: this.showModal,
            modalClose: this.modalClose,
            toggleOverlay: this.toggleOverlay,
            toggleConfirmPopup: this.toggleConfirmPopup,
            togglePro: this.props.togglePro,
            welcomemodeon: this.props.welcomemodeon,
            getStoriesByPackage: this.getStoriesByPackage,
            initGetStories: this.props.initGetStories,
            userData: this.props.userData,
            resize: this.resize,
            FeedAdSlotIds: this.props.InFeedAdSlotIds,
            location: this.props.location,
            redirect: this.redirect,
            logout: this.userLogout
          };
          break;
        default:
          injectedProps = {
            utcOffset: this.state.utcOffset,
            homeviewport: this.state.viewport,
            prevPath: prevPath,
            // pgloader : this.togglePageLoader,
            toggleAlert: this.toggleAlert,
            togglePopup: this.togglePopup,
            toggleOverlay: this.toggleOverlay,
            toggleConfirmPopup: this.toggleConfirmPopup,
            togglePro: this.props.togglePro,
            welcomemodeon: this.props.welcomemodeon,
            userData: this.props.userData,
            resize: this.resize,
            FeedAdSlotIds: this.props.InFeedAdSlotIds,
            location: this.props.location,
            showModal: this.showModal,
            modalClose: this.modalClose,
            redirect: this.redirect,
            logout: this.userLogout
          };
          break;
      }
      if (page === 'termsandconditions' || page === 'privacypolicy' || page === 'faq') {
        renderedView = element;
      } else {
        renderedView = React.cloneElement(element, { ...injectedProps });
      }
    });
    let homebodyStyle = {
      height: this.state.viewport.height !== '' ? `${this.state.viewport.height}px` : 'auto',
      overflow: page === 'stories' && this.state.viewport.height !== '' ? 'hidden' : 'auto',
      position: 'relative',
    };

    let returnToStoriesHandle =
      page !== 'stories' ? (
        page === 'settings' || page === 'userpreferences' ? (
          <div className="returnToStoriesHandle">
            <i className="fa fa-times returnToStoriesHandle" onClick={this.hardRefreshSystem}></i>
          </div>
        ) : (
          <Link to="/" className="returnToStoriesHandle">
            <i className="fa fa-times returnToStoriesHandle" onClick={this.hardRefreshSystem}></i>
          </Link>
        )
      ) : (
        ''
      );
    // console.log(this.props.render)
    return (
      <Row className={`justify-content-center text-center ${this.props.render === 'iframe' ? '' : 'bg-Parent'} ${this.props.render === 'iframe' ? 'iframe' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''}`} id="Wrap" onMouseEnter={this.handleMouseEnter}
        onTouchStart={(e) => {
          if (isMobile) {
            // this.togglePopup(false);
            this.toggleOverlay(false);
          }

        }}
        onClick={(e) => {
          // e.preventDefault();
          // e.stopPropagation();
          this.props.closeMainMenu(true);
          this.props.closeMainMenu_Iframe(true);
          this.props.closeTMWatchlistMenu(true);
          this.props.closeEventImportanceFilterMenu(true);
        }}
      >
        <NotifModule.Notifications />
        <NotifModule.MessageNotif />
        <Alerts
          AlertShow={AlertShow}
          AlertHideDelay={30000}
          ToggleAlert={this.toggleAlert}
          AlertAnimate={true}
          AlertAutoHide={true}
          AlertTitle={AlertTitle}
          AlertContent={AlertContent}
        />
        {
          (this.props.render !== 'iframe' && this.props.userData.userRole !== 'Blocked') && (
            <div>
              <StoryContextHandle ref={this.storyHandle} userData={this.props.userData} />
              <SearchSaveContextHandle ref={this.searchSaveHandle} userData={this.props.userData} />
              {
                this.props.showtoptrending && (
                  <TMContextHandleWrap showModal={this.showModal} urlParams={this.state.urlParams} ref={this.tmHandle} userData={this.props.userData} />
                )
              }

              <TMDetailsContextHandle ref={this.tmDetailsHandle} userData={this.props.userData} />
              <TMDetailsHandleWrap ref={this.tmDetailsHandlesWrap} userData={this.props.userData} />
            </div>
          )
        }
        {
          (this.props.render === 'iframe' && this.props.userData.userRole !== 'Blocked') && (
            <div>
              {
                !this.props.iframeasset && (
                  <StoryContextHandle ref={this.storyHandle} userData={this.props.userData} />
                )
              }
              {
                this.props.iframeasset && (
                  <SearchSaveContextHandle ref={this.searchSaveHandle} userData={this.props.userData} />
                )
              }
            </div>
          )
        }
        {/* {this.props.userData.userRole !== 'Blocked' && (
          <div>

            <StoryContextHandle ref={this.storyHandle} userData={this.props.userData} />
            {
              this.props.render !== 'iframe' && (
                <SearchSaveContextHandle ref={this.searchSaveHandle} userData={this.props.userData} />
              )
            }
            {
              this.props.render !== 'iframe' && (
                <TMContextHandleWrap showModal={this.showModal} urlParams={this.state.urlParams} ref={this.tmHandle} userData={this.props.userData} />
              )
            }
            {
              this.props.render !== 'iframe' && (
                <TMDetailsContextHandle ref={this.tmDetailsHandle} userData={this.props.userData} />
              )
            }
            {
              this.props.render !== 'iframe' && (
                <TMDetailsHandleWrap ref={this.tmDetailsHandlesWrap} userData={this.props.userData} />
              )
            }


          </div>
        )} */}

        <Col lg={12} sm={12} xs={12} md={12} xl={12} className="nopad">
          <div className="home-wrap">
            <div className="nopad marg-0" ref={`headbar`}>
              {
                this.props.render !== 'iframe' && (
                  <HeadBar
                    page={this.state.page}
                    logout={this.userLogout}
                    toggleOverlay={this.toggleOverlay}
                    redirect={this.redirect}
                    userdata={this.props.userData}
                    homeviewport={this.state.viewport}
                    {...this.props}
                    setHomeBodyStyle={this.setHomeBodyStyle}
                    searchSaveFeedRequestData={this.searchSaveFeedRequestData}
                    toggleAlert={this.toggleAlert}
                    showIntroVideo={this.showIntroVideo}
                    showWelcomeScreen={this.WelcomeScreen}
                    checkWatchList={this.checkWatchList}
                    EventTypeList={this.EventTypeList}
                    PreferenceList={this.PreferenceList}
                    showModal={this.showModal}
                    togglePro={this.props.togglePro}
                    toggleConfirmPopup={this.toggleConfirmPopup}
                    viewVerifyEmailButton={viewVerifyEmailButton}
                    viewPremiumButton={viewPremiumButton}
                  />
                )
              }



            </div>
            <div className="nopad marg-0 nodisp" ref={`desktopbanner`}>
              {
                this.props.render !== 'iframe' && (
                  <DesktopAppNotificationBanner page={page} />
                )
              }

            </div>

            <div className="marg-0 padTRL15 smpadright15" ref={`messagebanner`}>
              {viewVerifyEmailBanner && this.props.render !== 'iframe' ? (
                <Row className="marg-0 justify-content-center text-center">
                  <Col className="pad0">

                    <Row className="marg-0 nopad h-100 bronzeBanner">
                      <Col
                        lg={isMobile ? 8 : 12}
                        sm={isMobile ? 8 : 12}
                        xs={isMobile ? 8 : 12}
                        md={isMobile ? 8 : 12}
                        xl={isMobile ? 8 : 12}
                        className=" align-self-center "
                        style={{ padding: '15px 15px 15px 15px', textAlign: isMobile ? 'left' : 'center' }}
                      >

                        {/* <div className="bronzeBanner"> */}
                        <div style={{ color: '#000', fontSize: 14, fontWeight: 500 }} className="dispinb bronzemsg">
                          For a free trial of Premium, please press Verify in the email we recently sent you
                        </div>
                        <div
                          onClick={() => this.showModal('VerifyEmail')}
                          style={{ marginLeft: 8 }}
                          className={`dispinb-notimp bronzeBtnWrap pointer ${isMobile ? 'nodisp-imp' : ''}`}
                        >
                          <span className="bronzeBtn">Verify</span>
                        </div>
                      </Col>
                      <Col lg={4} sm={4} xs={4} md={4} xl={4} className={` align-self-center ${isMobile ? '' : 'nodisp'}`}>
                        <div
                          onClick={() => this.showModal('VerifyEmail')}
                          style={{ marginLeft: '-25px' }}
                          className={` bronzeBtnWrap pointer `}
                        >
                          <span className="bronzeBtn">Verify</span>
                        </div>
                      </Col>
                      <div style={{ position: 'absolute', right: '15px', top: '3px' }} className={``}>
                        <span
                          style={{ marginLeft: 5 }}
                          className={` pointer`}
                          onClick={this.closeEmailVerificationBanner}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 16.458 15.828">
                            <g transform="translate(1.414 1.414)">
                              <path
                                className="a"
                                d="M1560.6,103.5l-13.63,13"
                                transform="translate(-1546.97 -103.5)"
                                fill="none"
                                stroke={'#000'}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2px"
                              />
                              <g transform="translate(-1546.97 -103.5)">
                                <path
                                  className="a"
                                  d="M1546.97,103.5l13.63,13"
                                  fill="none"
                                  stroke={'#000'}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2px"
                                />
                              </g>
                            </g>
                          </svg>
                        </span>
                      </div>
                      {/* </div> */}
                    </Row>
                  </Col>
                </Row>
              ) : ('')}
            </div>

            <div className="marg-0 padTRL15 smpadright15" ref={`premiumbanner`}>
              {viewPremiumBanner && !viewPremiumButton && (randomBannerState === 1) && this.props.render !== 'iframe' ? (
                <GoPremiumBanner
                  toggleConfirmPopup={this.toggleConfirmPopup}
                  popup={true}
                  closePremiumBanner={this.closeBronzeBanner}
                  showModal={this.showModal}
                />
              ) : ('')}
              {/* {this.props.VerifiedEmail && isMobile && viewBronzeBanner && (
                <GoPremiumBanner
                  toggleConfirmPopup={this.toggleConfirmPopup}
                  popup={true}
                  resizeAfterClose={this.resize}
                  closePremiumBanner={this.props.closePremiumBanner}
                />
              )} */}
            </div>
            <div className={`marg-0 padTRL15 smpadright15 `} ref={`mobileAppbanner`} >
              <div className={`${(this.props.userData.usertype !== 'None' || (this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin')) ? 'nodisp' : ''}`}>
                {mobileAppInstallBanner && (randomBannerState === 2) && this.props.render !== 'iframe' ? (
                  <MobileAppBanner
                    // toggleConfirmPopup={this.toggleConfirmPopup}
                    // popup={true}
                    userData={this.props.userData}
                    closeMobileAppInstallBanner={this.closeMobileAppInstallBanner}
                  />
                ) : ('')}
              </div>
            </div>

            <div className="nopad marg-0" ref={`toolbar`}>
              {/* <Toolbar page={this.state.page} {...this.props} pageLoaders={this.state.pageLoaders} /> */}
              {/* <PageLoader /> */}
            </div>
            <div className={`nopad marg-0 ${page !== 'stories' && 'nodisp'}`} ref={`menubar`} style={{ marginBottom: this.props.render !== 'iframe' ? 5 : 0 }} >
              {
                this.props.render !== 'iframe' && (
                  <MenuFilter />
                )
              }

            </div>
            <div className={`nopad marg-0 ${page !== 'stories' && 'nodisp'}`} ref={`searchbar`} style={{ marginBottom: this.props.render !== 'iframe' ? 5 : 0 }} >
              {this.props.userData.userRole !== 'Blocked' && (
                <Row className={`marg-0 justify-content-center text-center ${this.props.MenuFilter === 'Search' ? '' : 'nodisp'}`}>
                  <Col className="padt4 smsearchContainer" style={{ paddingRight: '17px' }}>
                    {
                      this.props.render !== 'iframe' && (
                        <Search
                          {...this.props}
                          searchtext={''}
                          homeviewport={this.state.viewport}
                          toggleAlert={this.toggleAlert}
                          toggleOverlay={this.toggleOverlay}
                          showModal={this.showModal}
                          toggleConfirmPopup={this.toggleConfirmPopup}
                          searchTitleSave={this.searchTitleSave}
                        />
                      )
                    }

                  </Col>
                </Row>
              )}
            </div>

            <div className="home-body nopad marg-0  " style={homebodyStyle}>
              {returnToStoriesHandle}
              {isMobile ? (
                <Swipeable homeviewport={this.state.viewport} userData={this.props.userData} page={this.props.page} render={this.props.render}>
                  {renderedView}
                </Swipeable>
              ) : (
                <Scrollable homeviewport={this.state.viewport} userData={this.props.userData} page={this.props.page} render={this.props.render}>
                  {renderedView}
                </Scrollable>
              )}
            </div>
          </div>
        </Col>
        <Col lg={12} sm={12} xs={12} md={12} xl={12} className="nopad">
          <Row className="marg-0 ">
            {/* <Col lg={12} sm={12} xs={12} md={12} xl={12} className="nopad">
                <InFeedAd SlotId={`9422094602`} LayoutKey={'-gw-3+1f-3d+2z'} />
            </Col> */}
            {/* <Col lg={3} sm={3} xs={3} md={3} xl={3} className="nopad">
                <InFeedAd SlotId={`8434647694`} />
            </Col>
            <Col lg={3} sm={3} xs={3} md={3} xl={3} className="nopad">
                <InFeedAd SlotId={`4303830999`} />
            </Col>
            <Col lg={3} sm={3} xs={3} md={3} xl={3} className="nopad">
                <InFeedAd SlotId={`3615481572`} />
            </Col>*/}
          </Row>
        </Col>

        <Modals
          show={modalShow}
          onHide={this.modalClose}
          view={modalContent}
          data={modalData}
          userdata={this.props.userData}
          minify={this.state.viewport.width <= 600}
          WelcomeScreen={this.WelcomeScreen}
          changeModalContent={this.changeModalContent}
          redirect={this.redirect}
          hardRefreshSystem={this.hardRefreshSystem}
          MouseMoveOverStory={this.MouseMoveOverStory}
          toggleOverlay={this.toggleOverlay}
          toggleConfirmPopup={this.toggleConfirmPopup}
          closeEmailVerificationBanner={this.closeEmailVerificationBanner}
          togglePopup={this.togglePopup}
          resize={this.resize}
          location={this.props.location}
          welcomemodeon={this.props.welcomemodeon}
          maintenancemode={this.props.maintenancemode}
          toggleAlert={this.toggleAlert}
        />
        <Popups show={popupShow} content={popupContent} position={popupPos} ContainerClass={popupContainerClass} />
        <OverlayWrap show={overlayShow} target={overlayTarget} tooltiptext={overlayTip} styleData={overlayStyleData} />
        {this.state.showconfirmpopup && (
          // <UpgrageToPremiumPopUp
          //   onConfirm={() => this.toggleConfirmPopup(false)}
          // />
          <ConfirmationPopUp
            showOnlyOk={this.state.showconfirmonlyok}
            alertMessage={() => this.state.showconfirmmessage}
            onConfirm={() => this.toggleConfirmPopup(false)}
            subscirptionPopup={true}
          />
        )}
      </Row>
    );
  }
}
const mapStateToApp = (state) => {
  return {
    // userData : state.userData,
    VerifiedEmail: state.settings.VerifiedEmail,
    // isMobileAppInstalled: state.settings.isMobileAppInstalled,
    systemTime: state.settings.systemTime,
    // AdSlotIds: state.settings.AdSlotIds,
    InFeedAdSlotIds: state.settings.InFeedAdSlotIds,
    render: state.settings.render,
    lang: state.settings.lang,
    iframelogo: state.settings.iframelogo,
    iframeasset: state.settings.iframeasset,
    SecuritiesChecklist: state.settings.checkListStatus.SecuritiesCheckList,
    EventTypesCheckList: state.settings.checkListStatus.EventTypesCheckList,
    maintenancemodeon: state.settings.maintenancemodeon,
    MenuFilter: state.app_preferences.preferences.user_interface.MenuFilter,
    Theme: state.app_preferences.preferences.user_interface.Theme,
    showtoptrending: state.settings.showtoptrending
    // rootFeeds: state.rootFeeds,
    // StoriesIndex: state.StoriesIndex,
    // req_error: state.req_error
  };
};

const mapDispatchToProps = {
  ...filtersActionCreator,
  ...settingsActionCreators,
  ...preferencesActionCreator,
  getTMFrame,
  TMViewDetails,
  getCoinData
};

export default connect(mapStateToApp, mapDispatchToProps)(Home);
