import React, { Component } from 'react'; 
import { Row, Col } from 'react-bootstrap';
import  { connect } from 'react-redux';

class PageLoader extends Component {

    constructor() {
        super();
        this.state = {
            pageLoader: false
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        // console.log(nextProps.RequestState)
        return { pageLoader : nextProps.RequestState }
    }

    togglePageLoader = (State = false) => {
        this.setState({
            ...this.state,
            pageLoader: State
        })
    }
  
    render () {
        // console.log(this.state.pageLoader)
        return(
            <Row className="bg-toolbar marg-0 toolbar-wrap">
                <Col lg={12} sm={12} xs={12} md={12} xl={12} className="nopad text-left" >
                    <div className={`line-preloader-wrap ${this.state.pageLoader ? '' : 'nodisp'}`}>
                        <span className="line-preloader"></span>
                    </div>
                </Col>
            </Row>
        )
    }
  
}

const mapStateToApp = (state) => {
    return {
        RequestState : state.feeds.feedReqData.RequestState
    }
}


export default connect(mapStateToApp, null)(PageLoader)