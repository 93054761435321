import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { ResetTMSort } from '../../actions/trendingmentions.action';
import { closeTMWatchlistMenu } from '../../actions/settings.action';
import { changeTopTrendingCoinLimit } from '../../actions/trendingmentions.action';
// import * as moment from 'moment';
import { connect } from 'react-redux';
import { APPPATH } from '../../modules/helper.module';
import { isMobile } from 'react-device-detect';

class TMSubtitles extends Component {
    constructor(props) {
        super();
        this.state = {
            activeTab: props.activeTab,
            tmSortedColumn: props.tmSortedColumn,
            tmSortingType: props.tmSortingType,
            subtitle: this.makeSubtitle(props.activeTab, props.tmSortedColumn, props.tmSortingType),
            collapseHover: false,
            maxCoinListState: props.maxCoinListState,
            maxCoinListOutOfState: props.maxCoinListOutOfState,
            FromWatchlist: props.FromWatchlist,
            maxcoinmenustate: false
        };


    }

    componentWillReceiveProps(nextProps, prevState) {
        this.setState({
            ...this.state,
            activeTab: nextProps.activeTab,
            tmSortedColumn: nextProps.tmSortedColumn,
            tmSortingType: nextProps.tmSortingType,
            subtitle: this.makeSubtitle(nextProps.activeTab, nextProps.tmSortedColumn, nextProps.tmSortingType),
            maxCoinListState: nextProps.maxCoinListState,
            maxCoinListOutOfState: nextProps.maxCoinListOutOfState,
            FromWatchlist: nextProps.FromWatchlist,
        });
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.closetmwatchlistmenu === true) {
            if (this.state.maxcoinmenustate === true) {

                this.togglemaxCoinState()
            }

        }
    }

    makeSubtitle = (activeTab, tmSortedColumn, tmSortingType) => {
        // const { activeTab, tmSortedColumn, tmSortingType } = this.state;
        let subtitle;
        switch (activeTab) {
            case 'TopSentiment':
                if (tmSortedColumn === 'Sentiment Change') {
                    if (parseInt(tmSortingType) === 1) {
                        subtitle = 'Top By Sentiment Spike';
                    } else if (parseInt(tmSortingType) === -1) {
                        subtitle = 'Top By Sentiment Drop';
                    }
                } else if (tmSortedColumn === 'Sentiment') {
                    if (parseInt(tmSortingType) === 1) {
                        subtitle = 'Top By Positive Sentiment';
                    } else if (parseInt(tmSortingType) === -1) {
                        subtitle = 'Top By Negative Sentiment';
                    }
                }
                break;
            case 'TopMentions':
                if (tmSortedColumn === 'Mentions Change') {
                    if (parseInt(tmSortingType) === 1) {
                        subtitle = 'Top By Mentions Spike';
                    } else if (parseInt(tmSortingType) === -1) {
                        subtitle = 'Top By Mentions Drop';
                    }
                } else if (tmSortedColumn === 'Mentions Score') {
                    if (parseInt(tmSortingType) === 1) {
                        subtitle = 'Top By Most Mentions';
                    } else if (parseInt(tmSortingType) === -1) {
                        subtitle = 'Top By Least Mentions';
                    }
                }
                break;
            case 'TopReach':
                if (tmSortedColumn === 'Reach Score') {
                    if (parseInt(tmSortingType) === 1) {
                        subtitle = 'Top By Most Followers Reach';
                    } else if (parseInt(tmSortingType) === -1) {
                        subtitle = 'Top By Least Followers Reach';
                    }
                } else if (tmSortedColumn === 'Reach Change') {
                    if (parseInt(tmSortingType) === 1) {
                        subtitle = 'Top By Followers Reach Spike';
                    } else if (parseInt(tmSortingType) === -1) {
                        subtitle = 'Top By Followers Reach Drop';
                    }
                }
                break;
            case 'TopPrice':
                if (tmSortedColumn === 'Price') {
                    if (parseInt(tmSortingType) === 1) {
                        subtitle = 'Top By Highest Price';
                    } else if (parseInt(tmSortingType) === -1) {
                        subtitle = 'Top By Lowest Price';
                    }
                } else if (tmSortedColumn === 'Price Change') {
                    if (parseInt(tmSortingType) === 1) {
                        subtitle = 'Top By Price Spike';
                    } else if (parseInt(tmSortingType) === -1) {
                        subtitle = 'Top By Price Drop';
                    }
                }
                break;
            case 'TopVolume':
                if (tmSortedColumn === 'Volume') {
                    if (parseInt(tmSortingType) === 1) {
                        subtitle = 'Top By Highest Trade Volume';
                    } else if (parseInt(tmSortingType) === -1) {
                        subtitle = 'Top By Lowest Trade Volume';
                    }
                }
                break;
            default:
                subtitle = '';
                break;
        }
        return subtitle;
    }


    toggleCollapseHover = () => {
        this.setState({
            ...this.state,
            collapseHover: !this.state.collapseHover
        })
    }

    togglemaxCoinState = (forceshow = false) => {

        this.setState(
            {
                ...this.state,
                maxcoinmenustate: forceshow === true ? true : !this.state.maxcoinmenustate,
            },
            () => {
                if (this.props.closetmwatchlistmenu === true) {
                    this.props.closeTMWatchlistMenu()

                }
            }
        );
    };

    changeCoinListCount = (count, max, watchlist = false) => {

        this.setState(
            {
                ...this.state,
                maxCoinListState: count,
                maxCoinListOutOfState: max,
                FromWatchlist: watchlist,
                maxcoinmenustate: false,
                maxcoinmenu: '',
            },
            () => {
                let { cc_Preferences, cc_index } = this.props;
                cc_Preferences.tmListMaxCoinCount = count;
                cc_Preferences.tmtotalmaxcoincount = max;
                cc_Preferences.tmFromWatchlist = watchlist;
                this.props.updateCoinColumnPreferences(cc_Preferences, cc_index, this.props.listkey)
                this.props.changeTopTrendingCoinLimit(count, max, watchlist, cc_Preferences, cc_index);

            }
        );
    };

    render() {
        const { subtitle, collapseHover, maxcoinmenustate } = this.state;
        let collapseStatus = !maxcoinmenustate ? false : true;
        const containerStyleClass = `crl-tooltipMenu coincount normal-left ${this.props.Theme === 'light' ? 'lightmode' : ''}`;
        let pos = {
            right: 0,
            // left: ContainerRect.left -30,
            // top: ContainerRect.bottom,
            top: 40,
        };
        let CoinMenuText = this.props.maxCoinListState === 10 && this.props.maxCoinListOutOfState === 50 ? 'Largest Coins' :
            this.props.maxCoinListState === 10 && this.props.maxCoinListOutOfState === 1000 && !this.props.FromWatchlist ? 'All Coins' :
                this.props.maxCoinListState === 10 && this.props.maxCoinListOutOfState === 1000 && this.props.FromWatchlist ? 'My Watchlist' : '';
        // console.log(this.props.usertype)
        return (
            <Row className={`marg-0  tmTabWrap bg-tmSubtitles pad-tmSubtitles ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>
                <Col lg={7} sm={7} xs={7} md={7} xl={7} className={`nopad tmSubtitles ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>{subtitle}</Col>
                {
                    (this.props.usertype === 'None' || this.props.usertype === 'WUser' || this.props.usertype === 'WAdmin') && (
                        <Col lg={5} sm={5} xs={5} md={5} xl={5} className={`text-right ${this.props.Theme === 'light' ? 'lightmode' : ''}`} style={{ padding: '0px 10px 5px 0px' }}>
                            <span className={`usercoincount pointer ${this.props.Theme === 'light' ? 'lightmode' : ''}`}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    this.togglemaxCoinState()
                                }}
                            >
                                <i className={`fa fa-sliders pad2r5px fs14 `}></i>
                                {CoinMenuText}
                                <span
                                    onMouseEnter={this.toggleCollapseHover}
                                    onMouseLeave={this.toggleCollapseHover}
                                >
                                    <img
                                        style={{ width: '12px', marginTop: '-5px', marginLeft: '5px' }}
                                        src={`${collapseHover
                                            ? `${APPPATH.ImageUrl}/storyexpandiconblue.svg`
                                            : `${APPPATH.ImageUrl}/storyexpandicon.svg`
                                            }`}
                                        className={`storyExpandIcon ${!collapseStatus ? '' : 'transform180anticlock'}`}
                                        alt=""
                                    />
                                </span>
                            </span>
                            {/* {this.props.maxcoinmenu} */}
                            <div className={`marg-0 ${containerStyleClass} ${!maxcoinmenustate ? 'nodisp' : ''}`} style={{ ...pos, zIndex: 1203, border: '1px solid', borderColor: this.props.Theme === 'light' ? '#afafaf' : '#606060' }}>
                                <div>
                                    <Row className={`marg-0 nopad`}>
                                        <Col xs={12} sm={12} md={12} xl={12} lg={12} className={`tiphead-coinmenu brdr-bot-gray `}>
                                            {/* <img className="tradeWindowClose-closeicon" style={{width: '15px'}} src={`${APPPATH.ImageUrl}/button-close.svg`} alt="" /> */}
                                            <Row className={`marg-0 nopad`}>
                                                <Col className='bold' style={{ fontSize: '13px', textAlign: 'left', padding: '0px', color: this.props.Theme === 'light' ? "#252525" : "#ffffff" }}>
                                                    <span>
                                                        {
                                                            (this.props.usertype !== 'None' && (this.props.usertype !== 'WUser' && this.props.usertype !== 'WAdmin')) ? 'Select from which Companies' : 'Select Assets List'
                                                        }

                                                    </span>
                                                </Col>
                                                <Col xs={1} sm={1} md={1} xl={1} lg={1} className="" style={{ marginTop: '-4px', cursor: 'pointer' }} >
                                                    <i className="fa fa-times ttmenuclose" onClick={(e) => { e.stopPropagation(); this.togglemaxCoinState() }}></i>
                                                </Col>
                                            </Row>

                                        </Col>
                                    </Row >
                                    <Row className={`marg-0`} style={{ backgroundColor: this.props.Theme === 'light' ? '#ffffff' : '#0f1725', padding: "0px 10px 0px 10px" }}>
                                        <Col xs={12} sm={12} md={12} xl={12} lg={12} className={`tipItem brdr-bot-gray ${this.props.maxCoinListState === 10 && this.props.maxCoinListOutOfState === 1000 && this.props.FromWatchlist ? 'selected' : ''}`} onClick={(e) => { e.stopPropagation(); this.changeCoinListCount(10, 1000, true) }}>
                                            <div className="fs12 bold">
                                                From My Watchlist
                                                {/* <span>
                                            <img src={`${APPPATH.ImageUrl}/inner-badge-premium.svg`} alt="CrowdSense.ai"></img>
                                        </span> */}
                                            </div>
                                            <div className="fs11">
                                                {
                                                    (this.props.usertype !== 'None' && (this.props.usertype !== 'WUser' && this.props.usertype !== 'WAdmin')) ? 'From the companies in my watchlist with 50 or more daily social media mentions' : 'From my watchlist assets with 50 or more daily social media mentions'
                                                }

                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} xl={12} lg={12} className={`tipItem brdr-bot-gray ${this.props.maxCoinListState === 10 && this.props.maxCoinListOutOfState === 50 ? 'selected' : ''}`} onClick={(e) => { e.stopPropagation(); this.changeCoinListCount(10, 50) }}>
                                            <div className="fs12 bold">
                                                {
                                                    (this.props.usertype !== 'None' && (this.props.usertype !== 'WUser' && this.props.usertype !== 'WAdmin')) ? 'From Largest Companies' : 'From Largest Category Assets'
                                                }
                                            </div>
                                            <div className="fs11">
                                                {
                                                    (this.props.usertype !== 'None' && (this.props.usertype !== 'WUser' && this.props.usertype !== 'WAdmin')) ? 'Out of the largest 50 or more copanies by market cap' : 'From the largest assets in this category'
                                                }

                                            </div>
                                        </Col>

                                        <Col xs={12} sm={12} md={12} xl={12} lg={12} className={`tipItem ${this.props.maxCoinListState === 10 && this.props.maxCoinListOutOfState === 1000 && !this.props.FromWatchlist ? 'selected' : ''}`} onClick={(e) => { e.stopPropagation(); this.changeCoinListCount(10, 1000) }}>
                                            <div className="fs12 bold">
                                                {
                                                    (this.props.usertype !== 'None' && (this.props.usertype !== 'WUser' && this.props.usertype !== 'WAdmin')) ? 'From All Companies' : 'From All Category Assets'
                                                }

                                                {/* <span>
                                            <img src={`${APPPATH.ImageUrl}/inner-badge-premium.svg`} alt="CrowdSense.ai"></img>
                                        </span> */}
                                            </div>
                                            <div className="fs11">
                                                {
                                                    (this.props.usertype !== 'None' && (this.props.usertype !== 'WUser' && this.props.usertype !== 'WAdmin')) ? 'Out of all companies with $50M+ by market cap and 50 or more daily social media mentions' : 'Out of all assets in this category'
                                                }

                                            </div>
                                        </Col>


                                    </Row>
                                </div>
                            </div>
                        </Col>
                    )
                }
            </Row>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    return {
        activeTab: state.trendingMentions.coin_column_preferences[ownProps.cc_index] ? state.trendingMentions.coin_column_preferences[ownProps.cc_index].activeTab : '',
        tmSortedColumn: state.trendingMentions.coin_column_preferences[ownProps.cc_index] ? state.trendingMentions.coin_column_preferences[ownProps.cc_index].tmSortedColumn : '',
        tmSortingType: state.trendingMentions.coin_column_preferences[ownProps.cc_index] ? parseInt(state.trendingMentions.coin_column_preferences[ownProps.cc_index].tmSortingType) : '',
        Theme: state.app_preferences.preferences.user_interface.Theme,
        maxCoinListState: parseInt(state.trendingMentions.coin_column_preferences[ownProps.cc_index].tmListMaxCoinCount),
        maxCoinListOutOfState: parseInt(state.trendingMentions.coin_column_preferences[ownProps.cc_index].tmtotalmaxcoincount),
        FromWatchlist: state.trendingMentions.coin_column_preferences[ownProps.cc_index].tmFromWatchlist === 'true',
        usertype: state.settings.userData.usertype,
        // userData: state.settings.userData,
        closetmwatchlistmenu: state.settings.closetmwatchlistmenu,
        cc_Preferences: state.trendingMentions.coin_column_preferences[ownProps.cc_index],
    };
};

const mapDispatchToProps = {
    closeTMWatchlistMenu,
    changeTopTrendingCoinLimit
};

export default connect(mapStateToProps, mapDispatchToProps)(TMSubtitles);