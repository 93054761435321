import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';

// import * as moment from 'moment';
// import { TMViewDetails } from '../../actions/trendingmentions.action';
import { connect } from 'react-redux';
import { APPPATH, TrendingMentionsHelper } from '../../modules/helper.module';
import FavCoin from '../stories/favCoin';
//import LinesEllipsis from 'react-lines-ellipsis';

class SingleTM extends Component {
  constructor(props) {
    super();

    this.state = {
      TM: props.TM,
      isClamped: false,
      selected: false,
    };
    this.secName = '';
    this.longCoinNameArray = ['Bloomzed Loyalty Club Ticket', 'Decentraland', 'Kyber Network Crystal v2'];
    this.sentimentTextColor = {
      'Very Negative' : '#EA3434', 
      'Negative' : '#D36B0D', 
      'Neutral': '#FBB03B', 
      'Positive': '#81c534', 
      'Very Positive': '#39B54A'
    };
    this.OverlayrefEmotion = React.createRef();
    this.OverlayrefPrice = React.createRef();
    this.OverlayrefSentiment = React.createRef();
    this.OverlayrefVolume = React.createRef()
    this.OverlayrefReach = React.createRef()
    this.OverlayrefMentions = React.createRef()
    this.OverlayrefSentimentChange = React.createRef();
    this.OverlayrefReachChange = React.createRef()
    this.OverlayrefPriceChange = React.createRef()
    this.OverlayrefMentionsChange = React.createRef()
  }

  componentWillReceiveProps(nextProps, prevState) {
    // console.log(this.state.TM)
    if (JSON.stringify(this.props.TM) !== JSON.stringify(nextProps.TM)) {
      this.setState({
        ...this.state,
        TM: nextProps.TM,
      });
    }
  }

  viewDetails = () => {
    let { TM } = this.state;
    // console.log(this.props.listkey)
    // return;
    let option = {
      SecurityTicker: TM.SecurityTicker,
      SecurityName: TM.SecurityName,
    };
    // if (TrendingMentionsHelper.FreeCoinFilter(option, 50)) {
      if (this.props.tdecktype !== 'tmd') {
        window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
        window.Mixpanel.MixpanelProps.CoinName = TM.SecurityName;
        window.Mixpanel.actions.identify(this.props.userData.username);
        window.Mixpanel.actions.track('Clicked to view coin ', window.Mixpanel.MixpanelProps).then((data) => {
          window.Mixpanel.actions.people.set({
            UserName: this.props.userData.username,
          });
          window.Mixpanel.actions.resetProp('CoinName');
        });
        // this.props.TMViewDetails(TM);
        this.props.pinCoinColumn(option, this.props.listkey);
        // this.props.storyTabSelect('Trending Mentions Details');
        setTimeout(() => {
          if(this.props.viewState === 'expandeddesktop' || this.props.viewState === 'expandedmobile') {
            this.props.expandTopCoins(this.props.cc_index)
          }
        }, 1000);
        
        
      }
    // } else {
    //   this.props.toggleConfirmPopup(
    //     true,
    //     true,
    //     'Enable to see details for all coins'
    //   );
    // }
  };

  // formatCoinName = (text) => {
  //   // const ResponsiveEllipsis = responsiveHOC()(HTMLEllipsis)
  //   let ellipse = '...';
  //   let formattedStat = false;
  //   this.longCoinNameArray.forEach((itm) => {
  //     if(itm.toLowerCase().includes(text.toLowerCase())) {
  //       text = this.formatCoinNameWithLessData(text);
  //       ellipse = '...';
  //     }
  //   })

  //   return (
  //     <LinesEllipsis
  //       ref={(node) => (this.secName = node)}
  //       text={text}
  //       maxLine={1}
  //       ellipsis={ellipse}
  //       trimRight
  //       basedOn="letters"
  //     />
  //   );
  // };

  formatCoinNameWithLessData = (text) => {
    return text.length > 18 ? text.substring(0, 15) + '...' : text;
  };

  componentDidMount() {
    this.setState({
      ...this.state,
      isClamped: this.secName.clamped,
    });
  }

  render() {
    const { TM, isClamped, selected } = this.state;
    // console.log(TM)
    const { activeTab, userData, index, cc_Preferences } = this.props;
    // console.log(this.props.minifyIcon)
    // console.log(this.props.packageName)
    let Sentiment = '';
    let SentimentChange = '';
    let SentimentEmotionTip = '';
    let SentimentEmotionIcon = '';
    let SentimentEmotionTipText = '';
    // console.log(Sentiment)
        
          
    if (this.props.tdecktype === 'tmd') {
      // console.log(this.props.TM);
    }
    if (TM) {
      // this.props.modalClose();
      Sentiment = TM.Positive
      if((Sentiment / 10) < 2) {
        SentimentEmotionTip = 'Very Negative';
        SentimentEmotionTipText = 'Very negative daily sentiment score for the coin';
        SentimentEmotionIcon = (<img className="tickDet-icon" src={`${APPPATH.ImageUrl}/worstemotion.svg`} alt="" />);
      } else if((Sentiment / 10) >= 2 && (Sentiment / 10) < 4) {
        SentimentEmotionTip = 'Negative';
        SentimentEmotionTipText = 'Negative daily sentiment score for the coin';
        SentimentEmotionIcon = (<img className="tickDet-icon" src={`${APPPATH.ImageUrl}/bademotion.svg`} alt="" />);
      } else if((Sentiment / 10) >= 4 && (Sentiment / 10) < 6) {
        SentimentEmotionTip = 'Neutral';
        SentimentEmotionTipText = 'Neutral daily sentiment score for the coin';
        SentimentEmotionIcon = (<img className="tickDet-icon" src={`${APPPATH.ImageUrl}/goodemotion.svg`} alt="" />);
      } else if((Sentiment / 10) >= 6 && (Sentiment / 10) < 8) {
        SentimentEmotionTip = 'Positive';
        SentimentEmotionTipText = 'Positive daily sentiment score for the coin';
        SentimentEmotionIcon = (<img className="tickDet-icon" src={`${APPPATH.ImageUrl}/betteremotion.svg`} alt="" />);
      } else if((Sentiment / 10) >= 8 && (Sentiment / 10) <= 10) {
        SentimentEmotionTip = 'Very Positive';
        SentimentEmotionTipText = 'Very positive daily sentiment score for the coin';
        SentimentEmotionIcon = (<img className="tickDet-icon" src={`${APPPATH.ImageUrl}/bestemotion.svg`} alt="" />);
      }

      let typechangeColor = 'text-offwhite-ni';
      // let typecolor = 'text-offwhite-ni'
      if (activeTab === 'TopMentions') {
        if (!isNaN(parseFloat(TM.MentionsChange))) {
          if (parseFloat(TM.MentionsChange) < 0) {
            typechangeColor = 'text-red';
          } else if (parseFloat(TM.MentionsChange) > 0) {
            typechangeColor = 'text-green';
          }
        }
      } else if (activeTab === 'TopReach') {
        if (!isNaN(parseFloat(TM.ReachChange))) {
          if (parseFloat(TM.ReachChange) < 0) {
            typechangeColor = 'text-red';
          } else if (parseFloat(TM.ReachChange) > 0) {
            typechangeColor = 'text-green';
          }
        }
      } else if (activeTab === 'TopSentiment') {

        if (this.props.tdecktype === 'tmd') {
          if (!isNaN(parseFloat(TM.PositiveChange))) {
            if (parseFloat(TM.PositiveChange) < 0) {
              typechangeColor = 'text-red';
            } else if (parseFloat(TM.PositiveChange) > 0) {
              typechangeColor = 'text-green';
            }
          }
          Sentiment = TM.Positive;
          SentimentChange = TM.PositiveChange;
          
        } else {
          if (this.props.packageName === 'mentionspositive' || this.props.packageName === 'mentionschangepositive') {
            if (!isNaN(parseFloat(TM.PositiveChange))) {
              if (parseFloat(TM.PositiveChange) < 0) {
                typechangeColor = 'text-red';
              } else if (parseFloat(TM.PositiveChange) > 0) {
                typechangeColor = 'text-green';
              }
            }
            Sentiment = TM.Positive;
            SentimentChange = TM.PositiveChange;
          } else if (this.props.packageName === 'mentionsnegative' || this.props.packageName === 'mentionschangenegative') {
            Sentiment = TM.Positive;
            SentimentChange = TM.PositiveChange;
            if (!isNaN(parseFloat(SentimentChange))) {
              if (parseFloat(SentimentChange) < 0) {
                typechangeColor = 'text-red';
              } else if (parseFloat(SentimentChange) > 0) {
                typechangeColor = 'text-green';
              } else {
                typechangeColor = 'text-red';
              }
            }
          }
          
          
        }

        // console.log(Sentiment, SentimentChange)
      } else if (activeTab === 'TopPrice') {
        if (!isNaN(parseFloat(TM.Change))) {
          if (parseFloat(TM.Change) < 0) {
            typechangeColor = 'text-red';
          } else if (parseFloat(TM.Change) > 0) {
            typechangeColor = 'text-green';
          } else {
            typechangeColor = 'text-red';
          }
        }
      }
      let TickerIcon = `${APPPATH.CoinIconUrl}/${
        TM && TM.SecurityName && TM.SecurityName.toLowerCase().replace(/ /g, '_')
      }.png`;

      let coinName =
        TM.SecurityName !== null ? TM.SecurityName : TM.SecurityTicker !== null ? TM.SecurityTicker.toUpperCase() : '';

      let CoinTicker =
        TM.SecurityTicker !== null && TM.SecurityName !== null ? ` ${TM.SecurityTicker.toUpperCase()}` : ' ';

      let formattedcoinName = this.formatCoinNameWithLessData(`${coinName}`);

      // console.log(document.documentElement.clientWidth)
      
      if(this.props.viewState === 'expandeddesktop' || this.props.viewState === 'expandedmobile') {
        return (
          <tr onClick={this.viewDetails} >
            <td style={{width: '15%'}} className={`bordercellright ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>
              <Row className={`marg-0 nopad`}>
                {this.props.count > 0 && 
                  <div className={`${this.props.minifyIcon ? 'STM-Col3-Adjust' : ''} nopad  Col1 align-self-center text-offwhite-ni `} >
                          
                    {
                      this.props.count === 1 ? (
                        <img src={`${APPPATH.ImageUrl}/trophy-gold.svg`} alt="" style={{ width: 20 }}></img>
                      ) : this.props.count === 2 ? (
                        <img src={`${APPPATH.ImageUrl}/trophy-silver.svg`} alt="" style={{ width: 20 }}></img>
                      ) : this.props.count === 3 ? (
                        <img src={`${APPPATH.ImageUrl}/trophy-bronze.svg`} alt="" style={{ width: 20 }}></img>
                      ) : (
                        this.props.count
                      )
                    }
                    
                  </div>
                }
                <div className=" Col2 align-self-center Col2-adjust " style={{marginRight: '5px'}}>
                  {TickerIcon !== '' && (
                      <span>
                        {/* <object
                          className={`${
                            this.props.minifyIcon ? 'tmRowTickerIcon-minify' : 'tmRowTickerIcon'
                          } imground`}
                          data={TickerIcon}
                          type="image/png"
                        > */}
                          <img
                            src={TickerIcon}//{`${APPPATH.ImageUrl}/logo_hdr.png`}
                            className={`${
                              this.props.userData.usertype === 'None' || this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin' ? 
                                this.props.minifyIcon ? 'tmRowTickerIcon-minify' : 'tmRowTickerIcon'
                              : 'tmRowTickerIcon-minify-company'
                            } imground`}
                            alt="CrowdSense.ai"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = `${this.props.userData.usertype === 'None' ? APPPATH.ImageUrl+'/logo_hdr.png' : this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin' ? APPPATH.ImageUrl+'/companygenerallogo.png' : APPPATH.ImageUrl+'/companygenerallogo.png'}`
                            }}
                          />
                        {/* </object> */}
                      </span>
                  )}
                </div>
                <Col className="  nopad padsmTMTickerCol  text-left ">
                  <div>

                    <span title={coinName} className={`nopad text-white dispinb line-ellipsis tm-cointext`}>
                      {formattedcoinName}
                    </span>
                    {
                      (typeof this.props.userData.loginlessuser === 'undefined' || !this.props.userData.loginlessuser) && (
                        <span className="dispinb" style={{ paddingLeft: 5 }}>
                          <FavCoin
                            toggleOverlay={this.props.toggleOverlay}
                            tickerName={TM.SecurityName}
                            userName={this.props.userData.username}
                            toggleConfirmPopup={this.props.toggleConfirmPopup}
                          />
                        </span>
                      )
                    }
                    

                  </div>
                  {
                    (this.props.userData.usertype === 'None' || this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin') && (
                      <div>
                        {TM.SecurityTicker !== null && TM.SecurityName !== null && (
                          <span className="text-white fs12 fssm10" style={{ marginRight: 5, fontWeight: 'bold' }}>
                            {TM.SecurityTicker.toUpperCase()}
                          </span>
                        )}    
                      </div>
                    )
                  }
                  
                  {
                    ((parseInt(document.documentElement.clientWidth) < 600) || TrendingMentionsHelper.currencyFormat(TM.SecurityPrice, '$').length > 5) &&  (this.props.userData.usertype === 'None' || this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin') ? (
                      <div className={`nopad text-white fs12 bold ${this.props.minifyIcon ? 'fs10' : ''} fssm10`}>
                        {
                          parseFloat(TM.SecurityPrice) < 0.0001
                            ? '< $0.0001'
                            : TrendingMentionsHelper.currencyFormat(TM.SecurityPrice, '$')
                        }
                        {/* { TrendingMentionsHelper.currencyFormat(TM.SecurityPrice, '$')} */}
                        {
                          
                            <span
                              className={`nopad text-offwhite-ni fs12 fontnormal ${
                                this.props.minifyIcon ? 'fs10' : ''
                              } fssm10 ${TM.Change < 0 ? 'text-red' : TM.Change > 0 ? 'text-green' : ''} `}
                            >
                              &nbsp;
                              {`${!isNaN(parseFloat(TM.Change)) ? (parseFloat(TM.Change) > 0 ? '+' : '') : ''}${
                                !isNaN(parseFloat(TM.Change)) ? parseFloat(TM.Change).toFixed(2) : '0.00'
                              }%`}
                            </span>
                          
                        }
                      </div> 
                    ) : (this.props.userData.usertype === 'None' || this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin') ? (
                      <span>
                        {
                          parseFloat(TM.SecurityPrice) < 0.0001
                            ? '< $0.0001'
                            : TrendingMentionsHelper.currencyFormat(TM.SecurityPrice, '$')
                        }
                        {/* { TrendingMentionsHelper.currencyFormat(TM.SecurityPrice, '$')} */}
                        {
                          
                          <span
                            className={`nopad text-offwhite-ni fs12 fontnormal ${
                              this.props.minifyIcon ? 'fs10' : ''
                            } fssm10 ${TM.Change < 0 ? 'text-red' : TM.Change > 0 ? 'text-green' : ''} `}
                          >
                            &nbsp;
                            {`${!isNaN(parseFloat(TM.Change)) ? (parseFloat(TM.Change) > 0 ? '+' : '') : ''}${
                              !isNaN(parseFloat(TM.Change)) ? parseFloat(TM.Change).toFixed(2) : '0.00'
                            }%`}
                          </span>
                          
                        }
                      </span>
                    ) : ('')
                  }
                </Col>
                
              </Row>
              
            </td>
            <td className={`bordercellleft ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>
            {
                this.props.viewState === 'expandeddesktop' ? (
                  <div style={{textAlign: 'center'}}>
                    {/* this.props.viewState === 'expandeddesktop' || this.props.viewState === 'expandedmobile' */}
                    
                        <span className="tm-emotions"
                          ref={this.OverlayrefEmotion}
                          
                        >
                          { SentimentEmotionIcon }
                        </span>
                        <span
                          ref={this.OverlayrefSentiment}
                          
                          style={{paddingLeft: '5px'}}
                        >
                          {Sentiment / 10} / 10
                        </span>
                  </div>
                ) : (
                  <div style={{textAlign: 'center'}}>
                    {/* this.props.viewState === 'expandeddesktop' || this.props.viewState === 'expandedmobile' */}
                    
                        <div className="tm-emotions"
                          ref={this.OverlayrefEmotion}
                          
                        >
                          { SentimentEmotionIcon }
                        </div>
                        <div
                          ref={this.OverlayrefSentiment}
                          
                          style={{paddingLeft: '5px'}}
                        >
                          {Sentiment / 10} / 10
                        </div>
                  </div>
                )
                    
              }
                    
                  
            </td>
            <td className={`bordercellright ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>
              <div
                ref={this.OverlayrefSentimentChange}
                onMouseEnter={(e) => {
                  let ToolTipStyleData = {
                    Arrow: {
                      marginLeft: '40px',
                    },
                    ToolTip: {
                      align: 'center',
                    },
                  };
                  // this.props.toggleOverlay(
                  //   true,
                  //   this.OverlayrefSentimentChange,
                  //   ' Change in coin daily sentiment score vs. the average sentiment in the past 14 days',
                  //   ToolTipStyleData
                  // );
                }}
                className={`${parseFloat(TM.PositiveChange) >= 0 ? 'text-green' : 'text-red'}`}
                onMouseLeave={(e) => this.props.toggleOverlay(false)}
              >{`${parseFloat(TM.PositiveChange) > 0  ? '+' : ''}${!isNaN(parseFloat(TM.PositiveChange)) ? parseFloat(TM.PositiveChange).toFixed(2) : '0.00'}%`}</div>
            </td>
            <td className={`bordercellleft ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>
              <div
                ref={this.OverlayrefMentions}
                onMouseEnter={(e) => {
                  let ToolTipStyleData = {
                    Arrow: {
                      marginLeft: '50px',
                    },
                    ToolTip: {
                      align: 'left',
                    },
                  };
                  // this.props.toggleOverlay(
                  //   true,
                  //   this.OverlayrefMentions,
                  //   'Number of social media feeds mentioning the coin in the past day',
                  //   ToolTipStyleData
                  // );
                }}
                onMouseLeave={(e) => this.props.toggleOverlay(false)}
              >{TrendingMentionsHelper.abbreviateNumber(TM.Mentions)}</div>
            </td>
            <td className={`bordercellright ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>
              <div
                ref={this.OverlayrefMentionsChange}
                onMouseEnter={(e) => {
                  let ToolTipStyleData = {
                    Arrow: {
                      marginLeft: '40px',
                    },
                    ToolTip: {
                      align: 'center',
                    },
                  };
                  // this.props.toggleOverlay(
                  //   true,
                  //   this.OverlayrefMentionsChange,
                  //   'Change in the number of social media feeds mentioning the coin in the past day vs. the average in the past 14 days',
                  //   ToolTipStyleData
                  // );
                }}
                onMouseLeave={(e) => this.props.toggleOverlay(false)}
                className={`${parseFloat(TM.MentionsChange) >= 0 ? 'text-green' : 'text-red'}`}
              >{`${parseFloat(TM.MentionsChange) > 0 ? '+' : ''}${!isNaN(parseFloat(TM.MentionsChange)) ? parseFloat(TM.MentionsChange).toFixed(2) : '0.00'}`}%</div>
            </td>
            {
              this.props.showtopreach && (
                <td className={`bordercellleft ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>
                  <div
                    ref={this.OverlayrefReach}
                    onMouseEnter={(e) => {
                      let ToolTipStyleData = {
                        Arrow: {
                          marginLeft: '50px',
                        },
                        ToolTip: {
                          align: 'left',
                        },
                      };
                      // this.props.toggleOverlay(
                      //   true,
                      //   this.OverlayrefReach,
                      //   'Number of followers that were reached by social media feeds mentioning the coin in the past day',
                      //   ToolTipStyleData
                      // );
                    }}
                    onMouseLeave={(e) => this.props.toggleOverlay(false)}
                  >{TrendingMentionsHelper.abbreviateNumber(TM.Reach)}</div>
                </td>
              )
            }
            {
              this.props.showtopreach && (
                <td className={`${(this.props.userData.usertype === 'None' || this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin') && 'bordercellright'} ${this.props.Theme === 'light' ? 'lightmode' : ''} `}>
                  <div
                    ref={this.OverlayrefReachChange}
                    onMouseEnter={(e) => {
                      let ToolTipStyleData = {
                        Arrow: {
                          marginLeft: '40px',
                        },
                        ToolTip: {
                          align: 'center',
                        },
                      };
                      // this.props.toggleOverlay(
                      //   true,
                      //   this.OverlayrefReachChange,
                      //   'Change in the number of social media followers for feeds mentioning the coin in the past day vs. the average in the past 14 days',
                      //   ToolTipStyleData
                      // );
                    }}
                    onMouseLeave={(e) => this.props.toggleOverlay(false)}
                    className={`${parseFloat(TM.ReachChange) >= 0 ? 'text-green' : 'text-red'}`}
                  >{`${parseFloat(TM.ReachChange) > 0 ? '+' : ''}${!isNaN(parseFloat(TM.ReachChange)) ? parseFloat(TM.ReachChange).toFixed(2) : '0.00'}`}%</div>
                </td>
              )
            }
            
            {
                (this.props.userData.usertype === 'None' || this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin') && (
                  <td className={`bordercellleft ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>
                    <div
                      ref={this.OverlayrefPrice}
                      onMouseEnter={(e) => {
                        let ToolTipStyleData = {
                          Arrow: {
                            marginLeft: '50px',
                          },
                          ToolTip: {
                            align: 'left',
                          },
                        };
                        // this.props.toggleOverlay(
                        //   true,
                        //   this.OverlayrefPrice,
                        //   'USD Price for this coin',
                        //   ToolTipStyleData
                        // );
                      }}
                      onMouseLeave={(e) => this.props.toggleOverlay(false)}
                    >
                      {
                      parseFloat(TM.SecurityPrice) < 0.0001
                        ? '< $0.0001'
                        : TrendingMentionsHelper.currencyFormat(TM.SecurityPrice, '$')
                      }
                    </div>
                  </td>
                )
            }
            {
                (this.props.userData.usertype === 'None' || this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin') && (
                  <td className={`bordercellright ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>
                    <div
                      ref={this.OverlayrefPriceChange}
                      onMouseEnter={(e) => {
                        let ToolTipStyleData = {
                          Arrow: {
                            marginLeft: '40px',
                          },
                          ToolTip: {
                            align: 'center',
                          },
                        };
                        // this.props.toggleOverlay(
                        //   true,
                        //   this.OverlayrefPriceChange,
                        //   'Change in coin price within the past 24 hours',
                        //   ToolTipStyleData
                        // );
                      }}
                      onMouseLeave={(e) => this.props.toggleOverlay(false)}
                      className={`${parseFloat(TM.Change) >= 0 ? 'text-green' : 'text-red'}`}
                    >{`${parseFloat(TM.Change) > 0 ? '+' : ''}${!isNaN(parseFloat(TM.Change)) ? parseFloat(TM.Change).toFixed(2) : '0.00'}`}%</div>
                  </td>
                )
            }
            {
                (this.props.userData.usertype === 'None' || this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin') && (
                  <td className={`bordercellleft bordercellright ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>
                    <div
                      ref={this.OverlayrefVolume}
                      onMouseEnter={(e) => {
                        let ToolTipStyleData = {
                          Arrow: {
                            marginLeft: '50px',
                          },
                          ToolTip: {
                            align: 'left',
                          },
                        };
                        // this.props.toggleOverlay(
                        //   true,
                        //   this.OverlayrefVolume,
                        //   'Coin trading volume in USD in the past day',
                        //   ToolTipStyleData
                        // );
                      }}
                      onMouseLeave={(e) => this.props.toggleOverlay(false)}
                    >{
                      parseFloat(TM.Volume24hUsd) > 1 && parseFloat(TM.SecurityPrice) > 0
                        ? `$ ${TrendingMentionsHelper.abbreviateNumber(TM.Volume24hUsd)}`
                        : '0'
                    }</div>
                  </td>
                )
            } 
            
          </tr>
        )
      } else {
        return (
          <>
            
            {cc_Preferences.isPro === 'false' && (
              <Col
                lg={12}
                sm={12}
                xs={12}
                md={12}
                xl={12}
                className={`nopad tm-card bg-content ${this.props.Theme === 'light' ? 'lightmode' : ''} pointer`}
                onClick={this.viewDetails}
              >
                <Row className={`marg-0 nopad h-100`}>
                  <Col
                    className={`nopad  tmCol align-self-center text-offwhite-ni fs14 ${
                      this.props.minifyIcon ? 'STM-ttickerCol-Adjust' : ''
                    }`}
                  >
                    <Row className={`marg-0 nopad`}>
                      {this.props.count > 0 && <div className={`${this.props.minifyIcon ? 'STM-Col3-Adjust' : ''} nopad  Col1 align-self-center text-offwhite-ni `} >
                        
                        {
                          this.props.count === 1 ? (
                            <img src={`${APPPATH.ImageUrl}/trophy-gold.svg`} alt="" style={{ width: 20 }}></img>
                          ) : this.props.count === 2 ? (
                            <img src={`${APPPATH.ImageUrl}/trophy-silver.svg`} alt="" style={{ width: 20 }}></img>
                          ) : this.props.count === 3 ? (
                            <img src={`${APPPATH.ImageUrl}/trophy-bronze.svg`} alt="" style={{ width: 20 }}></img>
                          ) : (
                            this.props.count
                          )
                        }
                        
                      </div>}

                      <div className={` Col2 align-self-center  ${index > 2 ? 'non-troophy-icon' : ''} `}>
                        {TickerIcon !== '' && (
                          <>
                            <span>
                              {/* <object
                                className={`${
                                  this.props.minifyIcon ? 'tmRowTickerIcon-minify' : 'tmRowTickerIcon'
                                } imground`}
                                data={TickerIcon}
                                type="image/png"
                              > */}
                                <img 
                                  src={`${TickerIcon}`}
                                  className={`${
                                    (this.props.userData.usertype === 'None' || this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin') ? 
                                      this.props.minifyIcon ? 'tmRowTickerIcon-minify' : 'tmRowTickerIcon'
                                    : 'tmRowTickerIcon-minify-company'
                                  } imground`}
                                  alt="CrowdSense.ai"
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = this.props.userData.usertype === 'None' ? `${APPPATH.ImageUrl}/logo_hdr.png` : this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin' ? `${APPPATH.ImageUrl}/companygenerallogo.png` : `${APPPATH.ImageUrl}/companygenerallogo.png`
                                  }}
                                />
                              {/* </object> */}
                            </span>
                          </>
                        )}
                      </div>
                      <Col className={`nopad padsmTMTickerCol-x text-left margl10 align-self-center ${index > 2 ? 'non-troophy-text' : ''} `}>
                        <Row className={`marg-0 nopad`}>
                          <Col lg={12} sm={12} xs={12} md={12} xl={12} className={`nopad align-self-center padleft5`}>
                            <Row className={`marg-0 nopad`}>
                              <Col lg={12} sm={12} xs={12} md={12} xl={12} className={`nopad fs15 fs-sm-Ticker align-self-center`}>
                                <div className={``}>
                                  <span
                                    className={`nopad text-white dispinb ${activeTab === 'TopSentiment' ? 'line-ellipsis-x' : ''} tm-cointext`}
                                    title={coinName}
                                    style={{ fontWeight: 500, marginTop: 3 }}
                                  >
                                    {formattedcoinName}
                                  </span>
                                  {
                                    (typeof this.props.userData.loginlessuser === 'undefined' || !this.props.userData.loginlessuser) && (
                                      <span className="dispinb" style={{ paddingLeft: 5 }}>
                                        <FavCoin
                                          toggleOverlay={this.props.toggleOverlay}
                                          tickerName={TM.SecurityName}
                                          userName={this.props.userData.username}
                                          toggleConfirmPopup={this.props.toggleConfirmPopup}
                                        />
                                      </span>
                                    )
                                  }
                                  
                                </div>
                                {
                                  (this.props.userData.usertype === 'None' || this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin') && (
                                    <div className={`nopad fs10 text-white`} style={{ marginLeft: 0, fontWeight: 'bold' }}>
                                      {TM.SecurityTicker !== null && TM.SecurityName !== null
                                        ? ` ${TM.SecurityTicker.toUpperCase()}`
                                        : ' '}
                                    </div>
                                  )
                                }
                                
                              </Col>
                            </Row>
                          </Col>
                          
                          
                        </Row>
                      </Col>
                      {
                            activeTab === 'TopSentiment' && (
                              <Col lg={3} sm={3} xs={3} md={3} xl={3} className={`nopad align-self-center `}>
                                <div>
                                  { SentimentEmotionIcon }
                                </div>
                                <div style={{marginTop: '5px', fontSize: '10px', fontWeight: 'bold', color: this.sentimentTextColor[`${SentimentEmotionTip}`]}}>
                                  { SentimentEmotionTip }
                                </div>
                              </Col>
                            )
                          }
                    </Row>
                  </Col>

                  

                  {this.props.tdecktype !== 'tmd' && (
                    <Col
                      lg={1}
                      sm={1}
                      xs={1}
                      md={1}
                      xl={1}
                      className={`${
                        this.props.minifyIcon ? 'STM-Col3-Adjust' : ''
                      } nopad  tmCol align-self-center text-offwhite-ni `}
                    >
                      <img
                        src={`${APPPATH.ImageUrl}/storyexpandicon.svg`}
                        className="storyExpandIcon transform90anticlock"
                        alt=""
                        style={{ width: 15 }}
                      ></img>
                    </Col>
                  )}
                </Row>
              </Col>
            )}

            
            {cc_Preferences.isPro === 'true' && (
              <Col
                lg={12}
                sm={12}
                xs={12}
                md={12}
                xl={12}
                className={`nopad tm-card bg-content ${this.props.Theme === 'light' ? 'lightmode' : ''} pointer`}
                onClick={this.viewDetails}
              >
                <Row className={`marg-0 nopad h-100`}>
                  <Col
                    lg={this.props.minifyIcon ? 7 : 7}
                    sm={this.props.minifyIcon ? 7 : 7}
                    xs={this.props.minifyIcon ? 7 : 7}
                    md={this.props.minifyIcon ? 7 : 7}
                    xl={this.props.minifyIcon ? 7 : 7}
                    className={`nopad  tmCol align-self-center text-offwhite-ni fs14 tmcoincolWidAdjust1 ${
                      this.props.minifyIcon ? 'STM-ttickerCol-Adjust' : ''
                    }`}
                  >
                    <Row className={`marg-0 nopad`}>
                      
                      {this.props.count > 0 && <div className={`${this.props.minifyIcon ? 'STM-Col3-Adjust' : ''} nopad  Col1 align-self-center text-offwhite-ni margl5`} >
                        
                        {
                          this.props.count === 1 ? (
                            <img src={`${APPPATH.ImageUrl}/trophy-gold.svg`} alt="" style={{ width: 20 }}></img>
                          ) : this.props.count === 2 ? (
                            <img src={`${APPPATH.ImageUrl}/trophy-silver.svg`} alt="" style={{ width: 20 }}></img>
                          ) : this.props.count === 3 ? (
                            <img src={`${APPPATH.ImageUrl}/trophy-bronze.svg`} alt="" style={{ width: 20 }}></img>
                          ) : (
                            this.props.count
                          )
                        }
                        
                      </div>}

                      <div className=" Col2 align-self-center Col2-adjust ">
                        {TickerIcon !== '' && (
                          <>
                            <span>
                              {/* <object
                                className={`${
                                  this.props.minifyIcon ? 'tmRowTickerIcon-minify' : 'tmRowTickerIcon'
                                } imground`}
                                data={TickerIcon}
                                type="image/png"
                              > */}
                                <img
                                  src={`${TickerIcon}`}
                                  className={`${
                                    (this.props.userData.usertype === 'None' || this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin') ? 
                                      this.props.minifyIcon ? 'tmRowTickerIcon-minify' : 'tmRowTickerIcon'
                                    : 'tmRowTickerIcon-minify-company'
                                  } imground`}
                                  alt="CrowdSense.ai"
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = this.props.userData.usertype === 'None' ? `${APPPATH.ImageUrl}/logo_hdr.png` : this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin' ? `${APPPATH.ImageUrl}/companygenerallogo.png` : `${APPPATH.ImageUrl}/companygenerallogo.png`
                                  }}
                                />
                              {/* </object> */}
                            </span>
                          </>
                        )}
                      </div>
                      <Col className="  nopad padsmTMTickerCol  text-left wid65perc">
                        <Row className={`marg-0 nopad`}>
                          <Col lg={12} sm={12} xs={12} md={12} xl={12} className={`nopad align-self-center padleft5`}>
                            <Row className={`marg-0 nopad`}>
                              <Col lg={12} sm={12} xs={12} md={12} xl={12} className={`nopad fs15 fs-sm-Ticker`}>
                                <span
                                  title={coinName}
                                  className={`nopad text-white dispinb line-ellipsis tm-cointext`}
                                  style={{ fontWeight: 500 }}
                                >
                                  {formattedcoinName}
                                </span>
                                {
                                  (typeof this.props.userData.loginlessuser === 'undefined' || !this.props.userData.loginlessuser) && (
                                    <span className="dispinb" style={{ paddingLeft: 5 }}>
                                      <FavCoin
                                        toggleOverlay={this.props.toggleOverlay}
                                        tickerName={TM.SecurityName}
                                        userName={this.props.userData.username}
                                        toggleConfirmPopup={this.props.toggleConfirmPopup}
                                      />
                                    </span>
                                  )
                                }
                                
                              </Col>
                            </Row>
                          </Col>
                          
                            <Col lg={12} sm={12} xs={12} md={12} xl={12} className={`nopad align-self-center padleft5 `}>
                              <Row className={`marg-0 nopad`}>
                                {
                                  (this.props.userData.usertype === 'None' || this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin') && (
                                    <Col
                                      lg={12}
                                      sm={12}
                                      xs={12}
                                      md={12}
                                      xl={12}
                                      className={`nopad text-white fs12 bold ${
                                        this.props.minifyIcon ? 'fs10' : ''
                                      } fssm10`}
                                    >
                                      {TM.SecurityTicker !== null && TM.SecurityName !== null && (
                                        <span className="text-white" style={{ marginRight: 5, fontWeight: 'bold' }}>
                                          {TM.SecurityTicker.toUpperCase()}
                                        </span>
                                      )}
                                      {
                                        (parseInt(document.documentElement.clientWidth) < 600) || TrendingMentionsHelper.currencyFormat(TM.SecurityPrice, '$').length > 5 ? (
                                          <div>
                                            { activeTab !== 'TopPrice' && TrendingMentionsHelper.currencyFormat(TM.SecurityPrice, '$')}
                                            {
                                              activeTab !== 'TopPrice' && (
                                                <span
                                                  className={`nopad text-offwhite-ni fs12 fontnormal ${
                                                    this.props.minifyIcon ? 'fs10' : ''
                                                  } fssm10 ${TM.Change < 0 ? 'text-red' : TM.Change > 0 ? 'text-green' : ''} `}
                                                >
                                                  &nbsp;
                                                  {`${!isNaN(parseFloat(TM.Change)) ? (parseFloat(TM.Change) > 0 ? '+' : '') : ''}${
                                                    !isNaN(parseFloat(TM.Change)) ? parseFloat(TM.Change).toFixed(2) : '0.00'
                                                  }%`}
                                                </span>
                                              )
                                            }
                                          </div>
                                        ) : (
                                          <span>
                                            { activeTab !== 'TopPrice' && TrendingMentionsHelper.currencyFormat(TM.SecurityPrice, '$')}
                                            {
                                              activeTab !== 'TopPrice' && (
                                                <span
                                                  className={`nopad text-offwhite-ni fs12 fontnormal ${
                                                    this.props.minifyIcon ? 'fs10' : ''
                                                  } fssm10 ${TM.Change < 0 ? 'text-red' : TM.Change > 0 ? 'text-green' : ''} `}
                                                >
                                                  &nbsp;
                                                  {`${!isNaN(parseFloat(TM.Change)) ? (parseFloat(TM.Change) > 0 ? '+' : '') : ''}${
                                                    !isNaN(parseFloat(TM.Change)) ? parseFloat(TM.Change).toFixed(2) : '0.00'
                                                  }%`}
                                                </span>
                                              )
                                            }
                                          </span>
                                        )
                                      }
                                      
                                    </Col>
                                  )
                                }
                                
                                
                                
                              </Row>
                            </Col>
                          
                        </Row>
                      </Col>
                    </Row>
                  </Col>

                  <Col
                    lg={this.props.minifyIcon ? 3 : 3}
                    sm={this.props.minifyIcon ? 3 : 3}
                    xs={this.props.minifyIcon ? 3 : 3}
                    md={this.props.minifyIcon ? 3 : 3}
                    xl={this.props.minifyIcon ? 3 : 3}
                    className={`nopad  tmCol tmcolscorechangevalues1 tmcoincolWidAdjust2 fssm15-13 align-self-center ${this.props.minifyIcon ? 'STM-Col1-Adjust' : ''}`}
                  >
                    
                    {activeTab === 'TopMentions'
                      ? <div
                          ref={this.OverlayrefMentions}
                          onMouseEnter={(e) => {
                            let ToolTipStyleData = {
                              Arrow: {
                                marginLeft: '50px',
                              },
                              ToolTip: {
                                align: 'left',
                              },
                            };
                            this.props.toggleOverlay(
                              true,
                              this.OverlayrefMentions,
                              'Number of social media feeds mentioning the coin in the past day',
                              ToolTipStyleData
                            );
                          }}
                          onMouseLeave={(e) => this.props.toggleOverlay(false)}
                        >{TrendingMentionsHelper.abbreviateNumber(TM.Mentions)}</div>
                      : activeTab === 'TopReach'
                      ? <div
                          ref={this.OverlayrefReach}
                          onMouseEnter={(e) => {
                            let ToolTipStyleData = {
                              Arrow: {
                                marginLeft: '50px',
                              },
                              ToolTip: {
                                align: 'left',
                              },
                            };
                            this.props.toggleOverlay(
                              true,
                              this.OverlayrefReach,
                              'Number of followers that were reached by social media feeds mentioning the coin in the past day',
                              ToolTipStyleData
                            );
                          }}
                          onMouseLeave={(e) => this.props.toggleOverlay(false)}
                        >{TrendingMentionsHelper.abbreviateNumber(TM.Reach)}</div>
                      : activeTab === 'TopSentiment'
                      ? <div style={{textAlign: 'center'}}><div className="tm-emotions"
                        ref={this.OverlayrefEmotion}
                        onMouseEnter={(e) => {
                          let ToolTipStyleData = {
                            Arrow: {
                              marginLeft: '50px',
                            },
                            ToolTip: {
                              align: 'left',
                            },
                          };
                          this.props.toggleOverlay(
                            true,
                            this.OverlayrefEmotion,
                            SentimentEmotionTipText,
                            ToolTipStyleData
                          );
                        }}
                        onMouseLeave={(e) => this.props.toggleOverlay(false)}
                      >
                          { SentimentEmotionIcon }
                        </div><div
                          ref={this.OverlayrefSentiment}
                          onMouseEnter={(e) => {
                            let ToolTipStyleData = {
                              Arrow: {
                                marginLeft: '50px',
                              },
                              ToolTip: {
                                align: 'left',
                              },
                            };
                            this.props.toggleOverlay(
                              true,
                              this.OverlayrefSentiment,
                              'Daily sentiment score for the coin from 0/10 (very negative) to 10/10 (very positive)',
                              ToolTipStyleData
                            );
                          }}
                          onMouseLeave={(e) => this.props.toggleOverlay(false)}
                        >{Sentiment / 10} / 10</div></div>
                      : activeTab === 'TopPrice'
                      ? <div
                          ref={this.OverlayrefPrice}
                          onMouseEnter={(e) => {
                            let ToolTipStyleData = {
                              Arrow: {
                                marginLeft: '50px',
                              },
                              ToolTip: {
                                align: 'left',
                              },
                            };
                            this.props.toggleOverlay(
                              true,
                              this.OverlayrefPrice,
                              'USD Price for this coin',
                              ToolTipStyleData
                            );
                          }}
                          onMouseLeave={(e) => this.props.toggleOverlay(false)}
                        >
                          {
                          parseFloat(TM.SecurityPrice) < 0.0001
                            ? '< $0.0001'
                            : TrendingMentionsHelper.currencyFormat(TM.SecurityPrice, '$')
                          }
                        </div>
                      : activeTab === 'TopVolume'
                      ? <div
                          ref={this.OverlayrefVolume}
                          onMouseEnter={(e) => {
                            let ToolTipStyleData = {
                              Arrow: {
                                marginLeft: '50px',
                              },
                              ToolTip: {
                                align: 'left',
                              },
                            };
                            this.props.toggleOverlay(
                              true,
                              this.OverlayrefVolume,
                              'Coin trading volume in USD in the past day',
                              ToolTipStyleData
                            );
                          }}
                          onMouseLeave={(e) => this.props.toggleOverlay(false)}
                        >{
                          parseFloat(TM.Volume24hUsd) > 1 && parseFloat(TM.SecurityPrice) > 0
                            ? `$ ${TrendingMentionsHelper.abbreviateNumber(TM.Volume24hUsd)}`
                            : '0'
                        }</div>
                      : '0'}
                  </Col>
                  <Col
                    lg={this.props.minifyIcon ? 3 : 3}
                    sm={this.props.minifyIcon ? 3 : 3}
                    xs={this.props.minifyIcon ? 3 : 3}
                    md={this.props.minifyIcon ? 3 : 3}
                    xl={this.props.minifyIcon ? 3 : 3}
                    className={`nopad  tmCol tmcolscorechangevalues2 align-self-center ${typechangeColor} ${
                      this.props.minifyIcon ? 'STM-Col2-Adjust' : ''
                    }`}
                  >
                    {activeTab === 'TopMentions'
                    
                      ? <div
                          ref={this.OverlayrefMentionsChange}
                          onMouseEnter={(e) => {
                            let ToolTipStyleData = {
                              Arrow: {
                                marginLeft: '40px',
                              },
                              ToolTip: {
                                align: 'center',
                              },
                            };
                            this.props.toggleOverlay(
                              true,
                              this.OverlayrefMentionsChange,
                              'Change in the number of social media feeds mentioning the coin in the past day vs. the average in the past 14 days',
                              ToolTipStyleData
                            );
                          }}
                          onMouseLeave={(e) => this.props.toggleOverlay(false)}
                        
                        >{`${typechangeColor === 'text-green' ? '+' : ''}${!isNaN(parseFloat(TM.MentionsChange)) ? parseFloat(TM.MentionsChange).toFixed(2) : '0.00'}`}%</div>
                      : activeTab === 'TopReach'
                      ? <div
                            ref={this.OverlayrefReachChange}
                            onMouseEnter={(e) => {
                              let ToolTipStyleData = {
                                Arrow: {
                                  marginLeft: '40px',
                                },
                                ToolTip: {
                                  align: 'center',
                                },
                              };
                              this.props.toggleOverlay(
                                true,
                                this.OverlayrefReachChange,
                                'Change in the number of social media followers for feeds mentioning the coin in the past day vs. the average in the past 14 days',
                                ToolTipStyleData
                              );
                            }}
                            onMouseLeave={(e) => this.props.toggleOverlay(false)}
                        
                        >{`${typechangeColor === 'text-green' ? '+' : ''}${!isNaN(parseFloat(TM.ReachChange)) ? parseFloat(TM.ReachChange).toFixed(2) : '0.00'}`}%</div>
                      : activeTab === 'TopSentiment'
                      ? <div
                          ref={this.OverlayrefSentimentChange}
                          onMouseEnter={(e) => {
                            let ToolTipStyleData = {
                              Arrow: {
                                marginLeft: '40px',
                              },
                              ToolTip: {
                                align: 'center',
                              },
                            };
                            this.props.toggleOverlay(
                              true,
                              this.OverlayrefSentimentChange,
                              ' Change in coin daily sentiment score vs. the average sentiment in the past 14 days',
                              ToolTipStyleData
                            );
                          }}
                          onMouseLeave={(e) => this.props.toggleOverlay(false)}
                        >{`${typechangeColor === 'text-green' ? '+' : ''}${!isNaN(parseFloat(SentimentChange)) ? parseFloat(SentimentChange).toFixed(2) : '0.00'}%`}</div>
                      : activeTab === 'TopPrice'
                      ? <div
                          ref={this.OverlayrefPriceChange}
                          onMouseEnter={(e) => {
                            let ToolTipStyleData = {
                              Arrow: {
                                marginLeft: '40px',
                              },
                              ToolTip: {
                                align: 'center',
                              },
                            };
                            this.props.toggleOverlay(
                              true,
                              this.OverlayrefPriceChange,
                              'Change in coin price within the past 24 hours',
                              ToolTipStyleData
                            );
                          }}
                          onMouseLeave={(e) => this.props.toggleOverlay(false)}
                        
                        >{`${typechangeColor === 'text-green' ? '+' : ''}${!isNaN(parseFloat(TM.Change)) ? parseFloat(TM.Change).toFixed(2) : '0.00'}`}%</div>
                      : activeTab === 'TopVolume'
                      ? ''
                      : '0.00%'}
                  </Col>
                </Row>
              </Col>
            )}
          </>
        );
      }
      

      // return (
      //   <>
          
      //     {cc_Preferences.isPro === 'false' && (
      //       <Col
      //         lg={12}
      //         sm={12}
      //         xs={12}
      //         md={12}
      //         xl={12}
      //         className={`nopad tm-card bg-content pointer`}
      //         onClick={this.viewDetails}
      //       >
      //         <Row className={`marg-0 nopad h-100`}>
      //           <Col
      //             className={`nopad  tmCol align-self-center text-offwhite-ni fs14 ${
      //               this.props.minifyIcon ? 'STM-ttickerCol-Adjust' : ''
      //             }`}
      //           >
      //             <Row className={`marg-0 nopad`}>
      //               {this.props.count > 0 && <div className={`${this.props.minifyIcon ? 'STM-Col3-Adjust' : ''} nopad  Col1 align-self-center text-offwhite-ni `} >
                      
      //                 {
      //                   this.props.count === 1 ? (
      //                     <img src={`${APPPATH.ImageUrl}/trophy-gold.svg`} alt="" style={{ width: 20 }}></img>
      //                   ) : this.props.count === 2 ? (
      //                     <img src={`${APPPATH.ImageUrl}/trophy-silver.svg`} alt="" style={{ width: 20 }}></img>
      //                   ) : this.props.count === 3 ? (
      //                     <img src={`${APPPATH.ImageUrl}/trophy-bronze.svg`} alt="" style={{ width: 20 }}></img>
      //                   ) : (
      //                     this.props.count
      //                   )
      //                 }
                      
      //               </div>}

      //               <div className={` Col2 align-self-center  ${index > 2 ? 'non-troophy-icon' : ''} `}>
      //                 {TickerIcon !== '' && (
      //                   <>
      //                     <span>
      //                       <object
      //                         className={`${
      //                           this.props.minifyIcon ? 'tmRowTickerIcon-minify' : 'tmRowTickerIcon'
      //                         } imground`}
      //                         data={TickerIcon}
      //                         type="image/png"
      //                       >
      //                         <img
      //                           src={`${APPPATH.ImageUrl}/logo_hdr.png`}
      //                           className={`${
      //                             this.props.minifyIcon ? 'tmRowTickerIcon-minify' : 'tmRowTickerIcon'
      //                           } imground`}
      //                           alt="CrowdSense.ai"
      //                         />
      //                       </object>
      //                     </span>
      //                   </>
      //                 )}
      //               </div>
      //               <Col className={`nopad padsmTMTickerCol-x text-left margl10 ${index > 2 ? 'non-troophy-text' : ''} `}>
      //                 <Row className={`marg-0 nopad`}>
      //                   <Col lg={12} sm={12} xs={12} md={12} xl={12} className={`nopad align-self-center padleft5`}>
      //                     <Row className={`marg-0 nopad`}>
      //                       <Col lg={12} sm={12} xs={12} md={12} xl={12} className={`nopad fs15 fs-sm-Ticker`}>
      //                         <div>
      //                           <span
      //                             className={`nopad text-white dispinb ${activeTab === 'TopSentiment' ? 'line-ellipsis-x' : ''} tm-cointext`}
      //                             title={coinName}
      //                             style={{ fontWeight: 500, marginTop: 3 }}
      //                           >
      //                             {formattedcoinName}
      //                           </span>
                                
      //                           <span className="dispinb" style={{ paddingLeft: 5 }}>
      //                             <FavCoin
      //                               toggleOverlay={this.props.toggleOverlay}
      //                               tickerName={TM.SecurityName}
      //                               userName={this.props.userData.username}
      //                               toggleConfirmPopup={this.props.toggleConfirmPopup}
      //                             />
      //                           </span>
      //                         </div>
      //                         <div className={`nopad fs10 text-white`} style={{ marginLeft: 0, fontWeight: 'bold' }}>
      //                           {TM.SecurityTicker !== null && TM.SecurityName !== null
      //                             ? ` ${TM.SecurityTicker.toUpperCase()}`
      //                             : ' '}
      //                         </div>
      //                       </Col>
      //                     </Row>
      //                   </Col>
                        
                        
      //                 </Row>
      //               </Col>
      //               {
      //                     activeTab === 'TopSentiment' && (
      //                       <Col lg={3} sm={3} xs={3} md={3} xl={3} className={`nopad align-self-center `}>
      //                         <div>
      //                           { SentimentEmotionIcon }
      //                         </div>
      //                         <div style={{marginTop: '5px', fontSize: '10px', fontWeight: 'bold', color: this.sentimentTextColor[`${SentimentEmotionTip}`]}}>
      //                           { SentimentEmotionTip }
      //                         </div>
      //                       </Col>
      //                     )
      //                   }
      //             </Row>
      //           </Col>

                

      //           {this.props.tdecktype !== 'tmd' && (
      //             <Col
      //               lg={1}
      //               sm={1}
      //               xs={1}
      //               md={1}
      //               xl={1}
      //               className={`${
      //                 this.props.minifyIcon ? 'STM-Col3-Adjust' : ''
      //               } nopad  tmCol align-self-center text-offwhite-ni `}
      //             >
      //               <img
      //                 src={`${APPPATH.ImageUrl}/storyexpandicon.svg`}
      //                 className="storyExpandIcon transform90anticlock"
      //                 alt=""
      //                 style={{ width: 15 }}
      //               ></img>
      //             </Col>
      //           )}
      //         </Row>
      //       </Col>
      //     )}

          
      //     {cc_Preferences.isPro === 'true' && (
      //       <Col
      //         lg={12}
      //         sm={12}
      //         xs={12}
      //         md={12}
      //         xl={12}
      //         className={`nopad tm-card bg-content pointer`}
      //         onClick={this.viewDetails}
      //       >
      //         <Row className={`marg-0 nopad h-100`}>
      //           <Col
      //             lg={this.props.minifyIcon ? 7 : 7}
      //             sm={this.props.minifyIcon ? 7 : 7}
      //             xs={this.props.minifyIcon ? 7 : 7}
      //             md={this.props.minifyIcon ? 7 : 7}
      //             xl={this.props.minifyIcon ? 7 : 7}
      //             className={`nopad  tmCol align-self-center text-offwhite-ni fs14 tmcoincolWidAdjust1 ${
      //               this.props.minifyIcon ? 'STM-ttickerCol-Adjust' : ''
      //             }`}
      //           >
      //             <Row className={`marg-0 nopad`}>
                    
      //               {this.props.count > 0 && <div className={`${this.props.minifyIcon ? 'STM-Col3-Adjust' : ''} nopad  Col1 align-self-center text-offwhite-ni margl5`} >
                      
      //                 {
      //                   this.props.count === 1 ? (
      //                     <img src={`${APPPATH.ImageUrl}/trophy-gold.svg`} alt="" style={{ width: 20 }}></img>
      //                   ) : this.props.count === 2 ? (
      //                     <img src={`${APPPATH.ImageUrl}/trophy-silver.svg`} alt="" style={{ width: 20 }}></img>
      //                   ) : this.props.count === 3 ? (
      //                     <img src={`${APPPATH.ImageUrl}/trophy-bronze.svg`} alt="" style={{ width: 20 }}></img>
      //                   ) : (
      //                     this.props.count
      //                   )
      //                 }
                      
      //               </div>}

      //               <div className=" Col2 align-self-center Col2-adjust ">
      //                 {TickerIcon !== '' && (
      //                   <>
      //                     <span>
      //                       <object
      //                         className={`${
      //                           this.props.minifyIcon ? 'tmRowTickerIcon-minify' : 'tmRowTickerIcon'
      //                         } imground`}
      //                         data={TickerIcon}
      //                         type="image/png"
      //                       >
      //                         <img
      //                           src={`${APPPATH.ImageUrl}/logo_hdr.png`}
      //                           className={`${
      //                             this.props.minifyIcon ? 'tmRowTickerIcon-minify' : 'tmRowTickerIcon'
      //                           } imground`}
      //                           alt="CrowdSense.ai"
      //                         />
      //                       </object>
      //                     </span>
      //                   </>
      //                 )}
      //               </div>
      //               <Col className="  nopad padsmTMTickerCol  text-left wid65perc">
      //                 <Row className={`marg-0 nopad`}>
      //                   <Col lg={12} sm={12} xs={12} md={12} xl={12} className={`nopad align-self-center padleft5`}>
      //                     <Row className={`marg-0 nopad`}>
      //                       <Col lg={12} sm={12} xs={12} md={12} xl={12} className={`nopad fs15 fs-sm-Ticker`}>
      //                         <span
      //                           title={coinName}
      //                           className={`nopad text-white dispinb line-ellipsis tm-cointext`}
      //                           style={{ fontWeight: 500 }}
      //                         >
      //                           {formattedcoinName}
      //                         </span>
                              
      //                         <span className="dispinb" style={{ paddingLeft: 5 }}>
      //                           <FavCoin
      //                             toggleOverlay={this.props.toggleOverlay}
      //                             tickerName={TM.SecurityName}
      //                             userName={this.props.userData.username}
      //                             toggleConfirmPopup={this.props.toggleConfirmPopup}
      //                           />
      //                         </span>
      //                       </Col>
      //                     </Row>
      //                   </Col>
                        
      //                     <Col lg={12} sm={12} xs={12} md={12} xl={12} className={`nopad align-self-center padleft5 `}>
      //                       <Row className={`marg-0 nopad`}>
      //                         <Col
      //                           lg={12}
      //                           sm={12}
      //                           xs={12}
      //                           md={12}
      //                           xl={12}
      //                           className={`nopad text-white fs12 bold ${
      //                             this.props.minifyIcon ? 'fs10' : ''
      //                           } fssm10`}
      //                         >
      //                           {TM.SecurityTicker !== null && TM.SecurityName !== null && (
      //                             <span className="text-white" style={{ marginRight: 5, fontWeight: 'bold' }}>
      //                               {TM.SecurityTicker.toUpperCase()}
      //                             </span>
      //                           )}
      //                           {
      //                             (parseInt(document.documentElement.clientWidth) < 600) || TrendingMentionsHelper.currencyFormat(TM.SecurityPrice, '$').length > 5 ? (
      //                               <div>
      //                                 { activeTab !== 'TopPrice' && TrendingMentionsHelper.currencyFormat(TM.SecurityPrice, '$')}
      //                                 {
      //                                   activeTab !== 'TopPrice' && (
      //                                     <span
      //                                       className={`nopad text-offwhite-ni fs12 fontnormal ${
      //                                         this.props.minifyIcon ? 'fs10' : ''
      //                                       } fssm10 ${TM.Change < 0 ? 'text-red' : TM.Change > 0 ? 'text-green' : ''} `}
      //                                     >
      //                                       &nbsp;
      //                                       {`${!isNaN(parseFloat(TM.Change)) ? (parseFloat(TM.Change) > 0 ? '+' : '') : ''}${
      //                                         !isNaN(parseFloat(TM.Change)) ? parseFloat(TM.Change).toFixed(2) : '0.00'
      //                                       }%`}
      //                                     </span>
      //                                   )
      //                                 }
      //                               </div>
      //                             ) : (
      //                               <span>
      //                                 { activeTab !== 'TopPrice' && TrendingMentionsHelper.currencyFormat(TM.SecurityPrice, '$')}
      //                                 {
      //                                   activeTab !== 'TopPrice' && (
      //                                     <span
      //                                       className={`nopad text-offwhite-ni fs12 fontnormal ${
      //                                         this.props.minifyIcon ? 'fs10' : ''
      //                                       } fssm10 ${TM.Change < 0 ? 'text-red' : TM.Change > 0 ? 'text-green' : ''} `}
      //                                     >
      //                                       &nbsp;
      //                                       {`${!isNaN(parseFloat(TM.Change)) ? (parseFloat(TM.Change) > 0 ? '+' : '') : ''}${
      //                                         !isNaN(parseFloat(TM.Change)) ? parseFloat(TM.Change).toFixed(2) : '0.00'
      //                                       }%`}
      //                                     </span>
      //                                   )
      //                                 }
      //                               </span>
      //                             )
      //                           }
                                
      //                         </Col>
                              
                              
      //                       </Row>
      //                     </Col>
                        
      //                 </Row>
      //               </Col>
      //             </Row>
      //           </Col>

      //           <Col
      //             lg={this.props.minifyIcon ? 3 : 3}
      //             sm={this.props.minifyIcon ? 3 : 3}
      //             xs={this.props.minifyIcon ? 3 : 3}
      //             md={this.props.minifyIcon ? 3 : 3}
      //             xl={this.props.minifyIcon ? 3 : 3}
      //             className={`nopad  tmCol tmcolscorechangevalues1 tmcoincolWidAdjust2 fssm15-13 align-self-center ${this.props.minifyIcon ? 'STM-Col1-Adjust' : ''}`}
      //           >
                  
      //             {activeTab === 'TopMentions'
      //               ? <div
      //                   ref={this.OverlayrefMentions}
      //                   onMouseEnter={(e) => {
      //                     let ToolTipStyleData = {
      //                       Arrow: {
      //                         marginLeft: '50px',
      //                       },
      //                       ToolTip: {
      //                         align: 'left',
      //                       },
      //                     };
      //                     this.props.toggleOverlay(
      //                       true,
      //                       this.OverlayrefMentions,
      //                       'Number of social media feeds mentioning the coin in the past day',
      //                       ToolTipStyleData
      //                     );
      //                   }}
      //                   onMouseLeave={(e) => this.props.toggleOverlay(false)}
      //                 >{TrendingMentionsHelper.abbreviateNumber(TM.Mentions)}</div>
      //               : activeTab === 'TopReach'
      //               ? <div
      //                   ref={this.OverlayrefReach}
      //                   onMouseEnter={(e) => {
      //                     let ToolTipStyleData = {
      //                       Arrow: {
      //                         marginLeft: '50px',
      //                       },
      //                       ToolTip: {
      //                         align: 'left',
      //                       },
      //                     };
      //                     this.props.toggleOverlay(
      //                       true,
      //                       this.OverlayrefReach,
      //                       'Number of followers that were reached by social media feeds mentioning the coin in the past day',
      //                       ToolTipStyleData
      //                     );
      //                   }}
      //                   onMouseLeave={(e) => this.props.toggleOverlay(false)}
      //                 >{TrendingMentionsHelper.abbreviateNumber(TM.Reach)}</div>
      //               : activeTab === 'TopSentiment'
      //               ? <div style={{textAlign: 'center'}}><div className="tm-emotions"
      //                 ref={this.OverlayrefEmotion}
      //                 onMouseEnter={(e) => {
      //                   let ToolTipStyleData = {
      //                     Arrow: {
      //                       marginLeft: '50px',
      //                     },
      //                     ToolTip: {
      //                       align: 'left',
      //                     },
      //                   };
      //                   this.props.toggleOverlay(
      //                     true,
      //                     this.OverlayrefEmotion,
      //                     SentimentEmotionTipText,
      //                     ToolTipStyleData
      //                   );
      //                 }}
      //                 onMouseLeave={(e) => this.props.toggleOverlay(false)}
      //               >
      //                   { SentimentEmotionIcon }
      //                 </div><div
      //                   ref={this.OverlayrefSentiment}
      //                   onMouseEnter={(e) => {
      //                     let ToolTipStyleData = {
      //                       Arrow: {
      //                         marginLeft: '50px',
      //                       },
      //                       ToolTip: {
      //                         align: 'left',
      //                       },
      //                     };
      //                     this.props.toggleOverlay(
      //                       true,
      //                       this.OverlayrefSentiment,
      //                       'Daily sentiment score for the coin from 0/10 (very negative) to 10/10 (very positive)',
      //                       ToolTipStyleData
      //                     );
      //                   }}
      //                   onMouseLeave={(e) => this.props.toggleOverlay(false)}
      //                 >{Sentiment / 10} / 10</div></div>
      //               : activeTab === 'TopPrice'
      //               ? <div
      //                   ref={this.OverlayrefPrice}
      //                   onMouseEnter={(e) => {
      //                     let ToolTipStyleData = {
      //                       Arrow: {
      //                         marginLeft: '50px',
      //                       },
      //                       ToolTip: {
      //                         align: 'left',
      //                       },
      //                     };
      //                     this.props.toggleOverlay(
      //                       true,
      //                       this.OverlayrefPrice,
      //                       'USD Price for this coin',
      //                       ToolTipStyleData
      //                     );
      //                   }}
      //                   onMouseLeave={(e) => this.props.toggleOverlay(false)}
      //                 >
      //                   {
      //                   parseFloat(TM.SecurityPrice) < 0.0001
      //                     ? '< $0.0001'
      //                     : TrendingMentionsHelper.currencyFormat(TM.SecurityPrice, '$')
      //                   }
      //                 </div>
      //               : activeTab === 'TopVolume'
      //               ? <div
      //                   ref={this.OverlayrefVolume}
      //                   onMouseEnter={(e) => {
      //                     let ToolTipStyleData = {
      //                       Arrow: {
      //                         marginLeft: '50px',
      //                       },
      //                       ToolTip: {
      //                         align: 'left',
      //                       },
      //                     };
      //                     this.props.toggleOverlay(
      //                       true,
      //                       this.OverlayrefVolume,
      //                       'Coin trading volume in USD in the past day',
      //                       ToolTipStyleData
      //                     );
      //                   }}
      //                   onMouseLeave={(e) => this.props.toggleOverlay(false)}
      //                 >{
      //                   parseFloat(TM.Volume24hUsd) > 1 && parseFloat(TM.SecurityPrice) > 0
      //                     ? `$ ${TrendingMentionsHelper.abbreviateNumber(TM.Volume24hUsd)}`
      //                     : '0'
      //                 }</div>
      //               : '0'}
      //           </Col>
      //           <Col
      //             lg={this.props.minifyIcon ? 3 : 3}
      //             sm={this.props.minifyIcon ? 3 : 3}
      //             xs={this.props.minifyIcon ? 3 : 3}
      //             md={this.props.minifyIcon ? 3 : 3}
      //             xl={this.props.minifyIcon ? 3 : 3}
      //             className={`nopad  tmCol tmcolscorechangevalues2 align-self-center ${typechangeColor} ${
      //               this.props.minifyIcon ? 'STM-Col2-Adjust' : ''
      //             }`}
      //           >
      //             {activeTab === 'TopMentions'
                  
      //               ? <div
      //                   ref={this.OverlayrefMentionsChange}
      //                   onMouseEnter={(e) => {
      //                     let ToolTipStyleData = {
      //                       Arrow: {
      //                         marginLeft: '40px',
      //                       },
      //                       ToolTip: {
      //                         align: 'center',
      //                       },
      //                     };
      //                     this.props.toggleOverlay(
      //                       true,
      //                       this.OverlayrefMentionsChange,
      //                       'Change in the number of social media feeds mentioning the coin in the past day vs. the average in the past 14 days',
      //                       ToolTipStyleData
      //                     );
      //                   }}
      //                   onMouseLeave={(e) => this.props.toggleOverlay(false)}
                      
      //                 >{`${typechangeColor === 'text-green' ? '+' : ''}${!isNaN(parseFloat(TM.MentionsChange)) ? parseFloat(TM.MentionsChange).toFixed(2) : '0.00'}`}%</div>
      //               : activeTab === 'TopReach'
      //               ? <div
      //                     ref={this.OverlayrefReachChange}
      //                     onMouseEnter={(e) => {
      //                       let ToolTipStyleData = {
      //                         Arrow: {
      //                           marginLeft: '40px',
      //                         },
      //                         ToolTip: {
      //                           align: 'center',
      //                         },
      //                       };
      //                       this.props.toggleOverlay(
      //                         true,
      //                         this.OverlayrefReachChange,
      //                         'Change in the number of social media followers for feeds mentioning the coin in the past day vs. the average in the past 14 days',
      //                         ToolTipStyleData
      //                       );
      //                     }}
      //                     onMouseLeave={(e) => this.props.toggleOverlay(false)}
                      
      //                 >{`${typechangeColor === 'text-green' ? '+' : ''}${!isNaN(parseFloat(TM.ReachChange)) ? parseFloat(TM.ReachChange).toFixed(2) : '0.00'}`}%</div>
      //               : activeTab === 'TopSentiment'
      //               ? <div
      //                   ref={this.OverlayrefSentimentChange}
      //                   onMouseEnter={(e) => {
      //                     let ToolTipStyleData = {
      //                       Arrow: {
      //                         marginLeft: '40px',
      //                       },
      //                       ToolTip: {
      //                         align: 'center',
      //                       },
      //                     };
      //                     this.props.toggleOverlay(
      //                       true,
      //                       this.OverlayrefSentimentChange,
      //                       ' Change in coin daily sentiment score vs. the average sentiment in the past 14 days',
      //                       ToolTipStyleData
      //                     );
      //                   }}
      //                   onMouseLeave={(e) => this.props.toggleOverlay(false)}
      //                 >{`${typechangeColor === 'text-green' ? '+' : ''}${!isNaN(parseFloat(SentimentChange)) ? parseFloat(SentimentChange).toFixed(2) : '0.00'}%`}</div>
      //               : activeTab === 'TopPrice'
      //               ? <div
      //                   ref={this.OverlayrefPriceChange}
      //                   onMouseEnter={(e) => {
      //                     let ToolTipStyleData = {
      //                       Arrow: {
      //                         marginLeft: '40px',
      //                       },
      //                       ToolTip: {
      //                         align: 'center',
      //                       },
      //                     };
      //                     this.props.toggleOverlay(
      //                       true,
      //                       this.OverlayrefPriceChange,
      //                       'Change in coin price within the past 24 hours',
      //                       ToolTipStyleData
      //                     );
      //                   }}
      //                   onMouseLeave={(e) => this.props.toggleOverlay(false)}
                      
      //                 >{`${typechangeColor === 'text-green' ? '+' : ''}${!isNaN(parseFloat(TM.Change)) ? parseFloat(TM.Change).toFixed(2) : '0.00'}`}%</div>
      //               : activeTab === 'TopVolume'
      //               ? ''
      //               : '0.00%'}
      //           </Col>
      //         </Row>
      //       </Col>
      //     )}
      //   </>
      // );
    } else {
      
      if (this.props.TMRequestState || this.props.TabColumnChangeRequest) {
        // this.props.showModal('Loader');
        if(this.props.viewState === 'expandeddesktop') {
            return (
              <tr>
                
                <td colSpan={10} style={{backgroundColor: 'transparent'}}>
                  <Col lg={12} sm={12} xs={12} md={12} xl={12} className={`nopad`}>
                    <div>
                      <i className="fa fa-spinner fa-2x fa-spin storyloadindicator"></i>
                    </div>
                  </Col>
                </td>
                
              </tr>
              
            );
        } else if( this.props.viewState === 'expandedmobile') {
          return (
            <tr>
              
              <td style={{position: 'sticky', left: '20%', border: 'unset',backgroundColor: 'transparent'}}>
                <Col lg={12} sm={12} xs={12} md={12} xl={12} className={`nopad`}>
                  <div>
                    <i className="fa fa-spinner fa-2x fa-spin storyloadindicator"></i>
                  </div>
                </Col>
              </td>
              
            </tr>
            
          );
        }else {
          return (
            <Col lg={12} sm={12} xs={12} md={12} xl={12} className={`nopad`}>
              <div>
                <i className="fa fa-spinner fa-2x fa-spin storyloadindicator"></i>
              </div>
            </Col>
          );
        }
        
        // return (
        //   <Col lg={12} sm={12} xs={12} md={12} xl={12} className={`nopad`}>
        //     <div>
        //       <i className="fa fa-spinner fa-2x fa-spin storyloadindicator"></i>
        //     </div>
        //   </Col>
        // );
      } else {
        if(this.props.viewState === 'expandeddesktop') {
          return (
            <tr>
              <td colSpan={10} style={{backgroundColor: 'transparent'}}>
                <Col lg={12} sm={12} xs={12} md={12} xl={12} className={`nopad`}>
                  {`No Data `}
                </Col>
              </td>
            </tr>
            
          );
        } else if(this.props.viewState === 'expandedmobile') {
          return (
            <tr>
              <td style={{position: 'sticky', left: '0%', border: '1px solid #fff',backgroundColor: 'transparent'}} >
                <Col lg={12} sm={12} xs={12} md={12} xl={12} className={`nopad`}>
                  {`No Data`}
                </Col>
              </td>
            </tr>
            
          );
        }else {
          return (
            <Col lg={12} sm={12} xs={12} md={12} xl={12} className={`nopad`}>
              {`No Data`}
            </Col>
          );
        
        }
        // return (
        //   <Col lg={12} sm={12} xs={12} md={12} xl={12} className={`nopad`}>
        //     {`No Data in Trending by mentions`}
        //   </Col>
        // );
      }
    }
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    TM:
      ownProps.tdecktype === 'tmd'
        ? state.trendingMentions.tmDetails.TickerData
        : typeof state.trendingMentions.coin_column_data[ownProps.cc_index].tmList[ownProps.id] !== 'undefined'
        ? state.trendingMentions.coin_column_data[ownProps.cc_index].tmList[ownProps.id]
        : null,
    // TMRequestState: state.trendingMentions.tmReqData.RequestState,
    TMRequestState: state.trendingMentions.cc_tmReqData[ownProps.cc_index].RequestState,
    TabColumnChangeRequest: state.trendingMentions.TabColumnChangeRequest[ownProps.cc_index],
    packageName: state.trendingMentions.coin_column_preferences[ownProps.cc_index] ? state.trendingMentions.coin_column_preferences[ownProps.cc_index].packageName : '',
    activeTab: state.trendingMentions.coin_column_preferences[ownProps.cc_index] ? state.trendingMentions.coin_column_preferences[ownProps.cc_index].activeTab : '',
    showtopreach: state.settings.showtopreach,
    Theme: state.app_preferences.preferences.user_interface.Theme,
  };
};

const mapDispatchToProps = {
  // TMViewDetails
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleTM);
