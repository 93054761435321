import appGlobalState from '../appconfig';
import { APICONFIG , APPPATH } from '../modules/helper.module';

const errorReducer = (state = {...appGlobalState.error}, action) => {
    if(action.type === 'RESET_APP_ERROR') {
        window.localStorage.removeItem('userData');
        return {
            ...state,
            general: false,
            systemTime: false,
            rootFeeds: false,
            appError: ''
        };
    }
    if(action.type === 'REQUEST_ERROR') {
        
        // console.log(action.payload)
        // console.log(action.data)
        let errorData = action.payload ? action.payload.replace('Error:','').toString().split(':') : []
        let error = {
          'error' : errorData.length ? errorData[0].trim() : false,
          'error_description': action.payload
        }
        // console.log(error)
        if(errorData.length) {
    
            // window.Mixpanel.MixpanelProps.UserName = action.data && action.data.username ? action.data.username : '';
            // window.Mixpanel.MixpanelProps.ErrorType = error.error;
            // window.Mixpanel.MixpanelProps.ErrorDescription = action.payload;
            // window.Mixpanel.MixpanelProps.ActionInitiatedError = action.data && action.data.action ? action.data.action : '';
            // window.Mixpanel.MixpanelProps.ActionUrl = action.data && action.data.url ? action.data.url : '';
        
            // window.Mixpanel.actions.track(error.error_description, window.Mixpanel.MixpanelProps).then(data => {
            //     window.Mixpanel.actions.people.set({
            //         UserName: action.data && action.data.username ? action.data.username : ''
            //     });
            //     window.Mixpanel.actions.resetProp('ErrorType');
            //     window.Mixpanel.actions.resetProp('ErrorDescription');
            //     window.Mixpanel.actions.resetProp('ActionInitiatedError');
            // })
        
            if(error.error.trim().toString() === '401') {
                return {
                    ...state,
                    general: true,
                    appError: error.error_description
                };
            }
            if(action.data && action.data.action) {
                if(action.data.action === 'Get Alerts' || action.data.action === 'Update Alerts' || action.data.action === 'Search Events') {
                    return {
                        ...state,
                        rootFeeds: true,
                        appError: error.error_description
                    };
                } else if(action.data.action === 'Get System Time') {
                    return {
                        ...state,
                        systemTime: true,
                        appError: error.error_description
                    };
                } else if(action.data.action === 'Try to login') {
                    // console.log('here')
                    return {
                        ...state,
                        auth: true,
                        passwordlesslogin : error.error_description.includes('invalid_grant') && action.data.password.startsWith('##Link#AC') ? true : false,
                        appError: error.error_description.includes('invalid_grant') ? 
                            action.data.password.startsWith('##Link#AC') ? 
                                'Link is already used. Please generate a new link to login' 
                            : `The user name or password is incorrect.`
                        : error.error_description
                        
                    };
                } else if(action.data.action === 'UserNotFound') {
                    return {
                        ...state,
                        auth: true,
                        appError: `User not found. Please try with <a href="https://signin.crowdsense.ai/api/Account/ExternalLogin?provider=Google&response_type=token&client_id=self&redirect_uri=${encodeURIComponent(APPPATH.AppUrl)}%2F&state=CHArdfGXncDrhlsWlitp8vCtpaCGPBLI2FtiG0dbFJg1" target="_blank">Google Login</a> , <a href="https://signin.crowdsense.ai/api/Account/ExternalLogin?provider=Facebook&response_type=token&client_id=self&redirect_uri=${encodeURIComponent(APPPATH.AppUrl)}%2F&state=CHArdfGXncDrhlsWlitp8vCtpaCGPBLI2FtiG0dbFJg1" target="_blank">Facebook Login</a> or <a href="https://crowdsense.ai/signup" target="_blank">Signup</a>`
                    };
                    // Please try to login with username and password or <a href="https://crowdsense.ai/signup" target="_blank">Signup</a>
                } else if(action.data.action === 'FallbackScreen') {
                    return {
                        ...state,
                        fallbackError: true,
                        appError: 'The cloud provider we are using is experiencing some issues or going through maintenance. We will go live again as soon as they fix the issues.'
                    };
                } else if(action.data.action === 'Check User Message') {
                    return {
                        ...state,
                        appError: error.error_description
                    };
                } else if(action.data.action === 'Get Stories by Package') {
                    return {
                        ...state
                    }
                }
            }
        }
        return {
            ...state,
            general: true,
            appError: action.payload,
            data: action.data
        } 
          
    }
    if(action.type === 'PL_USERNOTFOUND') {
        return {
            ...state,
            passwordlesslogin_usernotfound: action.payload
        }
    }
    return state;
}

export default errorReducer;