import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { APPPATH, TrendingMentionsHelper, PreferenceHandler } from '../../../modules/helper.module';
import * as settingsActionCreators from '../../../actions/settings.action';
import * as preferencesActionCreator from '../../../actions/apppreferences.action';
import { isMobile } from 'react-device-detect';

class MenuFilter extends Component {
    constructor(props) {
        super();
        // console.log(props.MenuFilter);
        this.state = {
            ActiveFilter: props.MenuFilter
        };
    }

    componentWillReceiveProps(nextProps) {
        // console.log(nextProps.preferences)
        // console.log(nextProps.MenuFilter)
        this.setState({
            ...this.state,
            ActiveFilter: nextProps.MenuFilter
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.showtoptrending === false) {
            if (this.props.tmDetailPreferences.length && this.props.coin_column_preferences.length && this.props.columnorderlist.length) {
                this.hideTopCoins()
            }

        }
    }
    hideTopCoins = () => {
        let { coin_column_preferences, tmDetailPreferences } = this.props;
        let preferences = JSON.parse(JSON.stringify(this.props.preferences));
        if (preferences.user_interface.HasColumnOrderSettings === false) {
            preferences.user_interface.HasColumnOrderSettings = true;
        }
        let columnorderlist = JSON.parse(JSON.stringify(this.props.columnorderlist))
        // let status = false;
        let cc_prefs = JSON.parse(JSON.stringify(coin_column_preferences));
        cc_prefs.splice(0, 1);
        columnorderlist.splice(0, 1)

        preferences.user_interface.MenuFilter = 'Summary';
        let PreferenceStrings = PreferenceHandler.CreatePreferencesStrings(preferences);
        let CoinCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(cc_prefs);
        let CoinDetailCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(tmDetailPreferences, 'coindetails');
        let ColumnOrderStrings = PreferenceHandler.CreateColumnOrderString(columnorderlist)
        let newPrefStr = `${PreferenceStrings.userPrefData.UserPreferencesString}${CoinCoulmnStrings}${CoinDetailCoulmnStrings}${ColumnOrderStrings}`
        // console.log(newPrefStr);
        PreferenceStrings.userPrefData.UserPreferencesString = newPrefStr;
        PreferenceStrings.userPrefData.UserName = this.props.userData.username;
        this.props.setUserPreferences(PreferenceStrings.userPrefData);

    }
    selectingMenuFilter = (evnt) => {
        let newFilter = evnt.target.id
        // console.log(newFilter);
        let currentFilter = this.state.ActiveFilter
        let filterchange = currentFilter !== newFilter
        this.setState({
            ...this.state,
            ActiveFilter: newFilter
        }, () => {
            let preferences = { ...this.props.preferences };
            let { coin_column_preferences, tmDetailPreferences, columnorderlist } = this.props;
            if (preferences.user_interface.HasColumnOrderSettings === false) {
                preferences.user_interface.HasColumnOrderSettings = true;
            }
            preferences.user_interface.MenuFilter = this.state.ActiveFilter;
            if (this.state.ActiveFilter === 'Trending') {
                // console.log(coin_column_preferences)
                if (!coin_column_preferences.length) {
                    let cc_prefs = JSON.parse(JSON.stringify(coin_column_preferences));
                    let newPrefObj = TrendingMentionsHelper.newTopCoinPreference();
                    // newPrefObj.expanded = true;
                    cc_prefs[cc_prefs.length] = newPrefObj;
                    coin_column_preferences = cc_prefs
                    columnorderlist.unshift(newPrefObj)
                } else {
                    let cc_prefs = JSON.parse(JSON.stringify(coin_column_preferences));
                    // cc_prefs[cc_prefs.length-1].expanded = true
                    coin_column_preferences = cc_prefs
                    columnorderlist[0].expanded = true
                }
            } else {
                // console.log('here else')
                if (this.state.ActiveFilter === 'Summary' || this.state.ActiveFilter === 'Inbox' || this.state.ActiveFilter === 'Search') {
                    if (coin_column_preferences.length) {
                        let cc_prefs = JSON.parse(JSON.stringify(coin_column_preferences));
                        cc_prefs[cc_prefs.length - 1].expanded = false
                        coin_column_preferences = cc_prefs
                        columnorderlist[0].expanded = false
                    }
                }
            }
            let PreferenceStrings = PreferenceHandler.CreatePreferencesStrings(preferences);
            let CoinCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(coin_column_preferences);
            let CoinDetailCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(tmDetailPreferences, 'coindetails');
            let ColumnOrderStrings = PreferenceHandler.CreateColumnOrderString(columnorderlist)
            Object.keys(PreferenceStrings).map((StrType) => {
                PreferenceStrings[`${StrType}`].UserName = this.props.userData.username;
                return null;
            });
            PreferenceStrings.userPrefData.UserPreferencesString = `${PreferenceStrings.userPrefData.UserPreferencesString}${CoinCoulmnStrings}${CoinDetailCoulmnStrings}${ColumnOrderStrings}`
            if (typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser) {
                this.props.setUserPreferences_LLU(PreferenceStrings.userPrefData);
            } else {
                this.props.setUserPreferences(PreferenceStrings.userPrefData);
            }


            window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
            window.Mixpanel.MixpanelProps.Category = 'Tab Selected';
            window.Mixpanel.MixpanelProps.Tab = this.state.ActiveFilter;
            window.Mixpanel.actions.identify(this.props.userData.username);
            window.Mixpanel.actions.track(`Tab Selected or Changed`, window.Mixpanel.MixpanelProps).then((data) => {
                window.Mixpanel.actions.people.set({
                    UserName: this.props.userData.username,
                });
                window.Mixpanel.actions.resetProp('Category');
                window.Mixpanel.actions.resetProp('Tab');
            });


        })
    }

    render() {
        const { ActiveFilter } = this.state;
        // console.log(ActiveFilter)
        return (
            <Row className="marg-0 justify-content-center text-center">
                <Col className="padt4 " >
                    <ul className="nav">
                        <li className="nav-item">
                            <a className={`nav-link ${this.props.Theme === 'light' ? 'lightmode' : ''} pointer ${ActiveFilter === 'Summary' ? 'active' : ''}`} id="Summary" onClick={this.selectingMenuFilter}>Show All</a>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-link ${this.props.Theme === 'light' ? 'lightmode' : ''} pointer ${ActiveFilter === 'Inbox' ? 'active' : ''}`} id="Inbox" onClick={this.selectingMenuFilter}>Inbox</a>
                        </li>
                        {
                            this.props.showtoptrending && (
                                <li className="nav-item">
                                    <a className={`nav-link ${this.props.Theme === 'light' ? 'lightmode' : ''} pointer ${ActiveFilter === 'Trending' ? 'active' : ''}`} id="Trending" onClick={this.selectingMenuFilter}>Trending</a>
                                </li>
                            )
                        }

                        <li className="nav-item">
                            <a className={`nav-link ${this.props.Theme === 'light' ? 'lightmode' : ''} pointer ${ActiveFilter === 'Search' ? 'active' : ''}`} id="Search" onClick={this.selectingMenuFilter}>Search</a>
                        </li>
                    </ul>
                </Col>
            </Row>
        )
    }
}

const mapDispatchToProps = {
    ...settingsActionCreators,
    ...preferencesActionCreator
}
const mapStateToApp = (state) => {
    return {
        userData: state.settings.userData,
        MenuFilter: state.app_preferences.preferences.user_interface.MenuFilter,
        preferences: state.app_preferences.preferences,
        coin_column_preferences: state.trendingMentions.coin_column_preferences,
        tmDetailPreferences: state.trendingMentions.tmDetailPreferences,
        columnorderlist: state.settings.columnorderlist,
        Theme: state.app_preferences.preferences.user_interface.Theme,
        showtoptrending: state.settings.showtoptrending
    };
};

export default connect(mapStateToApp, mapDispatchToProps)(MenuFilter);