import React, { Component } from 'react'; 
import { GoogleApiWrapper } from 'google-maps-react';
import { APPPATH } from '../../modules/helper.module';

class StoryMaps extends Component {
    constructor(props) {
        super();
        this.state = {
            geoLocations: this.GeoLocationMaker(props.GeoLocations),
            geoTitle: this.GeoLocationMaker(props.GeoLocations, 'TITLE'),
            geoLocationsRadius: parseInt(props.GeoLocationsRadius),
            geoMarkers: this.CreateGeoMarkers(props.GeoMarkers),
            initialCenter : this.GeoLocationMaker(props.GeoLocations, 'INITIALCENTER'),
            mapStyle: {
                width: '100%',
                height: '200px',
                position: 'relative',
            },
            containerStyle: {
                position: 'relative',  
                width: '100%',
                height: '200px',
                marginTop: '5px',
                marginBottom: '5px',
                borderRadius: '5px',
            },
            infoWindowStyle: {
                color: '#282c34'
            } ,
            ZoomRadiusRelation: {
                '0' : 819200,
                '1' : 409600,
                '2' : 204800,
                '3' : 102400,
                '4' : 51200,
                '5' : 25600,
                '6' : 12800,
                '7' : 6400,
                '8' : 3200,
                '9' : 1600,
                '10' : 800,
                '11' : 400,
                '12' : 200,
                '13' : 100,
                '14' : 50,
                '15' : 25,
                '16' : 12,
                '17' : 6,
                '18' : 3
            }  
        }
        this.mapDom = '';
        this.map = '';
        this.EventRadiusCircle = [];
        this.markers = [];
        this.infowindow = '';
    }

    destinationPoint = (bearing, distance, refPoint) => {
        this.prototype.toRad = function() {
            return this * Math.PI / 180;
        }
        
        this.prototype.toDeg = function() {
            return this * 180 / Math.PI;
        }
        
        distance = (distance/1000) / 6371;
        bearing = bearing.toRad();
        
        const refLat = refPoint.lat().toRad();
        const refLon = refPoint.lng().toRad();
        // console.log(refPoint.lat(), refPoint.lng())
        // console.log(refLat, refLon)

        const newLat = Math.asin(Math.sin(refLat) * Math.cos(distance) + Math.cos(refLat) * Math.sin(distance) * Math.cos(bearing));
        const newLon = refLon + Math.atan2(Math.sin(bearing) * Math.sin(distance) * Math.cos(refLat), Math.cos(distance) - Math.sin(refLat) *Math.sin(newLat));

        // console.log(newLat, newLon)
        if (isNaN(newLat) || isNaN(newLon)) return null;
        return {lat: parseFloat(newLat.toDeg().toFixed(7)), lng: parseFloat(newLon.toDeg().toFixed(7))}
    }
    componentDidMount() {
        try{
            const { google } = this.props
            const { geoLocations, mapStyle, initialCenter, geoLocationsRadius, geoMarkers, geoTitle } = this.state

            // console.log(geoMarkers, initialCenter, geoTitle)
            this.map = new google.maps.Map(this.mapDom, {
                style: mapStyle,
                mapTypeId: google.maps.MapTypeId.HYBRID,
                center: { lat: initialCenter.latitude, lng: initialCenter.longitude },
                scrollwheel: true,
                scaleControl: true,
                panControl: false,
                mapTypeControl: false,
                streetViewControl: false,
                fullscreenControl: false,
                zoomControl: true,
                zoomControlOptions: {
                    position: google.maps.ControlPosition.RIGHT_BOTTOM,
                    style: google.maps.ZoomControlStyle.SMALL
                },
                // zoom: 14,
                tilt: 45
            });
            let MapBounds = new google.maps.LatLngBounds();
            this.infowindow = new google.maps.InfoWindow();

            // geo location markers
            const centralMarkerPosition = new google.maps.LatLng(initialCenter.latitude, initialCenter.longitude);
            const marker = new google.maps.Marker({
                position: centralMarkerPosition,
                map: this.map,
                title: `${geoTitle[0]}`,
                icon: `${APPPATH.ImageUrl}/map-center-marker.png`
            })
            this.markers.push(marker);
            this.AddMarkerClickListener(this.markers[0], `${geoTitle[0]}`);
            MapBounds.extend(centralMarkerPosition)

            // console.log(geoLocations, geoTitle)
            // if(Array.isArray(geoLocations) && geoLocations.length > 1) {
            //     geoLocations.forEach((loc, ind) => {
            //         if(ind > 0) {
            //             const newGeoMarkerPosition = new google.maps.LatLng(parseFloat(loc.split(',')[0]), parseFloat(loc.split(',')[1]));
            //             const newGeoMarker = new google.maps.Marker({
            //                 position: newGeoMarkerPosition,
            //                 map: this.map,
            //                 title: `${geoTitle[ind]}`,
            //                 icon: `${APPPATH.ImageUrl}/map-center-marker.png`
            //             })
            //             this.markers.push(newGeoMarker);
            //             this.AddMarkerClickListener(this.markers[this.markers.length -1], `${geoTitle[ind]}`);
            //             MapBounds.extend(newGeoMarkerPosition);
            //         }
            //     })
            // }
            if(Array.isArray(geoMarkers) && geoMarkers.length) {
                geoMarkers.forEach(geoMarker => {
                    const newMarkerPosition = new google.maps.LatLng(geoMarker.coords.lat, geoMarker.coords.lng);
                    const newMarker = new google.maps.Marker({
                        position: newMarkerPosition,
                        map: this.map,
                        title: `${geoMarker.title}`,
                        icon: `${APPPATH.ImageUrl}/assets_marker.png`
                    })
                    this.markers.push(newMarker);
                    this.AddMarkerClickListener(this.markers[this.markers.length -1], `${geoMarker.title}`);
                    MapBounds.extend(newMarkerPosition);
                })
            }
            let EventRadiusCircle = '';
            if(geoLocationsRadius > 0) {
                // console.log(geoLocationsRadius)
                EventRadiusCircle = new google.maps.Circle({
                    strokeColor: "#E75480",
                    strokeOpacity: 0.6,
                    strokeWeight: 2,
                    fillColor: "#E75480",
                    fillOpacity: 0.25,
                    map: this.map,
                    center: { lat: initialCenter.latitude, lng: initialCenter.longitude },
                    radius: geoLocationsRadius
                });
                this.EventRadiusCircle.push(EventRadiusCircle)
                
                if(geoLocations.length <= 1 && !geoMarkers.length) {
                    MapBounds.union(EventRadiusCircle.getBounds());
                } else {
                    let CBoundary1 = this.destinationPoint(90, geoLocationsRadius, centralMarkerPosition)
                    const CBoundary1MarkerPosition = new google.maps.LatLng(CBoundary1.lat, CBoundary1.lng);
                    MapBounds.extend(CBoundary1MarkerPosition);

                    let CBoundary2 = this.destinationPoint(180, geoLocationsRadius, centralMarkerPosition);
                    const CBoundary2MarkerPosition = new google.maps.LatLng(CBoundary2.lat, CBoundary2.lng);
                    MapBounds.extend(CBoundary2MarkerPosition);

                    let CBoundary3 = this.destinationPoint(270, geoLocationsRadius, centralMarkerPosition)
                    const CBoundary3MarkerPosition = new google.maps.LatLng(CBoundary3.lat, CBoundary3.lng);
                    MapBounds.extend(CBoundary3MarkerPosition);

                    let CBoundary4 = this.destinationPoint(360, geoLocationsRadius, centralMarkerPosition)
                    const CBoundary4MarkerPosition = new google.maps.LatLng(CBoundary4.lat, CBoundary4.lng);
                    MapBounds.extend(CBoundary4MarkerPosition);
                }
            }

            this.map.fitBounds(MapBounds);
            let ZoomListener = this.map.addListener("idle", () => {
                if(geoLocations.length <= 1 && !geoMarkers.length) {
                    this.map.setZoom(this.map.getZoom() +1);
                }
                const mapZoom = this.map.getZoom();
                if(this.RemoveCentralMarker(geoLocationsRadius, mapZoom)) {
                    this.markers[0].setMap(null)
                }
                this.map.setTilt(45);
                google.maps.event.removeListener(ZoomListener);
            })
        } catch(err) {
            console.log(err)
        }
        
    }
    
    RemoveCentralMarker = (radius = false, mapZoom = false) => {
        if(!radius) return false;
        let resp = false;
        // console.log(radius, mapZoom)
        Object.keys(this.state.ZoomRadiusRelation).forEach(zoom => {
            if(mapZoom === parseInt(zoom)) {
                if(radius > this.state.ZoomRadiusRelation[zoom]) {
                    resp = true;
                }
            }
        })
        return resp;
    } 

    GeoLocationMaker = (GeoLocations, type = 'COORDS') => {
        let geocoords = GeoLocations.split('~');
        let GeoString = [];
        let Title = [];
        let Coords = [];
        if(geocoords.length) {
            geocoords.forEach(itm => {
                GeoString.push(itm);
                Title.push(itm.trim().substr(0, itm.trim().indexOf('(')).trim() === '' ? "Event's Location" : itm.trim().substr(0, itm.trim().indexOf('(')).trim());
                Coords.push(itm.trim().substring(itm.trim().indexOf('(')+1, itm.trim().length-1).split(',').map(coord =>(coord.trim())).join(','));
            })
        } 
        
        switch(type) {
            case 'TITLE':
                return Title.map(itm => (itm.toLowerCase() !== 'none' ? itm :"Event's Location"))
                // break;
            case 'INITIALCENTER':
                return {
                    latitude: parseFloat(Coords[0].split(',')[0]),
                    longitude: parseFloat(Coords[0].split(',')[1])
                };
                // break;
            default:
                return Coords;
                // break;
        }
    }
    CreateGeoMarkers = (markersDataString = false) => {
        try {
            // console.log(markersDataString)
            if(markersDataString && markersDataString !== '') {
                let markersData = markersDataString.split('~').map(eachData => {
                    let subStringStartIndex = eachData.trim().indexOf('(');
                    let dataCoords = eachData.trim().substring(subStringStartIndex+1, eachData.trim().length-1).split(',').map(coord =>(coord.trim())).join(',');
                    let dataTitle = eachData.trim().substr(0, subStringStartIndex).trim();
                    return {
                        coords: {lat: parseFloat(dataCoords.split(',')[0]), lng: parseFloat(dataCoords.split(',')[1])},
                        title: `${dataTitle}`
                    }
                    
                })
                return markersData;
            }
            return false
        } catch (err) {
            console.log(err)
        }
        
    }

    AddMarkerClickListener = (marker, content) => {
        marker.addListener("click", () => {this.openInfoWindow(marker, content)})
    }

    openInfoWindow = (marker, content) => {
        let InfoWindowContent = `<div style="color:#282c34 !important;font-weight:bold;"><label>${content}</label></div>`;
        this.infowindow.setContent(InfoWindowContent);
        this.infowindow.open(this.map, marker);
    }

    componentWillReceiveProps(nextProps, prevState) {
        if(this.state.geoLocations !== nextProps.GeoLocations) {
            // console.log('triggering render')
            if(nextProps.GeoLocations !== '' && nextProps.GeoLocations.split(',').length === 2) {
                this.setState({
                    ...this.state,
                    geoLocations: nextProps.GeoLocations,
                    geoLocationsRadius: parseInt(nextProps.GeoLocationsRadius) !== this.state.geoLocationsRadius ? parseInt(nextProps.GeoLocationsRadius) : this.state.geoLocationsRadius,
                    initialCenter: {
                        ...this.state.initialCenter,
                        latitude: nextProps.GeoLocations.split(',')[0] !== this.state.initialCenter.latitude ? nextProps.GeoLocations.split(',')[0] : this.state.initialCenter.latitude,
                        longitude: nextProps.GeoLocations.split(',')[1] !== this.state.initialCenter.longitude ? nextProps.GeoLocations.split(',')[1] : this.state.initialCenter.longitude,
                    }
                })
            }
        }
        
    }

    shouldComponentUpdate(nextProps, nextState) {
        if(this.state.geoLocations === nextState.geoLocations) {
            return false;
        }
        return true;
    }

    render() {
        const { containerStyle } = this.state
        return (
            <div style={containerStyle} ref={(el) => {this.mapDom = el}}></div>
        );
    }
}

export default GoogleApiWrapper((props) => {
    return {
        apiKey: props.GMAPKEY
    }   
})(StoryMaps)
