import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as tmActionCreators from '../../actions/trendingmentions.action';
import TMContextHandle from './tmUpdater';

class TMContextHandleWrap extends Component {
  constructor(props) {
    super();
    this.state = {
        cc_preferences: props.coin_column_preferences
    }
    this.tmupdaterHandle = [];
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      cc_preferences: nextProps.coin_column_preferences
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevState.cc_preferences.length !== this.state.cc_preferences.length) {
        this.state.cc_preferences.forEach((itm,i) => {
            this.tmupdaterHandle.push(React.createRef());
        })
    }
    
  }

  render() {
    const { cc_preferences } = this.state;
    // console.log(cc_preferences)
    let updaterArr = [];
    cc_preferences.forEach((prefObj, i) => {
        updaterArr.push(
            <TMContextHandle 
                showModal={this.props.showModal} 
                urlParams={this.props.urlParams} 
                ref={this.tmupdaterHandle[i]} 
                userData={this.props.userData}
                columnindex={i}
                key={`updaterhandle${i}`}
            />
        )
    })
    return (<>{updaterArr}</>);
  }
}

const mapStateToComponent = (state) => {
    
    return {
      coin_column_preferences: state.trendingMentions.coin_column_preferences
    };
  };
  const mapDispatchToProps = {
    ...tmActionCreators,
  };
  
export default connect(mapStateToComponent, mapDispatchToProps, null, { forwardRef: true })(TMContextHandleWrap);