import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Row, Col } from 'react-bootstrap';
import { APPPATH, TrendingMentionsHelper, PreferenceHandler } from '../../../../modules/helper.module';
import SingleStory from '../../../stories/single_story';
import { connect } from 'react-redux';
import * as preferencesActionCreator from '../../../../actions/apppreferences.action';
import * as tmActionCreators from '../../../../actions/trendingmentions.action';
import * as settingsCreators from '../../../../actions/settings.action';
import * as filterCreators from '../../../../actions/filters.action';
import * as moment from 'moment';

class EventModal extends Component {

  pinCoinColumn = (TickerData) => {
    let { tmDetailPreferences, coin_column_preferences, preferences } = this.props;
    // console.log(tmDetailPreferences, TickerData)
    let tm_prefs = JSON.parse(JSON.stringify(tmDetailPreferences));
    let tminitlength = tm_prefs.length
    // let tminitlength = Object.keys(tm_prefs).length
    let SecNameArr = tm_prefs.map(item => item.SecurityName).filter(itm => (itm));
    if(SecNameArr.indexOf(TickerData.SecurityName) === -1) {
      if(tmDetailPreferences.length < 5) {
        let newPrefObj = TrendingMentionsHelper.newCoinDetailColumnPreference(TickerData);
        // console.log(newPrefObj)
        tm_prefs.unshift(newPrefObj)
        // tm_prefs = Object.assign(newPrefObj, tm_prefs)
        let searchData = {
          sec : [`${TickerData.SecurityName}`],
          startDate : moment().utcOffset(0).subtract(7,'days').format('YYYY-MM-DD HH:mm:ss'),
          endDate : moment().utcOffset(0).format('YYYY-MM-DD HH:mm:ss'),
          withCurated : newPrefObj.CDSearch_with_gray,
          withBlack : newPrefObj.CDSearch_with_black,
          withYellow : newPrefObj.CDSearch_with_yellow,
          withOrange : newPrefObj.CDSearch_with_orange,
          withRed : newPrefObj.CDSearch_with_red
        }
        let TickerFeedReqData = {
          RequestState: true, 
          InitialFeedRequest: searchData.startDate, 
          LastFeedRequest: searchData.endDate,
          LastFeedRequestEnd: searchData.endDate,
          InitialFeedResponse: searchData.endDate,
          LastFeedResponse: '', 
        }
        // tm_prefs[TickerData.SecurityName] = newPrefObj
        // console.log(tmDetailPreferences, tm_prefs, searchData)
        let PreferenceStrings = PreferenceHandler.CreatePreferencesStrings(preferences);
        let CoinCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(coin_column_preferences);
        let CoinDetailCoulmnStrings = TrendingMentionsHelper.CreateCCPreferencesStrings(tm_prefs, 'coindetails');
        // console.log(CoinDetailCoulmnStrings)
        let newPrefStr = `${PreferenceStrings.userPrefData.UserPreferencesString}${CoinCoulmnStrings}${CoinDetailCoulmnStrings}`
        // console.log(newPrefStr);
        PreferenceStrings.userPrefData.UserPreferencesString = newPrefStr;
        PreferenceStrings.userPrefData.UserName = this.props.userData.username;
        this.props.CloseTMDetails()
        if(tminitlength === 0) {
          // this.props.setUserPreferences(PreferenceStrings.userPrefData);
          this.props.setUserPreferences(PreferenceStrings.userPrefData, false, false, 'tmcd', '', false, TickerData);
          this.props.searchCoinDetailTickerEvents(searchData, 0, TickerData.SecurityName);
        } else {
          this.props.setUserPreferences(PreferenceStrings.userPrefData, false, false, 'tmcd', '', false, TickerData);
          // this.props.setTickerDetailsFeedReqDataCoinColumn(TickerFeedReqData, 0,  TickerData.SecurityName);
          this.props.searchCoinDetailTickerEvents(searchData, 0, TickerData.SecurityName);
        }
        let existingColumnName = `Coin_Column_details__0` 
        this.props.switchcolumnfocus(existingColumnName)
      } else {
        this.props.toggleAlert(
          true,
          'Limit Warning',
          "You've reached the limit of 5 topic views. Please close one in order to save this view"
        );
      }
    } else {
      let existingColumnName = `Coin_Column_details__${SecNameArr.indexOf(TickerData.SecurityName)}` 
      this.props.switchcolumnfocus(existingColumnName)
    }
  }

  filterEventTypeTree = (eventTypeChildren, eventTypes, action, eventFilter) => {
    // console.log(eventTypeChildren, eventTypes, action, eventFilter)
    eventTypes.forEach((event) => {
      if (eventTypeChildren.indexOf(event.EventTypeID) > -1) {
        event.selected = action === 'remove' ? false : true;
        if (action === 'remove') {
          if (eventFilter.indexOf(event.Name.toLowerCase()) > -1) {
            eventFilter.splice(eventFilter.indexOf(event.Name), 1);
          }
        } else {
          if (eventFilter.indexOf(event.Name) === -1) {
            eventFilter.push(event.Name);
          }
        }
        if (event.children.length > 0) {
          eventFilter = this.filterEventTypeTree(event.children, eventTypes, action, eventFilter);
        }
      }
    });
    return eventFilter;
  };

  orderTheSecurityFilterAsPerMentions = (stringArray) => {
    // console.log(this.props.securities, stringArray)
    var newRequiredArray = [];
    stringArray.forEach((item) => {
      // console.log(item)
      // console.log(this.props.securities.find((ticker) => ticker.Name === item))
      if(item === 'Binance Coin') { item = 'BNB'; }
      let RawTickerData = this.props.securities.find((ticker) => ticker.Name === item);
      if(typeof RawTickerData !== 'undefined') {
        newRequiredArray.push({
          tickerName: item,
          Scope: RawTickerData.Scope,
        });
      }
      
    });

    newRequiredArray = newRequiredArray.sort((a, b) => {
      return parseFloat(b.Scope) - parseFloat(a.Scope);
    });

    var newArray = [];
    newRequiredArray.forEach((item) => {
      newArray.push(item.tickerName);
    });

    return newArray;
  };

  componentDidUpdate() {
    
    if(typeof this.props.event !== 'undefined' && this.props.securitiesFilter.length && this.props.eventFilter.length && this.props.securities.length) {
      console.log('Event',this.props.event)
      console.log('Securities filter',this.props.securitiesFilter)
      console.log('Securities',this.props.securities)
      console.log('Eventtype filter',this.props.eventFilter)
      console.log('Eventtype',this.props.eventTypes)
      let addSec = false;
      let addEvnt = false;
      let corels = this.props.event.CorrelationResults
      let securitiesFilter = this.props.securitiesFilter.map(itm => itm).filter(itm => itm);
      let eventtypesFilter = this.props.eventFilter.map(itm => itm).filter(itm => itm);
      //adding Securities/Coin to Securities Filter String
      if(corels.length) {
        corels.forEach((corel, i) => {
          console.log(corel)
          if(i==0) {
            if(securitiesFilter.indexOf(corel.Security) === -1) {
              if(this.props.securities.length) {
                this.props.securities.forEach(itm => {
                  if(itm.Name === corel.Security) {
                    addSec = true;
                    securitiesFilter.push(itm.Name)
                  }
                })
              }
              
            }
          }
          
        })
      }
      //adding Event type to the Event Type Filter String
      let Eventtype = this.props.event.EventType
      console.log(Eventtype)
      let tmpevnttype = '';
      if(eventtypesFilter.length) {
        eventtypesFilter.forEach(itm => {
          if(itm.toLowerCase() === Eventtype.toLowerCase()) {
            console.log('in here', itm)
            tmpevnttype = itm;
          }
        })
        if(tmpevnttype === '') {
          addEvnt = true;
        }
        if(addEvnt) {
          this.props.eventTypes.forEach(event => {
            if(event.Name.toLowerCase() === Eventtype.toLowerCase()) {
              console.log('and in here too', event)
              
              if(!event.children.length) {
                eventtypesFilter.push(event.Name)
              } else if(event.children.length > 0) {
                eventtypesFilter.push(event.Name)
                eventtypesFilter = this.filterEventTypeTree(event.children, this.props.eventTypes, 'add', eventtypesFilter);
              }
            }
          })
        }
        
      }

      let securitiesdata = {
        UserName: this.props.userData.username,
        FilterString: this.orderTheSecurityFilterAsPerMentions(securitiesFilter).join(';'),
      };
      
      let evntdata = {
        UserName: this.props.userData.username,
        FilterString: eventtypesFilter.join(';'),
      };
      console.log('Add Securities',addSec)
      if(addSec) {
        console.log(securitiesdata)
        // console.log(this.props)
        this.props.updateSecuritiesFilter(securitiesdata);
      }
      console.log('Add Eventtypes',addEvnt)
      if(addEvnt) {
        console.log(evntdata)
        // console.log(this.props)
        this.props.updateEventSecurityFilter(evntdata);
      }
    }
  }

  render() {
    const { event, userdata } = this.props;
    // console.log(event)
    const TDeckDomRect = {
      bottom: 738,
      height: 650,
      left: 0,
      right: 1519,
      top: 88,
      width: 1519,
      x: 0,
      y: 88,
    };
    const colRect = {
      width: `${TDeckDomRect.width - 20}px`,
      left: `${0 + 5}px`,
      top: `${TDeckDomRect.top}px`,
    };

    

    return (
      <div className="watchlist-content justify-content-center text-center">
        <div className="btn tradeWindowClose text-white nopad marg-0" onClick={() => this.props.modalClose()}>
          <span className="pad10">
            <img className="tradeWindowClose-closeicon" src={`${APPPATH.ImageUrl}/button-close.svg`} alt="" />
          </span>
        </div>

        <Row className="marg-0 storymenuoptions-tabheader">
          <Col className="nopad">
            <div className="nav nav-tabs watchlist nav-fill  ">
              <div className={`nav-item fs16 active`}>
                <span>Event details</span>
              </div>
            </div>
          </Col>
        </Row>

        <Row
          className="cScroll scroll-notrack marg-0 justify-content-center  pad5 text-center"
          style={{ maxHeight: 300 }}
        >
          <Col lg={11} sm={11} xs={11} md={11} xl={11} className="justify-content-left text-left">
            <div className="userpref-list">
              {typeof event === 'undefined' && (
                <div>
                  <i className="fa fa-spinner fa-2x fa-spin storyloadindicator"></i>{' '}
                </div>
              )}
              {typeof event !== 'undefined' && (
                <SingleStory
                  {...this.props}
                  colRect={colRect}
                  userData={userdata}
                  currentEvent={event}
                  storyState={{
                    TabMode: false,
                  }}
                  story={event}
                  id={event.FeedId}
                  tdecktype="event"
                  pinCoinColumn={this.pinCoinColumn}
                />
              )}
            </div>
          </Col>
        </Row>
        <Row className={`marg-0 justify-content-center pointer pad5 text-center`}>
          {/* <Col lg={4} sm={4} xs={4} md={4} xl={4} className="text-left watchlistNavigatorButtons disabled "></Col> */}
          <Col lg={12} sm={12} xs={12} md={12} xl={12} className="text-center pad5 ">
            <span
              className="bronzeBtn pointer"
              onClick={() => {
                this.props.modalClose();
              }}
            >
              More Info
            </span>
          </Col>
          {/* <Col lg={4} sm={4} xs={4} md={4} xl={4} className="text-right watchlistNavigatorButtons"></Col> */}
        </Row>
      </div>
    );
  }
}

const mapDispatchToProps = {
  ...preferencesActionCreator,
  ...tmActionCreators,
  ...settingsCreators,
  ...filterCreators
};

const mapStateToComponent = (state) => {
  // console.log(state.trendingMentions.eventDetails.Event)
  return {
    userData: state.settings.userData,
    event: state.trendingMentions.eventDetails.Event,
    securities: state.filters.securities,
    securitiesFilter: state.filters.securitiesFilter,
    eventTypes: state.filters.eventTypes,
    eventFilter: state.filters.eventFilter,
    coin_column_preferences: state.trendingMentions.coin_column_preferences,
    tmDetailPreferences: state.trendingMentions.tmDetailPreferences,
    preferences: state.app_preferences.preferences,
  };
};

export default connect(mapStateToComponent, mapDispatchToProps)(EventModal);
