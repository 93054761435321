import React, { Component } from 'react'; 
import  { connect } from 'react-redux';
import * as url from '../helpers/url_helper';
import ReactNotifications from 'react-browser-notifications';
import UIfx from 'uifx';

class Notifications extends Component {
  constructor() {
    super();
    this.state = {
      notifications: {}
    }
    // let AudPath = `${process.env.PUBLIC_URL}/assets/notification-sounds/glass_ping.mp3`;
    // this.beep = new Audio(AudPath);
    // console.log(this.beep)
    this.notifySound = new UIfx('assets/notification-sounds/glass_ping.mp3', { volume: 0.9, throttleMs: 100});
  }

  componentWillMount() {
    if (!('Notification' in window)) {
      // alert('This browser does not support system notifications');
      return;
    }
    Notification.requestPermission();
   
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      ...this.state,
      notifications: nextProps.notifications
    },() => {
      if (!('Notification' in window)) {
        return;
      }
      if(this.state.notifications.has) {
        this.showNotifications()
      }
    })
  }

  showNotifications() {
    if(this.eventNotify.supported()){ 
      if(this.props.notifications.sms) {
        window.Mixpanel.MixpanelProps.MessageNotifications = this.props.notifications.body;
        window.Mixpanel.MixpanelProps.EventId = this.props.notifications.feedid;
        window.Mixpanel.MixpanelProps.FromUser = this.props.notifications.from;
      } else {
        window.Mixpanel.MixpanelProps.InterestedNotifications = this.props.notifications.body;
        window.Mixpanel.MixpanelProps.EventId = this.props.notifications.eventId;
      }
      
      
      window.Mixpanel.actions.identify(this.props.userData.username);
      let prefixStr;
      if(this.props.notifications.sms) {
        prefixStr = "Message Notifications";
      } else if(this.props.notifications.body.includes("BREAKING")) {
        prefixStr = "BREAKING Show Notification";
      }
      else{
        prefixStr = "UPDATE Show Notification";
      }
      window.Mixpanel.actions.track(prefixStr, window.Mixpanel.MixpanelProps).then(data => {
      window.Mixpanel.actions.people.set({
        UserName: this.props.userData.username
      });
      window.Mixpanel.actions.resetProp('InterestedNotifications');
      window.Mixpanel.actions.resetProp('FromUser');
      window.Mixpanel.actions.resetProp('EventId');
      })
      // console.log(this.state.notifications)
      if(this.state.notifications.canBeep) {
        this.notifySound.play(0.9)
      }
      
      this.eventNotify.show() 
         
    }
  }
  handleClick(event) {
    if(this.props.notifications.sms) {
      window.Mixpanel.MixpanelProps.MessageNotifications = this.props.notifications.body;
      window.Mixpanel.MixpanelProps.EventId = this.props.notifications.feedid;
      window.Mixpanel.MixpanelProps.FromUser = this.props.notifications.from;
    } else {
      window.Mixpanel.MixpanelProps.InterestedNotifications = this.props.notifications.body;
      window.Mixpanel.MixpanelProps.EventId = this.props.notifications.eventId;
    }
    window.Mixpanel.MixpanelProps.InterestedNotifications = this.props.notifications.body;
    window.Mixpanel.actions.identify(this.props.userData.username);
    let prefixStr;
    if(this.props.notifications.sms) {
      prefixStr = "Message Notifications clicked";
    } else if(this.props.notifications.body.includes("BREAKING")){
        prefixStr = "BREAKING Clicked Notification";
    } else {
      prefixStr = "UPDATE Clicked Notification";
    }
    window.Mixpanel.actions.track(prefixStr, window.Mixpanel.MixpanelProps).then(data => {
      window.Mixpanel.actions.people.set({
        UserName: this.props.userData.username
      });
      window.Mixpanel.actions.resetProp('InterestedNotifications');
      window.Mixpanel.actions.resetProp('FromUser');
      window.Mixpanel.actions.resetProp('EventId');
    })
    // eslint-disable-next-line no-restricted-globals
    parent.focus();
    window.focus();
    //this.close();
    this.eventNotify.close(event.target.tag);
  }

  render() {

    const { notifications } = this.state
    return (
      
          <ReactNotifications
            onRef={ref => (this.eventNotify = ref)} // Required
            title={notifications.title} // Required
            body={notifications.body}
            icon={`${url.ImageUrl}/logo.png`}
            tag=""
            timeout={notifications.sms ? '300000' : '120000'}
            interaction={notifications.sms ? true : ''}
            onClick={event => this.handleClick(event, notifications)}
          />
    )
  }
}

const mapStateToApp = (state) => {
  return {
    userData: state.settings.userData,
    notifications: state.settings.notifications,

  }
}

export default connect(mapStateToApp, null)(Notifications)