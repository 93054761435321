import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import Preferencelist from './preferencelist';
// import { connect } from 'react-redux';
// import * as appSettingsActionCreators from '../../actions/appSettingsAction';
// import * as moment from 'moment';

class PreferenceCategory extends Component {
  constructor() {
    super();
    this.state = {
      category: '',
      categoryData: '',
      itemView: true,
    };
  }

  componentWillMount() {
    // console.log(this.props.categoryData)
    this.setState({
      ...this.state,
      category: this.props.category,
      categoryData: this.props.categoryData,
    });
  }

  componentWillReceiveProps(nextProps) {
    // console.log(nextProps.categoryData)
    this.setState({
      ...this.state,
      category: nextProps.category,
      categoryData: nextProps.categoryData,
    });
  }
  handlePrefList = () => {
    this.setState({
      ...this.state,
      itemView: !this.state.itemView,
    });
  };

  render() {
    const { category, itemView } = this.state;
   
    let categoryText = '';
    let magBg = '';
    let magBgColor = '';
    switch (category) {
      case 'event_importance_filters':
        categoryText = 'Event Severity Filters';
        break;
      case 'notification':
        categoryText = 'Allow Notifications';
        break;
      case 'email_preferences':
        categoryText = 'Periodic Summary';
        break;
      case 'event_filters':
        categoryText = 'Event Details';
        break;
      case 'Red_Colored_Events':
        categoryText = 'High Magnitude (Red) Events';
        // categoryText = 'Red Headlines: Events with high potential to affect markets';
        magBg = 'mag-bg-high';
        magBgColor = 'preference-headline-light';
        break;
      case 'Yellow_Colored_Events':
        categoryText = 'Medium Magnitude (Orange) Events';
        // categoryText = 'Yellow Headlines: Events with medium potential to affect markets';
        magBg = 'mag-bg-medium';
        magBgColor = 'preference-headline-light';
        break;
      case 'Gray_Colored_Events':
        categoryText = 'Low Magnitude (Yellow) Events';
        // categoryText = 'Gray Headlines: Events with low potential to affect markets';
        magBg = 'mag-bg-default';
        // magBgColor = 'preference-headline-light';
        break;
      case 'Non_Active_Curated_Events':
        categoryText = 'Automatically Curated (Gray) Events [Not validated / may include duplicates, old, or mistakes]';
        // categoryText = 'Gray Headlines: Events with low potential to affect markets';
        magBg = 'mag-bg-gray';
        magBgColor = 'preference-headline-light';
        break;
      case 'localisation_preferences':
        categoryText = 'Localisation Preferences';
        break;
      case 'user_interface':
        categoryText = 'User Interface';
        break;
      case 'search_preferences':
        categoryText = 'Search Preferences';
        break;
      case 'coindetailsearch_preferences':
        categoryText = `Coin Detail Search Preferences`;
        break;
      case 'userDetails':
        categoryText = `User Details`;
        break;
      case 'cryptoEvents':
        categoryText = `Crypto Events`;
        break;
      case 'emailUpdates':
        categoryText = `Notifications`;
        break;
      case 'RealTimeEmails':
        categoryText = `Real-Time Notifications`;
        magBg = 'mag-bg-transparent';
        magBgColor = 'preference-headline-light';
        break;
      case 'SummaryEmails':
        categoryText = `Summary Emails`;
        magBg = 'mag-bg-transparent';
        magBgColor = 'preference-headline-light';
        break;
      default:
        break;
    }
    // console.log(category, categoryText)
    // if(category !== 'Red_Colored_Events') {
    return (
      <div className="bodypanel">
        <div className={`bodypanel-head ${itemView ? 'active' : ''} ${magBg} ${magBgColor}`}>
          <Row className={`marg-0 h-100 `}>
            <Col lg={1} sm={1} xs={1} md={1} xl={1} className="self-align-center preference-head-caret">
              <i
                className={`fa ${!itemView ? 'fa-caret-down text-blue1 ' : 'fa-caret-up'}`}
                onClick={this.handlePrefList}
              ></i>
            </Col>
            <Col lg={11} sm={11} xs={10} md={11} xl={11}>
              <span>
                {
                  /*category.replace(/_/g, ' ').split(' ').map((word) => word.substring(0,1).toUpperCase()+word.substring(1)).join(' ')*/ categoryText
                }
              </span>
            </Col>
          </Row>
        </div>
        <Preferencelist
          category={this.state.category}
          categoryData={this.state.categoryData}
          itemView={this.state.itemView}
          handleUserPreferences={this.props.handleUserPreferences}
          TimeZoneList={this.props.TimeZoneList}
          {...this.props}
        />
      </div>
    );
    // } else {
    //   return ('')
    // }
  }
}

export default PreferenceCategory;
