import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Col, Row } from 'react-bootstrap';

class IntegrityIndicator extends Component {

  constructor() {
    super()
    this.state = {
      integrities: ['1','2','3','4'],
      integrity: 1,
      magnitude: '',
      applyDefaultFill: true,
      high: 'rgba(18,170,69,1)',
      medium: 'rgba(249, 105, 14, 1)',
      low: 'rgba(216,216,0,1)',
      info: 'rgba(216,86,0,1)',
      defaultFill: '#37aaf8',
      blackFill: '#000000',
      grayFill: '#717274',
      whiteFill: '#e9edf6',
      yellowFill: '#ffcf95',
      orangeFill: '#ff7f27',
      redFill: '#d24242',
      backFill: 'rgba(0,0,0,0.44)'
    }
  }
  componentWillMount() {
    // console.log(this.props)
    let integrity = this.props.integrity || 1
    let magnitude = this.props.integrity || ''
    let high = this.props.high || this.state.high
    let medium = this.props.medium || this.state.medium
    let low = this.props.low || this.state.low
    let info = this.props.info || this.state.info
    let applyDefaultFill = this.props.applyDefaultFill
    let backFill = this.props.backFill || this.state.backFill
    this.setState({
      ...this.state,
      integrity : integrity,
      magnitude: magnitude,
      high : high,
      medium : medium,
      low : low,
      info : info,
      applyDefaultFill : applyDefaultFill,
      backFill : backFill,
    })
  }
  componentWillReceiveProps(nextProps) {
    let integrity = nextProps.integrity || 1
    let magnitude = nextProps.integrity || ''
    let high = nextProps.high || this.state.high
    let medium = nextProps.medium || this.state.medium
    let low = nextProps.low || this.state.low
    let info = nextProps.info || this.state.info
    let applyDefaultFill = nextProps.applyDefaultFill
    let backFill = nextProps.backFill || this.state.backFill
    this.setState({
      ...this.state,
      integrity : integrity,
      magnitude: magnitude,
      high : high,
      medium : medium,
      low : low,
      info : info,
      applyDefaultFill : applyDefaultFill,
      backFill : backFill
    })
  }

  createFill = () => {
    const { integrity, defaultFill, backFill } = this.state;
    let fill = {
      'fill1' : backFill,
      'fill2' : backFill,
      'fill3' : backFill,
      'fill4' : backFill,
    }
    if(integrity === 1) {
      fill.fill1 = defaultFill;
    }
    if(integrity === 2) {
      fill.fill1 = defaultFill;
      fill.fill2 = defaultFill;
    }
    if(integrity === 3) {
      fill.fill1 = defaultFill;
      fill.fill2 = defaultFill;
      fill.fill3 = defaultFill;
    }
    if(integrity === 4) {
      fill.fill1 = defaultFill;
      fill.fill2 = defaultFill;
      fill.fill3 = defaultFill;
      fill.fill4 = defaultFill;
    }
    // if(integrity.toLowerCase() === 'high' || integrity.toLowerCase() === 'medium' || integrity.toLowerCase() === 'low' || integrity.toLowerCase() === 'info' || integrity.toLowerCase() === 'gray') {
    //   fill.fill1 = applyDefaultFill ? defaultFill : this.state[`${integrity.toLowerCase()}`]
    // }
    // if(integrity.toLowerCase() === 'high' || integrity.toLowerCase() === 'medium' || integrity.toLowerCase() === 'low') {
    //   fill.fill2 = applyDefaultFill ? defaultFill : this.state[`${integrity.toLowerCase()}`]
    // }
    // if(integrity.toLowerCase() === 'high' || integrity.toLowerCase() === 'medium') {
    //   fill.fill3 = applyDefaultFill ? defaultFill : this.state[`${integrity.toLowerCase()}`]
    // }
    // if(integrity.toLowerCase() === 'high') {
    //   fill.fill4 = applyDefaultFill ? defaultFill : this.state[`${integrity.toLowerCase()}`]
    // }
    
    return fill;
  }

  MagnitudeFill = () => {
    const { magnitude, defaultFill, blackFill, grayFill, whiteFill, yellowFill, orangeFill, redFill, backFill } = this.state;
    let fill = {
      'fill1' : backFill,
      'fill2' : backFill,
      'fill3' : backFill,
      'fill4' : backFill,
      'fill5' : backFill,
    }
    if(magnitude === 'black') {
      // fill.fill1 = backFill;
      // fill.fill2 = backFill;
      // fill.fill3 = backFill;
      // fill.fill4 = backFill;
      // fill.fill5 = backFill;
    }
    if(magnitude === 'gray') {
      fill.fill1 = grayFill;
      // fill.fill2 = grayFill;
    }
    if(magnitude === 'white') {
      fill.fill1 = whiteFill;
      fill.fill2 = whiteFill;
      // fill.fill3 = whiteFill;
    }
    if(magnitude === 'yellow') {
      fill.fill1 = yellowFill;
      fill.fill2 = yellowFill;
      fill.fill3 = yellowFill;
      // fill.fill4 = defaultFill;
    }
    if(magnitude === 'orange') {
      fill.fill1 = orangeFill;
      fill.fill2 = orangeFill;
      fill.fill3 = orangeFill;
      fill.fill4 = orangeFill;
    }
    if(magnitude === 'red') {
      fill.fill1 = redFill;
      fill.fill2 = redFill;
      fill.fill3 = redFill;
      fill.fill4 = redFill;
      fill.fill5 = redFill;
    }
    // if(integrity.toLowerCase() === 'high' || integrity.toLowerCase() === 'medium' || integrity.toLowerCase() === 'low' || integrity.toLowerCase() === 'info' || integrity.toLowerCase() === 'gray') {
    //   fill.fill1 = applyDefaultFill ? defaultFill : this.state[`${integrity.toLowerCase()}`]
    // }
    // if(integrity.toLowerCase() === 'high' || integrity.toLowerCase() === 'medium' || integrity.toLowerCase() === 'low') {
    //   fill.fill2 = applyDefaultFill ? defaultFill : this.state[`${integrity.toLowerCase()}`]
    // }
    // if(integrity.toLowerCase() === 'high' || integrity.toLowerCase() === 'medium') {
    //   fill.fill3 = applyDefaultFill ? defaultFill : this.state[`${integrity.toLowerCase()}`]
    // }
    // if(integrity.toLowerCase() === 'high') {
    //   fill.fill4 = applyDefaultFill ? defaultFill : this.state[`${integrity.toLowerCase()}`]
    // }
    
    return fill;
  }

  render() {
    // let fill = this.createFill();
    let fill = this.MagnitudeFill();
    const { backFill } = this.state;
    let fillstroke = this.props.Theme === 'light' ? '#111111' : '#ffffff';
    return (

        <svg xmlns="http://www.w3.org/2000/svg" width="17.442" height="17.22" viewBox="0 0 17.442 17.22">


          <rect  width="2.444" height="3.666" transform="translate(0 9.554)" fill={`${fill.fill1}`} stroke={`${fill.fill1 === backFill ? fillstroke : fillstroke}`} strokeWidth={`0.1`}  />
          <rect  width="2.444" height="6.11" transform="translate(3.666 7.11)" fill={`${fill.fill2}`} stroke={`${fill.fill2 === backFill ? fillstroke : fillstroke}`} strokeWidth={`0.1`}  />
          <rect  width="2.444" height="8.554" transform="translate(7.332 4.466)" fill={`${fill.fill3}`} stroke={`${fill.fill3 === backFill ? fillstroke : fillstroke}`} strokeWidth={`0.1`}  />
          {/* <rect  width="2.444" height="11" transform="translate(10.998)" fill={`${fill.fill4}`} /> */}
          <rect  width="2.444" height="11" transform="translate(10.598, 2.1)" fill={`${fill.fill4}`} stroke={`${fill.fill4 === backFill ? fillstroke : fillstroke}`} strokeWidth={`0.1`}  />
          <rect  width="2.444" height="12.49" transform="translate(13.998, 0.5)" fill={`${fill.fill5}`} stroke={`${fill.fill5 === backFill ? fillstroke : fillstroke}`} strokeWidth={`0.1`}  />
        </svg>
      
    )
  }

}
const mapStateToProps = (state) => {
  return {
    Theme: state.app_preferences.preferences.user_interface.Theme,
  };
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(IntegrityIndicator);