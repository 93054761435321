import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Row, Col } from 'react-bootstrap';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import CorelTag from './coreltag';

import * as moment from 'moment';
import { isMobile } from 'react-device-detect';
import { APPPATH } from '../../modules/helper.module';

class StoryHeadLineText extends Component {
  constructor() {
    super();
    this.state = {
      HeadlineText: '',
      hdlineHeightManage: false,
      hdlineContainerHeight: '',
      BlinkNotifyEvent: false,
      NotifyEvent: false,
      RootChangeLogList: [],
      MagnitudeRate: '',
      EventReadStatus: false,
      hdlineTipHandle: false,
      isShowingTipInstance: false,
      iconStat: false,
      toggleExtraCoins: false,
    };
    this.deNotifyState = '';
    this.ttHandle = '';
    this.timeroutHandle = '';
    this.longTouchHandle = '';
    this.headlineWrap = '';
    this.SecTag = '';
    this.SecTagttHandle = '';
    this.extraSecTag = '';
    this.ExtraSecTagttHandle = '';
  }

  getHashAndDollarDataInBlue = (text) => {
    let newtext = text;
    var re = /(?:^|\W)#(\w+)(?!\w)/g,
      match,
      matches = [];
    while ((match = re.exec(text))) {
      matches.push(`#${match[1]}`);
      newtext = newtext.replace(`#${match[1]}`, `<span class="blue-font">#${match[1]}</span>`);
    }

    var re2 = /(?:^|\W)\$(\w+)(?!\w)/g,
      match2,
      matches2 = [];
    while ((match2 = re2.exec(text))) {
      if (isNaN(match2[1])) {
        matches2.push(`$${match2[1]}`);
        newtext = newtext.replace(`$${match2[1]}`, `<span class="blue-font">$${match2[1]}</span>`);
      }
    }
    return `<span>${newtext}</span>`;
  };

  componentWillMount() {
    let blinkNotifyEvent = false//  this.EventNotifyStatus(this.props.notifyEvent, this.props.rootChangeLogList);

    this.setState(
      {
        ...this.state,
        HeadlineText: this.props.headlineText,
        BlinkNotifyEvent: blinkNotifyEvent,
        NotifyEvent: this.props.NotifyEvent,
        RootChangeLogList: this.props.rootChangeLogList,
        MagnitudeRate: this.props.magnitudeRate,
        EventReadStatus: this.props.EventReadStatus,
        hdlineTipHandle: this.props.headlineTextTipHandler,
        EventActive: this.props.EventActive,
        EventCurated: this.props.EventCurated,
        // iconStat: this.props.TickerIconsList.indexOf(this.props.SecuritySymbol.toLowerCase()) > -1 ? true : false
      },
      () => {
        if (this.state.BlinkNotifyEvent) {
          this.deNotifyState = setTimeout(() => {
            this.setState({
              ...this.state,
              BlinkNotifyEvent: false,
            });
          }, 300000);
        }
      }
    );
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
    this.resize();

    if (this.props.tdecktype === 'event') {
      // if (!this.props.securityTicker.Security && !this.props.securityTicker.Symbol) {
      //   window.showCoinColumnFromText(this.props.Security, this.props.SecuritySymbol, false, this.props.tdecktype);
      // } else {
      //   window.showCoinColumnFromText(this.props.securityTicker.Security, this.props.securityTicker.Symbol, false, this.props.tdecktype);
      // }
    }
  }

  componentWillReceiveProps(nextProps) {
    this.resize();
    this.setState(
      {
        ...this.state,
        HeadlineText: nextProps.headlineText,
        NotifyEvent: nextProps.notifyEvent,
        RootChangeLogList: nextProps.rootChangeLogList,
        MagnitudeRate: nextProps.magnitudeRate,
        EventReadStatus: nextProps.EventReadStatus,
        EventActive: nextProps.EventActive,
        EventCurated: nextProps.EventCurated,
      },
      () => {
        if (this.state.BlinkNotifyEvent) {
          this.deNotifyState = setTimeout(() => {
            this.setState({
              ...this.state,
              BlinkNotifyEvent: false,
            });
          }, 300000);
        }
      }
    );
  }

  EventNotifyStatus = (NotifyStatus, RootChangeLogList) => {
    let BlinkNotify = this.state.NotifyEvent;
    if (typeof NotifyStatus !== 'undefined' && NotifyStatus) {
      if (RootChangeLogList.length) {
        // console.log(NotifyStatus, story.NotifyEvent)
        RootChangeLogList.forEach((RCLList) => {
          if (RCLList.UpdateTypeCategory.toLowerCase() === 'activate') {
            let RCLTime = moment(RCLList.RootChangeLogTime.toString());
            let now = moment();
            let duration = moment.duration(now.diff(RCLTime)).asSeconds();
            // console.log(duration)
            if (duration < 300) {
              BlinkNotify = true;
            }
          }
        });
      }
    }
    return BlinkNotify;
  };

  resize = () => {
    let dhlnmnght = this.state.hdlineContainerHeight;
    let dhlnmng = this.state.hdlineHeightManage;
    let HeadlineContainerHeight = ReactDOM.findDOMNode(this.refs['hdlineText']).getBoundingClientRect().height;

    if (parseInt(HeadlineContainerHeight) > 21) {
      dhlnmng = true;
    } // else { dhlnmng = false; }
    this.setState({
      ...this.state,
      hdlineContainerHeight: dhlnmnght,
      hdlineHeightManage: dhlnmng,
    });
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
    if (this.deNotifyState) clearTimeout(this.deNotifyState);
    clearTimeout(this.ttHandle);
    clearTimeout(this.timeroutHandle);
  }

  formatFeedText = (text) => {
    let feedMaxLines = !this.props.collapse ? 20 : document.documentElement.clientWidth > 767 ? 2 : 3;
    let LimitText = text;
    const ResponsiveEllipsis = responsiveHOC()(HTMLEllipsis);
    return <ResponsiveEllipsis unsafeHTML={LimitText} maxLine={feedMaxLines} ellipsisHTML={`...`} basedOn="words" />;
  };

  toggleHeadlineToolTip = (e, showState) => {
    let hdlineContainerRect = this.headlineWrap.getBoundingClientRect();
    let Pos = {
      left: hdlineContainerRect.left + 10,
      top: hdlineContainerRect.bottom,
    };
    const { HeadlineText } = this.state;
    if (this.ttHandle) {
      clearTimeout(this.ttHandle);
    }
    const containerStyleClass = `infoTip normal-left ${this.props.Theme === 'light' ? 'lightmode' : ''}`;
    if (showState) {
      if (!isMobile) {
        this.ttHandle = setTimeout(() => {
          this.props.togglePopup(showState, HeadlineText, Pos, containerStyleClass);
          window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
          window.Mixpanel.MixpanelProps.EventId = this.props.feedId;
          window.Mixpanel.MixpanelProps.Headline = this.props.headlineText;
          window.Mixpanel.MixpanelProps.ToolTipType = 'Event Headline';
          window.Mixpanel.actions.identify(this.props.userData.username);
          window.Mixpanel.actions.track(`Tooltip shown`, window.Mixpanel.MixpanelProps).then((data) => {
            window.Mixpanel.actions.people.set({
              UserName: this.props.userData.username,
            });
            window.Mixpanel.actions.resetProp('EventId');
            window.Mixpanel.actions.resetProp('Headline');
            window.Mixpanel.actions.resetProp('ToolTipType');
          });
        }, 1500);
      }
    } else {
      this.props.togglePopup(showState);
    }
  };
  handleCollapse = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.togglePopup(false);
    this.props.handleCollapse();
  };

  clickToSeeExtraCoins = (e, extraSecurityTags, feedId, toggleFlag = false) => {
    e.stopPropagation();
    var extraTagElement = document.getElementById(`extraTag${feedId}`);
    if (extraTagElement) {
      let ContainerRect = extraTagElement.getBoundingClientRect();
      let mousePosition = {
        left: ContainerRect.x - 100,
        top: ContainerRect.bottom + 15,
      };
      let balCRLList = [];
      extraSecurityTags.forEach((item, i) => {
        if (item.Impact !== 'evttime' && item.Impact !== 'feeds' && item.Impact !== 'usrReach') {
          let tmp = (
            <Row className={`tipItem marg-0 h-100`} key={i}>
              <CorelTag each={item} tagicon={this.tagIcon(item)} tagcontent={this.tagContent(item)} {...this.props} />
            </Row>
          );
          balCRLList.push(tmp);
        }
      });
      let balCRLCountList = <div className={``}>{balCRLList}</div>;
      let containerClass = `crl-tooltipMenu ${this.props.Theme === 'light' ? 'lightmode' : ''}`;
      // console.log(!this.state.toggleExtraCoins, balCRLCountList, mousePosition, containerClass)
      this.props.togglePopup(!this.state.toggleExtraCoins, balCRLCountList, mousePosition, containerClass);
      this.setState({
        toggleExtraCoins: !this.state.toggleExtraCoins,
      });
    }
  };
  toggleSectagtip = (status, tipText = false) => {
    if(!isMobile) {
      let ContainerRect = this.SecTag.getBoundingClientRect();
      let Pos = {
        left: ContainerRect.left,
        top: ContainerRect.bottom + 8,
      };
      if(this.SecTagttHandle) {
        clearTimeout(this.SecTagttHandle);
      }
      const containerStyleClass = `infoTip normal-left blue ${this.props.Theme === 'light' ? 'lightmode' : ''}`;
      if (status) {
        this.SecTagttHandle = setTimeout(() => {
          this.props.togglePopup(status, tipText, Pos, containerStyleClass);
          // window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
          // window.Mixpanel.MixpanelProps.EventId = this.props.FeedId;
          // window.Mixpanel.MixpanelProps.Headline = this.props.HeadlineText;
          // window.Mixpanel.MixpanelProps.ToolTipType = 'Resource Indicator';
          // window.Mixpanel.actions.identify(this.props.userData.username);
          // window.Mixpanel.actions.track(`Tooltip shown`, window.Mixpanel.MixpanelProps).then((data) => {
          //   window.Mixpanel.actions.people.set({
          //     UserName: this.props.userData.username,
          //   });
          //   window.Mixpanel.actions.resetProp('EventId');
          //   window.Mixpanel.actions.resetProp('Headline');
          //   window.Mixpanel.actions.resetProp('ToolTipType');
          // });
        }, 1500);
      } else {
        this.props.togglePopup(status);
      }
    }
    
  }
  securityTag = (security, securitySymbol, Sentiment, SentimentIndicator) => {
    // Sentiment !== null && typeof Sentiment !== 'undefined' && !isNaN(Sentiment) && SentimentIndicator !== ''
    let sentimentSymbol = Sentiment !== null && typeof Sentiment !== 'undefined' && !isNaN(Sentiment) && SentimentIndicator !== '' ? (
      <img src={`${APPPATH.ImageUrl+'/'+SentimentIndicator} `} className="pointer" alt="CrowdSense.ai" style={{width: '16px', marginTop: '-2px'}} onClick={() => ('')} />
    ) : ('')
    let sentimentbackground = Sentiment !== null && typeof Sentiment !== 'undefined' && !isNaN(Sentiment) && SentimentIndicator !== '' ?
      SentimentIndicator === 'neg_sentiment.png' ?
        'storysentimentred' :
      SentimentIndicator === 'pos_semtiment.png' ?
        'storysetimentgreen' : '' : ''
    let tiptext =  ''
    if(Sentiment !== null && typeof Sentiment !== 'undefined' && !isNaN(Sentiment) && SentimentIndicator !== '') {
      if(SentimentIndicator === 'neg_sentiment.png') {
        tiptext = 'Negative AI sentiment for event'
      } else if(SentimentIndicator === 'pos_semtiment.png') {
        tiptext = 'Positive AI sentiment for event'
      }
    } else {
      tiptext = 'Asset related to this event'
    }

    let EventActive = this.props.story.Active;
    let EventCurated = this.props.story.EventCurated;
    let MagnitudeRate = this.props.story.MagnitudeRate;
    let iframeclickable = false;
    if(MagnitudeRate >= 1) {
      if(Sentiment !== null && typeof Sentiment !== 'undefined' && !isNaN(Sentiment) && SentimentIndicator !== '') {
        iframeclickable = true
      }
    }

    // ${this.props.render === 'iframe' ? 'iframe' : ''} ${sentimentbackground}
    let iconSymbol =
      security !== '' ? (
        <span
          className={`tickhover margr2  padlr5tb2 bg-symbol ${this.props.Theme === 'light' ? 'lightmode' : ''} `}
          ref={(el) => (this.SecTag = el)}
          onMouseEnter={(e) => {
            this.toggleSectagtip(true, tiptext)
            // let ToolTipStyleData = {
            //   Arrow: {
            //     marginLeft: '10px',
            //   },
            //   ToolTip: {
            //     align: 'left',
            //   },
            // };
            // this.props.toggleOverlay(true, this.SecTag, tiptext, ToolTipStyleData);
          }}
          onMouseLeave={(e) => {
            // this.props.toggleOverlay(false);
            this.toggleSectagtip(false)
          }}
          onClick={(e) => {
            if(this.props.render !== 'iframe') {
              e.stopPropagation();
              window.showCoinColumnFromText(security, securitySymbol, this.props.listkey);
            } else if(this.props.render === 'iframe') {
              if(iframeclickable) {
                let Direction = '';
                if(Sentiment <= 3.5) { 
                  Direction = 'Sell';
                } else if(Sentiment >= 6.5) {
                  Direction = 'Buy'
                }
                e.stopPropagation();
                window.showCoinColumnFromText(security, securitySymbol, false, false,  Direction);
              }
            }
            
          }}
        >
          {/* <object
            className="coinicon imground"
            data={`${APPPATH.CoinIconUrl}/${
              security !== null ? security.toLowerCase().trim().replace(/ /g, '_') : ''
            }.png`}
            type="image/png"
          > */}
          
            <img 
              src={`${APPPATH.CoinIconUrl}/${
                security !== null ? security.toLowerCase().trim().replace(/ /g, '_') : ''
              }.png`} 
              className="coinicon" 
              alt="CrowdSense.ai" 
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = this.props.userData.usertype === 'None' ? `${APPPATH.ImageUrl}/logo_hdr.png` : this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin' ? `${APPPATH.ImageUrl}/companygenerallogo.png` : `${APPPATH.ImageUrl}/companygenerallogo.png`
              }}
            />
          
          {/* </object> */}
          {securitySymbol}
          {/* {sentimentSymbol} */}
        </span>
      ) : (
        ''
      );
    return iconSymbol;
  };

  extraSecurityCount = (corelsArray) => {
    var extrasecurityTags = [];
    for (let corelCount = 0; corelCount < corelsArray.length; corelCount++) {
      if (corelCount >= 1) {
        extrasecurityTags.push(corelsArray[corelCount]);
      }
    }
    //balCRLCountList = <div className={``}>{balCRLList}</div>;
    return extrasecurityTags;
  };

  tagContent = (item) => <span className="coreltext fs12  fssmsec">{item.Symbol}</span>;
  tagIcon = (Item) => (
    <span>
      {/* <object
        className="timelineIndicator imground"
        data={`${APPPATH.CoinIconUrl}/${
          Item.Security !== null ? Item.Security.toLowerCase().trim().replace(/ /g, '_') : ''
        }.png`}
        type="image/png"
      > */}
        <img 
          src={`${APPPATH.CoinIconUrl}/${
            Item.Security !== null ? Item.Security.toLowerCase().trim().replace(/ /g, '_') : ''
          }.png`} 
          className="timelineIndicator imground" 
          alt="CrowdSense.ai" 
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = this.props.userData.usertype === 'None' ? `${APPPATH.ImageUrl}/logo_hdr.png` : this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin' ? `${APPPATH.ImageUrl}/companygenerallogo.png` : `${APPPATH.ImageUrl}/companygenerallogo.png`
          }}
        />
      {/* </object> */}
    </span>
  );
  toggleExtraSectagtip = (status) => {
    if(!isMobile) {
      let ContainerRect = this.extraSecTag.getBoundingClientRect();
      let Pos = {
        left: ContainerRect.left - 15,
        top: ContainerRect.bottom + 8,
      };
      if(this.ExtraSecTagttHandle) {
        clearTimeout(this.ExtraSecTagttHandle);
      }
      let tipText = 'More Related Assets'
      const containerStyleClass = `infoTip normal-left blue ${this.props.Theme === 'light' ? 'lightmode' : ''}`;
      if (status) {
        this.ExtraSecTagttHandle = setTimeout(() => {
          this.props.togglePopup(status, tipText, Pos, containerStyleClass);
          // window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
          // window.Mixpanel.MixpanelProps.EventId = this.props.FeedId;
          // window.Mixpanel.MixpanelProps.Headline = this.props.HeadlineText;
          // window.Mixpanel.MixpanelProps.ToolTipType = 'Resource Indicator';
          // window.Mixpanel.actions.identify(this.props.userData.username);
          // window.Mixpanel.actions.track(`Tooltip shown`, window.Mixpanel.MixpanelProps).then((data) => {
          //   window.Mixpanel.actions.people.set({
          //     UserName: this.props.userData.username,
          //   });
          //   window.Mixpanel.actions.resetProp('EventId');
          //   window.Mixpanel.actions.resetProp('Headline');
          //   window.Mixpanel.actions.resetProp('ToolTipType');
          // });
        }, 1500);
      } else {
        this.props.togglePopup(status);
      }
    }
    
  }
  extraSecurityTag = (extraSecurity) => {
    const count = extraSecurity.length;
    var temp = (
      <span
        id={`extraTag${this.props.listkey}${this.props.feedId}`}
        ref={(el) => (this.extraSecTag = el)}
        onMouseEnter={(e) => {
          this.toggleExtraSectagtip(true)
          // let ToolTipStyleData = {
          //   Arrow: {
          //     marginLeft: '10px',
          //   },
          //   ToolTip: {
          //     align: 'left',
          //   },
          // };
          // this.props.toggleOverlay(true, this.SecTag, tiptext, ToolTipStyleData);
        }}
        onMouseLeave={(e) => {
          // this.props.toggleOverlay(false);
          this.toggleExtraSectagtip(false)
        }}
        onClick={(e) => {
          this.toggleExtraSectagtip(false);
          this.clickToSeeExtraCoins(e, extraSecurity, `${this.props.listkey}${this.props.feedId}`, this.state.toggleExtraCoins)
        }}
        className="tickhover margr2  padlr5tb2 bg-symbol"
      >
        {'+'}
        {count}
      </span>
    );
    return temp;
  };

  

  render() {
    const { HeadlineText, hdlineHeightManage, EventActive, EventCurated } = this.state;
    let HdlineCharLength = HeadlineText && HeadlineText.length > 0 ? HeadlineText.length : 0;
    // console.log(this.props.corels, this.props.securitiesFilter, this.props.SecurityName)
    // if(this.props.tdecktype === 'tmd' || this.props.tdecktype === 'search') {
    //   console.log(this.props.corels, this.props.securitiesFilter)
    // }
    // console.log(this.props.tdecktype)
    // let storyText = this.formatFeedText(this.getHashAndDollarDataInBlue(HeadlineText));
    let actualtext = HeadlineText.split('|')[0];
    let explanationtext = typeof HeadlineText.split('|')[1] !== 'undefined' && HeadlineText.split('|')[1] !== '' ? HeadlineText.split('|')[1] : false
    let Text = this.getHashAndDollarDataInBlue(actualtext);
    // console.log(Text)
    let storyText = '';
    if(explanationtext !== false) {
      storyText = `<div><div>${Text}</div><div class="fs13 font-normal-weight">${explanationtext}</div></div>`;
    } else {
      storyText = `<div><div>${Text}</div></div>`;
    }
    
    // !this.props.collapse
    let coins = '';
    let watchlistfilteredcorrelations = [];
    if (this.props.corels) {
      // if(this.props.story.FeedId === 49277420) {
      //   console.log(this.props.corels)
      // }
      this.props.corels.forEach((corels, i) => {
        // if(this.props.story.FeedId === 49277420) {
        //   console.log(corels, this.props.securitiesFilter)
        // }
        if(corels.Security !== null) {
          if(this.props.securitiesFilter.indexOf(corels.Security) > -1) {
            watchlistfilteredcorrelations.push(corels);
          } else if(this.props.SecurityName === corels.Security) {
            watchlistfilteredcorrelations.push(corels);
          } else if(this.props.securitiesFilter.indexOf(' Semrush') > -1 && corels.Security === 'Semrush') {
            watchlistfilteredcorrelations.push(corels);
          } 
        }
        
        // else {
        //   watchlistfilteredcorrelations.push(corels);
        // }
      })
      // if(this.props.story.FeedId === 49277420) {
        // console.log(watchlistfilteredcorrelations)
      // }
      
      if (watchlistfilteredcorrelations.length === 1 || this.props.render === 'iframe') {
        coins = <span>{this.securityTag(watchlistfilteredcorrelations[0].Security, watchlistfilteredcorrelations[0].CustomSymbol || watchlistfilteredcorrelations[0].Symbol, this.props.StorySentiment, this.props.StorySentimentIndicator)}</span>;
      }  else if (watchlistfilteredcorrelations.length > 1) {
        coins = (
          <span>
            {this.securityTag(watchlistfilteredcorrelations[0].Security, watchlistfilteredcorrelations[0].CustomSymbol || watchlistfilteredcorrelations[0].Symbol, this.props.StorySentiment, this.props.StorySentimentIndicator)}
            {/* {this.securityTag(watchlistfilteredcorrelations[1].Security, watchlistfilteredcorrelations[1].Symbol)} */}
            {this.extraSecurityTag(this.extraSecurityCount(watchlistfilteredcorrelations))}
          </span>
        );
      }
    }
    // console.log(this.props.collapse, HdlineCharLength)
    let needclipping = (HdlineCharLength > 100 && this.props.collapse) ? true : false
    // console.log(needclipping)
    return (
      <Col
        className={`align-self-center cursor-contextmenu storyHeadline ${this.props.Theme === 'light' ? 'lightmode' : ''}
          ${hdlineHeightManage ? 'lh1p4' : ''} 
          ${HdlineCharLength < 65 ? 'margtresp2' : ''}  
        `}
        style={{  height: needclipping ? `78px` : `auto` }}
        ref={(el) => (this.headlineWrap = el)}
        onClick={this.handleCollapse}
      >
        <div
          // onMouseEnter={(e) => this.toggleHeadlineToolTip(e, true)}
          // onMouseLeave={(e) => {
          //   this.toggleHeadlineToolTip(e, false);
          // }}
          // onWheel={(e) => this.toggleHeadlineToolTip(e, false)}
          ref={`hdlineText`}
          className={`overflowHidden`}
        >
          <div className="clampedStory pointer">
            {coins}
            <span dangerouslySetInnerHTML={{__html: storyText}}></span>
            {/* {storyText} */}
          </div>
        </div>
        
        <div className={`headlineoverlay ${needclipping ? 'hdlinebg-gradient': 'hdlinebg-transparent'} ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>
          
        </div>
         
        
      </Col>
    );
  }
}

export default StoryHeadLineText;
