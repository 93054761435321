import React, { Component } from 'react';
import { Row, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { watchlistLevels } from '../../../../data/watchlistLevelData';
import { SpanishTranslations, PortugueseTranslations, JapaneseTranslations, ArabicTranslations } from '../../../../static_data/translations';

class WatchListLevel extends Component {
  state = {
    levels: watchlistLevels,
  };
  componentDidMount = () => {
    const { levels } = this.state;
    if (this.props.userRole === "admin") {
      this.setState({
        levels: [...levels, {
          count: 3001,
          text: 'Add all',
          type: ''
        }]
      })
    }
  }
  changeTheSelectedItem = (selectedItem, selectedIndex, selectedtype) => {
    let newArray = this.state.levels;
    let count;
    let type;
    newArray.forEach((item, innerIndex) => {
      if (selectedIndex === innerIndex) {
        item.isSelected = true;
        count = item.count;
        type = item.type
      } else {
        item.isSelected = false;
      }
    });
    this.setState({
      levels: newArray,
      returnCount: count,
      returntype: type
    });
    this.props.updateSelectedLevel(count, type);
  };
  entering = (e) => {
    e.children[1].style.backgroundColor = '#37aaf8';
  };

  render() {
    return (
      <Row className="marg-0 justify-content-left" style={{ marginBottom: 10, marginLeft: '5px', cursor: 'pointer' }}>
        {this.state.levels.map((item, index) => {
          return (

            <Badge
              key={index}
              pill
              className={`level-badge ${item.text === 'Remove all' ? 'fs13' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''}`}
              onClick={() => {
                this.changeTheSelectedItem(item, index, item.type);
              }}
            >

              {
                this.props.lang && this.props.lang === 'spa' ? SpanishTranslations[item.text]
                  : this.props.lang && this.props.lang === 'por' ? PortugueseTranslations[item.text]
                    : this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations[item.text]
                      : this.props.lang && this.props.lang === 'ara' ? ArabicTranslations[item.text]
                        : item.text
              }
            </Badge>

          );
        })}
      </Row>
    );
  }
}

export default WatchListLevel;
