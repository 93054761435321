import React, { Component } from 'react'; 
import './cselect.css';



class CSelect extends Component {
  constructor() {
    super();
    this.state = {
      data : [],
      displayData: [],
      cselectValue: {
        text: '',
        id:''
      },
      showOptions: false,
      active: ''
    }
  }

  componentWillMount() {
    // console.log(this.props.data, this.props.active)
    let cselect;
    if(this.props.active !== '') {
      cselect = this.props.data.filter(tmz => { 
        return tmz.id === this.props.active 
      })
    } else {
      cselect = {
        text: '',
        id:''
      }
    }
    this.setState({
      ...this.state,
      data: this.props.data,
      displayData: this.props.data,
      cselectValue : this.props.active !== '' ? cselect[0] : cselect,
      active: this.props.active
    },() => {
      
    })
  }
  componentWillReceiveProps(nextProps) {
    
    let cselect;
    if(nextProps.active !== '') {
      cselect = nextProps.data.filter(tmz => { 
        return tmz.id === nextProps.active 
      })
    } else {
      cselect = {
        text: '',
        id:''
      }
    }
    this.setState({
      ...this.state,
      data: nextProps.data,
      cselectValue : nextProps.active !== '' ? cselect[0] : cselect,
      active: nextProps.active
    })
  }
  valueSelected = (e) => {
    let optionId = e.target.id;
    let Data = this.state.data;
    let selectedTimeZone = Data.filter(tmz => { 
      return tmz.id === optionId 
    })
    console.log(selectedTimeZone)
    this.setState({
      ...this.state,
      showOptions: false,
      cselectValue: selectedTimeZone[0]
    },() => {
      console.log(this.state.cselectValue)
      this.props.cselectApply(this.state.cselectValue.id);
    })
  }

  toggleshowoptions = () => {
    this.setState({
      ...this.state,
      showOptions: !this.state.showOptions,
      displayData: this.state.data
    })
  }

  showFilteredOptions = (e) => {
    let searchedOption = e.target.value;
    let Data = this.state.data;
    let showOptions = searchedOption !== ''? true : false
    let filteredlocations = Data.filter(dt => {
      return dt.text.toString().trim().toLowerCase().includes(searchedOption.toString().trim().toLowerCase())
    })
    this.setState({
      ...this.state,
      displayData: filteredlocations,
      showOptions: showOptions,
      cselectValue: {
        ...this.state.cselectValue,
        text: searchedOption,
        id: ''
      }
    })
  }

  render() {
    const { displayData, showOptions} = this.state;
    let optionView = displayData.map(dt => {
      return (
        <div id={dt.id} onClick={this.valueSelected} className="li" key={dt.id}>{dt.text}</div>
      )
    })
    return (
      <div>
        
        <input type="text" name="cselect" id="cselect" placeholder="" className="form-control cselect-input" onChange={this.showFilteredOptions}  value={this.state.cselectValue ? this.state.cselectValue.text : ''} />
        <i className="fa fa-caret-down cselect-icon" onClick={this.toggleshowoptions}></i>
        <div className={`cselect-dropdownlist ${showOptions ? '' : 'nodisp'}`}>
          <div className="ul">
            { optionView }
          </div>
        </div>

      </div>
    )
  }
}

export default CSelect