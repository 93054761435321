import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { APPPATH, TrendingMentionsHelper } from '../../../modules/helper.module';

import { connect } from 'react-redux';


class ErrorMsg extends Component {
  constructor(props) {
    super();
    this.state = {
        message: props.data
    }
    this.close = '';
  }

  componentDidMount() {
    this.close = setTimeout(() => {
        this.props.modalClose();
    },11000)
  }

  componentWillUnmount() {
    clearTimeout(this.close);
  }


//   (this.props.userData.usertype !== 'None' && (this.props.userData.usertype !== 'WUser' && this.props.userData.usertype !== 'WAdmin'))
  render() {
    let contactmail = (this.props.userData.usertype !== 'None' && (this.props.userData.usertype !== 'WUser' || this.props.userData.usertype !== 'WAdmin')) ? "info@algense.com" : "info@crowdsense.ai"
    return (
      <div className={`watchlist-content justify-content-center text-center ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>
        <div className="btn tradeWindowClose text-white nopad marg-0" onClick={() => {
          
          this.props.modalClose()
        //   this.props.maintenancemode(false);
        }}>
          <span className="pad10">
            <img className="tradeWindowClose-closeicon" src={`${APPPATH.ImageUrl}/button-close.svg`} alt="" />
          </span>
        </div>
        <Row className="marg-0 storymenuoptions-tabheader">
          <Col lg={12} sm={12} xs={12} md={12} xl={12} className="text-center align-items-to-center margin">
            <h5 className=''>An Error Occured !</h5>
            {/* <Logo noTitle={false} align={true} beta={false} userpackage={this.props.userData.userPackage.toLowerCase()} infreetrial={TrendingMentionsHelper.FreeSubscriptionFilter()} /> */}
          </Col>
          {/* <Col lg={12} sm={12} xs={12} md={12} xl={12} className="nopad" style={{marginTop: '-20px', marginBottom: '10px'}}>
            <div>
              <span className="">
                {
                  this.props.userData.usertype !== 'None' ? (
                    'All the information about your companies in one place'
                  ) : (
                    'All the information about your coins in one place'
                  )
                }
                
              </span>
            </div>
          </Col> */}
          <Col lg={12} sm={12} xs={12} md={12} xl={12} className="">
            {/* <div>
              <span className="WelcomeTitle">Let's Get Started!</span>
            </div> */}
            <div>
              <span className="WelcomeTitle-A"> 
                { this.state.message }
                <a href={`mailto:${contactmail}`}>please contact us</a> 
              </span>
            </div>
          </Col>
          
        </Row>
        <Row className={`marg-0 justify-content-center  pad5 text-center`}>
          <Col lg={3} sm={3} xs={3} md={3} xl={3} className={`text-left watchlistNavigatorButtons ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>
            {/* <span
              className="pointer"
              onClick={() => {
                window.Mixpanel.MixpanelProps.UserName = this.props.userdata.username;
                window.Mixpanel.actions.identify(this.props.userdata.username);
                window.Mixpanel.actions
                  .track('Clicking back to Intro video', window.Mixpanel.MixpanelProps)
                  .then((data) => {
                    window.Mixpanel.actions.people.set({
                      UserName: this.props.userdata.username,
                    });
                  });
                this.props.changeView('IntroVideo');
              }}
            >
              Back
            </span> */}
          </Col>
          <Col lg={6} sm={6} xs={6} md={6} xl={6} className="text-center pad5 ">
            {/* <div className={``}>
              <span className={`wizard-step-dot active`}></span>
              <span className={`wizard-step-dot`}></span>
              <span className={`wizard-step-dot`}></span>
              <span className={`wizard-step-dot`}></span>
              {
                this.props.userdata.usertype === 'None' ? (
                  <span className={`wizard-step-dot`}></span>
                ) : ('')
              }
              
            </div> */}
          </Col>
          <Col lg={3} sm={3} xs={3} md={3} xl={3} className={`text-right watchlistNavigatorButtons ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>
            <span
              className="pointer"
              onClick={() => {
                // window.Mixpanel.MixpanelProps.UserName = this.props.userdata.username;
                // window.Mixpanel.actions.identify(this.props.userdata.username);
                // window.Mixpanel.actions
                //   .track('Maintenance mode baneer closed', window.Mixpanel.MixpanelProps)
                //   .then((data) => {
                //     window.Mixpanel.actions.people.set({
                //       UserName: this.props.userdata.username,
                //     });
                //   });
                    
                    // this.props.modalClose()
                    // this.props.maintenancemode(false);
              }}
            >
              {/* Continue */}
            </span>
          </Col>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userData: state.settings.userData,
    username: state.settings.userData.username,
    Theme: state.app_preferences.preferences.user_interface.Theme,
  }
}


export default connect(mapStateToProps, null)(ErrorMsg);

