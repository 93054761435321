import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { APPPATH } from '../../../../modules/helper.module';
import * as settingsActionCreators from '../../../../actions/settings.action';

class VerifyEmail extends Component {
    constructor(props) {
      super();
      this.state = {
        _this: this,
      };
    }

    componentDidMount() {
        
    }

    // static getDerivedStateFromProps(nextProps, prevState) {
    //     return {
    //        null
    //     }
    // }
    resend = () => {
        
        this.props.onHide();
        setTimeout(() => {
            this.props.resendEmailVerification()
            window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
            window.Mixpanel.actions.identify(this.props.userName);
            window.Mixpanel.actions.track('Resend Verification Mail', window.Mixpanel.MixpanelProps).then((res) => {
                window.Mixpanel.actions.people.set({
                    UserName: this.props.userName,
                });
            });
        }, 2);
        // this.props.resendEmailVerification()
        
    }

    verifyLater = () => {
        this.props.onHide();
        // this.props.closeVerifyEmailBox(true)
        setTimeout(() => {
            // this.props.closeVerifyEmailBox(true);
            this.props.closeEmailVerificationBanner()
            window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
            window.Mixpanel.actions.identify(this.props.userName);
            window.Mixpanel.actions.track('Verify Email Later', window.Mixpanel.MixpanelProps).then((res) => {
                window.Mixpanel.actions.people.set({
                    UserName: this.props.userName,
                });
            });
        }, 2);
        
    }
    
    render() {
        
       
        
        return(
            <div className={`watchlist-content trd-window-brdr justify-content-center text-center ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>
                <div className="btn tradeWindowClose text-white nopad marg-0" onClick={this.verifyLater}>
                    <span className="pad10">
                    <img className="tradeWindowClose-closeicon" src={`${APPPATH.ImageUrl}/button-close.svg`} alt="" />
                    </span>
                </div>
                <Row className="marg-0 storymenuoptions-tabheader">
                    <Col className="nopad">
                        <div className="nav nav-tabs watchlist nav-fill  ">
                            <div className={`nav-item fs16 active`}>
                                <span>
                                
                                </span>
                                <span className="verifyemailcaption">{ `Verify Email` }</span>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="cScroll scroll-notrack marg-0 justify-content-left  pad5 text-left " style={{ maxHeight: 400 }}>
                    <Col lg={12} sm={12} xs={12} md={12} xl={12} className="justify-content-center text-center padb15 fs14"> 
                        For a free trial of Crowdsnese Premium, please press Verify in the email we recently sent you at <span style={{color: '#50c0ff'}}><b>{this.props.userData.username}</b></span>
                    </Col>
                    <Col lg={6} sm={6} xs={6} md={6} xl={6} className="justify-content-left text-left "> 
                    <a onClick={this.resend} className="btn btn-fb btn-fbb">
                        Send Again 
                    </a>
                    </Col>
                    <Col lg={6} sm={6} xs={6} md={6} xl={6} className="justify-content-right text-right "> 
                        <a onClick={this.verifyLater} className="btn btn-fb btn-fbs">
                            Verify Later 
                        </a>
                    </Col>
                </Row>
                
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
       userData: state.settings.userData,
       Theme: state.app_preferences.preferences.user_interface.Theme,
    }
}
  
const mapDispatchToProps = {
    ...settingsActionCreators
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail)