import React, { Component } from 'react'; 
import { connect } from 'react-redux';
import * as filtersActionCreators from '../../actions/filters.action';

class LocationListItem extends Component {

  constructor() {
    super()
    this.state = {
      active: false,
      selected: false,
      text: '',
      type: '',
      parent: ''
    }
  }
  componentWillMount() {
    this.setState({
      ...this.state,
      text : this.props.text,
      type : this.props.type,
      selected : this.props.selected,
      parent: this.props.parent
    },() => {
      // if(this.props.parent !== '') {
      //   if(!this.props.locations[`${this.props.text}`] || !this.props.locations[`${this.props.text}`].length) {
      //     this.props.getLocationByContinent(this.state.text)
      //   }
      // }
    })
  }

  componentWillReceiveProps(nextProps) {
    let should = this.props.selected !== nextProps.selected
    if(should) {
      this.setState({
        ...this.state,
        selected: nextProps.selected,
        text : nextProps.text,
        type : nextProps.type,
        parent: nextProps.parent
      })
    }
  }

  handleFilterChange = (e) => {
    this.setState({
      ...this.state,
      selected: !this.state.selected
    },() => {
      window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
      window.Mixpanel.MixpanelProps.Setting = 'Locations';
      
      window.Mixpanel.MixpanelProps.Location = this.state.text;
      window.Mixpanel.actions.identify(this.props.userData.username);
      let actionTrackerText = this.state.selected ? `Selected ${this.state.text}` : `Unselected ${this.state.text}`
      window.Mixpanel.actions.track(actionTrackerText, window.Mixpanel.MixpanelProps).then(data => {
        window.Mixpanel.actions.people.set({
          UserName: this.props.userData.username
        });
        delete window.Mixpanel.MixpanelProps.Location
      })
      this.props.manageFilters(this.state.selected, this.state.text, this.state.type, this.state.parent)
    })
    
  }

  handleClick = (e) => {
    
    this.setState({
      ...this.state,
      active : !this.state.active
    },() => {
      // console.log(this.state)
      window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
      window.Mixpanel.MixpanelProps.Setting = 'Locations';
      window.Mixpanel.MixpanelProps.Location = this.state.text;
      window.Mixpanel.actions.identify(this.props.userData.username);
      let actionTrackerText = this.state.active ? `Expanded ${this.state.text}` : `Minimised ${this.state.text}`
      window.Mixpanel.actions.track(actionTrackerText, window.Mixpanel.MixpanelProps).then(data => {
        window.Mixpanel.actions.people.set({
          UserName: this.props.userData.username
        });
      })
    })
  }

  directChildStatus = (locations, childStatusArr = []) => {
    locations.forEach((loc) => {
      childStatusArr.push(loc.selected)
      if(Object.keys(this.props.locations).indexOf(loc.name) > -1) {
        this.directChildStatus(this.props.locations[loc.name], childStatusArr)
      }
    })
    return childStatusArr
  }
  recursiveChildStatusCount = (locations, response = {childCount:0,selectedCount:0}) => {
    if(locations.length) {
      locations.forEach((loc) => {
        response.childCount++;
        if(loc.selected) {
          response.selectedCount++;
        }
        if(Object.keys(this.props.locations).indexOf(loc.name) > -1) {
          this.recursiveChildStatusCount(this.props.locations[loc.name], response)
        }
      })
    }
    return response
  }
  recursiveChildren = (locations, response) => {
    let loctypeviews = []; 
    let loctypeviewsArr = [];
    let childStatusArr =  []; let recursiveStatusCount = {}; let childStatus = '';
    if(locations.length) {
      childStatusArr = this.directChildStatus(locations)
      if(childStatusArr.indexOf(true) === -1) {
        childStatus = 'ALLFALSE';
      } else if(childStatusArr.indexOf(false) === -1) {
        childStatus = 'ALLTRUE';
      } else {
        childStatus = 'ALLMIXED';
      }

      recursiveStatusCount = this.recursiveChildStatusCount(locations)
      for( let item of locations) {
        loctypeviewsArr.push(item)
        loctypeviews.push(<LocationListItem key={item.name}  text={item.name} type={item.type} selected={item.selected} parent={item.parent} CountriesByContinent={this.props.CountriesByContinent} groupindex={this.props.groupIndex} akey={item.name} indexChange={this.props.indexchange} getLocationByContinent={this.props.getLocationByContinent} locations={this.props.locations} manageFilters={this.props.manageFilters} userData={this.props.userData} />)
      }
    }
    response = { 'loctypeviews' : loctypeviews, 'childStatus': childStatus, 'statusCount' : recursiveStatusCount }
    return response
  }

  render() {
    const { text, locations } = this.props;
    const { active, selected, type, parent } = this.state
    let detailTree = []; let detailTreeArr = {}; let childStatus = ''; let statusCount = ''  
    if(locations[`${text}`] && locations[`${text}`].length) {
      detailTreeArr = this.recursiveChildren(locations[`${text}`], detailTreeArr) 
      detailTree = detailTreeArr.loctypeviews ? detailTreeArr.loctypeviews : [];
      childStatus = detailTreeArr.childStatus ? detailTreeArr.childStatus : '';
      statusCount = `${detailTreeArr.statusCount.selectedCount} / ${detailTreeArr.statusCount.childCount}`;
    }
    
    
    return (
      <div className={` settings-list-item ${parent === '' && text === 'No Location' ? 'nodisp':''}`}>
        <div className={`settings-item-title marg-0  ${active ? 'selected' : ''}`}>
          <span className={`${!detailTree.length ? 'nodisp':''}`}>
            <i className={`fa ${!active ? 'fa-caret-down' : 'fa-caret-up selected'} float-left open-but`} onClick={this.handleClick}></i>
          </span>
          {
            detailTree.length ? (
              <span className={`sm-fs12 ${type === 'children' ? 'padl25':'' } `} onClick={this.handleClick}>
                { text.trim() }
              </span>
            ) : (
              <span className={`sm-fs12 ${type === 'children' ? 'padl25':'' } `}>
                { text.trim() }
              </span>
            )

          }
          {
            detailTree.length ? (
              <span className='selectedFilterStatus' onClick={this.handleClick} >{detailTree.length ? ` (${statusCount} selected)` : ''}</span>
            ) : (
              <span className='selectedFilterStatus' >{detailTree.length ? ` (${statusCount} selected)` : ''}</span>
            )
          }
          
          
            <span className="float-right">
              <label className="loc-switch float-right">
                <input type="checkbox" checked={`${selected? 'checked': ''}`} onChange={this.handleFilterChange} value={text} />
                <span className={`loc-slider round  ${childStatus === 'ALLMIXED'? 'mixedSelect' : ''}`}></span>
              </label>
            </span>
          
        </div>
        {
          locations[`${text}`] && locations[`${text}`].length ? (
            <div className={`settings-item-content ${!active ? 'nodisp' : '' }`}>
              <div className="securityItem" >
                { detailTree }
              </div>
              
            </div>
          ) : ('')
        }
      </div>
    )
  }
} 

const mapStateToProps = (state) => {
  return {
    userData: state.settings.userData,
    continentFilteredLocations: state.filters.continentFilteredLocations
  }
}

export default connect(mapStateToProps, filtersActionCreators)(LocationListItem)