import appGlobalState from '../appconfig';

const ChartReducer = (state = { ...appGlobalState.charts }, action) => {

    if(action.type === 'TICKERFEEDS') {
        // console.log(action.payload)
        return {
            ...state,
            ChartFeeds: action.payload,
        }
    }
    
    return state
}

export default ChartReducer