export class CorrelationsExpert {
  constructor(Options) {
    this.corels = Options.CorrelationResults || false;
    this.tagContainerWidth = null;
    this.corelTagsSizes = [];
    this.ActualCorrelations = []
    this.headers = Options.headers || false;
    this.username = Options.username || false;
  }

  setCorrelations = (calculateLimit = false, currentLimit = 0, resp = {}) => {
    // console.log(this.corels)
    resp = {
      correlations: this.corels || [],
      corelList: this.ActualCorrelations || [],
      coreltaglimit: this.ActualCorrelations.length || 0,
      direct: [],
      indirect: []
    }
    if(typeof this.corels !== 'undefined' && this.corels) {
      let correlations = this.corels;
      let tmpnewCorrelations = this.handleCorrelations(correlations);
      let newCorrelations = this.corelSpreadFilter(tmpnewCorrelations);
      let dircorel = [];
      let indircorel = [];
      let nacorel = [];
      if(newCorrelations.length) {
        newCorrelations.map((each) => {
          if(each.Impact.toLowerCase() === 'direct') { dircorel.push(each) }
          if(each.Impact.toLowerCase() === 'indirect') { indircorel.push(each) }
          if(each.Impact.toLowerCase() !== 'indirect' && each.Impact.toLowerCase() !== 'direct') { nacorel.push(each) }
          return null;
        })
      }
      
      this.ActualCorrelations = dircorel.concat(indircorel,nacorel);
      if(calculateLimit) {
        if(this.corelTagsSizes.length && this.tagContainerWidth) {
          resp = {
            correlations: this.corels,
            corelList: this.ActualCorrelations,
            coreltaglimit : this.ActualCorrelations.length,
            direct: dircorel,
            indirect: indircorel
          }
          let limit = this.corelTagLimit(currentLimit)
          resp.coreltaglimit = limit;
          return resp;
          
        }
      } else {
        resp = {
          correlations: this.corels,
          corelList: this.ActualCorrelations,
          coreltaglimit: this.ActualCorrelations.length,
          direct: dircorel,
          indirect: indircorel
        }

        return resp;
      }

    }
    return resp;
  }

  handleCorrelations = (correlations, newCorels = []) => {
    newCorels = correlations.length ? (
      correlations.map(item => {
      
        if(item.Security) {
          let secs = item.Security.split(';');
          if(secs.length > 1) {
            return this.recursedCorrelations(secs, item, 'Security', []);
            
          } else {
            return item;
          }
        } else {
          let secs = item.SecutiryType ? item.SecutiryType.split(';') : [];
          if(secs.length > 1) {
            return this.recursedCorrelations(secs, item, 'SecutiryType', []);
           
          } else {
            return item;
          }
        }

      })
    ) : ([])
    // console.log(newCorels)
    return newCorels;
  }
  recursedCorrelations = (securityArr, correlationItem, type, response = []) => {
    
    for(let sec of securityArr) {
      if(sec) {
        let newItem = {
          SecutiryType : null,
          Security : "",
          Impact : "",
          Symbol : null,
          Link : null,
          Description: ''
        };

        // let newItem = correlationItem;
        // response.push(newItem);
        newItem[`${type}`] = sec;
        newItem["Impact"] = correlationItem.Impact;
        newItem["Description"] = correlationItem.Description;
        if(type === 'Security') {
          newItem['SecutiryType'] = correlationItem.SecutiryType;
        }
        response.push(newItem);
      }
      
    }
    return response;
  }

  corelSpreadFilter = (tmp, resp = []) => {
    let filt = [];
    for(let row of tmp) {
        
      if(Array.isArray(row)) {
        for(let subRow of row) {
          
          if(subRow.Security && filt.indexOf(subRow.Security.trim().toLowerCase()) === -1) {
            resp.push(subRow)
            filt.push(subRow.Security.trim().toLowerCase())
          } else if(!subRow.Security) {
            if(subRow.SecutiryType && filt.indexOf(subRow.SecutiryType.trim().toLowerCase()) === -1) {
              resp.push(subRow)
              filt.push(subRow.SecutiryType.trim().toLowerCase())
            }
          }
          
        }
      } else {
        if(row.Security && filt.indexOf(row.Security.trim().toLowerCase()) === -1) {
          resp.push(row)
          filt.push(row.Security.trim().toLowerCase())
        } else if(!row.Security) {
          if(row.SecutiryType && filt.indexOf(row.SecutiryType.trim().toLowerCase()) === -1) {
            resp.push(row)
            filt.push(row.SecutiryType.trim().toLowerCase())
          }
        }
      }
      
    }
    return resp;
  }

  setTagSizes = async (TagDOMArray = []) => {
    if(TagDOMArray.length === this.ActualCorrelations.length) {
      this.corelTagsSizes = TagDOMArray.length ? TagDOMArray.map(itm => (itm.clientWidth)) : [];
      return true;
    }
    return false;
  }

  setTagContainerDOMSize = async (TagContainerDOMArray = []) => {
    if(TagContainerDOMArray.clientWidth) {
      this.tagContainerWidth = TagContainerDOMArray.clientWidth;
      return true;
    }
    return false
  }

  corelTagLimit = (currentLimit) => {
    // console.log(this.corelTagSizes)
    let sizeDataOFTags = [];
    let containerWidth = 0;
    let limitedTagNumber = 0;
    let tottagWidth = 0;
    if(this.ActualCorrelations.length) {
      for(let i = 0; i <= this.corelTagsSizes.length ; i++) {
        // console.log(this.corelsArr[i] ? this.corelsArr[i].clientWidth : 'undefined')
        if(this.corelTagsSizes[i]) { 
          sizeDataOFTags.push(this.corelTagsSizes[i]);
        }
        
      }
    }
    // let sizeDataOFContainer = this.crlcontainer;
    containerWidth = this.tagContainerWidth;
    let allowedTagWidth = containerWidth*(99/100);
    let rightMarginForEactTag = 3;
    for(let i=0;i<sizeDataOFTags.length;i++) {
      
      if(tottagWidth < allowedTagWidth && (tottagWidth+rightMarginForEactTag+sizeDataOFTags[i]) < allowedTagWidth) {
        tottagWidth = tottagWidth+sizeDataOFTags[i]+rightMarginForEactTag
      } else {
        
        limitedTagNumber = i;
        
        break;
      }
      
    }
    return limitedTagNumber ? limitedTagNumber : currentLimit;
  }
}