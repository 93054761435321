import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import './cpinput.css';
import CSelect from '../crowdsenseSelect/cselect';
import * as ISDCodes from '../../static_data/isd_phonecodes';

class CPinput extends Component {
  constructor() {
    super();
    this.state = {
      isd: '',
      phone: '',
      isdError: false,
      phoneError: false,
    };
    this.isdCodes = [];
    let codeArr = [];
    ISDCodes.Codes.forEach((code) => {
      codeArr.push(code.dial_code.slice(1));
    });
    // let sortedCodeArr = codeArr.sort((a,b) => {
    //   return parseInt(a) - parseInt(b)
    // });
    // console.log(sortedCodeArr)
    let uniquecodeArr = codeArr.filter((a, b) => codeArr.indexOf(a) === b);
    uniquecodeArr = uniquecodeArr.sort((a, b) => {
      return parseFloat(a) - parseFloat(b);
    });
    uniquecodeArr.forEach((uc) => {
      let obj = {
        text: `+${uc}`,
        id: `+${uc}`,
      };
      this.isdCodes.push(obj);
    });
  }

  componentWillMount() {
    if (this.props.data !== '') {
      this.cpinputstate(this.props.data);
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== '') {
      this.cpinputstate(nextProps.data);
    }
  }

  cpinputstate = (data) => {
    let phone = data.split('-')[1];
    let isd = data.split('-')[0];
    this.setState({
      ...this.state,
      isd: isd,
      phone: phone,
    });
  };

  validate = (isd, phone) => {
    // const { isd, phone } = this.state;
    console.log(isd, phone)
    let res = {
      isdError: false,
      phoneError: false,
    };
    if (isNaN(parseInt(isd.replace('+', ''))) || isd === '') {
      res.isdError = true;
    }
    if (typeof phone === 'undefined' || phone === '' || phone.toString().length < 8 || isNaN(phone)) {
      res.phoneError = true;
    }
    return res;
  };

  isdcodeSelect = (isdObj) => {
    // let isd = isdObj.id;
    // let regex = /^(\+?\d{1,3}|\d{1,4})$/gm
    // let match = isd.match(regex)
    // console.log(match)
    // console.log(isdObj)
    let validated = this.validate(isdObj, this.state.phone);
    // console.log(validated)
    this.setState(
      {
        ...this.state,
        isd: isdObj,
        isdError: validated.isdError,
        phoneError: validated.phoneError,
      },
      () => {
        if (!validated.isdError && !validated.phoneError) {
          this.handleChange();
        }
      }
      // () => {
        
      //   this.setState(
      //     {
      //       ...this.state,
            
      //     },
          
      //   );
      // }
    );
  };

  handlePhone = (e) => {
    let phone = e.target.value;
    let validated = this.validate(this.state.isd, e.target.value);
    this.setState(
      {
        ...this.state,
        phone: phone,
        isdError: validated.isdError,
        phoneError: validated.phoneError,
      },
      () => {
        if (!validated.isdError && !validated.phoneError) {
          this.handleChange();
        }
      }
      // () => {
      //   let validated = this.validate();
      //   this.setState(
      //     {
      //       ...this.state,
            
      //     },

      //   );
      // }
    );
  };

  handleChange = () => {
    const { isd, phone } = this.state;
    let newphone = `${isd}-${phone}`;
    this.props.cinputApply(newphone);
  };

  render() {
    const { isd, phone } = this.state;

    return (
      <Row>
        <Col lg={3} sm={3} xs={3} md={3} xl={3} className="padright0">
          <CSelect data={this.isdCodes} cselectApply={this.isdcodeSelect} active={isd} key={`ISDCode`} />
          <span className={`hasError ${this.state.isdError ? '' : 'nodisp'}`}>Please select a valid code</span>
        </Col>
        <Col lg={9} sm={9} xs={9} md={9} xl={9} className="padl3">
          <input type="text" name={`phone`} id={`phone`} value={phone} className="form-control cpinput" onChange={this.handlePhone}
          />
          <span className={`hasError ${this.state.phoneError ? '' : 'nodisp'}`}>Please enter a valid phone number</span>
        </Col>
      </Row>
    );
  }
}

export default CPinput;
