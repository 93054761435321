import React, { Component } from 'react';
import { APPPATH } from '../../modules/helper.module';
import { connect } from 'react-redux';

class InitialColumnLoader extends Component {
    constructor() {
      super()
      this.state = {
      }
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.initialcolumnloadcounter < nextProps.initialcolumnloadcounter) {
            nextProps.columnLoadUpdate(nextProps.initialcolumnloadcounter);
        }
    }
    render () {
        return('');
    }
}

const mapStateToProps = (state, ownProps) => {
    // console.log(state.trendingMentions.tmDetailPreferencesDataStore)
    
    return {
        initialcolumnloadcounter: state.settings.initialcolumnloadcounter,
    };
  };
  
  const mapDispatchToProps = {
    // setcolumnlist
  };

  export default connect(mapStateToProps, mapDispatchToProps)(InitialColumnLoader);
