import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { APPPATH, TrendingMentionsHelper } from '../../../../modules/helper.module';
import * as tmActionCreators from '../../../../actions/trendingmentions.action';
import { isMobile } from 'react-device-detect';

class EachExchangeAndRate extends Component {
  constructor(props) {
    super();
    this.state = {
      data: {
        Exchange: props.dataObj.Exchange,
        ExchangeURL: props.dataObj.Exchange.toLowerCase() === 'binance' ? 'https://accounts.binance.com/en/register?ref=331002527' : props.dataObj.ExchangeURL,
        Coin: props.dataObj.Coin,
        Price: props.dataObj.Price
      },
      columns: props.columns,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      data: {
        Exchange: nextProps.dataObj.Exchange,
        ExchangeURL: nextProps.dataObj.Exchange.toLowerCase() === 'binance' ?  'https://accounts.binance.com/en/register?ref=331002527' : nextProps.dataObj.ExchangeURL,
        Coin: nextProps.dataObj.Coin,
        Price: nextProps.dataObj.Price
      },
      columns: nextProps.columns,
    };
  }

  redirectToExchange = () => {
    const { data } = this.state;
    window.Mixpanel.MixpanelProps.UserName = this.props.userName;
    window.Mixpanel.MixpanelProps.Exchange = data.Exchange;
    window.Mixpanel.MixpanelProps.Coin = data.Coin;
    window.Mixpanel.MixpanelProps.Price = data.Price;
    window.Mixpanel.actions.identify(this.props.userName);
    window.Mixpanel.actions.track(`Trade Redirected to ${data.Exchange}`, window.Mixpanel.MixpanelProps).then((res) => {
      window.Mixpanel.actions.people.set({
        UserName: this.props.userName,
      });
      window.Mixpanel.actions.resetProp('Exchange');
      window.Mixpanel.actions.resetProp('Coin');
      window.Mixpanel.actions.resetProp('Price');
      // window.open(`${data.ExchangeURL}`);
      const urlSearchParams = new URLSearchParams(this.props.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      let FromMobile = params && Object.keys(params).length !== 0 && Object.getPrototypeOf(params) === Object.prototype && typeof params.mobileapp !== 'undefined' && params.mobileapp === 'true';
      if(data.Exchange === 'huobi') {
        data.ExchangeURL = 'https://www.huobi.com/en-us/v/register/double-invite/?inviter_id=11345710&invite_code=7vwn3223'
      }
      if(FromMobile) {
        window.location.href = data.ExchangeURL;
      } else {
        window.open(`${data.ExchangeURL}`, '_blank');
      }
      
    });
  };
  entering = (e) => {
    e.children[1].style.backgroundColor = '#37aaf8';
  };
  render() {
    const { columns, data } = this.state;
    console.log(data);
    return (
      <Col
        lg={columns}
        sm={columns}
        xs={12}
        md={columns}
        xl={columns}
        className="nopad ustify-content-center text-center"
      >
        <div className="exchange-pill">
          {/* <div className="exchangePrice">{TrendingMentionsHelper.currencyFormat(data.Price, '$')}</div> */}
          <div style={{ fontSize: 10, color: '#BFBFBF' }}>traded on</div>
          <div style={{ padding: '15px 0px 15px 0px' }}>
            <img className="exchangeIcon" src={`${APPPATH.ExchangeIconUrl}/${data.Exchange}.png`} />
          </div>
          {!isMobile ? (
            <OverlayTrigger
              trigger="hover"
              placement="bottom"
              onEntering={this.entering}
              overlay={<Tooltip>{'Click to go to the exchange to register or trade'}</Tooltip>}
            >
              <div>
                {/* href={`${data.ExchangeURL}`} target="_blank" */}
                <a onClick={this.redirectToExchange} className="btn btnTrd-Redirect">
                  Check Price
                </a>
              </div>
            </OverlayTrigger>
          ) : (
            <div>
              {/* href={`${data.ExchangeURL}`} target="_blank" */}
              <a onClick={this.redirectToExchange} className="btn btnTrd-Redirect">
                Check Price
              </a>
            </div>
          )}
        </div>
      </Col>
    );
  }
}

export default EachExchangeAndRate;
