import React, { Component } from 'react'; 

class TNC extends Component {
  constructor() {
    super();
    this.state = {
      buttonHighlight : 'accept' //'decline'
    }
  }

 

  render() {
    
    return (
      this.props.userData.usertype === 'None' ? (
        <div className="tnc-body bg-white">
          <h4 className="tnc-body-head-title">Crowdsense Terms of Service</h4>
          <p className="tnc-body-content">
            Crowdsense Ltd. ("Crowdsense", "us", "our", or "we") is the creator and owner of a technology for detecting, analyzing, and correlating events and other signals with potentially affected tradeable securities ("Events"), available through our website, and/or provided via SMS notifications  (collectively "Site"). These Terms of Service ("Terms") govern your access and use of the Site and any services available thereon ("Services"). Our Privacy Notice, available at http://crowdsense.ai/privacy ("Privacy Notice") governs our collection, processing and transfer of any Personal Data (as such term is defined in the Privacy Notice). "User" or "you" means any adult user using the Site and/or Services on behalf of an entity, individual or yourself.
          </p>
          <p className="tnc-body-content">
            Please read these Terms carefully. By clicking on the button marked "I agree" you signify your assent to these Terms. Changes may be made to these Terms from time to time. If you do not agree to any of these Terms, please do not click the button marked "I agree" and do not use the Services.
          </p>
          <p className="tnc-body-content">
            If you are registering on behalf of any entity or company ("Company"), you represent that you are authorized to enter into, and bind the Company to these Terms and register for the Services. You are solely responsible for ensuring that these Terms are in compliance with all laws, rules, and regulations applicable to you and the Company and the right to access the Services is revoked where use of the Services is prohibited.
          </p>
          <p className="tnc-body-content">
            In the event of a conflict between these Terms and an order form and/or statement of work we have executed with the applicable Company ("Customer Agreement") separately prior to entering into these Terms, the provisions of such Customer Agreement, as applicable, shall prevail.
          </p>
          <h4 className="tnc-body-title">Account Registration</h4>
          <p className="tnc-body-content">
            In order to use the Services, you will need to have a registered account. You may be able to register by logging in through your Facebook, Gmail or other third-party login account ("Login Account") as may be permitted by Crowdsense from time to time. By registering through a Login Account you represent and warrant that such Login Account is yours and that you have full rights to provide us with the information in such Login Account.  To complete the registration process, you must provide all (additional) registration information as requested by us. We may indicate that the provision of some information is optional, but your agreement to provide such information may assist us in providing you with improved Services.
          </p>
          <p className="tnc-body-content">
            Crowdsense may refuse to open an account for any individual or entity at its sole discretion.
          </p>
          <p className="tnc-body-content">
            You agree to notify us immediately of any unauthorized use of your account or password. You are fully and solely responsible for the security of your computer system and/or mobile device and all activity on your account, even if such activities were not committed by you. Crowdsense will not be liable for any losses or damage arising from unauthorized use of your account or password, and you agree to indemnify and hold Crowdsense harmless for any unauthorized, improper or illegal use of your account and any charges and taxes incurred, unless you have notified us via e-mail at info@crowdsense.ai that your account has been compromised and have requested that we block access to it, which we will do as soon as reasonably practicable. We do not police for and cannot guarantee that we will learn of or prevent, any inappropriate use of the Services.
          </p>
          <h4 className="tnc-body-title">Use of Services</h4>
          <p className="tnc-body-content">
            Crowdsense allows you to access and use the Site and Services on a non-exclusive basis subject to these Terms solely for your personal or internal business purposes. As a user of the Services you will receive push notifications to your Crowdsense account regarding Events in accordance with the preferences, parameters and settings provided by you upon registration or as otherwise configured by you on the Site and/or Services ("Alerts" and "Alert Preferences," respectively). All Alerts and Alert Preferences shall be considered confidential. Notwithstanding the foregoing, your Alert Preferences, as well as certain Events and other correlations and associations derived therefrom, may be used by Crowdsense for its own commercial purposes, including providing such information to other users on an anonymous and aggregated basis. You may request that we do not share such information with other users by contacting us at info@crowdsense.ai. As part of the Services, you shall have access to past Alerts you have received and may receive certain reports in connection therewith. Crowdsense may, at its sole discretion and at any time, modify or discontinue providing the Services or any part thereof without notice.
          </p>
          <p className="tnc-body-content">
            Use of and access to the Site and Services is void where prohibited by law. You represent and warrant that (a) any and all registration information you submit is truthful and accurate; (b) you will maintain the accuracy of such information; (c) you are 18 years of age or older, and have the ability to form a binding contract; and (d) your use of the Services does not violate any applicable law, regulation, or obligation you may have to a third party and you shall comply with applicable laws, regulations, guidelines, and these Terms throughout your use of the Site and/or Services.
          </p>
          <p className="tnc-body-content">
            The Site and Services utilize third party resources, as detailed on the Alerts. By opening any Alert, you confirm that you are aware of the specific terms and privacy policies governing use of that resource and agree to comply with any such terms with respect to your use of such third party's content ("Third-Party Content").
          </p>
          <h4 className="tnc-body-title">Termination of Account</h4>
          <p className="tnc-body-content">
            You agree that Crowdsense may for any reason, at its sole discretion and without notice, terminate your account, and may remove any Content (as defined below) associated with your account and take any other corrective action it deems appropriate. Grounds for such termination may include (i) extended periods of inactivity, (ii) violation of the letter or spirit of these Terms, (iii) fraudulent, harassing or abusive behavior, (iv) behavior that is harmful to other users or the business interests of Crowdsense, or (v) failure to make payment in accordance with the terms specified herein, including chargebacks. If your account is terminated, you may not rejoin Crowdsense again without our express permission. Upon termination of your account, you shall not have any further access to any Content that may be available through your account.
          </p>
          <p className="tnc-body-content">
            We reserve the right to investigate suspected violations of these Terms or illegal and inappropriate behavior through the Services. We will fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity, behavior or Content of anyone believed to have violated these Terms or to have engaged in illegal behavior in connection with the Services.
          </p>
          <p className="tnc-body-content">
            You may be able to terminate or account or, alternatively, request Crowdsense to termination of your Crowdsense account at any time and for any reason by sending an email to info@crowdsense.ai. Following such request, Crowdsense shall close your account as soon as reasonably practicable. Any suspension, cancellation or termination of your account shall not affect your obligations under these Terms (including but not limited to ownership, indemnification, any representations and warranties made by you, limitation of liability, and payment obligations), which by their sense and context are intended to survive such suspension, cancellation, or termination.
          </p>
          <h4 className="tnc-body-title">Fees and Payment</h4>
          <p className="tnc-body-content">
            Following a two-week free trial period, which Crowdsense may extend for an additional two weeks at its sole discretion, use of the Services will be subject to payment and you shall pay Crowdsense the fees as agreed between the parties. Unless otherwise agreed between you and Crowdsense in advance and in writing, Payments are to be made on a monthly in advance of the applicable month. It is clarified that you will not be eligible for a refund in respect of any month paid in advance. You will be asked to provide customary billing information such as name, billing address and if relevant, credit card information either to Crowdsense or its third-party payment processor(s). You hereby authorize the collection of such amounts by charging the credit card provided or by any other means available, either directly by Crowdsense or indirectly, via a third-party online payment processor or by one of the payment methods described in the Services. If you are directed to a third-party payment processor, you may be subject to terms and conditions governing use of that third party's service and that third party's Personal Data collection practices. Please review such terms and conditions and privacy policy before using such services.
          </p>
          <p className="tnc-body-content">
            Where applicable, taxes may also be charged. It is your responsibility to determine whether you are required to pay any applicable taxes, and to clarify such information when making a payment. Crowdsense does not accept any responsibility for the calculation or collection of any applicable taxes. Except as expressly provided in these Terms, fees are non-refundable.
          </p>
          <p className="tnc-body-content">
            Please note that Crowdsense may impose or deduct foreign currency processing costs on or from any payments or payouts by Crowdsense in currencies as detailed on the Site. When converting currency, prices may be rounded up to the nearest whole number.
          </p>
          <h4 className="tnc-body-title">Content</h4>
          <p className="tnc-body-content">
            Certain types of content may be made available through the Services. "Content" as used in these Terms means, collectively, all content on or made available through the Services, including any images, text, photos, pictures, videos, reports, Third-Party Content, and any modifications or derivatives of the foregoing.
          </p>
          <p className="tnc-body-content">
            Content on the Site and/or Services comes from a variety of sources. You understand that Crowdsense is not responsible for the accuracy, usefulness, safety or intellectual property rights of or relating to this Content. CROWDSENSE DOES NOT ENDORSE ANY CONTENT OR ANY OPINION, RECOMMENDATION, OR ADVICE EXPRESSED IN ANY CONTENT AND EXPRESSLY DISCLAIMS ANY AND ALL LIABILITY IN CONNECTION WITH THE CONTENT.
          </p>
          <h4 className="tnc-body-title">Use Restrictions</h4>
          <p className="tnc-body-content">
            You may not do or attempt to do or facilitate a third party in doing any of the following: (1) decipher, decompile, disassemble,  or reverse-engineer any of the software and/or code, if and as applicable, used to provide the Site or Services without our prior written authorization, including framing or mirroring any part of the Site or Services; (2) circumvent, disable, or otherwise interfere with security-related features of the Services or features that prevent or restrict use or copying of any Content, including any Third-Party Content; (3) share, disclose or otherwise transmit any Content or Third-Party Content to any third-party, including, without limitation, any customer or client of a user; (4) use any robot, spider, site search or retrieval application, or any other manual or automatic device or process to retrieve, index, data-mine, or in any way reproduce or circumvent the navigational structure or presentation of the Site or Services; (5) use or access another user’s account or password without permission; (6) use the Site, Services or Content thereon, including any Third-Party Content, in any manner not permitted by these Terms.
          </p>
          <h4 className="tnc-body-title">Intellectual Property</h4>
          <p className="tnc-body-content">
            Crowdsense or its licensors, as the case may be, have all right, title and interest in the Site, Services and Content, including its overall appearance, text, graphics, graphics design, videos, demos, interfaces, and underlying source files, and all worldwide intellectual property rights in the Services, and the trademarks, service marks, and logos contained therein, whether registered and unregistered. Except as expressly permitted herein, you may not copy, further develop, reproduce, republish, modify, alter download, post, broadcast, transmit or otherwise use the Content for any purpose. You will not remove, alter or conceal any copyright, trademark, service mark or other proprietary rights notices incorporated in the Site or Services, if any. All trademarks are trademarks or registered trademarks of their respective owners. Nothing in these Terms should be construed as granting you any right to use any trademark, service mark, logo, or trade name of Crowdsense or any third party. If you provide Crowdsense with any feedback regarding any content on the Site and/or Services, Crowdsense may use all such feedback without restriction and shall not be subject to any non-disclosure or non-use obligations in respect of such feedback.
          </p>
          <h4 className="tnc-body-title">Disclaimers and Disclaimer of Warranty</h4>
          <p className="tnc-body-content">
            All information and content posted on the Site and Services is for informational purposes only and should not be considered investment advice and Crowdsense provides no guarantees with respect thereto. Your use of the Site and/or Services is at your sole discretion and risk. The Site, Services and Content thereon, are provided on an "AS IS" and "AS AVAILABLE" basis without warranties of any kind. While we make reasonable efforts to provide Content that is informative and useful, we do not make any warranties or representations in respect of any Content. We do not represent or warrant that: (i) you will find any Content to be informative or useful for your own needs; (ii) the information we provide, including through the Alerts will be accurate, relevant or true. 
          </p>
          <p className="tnc-body-content">
            WE EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, EXPRESS, IMPLIED OR STATUTORY, RELATING TO THE SITE AND/OR SERVICES OR ANY CONTENT THEREON, INCLUDING WITHOUT LIMITATION THE WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT OF PROPRIETARY RIGHTS, COURSE OF DEALING OR COURSE OF PERFORMANCE. WE DISCLAIM ANY WARRANTIES, EXPRESS OR IMPLIED, (I) REGARDING THE SECURITY, ACCURACY, RELIABILITY, TIMELINESS AND PERFORMANCE OF THE SITE AND/OR SERVICES; OR (II) THAT THE SITE OR SERVICES WILL BE ERROR-FREE OR THAT ANY ERRORS WILL BE CORRECTED; OR (III) REGARDING THE PERFORMANCE OF OR ACCURACY, QUALITY, CURRENCY, COMPLETENESS OR USEFULNESS OF ANY INFORMATION PROVIDED ON THE SITE AND/OR SERVICES.
          </p>
          <p className="tnc-body-content">
            No advice or information, whether oral or written, obtained by you from us, shall create any warranty not expressly stated in these Terms. Crowdsense does not offer investment or financial advice. No information or other Content provided through the Site and/or Services shall be construed as any advice, endorsement, or recommendation to buy, sell or hold any security, future, option or other financial instrument or asset, or engage in any other investment or trading activity. If you choose to rely on such information, you do so solely at your own risk.
          </p>
          <p className="tnc-body-content">
            Some states or jurisdictions do not allow the exclusion of certain warranties. Accordingly, some of the above exclusions may not apply to you. Check your local laws for any restrictions or limitations regarding the exclusion of implied warranties.
          </p>
          <h4 className="tnc-body-title">Limitation of Liability</h4>
          <p className="tnc-body-content">
            In addition to the foregoing, we assume no responsibility for any error, omission, interruption, deletion, defect, delay in operation or transmission, communications line failure, theft or destruction or unauthorized access to, or alteration of, the Site, Content or Services. We are not responsible for any problems or technical malfunction of any telephone network or lines, computer online systems or equipment, servers or providers, software, failure of any email due to technical problems or traffic congestion on the internet or on the Site or Services, including any injury or damage to users or to any person's mobile device or computer related to or resulting from use of the Site or Services. Under no circumstances shall we be responsible for any loss or damage, including personal injury or death, resulting from use of the Site or Services, from any Content posted on or through the Services, or from the conduct of any users of the Services, whether online or offline. In addition, we assume no responsibility for any incorrect data, including Personal Data provided by you or on your behalf and you hereby represent and warrant that you are solely responsible for any and all data provided to Crowdsense, including any incorrect data and you shall assume any and all liability for any consequences of provision of such incorrect data to us.
          </p>
          <p className="tnc-body-content">
            IN NO EVENT SHALL CROWDSENSE, ITS AFFILIATES OR ANY OF THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, ASSIGNEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DAMAGES WHATSOEVER, INCLUDING WITHOUT LIMITATION INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES, ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE SITE OR SERVICES, INCLUDING BUT NOT LIMITED TO THE QUALITY, ACCURACY, OR UTILITY OF THE INFORMATION PROVIDED AS PART OF OR THROUGH THE SERVICES, WHETHER THE DAMAGES ARE FORESEEABLE AND WHETHER OR NOT CROWDSENSE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION AND IN NO EVENT SHALL OUR CUMULATIVE LIABILITY TO YOU EXCEED THE AMOUNT YOU HAVE PAID US IN THE THREEMONTHS IMMEDIATELY PRECEDING THE DATE ON WHICH THE APPLICABLE CLAIM OR CAUSE OF ACTION AROSE, IF APPLICABLE. IF YOU HAVE NOT MADE ANY PAYMENTS TO CROWDSENSE FOR THE USE OF THE SERVICES, THEN CROWDSENSE SHALL NOT HAVE ANY LIABILITY TOWARD YOU.
          </p>
          <h4 className="tnc-body-title">Indemnification</h4>
          <p className="tnc-body-content">
            You agree to indemnify, defend, and hold harmless Crowdsense and its respective employees, directors, officers, subcontractors and agents, against any and all claims, damages, or costs, losses, liabilities or expenses (including court costs and attorneys’ fees) that arise directly or indirectly from: (a) breach of these Terms by you or anyone using your computer and/or mobile device, password (whether authorized or unauthorized); (b) any claim, loss or damage experienced from your use or attempted use  of (or inability to use) Site or Services; (c) your violation of any law or regulation or any of your obligations, representations, or warranties hereunder including but not limited to breach of any privacy and/or data protection laws and regulations to which you are subject; (d) your infringement of any right of any third party; and (e) any other matter for which you are responsible hereunder or under applicable law.
          </p>
          <h4 className="tnc-body-title">Third-Party Content</h4>
          <p className="tnc-body-content">
            The Site and/or Services may provide you with third-party links to websites, applications, and services, including, without limitation, any Third-Party Content. We make no promises regarding any content, goods or services provided by such third parties and all use of third-party websites and applications is at your own risk. Additionally, we do not accept responsibility for any payments processed or submitted through third-party websites and applications or for the privacy policies of such third parties. We do not endorse any products offered by third parties and we urge our users to exercise caution in using third-party websites or applications. All use of third-party applications or services is at your own risk and subject to such third party's terms and privacy policies.
          </p>
          
          <h4 className="tnc-body-title">Miscellaneous</h4>
          <p className="tnc-body-content">
            These Terms shall be governed by the laws of the State of Israel exclusive of its choice of law rules, and without regard to the United Nations Convention on the International Sales of Goods and the competent courts in Tel Aviv-Jaffa shall have exclusive jurisdiction to hear any disputes arising hereunder. In any action to enforce these Terms, the prevailing party will be entitled to costs and attorneys’ fees. Any cause of action against Crowdsense must be brought within one (1) year of the date such cause of action arose. In the event that any provision of these Terms is held to be unenforceable, such provision shall be replaced with an enforceable provision which most closely achieves the effect of the original provision, and the remaining terms of these Terms shall remain in full force and effect. Nothing in these Terms creates any agency, employment, joint venture, or partnership relationship between you and Crowdsense or enables you to act on behalf of Crowdsense. Except as may be expressly stated in these Terms, these Terms constitute the entire agreement between us and you pertaining to the subject matter hereof, and any and all other agreements existing between us relating thereto are hereby canceled. We may assign and/or transfer our rights and obligations hereunder to any third party without prior notice. You shall not assign and/or transfer any of your rights or obligations hereunder, and any assignment in violation of the foregoing shall be void. No waiver of any breach or default hereunder shall be deemed to be a waiver of any preceding or subsequent breach or default. If we are required to provide notice to you hereunder, we may provide such notice to the contact details you provided upon registration.
          </p>
          <p className="tnc-body-content" style={{'fontSize': '12px','textAlign':'left'}}><i>Last updated: December 2020</i></p>
          
        </div>
      ) : (this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin') ? (
        <div className="tnc-body bg-white">
          <h4 className="tnc-body-head-title">Algense AI Events</h4>
          <p className="tnc-body-content">
            Please review the below Guidance, Terms, and Privacy Policy.
          </p>
          <h4 className="tnc-body-title">Guidance</h4>
          <p className="tnc-body-content">
            <ul>
              <li>Algense is a product owned by the legal entity of Crowdsense Ltd.</li>
              <li>Crowdsense is not an investment advisor, but a global B2B provider of multi-asset AI events.</li>
              <li>Algense Events are for informational purposes only and do not constitute investment advice.</li>
              <li>Algense Events do not constitute a solicitation to buy or sell in the financial markets.</li>
              <li>Algense Events are generated from a variety of social media posts and websites. Therefore, Algense is not responsible for guaranteeing the accuracy, timeliness, completeness, or fitness of such sources.</li>
              <li>You understand that Algense is not responsible for the accuracy, usefulness, safety or intellectual property rights of or relating to its events.</li>
              <li>Algense shall not be responsible for and disclaims all liability for any losses which may be suffered from access and use of the contents of the Algense Events.</li>
              <li>CROWDSENSE DOES NOT ENDORSE ANY CONTENT OR ANY OPINION, RECOMMENDATION, OR ADVICE EXPRESSED IN ANY CONTENT AND EXPRESSLY DISCLAIMS ANY AND ALL LIABILITY IN CONNECTION WITH THE CONTENT.</li>
              <li>Algense AI Events are generated in real time from breaking social media posts or web articles.</li>
              <li>Algense AI Events include a positive or negative sentiment for correlated assets.</li>
              <li>AI Events are NOT technical signals or entry points - AI Events gives the prevailing sentiment and it is best to wait for the entry point based on technical analysis.</li>
              <li>It is recommended to check all recent AI Events as well as additional indicators for an asset before trading.</li>
              <li>AI Events are ranked with an exposure & credibility magnitude.</li>
            </ul>
          </p>
          <h4 className="tnc-body-title">Terms & Conditions</h4>
          <p className="tnc-body-content">
            Algense is the creator and owner of technology for detecting, analyzing, and correlating events and other insights from social media with potentially affected tradeable securities ("Events"), available through our websites, and/or provided via email, telegram, or SMS notifications (collectively "Site"). These Terms of Service ("Terms") govern your access and use of the Site and any services available thereon ("Services"). "User" or "you" means any adult user using the Site and/or Services on behalf of an entity, individual, or yourself.
          </p>
          <p className="tnc-body-content">
            If you are registering on behalf of any entity or company ("Company"), you represent that you are authorized to enter into, and bind the Company to these Terms and register for the Services. You are solely responsible for ensuring that these Terms are in compliance with all laws, rules, and regulations applicable to you and the Company and the right to access the Services is revoked where use of the Services is prohibited.
          </p>
          <p className="tnc-body-content">
            In the event of a conflict between these Terms and an order form and/or statement of work we have executed with the applicable Company ("Customer Agreement") separately prior to entering into these Terms, the provisions of such Customer Agreement, as applicable, shall prevail.
          </p>
          <h4 className="tnc-body-title">Use of Services</h4>
          <p className="tnc-body-content">
            Algense allows you to access and use the Site and Services on a non-exclusive basis subject to these Terms solely for your personal or internal business purposes. As a user of the Services you will receive push notifications through Algense regarding Events in accordance with the preferences, parameters and settings provided by you or as otherwise configured by you on the Site and/or Services ("Alerts" and "Alert Preferences," respectively).
          </p>
          <p className="tnc-body-content">
            All Alerts and Alert Preferences shall be considered confidential. Notwithstanding the foregoing, your Alert Preferences, as well as certain Events and other correlations and associations derived therefrom, may be used by Algense for its own commercial purposes, including providing such information to other users on an anonymous and aggregated basis. As part of the Services, you shall have access to past Alerts you have received and may receive certain reports in connection therewith. Algense may, at its sole discretion and at any time, modify or discontinue providing the Services or any part thereof without notice.
          </p>
          <p className="tnc-body-content">
            The Site and Services utilize third party resources, as detailed on the Alerts. By opening any Alert, you confirm that you are aware of the specific terms and privacy policies governing use of that resource and agree to comply with any such terms with respect to your use of such third party's content ("Third-Party Content").
          </p>
          <h4 className="tnc-body-title">Content</h4>
          <p className="tnc-body-content">
            Certain types of content may be made available through the Services. "Content" as used in these Terms means, collectively, all content on or made available through the Services, including any images, text, photos, pictures, videos, reports, Third-Party Content, and any modifications or derivatives of the foregoing.
          </p>
          <p className="tnc-body-content">
            Content on the Site and/or Services comes from a variety of sources. You understand that Algense is not responsible for the accuracy, usefulness, safety or intellectual property rights of or relating to this Content. ALGENSE DOES NOT ENDORSE ANY CONTENT OR ANY OPINION, RECOMMENDATION, OR ADVICE EXPRESSED IN ANY CONTENT AND EXPRESSLY DISCLAIMS ANY AND ALL LIABILITY IN CONNECTION WITH THE CONTENT.
          </p>
          
          <h4 className="tnc-body-title">Use Restrictions</h4>
          <p className="tnc-body-content">
            You may not do or attempt to do or facilitate a third party in doing any of the following: (1) decipher, decompile, disassemble, or reverse-engineer any of the software and/or code, if and as applicable, used to provide the Site or Services without our prior written authorization, including framing or mirroring any part of the Site or Services; (2) circumvent, disable, or otherwise interfere with security-related features of the Services or features that prevent or restrict use or copying of any Content, including any Third-Party Content; (3) share, disclose or otherwise transmit any Content or Third-Party Content to any third-party, including, without limitation, any customer or client of a user; (4) use any robot, spider, site search or retrieval application, or any other manual or automatic device or process to retrieve, index, data-mine, or in any way reproduce or circumvent the navigational structure or presentation of the Site or Services; (5) use the Site, Services or Content thereon, including any Third-Party Content, in any manner not permitted by these Terms.
          </p>
          
          <h4 className="tnc-body-title">Intellectual Property</h4>
          <p className="tnc-body-content">
            Algense or its licensors, as the case may be, have all right, title and interest in the Site, Services and Content, including its overall appearance, text, graphics, graphics design, videos, demos, interfaces, and underlying source files, and all worldwide intellectual property rights in the Services, and the trademarks, service marks, and logos contained therein, whether registered and unregistered. Except as expressly permitted herein, you may not copy, further develop, reproduce, republish, modify, alter download, post, broadcast, transmit or otherwise use the Content for any purpose. You will not remove, alter or conceal any copyright, trademark, service mark or other proprietary rights notices incorporated in the Site or Services, if any. All trademarks are trademarks or registered trademarks of their respective owners. Nothing in these Terms should be construed as granting you any right to use any trademark, service mark, logo, or trade name of Algense or any third party. If you provide Algense with any feedback regarding any content on the Site and/or Services, Algense may use all such feedback without restriction and shall not be subject to any non-disclosure or non-use obligations in respect of such feedback.
          </p>
          <h4 className="tnc-body-title">Disclaimers and Disclaimer of Warranty</h4>
          <p className="tnc-body-content">
            All information and content posted on the Site and Services is for informational purposes only and should not be considered investment advice and Algense provides no guarantees with respect thereto. Your use of the Site and/or Services is at your sole discretion and risk. The Site, Services and Content thereon, are provided on an "AS IS" and "AS AVAILABLE" basis without warranties of any kind. While we make reasonable efforts to provide Content that is informative and useful, we do not make any warranties or representations in respect of any Content. We do not represent or warrant that: (i) you will find any Content to be informative or useful for your own needs; (ii) the information we provide, including through the Alerts will be accurate, relevant or true.
          </p>
          <p className="tnc-body-content">
            WE EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, EXPRESS, IMPLIED OR STATUTORY, RELATING TO THE SITE AND/OR SERVICES OR ANY CONTENT THEREON, INCLUDING WITHOUT LIMITATION THE WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT OF PROPRIETARY RIGHTS, COURSE OF DEALING OR COURSE OF PERFORMANCE. WE DISCLAIM ANY WARRANTIES, EXPRESS OR IMPLIED, (I) REGARDING THE SECURITY, ACCURACY, RELIABILITY, TIMELINESS AND PERFORMANCE OF THE SITE AND/OR SERVICES; OR (II) THAT THE SITE OR SERVICES WILL BE ERROR-FREE OR THAT ANY ERRORS WILL BE CORRECTED; OR (III) REGARDING THE PERFORMANCE OF OR ACCURACY, QUALITY, CURRENCY, COMPLETENESS OR USEFULNESS OF ANY INFORMATION PROVIDED ON THE SITE AND/OR SERVICES.
          </p>
          <p className="tnc-body-content">
            No advice or information, whether oral or written, obtained by you from us, shall create any warranty not expressly stated in these Terms. Algense does not offer investment or financial advice. No information or other Content provided through the Site and/or Services shall be construed as any advice, endorsement, or recommendation to buy, sell or hold any security, future, option or other financial instrument or asset, or engage in any other investment or trading activity. If you choose to rely on such information, you do so solely at your own risk.
          </p>
          <p className="tnc-body-content">
            Some states or jurisdictions do not allow the exclusion of certain warranties. Accordingly, some of the above exclusions may not apply to you. Check your local laws for any restrictions or limitations regarding the exclusion of implied warranties.
          </p>
          <h4 className="tnc-body-title">Limitation of Liability</h4>
          <p className="tnc-body-content">
            In addition to the foregoing, we assume no responsibility for any error, omission, interruption, deletion, defect, delay in operation or transmission, communications line failure, theft or destruction or unauthorized access to, or alteration of, the Site, Content or Services. We are not responsible for any problems or technical malfunction of any telephone network or lines, computer online systems or equipment, servers or providers, software, failure of any email due to technical problems or traffic congestion on the internet or on the Site or Services, including any injury or damage to users or to any person's mobile device or computer related to or resulting from use of the Site or Services. Under no circumstances shall we be responsible for any loss or damage, including personal injury or death, resulting from use of the Site or Services, from any Content posted on or through the Services, or from the conduct of any users of the Services, whether online or offline. In addition, we assume no responsibility for any incorrect data, including Personal Data provided by you or on your behalf and you hereby represent and warrant that you are solely responsible for any and all data provided to Algense, including any incorrect data and you shall assume any and all liability for any consequences of provision of such incorrect data to us.
          </p>
          <p className="tnc-body-content">
            IN NO EVENT SHALL ALGENSE, ITS AFFILIATES OR ANY OF THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, ASSIGNEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DAMAGES WHATSOEVER, INCLUDING WITHOUT LIMITATION INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES, ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE SITE OR SERVICES, INCLUDING BUT NOT LIMITED TO THE QUALITY, ACCURACY, OR UTILITY OF THE INFORMATION PROVIDED AS PART OF OR THROUGH THE SERVICES, WHETHER THE DAMAGES ARE FORESEEABLE AND WHETHER OR NOT ALGENSE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION AND IN NO EVENT SHALL OUR CUMULATIVE LIABILITY TO YOU EXCEED THE AMOUNT YOU HAVE PAID US IN THE THREEMONTHS IMMEDIATELY PRECEDING THE DATE ON WHICH THE APPLICABLE CLAIM OR CAUSE OF ACTION AROSE, IF APPLICABLE. IF YOU HAVE NOT MADE ANY PAYMENTS TO ALGENSE FOR THE USE OF THE SERVICES, THEN ALGENSE SHALL NOT HAVE ANY LIABILITY TOWARD YOU.
          </p>
          <h4 className="tnc-body-title">Indemnification</h4>
          <p className="tnc-body-content">
            You agree to indemnify, defend, and hold harmless Algense and its respective employees, directors, officers, subcontractors and agents, against any and all claims, damages, or costs, losses, liabilities or expenses (including court costs and attorneys’ fees) that arise directly or indirectly from: (a) breach of these Terms by you or anyone using your computer and/or mobile device, password (whether authorized or unauthorized); (b) any claim, loss or damage experienced from your use or attempted use of (or inability to use) Site or Services; (c) your violation of any law or regulation or any of your obligations, representations, or warranties hereunder including but not limited to breach of any privacy and/or data protection laws and regulations to which you are subject; (d) your infringement of any right of any third party; and (e) any other matter for which you are responsible hereunder or under applicable law.
          </p>
          <h4 className="tnc-body-title">Third-Party Content</h4>
          <p className="tnc-body-content">
            The Site and/or Services may provide you with third-party links to websites, applications, and services, including, without limitation, any Third-Party Content. We make no promises regarding any content, goods or services provided by such third parties and all use of third-party websites and applications is at your own risk. Additionally, we do not accept responsibility for any payments processed or submitted through third-party websites and applications or for the privacy policies of such third parties. We do not endorse any products offered by third parties and we urge our users to exercise caution in using third-party websites or applications. All use of third-party applications or services is at your own risk and subject to such third party's terms and privacy policies.
          </p>
          <h4 className="tnc-body-title">Miscellaneous</h4>
          <p className="tnc-body-content">
            These Terms shall be governed by the laws of the State of Israel exclusive of its choice of law rules, and without regard to the United Nations Convention on the International Sales of Goods and the competent courts in Tel Aviv-Jaffa shall have exclusive jurisdiction to hear any disputes arising hereunder. In any action to enforce these Terms, the prevailing party will be entitled to costs and attorneys’ fees. Any cause of action against Algense must be brought within one (1) year of the date such cause of action arose. In the event that any provision of these Terms is held to be unenforceable, such provision shall be replaced with an enforceable provision which most closely achieves the effect of the original provision, and the remaining terms of these Terms shall remain in full force and effect. Nothing in these Terms creates any agency, employment, joint venture, or partnership relationship between you and Algense or enables you to act on behalf of Algense. Except as may be expressly stated in these Terms, these Terms constitute the entire agreement between us and you pertaining to the subject matter hereof, and any and all other agreements existing between us relating thereto are hereby canceled. We may assign and/or transfer our rights and obligations hereunder to any third party without prior notice. You shall not assign and/or transfer any of your rights or obligations hereunder, and any assignment in violation of the foregoing shall be void. No waiver of any breach or default hereunder shall be deemed to be a waiver of any preceding or subsequent breach or default. If we are required to provide notice to you hereunder, we may provide such notice to the contact details you provided upon registration.
          </p>
          
          <p className="tnc-body-content" style={{'fontSize': '12px','textAlign':'left'}}><i>Last updated: May 2024</i></p>
        </div>
      ) : (
        <div className="tnc-body bg-white">
          <h4 className="tnc-body-head-title">Algense AI Events</h4>
          <p className="tnc-body-content">
            Please review the below Guidance, Terms, and Privacy Policy.
          </p>
          <h4 className="tnc-body-title">Guidance</h4>
          <p className="tnc-body-content">
            <ul>
              <li>Algense is a product owned by the legal entity of Crowdsense Ltd.</li>
              <li>Crowdsense is not an investment advisor, but a global B2B provider of multi-asset AI events.</li>
              <li>Algense Events are for informational purposes only and do not constitute investment advice.</li>
              <li>Algense Events do not constitute a solicitation to buy or sell in the financial markets.</li>
              <li>Algense Events are generated from a variety of social media posts and websites. Therefore, Algense is not responsible for guaranteeing the accuracy, timeliness, completeness, or fitness of such sources.</li>
              <li>You understand that Algense is not responsible for the accuracy, usefulness, safety or intellectual property rights of or relating to its events.</li>
              <li>Algense shall not be responsible for and disclaims all liability for any losses which may be suffered from access and use of the contents of the Algense Events.</li>
              <li>CROWDSENSE DOES NOT ENDORSE ANY CONTENT OR ANY OPINION, RECOMMENDATION, OR ADVICE EXPRESSED IN ANY CONTENT AND EXPRESSLY DISCLAIMS ANY AND ALL LIABILITY IN CONNECTION WITH THE CONTENT.</li>
              <li>Algense AI Events are generated in real time from breaking social media posts or web articles.</li>
              <li>Algense AI Events include a positive or negative sentiment for correlated assets.</li>
              <li>AI Events are NOT technical signals or entry points - AI Events gives the prevailing sentiment and it is best to wait for the entry point based on technical analysis.</li>
              <li>It is recommended to check all recent AI Events as well as additional indicators for an asset before trading.</li>
              <li>AI Events are ranked with an exposure & credibility magnitude.</li>
            </ul>
          </p>
          <h4 className="tnc-body-title">Terms & Conditions</h4>
          <p className="tnc-body-content">
            Algense is the creator and owner of technology for detecting, analyzing, and correlating events and other insights from social media with potentially affected tradeable securities ("Events"), available through our websites, and/or provided via email, telegram, or SMS notifications (collectively "Site"). These Terms of Service ("Terms") govern your access and use of the Site and any services available thereon ("Services"). "User" or "you" means any adult user using the Site and/or Services on behalf of an entity, individual, or yourself.
          </p>
          <p className="tnc-body-content">
            If you are registering on behalf of any entity or company ("Company"), you represent that you are authorized to enter into, and bind the Company to these Terms and register for the Services. You are solely responsible for ensuring that these Terms are in compliance with all laws, rules, and regulations applicable to you and the Company and the right to access the Services is revoked where use of the Services is prohibited.
          </p>
          <p className="tnc-body-content">
            In the event of a conflict between these Terms and an order form and/or statement of work we have executed with the applicable Company ("Customer Agreement") separately prior to entering into these Terms, the provisions of such Customer Agreement, as applicable, shall prevail.
          </p>
          <h4 className="tnc-body-title">Use of Services</h4>
          <p className="tnc-body-content">
            Algense allows you to access and use the Site and Services on a non-exclusive basis subject to these Terms solely for your personal or internal business purposes. As a user of the Services you will receive push notifications through Algense regarding Events in accordance with the preferences, parameters and settings provided by you or as otherwise configured by you on the Site and/or Services ("Alerts" and "Alert Preferences," respectively).
          </p>
          <p className="tnc-body-content">
            All Alerts and Alert Preferences shall be considered confidential. Notwithstanding the foregoing, your Alert Preferences, as well as certain Events and other correlations and associations derived therefrom, may be used by Algense for its own commercial purposes, including providing such information to other users on an anonymous and aggregated basis. As part of the Services, you shall have access to past Alerts you have received and may receive certain reports in connection therewith. Algense may, at its sole discretion and at any time, modify or discontinue providing the Services or any part thereof without notice.
          </p>
          <p className="tnc-body-content">
            The Site and Services utilize third party resources, as detailed on the Alerts. By opening any Alert, you confirm that you are aware of the specific terms and privacy policies governing use of that resource and agree to comply with any such terms with respect to your use of such third party's content ("Third-Party Content").
          </p>
          <h4 className="tnc-body-title">Content</h4>
          <p className="tnc-body-content">
            Certain types of content may be made available through the Services. "Content" as used in these Terms means, collectively, all content on or made available through the Services, including any images, text, photos, pictures, videos, reports, Third-Party Content, and any modifications or derivatives of the foregoing.
          </p>
          <p className="tnc-body-content">
            Content on the Site and/or Services comes from a variety of sources. You understand that Algense is not responsible for the accuracy, usefulness, safety or intellectual property rights of or relating to this Content. ALGENSE DOES NOT ENDORSE ANY CONTENT OR ANY OPINION, RECOMMENDATION, OR ADVICE EXPRESSED IN ANY CONTENT AND EXPRESSLY DISCLAIMS ANY AND ALL LIABILITY IN CONNECTION WITH THE CONTENT.
          </p>
          
          <h4 className="tnc-body-title">Use Restrictions</h4>
          <p className="tnc-body-content">
            You may not do or attempt to do or facilitate a third party in doing any of the following: (1) decipher, decompile, disassemble, or reverse-engineer any of the software and/or code, if and as applicable, used to provide the Site or Services without our prior written authorization, including framing or mirroring any part of the Site or Services; (2) circumvent, disable, or otherwise interfere with security-related features of the Services or features that prevent or restrict use or copying of any Content, including any Third-Party Content; (3) share, disclose or otherwise transmit any Content or Third-Party Content to any third-party, including, without limitation, any customer or client of a user; (4) use any robot, spider, site search or retrieval application, or any other manual or automatic device or process to retrieve, index, data-mine, or in any way reproduce or circumvent the navigational structure or presentation of the Site or Services; (5) use the Site, Services or Content thereon, including any Third-Party Content, in any manner not permitted by these Terms.
          </p>
          
          <h4 className="tnc-body-title">Intellectual Property</h4>
          <p className="tnc-body-content">
            Algense or its licensors, as the case may be, have all right, title and interest in the Site, Services and Content, including its overall appearance, text, graphics, graphics design, videos, demos, interfaces, and underlying source files, and all worldwide intellectual property rights in the Services, and the trademarks, service marks, and logos contained therein, whether registered and unregistered. Except as expressly permitted herein, you may not copy, further develop, reproduce, republish, modify, alter download, post, broadcast, transmit or otherwise use the Content for any purpose. You will not remove, alter or conceal any copyright, trademark, service mark or other proprietary rights notices incorporated in the Site or Services, if any. All trademarks are trademarks or registered trademarks of their respective owners. Nothing in these Terms should be construed as granting you any right to use any trademark, service mark, logo, or trade name of Algense or any third party. If you provide Algense with any feedback regarding any content on the Site and/or Services, Algense may use all such feedback without restriction and shall not be subject to any non-disclosure or non-use obligations in respect of such feedback.
          </p>
          <h4 className="tnc-body-title">Disclaimers and Disclaimer of Warranty</h4>
          <p className="tnc-body-content">
            All information and content posted on the Site and Services is for informational purposes only and should not be considered investment advice and Algense provides no guarantees with respect thereto. Your use of the Site and/or Services is at your sole discretion and risk. The Site, Services and Content thereon, are provided on an "AS IS" and "AS AVAILABLE" basis without warranties of any kind. While we make reasonable efforts to provide Content that is informative and useful, we do not make any warranties or representations in respect of any Content. We do not represent or warrant that: (i) you will find any Content to be informative or useful for your own needs; (ii) the information we provide, including through the Alerts will be accurate, relevant or true.
          </p>
          <p className="tnc-body-content">
            WE EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, EXPRESS, IMPLIED OR STATUTORY, RELATING TO THE SITE AND/OR SERVICES OR ANY CONTENT THEREON, INCLUDING WITHOUT LIMITATION THE WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT OF PROPRIETARY RIGHTS, COURSE OF DEALING OR COURSE OF PERFORMANCE. WE DISCLAIM ANY WARRANTIES, EXPRESS OR IMPLIED, (I) REGARDING THE SECURITY, ACCURACY, RELIABILITY, TIMELINESS AND PERFORMANCE OF THE SITE AND/OR SERVICES; OR (II) THAT THE SITE OR SERVICES WILL BE ERROR-FREE OR THAT ANY ERRORS WILL BE CORRECTED; OR (III) REGARDING THE PERFORMANCE OF OR ACCURACY, QUALITY, CURRENCY, COMPLETENESS OR USEFULNESS OF ANY INFORMATION PROVIDED ON THE SITE AND/OR SERVICES.
          </p>
          <p className="tnc-body-content">
            No advice or information, whether oral or written, obtained by you from us, shall create any warranty not expressly stated in these Terms. Algense does not offer investment or financial advice. No information or other Content provided through the Site and/or Services shall be construed as any advice, endorsement, or recommendation to buy, sell or hold any security, future, option or other financial instrument or asset, or engage in any other investment or trading activity. If you choose to rely on such information, you do so solely at your own risk.
          </p>
          <p className="tnc-body-content">
            Some states or jurisdictions do not allow the exclusion of certain warranties. Accordingly, some of the above exclusions may not apply to you. Check your local laws for any restrictions or limitations regarding the exclusion of implied warranties.
          </p>
          <h4 className="tnc-body-title">Limitation of Liability</h4>
          <p className="tnc-body-content">
            In addition to the foregoing, we assume no responsibility for any error, omission, interruption, deletion, defect, delay in operation or transmission, communications line failure, theft or destruction or unauthorized access to, or alteration of, the Site, Content or Services. We are not responsible for any problems or technical malfunction of any telephone network or lines, computer online systems or equipment, servers or providers, software, failure of any email due to technical problems or traffic congestion on the internet or on the Site or Services, including any injury or damage to users or to any person's mobile device or computer related to or resulting from use of the Site or Services. Under no circumstances shall we be responsible for any loss or damage, including personal injury or death, resulting from use of the Site or Services, from any Content posted on or through the Services, or from the conduct of any users of the Services, whether online or offline. In addition, we assume no responsibility for any incorrect data, including Personal Data provided by you or on your behalf and you hereby represent and warrant that you are solely responsible for any and all data provided to Algense, including any incorrect data and you shall assume any and all liability for any consequences of provision of such incorrect data to us.
          </p>
          <p className="tnc-body-content">
            IN NO EVENT SHALL ALGENSE, ITS AFFILIATES OR ANY OF THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, ASSIGNEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DAMAGES WHATSOEVER, INCLUDING WITHOUT LIMITATION INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES, ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE SITE OR SERVICES, INCLUDING BUT NOT LIMITED TO THE QUALITY, ACCURACY, OR UTILITY OF THE INFORMATION PROVIDED AS PART OF OR THROUGH THE SERVICES, WHETHER THE DAMAGES ARE FORESEEABLE AND WHETHER OR NOT ALGENSE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION AND IN NO EVENT SHALL OUR CUMULATIVE LIABILITY TO YOU EXCEED THE AMOUNT YOU HAVE PAID US IN THE THREEMONTHS IMMEDIATELY PRECEDING THE DATE ON WHICH THE APPLICABLE CLAIM OR CAUSE OF ACTION AROSE, IF APPLICABLE. IF YOU HAVE NOT MADE ANY PAYMENTS TO ALGENSE FOR THE USE OF THE SERVICES, THEN ALGENSE SHALL NOT HAVE ANY LIABILITY TOWARD YOU.
          </p>
          <h4 className="tnc-body-title">Indemnification</h4>
          <p className="tnc-body-content">
            You agree to indemnify, defend, and hold harmless Algense and its respective employees, directors, officers, subcontractors and agents, against any and all claims, damages, or costs, losses, liabilities or expenses (including court costs and attorneys’ fees) that arise directly or indirectly from: (a) breach of these Terms by you or anyone using your computer and/or mobile device, password (whether authorized or unauthorized); (b) any claim, loss or damage experienced from your use or attempted use of (or inability to use) Site or Services; (c) your violation of any law or regulation or any of your obligations, representations, or warranties hereunder including but not limited to breach of any privacy and/or data protection laws and regulations to which you are subject; (d) your infringement of any right of any third party; and (e) any other matter for which you are responsible hereunder or under applicable law.
          </p>
          <h4 className="tnc-body-title">Third-Party Content</h4>
          <p className="tnc-body-content">
            The Site and/or Services may provide you with third-party links to websites, applications, and services, including, without limitation, any Third-Party Content. We make no promises regarding any content, goods or services provided by such third parties and all use of third-party websites and applications is at your own risk. Additionally, we do not accept responsibility for any payments processed or submitted through third-party websites and applications or for the privacy policies of such third parties. We do not endorse any products offered by third parties and we urge our users to exercise caution in using third-party websites or applications. All use of third-party applications or services is at your own risk and subject to such third party's terms and privacy policies.
          </p>
          <h4 className="tnc-body-title">Miscellaneous</h4>
          <p className="tnc-body-content">
            These Terms shall be governed by the laws of the State of Israel exclusive of its choice of law rules, and without regard to the United Nations Convention on the International Sales of Goods and the competent courts in Tel Aviv-Jaffa shall have exclusive jurisdiction to hear any disputes arising hereunder. In any action to enforce these Terms, the prevailing party will be entitled to costs and attorneys’ fees. Any cause of action against Algense must be brought within one (1) year of the date such cause of action arose. In the event that any provision of these Terms is held to be unenforceable, such provision shall be replaced with an enforceable provision which most closely achieves the effect of the original provision, and the remaining terms of these Terms shall remain in full force and effect. Nothing in these Terms creates any agency, employment, joint venture, or partnership relationship between you and Algense or enables you to act on behalf of Algense. Except as may be expressly stated in these Terms, these Terms constitute the entire agreement between us and you pertaining to the subject matter hereof, and any and all other agreements existing between us relating thereto are hereby canceled. We may assign and/or transfer our rights and obligations hereunder to any third party without prior notice. You shall not assign and/or transfer any of your rights or obligations hereunder, and any assignment in violation of the foregoing shall be void. No waiver of any breach or default hereunder shall be deemed to be a waiver of any preceding or subsequent breach or default. If we are required to provide notice to you hereunder, we may provide such notice to the contact details you provided upon registration.
          </p>
          
          <p className="tnc-body-content" style={{'fontSize': '12px','textAlign':'left'}}><i>Last updated: May 2024</i></p>
        </div>
      )
        
    )
  }
}


export default TNC