import React, { Component } from 'react';
import { APPPATH } from '../../modules/helper.module';

class ShowDetailIcon extends Component {
  state = { listcollapseHover: true };
  collapseHover = (e, flag) => {
    e.preventDefault();
    this.setState({
      listcollapseHover: flag,
    });
  };
  render() {
    return (
      <span className="dispinb posrelative">
        <span
          onMouseEnter={(e) => {
            this.collapseHover(e, true);
          }}
          onMouseLeave={(e) => {
            this.collapseHover(e, false);
          }}
          style={{ marginLeft: 5 }}
        >
          <img
            style={{ width: '15px' }}
            src={`${
              this.state.listcollapseHover
                ? `${APPPATH.ImageUrl}/storyexpandiconblue.svg`
                : `${APPPATH.ImageUrl}/storyexpandicon.svg`
            }`}
            className={`storyExpandIcon ${this.props.listcollapse ? '' : 'transform180anticlock'}`}
            alt=""
          />
        </span>
      </span>
    );
  }
}

export default ShowDetailIcon;
