import React, { Component } from 'react';

class GoogleMultiplexAds extends Component {

    constructor(props) {
        super();
        this.state = {
            SlotId : props.SlotId
        }
    }

    componentDidMount() {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
    

    render() {
        const { SlotId } = this.state;
        return (
            
            <ins className="adsbygoogle"
            style={{display:'block'}}
            data-ad-format="autorelaxed"
            data-ad-client="ca-pub-8523528160500226"
            data-ad-slot={SlotId}
            data-matched-content-ui-type="image_sidebyside"
            data-matched-content-rows-num="4,2"
            data-matched-content-columns-num="1,4"></ins>
        );
    }

}

export default GoogleMultiplexAds