import React, {useState, useEffect} from 'react';
// import { Link } from 'react-router-dom';
import { APPPATH } from '../../../modules/helper.module';
import { useDispatch, useSelector } from 'react-redux';

const Logo = ({ noTitle, align, beta = true, userpackage, infreetrial }) => {
  // console.log(userpackage === '')
  // const dispatch = useDispatch();
  let userData = useSelector(({settings}) => settings.userData);
  let Theme = useSelector(({app_preferences}) => app_preferences.preferences.user_interface.Theme);
  const [UserType, setUserType] = useState('None');
  useEffect(() => {
      setUserType(userData.usertype)
  },[userData])
  // console.log(window.location.host)
  
  return (
    <div className={`logo-wrap  ${align ? 'fleft' : ''}`}>
      <div className="logo-link pointer" onClick={pushTo}>
        <span>
          {
            window.location.host.includes('data.algense.com') ||  window.location.host.includes('events.algense.com')? (
              <img src={`${APPPATH.ImageUrl}/transparent_company_logo.png`} className={`logo company ${!align ? 'funset' : ''}`} alt="CrowdSense.ai" />
            ) : (UserType !== 'None' && (UserType !== 'WUser' || UserType !== 'WAdmin')) ? (
              <img src={`${APPPATH.ImageUrl}/transparent_company_logo.png`} className={`logo company ${!align ? 'funset' : ''}`} alt="CrowdSense.ai" />
            ) : userpackage !== '' && (userpackage === 'premium' || !infreetrial) ? (
              Theme === 'light' ? (
                <img src={`${APPPATH.ImageUrl}/logo-premium.svg`} className={`logo ${!align ? 'funset' : ''}`} alt="CrowdSense.ai" />
              ) : (
                <img src={`${APPPATH.ImageUrl}/premium2.svg`} className={`logo ${!align ? 'funset' : ''}`} alt="CrowdSense.ai" />
              )
            ) : (
              <img src={`${APPPATH.ImageUrl}/transparent_logo.png`} className={`logo ${!align ? 'funset' : ''}`} alt="CrowdSense.ai" />
            )
          }
          {/* {
            
            userpackage !== '' && (userpackage === 'premium' || !infreetrial) ? (
              <img src={`${APPPATH.ImageUrl}/premium2.svg`} className={`logo ${!align ? 'funset' : ''}`} alt="CrowdSense.ai" />
            ) : (
              <img src={`${APPPATH.ImageUrl}/transparent_logo.png`} className={`logo ${!align ? 'funset' : ''}`} alt="CrowdSense.ai" />
            )
          } */}
          {/*  */}
          
        </span>
      </div>
    </div>
  );
};
let pushTo = () => {
  window.location.href = APPPATH.AppUrl;
};
export default Logo;
