import React, { Component } from 'react'; 
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
// import Tooltip from '../shared/tooltip/generaltooltip';
import * as appSettingsActionCreators from '../../actions/settings.action';
import { APPPATH } from '../../modules/helper.module';

class LikeDislike extends Component {
  constructor() {
    super();
    this.state = {
      feedback: 0,//
      prevfeedback: 0,
      dislikeReasonTip : false,
      likeReasonTip : false,
      dislikeOtherMsgDisabled : true,
      likeOtherMsgDisabled : true,
      dislikeOtherMsg : '',
      likeOtherMsg : '',
      likeToolTip: false,
      dislikeToolTip: false,
      likeTipHandle :false,
      isClickedInstanceLike: false,
      dislikeTipHandle :false,
      isClickedInstanceDislike: false,

      LDtooltip: false,
      LDReasonTip: false,
      LDReasonTipHandle: false,
      isClickedInstance: false,
      OtherMsg: '',
      OtherMsgDisabled : false,
      dislikeReasons : [
        // "Event arrived / updated after market affect",
        // "Event arrived after the news",
        // "Event happened before, no impact expected",
        // "Event doesn't affect any securities",
        // "Event doesn't affect my securities",
        // "This is not an event",
        // "Some affected securities are not mentioned",
        // "Some mentioned securities are not affected",
        // "Some feeds seem like fake news",
        // "Alert is a post-event discussion or opinion",
        // "Mixed events in one alert",
        "Not relevant to me",
        "Arrived late",
        "Fake news",
        "Incorrect details"
      ],
      likeReasons : [
        // 'Alert drove / can drive me to trade',
        // 'Alert is related to my tradeable securities',
        // 'Alert is related to an event affecting capital markets',
        // 'Alert arrived before markets were affected',
        // 'Alert arrived before event appeared on other news sources',
        // 'Alert seems related to a real breaking event',
        // 'Alert headline provided relevant information',
        'Relevant to me',
        'Arrived in real time',
        'Led me to an action'
      ],
      selectedDislikeReasons : [],
      selectedLikeReasons : [],
      selectedOtherReasonType : "like"
    }
    this.dislikeOtherReasonInput = React.createRef()
    this.likeOtherReasonInput = React.createRef()
    this.OtherReasonInput = React.createRef()
    this.ttHandle = '';
  }

  componentWillMount() {
    this.mapFeedback()
  }

  componentWillReceiveProps(nextProps) {
    this.mapFeedback(true, nextProps)
  }

  mapFeedback(update = false, nextProps = null) {
    
    let FeedB = 0;
    let selectedLikeReasons;
    let selectedDislikeReasons;
    let selectedOtherReasonType;
    let OtherMsg;
    // let OtherMsgDisabled = true;
    let LDrestip = false;
    let FBRow;
    if(!update) {
      if(this.props.userFeedbacks.length) {
        for(let row of this.props.userFeedbacks) {
          if(row.EventID === this.props.FeedId) {
            FeedB = row.Like;
            FBRow = row;
          }
        }
      }
    } else {
     
      if(nextProps.userFeedbacks.length) {
        for(let row of nextProps.userFeedbacks) {
          if(row.EventID === nextProps.FeedId) {
            FeedB = row.Like; 
            FBRow = row; 
          }
        }
      }
    }
    switch(FeedB) {
      case 1:
        selectedLikeReasons = FBRow.Reason ? FBRow.Reason.split(';') : [];
        selectedDislikeReasons = [];
        selectedOtherReasonType = 'like';
        OtherMsg = FBRow.OtherReason ? FBRow.OtherReason : '';
        break;
      case -1:
        selectedLikeReasons = [];
        selectedDislikeReasons = FBRow.Reason ? FBRow.Reason.split(';') : [];
        selectedOtherReasonType = 'dislike';
        OtherMsg = FBRow.OtherReason ? FBRow.OtherReason : '';
        break;
      default:
        selectedLikeReasons = [];
        selectedDislikeReasons = [];
        selectedOtherReasonType = 'like';
        OtherMsg = '';
    }
    
    if(update) {
      if(this.props.likedislikeToolTipHandler !== nextProps.likedislikeToolTipHandler) {
        if(!nextProps.likedislikeToolTipHandler || (nextProps.likedislikeToolTipHandler !== nextProps.FeedId)) {
          LDrestip = false;
          this.setState({
            ...this.state,
            LDReasonTip : LDrestip,
            LDReasonTipHandle: nextProps.likedislikeToolTipHandler,
            feedback: FeedB,
            selectedLikeReasons: selectedLikeReasons,
            selectedDislikeReasons: selectedDislikeReasons,
            selectedOtherReasonType: selectedOtherReasonType,
            OtherMsg: OtherMsg,
            // OtherMsgDisabled: OtherMsgDisabled
          })
        } else {
          window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
          window.Mixpanel.MixpanelProps.EventId = this.props.FeedId;
          window.Mixpanel.MixpanelProps.Headline = this.props.HeadlineText;
          window.Mixpanel.actions.identify(this.props.userData.username);
          window.Mixpanel.actions.track(`Clicked to see like dislike reasons`, window.Mixpanel.MixpanelProps).then(data => {
            window.Mixpanel.actions.people.set({
              UserName: this.props.userData.username
            });
            window.Mixpanel.actions.resetProp('EventId');
            window.Mixpanel.actions.resetProp('Headline');
          })
        }
      } else {
        this.setState({
          ...this.state,
          feedback: FeedB,
          selectedLikeReasons: selectedLikeReasons,
          selectedDislikeReasons: selectedDislikeReasons,
          selectedOtherReasonType: selectedOtherReasonType,
          OtherMsg: OtherMsg
        })
      }
      
    } else {
      this.setState({
        ...this.state,
        feedback: FeedB,
        selectedLikeReasons: selectedLikeReasons,
        selectedDislikeReasons: selectedDislikeReasons,
        selectedOtherReasonType: selectedOtherReasonType,
        OtherMsg: OtherMsg
      })
    }
    
    
  }

  DislikeReasonCLicked = () => {
    let resTip = !this.state.dislikeReasonTip
    if(this.state.feedback > -1) {
        this.setState({
        ...this.state,
        dislikeReasonTip : !this.state.dislikeReasonTip,
        likeReasonTip : false,
        prevfeedback: this.state.feedback,
        feedback : -1,
        dislikeToolTip: false,
        isClickedInstanceDislike: true
      }, () => {
        
        if(resTip) {
        
          this.props.tipHandle(resTip, this.props.FeedId, 'dislikeToolTipHandler')
        } else {
          this.setState({
            ...this.state,
            dislikeReasonTip: false,
            isClickedInstanceDislike: false,
            feedback : this.state.prevfeedback
          })
        }
      })
    } else {
      this.setFeedBack('0', 'dislike', false);
    }
  }
  
  toggleReasonDislike = (event) => {
    event.preventDefault();
    if(!this.props.EventReadStatusMain) {
      this.props.storyClicked().then(() => {
        this.DislikeReasonCLicked();
      })
    } else {
      this.DislikeReasonCLicked();
    }
  }

  LikeReasonClicked = () => {
    let resTip = !this.state.likeReasonTip
    // console.log(resTip)
    if(this.state.feedback < 1) {
        this.setState({
        ...this.state,
        likeReasonTip : !this.state.likeReasonTip,
        dislikeReasonTip : false,
        prevfeedback: this.state.feedback,
        feedback : 1,
        likeToolTip: false,
        isClickedInstanceLike: true
      }, () => {
        
        if(resTip) {
        
          this.props.tipHandle(resTip, this.props.FeedId, 'likeToolTipHandler')
        } else {
          this.setState({
            ...this.state,
            likeReasonTip: false,
            isClickedInstanceLike: false,
            feedback : this.state.prevfeedback
          })
        }
      })
    } else {
      this.setFeedBack('0', 'like', false)
    }
  }

  toggleReasonLike = (event) => {
    event.preventDefault();
    if(!this.props.EventReadStatusMain) {
      this.props.storyClicked().then(() => {
        this.LikeReasonClicked()
      })
    } else {
      this.LikeReasonClicked()
    }
  }

  dislikeHandleOtherReason = (e) => {
    let otherReason = e.target.value;
    this.setState({
      ...this.state,
      dislikeOtherMsg : otherReason
    })
  }

  likeHandleOtherReason = (e) => {
    let otherReason = e.target.value;
    this.setState({
      ...this.state,
      likeOtherMsg : otherReason
    })
  }

  toggleLikeToolTip = (showState) => {
    const { likeReasonTip } = this.state;
    if(!likeReasonTip) {
      this.setState({
        ...this.state,
        likeToolTip: showState
      }, () => {
        if(showState) {
          window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
          window.Mixpanel.MixpanelProps.EventId = this.props.FeedId;
          window.Mixpanel.MixpanelProps.Headline = this.props.HeadlineText;
          window.Mixpanel.MixpanelProps.ToolTipType = 'Like';
          window.Mixpanel.actions.identify(this.props.userData.username);
          window.Mixpanel.actions.track(`Tooltip shown`, window.Mixpanel.MixpanelProps).then(data => {
            window.Mixpanel.actions.people.set({
              UserName: this.props.userData.username
            });
            window.Mixpanel.actions.resetProp('EventId');
            window.Mixpanel.actions.resetProp('Headline');
            window.Mixpanel.actions.resetProp('ToolTipType');
          })
        }
        
      })
    }
    
  }

  toggleDislikeToolTip = (showState) => {
    const { dislikeReasonTip } = this.state;
    if(!dislikeReasonTip) {
      this.setState({
        ...this.state,
        dislikeToolTip: showState
      },() => {
        if(showState) {
          window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
          window.Mixpanel.MixpanelProps.EventId = this.props.FeedId;
          window.Mixpanel.MixpanelProps.Headline = this.props.HeadlineText;
          window.Mixpanel.MixpanelProps.ToolTipType = 'Dislike';
          window.Mixpanel.actions.identify(this.props.userData.username);
          window.Mixpanel.actions.track(`Tooltip shown`, window.Mixpanel.MixpanelProps).then(data => {
            window.Mixpanel.actions.people.set({
              UserName: this.props.userData.username
            });
            window.Mixpanel.actions.resetProp('EventId');
            window.Mixpanel.actions.resetProp('Headline');
            window.Mixpanel.actions.resetProp('ToolTipType');
          })
        }
      })
    }
    
  }
  
  toggleToolTip = (showState) => {
    const { LDReasonTip } = this.state;
    if(!LDReasonTip) {
      if(this.ttHandle) {
        clearTimeout(this.ttHandle)
      }
      if(showState) {
        this.ttHandle = setTimeout(() => {
          this.setState({
            ...this.state,
            LDtooltip: showState
          }, () => {
            // console.log(this.state.LDtooltip)
            // if(showState) {
              window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
              window.Mixpanel.MixpanelProps.EventId = this.props.FeedId;
              window.Mixpanel.MixpanelProps.Headline = this.props.HeadlineText;
              window.Mixpanel.MixpanelProps.ToolTipType = 'Like Dislike';
              window.Mixpanel.actions.identify(this.props.userData.username);
              window.Mixpanel.actions.track(`Tooltip shown`, window.Mixpanel.MixpanelProps).then(data => {
                window.Mixpanel.actions.people.set({
                  UserName: this.props.userData.username
                });
                window.Mixpanel.actions.resetProp('EventId');
                window.Mixpanel.actions.resetProp('Headline');
                window.Mixpanel.actions.resetProp('ToolTipType');
              })
            // }
            
          })
        }, 1500)
      } else {
        this.setState({
          ...this.state,
          LDtooltip: showState
        })
      }
      
    }
    
  }
  toggleReasonTip = (event) => {
    event.preventDefault();
    if(!this.props.EventReadStatusMain) {
      // this.props.storyClicked().then(() => {
        this.ReasonClicked()
      // })
    } else {
      this.ReasonClicked()
    }
  }
  ReasonClicked = () => {
    let resTip = !this.state.LDReasonTip
    if(this.ttHandle) {
      clearTimeout(this.ttHandle)
    }
    this.setState({
      ...this.state,
      LDReasonTip: resTip,
      LDtooltip: false,
      isClickedInstance: true
    },() => {
      if(resTip) {
        this.props.tipHandle(resTip, this.props.FeedId, 'likedislikeToolTipHandler')
      }
    })
    
  }
  HandleOtherReason = (e) => {
    let otherReason = e.target.value;
    this.setState({
      ...this.state,
      OtherMsg : otherReason
    })
  }
  selectDislikeReason = (item) => {
    let selReas = this.state.selectedDislikeReasons;
    let likeReas = [];
    let otherMsg = this.state.OtherMsg;
    let OtherMsgDisabled = this.state.OtherMsgDisabled;
    let newValue = item;
    if(selReas.indexOf(newValue) > -1) {
      selReas.splice(selReas.indexOf(newValue),1);
    } else {
      selReas.push(newValue)
    }
    
    this.setState({
      ...this.state,
      selectedDislikeReasons : selReas,
      selectedLikeReasons : likeReas,
      OtherMsgDisabled : OtherMsgDisabled,
      OtherMsg : otherMsg,
      selectedOtherReasonType: 'dislike'
    },() => {
      // console.log(this.state.selectedOtherReasonType)
    })
  }
  selectLikeReason = (item) => {
    let selReas = this.state.selectedLikeReasons;
    let dislikeReas = [];
    let otherMsg = this.state.OtherMsg;
    let OtherMsgDisabled = this.state.OtherMsgDisabled;
    let newValue = item;
    if(selReas.indexOf(newValue) > -1) {
      selReas.splice(selReas.indexOf(newValue),1);
    } else {
      selReas.push(newValue)
    }
    
    this.setState({
      ...this.state,
      selectedLikeReasons : selReas,
      selectedDislikeReasons: dislikeReas,
      OtherMsgDisabled : OtherMsgDisabled,
      OtherMsg : otherMsg,
      selectedOtherReasonType: 'like'
    },() => {
      console.log(this.state.selectedOtherReasonType)
    })
  }
  selectOtherReason = () => {
    
    let OtherMsgDisabled = !this.state.OtherMsgDisabled;
    let otherMsg = '';
    this.setState({
      ...this.state,
      OtherMsgDisabled : OtherMsgDisabled,
      OtherMsg : otherMsg
    })
  }
  HandleOtherReason = (e) => {
    let otherReason = e.target.value;
    this.setState({
      ...this.state,
      OtherMsg : otherReason
    })
  }
  selectOtherReasonType = (e) => {
    
    let likeReas = this.state.selectedLikeReasons;
    let dislikeReas = this.state.selectedDislikeReasons
    if( e.target.id === 'like') {
      dislikeReas = [];
    } else if( e.target.id === 'dislike') {
      likeReas = []
    }
    this.setState ({
    ...this.state,
    selectedOtherReasonType : e.target.id,
    selectedLikeReasons: likeReas,
    selectedDislikeReasons: dislikeReas
   })

  }
  setFeedBack = (actionType = false) => {
    let feedBackData = {};
    let OthrMsg = this.state.OtherMsg !== '' ? this.state.OtherMsg.trim() : '';
    let categories;
    let selectedOtherReasonType = this.state.selectedOtherReasonType;
    let newFeedback;
    if(actionType) {
      if(selectedOtherReasonType === 'like') {
        categories = this.state.selectedLikeReasons;
        newFeedback = 1;
      } else if(selectedOtherReasonType === 'dislike') {
        categories = this.state.selectedDislikeReasons
        newFeedback = -1;
      }
      feedBackData = {
        "UserName": this.props.userData.username,
        "Vote": newFeedback,
        "EventID": this.props.FeedId,
        "Categories": categories.join(';'),
        "OtherMessage": OthrMsg
      }
      this.props.setEventFeedBackWithReason(feedBackData);
      
      window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
      window.Mixpanel.MixpanelProps.EventId = this.props.FeedId;
      window.Mixpanel.MixpanelProps.Headline = this.props.HeadlineText;
      window.Mixpanel.MixpanelProps.Vote = newFeedback > 0 ? 'Liked' : newFeedback < 0 ? 'Disliked' : 'Neutral';
      window.Mixpanel.MixpanelProps.Reason = feedBackData.Categories;
      window.Mixpanel.MixpanelProps.OtherReason = feedBackData.OtherMessage;
      window.Mixpanel.actions.identify(this.props.userData.username);
      window.Mixpanel.actions.track(`Event ${window.Mixpanel.MixpanelProps.Vote}`, window.Mixpanel.MixpanelProps).then(data => {
        window.Mixpanel.actions.people.set({
          UserName: this.props.userData.username
        });
        window.Mixpanel.actions.resetProp('EventId');
        window.Mixpanel.actions.resetProp('Headline');
        window.Mixpanel.actions.resetProp('OtherReason');
      })
    } else {
      console.log(actionType)
      if(this.state.feedback === 1) {
        categories = this.state.selectedLikeReasons;
      } else if(this.state.feedback === -1) {
        categories = this.state.selectedDislikeReasons
      } else {
        categories = [];
      }
      newFeedback = this.state.feedback;
      feedBackData = {
        "UserName": this.props.userData.username,
        "Vote": this.state.feedback,
        "EventID": this.props.FeedId,
        "Categories": categories.length ? categories.join(';') : '',
        "OtherMessage": this.state.OtherMsg.trim()
      }
      window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
      window.Mixpanel.MixpanelProps.EventId = this.props.FeedId;
      window.Mixpanel.MixpanelProps.Headline = this.props.HeadlineText;
      window.Mixpanel.MixpanelProps.Vote = newFeedback > 0 ? 'Liked' : newFeedback < 0 ? 'Disliked' : 'Neutral';
      window.Mixpanel.MixpanelProps.Reason = feedBackData.Categories;
      window.Mixpanel.MixpanelProps.OtherReason = feedBackData.OtherMessage;
      window.Mixpanel.actions.identify(this.props.userData.username);
      window.Mixpanel.actions.track(`Event Like-Dislike cancelled`, window.Mixpanel.MixpanelProps).then(data => {
        window.Mixpanel.actions.people.set({
          UserName: this.props.userData.username
        });
        window.Mixpanel.actions.resetProp('EventId');
        window.Mixpanel.actions.resetProp('Headline');
        window.Mixpanel.actions.resetProp('OtherReason');
      })
    }
    this.setState({
      ...this.state,
      feedback: newFeedback,
      LDReasonTip : !this.state.LDReasonTip
    },() => {
      
      
      this.props.modalClose();
    })
  }
  render() {
    const { dislikeReasons, likeReasons, selectedDislikeReasons, selectedLikeReasons, OtherMsg, OtherMsgDisabled } = this.state;
    
    // let hasLikeAndDislikeicon = `${APPPATH.ImageUrl}/likedislikefalse.png`
    // if(selectedLikeReasons.length && selectedDislikeReasons.length) {
    //   hasLikeAndDislikeicon = `${APPPATH.ImageUrl}/likedislike.png`
    // } else if(!selectedLikeReasons.length && selectedDislikeReasons.length) {
    //   hasLikeAndDislikeicon = `${APPPATH.ImageUrl}/dislikeonly.png`
    // } else if(selectedLikeReasons.length && !selectedDislikeReasons.length) {
    //   hasLikeAndDislikeicon = `${APPPATH.ImageUrl}/likeonly.png`
    // }
    let dislikeReasonList = dislikeReasons.length? (
      dislikeReasons.map((item, i) => {
        let DChecked = false
        return (
          <Row className="marg-0 pad5" key={`dislike${i}`}>
            <Col xs={1} sm={1} md={1} xl={1} lg={1} className="align-self-center  margright15" >
              {
                DChecked = (selectedDislikeReasons.indexOf(item) > -1)
              }
              <label className="marg-0">
                <img src={`${DChecked ? APPPATH.ImageUrl+'/dislikefill.png' : APPPATH.ImageUrl+'/dislike.png'} `} className="pointer" alt="CrowdSense.ai" onClick={() => this.selectDislikeReason(item.trim())} />
              </label>
            </Col>
            <Col className="align-self-center pad5 text-left" >
              <span className=""> { item }</span>
            </Col>
          </Row>
        )
      })
    ) : (
      ''
    )
    let likeReasonList = likeReasons.length? (
      likeReasons.map((item, i) => {
        let LChecked = false
        return (
          <Row className="marg-0 pad5" key={`like${i}`}>
            <Col xs={1} sm={1} md={1} xl={1} lg={1} className="align-self-center margright15" >
              {
                LChecked = (selectedLikeReasons.indexOf(item) > -1)
              }
              <label className="marg-0 ">
                
                <img src={`${LChecked ? APPPATH.ImageUrl+'/likefill.png' : APPPATH.ImageUrl+'/like.png'} `} className="pointer" alt="CrowdSense.ai" onClick={() => this.selectLikeReason(item.trim())} />
              </label>
            </Col>
            <Col className="align-self-center pad5 text-left" >
              <span className=""> { item }</span>
            </Col>
          </Row>
        )
      })
    ) : (
      ''
    )
    return(
      // <Col className={`story-likedislike pointer margr2 nopad ${this.props.Active ? this.props.EventReadStatusMain ? '' : 'bg-headbar-unread' : this.props.EventCurated ? 'mag-bg-gray' : 'mag-bg-black'}`}>
      //   <Row className="h-100 my-auto marg-0">
      //     <Col className="align-self-center marg-0 nopad ldIcon-contain text-center">
      //       <img src={hasLikeAndDislikeicon} className="ldicon " alt="Like-Dislike" onMouseEnter={() => this.toggleToolTip(true)} onMouseLeave={() => this.toggleToolTip(false)} onPointerOut={() => this.toggleToolTip(false)} onClick={this.toggleReasonTip} />
          
      //       <Tooltip view={`Relevant/Irrelevant Event Feedback`} show={LDtooltip} type={`normal-right`}/>
      //     </Col>
      //     <div className="reason-popup">
            
            <Row className={`marg-0 padlr25 padb15`} >
              {/* <Col xs={12} sm={12} md={12} xl={12} lg={12} className="reason-tipHead" >Please select unlike reason</Col> */}
              <Col xs={12} sm={12} md={12} xl={12} lg={12} className="muteunmute-tipItem">
                <Row className="marg-0">
                  <Col className="storyOptionTitle">
                    <span>Like / Dislike alerts</span>
                  </Col>
                </Row>
              </Col>
              <Col xs={6} sm={6} md={6} xl={6} lg={6} className="reason-tipItem">
                { likeReasonList }
              </Col>
              <Col xs={6} sm={6} md={6} xl={6} lg={6} className="reason-tipItem">
                { dislikeReasonList }
              </Col>
              <Col xs={12} sm={12} md={12} xl={12} lg={12} className="reason-tipItem">
                <Row className="marg-0">
                  <Col xs={6} sm={6} md={6} xl={6} lg={6} className="align-self-center text-left padt5 padb5" >
                    <span className="bold500">{ `Other Reason` } </span>
                  </Col>
                  <Col xs={5} sm={5} md={5} xl={5} lg={5} className="align-self-center pad5 text-right" >
                    
                  </Col>
                </Row>
                <Row className="marg-0">
                  <Col xs={12} sm={12} md={12} xl={12} lg={12} className="align-self-center " >
                    <textarea type="text" className="form-control my-form-control dislike" rows="3" ref={this.OtherReasonInput} disabled={OtherMsgDisabled} placeholder={`${OtherMsgDisabled ? '' : 'Write your reason'}`} value={OtherMsg} onChange={this.HandleOtherReason}></textarea>
                  </Col>
                  
                </Row>
              </Col>
              <Col xs={12} sm={12} md={12} xl={12} lg={12} className="reason-tipItem padright10">
                <button type="button" className="btn btn-crowdsense-prime float-right marglr5" onClick={() => this.setFeedBack(true)}>Confirm</button>
                <button type="button" className="btn btn-crowdsense-border float-right marglr5" onClick={() => this.setFeedBack(false)}>Cancel</button>
              </Col>
            </Row>
          
    //       </div>
    //     </Row>
    // </Col> 
    )
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.settings.userData,
    userFeedbacks: state.settings.userFeedbacks,
    likedislikeToolTipHandler : state.settings.toolTipGlobals.likedislikeToolTipHandler,
    
  }
}

const mapDispatchToProps = {
  ...appSettingsActionCreators
};

export default connect(mapStateToProps, mapDispatchToProps)(LikeDislike)