import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { APPPATH } from '../../modules/helper.module';
import { Badge } from 'react-bootstrap';
import PremiumButton from '../shared/headbar/PremiumButton';
import { connect } from 'react-redux';
import * as settingActions from '../../actions/settings.action';

class SubscriptionPopUp extends Component {
  constructor(props) {
    super();
    console.log(props);
  }
  render() {
    // console.log(this.props.freeTrial, this.props.daystogo)
    let trialState = 0;
    let bannerText = '';
    
    if(!this.props.freeTrial && this.props.daystogo === 0) {
      trialState = 0;
    } else if(this.props.freeTrial && this.props.daystogo > 0) {
      trialState = 1;
    } else if(this.props.freeTrial && this.props.daystogo <= 0) {
      trialState = 2;
    }
    let button = trialState === 0 ? (
      <span className="btn tryforfree-btn" onClick={() => {
        //add mixpanel
        window.Mixpanel.MixpanelProps.UserName = this.props.userdata.username;
        window.Mixpanel.actions.identify(this.props.userdata.username);
        window.Mixpanel.actions
          .track('Try for free clicked', window.Mixpanel.MixpanelProps)
          .then((data) => {
            window.Mixpanel.actions.people.set({
              UserName: this.props.userdata.username,
            });
            this.props.setFreeTrial();
            this.props.onConfirm();
            window.location.reload();
          });
        
      }} >
        <img src={`${APPPATH.ImageUrl}/button-premium-small-noArrow.svg`} alt="CrowdSense.ai" style={{marginTop: '-1px'}} /><span style={{marginLeft: '-3px', padding: '5px 5px 0px 0px'}}>Try for Free</span>
      </span>
    ) : (
      <PremiumButton
        noTitle={false}
        align={true}
        beta={false}
        notLogo={true}
        popup={false}
        isCalledFromTrendingColumn={false}
      />
    )
    switch(trialState) {
      case 0:
        bannerText = 'For free 2-weeks trial of our premium features (no credit card needed)...';
        break;
      case 1:
        bannerText = `${this.props.daystogo} more days remaining on your premium trial. To keep using it for 70% off...`;
        break;
      case 2:
        bannerText = `Upgrade today for a limited-time 70% off offer`;
        break;
      default:
        bannerText = 'For faster real-time arrival of data for thousands of assets';
        break;
    }
    return (
      <SweetAlert
        customClass="upgrade-to-premium"
        showCloseButton
        title={
          <>
            <span
              style={{
                float: 'right',
                fontSize: 20,
                marginTop: -20,
                marginRight: -15,
                cursor: 'pointer',
                color: '#000',
              }}
              onClick={this.props.onConfirm}
            >
              {/* <i className="fa fa-times"></i> */}
              <img
                src={`${APPPATH.ImageUrl}/button-close-premium.svg`}
                className="search-init subscriptionPopupClose"
                alt=""
              />
            </span>
            <div>
              <span style={{ position: 'absolute', zIndex: 5601, top: '-70px', left: '90px' }}>
                <img src={`${APPPATH.ImageUrl}/badge-premium-big.svg`} alt="" style={{ width: '75px' }} />
              </span>
            </div>
            <div style={{ marginTop: 20, marginBottom: 10 }}>
              <div style={{ fontSize: 16, marginBottom: 15 }}>
                {
                  this.props.message && this.props.message !== '' ? (
                    <span>{this.props.message}</span>
                  ) : (
                    <span>Real-time and more comprehensive experience</span>
                  )
                }
                
              </div>
              {
                trialState === 0 ? (
                  <div>
                    <p style={{ fontSize: 12, color: '#000000', marginBottom: '0rem' }}>You can now experience</p>
                    <span style={{ color: '#50c0ff', fontSize: 30, fontWeight: 'bold', marginLeft: 10 }}>Premium</span>
                    <p style={{ fontSize: 12, color: '#000000', margin: 'unset' }}>For Free </p>
                  </div>
                ) : (
                  <div>
                    <p style={{ fontSize: 12, color: '#000000', marginBottom: '0rem' }}>For up to</p>
                    <span style={{ color: '#50c0ff', fontSize: 30, fontWeight: 'bold', marginLeft: 10 }}>70% Off</span>
                    <p style={{ fontSize: 12, color: '#000000', margin: 'unset' }}>Per Month </p>
                  </div>
                )
              }
              
            </div>
            <div>
              <div>
                <ul
                  className="premium-features-list"
                  style={{
                    fontSize: 14,
                    fontWeight: '500',
                    padding: '15px 10px 0px 20px',
                    listStyleImage: `url(${APPPATH.ImageUrl}/icon-tick.svg)`,
                    listStylePosition: 'inside',
                    textAlign: 'left',
                  }}
                >
                  <li className="">
                    <span>Faster events arrival time</span>
                  </li>
                  <li className="">
                    <span>Thousands of assets to check</span>
                  </li>
                  <li className="">
                    <span>More assets in your watchlist</span>
                  </li>
                  <li className="">
                    <span>More real-time notifications</span>
                  </li>
                  <li className="">
                    <span>No Advertisements</span>
                  </li>
                </ul>
              </div>
            </div>
          </>
        }
        style={{ backgroundColor: '#fff', color: '#000', width: '300px', height: '450px', overflow: 'unset' }}
        reverseButtons={true}
        customButtons={
          button
        }
      />
    );
  }
}

// export default SubscriptionPopUp;
const mapStateToApp = (state) => {
  return {
    userdata: state.settings.userData,
    freeTrial: state.settings.freeTrial,
    daystogo: state.settings.freeTrialDetails.daystogo
  };
};

const mapDispatchToProps = {
  ...settingActions
};

export default connect(mapStateToApp, mapDispatchToProps)(SubscriptionPopUp);
