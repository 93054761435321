import appGlobalState from '../appconfig';
import { NotifyFeedsHandler } from '../modules/helper.module';
import * as moment from 'moment';

const feedsReducer = (state = {...appGlobalState.feeds}, action) => {
    

    if(action.type === 'GET_STORIES'){
      const maxCount = state.tweetListMaxCount
      let breakingList = {};
      let breakingListIndexRef = [];
      let analysedList = {};
      let analysedListIndexRef = [];
      
      if(action.callForAnalysed === true) {
        if(action.StoriesIndex.length) {
          
          action.StoriesIndex.map((rs, indx) => {
              
            if(NotifyFeedsHandler.BreakingEventStatus(rs.notifyEvent, rs.magnitudeRate)) {
                
              breakingList[`${rs.FeedId}`] = action.Stories[`${rs.FeedId}`]
              breakingListIndexRef.push({
                FeedId : rs.FeedId,
                SortByDate : rs.SortByDate,
                notifyEvent : rs.notifyEvent,
                magnitudeRate : rs.magnitudeRate
              });
            }
  
            if(!(!action.Stories[`${rs.FeedId}`].Active && action.Stories[`${rs.FeedId}`].EventCurated) && !(!action.Stories[`${rs.FeedId}`].Active && !action.Stories[`${rs.FeedId}`].EventCurated)) {
              analysedList[`${rs.FeedId}`] = action.Stories[`${rs.FeedId}`]
              analysedListIndexRef.push({
                FeedId : rs.FeedId,
                SortByDate : rs.SortByDate,
                notifyEvent : rs.notifyEvent,
                magnitudeRate : rs.magnitudeRate
              });
            }

            return null;
          })
        }
        analysedListIndexRef.length = analysedListIndexRef.length > maxCount ? maxCount : analysedListIndexRef.length; 
        let limitedAnalysedList = {}
        analysedListIndexRef.map(itm => {
          limitedAnalysedList[`${itm.FeedId}`] = analysedList[`${itm.FeedId}`]
          return null;
        })

        breakingListIndexRef.length = breakingListIndexRef.length > maxCount ? maxCount : breakingListIndexRef.length; 
        let limitedBreakingStoriesList = {}
        breakingListIndexRef.map(itm => {
          limitedBreakingStoriesList[`${itm.FeedId}`] = breakingList[`${itm.FeedId}`]
          return null;
        })
        return {
          ...state,
          BreakingStories: breakingList,
          BreakingStoriesIndex: breakingListIndexRef,
          AnalysedStories: limitedAnalysedList,
          AnalysedStoriesIndex: analysedListIndexRef,
        }
      } else if(action.callForAnalysed === false) {
        action.StoriesIndex.length = action.StoriesIndex.length > maxCount ? maxCount : action.StoriesIndex.length;
        let limitedStoriesList = {}
        action.StoriesIndex.map(key => {
          limitedStoriesList[`${key.FeedId}`] = action.Stories[`${key.FeedId}`]
          return null;
        })
        let latestEventTime = NotifyFeedsHandler.getLatestEventTime(limitedStoriesList[action.StoriesIndex[0].FeedId])
        // console.log(limitedStoriesList[`${action.StoriesIndex[0].FeedId}`])
        
        // limitedStoriesList[`${action.StoriesIndex[0].FeedId}`].GeoLocation = "Bethlehem, Israel (31.705791, 35.200657)";
        // limitedStoriesList[`${action.StoriesIndex[0].FeedId}`].Radius = 1200;
        // limitedStoriesList[`${action.StoriesIndex[0].FeedId}`].EventMapMarkers = "Ra'anana, Israel (32.184448, 34.870766)~Ashdod, Israel (31.801447, 34.643497)~Nazareth, Israel (32.699635, 35.303547)~Bat Yam, Tel-Aviv, Israel (32.017136, 34.745441)~Haifa, Israel (32.794044, 34.989571)~Karmiel, Israel (32.919945, 35.290146)~Herzliya, Tel-Aviv, Israel (32.166313, 34.843311)~Rehovot, Israel (31.894756, 34.809322)";
        // console.log('settingSTate for get_stories', moment().format('HH:mm:ss'))
        return {
          ...state,
          Stories: limitedStoriesList,
          StoriesIndex: action.StoriesIndex,
          StoriesLatestDate: latestEventTime,
        }
      } else if(action.callForAnalysed === 'mix') {
        if(action.StoriesIndex.length) {
          
          action.StoriesIndex.map((rs, indx) => {
              
            if(NotifyFeedsHandler.BreakingEventStatus(rs.notifyEvent, rs.magnitudeRate)) {
              
              breakingList[`${rs.FeedId}`] = action.Stories[`${rs.FeedId}`]
              breakingListIndexRef.push({
                FeedId : rs.FeedId,
                SortByDate : rs.SortByDate,
                notifyEvent : rs.notifyEvent,
                magnitudeRate : rs.magnitudeRate
              });
            }
  
            if(!(!action.Stories[`${rs.FeedId}`].Active && action.Stories[`${rs.FeedId}`].EventCurated) && !(!action.Stories[`${rs.FeedId}`].Active && !action.Stories[`${rs.FeedId}`].EventCurated)) {
              analysedList[`${rs.FeedId}`] = action.Stories[`${rs.FeedId}`]
              analysedListIndexRef.push({
                FeedId : rs.FeedId,
                SortByDate : rs.SortByDate,
                notifyEvent : rs.notifyEvent,
                magnitudeRate : rs.magnitudeRate
              });
            }
            return null;
          })
        }
        analysedListIndexRef.length = analysedListIndexRef.length > maxCount ? maxCount : analysedListIndexRef.length; 
        let limitedAnalysedList = {}
        analysedListIndexRef.map(itm => {
          limitedAnalysedList[`${itm.FeedId}`] = analysedList[`${itm.FeedId}`]
          return null;
        })

        breakingListIndexRef.length = breakingListIndexRef.length > maxCount ? maxCount : breakingListIndexRef.length; 
        let limitedBreakingStoriesList = {}
        breakingListIndexRef.map(itm => {
          limitedBreakingStoriesList[`${itm.FeedId}`] = breakingList[`${itm.FeedId}`]
          return null;
        })

        action.StoriesIndex.length = action.StoriesIndex.length > maxCount ? maxCount : action.StoriesIndex.length;
        let limitedStoriesList = {}
        action.StoriesIndex.map(key => {
          limitedStoriesList[`${key.FeedId}`] = action.Stories[`${key.FeedId}`]
          return null;
        })

        let latestEventTime = NotifyFeedsHandler.getLatestEventTime(limitedStoriesList[action.StoriesIndex[0].FeedId])

        return {
          ...state,
          Stories: limitedStoriesList,
          StoriesIndex: action.StoriesIndex,
          StoriesLatestDate: latestEventTime,
          BreakingStories: breakingList,
          BreakingStoriesIndex: breakingListIndexRef,
          AnalysedStories: limitedAnalysedList,
          AnalysedStoriesIndex: analysedListIndexRef,
        }
      }
    }

    if(action.type === 'GET_STORIES_BY_PACKAGE') {

      // console.log('GET_STORIES_BY_PACKAGE', action.payload)
      const maxCount = state.tweetListMaxCount;
      let breakingList = {};
      let breakingListIndexRef = [];
      let analysedList = {};
      let analysedListIndexRef = [];
      
      if(action.StoriesIndex.length) {
        action.StoriesIndex.map((rs, indx) => {
          if(NotifyFeedsHandler.BreakingEventStatus(rs.notifyEvent, rs.magnitudeRate)) {
            breakingList[`${rs.FeedId}`] = action.Stories[`${rs.FeedId}`]
            breakingListIndexRef.push({
              FeedId : rs.FeedId,
              SortByDate : rs.SortByDate,
              notifyEvent : rs.notifyEvent,
              magnitudeRate : rs.magnitudeRate
            });
          }
          if(!(!action.Stories[`${rs.FeedId}`].Active && action.Stories[`${rs.FeedId}`].EventCurated) && !(!action.Stories[`${rs.FeedId}`].Active && !action.Stories[`${rs.FeedId}`].EventCurated)) {
            analysedList[`${rs.FeedId}`] = action.Stories[`${rs.FeedId}`]
            analysedListIndexRef.push({
              FeedId : rs.FeedId,
              SortByDate : rs.SortByDate,
              notifyEvent : rs.notifyEvent,
              magnitudeRate : rs.magnitudeRate
            });
          }
          return null;
        })
      }

      // let oldStoriesList = state.Stories
      // let oldStoriesIndex = state.StoriesIndex
      // let oldBreakingStories = state.BreakingStories
      // let oldBreakingStoriesIndex = state.BreakingStoriesIndex
      // let oldAnalysedStories = state.AnalysedStories
      // let oldAnalysedStoriesIndex = state.AnalysedStoriesIndex

      
      
      // let StoriesData = NotifyFeedsHandler.UniqueMergeEventsByPackage(oldStoriesList, oldStoriesIndex, action.Stories, action.StoriesIndex);
      // let newStoriesList = StoriesData.newList
      // let newStoriesIndex = StoriesData.newIndex
      // if(action.NACE) {
      //   if(action.withCurated) {
      //     newStoriesList = {}
      //     newStoriesIndex = []
      //     newStoriesList = action.Stories
      //     newStoriesIndex = action.StoriesIndex
      //   }
      // }
      

      // let AnalysedStoriesData = NotifyFeedsHandler.UniqueMergeEventsByPackage(oldAnalysedStories, oldAnalysedStoriesIndex, analysedList, analysedListIndexRef);
      // let newAnalysedStoriesList = AnalysedStoriesData.newList;
      // let newAnalysedStoriesIndex = AnalysedStoriesData.newIndex;

      // let BreakingStoriesData = NotifyFeedsHandler.UniqueMergeEventsByPackage(oldBreakingStories, oldBreakingStoriesIndex, breakingList, breakingListIndexRef);
      // let newBreakingStoriesList = BreakingStoriesData.newList;
      // let newBreakingStoriesIndex = BreakingStoriesData.newIndex;
      

      let sortedNewAnalysedStoriesIndex = analysedListIndexRef.sort((a,b) => {
        return moment(b.sortingtime) - moment(a.sortingtime)
      });
      sortedNewAnalysedStoriesIndex.length = sortedNewAnalysedStoriesIndex.length > maxCount ? maxCount : sortedNewAnalysedStoriesIndex.length; 
      let limitedAnalysedList = {}
      sortedNewAnalysedStoriesIndex.map(itm => {
        limitedAnalysedList[`${itm.FeedId}`] = analysedList[`${itm.FeedId}`]
        return null;
      })
      

      let sortedNewBreakingStoriesIndex = breakingListIndexRef.sort((a,b) => {
        return moment(b.sortingtime) - moment(a.sortingtime)
      });
      sortedNewBreakingStoriesIndex.length = sortedNewBreakingStoriesIndex.length > maxCount ? maxCount : sortedNewBreakingStoriesIndex.length; 
      let limitedBreakingStoriesList = {}
      sortedNewBreakingStoriesIndex.map(itm => {
        limitedBreakingStoriesList[`${itm.FeedId}`] = breakingList[`${itm.FeedId}`]
        return null;
      })
      

      let sortedNewStoriesIndex = action.StoriesIndex.sort((a,b) => {
        // console.log(b.sortingtime, a.sortingtime)
        return moment(b.SortByDate) - moment(a.SortByDate)
      });
      // sortedNewStoriesIndex.length = sortedNewStoriesIndex.length > maxCount ? maxCount : sortedNewStoriesIndex.length;
      let limitedStoriesList = {}
      sortedNewStoriesIndex.map(key => {
        limitedStoriesList[`${key.FeedId}`] = action.Stories[`${key.FeedId}`]
        return null;
      })
      
      // let grayEvents = [];
      // let otherEvents = [];
      // sortedNewStoriesIndex.map(key => {
      //   if(!(!newStoriesList[`${key.FeedId}`].Active && newStoriesList[`${key.FeedId}`].EventCurated) && !(!newStoriesList[`${key.FeedId}`].Active && !newStoriesList[`${key.FeedId}`].EventCurated)) {
      //     otherEvents.push(key)
      //   } else {
      //     grayEvents.push(key)
      //   }
      // })
      // sortedNewStoriesIndex = [];
      // sortedNewStoriesIndex = grayEvents.concat(otherEvents);
      // let SearchForEventsFromSecFilters = sortedNewStoriesIndex.length >= 10 ? false : true
      let latestEventTime = sortedNewStoriesIndex.length && NotifyFeedsHandler.getLatestEventTime(limitedStoriesList[sortedNewStoriesIndex[0].FeedId]);
      // console.log(latestEventTime)
      return {
        ...state,
        Stories: JSON.parse(JSON.stringify(limitedStoriesList)),
        StoriesIndex: JSON.parse(JSON.stringify(sortedNewStoriesIndex)),
        StoriesLatestDate: latestEventTime,
        BreakingStories: limitedBreakingStoriesList,
        BreakingStoriesIndex: sortedNewBreakingStoriesIndex,
        AnalysedStories: limitedAnalysedList,
        AnalysedStoriesIndex: sortedNewAnalysedStoriesIndex,
        // SearchForEventsFromSecFilters
      }
    }

    if(action.type === 'SET_PREVENT_STORY_UPDATE') {
      return {
        ...state,
        preventStoryUpdate: action.payload
      }
    }

    if(action.type === 'SET_FEEDREQDATA') {
      return {
        ...state,
        feedReqData : action.payload
      }
    }
    if(action.type === 'UPDATE_STORYEVENT_LIKES') {
      let stories = state.Stories
      if(stories[action.payload.EventID]) {
        let Story = stories[action.payload.EventID]
        Story.NumberOfLikes = action.payload.Like === 1 ? parseInt(Story.NumberOfLikes)+1 : parseInt(Story.NumberOfLikes)-1
        return {
          ...state,
          Stories: {
            ...state.Stories,
            [action.payload.EventID]: JSON.parse(JSON.stringify(Story))
          }
        }
      }
    }

    if(action.type === 'UPDATE_STORIES') {
      
      if(!state.preventStoryUpdate) {
        // console.log("UPDATE_STORIES Start")
      
        const preferences = action.preferences;
        const webNotifications = action.webNotifications;
        const tweetListMaxCount = state.tweetListMaxCount;
        const StoriesLatestDate = state.StoriesLatestDate;
        let { webBeep, DesktopNotifications } = action.updateHandle.getDesktopWebBeepNotificationPreferences(preferences)
        // const isTrayTriggered = sessionStorage.getItem('trayapptrig');
        // console.log(webBeep, DesktopNotifications)
        // console.log(webBeep)
        let notif = {
          has: false,
          title: 'Crowdsense Notifications',
          body: '',
        }
        let CanBeep = [];
        let newStories = state.Stories;
        let newStoriesIndex = state.StoriesIndex;
        let BreakingStories = state.BreakingStories
        let BreakingStoriesIndex = state.BreakingStoriesIndex
        let AnalysedStories = state.AnalysedStories
        let AnalysedStoriesIndex = state.AnalysedStoriesIndex
  
        let incomingBreakingFeeds = {};
        let incomingBreakingFeedsIndex = [];
        let incomingAnalysedFeeds = {};
        let incomingAnalysedFeedsIndex = [];

        let RootFeedIdArr = Object.keys(newStories);
        let RootFeedHeadlines = RootFeedIdArr.map(it => (newStories[it].HeadlineText && newStories[it].HeadlineText.trim()))

        let BreakingFeedIdArr = Object.keys(BreakingStories);
        let BreakingFeedHeadlines = BreakingFeedIdArr.map(it => (BreakingStories[it].HeadlineText && BreakingStories[it].HeadlineText.trim()))

        let AnalysedFeedIdArr = Object.keys(AnalysedStories);
        let AnalysedFeedHeadlines = AnalysedFeedIdArr.length > 0 ? (AnalysedFeedIdArr.map(it => (AnalysedStories[it].HeadlineText && AnalysedStories[it].HeadlineText ? AnalysedStories[it].HeadlineText.trim() : ''))).filter(el => (el)) : ([])
  
        // let incomingFeeds = {}
        // let incomingFeedsIndex = [];
  
        // let incBlacklistedIds = [];
  
        // console.log(action.UpdatedStories, action.UpdatedStoriesIndex)
        
        // let LatestStoryTimeFilter = moment(now).diff(then);
        // console.log(then, LatestStoryTimeFilter, now)
        // console.log(Object.keys(action.UpdatedStories).length)
        
        if(Object.keys(action.UpdatedStories).length) {
          if(StoriesLatestDate) {
            
            let incids = Object.keys(action.UpdatedStories);
            incids.forEach((fdid,indx) => {
            let latestEventTime = NotifyFeedsHandler.getLatestEventTime(action.UpdatedStories[fdid])
            // console.log(StoriesLatestDate, latestEventTime)
              let then = moment(latestEventTime);
              let now = moment(StoriesLatestDate);
              let LatestStoryTimeDiff = moment(now).diff(then);
              // console.log(LatestStoryTimeDiff, latestEventTime, StoriesLatestDate)
                if(LatestStoryTimeDiff > 0) {
                  // console.log(LatestStoryTimeDiff)
                  // if((!action.UpdatedStories[fdid].Active && action.UpdatedStories[fdid].EventCurated) || (!action.UpdatedStories[fdid].Active && !action.UpdatedStories[fdid].EventCurated)) {
                    // console.log('curated ones or black ones', fdid)
                    if(RootFeedIdArr.indexOf(fdid) === -1 || RootFeedHeadlines.indexOf(action.UpdatedStories[fdid].HeadlineText) === -1) {
                      // let newindex = action.UpdatedStoriesIndex.map(itm => (itm.FeedId !== fdid)).filter(itm => (itm))
                      let newindex = action.UpdatedStoriesIndex.findIndex(itm => (itm.FeedId.toString() === fdid.toString()))
                      // console.log(newindex)
                      delete action.UpdatedStories[fdid];
                      action.UpdatedStoriesIndex.splice(newindex, 1)
                    }
                    
                  // }
            
                }
            })
          }
          // let incids = Object.keys(action.UpdatedStories);
          // incids.forEach((fdid,indx) => {
          //   let sortByDate = action.UpdatedStories[fdid].SortByDate
          //   let lastFeedsUpdateTime = action.UpdatedStories[fdid].LastFeedsUpdateTime
          //   let LatestTimeDiff = moment(lastFeedsUpdateTime).diff(sortByDate);
          //   if(LatestTimeDiff > 0) {
          //     let newindex = action.UpdatedStoriesIndex.findIndex(itm => (itm.FeedId.toString() === fdid.toString()))
          //     // console.log(newindex)
          //     delete action.UpdatedStories[fdid];
          //     action.UpdatedStoriesIndex.splice(newindex, 1)
          //   }
          // })
        }
        // console.log(action.UpdatedStories, action.UpdatedStoriesIndex)

        if(action.UpdatedStoriesIndex !== null && action.UpdatedStoriesIndex.length > 0) {
          // console.log(action.UpdatedStories, action.UpdatedStoriesIndex)
          // let newUpdatedStoriesIndex = [];
          
          // splitting updates into breaking and non breaking and analysed-------------------------------------------------------------//
          action.UpdatedStoriesIndex.map((rs) => {
            if(NotifyFeedsHandler.BreakingEventStatus(rs.notifyEvent, rs.magnitudeRate)) {
              incomingBreakingFeeds[`${rs.FeedId}`] = action.UpdatedStories[`${rs.FeedId}`];
              incomingBreakingFeedsIndex.push({
                FeedId : rs.FeedId,
                SortByDate : rs.SortByDate,
                notifyEvent : rs.NotifyEvent,
                magnitudeRate : rs.MagnitudeRate
              })
            }
            if(!(!action.UpdatedStories[`${rs.FeedId}`].Active && action.UpdatedStories[`${rs.FeedId}`].EventCurated) && !(!action.UpdatedStories[`${rs.FeedId}`].Active && !action.UpdatedStories[`${rs.FeedId}`].EventCurated)) {
              incomingAnalysedFeeds[`${rs.FeedId}`] = action.UpdatedStories[`${rs.FeedId}`]
              incomingAnalysedFeedsIndex.push({
                FeedId : rs.FeedId,
                SortByDate : rs.SortByDate,
                notifyEvent : rs.notifyEvent,
                magnitudeRate : rs.magnitudeRate
              });
            } 
            // else {
            //   incomingFeeds[`${rs.FeedId}`] = action.UpdatedStories[`${rs.FeedId}`];
            //   incomingFeedsIndex.push({
            //     FeedId : rs.FeedId,
            //     SortByDate : rs.SortByDate,
            //     notifyEvent : rs.NotifyEvent,
            //     magnitudeRate : rs.MagnitudeRate
            //   })
            // }
            return null;
          })
          //---------------------------------------------------------------------------------------------------------------//
          // console.log("1_1 We are in the if of the UPDATE_STORIES")
          // console.log(incomingFeeds, incomingBreakingFeeds)
          
          
          let incomingFeeds = action.UpdatedStories
          let notifBodyData = []
  
          
          
         

          let incomingFeedIdArr = Object.keys(incomingFeeds);
          
          // let incomingFeedHeadlines = incomingFeedIdArr.map(it => (incomingFeeds[it].HeadlineText.trim()))
        
          let incomingBreakingFeedIdArr = Object.keys(incomingBreakingFeeds);
          // let incomingBreakingFeedHeadlines = incomingBreakingFeedIdArr.map(it => (incomingBreakingFeeds[it].HeadlineText.trim()))
  
          let incomingAnalysedFeedIdArr = Object.keys(incomingAnalysedFeeds);
          // let incomingAnalysedFeedHeadlines = incomingAnalysedFeedIdArr.map(it => (incomingAnalysedFeeds[it].HeadlineText.trim()))
          //----------------------------------------Existing Update Analysed-------------------------------------------------//
            AnalysedFeedIdArr.forEach(eachFeedId => {
              let eachFeed = AnalysedStories[eachFeedId]
              if(incomingAnalysedFeedIdArr.indexOf(eachFeedId) > -1) {
                // console.log('update came in for breaking feeds')
                let inc = incomingAnalysedFeeds[eachFeedId];
                let { canbeep, canNotifyDesktop } = action.updateHandle.getCanNotify(inc, webBeep, DesktopNotifications, action.systemTime)
                CanBeep = CanBeep.concat(canbeep);
                if(canNotifyDesktop && webNotifications) {
                  notifBodyData = action.updateHandle.ifCanNotify(inc, eachFeed, notifBodyData);
                }
                let updateKey = eachFeedId;
                AnalysedStories[eachFeedId] = JSON.parse(JSON.stringify(inc));
                incomingAnalysedFeedIdArr.splice(incomingAnalysedFeedIdArr.indexOf(updateKey), 1);
              }
            })
            notif = action.updateHandle.addToNotif(notifBodyData, notif);
          //-----------------------------------------New Update Breaking---------------------------------------------------//
  
            notifBodyData = [];
            let analysedfiltInc = incomingAnalysedFeedIdArr.filter(inFdid => { return (AnalysedFeedIdArr.indexOf(inFdid) === -1) })
            analysedfiltInc.forEach((item, i) => {
              let incNewFeed = incomingAnalysedFeeds[item];
              if(AnalysedFeedHeadlines.indexOf(incNewFeed.HeadlineText) > -1) {
                // console.log('duplicate came in for breaking update')
                let existingHeadLineFeed = AnalysedStories[AnalysedFeedIdArr[AnalysedFeedHeadlines.indexOf(incNewFeed.HeadlineText)]];
                let existingHeadlineFeedIndex = '';
                if(AnalysedStories && Object.keys(AnalysedStories).length) {
                  Object.keys(AnalysedStories).forEach((FeedId, indx) => {
                    if(existingHeadLineFeed.FeedId && FeedId === existingHeadLineFeed.FeedId) {
                      existingHeadlineFeedIndex = indx;
                    }
                  })
                }
                
                AnalysedStories = Object.assign({[`${item}`]: incomingAnalysedFeeds[item]}, AnalysedStories)
                AnalysedStoriesIndex[existingHeadlineFeedIndex] = {
                  FeedId : item,
                  SortByDate : incomingAnalysedFeeds[item].SortByDate,
                  notifyEvent : incomingAnalysedFeeds[item].NotifyEvent,
                  magnitudeRate : incomingAnalysedFeeds[item].MagnitudeRate
                }
                let changednewStories = {}
                AnalysedStoriesIndex.forEach((bs) => {
                  changednewStories[`${bs.FeedId}`] = AnalysedStories[`${bs.FeedId}`]
                })
                AnalysedStories = {};
                AnalysedStories = changednewStories;
              } else {
                // console.log('new update came in for Analysed feeds')
                let nowTime = moment();
                let thenTime = moment(incomingAnalysedFeeds[item].FeedTime);
                let days = moment(nowTime,'DD-MM-YYYYThh:mm:ss').diff(thenTime, 'days');
                if(days < 2){
                  AnalysedStoriesIndex.unshift({
                    FeedId : item,
                    SortByDate : incomingAnalysedFeeds[item].SortByDate,
                    notifyEvent : incomingAnalysedFeeds[item].NotifyEvent,
                    magnitudeRate : incomingAnalysedFeeds[item].MagnitudeRate
                  })
                  AnalysedStories = Object.assign({[`${item}`]: incomingAnalysedFeeds[item]}, AnalysedStories)
  
                  AnalysedStoriesIndex.length = AnalysedStoriesIndex.length && AnalysedStoriesIndex.length > tweetListMaxCount ? tweetListMaxCount : AnalysedStoriesIndex.length;
                  
                  let limitedNewStories = {}
                  if(AnalysedStoriesIndex.length && AnalysedStoriesIndex.length) {
                    AnalysedStoriesIndex.map(stindx => {
                      limitedNewStories[`${stindx.FeedId}`] = AnalysedStories[stindx.FeedId];
                      return null;
                    })
                    return null;
                  }
                  AnalysedStories = {};
                  AnalysedStories = limitedNewStories;
                }
              }
              let { canbeep, canNotifyDesktop } = action.updateHandle.getCanNotify(incomingAnalysedFeeds[item], webBeep, DesktopNotifications, action.systemTime);
              CanBeep = CanBeep.concat(canbeep);
              if(canNotifyDesktop && webNotifications) {
                notifBodyData.push(`${incomingFeeds[item].HeadlineText}$$${incomingFeeds[item].FeedId.toString()}`);
              }
            })
            notif = action.updateHandle.addToNotif(notifBodyData, notif);
  
          //----------------------------------------Existing Update Breaking-------------------------------------------------//
          BreakingFeedIdArr.forEach(eachFeedId => {
            let eachFeed = BreakingStories[eachFeedId]
            if(incomingBreakingFeedIdArr.indexOf(eachFeedId) > -1) {
              // console.log('update came in for breaking feeds')
              let inc = incomingBreakingFeeds[eachFeedId];
              let { canbeep, canNotifyDesktop } = action.updateHandle.getCanNotify(inc, webBeep, DesktopNotifications, action.systemTime)
              CanBeep = CanBeep.concat(canbeep);
              if(canNotifyDesktop && webNotifications) {
                notifBodyData = action.updateHandle.ifCanNotify(inc, eachFeed, notifBodyData);
              }
              let updateKey = eachFeedId;
              BreakingStories[eachFeedId] = JSON.parse(JSON.stringify(inc));
              incomingBreakingFeedIdArr.splice(incomingBreakingFeedIdArr.indexOf(updateKey), 1);
            }
          })
          notif = action.updateHandle.addToNotif(notifBodyData, notif);
        
          //-----------------------------------------New Update Breaking---------------------------------------------------//
          notifBodyData = [];
          let breakingfiltInc = incomingBreakingFeedIdArr.filter(inFdid => { return (BreakingFeedIdArr.indexOf(inFdid) === -1) })
          breakingfiltInc.forEach((item, i) => {
            let incNewFeed = incomingBreakingFeeds[item];
            if(BreakingFeedHeadlines.indexOf(incNewFeed.HeadlineText) > -1) {
              // console.log('duplicate came in for breaking update')
              let existingHeadLineFeed = BreakingStories[BreakingFeedIdArr[BreakingFeedHeadlines.indexOf(incNewFeed.HeadlineText)]];
              let existingHeadlineFeedIndex = '';
              if(BreakingStories && Object.keys(BreakingStories).length) {
                Object.keys(BreakingStories).forEach((FeedId, indx) => {
                  if(existingHeadLineFeed.FeedId && FeedId === existingHeadLineFeed.FeedId) {
                    existingHeadlineFeedIndex = indx;
                  }
                })
              }
              BreakingStories = Object.assign({[`${item}`]: incomingBreakingFeeds[item]}, BreakingStories)
              BreakingStoriesIndex[existingHeadlineFeedIndex] = {
                FeedId : item,
                SortByDate : incomingBreakingFeeds[item].SortByDate,
                notifyEvent : incomingBreakingFeeds[item].NotifyEvent,
                magnitudeRate : incomingBreakingFeeds[item].MagnitudeRate
              }
              let changednewStories = {}
              BreakingStoriesIndex.forEach((bs) => {
                changednewStories[`${bs.FeedId}`] = BreakingStories[`${bs.FeedId}`]
              })
              BreakingStories = {};
              BreakingStories = changednewStories;
            } else {
              // console.log('new update came in for breaking feeds')
              let nowTime = moment();
              let thenTime = moment(incomingBreakingFeeds[item].FeedTime);
              let days = moment(nowTime,'DD-MM-YYYYThh:mm:ss').diff(thenTime, 'days');
              if(days < 2){
                BreakingStoriesIndex.unshift({
                  FeedId : item,
                  SortByDate : incomingBreakingFeeds[item].SortByDate,
                  notifyEvent : incomingBreakingFeeds[item].NotifyEvent,
                  magnitudeRate : incomingBreakingFeeds[item].MagnitudeRate
                })
                BreakingStories = Object.assign({[`${item}`]: incomingBreakingFeeds[item]}, BreakingStories)
  
                BreakingStoriesIndex.length = BreakingStoriesIndex.length && BreakingStoriesIndex.length > tweetListMaxCount ? tweetListMaxCount : BreakingStoriesIndex.length;
               
                let limitedNewStories = {}
                if(BreakingStoriesIndex.length && BreakingStoriesIndex.length > 0) {
                  BreakingStoriesIndex.map(stindx => {
                    limitedNewStories[`${stindx.FeedId}`] = BreakingStories[stindx.FeedId];
                    return null;
                  })
                }
                BreakingStories = {};
                BreakingStories = limitedNewStories;
              }
            }
            let { canbeep, canNotifyDesktop } = action.updateHandle.getCanNotify(incomingBreakingFeeds[item], webBeep, DesktopNotifications, action.systemTime);
            CanBeep = CanBeep.concat(canbeep);
            if(canNotifyDesktop && webNotifications) {
              notifBodyData.push(`${incomingFeeds[item].HeadlineText}$$${incomingFeeds[item].FeedId.toString()}`);
            }
          })
          notif = action.updateHandle.addToNotif(notifBodyData, notif);
  
          //--------------------------------------Existing Update for Regular Feeds----------------------------------------//
          // let CanBeep = [];
          RootFeedIdArr.forEach(eachFeedId => {
            let eachFeed = newStories[eachFeedId];
            // if(incomingFeedIdArr.indexOf(eachFeedId) > -1 || incomingFeedHeadlines.indexOf(eachFeed.HeadlineText.trim()) > -1) {
            if(incomingFeedIdArr.indexOf(eachFeedId) > -1) {
              // console.log('regular update existing feed')
              // incomingFeeds[incomingFeedIdArr[incomingFeedHeadlines.indexOf(eachFeed.HeadlineText.trim())]]
              // let inc = incomingFeedIdArr.indexOf(eachFeedId) > -1 ? incomingFeeds[eachFeedId] : incomingFeeds[incomingFeedIdArr[incomingFeedHeadlines.indexOf(eachFeed.HeadlineText.trim())]];
              let inc = incomingFeeds[eachFeedId];
              // let { CanBeep, canNotifyDesktop } = action.updateHandle.getCanNotify(inc, webBeep, DesktopNotifications, action.systemTime)
              let canNotifyDesktop = true;
              switch(inc.MagnitudeRate) {
                case 0:
                  if(webBeep.indexOf('Gray_Colored_Events') === -1) { CanBeep.push(0); }
                  else { CanBeep.push(1); }
  
                  if(DesktopNotifications.indexOf('Low') === -1) {
                    if(DesktopNotifications.indexOf('Low_BreakingOnly') > -1) {
                      if(!inc.NotifyEvent) {
                        canNotifyDesktop = false;
                      }
                    } else {
                      canNotifyDesktop = false;
                    }
                  }
                  break;
                case 1:
                  if(webBeep.indexOf('Yellow_Colored_Events') === -1) { CanBeep.push(0); }
                  else { CanBeep.push(1); }
  
                  if(DesktopNotifications.indexOf('Medium') === -1) {
                    if(DesktopNotifications.indexOf('Medium_BreakingOnly') > -1) {
                      if(!inc.NotifyEvent) {
                        canNotifyDesktop = false;
                      }
                    } else {
                      canNotifyDesktop = false;
                    }
                  }
                  break;
                case 2:
                  if(webBeep.indexOf('Red_Colored_Events') === -1) { CanBeep.push(0); }
                  else { CanBeep.push(1); }
  
                  if(DesktopNotifications.indexOf('High') === -1) {
                    if(DesktopNotifications.indexOf('High_BreakingOnly') > -1) {
                      if(!inc.NotifyEvent) {
                        canNotifyDesktop = false;
                      }
                    } else {
                      canNotifyDesktop = false;
                    }
                  }
                  break;
                default: break;
              }
              if(!inc.Active) {
                if(!inc.EventCurated) {
                  canNotifyDesktop = false;
                } else {
                  if(!preferences.event_importance_filters.Non_Active_Curated_Events.Desktop_Notifications_NACE) {
                    canNotifyDesktop = false;
                  }
                }
                
              }
              if(action.systemTime.TimeToNextState !== -1) {
                if(!action.systemTime.IsOpen && (action.systemTime.IsSpecialClose || action.systemTime.IsWeekend)) {
                  canNotifyDesktop = false;
                }
              } else {
                if(action.systemTime.IsSpecialClose) {
                  canNotifyDesktop = false;
                }
              }
              if(canNotifyDesktop && webNotifications) {
                      
                let LastAttributesUpdateTime = moment(inc.LastAttributesUpdateTime).subtract(1, 'minute');
                let LastEventTypeUpdateTime = moment(inc.LastEventTypeUpdateTime).subtract(1, 'minute');
                let LastLocationUpdateTime = moment(inc.LastLocationUpdateTime).subtract(1, 'minute');
  
                if(moment(eachFeed.LastAttributesUpdateTime) < LastAttributesUpdateTime || moment(eachFeed.LastEventTypeUpdateTime) < LastEventTypeUpdateTime || moment(eachFeed.LastLocationUpdateTime) < LastLocationUpdateTime) {
                  console.log(Date(Date.now()).toString() + ': Notification Approved, FeedId: ' + inc.FeedId.toString());
                  console.log('LastAttributesUpdateTime eachFeed: ' + eachFeed.LastAttributesUpdateTime);
                  console.log('LastAttributesUpdateTime inc: ' + LastAttributesUpdateTime.toString());
                  console.log('LastEventTypeUpdateTime eachFeed: ' + eachFeed.LastEventTypeUpdateTime);
                  console.log('LastEventTypeUpdateTime inc: ' + LastEventTypeUpdateTime.toString());
                  console.log('LastLocationUpdateTime eachFeed: ' + eachFeed.LastLocationUpdateTime);
                  console.log('LastLocationUpdateTime inc: ' + LastLocationUpdateTime.toString());
                  // let RootChangeLogList = inc.RootChangeLogList;
                  // let alertType = '';
                  // if(RootChangeLogList !== [] && RootChangeLogList.length > 0){
              
                  //   switch(RootChangeLogList[RootChangeLogList.length - 1].UpdateTypeCategory) {
                  //     case 'Activate':
                  //       alertType = 'Event Alert';
                  //       break;
                  //     case 'Detected':
                  //       alertType = 'Event Detected';
                  //       break;
                  //     case 'Details Level':
                  //       alertType = 'Integrity Updated';
                  //       break;
                  //     case 'Event Type':
                  //       alertType = 'Event Type Updated';
                  //       break;
                  //     case 'Attributes':
                  //       alertType = 'Topics Updated';
                  //       break;
                  //     case 'Location':
                  //       alertType = 'Location Updated';
                  //       break;
                  //     case 'Magnitude':
                  //       alertType = 'Magnitude Updated';
                  //       break;
                  //     default: 
                  //       alertType = 'Major Update';
                  //       break;
                  //   }
                  // }
                  // notifBodyData.push(`${alertType} in ${inc.Location} ${inc.MentionTypes ? '('+inc.MentionTypes+')' : ''}$$${inc.FeedId.toString()}`)
                  notifBodyData.push(`${inc.HeadlineText}$$${inc.FeedId.toString()}`)
                  // actualRootFeeds.push(inc)
                }
                else
                {
                  console.log(Date(Date.now()).toString() + ': Notification Denined, FeedId: ' + inc.FeedId.toString());
                }
              }
              let updateKey = eachFeedId;
              // console.log(newStories[eachFeedId], inc)
              newStories[eachFeedId] = JSON.parse(JSON.stringify(inc));
              incomingFeedIdArr.splice(incomingFeedIdArr.indexOf(updateKey), 1)
              // console.log(inc.FeedId, eachFeedId)
              // if(inc.FeedId === parseInt(eachFeedId)) {
              //   newStories[eachFeedId] = JSON.parse(JSON.stringify(inc));
              // } else {
              //   delete newStories[eachFeedId];
              //   newStories[inc.FeedId] = JSON.parse(JSON.stringify(inc));
              //   // console.log(newStoriesIndex)
              //   let newStoriesIndexIdArr = newStoriesIndex.map(itm => (itm.FeedId));
              //   // console.log(newStoriesIndexIdArr, eachFeedId, newStoriesIndexIdArr.indexOf(parseInt(eachFeedId)))
              //   if(newStoriesIndexIdArr.indexOf(parseInt(eachFeedId)) > -1) {
              //     newStoriesIndex.splice(newStoriesIndexIdArr.indexOf(parseInt(eachFeedId)), 1);
              //   }
              //   // console.log(newStoriesIndex)
              //   newStoriesIndex.push({
              //     FeedId : inc.FeedId,
              //     SortByDate : inc.SortByDate,
              //     notifyEvent : inc.NotifyEvent,
              //     magnitudeRate : inc.MagnitudeRate
              //   })
              //   actualRootFeeds = actualRootFeeds.filter(itm => (itm.FeedId !== eachFeedId));
              //   actualRootFeeds.push(inc)
              // }
              // newStories[eachFeedId] = JSON.parse(JSON.stringify(inc));
              // incomingFeedIdArr.splice(incomingFeedIdArr.indexOf(eachFeedId), 1);
            }
          })
          // RootFeedIdArr = Object.keys(newStories);
          if(notifBodyData.length && notifBodyData.length > 0) {
            let actualBD = []; 
            let evtId = [];
            notifBodyData.forEach(notBD => {
              evtId.push(notBD.split('$$')[1]);
              actualBD.push(notBD.split('$$')[0]);
            })
            notif.body += 'UPDATED: '+actualBD.join(',') + ' .'; 
            notif.has = true; 
            notif.eventId = evtId.join(',');
          }
          //----------------------------------------New Updates for Regular feeds-------------------------------------------//
          notifBodyData = [];
          // console.log(webBeep.indexOf('Gray_Colored_Events'));
          // console.log(incomingFeedIdArr.filter(inFdid => { return (RootFeedIdArr.indexOf(inFdid) === -1) }))
          let filtInc = incomingFeedIdArr.filter(inFdid => { return (RootFeedIdArr.indexOf(inFdid) === -1) })
          
          filtInc.forEach((item, i) => {
            // console.log(incomingFeeds[item])
            let incNewFeed = incomingFeeds[item];
            // console.log(RootFeedHeadlines.indexOf(incNewFeed.HeadlineText) > -1)
            if(RootFeedHeadlines.indexOf(incNewFeed.HeadlineText) > -1) {
              // console.log('duplicate came in for regular update')
              let existingHeadLineFeed = newStories[RootFeedIdArr[RootFeedHeadlines.indexOf(incNewFeed.HeadlineText)]];
              let existingHeadlineFeedIndex = '';
              newStoriesIndex.forEach((ns, indx) => {
                if(ns.FeedId === existingHeadLineFeed.FeedId) {
                  existingHeadlineFeedIndex = indx;
                }
              })
              newStories = Object.assign({[`${item}`]: incomingFeeds[item]}, newStories)
              newStoriesIndex[existingHeadlineFeedIndex] = {
                FeedId : item,
                SortByDate : incomingFeeds[item].SortByDate,
                notifyEvent : incomingFeeds[item].NotifyEvent,
                magnitudeRate : incomingFeeds[item].MagnitudeRate
              }
              let changednewStories = {}
              newStoriesIndex.forEach((ns) => {
                changednewStories[`${ns.FeedId}`] = newStories[`${ns.FeedId}`]
              })
              newStories = {};
              newStories = changednewStories;
              // delete newStories[`${existingHeadLineFeed.FeedId}`]
              // console.log('updated duplicate stories')
            } else {
              let nowTime = moment();
              let thenTime = moment(incomingFeeds[item].FeedTime);
              let days = moment(nowTime,'DD-MM-YYYYThh:mm:ss').diff(thenTime, 'days');
              // console.log(days)
              if(days < 2){
                
                newStoriesIndex.unshift({
                  FeedId : item,
                  SortByDate : incomingFeeds[item].SortByDate,
                  notifyEvent : incomingFeeds[item].NotifyEvent,
                  magnitudeRate : incomingFeeds[item].MagnitudeRate
                })
                newStories = Object.assign({[`${item}`]: incomingFeeds[item]}, newStories)
                // newStoriesIndex.length = newStoriesIndex.length > tweetListMaxCount ? tweetListMaxCount : newStoriesIndex.length;
                
                // newStories[item] = incomingFeeds[item];
                
                
                let limitedNewStories = {}
                if(newStoriesIndex.length) {
                  newStoriesIndex.map(stindx => {
                    limitedNewStories[`${stindx.FeedId}`] = newStories[stindx.FeedId];
                    return null;
                  })
                }
                newStories = {};
                newStories = limitedNewStories;
  
                
  
              }
            }
            let canNotifyDesktop = true;
            // console.log(incomingFeeds[item].MagnitudeRate)
            switch(incomingFeeds[item].MagnitudeRate) {
              case 0:
                if(webBeep.indexOf('Gray_Colored_Events') === -1) { CanBeep.push(0); }
                else { CanBeep.push(1); }
                // console.log(CanBeep)
                if(DesktopNotifications.indexOf('Low') === -1) {
                  if(DesktopNotifications.indexOf('Low_BreakingOnly') > -1) {
                    if(!incomingFeeds[item].NotifyEvent) {
                      canNotifyDesktop = false;
                    }
                  } else {
                    canNotifyDesktop = false;
                  }
                }
                break;
              case 1:
                if(webBeep.indexOf('Yellow_Colored_Events') === -1) { CanBeep.push(0); }
                else { CanBeep.push(1); }
  
                if(DesktopNotifications.indexOf('Medium') === -1) {
                  if(DesktopNotifications.indexOf('Medium_BreakingOnly') > -1) {
                    if(!incomingFeeds[item].NotifyEvent) {
                      canNotifyDesktop = false;
                    }
                  } else {
                    canNotifyDesktop = false;
                  }
                }
                break;
              case 2:
                if(webBeep.indexOf('Red_Colored_Events') === -1) { CanBeep.push(0); }
                else { CanBeep.push(1); }
  
                if(DesktopNotifications.indexOf('High') === -1) {
                  if(DesktopNotifications.indexOf('High_BreakingOnly') > -1) {
                    if(!incomingFeeds[item].NotifyEvent) {
                      canNotifyDesktop = false;
                    }
                  } else {
                    canNotifyDesktop = false;
                  }
                }
                break;
              default: break;
            }
            if(!incomingFeeds[item].Active) {
              if(!incomingFeeds[item].EventCurated) {
                canNotifyDesktop = false;
              } else {
                if(!preferences.event_importance_filters.Non_Active_Curated_Events.Desktop_Notifications_NACE) {
                  canNotifyDesktop = false;
                }
              }
            }
            if(canNotifyDesktop && webNotifications) {
              notifBodyData.push(`${incomingFeeds[item].HeadlineText}$$${incomingFeeds[item].FeedId.toString()}`);
            }
          })
          if(notifBodyData.length && notifBodyData.length) { 
            let actualBD = []; 
            let evtId = [];
            notifBodyData.forEach(notBD => {
              evtId.push(notBD.split('$$')[1]);
              actualBD.push(notBD.split('$$')[0]);
            })
            notif.body += actualBD.join(',') + '.'; 
            notif.has = true;
            notif.eventId = evtId.join(','); 
          }
  
          //-----------------------------------------------------------------------------------------------------------------//
          // console.log(CanBeep)
          notif.canBeep = CanBeep.indexOf(0) > -1 ? false : true;
          let sortedNewBreakingStoriesIndex = BreakingStoriesIndex.sort((a,b) => {
            return moment(b.SortByDate) - moment(a.SortByDate)
          });
          let sortedNewAnalysedStoriesIndex = AnalysedStoriesIndex.sort((a,b) => {
            return moment(b.SortByDate) - moment(a.SortByDate)
          });
          let sortedNewStoriesIndex = newStoriesIndex.sort((a,b) => {
            // console.log("a: ", a.SortByDate)
            // console.log("b: ", b.SortByDate)
            return moment(b.SortByDate) - moment(a.SortByDate)
          });
          // let eventreadstatus = {};
          let LatestStoryDateCaptured = false;
          let LatestStoryDate = '';
          
          if(sortedNewStoriesIndex && sortedNewStoriesIndex.length) {
            sortedNewStoriesIndex.forEach(rf => {
              // console.log(rf.FeedId)
              // eventreadstatus[`${rf.FeedId}`] = newStories[rf.FeedId].EventReadStatus;
  
              let Story = newStories[`${rf.FeedId}`];
              // console.log(Story)
              if(Story && !LatestStoryDateCaptured) {
              
                if((!Story.Active && Story.EventCurated) || (!Story.Active && !Story.EventCurated)) {} else {
                  LatestStoryDate = NotifyFeedsHandler.getLatestEventTime(Story);
                  // console.log(LatestStoryDate)
                  LatestStoryDateCaptured = true;
                }
              }
            })
          }
         
          
          return {
            ...state,
            Stories : newStories,
            StoriesIndex : JSON.parse(JSON.stringify(sortedNewStoriesIndex)),
            StoriesLatestDate : LatestStoryDate,
            BreakingStories: BreakingStories,
            BreakingStoriesIndex: JSON.parse(JSON.stringify(sortedNewBreakingStoriesIndex)),
            AnalysedStories: AnalysedStories,
            AnalysedStoriesIndex: JSON.parse(JSON.stringify(sortedNewAnalysedStoriesIndex)),
            FeedsNotifications : notif
          }
        } else { 
          // console.log("We got to the else of the UPDATE_STORIES")
          notif = {
            has: false,
            title: 'Crowdsense Notifications',
            body: ''
          }
          // console.log("UPDATE_STORIES End Else")
          return {
            ...state,
            Stories : newStories,
            StoriesIndex : JSON.parse(JSON.stringify(newStoriesIndex)),
            FeedsNotifications : notif
          }
        }
      }
    }

    if(action.type === 'RESET_STORIES') {
      // let Stories = state.Stories;
      // let StoriesIndex = state.StoriesIndex;
      return {
        ...state,
        Stories: {},
        StoriesIndex: [],
        feedResetHandle: true
      }
    }
    if(action.type === 'RESET_STORIES_HANDLE') {
      return {
        ...state,
        feedResetHandle: false
      }
    }

    if(action.type === 'GET_STORY_DETAILS') {
      if(action.payload.length) {
        return {
          ...state,
          feedDetails : {
            ...state.feedDetails,
            [action.rootFeedId] : action.payload.reverse()
          },
          feedDetailsRequests: state.feedDetailsRequests + 1
        }
      } else {
        return {
          ...state,
          feedDetails : {
            ...state.feedDetails,
            [action.rootFeedId] : []
          },
          feedDetailsRequests: state.feedDetailsRequests + 1
        }
      }
    }

    if(action.type === 'READ_STAT_UPDATE_STORIES') {
      if(action.payload) {
        let BreakingStories = state.BreakingStories
        if(BreakingStories[`${action.payload.RootId}`]) {
          BreakingStories[`${action.payload.RootId}`].EventReadStatus = action.payload.ReadStatus
        }
        let Stories = state.Stories
        if(Stories[`${action.payload.RootId}`]) {
          Stories[`${action.payload.RootId}`].EventReadStatus = action.payload.ReadStatus
        }
        let AnalysedStories = state.AnalysedStories
        if(AnalysedStories[`${action.payload.RootId}`]) {
          AnalysedStories[`${action.payload.RootId}`].EventReadStatus = action.payload.ReadStatus
        }
        return {
          ...state,
          Stories,
          AnalysedStories,
          BreakingStories
        }
      }
      
    }

    if(action.type === 'SET_MESSAGE_NOTIF_UPDATED_FEEDS') {
      return {
        ...state,
        ...action.payload
      }
    }

    if(action.type === 'OPEN_CLOSE_STORY_TRACK') {
      return {
        ...state,
        feedOpenCloseList: {
          ...state.feedOpenCloseList,
          [`${action.FeedId}`]: action.payload
        }
      }
    }

    

    



    return state;
}

export default feedsReducer