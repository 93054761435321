import React, { Component } from 'react'; 
import { connect } from 'react-redux';
import * as filtersActionCreators from '../../actions/filters.action';

import AttributeListItem from './attribute-list-item';

class AttributeSetting extends Component {
  constructor() {
    super();
    this.state= {
      parentText: '',
      parentActive: false,
      mentiontypesFilter:[],
      mentionTypes: [],
      heirarchialState: []
    }
  }

  componentWillMount() {
    let mentionTypes = this.props.mentionTypes;
    let mentiontypesFilter = this.props.mentiontypesFilter;
    this.setMentionTypes(mentionTypes, mentiontypesFilter)
  }

  componentWillReceiveProps(nextProps) {
    if(this.props.active !== nextProps.active) {
      let mentionTypes = nextProps.mentionTypes;
      let mentiontypesFilter = nextProps.mentiontypesFilter;
      this.setMentionTypes(mentionTypes, mentiontypesFilter, nextProps)
    }
  }

  setMentionTypes = (mentionTypes, mentiontypesFilter, nextProps = false) => {
    
    let parentState = [];
    let HeirarchialState = [];
    if(mentionTypes.length) {
      mentionTypes.forEach((mntp,i) => {
        if(mentiontypesFilter.length) {
          mentiontypesFilter.forEach(filt => {
            if(mntp.Name.toString().trim().toLowerCase() === filt.toString().trim().toLowerCase()) {
              mntp.selected = true;
            }
          })
        }
        if(mntp.MentionTypeParent === 0) {
          parentState.push(mntp.MentionTypeID);
        }
      })
      HeirarchialState.push(parentState);
    }
    this.setState({
      ...this.state,
      parentActive: nextProps? nextProps.active : this.props.active,
      parentText: nextProps? nextProps.text : this.props.text,
      mentiontypesFilter: nextProps? nextProps.mentiontypesFilter : this.props.mentiontypesFilter,
      mentionTypes: nextProps? nextProps.mentionTypes : this.props.mentionTypes,
      heirarchialState: HeirarchialState
    })
  }

  
  getRecursiveChildren = (bucketArr, keyArr, resp) => {
    
    if(keyArr.length) {
      bucketArr.forEach(ele => {
        if(keyArr.indexOf(ele.MentionTypeID) > -1) {
          resp.push(ele.MentionTypeID)
          
          
          if(ele.children.length) {
            this.getRecursiveChildren(bucketArr, ele.children, resp)
          }
        }
      })
    }
    return {mentionTypes: resp};
  }
  getRecursiveParent = (key,selectedChild, bucketArr, stateFilter, resp, iterationCounts = 0) => {
    
    if(key !== 0) {
      bucketArr.forEach(ele => {
        if(ele.MentionTypeID === key) {
          let chST = []
          bucketArr.forEach((cele,i) => {
            if(ele.children.indexOf(cele.MentionTypeID) > -1) {
              if(iterationCounts === 0) {
                if(cele.MentionTypeID !== selectedChild) {
                  chST.push(cele.selected)
                }
              } else {
                chST.push(cele.selected)
              }
              
            }
          })
          // console.log(chST)
          if(stateFilter) {
            if(chST.indexOf(stateFilter) === -1) {
              resp.push(ele.MentionTypeID)
            } else if(chST.indexOf(!stateFilter) === -1) {
              resp.push(ele.MentionTypeID)
            } 
          } else {
            
            if(chST.indexOf(!stateFilter) === -1) {
              resp.push(ele.MentionTypeID) 
            } 
          }
          
          // if((stateFilter && chST.indexOf(stateFilter) > -1 && chST.indexOf(!stateFilter) === -1) || (!stateFilter && chST.indexOf(!stateFilter) > -1 && chST.indexOf(stateFilter) === -1)) {
          //   resp.push(ele.EventTypeID)
          // } 
          if(ele.MentionTypeParent > 0) {
            iterationCounts++;
            this.getRecursiveParent(ele.MentionTypeParent,ele.MentionTypeID,bucketArr,stateFilter,resp, iterationCounts)
          }
        }
      })
    }
    return resp
  }
  getRecursiveParentStage = (mention, bucketArr, stateFilter, resp) => {
    
    
      let parents = this.getRecursiveParent(mention.MentionTypeParent, mention.MentionTypeID, bucketArr, stateFilter, resp);
      
      return parents;
  }
  manageFilter = (stateFilter, Mention) => {
    let MF = [];
    let rawmentions = this.props.mentionTypes;
    let affectedChildren = this.getRecursiveChildren(rawmentions, Mention.children, [])
    let affectedParents = this.getRecursiveParentStage(Mention, rawmentions, stateFilter, [])
    
    this.props.mentionTypes.forEach((element,x) => {
      if(affectedChildren.mentionTypes.length) {
        if(affectedChildren.mentionTypes.indexOf(element.MentionTypeID) > -1) {
          this.props.mentionTypes[x].selected = stateFilter;
        }
      }
      if(affectedParents.length) {
        if(affectedParents.indexOf(element.MentionTypeID) > -1) {
          this.props.mentionTypes[x].selected = stateFilter;
        }
      }
    })
    this.props.mentionTypes.forEach((element,x) => {
      if(element.MentionTypeID === Mention.MentionTypeID) {
        element.selected = stateFilter
      }
    })
    this.props.mentionTypes.forEach(el => {
      if(el.selected) {
        MF.push(el.Name);
      }
    })
    let data = {
      "UserName": this.props.userData.username,
      "FilterString": MF.join(';')
    }
    this.props.updateMentionTypesFilter(data)
    this.setState({
      ...this.state,
      mentiontypesFilter: MF
    })
  }

  render() {

    const { parentActive, parentText, heirarchialState } = this.state
    let mentionsList = [];
    if(parentText === 'Topics' && parentActive) {
      let grpind = 0;
      heirarchialState.forEach((eachGroup, groupIndex) => {
        let groupMentionList = []
        if(this.props.mentionTypes.length) {
          let mntpcnt = 0;
          this.props.mentionTypes.forEach((item) => {
            let indexchange = false;
            if(eachGroup.indexOf(item.MentionTypeID) > -1) {
              if(groupIndex !== grpind) { indexchange = true; grpind = groupIndex }
              groupMentionList[mntpcnt] = <AttributeListItem mention={item} groupindex={groupIndex} akey={item.MentionTypeID} indexChange={indexchange} key={item.MentionTypeID} managefilter={this.manageFilter} selected={item.selected} mentionTypes={this.props.mentionTypes} userData={this.props.userData}  />
              mntpcnt++;
            }
          })
        }
        mentionsList.push(groupMentionList)
      })
    }
    
    return (
      
      <div className={`securityItem`}>
        { parentText === 'Topics' ? mentionsList : '' }
      </div>
    )
  }

}

const mapStateToProps = (state) => {
  return {
    userData: state.settings.userData,
    mentionTypes: state.filters.mentionTypes,
    mentiontypesFilter: state.filters.mentiontypesFilter
  }
}
export default connect(mapStateToProps, filtersActionCreators)(AttributeSetting)