import React, { Component } from 'react';

export default function withWrapHandle(SuperComponent = false) {
    return function(ChildComponent) {
        return class extends Component {
            render() {
                
                return SuperComponent ? (
                    <SuperComponent >
                        <ChildComponent {...this.props} />
                    </SuperComponent>
                ) : (
                    <ChildComponent {...this.props} />
                )
            }
        }
    }
}