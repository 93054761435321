import React, { useState, useEffect, useRef } from 'react';

import Chart from '../../echarts/chart';
import Relationline from '../../echarts/templates/relationline'; 
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getChartFeedFromTicker } from '../../../../actions/chart.action';
import { APPPATH } from '../../../../modules/helper.module';

function RelationChart({ ...props }) {
    // console.log(props)
    const dispatch = useDispatch();
    const [TMObject, setTMObject] = useState(props.data.TM)
    const [ChartOptions, setChartOptions] = useState({})
    const [ChartMinifyMode, setChartMinifyMode] = useState(false)
    const [ChartReqMode, setChartReqMode] = useState(false)
    // const [Theme, setTheme] = useState('dark')
    let Chartfeeds = useSelector(({charts}) => charts.ChartFeeds);
    let Theme = useSelector(({app_preferences}) => app_preferences.preferences.user_interface.Theme);
    useEffect(() => {
        if(window.screen.width < 992) {
            setChartMinifyMode(true)
        }
        setChartReqMode(true)
        dispatch(getChartFeedFromTicker(props.data.TM.SecurityName, props.data.templateType))
    },[])
    useEffect(() => {
        window.addEventListener('resize', setMinMode);
        
        return(() => {
            window.removeEventListener('resize', setMinMode);
        })
    }, [ChartMinifyMode])
    useEffect(() => {
        setChartOptions(Relationline('CoinSaveTime', 'SecurityPrice', props.data.templateType, props.data.TM, Chartfeeds, ChartMinifyMode, props.userdata.usertype, Theme))
        setTimeout(() => {
            setChartReqMode(false)
        }, 2000);
    },[Chartfeeds, Theme])

    const setMinMode = () => {
        // console.log(window.screen.width)
        if(window.screen.width < 992) {
            setChartMinifyMode(true);
            setChartOptions(Relationline('CoinSaveTime', 'SecurityPrice', props.data.templateType, props.data.TM, Chartfeeds, true, props.userdata.usertype, Theme));
        } else {
            setChartMinifyMode(false);
            setChartOptions(Relationline('CoinSaveTime', 'SecurityPrice', props.data.templateType, props.data.TM, Chartfeeds, false, props.userdata.usertype, Theme));
        }
        
    }
    // console.log(ChartMinifyMode)
    return(
        <div className={`watchlist-content trd-window-brdr justify-content-center text-center ${Theme === 'light' ? 'lightmode' : ''}`}>
            <div className="btn tradeWindowClose text-white nopad marg-0" onClick={props.modalClose}>
                <span className="pad10">
                    <img className="tradeWindowClose-closeicon" src={`${APPPATH.ImageUrl}/button-close.svg`} alt="" />
                </span>
            </div>
            <Row className="marg-0 storymenuoptions-tabheader">
                <Col className="nopad">
                    <div className={`nav nav-tabs watchlist nav-fill ${Theme === 'light' ? 'lightmode' : ''} `}>
                        <div className={`nav-item fs16 active`}>
                            <span>
                            {/* <object className="trdtickericon imground" data={`${APPPATH.CoinIconUrl}/${TMObject.SecurityName && TMObject.SecurityName.toLowerCase().replace(/ /g, '_')}.png`} type="image/png" > */}
                                <img 
                                    src={`${APPPATH.CoinIconUrl}/${TMObject.SecurityName && TMObject.SecurityName.toLowerCase().replace(/ /g, '_')}.png`} 
                                    className="trdtickericon imground" 
                                    alt="CrowdSense.ai"  
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = props.userdata.usertype === 'None' ? `${APPPATH.ImageUrl}/logo_hdr.png` : (props.userData.usertype === 'WUser' || props.userData.usertype === 'WAdmin') ? `${APPPATH.ImageUrl}/companygenerallogo.png` : `${APPPATH.ImageUrl}/companygenerallogo.png`
                                    }}
                                />
                            {/* </object> */}
                                {/* <img src={`${APPPATH.CoinIconUrl}/${SecurityTicker && SecurityTicker.toLowerCase().trim()}.png`} className="trdtickericon imground" alt="" /> */}
                            </span>
                            {/* <span className="trd">{ `${TMObject.SecurityName} (${TMObject.SecurityName}) Metrics` }</span> */}
                            <span className="trd">{ `${TMObject.SecurityName} Daily ${props.data.templateType}` }</span>
                            <span style={{cursor: 'pointer'}}>
                          <img
                            src={`${APPPATH.ImageUrl}/video-help.png`}
                            className=" helper-image"
                            alt=""
                            onClick={() => props.changeView('graph-help')}
                          />
                        </span>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="marg-0 ">
                { ChartReqMode && (
                    <Col lg={12} sm={12} xs={12} md={12} xl={12} >
                        <div className="" style={{height: '500px'}}>
                            <i className="fa fa-spinner fa-2x fa-spin storyloadindicator align-self-center" style={{marginTop: '30%'}}></i>
                        </div>
                    </Col>
                )}
                { !ChartReqMode && (
                    <Col lg={12} sm={12} xs={12} md={12} xl={12} className="" style={{height: '500px'}}>
                        <Chart options={ChartOptions} />
                    </Col>
                )}
            </Row>
        </div>
    )
}

export default RelationChart