import { Component } from 'react'; 
import  { connect } from 'react-redux';
import { CheckIncSMS, setNotificationsForMessage } from '../../actions/settings.action';


class MessageNotif extends Component {
  constructor() {
    super();
    this.state = {
      messageNotifications: [],
      SMSEvents: [],
    }
    this.incomingMessageCheck = '';
    this.notifyDelayer = '';
  }

  componentWillMount() {
    
    
    // if(!this.incomingMessageCheck) {
    //   this.incomingMessageCheck = setInterval(() => {
    //     this.checkIncSMS();
    //   },60000)
    // }
    if(this.props.messageNotifications.length) {
      this.handleIncMsgNot(this.props.messageNotifications)
    }
  }

  componentWillReceiveProps(nextProps) {
    // console.log(nextProps)
    if(nextProps.messageNotifications.length) {
      this.handleIncMsgNot(nextProps.messageNotifications, nextProps)
    } 
  }
  componentWillUnmount() {
    clearInterval(this.incomingMessageCheck);
  }

  checkIncSMS = () => {
    // console.log('checking inc sms')
    this.props.CheckIncSMS()
  }

  handleIncMsgNot = (incs, nextProps = false) => {
    let retEvents = [];
    incs.map((sms,i) => {
      let rf = sms.event
      
      let notif;
      rf.FromUser = sms.FromUser;
      let RootChangeLogList = rf.RootChangeLogList;
      let alertType = '';
      let notifBodyData = '';
      if(RootChangeLogList.length){
  
        switch(RootChangeLogList[RootChangeLogList.length - 1].UpdateTypeCategory) {
          case 'Activate':
            alertType = 'Event Detected';
            break;
          case 'Details Level':
            alertType = 'Integrity Updated';
            break;
          case 'Event Type':
            alertType = 'Event Type Updated';
            break;
          case 'Attributes':
            alertType = 'Topics Updated';
            break;
          case 'Location':
            alertType = 'Location Updated';
            break;
          case 'Magnitude':
            alertType = 'Magnitude Updated';
            break;
          default: 
            alertType = 'Major Update';
            break;
        }
      }
      notifBodyData = `${alertType} in ${rf.Location} ${rf.MentionTypes ? '('+rf.MentionTypes+')' : ''}#-#${rf.FromUser}#-@${rf.FeedId}`
      if(notifBodyData !== '') {
  
        let bodyData = rf.HeadlineText;
        let FromUser = notifBodyData.split("#-#")[1].split("#-@")[0];
        let FeedID = notifBodyData.split("#-@")[1];
        notif = {
          title: 'From ' + FromUser,
          from: FromUser,
          body: bodyData,
          feedid: FeedID
        }
      }
      retEvents.push(notif)
      return null;
    })
    this.setState({
      ...this.state,
      messageNotifications: nextProps ? nextProps.messageNotifications : this.props.messageNotifications,
      SMSEvents: retEvents,
    },() => {
      if(this.state.SMSEvents.length) {
        let i=0;
        this.notifyDelayer = setInterval(() => {
          if(i < this.state.SMSEvents.length) {
            this.showNotifications(this.state.SMSEvents[i]);
            i++
          } else {
            clearInterval(this.notifyDelayer)
          }
        },3000)
        
      }
    })
  }

  showNotifications(notif) {
    
        
        let notifData = {
          title: notif.title,
          body: notif.body,
          has: true,
          sms: true,
          feedid: notif.feedid,
          from: notif.from
        }
        this.props.setNotificationsForMessage(notifData);

    
  }
  

  render() {
    return (
      ''
    )     
  }
}

const mapStateToApp = (state) => {
  
  return {
    userData : state.settings.userData,
    messageNotifications: state.settings.messageNotifications,
  }
}

const mapDispatchToProps = {
  CheckIncSMS, setNotificationsForMessage
};

export default connect(mapStateToApp, mapDispatchToProps)(MessageNotif)