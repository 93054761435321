import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import CSelect from '../crowdsenseSelect/cselect';
import CPinput from '../crowdsensePhoneInput/cpinput';
import CRInput from '../emailInput/crinput';
import { APPPATH } from '../../modules/helper.module';

class PreferenceItem extends Component {
  constructor() {
    super();
    this.state = {
      title: '',
      value: false,
      code: '',
      phone: '',
      timeZonesList: [],
    };
  }

  componentWillMount() {
    // console.log(this.props.value)
    // let title = this.props.title;
    let TZs = [];
    if (this.props.TimeZoneList.length) {
      this.props.TimeZoneList.forEach((dat) => {
        let obj = {
          text: dat.Time,
          id: dat.Name,
        };
        TZs.push(obj);
      });
    }
    this.setState({
      ...this.state,
      title: this.props.title,
      value: this.props.value,
      timeZonesList: TZs,
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      ...this.state,
      title: nextProps.title,
      value: nextProps.value,
    });
  }

  handleChange = (e) => {
    let type = e.target.type;

    // let val;
    if (type === 'checkbox') {
      let newValue = false;

      if (this.props.category === 'notification') {
        newValue = this.state.value === 'Off' ? 'On' : 'Off';
      } else {
        newValue = !this.state.value;
      }

      this.setState(
        {
          ...this.state,
          title: this.state.title,
          value: newValue,
        },
        () => {
          this.props.handleUserPreferences(type, this.state.title, this.state.value, this.props.category);
        }
      );
    } else if (type === 'radio') {
      this.setState(
        {
          ...this.state,
          value: true,
        },
        () => {
          this.props.handleUserPreferences(type, this.state.title, this.state.value, this.props.category);
        }
      );
    }
  };

  makeTitleText = (title) => {
    let retText = '';
    switch (title) {
      case 'Low_Importance':
        retText = 'Show yellow events';
        break;
      case 'Medium_Importance':
        retText = 'Show orange events';
        break;
      case 'High_Importance':
        retText = 'Show red events';
        break;
      case 'Show_Red':
        retText = 'Red:Include events 10M+ followers or huge social spikes';
        break;
      case 'Show_Yellow':
        retText = 'Orange: Include events with 1M+ followers or large-huge social spikes';
        break;
      case 'Show_Gray':
        retText = 'Yellow: Include events with 20K+ followers or regular-huge spikes';
        break;
      case 'Show_NACE':
        retText = 'Gray: Include all credible events and all relevant social spikes';
        break;
      case 'Desktop_Notifications_NACE':
        retText = 'Desktop notifications for gray-colored events';
        break;
      case 'Desktop_Notifications_Red':
        retText = 'Desktop notifications for red-colored events';
        break;
      case 'Desktop_Notifications_Yellow':
        retText = 'Desktop notifications for orange-colored events';
        break;
      case 'Desktop_Notifications_Gray':
        retText = 'Desktop notifications for yellow-colored events';
        break;
      case 'Desktop_Notify_Breaking_Only_Red':
        retText = 'Desktop notifications for red early event alerts';
        break;
      case 'Desktop_Notify_Breaking_Only_Yellow':
        retText = 'Desktop notifications for orange early event alerts';
        break;
      case 'Desktop_Notify_Breaking_Only_Gray':
        retText = 'Desktop notifications for yellow early event alerts';
        break;

      case 'Email_Notifications_Red':
        retText = 'Real-time emails for each red event';
        break;
      case 'Email_Notifications_Yellow':
        retText = 'Real-time emails for each orange event';
        break;
      case 'Email_Notifications_Gray':
        retText = 'Email notifications for yellow-colored events';
        break;
      case 'Email_Notify_Breaking_Only_Red':
        retText = 'Email notifications for red early event alerts';
        break;
      case 'Email_Notify_Breaking_Only_Yellow':
        retText = 'Email notifications for orange early event alerts';
        break;
      case 'Email_Notify_Breaking_Only_Gray':
        retText = 'Email notifications for yellow early event alerts';
        break;

      case 'SMS_Notifications_Red':
        retText = 'Receive SMS for red early event alerts';
        break;
      case 'SMS_Notifications_Yellow':
        retText = 'Receive SMS for orange early event alerts';
        break;
      case 'SMS_Notifications_Gray':
        retText = 'Receive SMS for yellow early event alerts';
        break;
      case 'Speech_Notifications_Red':
        retText = 'Narrate red early event alerts';
        break;
      case 'Speech_Notifications_Yellow':
        retText = 'Narrate orange early event alerts';
        break;
      case 'Speech_Notifications_Gray':
        retText = 'Narrate yellow early event alerts';
        break;
      // case 'Desktop_Notifications':
      //   retText = 'Desktop notifications';
      //   break;
      // case 'Daily_Notifications':
      //   retText = 'Daily summary email of top coins and events';
      //   break;
      // case 'Weekly_Notifications':
      //   retText = 'Weekly summary emails (orange, red)';

      //   break;
      // case 'SMS_Notifications':
      //   retText = 'SMS notifications (Yellow, Red)';
      //   break;
      case 'Mainstream_News':
        retText = 'Show all events including early in right column';
        // retText = 'Include early events in right column / tab';
        // retText = 'Display only breaking events detected early';
        break;
      // case 'Local_News':
      //   retText = 'Show events with first feed from local news';
      //   break;
      case 'Securities_Line':
        retText = 'Show potentially affected tradeable markets / assets';
        break;
      case 'Recurring_Events':
        retText = 'Show recurring events (coming soon)';
        break;
      case 'Tab_Mode':
        retText = 'Tabs view instead of columns view';
        break;
      case 'Early_Events':
        retText = 'Show early events column / tab';
        break;
      case 'Locations_Related_Countries':
        retText = 'Show event location';
        break;
      case 'Analyzed_Events':
        retText = 'Show analyzed events column / tab';
        break;
      case 'Early_In_Analyzed_Events':
        // retText = 'Show all events including early in right column';
        retText = 'Include early events in analyzed events column / tab';
        break;
      case 'Event_Collapsed_View':
        retText = 'Headlines only view';
        break;
      case 'Trending_Mentions':
        retText = 'Top Coins 24 Hours';
        break;
      case 'Planned_Scheduled_Events':
        retText = 'Show planned or scheduled events (coming soon)';
        break;
      case 'Email':
        retText = 'Email';
        break;
      case 'Timezone':
        retText = 'Timezone';
        break;
      case 'Phone':
        retText = 'Phone';
        break;
      case 'Search_with_gray':
        retText = 'Include unanalyzed gray events in default search';
        break;
      case 'Search_with_black':
        retText = 'Include uncurated black events in default search';
        break;
      case 'Search_with_yellow':
        retText = 'Include analyzed yellow events in default search';
        break;
      case 'Search_with_orange':
        retText = 'Include analyzed orange events in default search';
        break;
      case 'Search_with_red':
        retText = 'Include analyzed red events in default search';
        break;
      case 'CDSearch_with_gray':
        retText = 'Include unanalyzed gray events in coin details';
        break;
      case 'CDSearch_with_black':
        retText = 'Include uncurated black events in coin details';
        break;
      case 'CDSearch_with_yellow':
        retText = 'Include analyzed yellow events in coin details';
        break;
      case 'CDSearch_with_orange':
        retText = 'Include analyzed orange events in coin details';
        break;
      case 'None':
        retText = 'No real-time notifications';
        break;
      case 'Lite':
        retText = 'Up to 2 real-time notifications per day';
        break;
      case 'Standard':
        retText = (
          <span>
            Up to 5 real-time notifications per day
            <img src={`${APPPATH.ImageUrl}/inner-badge-premium.svg`} alt="CrowdSense.ai"></img>
          </span>
        );

        break;
      case 'Professional':
        retText = (
          <span>
            Up to 8 real-time notifications per day
            <img src={`${APPPATH.ImageUrl}/inner-badge-premium.svg`} alt="CrowdSense.ai"></img>
          </span>
        );
        break;
      case 'Daily_Weekly_Notifications':
        retText = 'Daily summary email';
        break;
      case 'Daily_notifications':
        retText = 'Daily summary emails';
        break;
      case 'Weekly_notifications':
        retText = 'Weekly summary emails';

        break;
      case 'No_notifications':
        retText = 'No Summary Emails';

        break;
      default:
        break;
    }
    return retText;
  };

  cselectApply = (tmzObj) => {
    // console.log(tmzObj)
    this.props.handleUserPreferences('input', this.state.title, tmzObj, this.props.category);
  };

  cinputApply = (value) => {
    // console.log(this.state.title, value, this.props.category)
    this.props.handleUserPreferences('input', this.state.title, value, this.props.category);
  };

  inputAction = (value) => {
    this.props.handleUserPreferences('input', this.state.title, value, this.props.category);
  };

  handleEmailUpdate = (e) => {
    // console.log('radio', this.state.title, true, this.props.category)
    
    if (this.state.title === 'Professional' || this.state.title === 'Standard') {
      if (this.props.userdata.userPackage.toLowerCase() === 'bronze') {
        this.props.toggleConfirmPopup(true);
      } else {
        this.props.handleUserPreferences('radio', this.state.title, true, this.props.category);
      }
    } else {
      this.props.handleUserPreferences('radio', this.state.title, true, this.props.category);
    }
  };

  render() {
    const { title, value } = this.state;
    let titleText = this.makeTitleText(title);
    // let value2 = [];
    // console.log(this.state.timeZonesList)
    // if (title === 'Timezone') {
    //   value2 = this.state.timeZonesList.filter((x) => x.text === value);
    // }
    // console.log(this.props.category, title, value)
    if (titleText) {
      return (
        <Row className="marg-0 padtb10">
          {this.props.category !== 'search_window_preferences' &&
          this.props.category !== 'localisation_preferences' &&
          this.props.category !== 'userDetails' &&
          this.props.category !== 'RealTimeEmails' &&
          this.props.category !== 'SummaryEmails' ? (
            <Row className="wid100perc smwidunset">
              <Col lg={10} sm={10} xs={10} md={10} xl={10} className="preferenceItem-title">
                {titleText}
              </Col>
              <Col lg={2} sm={2} xs={2} md={2} xl={2} className="">
                <label className="sec-switch float-right">
                  <input
                    type="checkbox"
                    checked={`${value === true || value === 'On' ? 'checked' : ''}`}
                    onChange={this.handleChange}
                  />

                  <span className="sec-slider   round"></span>
                </label>
              </Col>
            </Row>
          ) : this.props.category === 'localisation_preferences' || this.props.category === 'userDetails' ? (
            <Row className="wid100perc smwidunset">
              <Col lg={3} sm={3} xs={12} md={3} xl={3} className="preferenceItem-title">
                <label>{titleText}</label>
              </Col>
              <Col lg={9} sm={9} xs={12} md={9} xl={9} className="">
                {title === 'Timezone' ? (
                  <CSelect
                    data={this.state.timeZonesList}
                    cselectApply={this.cselectApply}
                    // active={value2[0].id}
                    active={value}
                    key={`TimeZone`}
                  />
                ) : title === 'Email' ? (
                  <CRInput
                    type="email"
                    placholder="Email Address"
                    value={value}
                    inputAction={this.inputAction}
                    readonly={true}
                  />
                ) : (
                  <CPinput data={value} title={title} cinputApply={this.cinputApply} />
                )}
              </Col>
            </Row>
          ) : this.props.category === 'RealTimeEmails' || this.props.category === 'SummaryEmails' ? (
            <Row className="wid100perc smwidunset">
              <Col lg={10} sm={10} xs={10} md={10} xl={10} className="preferenceItem-title">
                {titleText}
              </Col>
              <Col lg={2} sm={2} xs={2} md={2} xl={2} className="">
                <label className="cs-radio float-right">
                  <input
                    type="radio"
                    checked={`${value === true || value === 'On' ? 'checked' : ''}`}
                    name={`${this.props.category}`}
                    onChange={this.handleEmailUpdate}
                  />
                  <span className="cs-radio-mark"></span>
                </label>
              </Col>
            </Row>
          ) : (
            ''
          )}
        </Row>
      );
    } else {
      return '';
    }
  }
}

export default PreferenceItem;
