import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { APPPATH } from '../../../../modules/helper.module';
import * as tmActionCreators from '../../../../actions/trendingmentions.action';
import EachExchangeAndRate from './eachExchangeAndRate';

class ExchangesAndRates extends Component {
    constructor(props) {
      super();
      this.state = {
        _this: this,
        TickerData: props.data,
        ExchangesAndRates: props.ExchangesAndRates,
        loadingExchanges: props.ExchangesAndRatesLoading
      };
    }

    componentDidMount() {
        if(this.state.TickerData !== {}) {
            this.props.RequestExchangesAndRates(true);
            this.props.getExchangesAndRates(this.state.TickerData)
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            TickerData: nextProps.data,
            ExchangesAndRates: nextProps.ExchangesAndRates,
            loadingExchanges: nextProps.ExchangesAndRatesLoading
        }
    }
    
    render() {
        // console.log(this.props)
        // console.log(this.state.TickerData)
        const { SecurityName, SecurityTicker } = this.state.TickerData
        const { loadingExchanges, ExchangesAndRates } = this.state
        let colValue = 4; //ExchangesAndRates.length >= 4 ? 4 : 6
        // console.log(loadingExchanges, ExchangesAndRates)
        let ExchangesPriceList = ExchangesAndRates.map(exch => 
            <EachExchangeAndRate columns={colValue} dataObj={exch} key={exch.Exchange} userName={this.props.userdata.username} location={this.props.location} />
        )
        
        return(
            <div className="watchlist-content trd-window-brdr justify-content-center text-center">
                <div className="btn tradeWindowClose text-white nopad marg-0" onClick={this.props.modalClose}>
                    <span className="pad10">
                    <img className="tradeWindowClose-closeicon" src={`${APPPATH.ImageUrl}/button-close.svg`} alt="" />
                    </span>
                </div>
                <Row className="marg-0 storymenuoptions-tabheader">
                    <Col className="nopad">
                        <div className="nav nav-tabs watchlist nav-fill  ">
                            <div className={`nav-item fs16 active`}>
                                <span>
                                <object className="trdtickericon imground" data={`${APPPATH.CoinIconUrl}/${SecurityName && SecurityName.toLowerCase().replace(/ /g, '_')}.png`} type="image/png" >
                                    <img src={`${APPPATH.ImageUrl}/logo_hdr.png`} className="trdtickericon imground" alt="CrowdSense.ai"  />
                                </object>
                                    {/* <img src={`${APPPATH.CoinIconUrl}/${SecurityTicker && SecurityTicker.toLowerCase().trim()}.png`} className="trdtickericon imground" alt="" /> */}
                                </span>
                                <span className="trd">{ `${SecurityName} (${SecurityTicker}) Trade` }</span>
                            </div>
                        </div>
                    </Col>
                </Row>
                {
                    loadingExchanges ? (
                        <Row className={` marg-0 justify-content-center  pad5 text-center `}>
                            <Col className="nopad">
                                <div style={{paddingTop: 30, paddingBottom: 30}}>
                                    <i className="fa fa-spinner fa-2x fa-spin storyloadindicator"></i>
                                    {/* <LinearLoader /> */}
                                </div>
                            </Col>
                        </Row>
                    ) : ExchangesAndRates.length < 1 ? (
                        <Row className="cScroll scroll-notrack marg-0 justify-content-center  pad5 text-center" style={{ maxHeight: 400 }}>
                            <Col lg={11} sm={11} xs={11} md={11} xl={11} className="justify-content-left text-left">
                                <div className="">
                                   No Data Found
                                </div>
                            </Col>
                        </Row>  
                    ) : (
                        <Row className="cScroll scroll-notrack marg-0 justify-content-center  pad5 text-center " style={{ maxHeight: 400 }}>
                            <Col lg={12} sm={12} xs={12} md={12} xl={12} className="justify-content-center text-center "> 
                                <Row className="marg-0 justify-content-left text-left"> 
                                   {ExchangesPriceList}
                                </Row>
                            </Col>
                        </Row>
                    )
                }
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        ExchangesAndRates: state.trendingMentions.tmDetails.ExchangesAndRates,
        ExchangesAndRatesLoading: state.trendingMentions.tmDetails.ExchangesAndRatesLoading
    }
}
  
const mapDispatchToProps = {
    ...tmActionCreators
}

export default connect(mapStateToProps, mapDispatchToProps)(ExchangesAndRates)