import React, { useState, useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import PropTypes from 'prop-types';

function Chart({ options }) {

    
    // console.log(options)
    const TickerChart = useRef(null)
    useEffect(() => {
        const chart = echarts.init(TickerChart.current)
        // chart.setOption({})
        // chart.showLoading({
        //     text: '',
        //     color: '#50C0FF',
        //     textColor: '#000',
        //     maskColor: 'rgba(20, 22, 30, 1)',
        //     zlevel: 0,
        //     fontSize: 12,
        //     showSpinner: true,
        //     spinnerRadius: 10,
        //     lineWidth: 5
        // })
        
            
        chart.setOption(options)
        // setTimeout(() => {
        //     chart.hideLoading();
        // }, 2000);
        
        window.onresize = function() {
            chart.resize();
        }
    },[options])
    return (
        <div ref={TickerChart} style={{width: "100%", height: "100%"}} className=""></div>
    );
}

Chart.propTypes = {
    options: PropTypes.any,
}

export default Chart;