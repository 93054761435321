import { APICONFIG, ErrorHandleHelper } from "../modules/helper.module";
import { NotifyFeedsHandler, TrendingMentionsHelper } from '../modules/helper.module';
import moment from 'moment';

export function getChartFeedFromTicker(security, dataType) {
    return dispatch => {
        // console.log(dataType)
        // return fetch(`${APICONFIG.apiBaseUrl}/api/coinpages/getgraphdata?securityName=${security.toLowerCase()}&startDate=${moment().utcOffset(0).subtract(1, 'day').format('')}`,{
        return fetch(`${APICONFIG.apiBaseUrl}/api/securities/getgraphdata?securityName=${security.toLowerCase()}&dataType=${dataType}&timeframe=${`1D`}`,{
            method: 'GET',
            headers: APICONFIG.apiHeaders()
        })
        .then(async (response) => {
            if(response.status === 200 && response.ok) {
                await response.json().then((result) => {
                    // console.log('chart feeds',result)
                    let lastDayFilteredResults = [];
                    let NullFilledDataSet = [];
                    let FormattedResult = [];
                    if(result.length) {
                        if(APICONFIG.apiLoggedInUserType() === 'None' || APICONFIG.apiLoggedInUserType() === 'WUser' || APICONFIG.apiLoggedInUserType() === 'WAdmin') {
                            if(TrendingMentionsHelper.FreeSubscriptionFilter()) {
                                lastDayFilteredResults = NotifyFeedsHandler.filterInLastDayData(result)
                                FormattedResult = NotifyFeedsHandler.FormatMissingGraphData(lastDayFilteredResults);
                            } else {
                                FormattedResult = NotifyFeedsHandler.FormatMissingGraphData(result);
                            }
                        } else {
                            FormattedResult = NotifyFeedsHandler.FormatMissingGraphData(result);
                        }
                        
                        // console.log(NotifyFeedsHandler.FormatMissingGraphData(result))
                        
                        // let newResultArray = [];
                        // result.forEach(rs => {
                        //     newResultArray.push(NotifyFeedsHandler.GraphTimeToLocal(rs))
                        // })
                        // console.log('LINE 11',newResultArray)
                        // dispatch({
                        //     type: 'TICKERFEEDS',
                        //     payload: result
                        //     // payload: newResultArray
                        // })
                    }
                    dispatch({
                        type: 'TICKERFEEDS',
                        // payload: lastDayFilteredResults
                        // payload: result
                        payload: FormattedResult
                        // payload: newResultArray
                    })
                    
                })
            
            } else {
            await response.json().then((result) => {
                // console.log('LINE 17',result)
                throw new Error(`${response.status} : ${response.statusText} - ${result.Message}`)
            })
            }
        })
        .catch((err) => {
            console.log(err)
            let data = {
                username : APICONFIG.apiLoggedInUser(),
                action: 'Get Chart Feeds',
                url : `${APICONFIG.apiBaseUrl}/api/securities/getgraphdata?securityName=${security.toLowerCase()}&dataType=${dataType}&timeframe=${`1D`}`,
                severity: 'low'
            }
            dispatch({
                type: 'REQUEST_ERROR',
                payload: ErrorHandleHelper.generateErrorString(err, 'GetChartFeeds'),
                data: data
            })
        })
    }
    
}