import React, { Component } from 'react'; 
import Logo from '../../headbar/logo';
import { connect } from 'react-redux';
import * as authActionCreator from '../../../../actions/authenticate.action';
// import TNCIndex from './tnc_index';
import TNC from './tnc';
import PP from './pp';
import * as moment from 'moment';
import { TrendingMentionsHelper } from '../../../../modules/helper.module';

class TNCModalView extends Component {
  constructor() {
    super();
    this.state = {
      view: 'GENERAL',
      tnc: false,
      pp: false,
      selected : {
        tnc : false,
        pp : false,
      },
      requestAction :false
    }
  }

  acceptTerms = () => {
    this.setState({
      ...this.state,
      tnc: true,
      view :'GENERAL',
      requestAction: true,
      selected : {
        ...this.state.selected,
        tnc : true
      }
    },() => {
      if(this.checkOverallStat(this.state)) {
        if(typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser) {
          this.props.acceptTermsNCond_LLU(true)
        } else {
          this.props.acceptTermsNCond(true)
        }
      } 
    })
  }

  acceptPrivacyPolicy = () => {
    this.setState({
      ...this.state,
      pp: true,
      view :'GENERAL',
      requestAction: true,
      selected : {
        ...this.state.selected,
        pp : true
      }
    },() => {
      if(this.checkOverallStat(this.state)) {
        if(typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser) {
          this.props.acceptTermsNCond_LLU(true)
        } else {
          this.props.acceptTermsNCond(true)
        }
      }
    })
  }
  checkOverallStat = (currentState) => {
    let stat = currentState.tnc && currentState.pp ?  true : false;
    return stat;
  }
  handleSelectState = (state, mark = false) => {
    if(mark) {
      this.setState({
        ...this.state,
        // selected : {
        //   ...this.state.selected,
        //   [`${state}`]: true,
        // },
        view: state.toUpperCase()
      })
    }
  }

  resetView = () => {
    this.setState({
      ...this.state,
      view: 'GENERAL'
    })
  }

  render() {
    
    // const { view } = this.state;
    // let viewContent;
    // switch(view) {
    //   case 'TNC':
    //     viewContent = <TNC {...this.props} acceptTerms={this.acceptTerms} />
    //     break;
    //   case 'PP':
    //     viewContent = <PP {...this.props} acceptPrivacyPolicy={this.acceptPrivacyPolicy} />
    //     break;
    //   default: 
    //     viewContent = <TNCIndex {...this.props} tnc={this.state.tnc} pp={this.state.pp} selectState={this.state.selected} handleSelectState={this.handleSelectState} />
    // }
    return (
      <div className="tnc-content">
        <div className="tnc-header">
            <Logo noTitle={false} align={false} beta={false} userpackage={this.props.userData.userPackage.toLowerCase()} infreetrial={TrendingMentionsHelper.FreeSubscriptionFilter()}/> 
            {
              this.state.view !== 'GENERAL' ? (
                <div className="tnc-return" onClick={this.resetView}>Back</div>
              ) : ('')
            }
            <div className="tnc-title">
              {/* {`Terms Of Service & Privacy Policy`} */}
              {
                typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser ? `Terms Of Service` : `Terms Of Service & Privacy Policy`
              }
              {/* {`Terms Of Service`} */}
              {/* {
                this.state.view === 'TNC' ? ('Terms & Conditions') : 
                this.state.view === 'PP' ? ('Privacy Policy') : ('Crowdsense Terms Of Service & Privacy Policy')
              } */}
            </div>
            <div className="tnc-sub-title ">
              {
                typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser ? (
                  <div className="tnc-sub-title-wrap">Please read carefully and accept our Terms & Conditions to get full access to using our application</div>
                ) : (
                  <div className="tnc-sub-title-wrap">Please read carefully and accept our Terms & Conditions and Privacy Policy get full access to using our application</div>
                )
              }
              
            </div>
        </div>
        <div>
          <div className="tncppWrap">
            {
              // viewContent
              <TNC {...this.props} acceptTerms={this.acceptTerms} />
            }
            {
              typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser ? ('') : (
                <PP {...this.props} acceptPrivacyPolicy={this.acceptPrivacyPolicy} />
              )
            }
          </div>
          <div className="tnc-footer">
            <div>
              <button type="button" className="btn btn-tnc blue" onClick={() => {
                  window.Mixpanel.MixpanelProps.UserName = this.props.username;
                  window.Mixpanel.MixpanelProps.TNC_Accepted = moment().format('DD/MM/YYYY HH:mm:s A');
                  window.Mixpanel.actions.identify(this.props.username);
                  
                  window.Mixpanel.actions.track(`Terms and Conditions Accepted`, window.Mixpanel.MixpanelProps).then(data => {
                    window.Mixpanel.actions.people.set({
                      UserName: this.props.username
                    });
                  })
                  if(typeof this.props.userData.loginlessuser !== 'undefined' && this.props.userData.loginlessuser) {
                    this.props.acceptTermsNCond_LLU(true)
                  } else {
                    this.props.acceptTermsNCond(true)
                  }
                  
                }}
              > I Accept </button>
            </div>
            <div><button type="button" className="btn btn-tnc" onClick={() => {
              window.Mixpanel.MixpanelProps.UserName = this.props.username;
              window.Mixpanel.MixpanelProps.TNC_Declined = moment().format('DD/MM/YYYY HH:mm:s A');
              window.Mixpanel.actions.identify(this.props.username);
              
              window.Mixpanel.actions.track(`Terms and Conditions Declined`, window.Mixpanel.MixpanelProps).then(data => {
                window.Mixpanel.actions.people.set({
                  UserName: this.props.username
                });
                this.props.onHide()
              })
              
            }}>I Decline</button></div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.settings.userData,
    username: state.settings.userData.username
  }
}


export default connect(mapStateToProps, authActionCreator)(TNCModalView)