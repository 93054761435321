import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
// import StoryElapsedTime from './story_elapsedtime';
import ReputationIndicator from './reputationindicator';
import Tooltip from '../shared/tooltip/generaltooltip';
import { APPPATH } from '../../modules/helper.module';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import * as moment from 'moment';

class StoryDetailsEach extends Component {
  constructor() {
    super();
    this.state = {
      hovered: false,
      msgLinkToolTip: false,
      viewport: {},
    };
    this.ttHandle = '';
    window.feeddetailtextlinkClick = function(event) {
      // console.log(event)
      window.open(event.target.href, '_blank');
    }
  }
  componentDidMount() {
    this.setState(
      {
        ...this.state,
        viewport: {
          ...this.state.viewport,
          width: document.documentElement.clientWidth,
          height: document.documentElement.clientHeight,
        },
      },
      () => {
        window.addEventListener('resize', this._resize);
      }
    );
  }
  _resize = () => {
    this.setState({
      ...this.state,
      viewport: {
        ...this.state.viewport,
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight,
      },
    });
  };
  hover = () => {
    this.setState({
      ...this.state,
      hovered: !this.state.hovered,
    });
  };
  hoverClick = () => {
    window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
    window.Mixpanel.MixpanelProps.FeedId = this.props.FeedId;
    window.Mixpanel.MixpanelProps.EventId = this.props.rootId;
    window.Mixpanel.MixpanelProps.Publisher = this.props.Publisher;
    window.Mixpanel.actions.identify(this.props.userData.username);
    window.Mixpanel.actions.track(`Clicked to see user info`, window.Mixpanel.MixpanelProps).then((data) => {
      window.Mixpanel.actions.people.set({
        UserName: this.props.userData.username,
      });
      window.Mixpanel.actions.resetProp('EventId');
    });
  };
  formatFeedText = (text, feedurl) => {
    // console.log(text, this.props.eachFeed)
    const { viewport } = this.state;
    let feedMaxLines = viewport.width > 767 ? 2 : 3;
    //let LimitText = text && text.length > 110 ? `${text.substr(0,text.lastIndexOf(' ', 110))}...` : text;
    var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
    // let links = text.match(urlRegex);
    // let linkIndexes = []
    // let match
    // let formattedText = text
    // while (match = urlRegex.exec(text)) {
    //   linkIndexes.push(`${match.index}-${urlRegex.lastIndex}`)
    // }

    let formattedText = text.replace(/\s\s+/g, ' ');
    // console.log(formattedText, text)
    let FTextBits = formattedText.trim().split(' ');
    if (FTextBits.length > 1 && FTextBits[1].toLowerCase() === 'from' && FTextBits[3] === '-') {
      formattedText = text.replace(`${FTextBits[0]} ${FTextBits[1]} ${FTextBits[2]} ${FTextBits[3]}`, function (
        langTxt
      ) {
        return `<span class="from-lang-indicator"> ${FTextBits[1]} ${FTextBits[2]} ${FTextBits[3]} </span>${FTextBits[0]}`;
      });
    } else if (FTextBits.length > 1 && FTextBits[1].toLowerCase() === 'translated' && FTextBits[2] === '-') {
      formattedText = text.replace(`${FTextBits[1]} ${FTextBits[2]}`, function (langTxt) {
        return `<span class="from-lang-indicator"> ${FTextBits[1]} ${FTextBits[2]} </span>`;
      });
    }
    if(this.props.render === 'iframe' && this.props.userData.loginlessuser !== 'Iforex') {
      
      formattedText = formattedText.replace(urlRegex, function (url) {
        return `<a href="${url}" target="_blank" onclick="window.feeddetailtextlinkClick(event);">${url}</a>`;
      });
    }
    
    formattedText = formattedText.replace('...', '');
    // return formattedText;
    // return formattedText;

    const ResponsiveEllipsis = responsiveHOC()(HTMLEllipsis);
    return (
      <ResponsiveEllipsis unsafeHTML={formattedText} maxLine={`${feedMaxLines}`} ellipsisHTML="..." basedOn="words" />
    );
  };
  feedSourceToolTip = (source) => {
    let sourceType = source.split('|')[1] ? source.split('|')[1] : '';
    // console.log(source)
    let tooltipText = '';
    switch (sourceType) {
      case 'Financial News':
        tooltipText = 'Chatter from social media';
        break;
      case 'Bloomberg':
        tooltipText = 'Article link to Bloomberg websites';
        break;
      case 'Reuters':
        tooltipText = 'Article link to Reuters websites';
        break;
      default:
        tooltipText = source.split('|')[1] ? source.split('|')[1] : source;
        break;
    }
    return tooltipText;
  };
  feedSourceIcon = (source) => {
    let sourceType = source.split('|')[1] ? source.split('|')[1] : source;
    let feedIcon = '';
    // console.log(sourceType)
    switch (sourceType) {
      case 'Local News English':
        feedIcon = this.props.Theme === 'light' ? 'news_light' : 'news_dark';
        break;
      case 'International News English':
        feedIcon = this.props.Theme === 'light' ? 'news_light' : 'news_dark';
        break;
      case 'Financial News':
        feedIcon = this.props.Theme === 'light' ? 'news_light' : 'news_dark';
        break;
      case 'Financial News Aggregator':
        feedIcon = this.props.Theme === 'light' ? 'news_light' : 'news_dark';
        break;
      case 'Bloomberg':
        feedIcon = this.props.Theme === 'light' ? 'news_light' : 'news_dark';
        break;
      case 'Reuters':
        feedIcon = this.props.Theme === 'light' ? 'news_light' : 'news_dark';
        break;
      case 'Reddit':
        feedIcon = 'reddit_feed_source';
        break;
      case 'Telegram':
        feedIcon = 'telegram_feed_source';
        break;
      case 'Local News Local Language':
        feedIcon = this.props.Theme === 'light' ? 'news_light' : 'news_dark';
        break;
      case 'WebSites':
        feedIcon = this.props.Theme === 'light' ? 'general_feed_source1_1light' : 'general_feed_source1_1dark';
        break;
      case 'TwitterScraping':
        feedIcon = this.props.Theme === 'light' ? 'twitter_light' : 'twitter_dark';
        break;
      case 'Twitter':
        feedIcon = this.props.Theme === 'light' ? 'twitter_light' : 'twitter_dark';
        break;
      case 'TwitterScraping|Financial News':
        feedIcon = this.props.Theme === 'light' ? 'news_light' : 'news_dark';
        break;
      case 'Twitter Financial News':
        feedIcon = this.props.Theme === 'light' ? 'news_light' : 'news_dark';
        break;
      case 'Twitter Financial User':
        feedIcon = this.props.Theme === 'light' ? 'news_light' : 'news_dark';
        break;
      case 'Telegram Financial News':
        feedIcon = this.props.Theme === 'light' ? 'news_light' : 'news_dark';
        break;
      case 'Telegram Financial User':
        feedIcon = this.props.Theme === 'light' ? 'news_light' : 'news_dark';
        break;
      case 'Telegram|Financial User':
        feedIcon = this.props.Theme === 'light' ? 'news_light' : 'news_dark';
        break;
      default:
        feedIcon = feedIcon = this.props.Theme === 'light' ? 'news_light' : 'news_dark';
        break;
    }
    // if(source.toLowerCase().includes('twitter')) {
    //   feedIcon = 'twitter';
    // }
    return feedIcon;
  };

  toggleMsgtoLinkToolTip = (showState) => {
    if (this.ttHandle) {
      clearTimeout(this.ttHandle);
    }
    if (showState) {
      this.ttHandle = setTimeout(() => {
        this.setState(
          {
            ...this.state,
            msgLinkToolTip: showState,
          },
          () => {
            if (showState) {
              window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
              window.Mixpanel.MixpanelProps.EventId = this.props.rootId;
              window.Mixpanel.MixpanelProps.Headline = this.props.story.HeadlineText;
              window.Mixpanel.MixpanelProps.ToolTipType = 'Feed Icon';
              window.Mixpanel.actions.identify(this.props.userData.username);
              window.Mixpanel.actions.track(`Tooltip shown`, window.Mixpanel.MixpanelProps).then((data) => {
                window.Mixpanel.actions.people.set({
                  UserName: this.props.userData.username,
                });
                window.Mixpanel.actions.resetProp('EventId');
                window.Mixpanel.actions.resetProp('Headline');
                window.Mixpanel.actions.resetProp('ToolTipType');
              });
            }
          }
        );
      }, 1500);
    } else {
      this.setState({
        ...this.state,
        msgLinkToolTip: showState,
      });
    }
  };
  mixpanelCapture = (feedText = false) => {
    window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
    window.Mixpanel.MixpanelProps.FeedId = this.props.eachFeed.FeedId;
    window.Mixpanel.MixpanelProps.Headline = this.props.story.HeadlineText;
    window.Mixpanel.MixpanelProps.FeedText = feedText !== false ? feedText : '';
    window.Mixpanel.MixpanelProps.EventId = this.props.rootId;
    window.Mixpanel.actions.identify(this.props.userData.username);
    window.Mixpanel.actions.track(`Clicked Feed link icon`, window.Mixpanel.MixpanelProps).then((data) => {
      window.Mixpanel.actions.people.set({
        UserName: this.props.userData.username,
      });
      window.Mixpanel.actions.resetProp('EventId');
      window.Mixpanel.actions.resetProp('FeedId');
      window.Mixpanel.actions.resetProp('Headline');
      window.Mixpanel.actions.resetProp('FeedText');
    });
  };
  mouseOverHandler = (action) => {
    window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
    window.Mixpanel.MixpanelProps.FeedId = this.props.eachFeed.FeedId;
    window.Mixpanel.MixpanelProps.EventId = this.props.rootId;
    window.Mixpanel.MixpanelProps.Headline = this.props.story.HeadlineText;
    window.Mixpanel.actions.identify(this.props.userData.username);
    window.Mixpanel.actions.track(action, window.Mixpanel.MixpanelProps).then((data) => {
      window.Mixpanel.actions.people.set({
        UserName: this.props.userData.username,
      });
      window.Mixpanel.actions.resetProp('EventId');
      window.Mixpanel.actions.resetProp('FeedId');
      window.Mixpanel.actions.resetProp('Headline');
    });
  };
  componentWillUnmount() {
    clearTimeout(this.ttHandle);
    window.removeEventListener('resize', this._resize);
  }
  timeAgo = (time) => {
    // console.log(moment.utc().utcOffset(this.props.timeOffset).diff(moment(time),'minutes'))
    let EventTime = moment(time);
    let TimeChar = EventTime.fromNow(true).split(' ')[0][0] === 'a' ? 1 : EventTime.fromNow(true).split(' ')[0];
    let FromChar = `${EventTime.fromNow(true).split(' ')[1][0]}${EventTime.fromNow(true).split(' ')[1][1]}`;
    if (FromChar === 'we') {
      FromChar = 'w';
    }
    if (FromChar === 'we') {
      FromChar = 'w';
    }
    if (FromChar === 'da') {
      FromChar = 'd';
    }
    if (FromChar === 'ho') {
      FromChar = 'h';
    }
    if (FromChar === 'ye') {
      FromChar = 'y';
    }
    if (FromChar === 'mi') {
      FromChar = 'm';
    }
    let EventTimeAgo = `${TimeChar}${FromChar}`;
    if (EventTimeAgo === '1fe') {
      EventTimeAgo = '1m';
    }
    return EventTimeAgo;
  };
  render() {
    const { msgLinkToolTip } = this.state;
    const urlSearchParams = new URLSearchParams(this.props.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    let FromMobile = params && Object.keys(params).length !== 0 && Object.getPrototypeOf(params) === Object.prototype && typeof params.mobileapp !== 'undefined' && params.mobileapp === 'true';
    // console.log(this.props.Theme)
    // console.log(this.props.userData.loginlessuser)
    if (this.props.lastFeedDetail) {
      let story = this.props.eachFeed;
      let storyTimeAgo = this.timeAgo(story.LastFeedTime);
      let storySourceIcon = this.feedSourceIcon(story.Source);
      storySourceIcon = storySourceIcon + '.svg';
      // if (this.state.hovered) {
      //   storySourceIcon = storySourceIcon + 'blue.svg';
      // } else {
      //   storySourceIcon = storySourceIcon + '.svg';
      // }
      let storySourceToolTip = this.feedSourceToolTip(story.Source);
      let storyFormattedText = this.formatFeedText(
        `<span class="margr5p fs12 padlr2tb2 text-blue1 fsmx60010px">${storyTimeAgo}</span>${story.LastFeedText}`
      );
      return (
        <Col
          lg={12}
          sm={12}
          xs={12}
          md={12}
          xl={12}
          className={`story-feed nopad  ${this.props.EventReadStatus ? '' : 'bg-headbar-unread'}`}
          onMouseEnter={() => this.mouseOverHandler('Mouse over last feed')}
        >
          <Row className={`marg-0 h-100 `} style={{padding: "10px 0px 0px 0px", marginLeft: "10px", marginRight: "10px", borderBottom: this.props.Theme === 'light' ? "1px solid #afafaf" : "1px solid #696969"}}>
            <Col
              lg={10}
              sm={8}
              xs={8}
              md={10}
              xl={10}
              className={`feed-text ${this.props.Theme === 'light' ? 'lightmode' : ''} ${this.props.EventReadStatus ? '' : 'bg-headbar-unread'}`}
              onClick={this.hoverClick}
            >
              <Row className="h-100 my-auto marg-0 pointer">
                <Col className="align-self-center nopad text-left text-offwhite3">{storyFormattedText}</Col>
              </Row>
            </Col>
            {
              (this.props.render === 'iframe' && this.props.userData.loginlessuser !== 'Iforex') ? 
                (
                  <Col lg={1} sm={2} xs={2} md={1} xl={1} className="feed-rep  ">
                    <div className="h-100 marg-0 dispf minwid117perc padr5">
                      <ReputationIndicator
                        publisher={story.Publisher}
                        reputation={story.UserReputationRate}
                        repData={story.UserReputationDetails}
                        {...story}
                        rootId={this.props.rootId}
                      />
                      {/* <StoryElapsedTime time={story.LastFeedTime} pa={'fe'} rootId={this.props.rootId} />               */}
                    </div>
                  </Col>
                ) : ('')
            }
            
            <Col
              lg={1}
              sm={2}
              xs={2}
              md={1}
              xl={1}
              className={`story-detail-ico text-left align-self-center ${
                this.props.EventReadStatus ? '' : 'bg-headbar-unread'
              }`}
              onMouseEnter={this.hover}
              onMouseLeave={this.hover}
            >
              <div className={``}>
                <a
                  // rel="noopener noreferrer"
                  // target="_blank"
                  // href={`${story.FeedURL}`}
                  
                  onMouseEnter={() => this.toggleMsgtoLinkToolTip(true)}
                  onMouseLeave={() => this.toggleMsgtoLinkToolTip(false)}
                  onPointerOut={() => this.toggleMsgtoLinkToolTip(false)}
                >
                  <img src={`${APPPATH.ImageUrl}/${storySourceIcon}`} className="feedico" alt="CrowdSense.ai" onClick={() => {
                  
                    if((this.props.render !== 'iframe')) {
                      if(FromMobile) {
                        window.location.href = story.FeedURL;
                      } else {
                        window.open(`${story.FeedURL}`, '_blank');
                      }
                    } else if(this.props.render === 'iframe' && this.props.userData.loginlessuser !== 'Iforex') {
                      if(FromMobile) {
                        window.location.href = story.FeedURL;
                      } else {
                        window.open(`${story.FeedURL}`, '_blank');
                      }
                    }
                    
                    this.mixpanelCapture(story.LastFeedText);
                  }} />
                </a>
                <Tooltip view={storySourceToolTip} show={msgLinkToolTip} type={`left`} />
              </div>
            </Col>
                
            
          </Row>
        </Col>
      );
    } else {
      const { eachFeed } = this.props;
      let fulltext = eachFeed.FullText.replace('No_User:', '').replace(/\s\s+/g, ' ')
      let feeduser = '';
      let FTextBits = fulltext.trim().split(' ');
      // console.log(fulltext, FTextBits)
      if (FTextBits.length > 1) {
        let usrnameendbit = 0;
        let endbitfound = false;
        FTextBits.forEach((bit, i) => {
          if(!endbitfound){
            if(bit.includes(':')) {
              usrnameendbit = i
              endbitfound = true
            }
          }
        })
        FTextBits.forEach((bit, i) => {
          if(i <= usrnameendbit) {
            feeduser = `${feeduser} ${bit}`;
          }
        })
        
      }
      // if (FTextBits.length > 1 && FTextBits[1].toLowerCase() === 'from' && FTextBits[3] === '-') {
      //   formattedText = text.replace(`${FTextBits[0]} ${FTextBits[1]} ${FTextBits[2]} ${FTextBits[3]}`, function (
      //     langTxt
      //   ) {
      //     return `<span class="from-lang-indicator"> ${FTextBits[1]} ${FTextBits[2]} ${FTextBits[3]} </span>${FTextBits[0]}`;
      //   });
      // } else if (FTextBits.length > 1 && FTextBits[1].toLowerCase() === 'translated' && FTextBits[2] === '-') {
      //   formattedText = text.replace(`${FTextBits[1]} ${FTextBits[2]}`, function (langTxt) {
      //     return `<span class="from-lang-indicator"> ${FTextBits[1]} ${FTextBits[2]} </span>`;
      //   });
      // }
      // console.log(eachFeed)
      // console.log('timeOffset: ', this.props.timeOffset)
      // console.log('time Now: ',moment.utc().format())
      // console.log('User TimeZone Time Now: ',moment.utc().utcOffset(this.props.timeOffset).format())
      // console.log('feed Time: ', eachFeed.FeedTime)
      // console.log('ddiff: ', moment.utc().utcOffset(this.props.timeOffset).diff(moment(eachFeed.FeedTime), 'minutes'))
      let feedTimeAgo = this.timeAgo(eachFeed.FeedTime);
      let storySourceIcon = this.feedSourceIcon(eachFeed.Source);
      // console.log(eachFeed.Source)
      let storySourceToolTip = this.feedSourceToolTip(eachFeed.Source);
      //<span class="margr5p fs12 padlr2tb2 text-blue1 fsmx60010px">{feedTimeAgo}</span>
      let formattedText = '';
      if((this.props.lang && this.props.lang !== 'eng') || (this.props.render === 'iframe' && this.props.userData.loginlessuser === 'Iforex')) {
        formattedText = this.formatFeedText(`${feeduser !== '' ? feeduser : ''} ${eachFeed.FeedURL}`);
      } else {
        formattedText = this.formatFeedText(`${fulltext}`);
      }
      let feedRepeatList =
        eachFeed.FeedRepeats > 0 ? (
          <Row className={`marg-0 msg-tooltipMenu feedico-moretip text-center`}>
            {/* feedico-moretip*/}
            <Col xs={12} sm={12} md={12} xl={12} lg={12} className="tipHead text-center nopad">
              +{eachFeed.FeedRepeats ? eachFeed.FeedRepeats : '0'}
            </Col>
          </Row>
        ) : (
          ''
        );
      // let optStyleIco = {}
      // if(this.props.TDeckColWidth <= 387) { optStyleIco = {'width' : '30px !important', 'height' : '40px !important'} }
      // if (this.state.hovered) {
      //   storySourceIcon = storySourceIcon + 'blue.svg';
      // } else {
      //   storySourceIcon = storySourceIcon + '.svg';
      // }
      storySourceIcon = storySourceIcon + '.svg';
      let feedbottomborder = '';
      if(!this.props.lastFeed) {
        feedbottomborder = this.props.Theme === 'light' ? '1px solid #afafaf' : '1px solid #696969'
      }
      return (
        <Col
          lg={12}
          sm={12}
          xs={12}
          md={12}
          xl={12}
          className={`story-feed nopad `}
          key={eachFeed.FeedId}
          onMouseEnter={() => this.mouseOverHandler('Mouse over feed')}
        >
          <Row className="marg-0" style={{padding: "10px 0px 0px 0px", marginLeft: '10px', marginRight: '10px', borderBottom: feedbottomborder}}>
            <div 
              
              className={`feedlinkicon`}>
                <div>
                  <a
                    // rel="noopener noreferrer"
                    // target={`${this.props.render !== 'iframe' ? "_blank" : ''}`}
                    // href={`${this.props.render !== 'iframe' ? eachFeed.FeedURL : ''}`}
                    onClick={() => {
                      this.mixpanelCapture(eachFeed.FullText);
                    }}
                    onMouseEnter={() => this.toggleMsgtoLinkToolTip(true)}
                    onMouseLeave={() => this.toggleMsgtoLinkToolTip(false)}
                    onPointerOut={() => this.toggleMsgtoLinkToolTip(false)}
                  >
                    <img
                      src={`${APPPATH.ImageUrl}/${storySourceIcon}`}
                      className={`${this.props.TDeckColWidth <= 387 ? 'feedico' : 'feedico'} ${this.props.lang && this.props.lang === 'ara'? 'arabic' : ''}`}
                      alt="CrowdSense.ai"
                    />
                  </a>
                  <Tooltip view={storySourceToolTip} show={msgLinkToolTip} type={`left`} />
                </div>
                <div style={{marginTop: "-3px"}}>
                  <span className=" fs11 text-offwhite1">{feedTimeAgo}</span>
                </div>
            </div>
            <Col 
              lg={this.props.render === 'iframe' && this.props.userData.loginlessuser !== 'Iforex' ? 10 : 11} 
              sm={this.props.render === 'iframe' && this.props.userData.loginlessuser !== 'Iforex' ? 10 : 11} 
              xs={this.props.render === 'iframe' && this.props.userData.loginlessuser !== 'Iforex' ? 10 : 11} 
              md={this.props.render === 'iframe' && this.props.userData.loginlessuser !== 'Iforex' ? 10 : 11} 
              xl={this.props.render === 'iframe' && this.props.userData.loginlessuser !== 'Iforex' ? 10 : 11} 
              className={`feed-text nopointer ${this.props.Theme === 'light' ? 'lightmode' : ''}`}
            >
              <Row className="h-100 my-auto marg-0 pointer">
                <Col className={`nopad ${this.props.lang && this.props.lang === 'ara'? 'text-right margright5' : 'text-left margl5'} text-offWhite3 linebreak`} onClick={() => {
                  if(this.props.render !== 'iframe') {
                    window.open(eachFeed.FeedURL, '_blank')
                  } else if(this.props.render === 'iframe' && this.props.userData.loginlessuser !== 'Iforex') {
                    window.open(eachFeed.FeedURL, '_blank')
                  }
                }}>{formattedText}</Col>
              </Row>
            </Col>


            {
              (this.props.render === 'iframe' && this.props.userData.loginlessuser !== 'Iforex') ?
                (
                  <Col lg={1} sm={1} xs={1} md={1} xl={1} className="feed-rep nopointer ">
                    <Row className="h-100 marg-0 ">
                      <Col className="nopad  padt10">
                        {
                          eachFeed.Publisher !== '' && typeof eachFeed.Publisher !== 'undefined' && eachFeed.Publisher !== 'No User' ? (
                            <ReputationIndicator
                              publisher={eachFeed.Publisher}
                              reputation={eachFeed.UserReputationRate}
                              repData={eachFeed.UserReputationDetails}
                              {...eachFeed}
                              rootId={this.props.rootId}
                              {...this.props.story}
                              alertDetailId={eachFeed.FeedId}
                              togglePopup={this.props.togglePopup}
                            />
                          ) : ('')
                        }
                        

                        {/* <StoryElapsedTime time={eachFeed.FeedTime} pa={'fe'} rootId={this.props.rootId} /> */}
                      </Col>
                    </Row>
                  </Col>
                ) : ('')
            }
            
          </Row>
        </Col>
      );
    }
  }
}

export default StoryDetailsEach;
