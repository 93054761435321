import React, { Component } from 'react';
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { SetTMSort } from '../../actions/trendingmentions.action';
// import * as moment from 'moment';
import { connect } from 'react-redux';
// import { APPPATH } from '../../modules/helper.module';
import { isMobile } from 'react-device-detect';

class TMTabTitles extends Component {
  constructor(props) {
    super();
    // console.log(props)
    this.state = {
      activeTab: props.activeTab,
      lastSortedColumn: props.tmSortedColumn,
      lastSortingType: props.tmSortingType,
    };
    this.ttHandle = '';
    this.ScoreTitleCol = React.createRef();
    this.ChangeTitleCol = React.createRef();
  }

  componentWillReceiveProps(nextProps, prevState) {
    this.setState({
      ...this.state,
      activeTab: nextProps.activeTab,
      lastSortedColumn: nextProps.tmSortedColumn,
      lastSortingType: nextProps.tmSortingType,
    });
  }

  getnewTab = (sortedcolumn) => {
    let newTab = '';
    if(sortedcolumn.startsWith('Sentiment')) {
      newTab = 'TopSentiment'
    } else if(sortedcolumn.startsWith('Mentions')) {
      newTab = 'TopMentions'
    } else if(sortedcolumn.startsWith('Reach')) {
      newTab = 'TopReach'
    } else if(sortedcolumn.startsWith('Price')) {
      newTab = 'TopPrice'
    } else if(sortedcolumn.startsWith('Volume')) {
      newTab = 'TopVolume'
    }
    return newTab;
  }

  TMSort = (sortedColumn) => {
    const { colType, userData, cc_index, cc_Preferences } = this.props;
    let newTab = '';
    if (colType !== 'tmd') {
      let { activeTab, lastSortedColumn, lastSortingType } = this.state;
      console.log(activeTab, lastSortedColumn, lastSortingType, sortedColumn)
      let columnChange = false;
      let tabChange = false;
      if (lastSortedColumn !== sortedColumn) {
        let colName = lastSortedColumn.split(' ')[0]
        if(!sortedColumn.startsWith(colName)) {
          tabChange = true
        }
        columnChange = true;
        // lastSortingType = 1;
        lastSortedColumn = sortedColumn;
      } else {
        if (!cc_Preferences.isPro) {
          return; //do nothing
        } else if (lastSortingType === 0) {
          lastSortingType = 1;
        } else {
          lastSortingType *= -1;
        }
      }
      console.log(tabChange, columnChange, sortedColumn, lastSortingType)
      
        this.setState(
          {
            ...this.state,
            lastSortedColumn,
            lastSortingType,
          },
          () => {
            if(tabChange) {
              newTab = this.getnewTab(sortedColumn)
              this.props.changeTMTabs(newTab, cc_index, lastSortedColumn, false, this.props.listkey)
            } else {
              // this.props.SetTMSort(activeTab, lastSortedColumn, lastSortingType, columnChange);
              this.props.tmSortChange(activeTab, lastSortedColumn, lastSortingType, columnChange, cc_index, this.props.listkey);
            }
          }
        );
      
      
      
    }
  };

  toggleToolTip = (e, showState, dom, TipText, ToolTipStyleData) => {
    // console.log(showState, dom, TipText, ToolTipStyleData);

    this.props.toggleOverlay(showState, dom, TipText, ToolTipStyleData);
  };

  gettoolTipText = (ActiveTab, ActiveCol) => {
    if (ActiveTab === 'TopMentions') {
      if (ActiveCol === 1) {
        return 'Top coins by number of posts mentioning coin';
      } else if (ActiveCol === 2) {
        return 'Top coins by change in number of posts mentioning coin';
      }
    } else if (ActiveTab === 'TopReach') {
      if (ActiveCol === 1) {
        return 'Top coins by social media followers receiving posts about that coin';
      } else if (ActiveCol === 2) {
        return 'Top coins by change in social media followers receiving posts about that coin';
      }
    } else if (ActiveTab === 'TopSentiment') {
      if (ActiveCol === 1) {
        return 'Top coins by positive or negative net sentiment score';
      } else if (ActiveCol === 2) {
        return 'Top coins by positive or negative change in net sentiment score';
      }
    } else if (ActiveTab === 'TopPrice') {
      if (ActiveCol === 1) {
        return 'Top coins by price';
      } else if (ActiveCol === 2) {
        return 'Top coins by price change';
      }
    } else if (ActiveTab === 'TopVolume') {
      if (ActiveCol === 1) {
        return 'Top coins by traded volume in 24 hours';
      }
    }
  };

  entering = (e) => {
    e.children[1].style.backgroundColor = '#37aaf8';
  };

  render() {
    const { activeTab, lastSortedColumn, lastSortingType } = this.state;
    const { colType, minify, userData, cc_Preferences } = this.props;

    // console.log(activeTab, lastSortedColumn, lastSortingType)

    let TabTitle =
      this.props.colType === 'tmd'
        ? activeTab === 'TopMentions'
          ? 'Mentions'
          : activeTab === 'TopReach'
          ? 'Reach'
          : activeTab === 'TopSentiment'
          ? 'Sentiment'
          : ''
        : '';

    let TabIcon =
      this.props.colType === 'tmd'
        ? activeTab === 'TopMentions'
          ? 'fa-comments-o'
          : activeTab === 'TopReach'
          ? 'fa-rss'
          : activeTab === 'TopSentiment'
          ? 'fa-smile-o'
          : ''
        : '';

    let sortedColumn =
      activeTab === 'TopMentions'
        ? 'Mentions'
        : activeTab === 'TopReach'
        ? 'Reach'
        : activeTab === 'TopSentiment'
        ? 'Sentiment'
        : activeTab === 'TopPrice'
        ? 'Price'
        : activeTab === 'TopVolume'
        ? 'Volume'
        : '';
    
      if(this.props.viewState === 'expandeddesktop' || this.props.viewState === 'expandedmobile') {
        return(
          
            <tr>
              <th className={`th2 bg-tmSubtitles text-white ${this.props.Theme === 'light' ? 'lightmode' : ''}`} style={{textAlign: 'left', paddingLeft: 20, width: '15%', borderRight: '1px solid #3e3e3e'}}>
                <span>Rank</span>
              </th>
              <th
                className={`th2 ${(lastSortedColumn === 'Sentiment') && lastSortingType !== 0 ? 'text-blue' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''}`}
                onClick={() => { this.TMSort('Sentiment'); }}
                style={{borderLeft: '1px solid #3e3e3e'}}
              >
                <span className={``} >
                  Sentiment
                </span>
                {lastSortedColumn === 'Sentiment' && lastSortingType !== 0 ? (
                  <i
                    className={`fa ${lastSortingType === -1 ? 'fa-caret-up' : 'fa-caret-down'} padlr5 `}
                    style={{paddingLeft: '3px !important'}}
                  ></i>
                ) : ('')}
              </th>
              <th
                className={`th2 ${(lastSortedColumn === 'Sentiment Change') && lastSortingType !== 0 ? 'text-blue' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''}`}
                onClick={() => { this.TMSort('Sentiment Change'); }}
                style={{borderRight: '1px solid #3e3e3e'}}
              >
                <span className={``} >
                  Change
                </span>
                {lastSortedColumn === 'Sentiment Change' && lastSortingType !== 0 ? (
                  <i
                    className={`fa ${lastSortingType === -1 ? 'fa-caret-up' : 'fa-caret-down'} padlr5 `}
                    style={{paddingLeft: '3px !important'}}
                  ></i>
                ) : ('')}
              </th>
              <th
                className={`th2 ${(lastSortedColumn === 'Mentions Score') && lastSortingType !== 0 ? 'text-blue' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''}`}
                onClick={() => { this.TMSort('Mentions Score'); }}
                style={{borderLeft: '1px solid #3e3e3e'}}
              >
                <span className={``} >
                  Mentions
                </span>
                {lastSortedColumn === 'Mentions Score' && lastSortingType !== 0 ? (
                  <i
                    className={`fa ${lastSortingType === -1 ? 'fa-caret-up' : 'fa-caret-down'} padlr5 `}
                    style={{paddingLeft: '3px !important'}}
                  ></i>
                ) : ('')}
              </th>
              <th
                className={`th2 ${(lastSortedColumn === 'Mentions Change') && lastSortingType !== 0 ? 'text-blue' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''}`}
                onClick={() => { this.TMSort('Mentions Change'); }}
                style={{borderRight: '1px solid #3e3e3e'}}
              >
                <span className={``} >
                  Change
                </span>
                {lastSortedColumn === 'Mentions Change' && lastSortingType !== 0 ? (
                  <i
                    className={`fa ${lastSortingType === -1 ? 'fa-caret-up' : 'fa-caret-down'} padlr5 `}
                    style={{paddingLeft: '3px !important'}}
                  ></i>
                ) : ('')}
              </th>
              {
                this.props.showtopreach && (
                  <th
                    className={`th2 ${(lastSortedColumn === 'Reach Score') && lastSortingType !== 0 ? 'text-blue' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''}`}
                    onClick={() => { this.TMSort('Reach Score'); }}
                    style={{borderLeft: '1px solid #3e3e3e'}}
                  >
                    <span className={``} >
                      Reach
                    </span>
                    {lastSortedColumn === 'Reach Score' && lastSortingType !== 0 ? (
                      <i
                        className={`fa ${lastSortingType === -1 ? 'fa-caret-up' : 'fa-caret-down'} padlr5 `}
                        style={{paddingLeft: '3px !important'}}
                      ></i>
                    ) : ('')}
                  </th>
                )
              }
              {
                this.props.showtopreach && (
                  <th
                    className={`th2 ${(lastSortedColumn === 'Reach Change') && lastSortingType !== 0 ? 'text-blue' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''}`}
                    onClick={() => { this.TMSort('Reach Change'); }}
                    style={{borderRight: `${(this.props.userData.usertype === 'None' || this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin') && '1px solid #3e3e3e'}`}}
                  >
                    <span className={``} >
                      Change
                    </span>
                    {lastSortedColumn === 'Reach Change' && lastSortingType !== 0 ? (
                      <i
                        className={`fa ${lastSortingType === -1 ? 'fa-caret-up' : 'fa-caret-down'} padlr5 `}
                        style={{paddingLeft: '3px !important'}}
                      ></i>
                    ) : ('')}
                  </th>
                )
              }
              
              {
                (this.props.userData.usertype === 'None' || this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin') && (
                  <th
                    className={`th2 ${(lastSortedColumn === 'Price') && lastSortingType !== 0 ? 'text-blue' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''}`}
                    onClick={() => { this.TMSort('Price'); }}
                    style={{borderLeft: '1px solid #3e3e3e'}}
                  >
                    <span className={``} >
                      Price
                    </span>
                    {lastSortedColumn === 'Price' && lastSortingType !== 0 ? (
                      <i
                        className={`fa ${lastSortingType === -1 ? 'fa-caret-up' : 'fa-caret-down'} padlr5 `}
                        style={{paddingLeft: '3px !important'}}
                      ></i>
                    ) : ('')}
                  </th>
                )
              }
              {
                (this.props.userData.usertype === 'None' || this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin') && (
                  <th
                    className={`th2 ${(lastSortedColumn === 'Price Change') && lastSortingType !== 0 ? 'text-blue' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''}`}
                    onClick={() => { this.TMSort('Price Change'); }}
                    style={{borderRight: '1px solid #3e3e3e'}}
                  >
                    <span className={``} >
                      Change
                    </span>
                    {lastSortedColumn === 'Price Change' && lastSortingType !== 0 ? (
                      <i
                        className={`fa ${lastSortingType === -1 ? 'fa-caret-up' : 'fa-caret-down'} padlr5 `}
                        style={{paddingLeft: '3px !important'}}
                      ></i>
                    ) : ('')}
                  </th>
                )
              }
              {
                (this.props.userData.usertype === 'None' || this.props.userData.usertype === 'WUser' || this.props.userData.usertype === 'WAdmin') && (
                  <th
                  colSpan={2}
                    className={`th2 ${(lastSortedColumn === 'Volume') && lastSortingType !== 0 ? 'text-blue' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''}`}
                    onClick={() => { this.TMSort('Volume'); }}
                    style={{borderLeft: '1px solid #3e3e3e'}}
                  >
                    <span className={``} >
                      Volume
                    </span>
                    {lastSortedColumn === 'Volume' && lastSortingType !== 0 ? (
                      <i
                        className={`fa ${lastSortingType === -1 ? 'fa-caret-up' : 'fa-caret-down'} padlr5 `}
                        style={{paddingLeft: '3px !important'}}
                      ></i>
                    ) : ('')}
                  </th>
                )
              }
              
            </tr>
          
        )
        
      } else {
        return (
          <div className={`${this.props.colType === 'tm' ? 'bg-tmSubtitles' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''}`} style={{ paddingLeft: 10 }}>
            {cc_Preferences.isPro === 'false' && (
              <Row className={`h-100`}>
                <Col
                  className={`sorting-col pointer ${(lastSortedColumn === 'Price Change' || lastSortedColumn === 'Volume Change' || lastSortedColumn === 'Mentions Change' || lastSortedColumn === 'Reach Change' || lastSortedColumn === 'Sentiment Change') && lastSortingType !== 0 && colType !== 'tmd' ? 'text-blue' : ''} ${minify ? 'STM-Col2-Adjust' : ''}`}
                  onClick={() => {
                    let sortedColumn =
                      activeTab === 'TopMentions'
                        ? 'Mentions Change'
                        : activeTab === 'TopReach'
                        ? 'Reach Change'
                        : activeTab === 'TopSentiment'
                        ? 'Sentiment Change'
                        : activeTab === 'TopPrice'
                        ? 'Price Change'
                        : '';
                    if (activeTab === 'TopVolume') {
                      //do nothing
                      return;
                    }
                    this.TMSort(sortedColumn);
                  }}
                >
                  <Row className={`marg-0 nopad h-100`}>
                    <Col className="nopad align-self-center">
                      <span
                        className={`${activeTab === 'TopVolume' && 'tranparent-font'}`}
                        ref={this.ChangeTitleCol}
                        onMouseEnter={(e) => {
                          let ToolTipStyleData = {
                            Arrow: {
                              marginLeft: '50%',
                            },
                            ToolTip: {
                              align: 'leftmedium',
                            },
                          };
                          if (activeTab !== 'TopVolume') {
                            this.toggleToolTip(
                              e,
                              true,
                              this.ChangeTitleCol,
                              this.gettoolTipText(activeTab, 2),
                              ToolTipStyleData
                            );
                          }
                        }}
                        onMouseLeave={(e) => {
                          this.toggleToolTip(e, false);
                        }}
                      >
                        {activeTab === 'TopMentions'
                          ? 'Top Mentions Change'
                          : activeTab === 'TopReach'
                          ? 'Top Reach Change'
                          : activeTab === 'TopSentiment'
                          ? 'Top Sentiment Change'
                          : activeTab === 'TopPrice'
                          ? 'Top Price Change'
                          : activeTab === 'TopVolume'
                          ? 'Top Volume Change'
                          : ''}
                      </span>
                    </Col>
                  </Row>
                </Col>
                <Col
                  className={`sorting-col pointer ${
                    (lastSortedColumn === 'Price' ||
                      lastSortedColumn === 'Volume' ||
                      lastSortedColumn === 'Mentions Score' ||
                      lastSortedColumn === 'Reach Score' ||
                      lastSortedColumn === 'Sentiment') &&
                    lastSortingType !== 0 &&
                    colType !== 'tmd'
                      ? 'text-blue blue-bottom-border'
                      : ''
                  } ${minify ? 'STM-Col1-Adjust' : ''}`}
                  onClick={() => {
                    let sortedColumn =
                      activeTab === 'TopMentions'
                        ? 'Mentions Score'
                        : activeTab === 'TopReach'
                        ? 'Reach Score'
                        : activeTab === 'TopSentiment'
                        ? 'Sentiment'
                        : activeTab === 'TopPrice'
                        ? 'Price'
                        : activeTab === 'TopVolume'
                        ? 'Volume'
                        : '';
                    this.TMSort(sortedColumn);
                  }}
                >
                  <Row className={`marg-0 nopad h-100`}>
                    <Col className="nopad align-self-center">
                      <span
                        className={``}
                        ref={this.ScoreTitleCol}
                        onMouseEnter={(e) => {
                          let ToolTipStyleData = {
                            Arrow: {
                              marginLeft: '50%',
                            },
                            ToolTip: {
                              align: 'left-center',
                            },
                          };
                          this.toggleToolTip(
                            e,
                            true,
                            this.ScoreTitleCol,
                            this.gettoolTipText(activeTab, 1),
                            ToolTipStyleData
                          );
                        }}
                        onMouseLeave={(e) => {
                          this.toggleToolTip(e, false);
                        }}
                      >
                        {activeTab === 'TopMentions'
                          ? 'Most Mentioned'
                          : activeTab === 'TopReach'
                          ? 'Most Reached'
                          : activeTab === 'TopSentiment'
                          ? 'Most Positive Sentiment'
                          : activeTab === 'TopPrice'
                          ? 'Highest Price'
                          : activeTab === 'TopVolume'
                          ? 'Top by Trading Volume'
                          : ''}
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
            {cc_Preferences.isPro === 'true' && (
              <Row className={`marg-0 nopad`}>
                <Col
                  lg={this.props.minify ? 7 : 7}
                  sm={this.props.minify ? 7 : 7}
                  xs={this.props.minify ? 7 : 7}
                  md={this.props.minify ? 7 : 7}
                  xl={this.props.minify ? 7 : 7}
                  className={`nopad tmTabTitleProWidthAdjust1 tmColTitle ${minify ? 'STM-ttickerCol-Adjust' : ''}`}
                >
                  <Row className={`marg-0 nopad h-100`}>
                    <Col lg={1} sm={1} xs={1} md={1} xl={1} className={` fssmWid-Adjust  align-self-center nopad text-white ${this.props.Theme === 'light' ? 'lightmode' : ''}  padl10  `}>
                      Rank
                    </Col>
                  </Row>
                </Col>

                <Col
                  lg={this.props.minify ? 2 : 2}
                  sm={this.props.minify ? 2 : 2}
                  xs={this.props.minify ? 2 : 2}
                  md={this.props.minify ? 2 : 2}
                  xl={this.props.minify ? 2 : 2}
                  className={`nopad pointer tmTabTitleProWidthAdjust2 tmColTitle ${this.props.Theme === 'light' ? 'lightmode' : ''} ${
                    (lastSortedColumn === 'Price' ||
                      lastSortedColumn === 'Volume' ||
                      lastSortedColumn === 'Mentions Score' ||
                      lastSortedColumn === 'Reach Score' ||
                      lastSortedColumn === 'Sentiment') &&
                    lastSortingType !== 0 &&
                    colType !== 'tmd'
                      ? 'text-blue'
                      : ''
                  } ${minify ? 'STM-Col1-Adjust' : ''}`}
                  onClick={() => {
                    let sortedColumn =
                      activeTab === 'TopMentions'
                        ? 'Mentions Score'
                        : activeTab === 'TopReach'
                        ? 'Reach Score'
                        : activeTab === 'TopSentiment'
                        ? 'Sentiment'
                        : activeTab === 'TopPrice'
                        ? 'Price'
                        : activeTab === 'TopVolume'
                        ? 'Volume'
                        : '';
                    this.TMSort(sortedColumn);
                  }}
                  style={{marginLeft: '-5px'}}
                >
                  <Row className={`marg-0 nopad h-100`}>
                    <Col className="nopad align-self-center">
                      <span className={``} ref={this.ScoreTitleCol}
                        onMouseEnter={(e) => {
                          let ToolTipStyleData = {
                            Arrow: {
                              marginLeft: '20%',
                            },
                            ToolTip: {
                              align: 'left',
                            },
                          };
                          this.toggleToolTip(e, true, this.ScoreTitleCol, this.gettoolTipText(activeTab, 1), ToolTipStyleData);
                        }}
                        onMouseLeave={(e) => {
                          this.toggleToolTip(e, false);
                        }}
                      >
                        {activeTab === 'TopMentions'
                          ? 'Mentions'
                          : activeTab === 'TopReach'
                          ? 'Reach'
                          : activeTab === 'TopSentiment'
                          ? 'Sentiment'
                          : activeTab === 'TopPrice'
                          ? 'Price'
                          : activeTab === 'TopVolume'
                          ? 'Volume'
                          : ''}
                      </span>

                      {(lastSortedColumn === 'Price' ||
                        lastSortedColumn === 'Volume' ||
                        lastSortedColumn === 'Mentions Score' ||
                        lastSortedColumn === 'Reach Score' ||
                        lastSortedColumn === 'Sentiment') &&
                      lastSortingType !== 0 &&
                      colType !== 'tmd' ? (
                        <i
                          className={`fa ${
                            lastSortedColumn === 'Price' ||
                            lastSortedColumn === 'Volume' ||
                            lastSortedColumn === 'Mentions Score' ||
                            lastSortedColumn === 'Reach Score' ||
                            lastSortedColumn === 'Sentiment'
                              ? lastSortingType === -1
                                ? 'fa-caret-up'
                                : 'fa-caret-down'
                              : 'fa-caret-down'
                          } padlr5 `}
                          style={{paddingLeft: '3px !important'}}
                        ></i>
                      ) : (
                        ''
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col
                  lg={this.props.minify ? 2 : 2}
                  sm={this.props.minify ? 2 : 2}
                  xs={this.props.minify ? 2 : 2}
                  md={this.props.minify ? 2 : 2}
                  xl={this.props.minify ? 2 : 2}
                  className={`nopad pointer tmTabTitleProWidthAdjust3 tmColTitle ${this.props.Theme === 'light' ? 'lightmode' : ''}
                  ${(lastSortedColumn === 'Price Change' ||
                      lastSortedColumn === 'Volume Change' ||
                      lastSortedColumn === 'Mentions Change' ||
                      lastSortedColumn === 'Reach Change' ||
                      lastSortedColumn === 'Sentiment Change') &&
                    lastSortingType !== 0 &&
                    colType !== 'tmd'
                      ? 'text-blue'
                      : ''
                  } ${minify ? 'STM-Col2-Adjust' : ''}`}
                  

                  onClick={() => {
                    let sortedColumn =
                      activeTab === 'TopMentions'
                        ? 'Mentions Change'
                        : activeTab === 'TopReach'
                        ? 'Reach Change'
                        : activeTab === 'TopSentiment'
                        ? 'Sentiment Change'
                        : activeTab === 'TopPrice'
                        ? 'Price Change'
                        : '';
                    this.TMSort(sortedColumn);
                  }}
                >
                  <Row className={`marg-0 nopad h-100`}>
                    <Col className="nopad align-self-center">
                      <span
                        className={``}
                        ref={this.ChangeTitleCol}
                        onMouseEnter={(e) => {
                          let ToolTipStyleData = {
                            Arrow: {
                              marginLeft: '20px',
                            },
                            ToolTip: {
                              align: 'center',
                            },
                          };
                          this.toggleToolTip(
                            e,
                            true,
                            this.ChangeTitleCol,
                            this.gettoolTipText(activeTab, 2),
                            ToolTipStyleData
                          );
                        }}
                        onMouseLeave={(e) => {
                          this.toggleToolTip(e, false);
                        }}
                      >
                        {activeTab === 'TopMentions'
                          ? 'Change'
                          : activeTab === 'TopReach'
                          ? 'Change'
                          : activeTab === 'TopSentiment'
                          ? 'Change'
                          : activeTab === 'TopPrice'
                          ? 'Change'
                          : ''}
                      </span>
                      {(lastSortedColumn === 'Price Change' ||
                        lastSortedColumn === 'Mentions Change' ||
                        lastSortedColumn === 'Reach Change' ||
                        lastSortedColumn === 'Sentiment Change') &&
                      lastSortingType !== 0 &&
                      colType !== 'tmd' ? (
                        <i
                          className={`fa ${
                            lastSortedColumn === 'Price Change' ||
                            lastSortedColumn === 'Mentions Change' ||
                            lastSortedColumn === 'Reach Change' ||
                            lastSortedColumn === 'Sentiment Change'
                              ? lastSortingType === -1
                                ? 'fa-caret-up'
                                : 'fa-caret-down'
                              : 'fa-caret-down'
                          } padlr5`}
                          style={{paddingLeft: '3px !important'}}
                        ></i>
                      ) : (
                        ''
                      )}
                    </Col>
                  </Row>
                </Col>
              
              </Row>
            )}
          </div>
        );
      }
    
    // return (
    //   <div style={{ paddingLeft: 10, backgroundColor: this.props.colType === 'tm' ? '#13181E' : '' }}>
    //     {cc_Preferences.isPro === 'false' && (
    //       <Row className={`h-100`}>
    //         <Col
    //           className={`sorting-col pointer ${(lastSortedColumn === 'Price Change' || lastSortedColumn === 'Volume Change' || lastSortedColumn === 'Mentions Change' || lastSortedColumn === 'Reach Change' || lastSortedColumn === 'Sentiment Change') && lastSortingType !== 0 && colType !== 'tmd' ? 'text-blue' : ''} ${minify ? 'STM-Col2-Adjust' : ''}`}
    //           onClick={() => {
    //             let sortedColumn =
    //               activeTab === 'TopMentions'
    //                 ? 'Mentions Change'
    //                 : activeTab === 'TopReach'
    //                 ? 'Reach Change'
    //                 : activeTab === 'TopSentiment'
    //                 ? 'Sentiment Change'
    //                 : activeTab === 'TopPrice'
    //                 ? 'Price Change'
    //                 : '';
    //             if (activeTab === 'TopVolume') {
    //               //do nothing
    //               return;
    //             }
    //             this.TMSort(sortedColumn);
    //           }}
    //         >
    //           <Row className={`marg-0 nopad h-100`}>
    //             <Col className="nopad align-self-center">
    //               <span
    //                 className={`${activeTab === 'TopVolume' && 'tranparent-font'}`}
    //                 ref={this.ChangeTitleCol}
    //                 onMouseEnter={(e) => {
    //                   let ToolTipStyleData = {
    //                     Arrow: {
    //                       marginLeft: '50%',
    //                     },
    //                     ToolTip: {
    //                       align: 'leftmedium',
    //                     },
    //                   };
    //                   if (activeTab !== 'TopVolume') {
    //                     this.toggleToolTip(
    //                       e,
    //                       true,
    //                       this.ChangeTitleCol,
    //                       this.gettoolTipText(activeTab, 2),
    //                       ToolTipStyleData
    //                     );
    //                   }
    //                 }}
    //                 onMouseLeave={(e) => {
    //                   this.toggleToolTip(e, false);
    //                 }}
    //               >
    //                 {activeTab === 'TopMentions'
    //                   ? 'Top Mentions Change'
    //                   : activeTab === 'TopReach'
    //                   ? 'Top Reach Change'
    //                   : activeTab === 'TopSentiment'
    //                   ? 'Top Sentiment Change'
    //                   : activeTab === 'TopPrice'
    //                   ? 'Top Price Change'
    //                   : activeTab === 'TopVolume'
    //                   ? 'Top Volume Change'
    //                   : ''}
    //               </span>
    //             </Col>
    //           </Row>
    //         </Col>
    //         <Col
    //           className={`sorting-col pointer ${
    //             (lastSortedColumn === 'Price' ||
    //               lastSortedColumn === 'Volume' ||
    //               lastSortedColumn === 'Mentions Score' ||
    //               lastSortedColumn === 'Reach Score' ||
    //               lastSortedColumn === 'Sentiment') &&
    //             lastSortingType !== 0 &&
    //             colType !== 'tmd'
    //               ? 'text-blue blue-bottom-border'
    //               : ''
    //           } ${minify ? 'STM-Col1-Adjust' : ''}`}
    //           onClick={() => {
    //             let sortedColumn =
    //               activeTab === 'TopMentions'
    //                 ? 'Mentions Score'
    //                 : activeTab === 'TopReach'
    //                 ? 'Reach Score'
    //                 : activeTab === 'TopSentiment'
    //                 ? 'Sentiment'
    //                 : activeTab === 'TopPrice'
    //                 ? 'Price'
    //                 : activeTab === 'TopVolume'
    //                 ? 'Volume'
    //                 : '';
    //             this.TMSort(sortedColumn);
    //           }}
    //         >
    //           <Row className={`marg-0 nopad h-100`}>
    //             <Col className="nopad align-self-center">
    //               <span
    //                 className={``}
    //                 ref={this.ScoreTitleCol}
    //                 onMouseEnter={(e) => {
    //                   let ToolTipStyleData = {
    //                     Arrow: {
    //                       marginLeft: '50%',
    //                     },
    //                     ToolTip: {
    //                       align: 'left-center',
    //                     },
    //                   };
    //                   this.toggleToolTip(
    //                     e,
    //                     true,
    //                     this.ScoreTitleCol,
    //                     this.gettoolTipText(activeTab, 1),
    //                     ToolTipStyleData
    //                   );
    //                 }}
    //                 onMouseLeave={(e) => {
    //                   this.toggleToolTip(e, false);
    //                 }}
    //               >
    //                 {activeTab === 'TopMentions'
    //                   ? 'Most Mentioned'
    //                   : activeTab === 'TopReach'
    //                   ? 'Most Reached'
    //                   : activeTab === 'TopSentiment'
    //                   ? 'Most Positive Sentiment'
    //                   : activeTab === 'TopPrice'
    //                   ? 'Highest Price'
    //                   : activeTab === 'TopVolume'
    //                   ? 'Top by Trading Volume'
    //                   : ''}
    //               </span>
    //             </Col>
    //           </Row>
    //         </Col>
    //       </Row>
    //     )}
    //     {cc_Preferences.isPro === 'true' && (
    //       <Row className={`marg-0 nopad`}>
    //         <Col
    //           lg={this.props.minify ? 7 : 7}
    //           sm={this.props.minify ? 7 : 7}
    //           xs={this.props.minify ? 7 : 7}
    //           md={this.props.minify ? 7 : 7}
    //           xl={this.props.minify ? 7 : 7}
    //           className={`nopad tmTabTitleProWidthAdjust1 tmColTitle ${minify ? 'STM-ttickerCol-Adjust' : ''}`}
    //         >
    //           <Row className={`marg-0 nopad h-100`}>
    //             <Col lg={1} sm={1} xs={1} md={1} xl={1} className=" fssmWid-Adjust  align-self-center nopad text-white padl10">
    //               Rank
    //             </Col>
    //           </Row>
    //         </Col>

    //         <Col
    //           lg={this.props.minify ? 2 : 2}
    //           sm={this.props.minify ? 2 : 2}
    //           xs={this.props.minify ? 2 : 2}
    //           md={this.props.minify ? 2 : 2}
    //           xl={this.props.minify ? 2 : 2}
    //           className={`nopad pointer tmTabTitleProWidthAdjust2 tmColTitle ${
    //             (lastSortedColumn === 'Price' ||
    //               lastSortedColumn === 'Volume' ||
    //               lastSortedColumn === 'Mentions Score' ||
    //               lastSortedColumn === 'Reach Score' ||
    //               lastSortedColumn === 'Sentiment') &&
    //             lastSortingType !== 0 &&
    //             colType !== 'tmd'
    //               ? 'text-blue'
    //               : ''
    //           } ${minify ? 'STM-Col1-Adjust' : ''}`}
    //           onClick={() => {
    //             let sortedColumn =
    //               activeTab === 'TopMentions'
    //                 ? 'Mentions Score'
    //                 : activeTab === 'TopReach'
    //                 ? 'Reach Score'
    //                 : activeTab === 'TopSentiment'
    //                 ? 'Sentiment'
    //                 : activeTab === 'TopPrice'
    //                 ? 'Price'
    //                 : activeTab === 'TopVolume'
    //                 ? 'Volume'
    //                 : '';
    //             this.TMSort(sortedColumn);
    //           }}
    //           style={{marginLeft: '-5px'}}
    //         >
    //           <Row className={`marg-0 nopad h-100`}>
    //             <Col className="nopad align-self-center">
    //               <span className={``} ref={this.ScoreTitleCol}
    //                 onMouseEnter={(e) => {
    //                   let ToolTipStyleData = {
    //                     Arrow: {
    //                       marginLeft: '20%',
    //                     },
    //                     ToolTip: {
    //                       align: 'left',
    //                     },
    //                   };
    //                   this.toggleToolTip(e, true, this.ScoreTitleCol, this.gettoolTipText(activeTab, 1), ToolTipStyleData);
    //                 }}
    //                 onMouseLeave={(e) => {
    //                   this.toggleToolTip(e, false);
    //                 }}
    //               >
    //                 {activeTab === 'TopMentions'
    //                   ? 'Mentions'
    //                   : activeTab === 'TopReach'
    //                   ? 'Reach'
    //                   : activeTab === 'TopSentiment'
    //                   ? 'Sentiment'
    //                   : activeTab === 'TopPrice'
    //                   ? 'Price'
    //                   : activeTab === 'TopVolume'
    //                   ? 'Volume'
    //                   : ''}
    //               </span>

    //               {(lastSortedColumn === 'Price' ||
    //                 lastSortedColumn === 'Volume' ||
    //                 lastSortedColumn === 'Mentions Score' ||
    //                 lastSortedColumn === 'Reach Score' ||
    //                 lastSortedColumn === 'Sentiment') &&
    //               lastSortingType !== 0 &&
    //               colType !== 'tmd' ? (
    //                 <i
    //                   className={`fa ${
    //                     lastSortedColumn === 'Price' ||
    //                     lastSortedColumn === 'Volume' ||
    //                     lastSortedColumn === 'Mentions Score' ||
    //                     lastSortedColumn === 'Reach Score' ||
    //                     lastSortedColumn === 'Sentiment'
    //                       ? lastSortingType === -1
    //                         ? 'fa-caret-up'
    //                         : 'fa-caret-down'
    //                       : 'fa-caret-down'
    //                   } padlr5 `}
    //                   style={{paddingLeft: '3px !important'}}
    //                 ></i>
    //               ) : (
    //                 ''
    //               )}
    //             </Col>
    //           </Row>
    //         </Col>
    //         <Col
    //           lg={this.props.minify ? 2 : 2}
    //           sm={this.props.minify ? 2 : 2}
    //           xs={this.props.minify ? 2 : 2}
    //           md={this.props.minify ? 2 : 2}
    //           xl={this.props.minify ? 2 : 2}
    //           className={`nopad pointer tmTabTitleProWidthAdjust3 tmColTitle 
    //           ${(lastSortedColumn === 'Price Change' ||
    //               lastSortedColumn === 'Volume Change' ||
    //               lastSortedColumn === 'Mentions Change' ||
    //               lastSortedColumn === 'Reach Change' ||
    //               lastSortedColumn === 'Sentiment Change') &&
    //             lastSortingType !== 0 &&
    //             colType !== 'tmd'
    //               ? 'text-blue'
    //               : ''
    //           } ${minify ? 'STM-Col2-Adjust' : ''}`}
              

    //           onClick={() => {
    //             let sortedColumn =
    //               activeTab === 'TopMentions'
    //                 ? 'Mentions Change'
    //                 : activeTab === 'TopReach'
    //                 ? 'Reach Change'
    //                 : activeTab === 'TopSentiment'
    //                 ? 'Sentiment Change'
    //                 : activeTab === 'TopPrice'
    //                 ? 'Price Change'
    //                 : '';
    //             this.TMSort(sortedColumn);
    //           }}
    //         >
    //           <Row className={`marg-0 nopad h-100`}>
    //             <Col className="nopad align-self-center">
    //               <span
    //                 className={``}
    //                 ref={this.ChangeTitleCol}
    //                 onMouseEnter={(e) => {
    //                   let ToolTipStyleData = {
    //                     Arrow: {
    //                       marginLeft: '20px',
    //                     },
    //                     ToolTip: {
    //                       align: 'center',
    //                     },
    //                   };
    //                   this.toggleToolTip(
    //                     e,
    //                     true,
    //                     this.ChangeTitleCol,
    //                     this.gettoolTipText(activeTab, 2),
    //                     ToolTipStyleData
    //                   );
    //                 }}
    //                 onMouseLeave={(e) => {
    //                   this.toggleToolTip(e, false);
    //                 }}
    //               >
    //                 {activeTab === 'TopMentions'
    //                   ? 'Change'
    //                   : activeTab === 'TopReach'
    //                   ? 'Change'
    //                   : activeTab === 'TopSentiment'
    //                   ? 'Change'
    //                   : activeTab === 'TopPrice'
    //                   ? 'Change'
    //                   : ''}
    //               </span>
    //               {(lastSortedColumn === 'Price Change' ||
    //                 lastSortedColumn === 'Mentions Change' ||
    //                 lastSortedColumn === 'Reach Change' ||
    //                 lastSortedColumn === 'Sentiment Change') &&
    //               lastSortingType !== 0 &&
    //               colType !== 'tmd' ? (
    //                 <i
    //                   className={`fa ${
    //                     lastSortedColumn === 'Price Change' ||
    //                     lastSortedColumn === 'Mentions Change' ||
    //                     lastSortedColumn === 'Reach Change' ||
    //                     lastSortedColumn === 'Sentiment Change'
    //                       ? lastSortingType === -1
    //                         ? 'fa-caret-up'
    //                         : 'fa-caret-down'
    //                       : 'fa-caret-down'
    //                   } padlr5`}
    //                   style={{paddingLeft: '3px !important'}}
    //                 ></i>
    //               ) : (
    //                 ''
    //               )}
    //             </Col>
    //           </Row>
    //         </Col>
           
    //       </Row>
    //     )}
    //   </div>
    // );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    activeTab: state.trendingMentions.coin_column_preferences[ownProps.cc_index] ? state.trendingMentions.coin_column_preferences[ownProps.cc_index].activeTab : '',
    tmSortedColumn: state.trendingMentions.coin_column_preferences[ownProps.cc_index] ? state.trendingMentions.coin_column_preferences[ownProps.cc_index].tmSortedColumn : '',
    tmSortingType: state.trendingMentions.coin_column_preferences[ownProps.cc_index] ? parseInt(state.trendingMentions.coin_column_preferences[ownProps.cc_index].tmSortingType) : '',
    showtopreach: state.settings.showtopreach,
    Theme: state.app_preferences.preferences.user_interface.Theme,
  };
};

const mapDispatchToProps = {
  SetTMSort,
};

export default connect(mapStateToProps, mapDispatchToProps)(TMTabTitles);
