import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Badge } from 'react-bootstrap';
import { APPPATH, TrendingMentionsHelper } from '../../../../modules/helper.module';
import { updateSecuritiesFilter } from '../../../../actions/filters.action';
import WatchListLevel from './watchlistLevel';
import ConfirmationPopUp from '../../../common/confirmationPopUp';
import { SecTypeIds } from '../../../../static_data/securitytypesid';
import { SpanishTranslations, PortugueseTranslations, JapaneseTranslations, ArabicTranslations } from '../../../../static_data/translations';
// import { isMobile } from "react-device-detect";

class WatchListIndex extends Component {
  constructor(props) {
    super();
    this.state = {
      searchValue: '',
      // securityFilter_Req: true,
      securities: props.securities,
      securitiesFilter: props.securitiesFilter,
      SecNameArr: props.SecNameArr,
      SecSymbolArr: props.SecSymbolArr,
      List: [],
      suggestionList: [],
      plwStatus: false,
      levelCount: 10, //default count value
      showError: props.securitiesFilter.length === 0,
      showSweetAlert: false,
      showOnlyOk: false,
      showconfirmpopup: false,
      onConfirm: () => {
        this.onCancelOfAlert();
      },
    };
    this.coinSearchInput = React.createRef();
    this.confirmPopup = false;
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      List: this.props.securities.length
        ? this.makeCoinList(
          this.props.securities,
          this.props.securitiesFilter,
          this.props.SecNameArr,
          this.state.levelCount
        )
        : [],
    });
  }

  componentWillReceiveProps(nextProps, prevState) {
    if (nextProps.securities !== this.props.securities || nextProps.securitiesFilter !== this.props.securitiesFilter) {
      this.setState({
        ...this.state,
        securities: nextProps.securities !== this.props.securities ? nextProps.securities : this.state.securities,
        securitiesFilter:
          nextProps.securitiesFilter !== this.props.securitiesFilter
            ? nextProps.securitiesFilter
            : this.state.securitiesFilter,
        List:
          nextProps.securities !== this.props.securities || nextProps.securitiesFilter !== this.props.securitiesFilter
            ? this.makeCoinList(
              nextProps.securities,
              nextProps.securitiesFilter,
              nextProps.SecNameArr,
              this.state.levelCount
            )
            : this.state.List,
        plwStatus: false,
        SecNameArr: nextProps.SecNameArr,
        SecSymbolArr: nextProps.SecSymbolArr,
        showError: nextProps.securitiesFilter.length === 0,
      });
    }
  }

  makeCoinList = (securities, securitiesFilter, SecNameArr, newTotal) => {
    let selectedCoinList = [];
    if (securitiesFilter.length > 0 && securities.length > 0) {
      securitiesFilter.forEach((sec) => {
        let currentItem = securities.filter((item) => item.Name === sec);
        if (currentItem.length !== 0) {
          selectedCoinList.push({
            Symbol: currentItem[0].Symbol,
            Name: sec,
            Scope: currentItem[0].Scope,
            selected: true,
          });
        }
      });
    }
    return selectedCoinList;
  };

  CoinSearch = (e) => {
    const { securities, securitiesFilter, SecNameArr, SecSymbolArr } = this.props;
    let MarketCapSortedList = [];
    if (e.target.value.length > 1) {
      let suggestionList = [];
      securities.forEach((coin, i) => {
        if (
          coin.Name.toLowerCase().includes(e.target.value.toLowerCase()) ||
          coin.Symbol.toLowerCase().includes(e.target.value.toLowerCase())
        ) {
          if (securitiesFilter.indexOf(coin.Name) > -1) {
            coin.selected = true;
          }
          coin.Scope = parseFloat(coin.Scope);
          suggestionList.push(coin);
        }
      });
      if (suggestionList.length) {
        MarketCapSortedList = suggestionList.sort((a, b) => {
          return b.Scope - a.Scope;
        });
      }
      this.setState({
        ...this.state,
        suggestionList: MarketCapSortedList,
      });
    } else {
      this.setState({
        ...this.state,
        suggestionList: [],
      });
    }
  };

  addValuesToWatchList = (count) => {
    // alert(count)
    const { userdata, SecNameArr } = this.props;
    var newSecNameArr = SecNameArr.slice(0, count);
    let newsecfilter = JSON.parse(JSON.stringify(this.props.securitiesFilter));
    if (count > 0) {
      if (newSecNameArr.length) {
        newSecNameArr.forEach(ele => {
          if (newsecfilter.indexOf(ele) === -1) {
            newsecfilter.push(ele)
          }
        })
      }
      let data = {
        UserName: userdata.username,
        FilterString: newsecfilter.join(';'),
      };
      this.props.updateSecuritiesFilter(data);
      this.onCancelOfAlert();
    } else {
      this.onCancelOfAlert();
    }
    // if (securitiesFilter.length < count) {
    //   var newSecNameArr = SecNameArr.slice(0, count);
    //   //add top as per count
    //   let data = {
    //     UserName: userdata.username,
    //     FilterString: newSecNameArr.join(';'),
    //   };
    //   this.props.updateSecuritiesFilter(data);
    //   this.onCancelOfAlert();
    // } else {
    //   this.onCancelOfAlert();
    // }
  };

  // addForexValuesToWatchList = (count) => {
  //   // console.log(count)
  //   if(count > 0) {
  //     const { userdata, ForexSecNameArr } = this.props;
  //     let newsecfilter = JSON.parse(JSON.stringify(this.props.securitiesFilter));
  //     if(ForexSecNameArr.length) {
  //       ForexSecNameArr.forEach(ele => {
  //         if(newsecfilter.indexOf(ele) === -1) {
  //           newsecfilter.push(ele)
  //         }
  //       })
  //     }
  //     let data = {
  //       UserName: userdata.username,
  //       FilterString: newsecfilter.join(';'),
  //     };
  //     this.props.updateSecuritiesFilter(data);
  //     this.onCancelOfAlert();
  //   } else {
  //     this.onCancelOfAlert();
  //   }

  // }
  addForexToWatchList = (count) => {
    // console.log(count)
    if (count > 0) {
      const { userdata, securities } = this.props;
      let newsecfilter = JSON.parse(JSON.stringify(this.props.securitiesFilter));
      let forexSecs = [];
      let SecNameArr = [];
      let SecSymbolArr = [];
      if (securities.length) {
        securities.forEach((sec, i) => {
          if (sec.SecurityTypeID === SecTypeIds.forex) {
            forexSecs.push(sec)
            SecNameArr.push(sec.Name)
            SecSymbolArr.push(sec.Symbol)
          }
        })
      }

      if (SecNameArr.length) {
        SecNameArr.forEach(ele => {
          if (newsecfilter.indexOf(ele) === -1) {
            newsecfilter.push(ele)
          }
        })
      }
      let data = {
        UserName: userdata.username,
        FilterString: newsecfilter.join(';'),
      };
      this.props.updateSecuritiesFilter(data);
      this.onCancelOfAlert();
    } else {
      this.onCancelOfAlert();
    }

  }
  addStocksToWatchList = (count) => {
    // console.log(count)
    if (count > 0) {
      const { userdata, securities } = this.props;
      let newsecfilter = JSON.parse(JSON.stringify(this.props.securitiesFilter));
      let stockSecs = [];
      let SecNameArr = [];
      let SecSymbolArr = [];
      if (securities.length) {
        securities.forEach((sec, i) => {
          if (sec.SecurityTypeID === SecTypeIds.stocks) {
            stockSecs.push(sec)
            SecNameArr.push(sec.Name)
            SecSymbolArr.push(sec.Symbol)
          }
        })
      }

      if (SecNameArr.length) {
        SecNameArr.forEach(ele => {
          if (newsecfilter.indexOf(ele) === -1) {
            newsecfilter.push(ele)
          }
        })
      }
      let data = {
        UserName: userdata.username,
        FilterString: newsecfilter.join(';'),
      };
      this.props.updateSecuritiesFilter(data);
      this.onCancelOfAlert();
    } else {
      this.onCancelOfAlert();
    }

  }
  addCommoditiesToWatchList = (count) => {
    // console.log(count)
    if (count > 0) {
      const { userdata, securities } = this.props;
      let newsecfilter = JSON.parse(JSON.stringify(this.props.securitiesFilter));
      let commoditySecs = [];
      let SecNameArr = [];
      let SecSymbolArr = [];
      if (securities.length) {
        securities.forEach((sec, i) => {
          if (sec.SecurityTypeID === SecTypeIds.commodities) {
            commoditySecs.push(sec)
            SecNameArr.push(sec.Name)
            SecSymbolArr.push(sec.Symbol)
          }
        })
      }

      if (SecNameArr.length) {
        SecNameArr.forEach(ele => {
          if (newsecfilter.indexOf(ele) === -1) {
            newsecfilter.push(ele)
          }
        })
      }
      let data = {
        UserName: userdata.username,
        FilterString: newsecfilter.join(';'),
      };
      this.props.updateSecuritiesFilter(data);
      this.onCancelOfAlert();
    } else {
      this.onCancelOfAlert();
    }

  }
  addIndicesToWatchList = (count) => {
    // console.log(count)
    if (count > 0) {
      const { userdata, securities } = this.props;
      let newsecfilter = JSON.parse(JSON.stringify(this.props.securitiesFilter));
      let indicesSecs = [];
      let SecNameArr = [];
      let SecSymbolArr = [];
      if (securities.length) {
        securities.forEach((sec, i) => {
          if (sec.SecurityTypeID === SecTypeIds.indices) {
            indicesSecs.push(sec)
            SecNameArr.push(sec.Name)
            SecSymbolArr.push(sec.Symbol)
          }
        })
      }

      if (SecNameArr.length) {
        SecNameArr.forEach(ele => {
          if (newsecfilter.indexOf(ele) === -1) {
            newsecfilter.push(ele)
          }
        })
      }
      let data = {
        UserName: userdata.username,
        FilterString: newsecfilter.join(';'),
      };
      this.props.updateSecuritiesFilter(data);
      this.onCancelOfAlert();
    } else {
      this.onCancelOfAlert();
    }

  }
  addCryptoToWatchList = (count) => {
    // console.log(count)
    if (count > 0) {
      const { userdata, securities } = this.props;
      let newsecfilter = JSON.parse(JSON.stringify(this.props.securitiesFilter));
      let cryptoSecs = [];
      let SecNameArr = [];
      let SecSymbolArr = [];
      if (securities.length) {
        securities.forEach((sec, i) => {
          if (sec.SecurityTypeID === SecTypeIds.crypto) {
            cryptoSecs.push(sec)
            SecNameArr.push(sec.Name)
            SecSymbolArr.push(sec.Symbol)
          }
        })
      }
      var newSecNameArr = SecNameArr.slice(0, count);
      if (newSecNameArr.length) {
        newSecNameArr.forEach(ele => {
          if (newsecfilter.indexOf(ele) === -1) {
            newsecfilter.push(ele)
          }
        })
      }
      let data = {
        UserName: userdata.username,
        FilterString: newsecfilter.join(';'),
      };
      this.props.updateSecuritiesFilter(data);
      this.onCancelOfAlert();
    } else {
      this.onCancelOfAlert();
    }

  }

  // hastopcrypto = (count) => {
  //   let hasTopCrypto = true;
  //   if(count > 0) {
  //     const { userdata, securitiesFilter } = this.props;
  //     // const { userdata, SecNameArr, securitiesFilter } = this.props;
  //     let topSecNameArr = SecNameArr.slice(0, count);
  //     if(topSecNameArr.length) {
  //       topSecNameArr.forEach((itm, i) => {
  //         if(securitiesFilter.indexOf(itm) === -1) {
  //           hasTopCrypto = false;
  //         }
  //       })
  //     }
  //   }
  //   return hasTopCrypto;
  // }
  hastopcrypto = (count) => {
    let hasTopCrypto = true;
    if (count > 0) {
      const { userdata, securities, securitiesFilter } = this.props;
      let cryptoSecs = [];
      let SecNameArr = [];
      let SecSymbolArr = [];
      if (securities.length) {
        securities.forEach((sec, i) => {
          if (sec.SecurityTypeID === SecTypeIds.crypto) {
            cryptoSecs.push(sec)
            SecNameArr.push(sec.Name)
            SecSymbolArr.push(sec.Symbol)
          }
        })
      }
      let topSecNameArr = SecNameArr.slice(0, count);
      if (topSecNameArr.length) {
        topSecNameArr.forEach((itm, i) => {
          if (securitiesFilter.indexOf(itm) === -1) {
            hasTopCrypto = false;
          }
        })
      }
    }
    return hasTopCrypto;
  }
  hastopforex = (count) => {
    let hasTopForex = true;
    if (count > 0) {
      const { userdata, securities, securitiesFilter } = this.props;
      let forexSecs = [];
      let SecNameArr = [];
      let SecSymbolArr = [];
      // console.log(securities, securitiesFilter, SecTypeIds)
      // return;
      if (securities.length) {
        securities.forEach((sec, i) => {
          if (sec.SecurityTypeID === SecTypeIds.forex) {
            forexSecs.push(sec)
            SecNameArr.push(sec.Name)
            SecSymbolArr.push(sec.Symbol)
          }
        })
      }
      let topSecNameArr = SecNameArr//.slice(0, count);
      if (topSecNameArr.length) {
        topSecNameArr.forEach((itm, i) => {
          if (securitiesFilter.indexOf(itm) === -1) {
            hasTopForex = false;
          }
        })
      }
    }
    return hasTopForex;
  }
  hastopstocks = (count) => {
    let hasTopStocks = true;
    if (count > 0) {
      const { userdata, securities, securitiesFilter } = this.props;
      let stockSecs = [];
      let SecNameArr = [];
      let SecSymbolArr = [];
      if (securities.length) {
        securities.forEach((sec, i) => {
          if (sec.SecurityTypeID === SecTypeIds.stocks) {
            stockSecs.push(sec)
            SecNameArr.push(sec.Name)
            SecSymbolArr.push(sec.Symbol)
          }
        })
      }
      let topSecNameArr = SecNameArr//.slice(0, count);
      if (topSecNameArr.length) {
        topSecNameArr.forEach((itm, i) => {
          if (securitiesFilter.indexOf(itm) === -1) {
            hasTopStocks = false;
          }
        })
      }
    }
    return hasTopStocks;
  }
  hastopcommodities = (count) => {
    let hasTopCommodities = true;
    if (count > 0) {
      const { userdata, securities, securitiesFilter } = this.props;
      let commoditySecs = [];
      let SecNameArr = [];
      let SecSymbolArr = [];
      if (securities.length) {
        securities.forEach((sec, i) => {
          if (sec.SecurityTypeID === SecTypeIds.commodities) {
            commoditySecs.push(sec)
            SecNameArr.push(sec.Name)
            SecSymbolArr.push(sec.Symbol)
          }
        })
      }
      let topSecNameArr = SecNameArr//.slice(0, count);
      if (topSecNameArr.length) {
        topSecNameArr.forEach((itm, i) => {
          if (securitiesFilter.indexOf(itm) === -1) {
            hasTopCommodities = false;
          }
        })
      }
    }
    return hasTopCommodities;
  }
  hastopindices = (count) => {
    let hasTopIndices = true;
    if (count > 0) {
      const { userdata, securities, securitiesFilter } = this.props;
      let IndicesSecs = [];
      let SecNameArr = [];
      let SecSymbolArr = [];
      if (securities.length) {
        securities.forEach((sec, i) => {
          if (sec.SecurityTypeID === SecTypeIds.indices) {
            IndicesSecs.push(sec)
            SecNameArr.push(sec.Name)
            SecSymbolArr.push(sec.Symbol)
          }
        })
      }
      let topSecNameArr = SecNameArr//.slice(0, count);
      if (topSecNameArr.length) {
        topSecNameArr.forEach((itm, i) => {
          if (securitiesFilter.indexOf(itm) === -1) {
            hasTopIndices = false;
          }
        })
      }
    }
    return hasTopIndices;
  }

  // hastopforex = (count) => {
  //   let hasTopForex = true;
  //   if(count > 0) {
  //     const { userdata, ForexSecNameArr, securitiesFilter } = this.props;
  //     let topSecNameArr = ForexSecNameArr.slice(0, count);
  //     if(topSecNameArr.length) {
  //       topSecNameArr.forEach((itm, i) => {
  //         if(securitiesFilter.indexOf(itm) === -1) {
  //           hasTopForex = false;
  //         }
  //       })
  //     }
  //   }
  //   return hasTopForex;
  // }

  addAll = () => {
    const { userdata, SecNameArr } = this.props;
    //add all
    console.log(SecNameArr)
    let data = {
      UserName: userdata.username,
      FilterString: SecNameArr.join(';'),
    };
    this.props.updateSecuritiesFilter(data);
    this.onCancelOfAlert();
  };

  removeAll = () => {
    const { userdata } = this.props;
    //remove all
    let data = {
      UserName: userdata.username,
      FilterString: '',
      showError: true,
    };
    this.props.updateSecuritiesFilter(data);
    this.onCancelOfAlert();
  };

  handleList = (Security, ActionType) => {
    console.log(this.props.userdata.userRole.toLowerCase())
    this.setState(
      {
        ...this.state,
        plwStatus: true,
        suggestionList: [],
      },
      () => {
        let { securitiesFilter, userdata, securities, userPackage } = this.props;
        // const top50Coins = securities.slice(0, 50);
        // let isSelectedCoinPresentInTop50 = top50Coins.indexOf(Security) > -1;

        let isSelectedCoinPresentInTop50 = true;
        if (userPackage !== 'bronze') {
          isSelectedCoinPresentInTop50 = true;
        }
        if (ActionType && isSelectedCoinPresentInTop50) {
          if (securitiesFilter.indexOf(Security.Name) === -1) {
            if (this.props.userdata.usertype === 'None' || this.props.userdata.usertype === 'WUser' || this.props.userdata.usertype === 'WAdmin') {
              if (userPackage !== 'bronze') {
                if (this.props.userdata.userRole === "Admin") {
                  securitiesFilter.push(Security.Name);
                } else if (securitiesFilter.length < 200) {
                  securitiesFilter.push(Security.Name);
                } else {
                  this.setState({
                    showSweetAlert: true,
                    plwStatus: false,
                    alertMessage: () => {
                      var message = 'Maximum assets of 200 reached. Please delete existing assets to add new assets';
                      return message;
                    },
                    showOnlyOk: true,
                    onConfirm: () => {
                      this.onCancelOfAlert();
                    },
                  });
                }
              } else {
                if (securitiesFilter.length < 200) {
                  securitiesFilter.push(Security.Name);
                } else {
                  this.setState({
                    showSweetAlert: true,
                    plwStatus: false,
                    // showconfirmpopup: true,
                    alertMessage: () => {
                      var message = 'Maximum assets of 200 reached, Please delete existing assets to add new assets';
                      return message;
                    },
                    onConfirm: () => {
                      this.onCancelOfAlert();
                    },
                  });
                }
              }
            } else {
              securitiesFilter.push(Security.Name);
            }

          }
        } else if (!ActionType) {
          if (securitiesFilter.indexOf(Security.Name) > -1) {
            securitiesFilter.splice(securitiesFilter.indexOf(Security.Name), 1);
          }
        } else {
          this.setState({
            showSweetAlert: true,
            plwStatus: false,
            showconfirmpopup: true,
            alertMessage: () => {
              var message = this.props.userdata.usertype === 'None' || this.props.userdata.usertype === 'WUser' || this.props.userdata.usertype === 'WAdmin' ? 'Please upgrade to premium' : '';
              return message;
            },
            onConfirm: () => {
              this.onCancelOfAlert();
            },
          });
          return;
        }
        let data = {
          UserName: userdata.username,
          FilterString: this.orderTheSecurityFilterAsPerMentions(securitiesFilter).join(';'),
        };
        this.props.updateSecuritiesFilter(data);
      }
    );
  };

  orderTheSecurityFilterAsPerMentions = (stringArray) => {
    // console.log(this.props.securities, stringArray)
    var newRequiredArray = [];
    stringArray.forEach((item) => {
      // console.log(item)
      // console.log(this.props.securities.find((ticker) => ticker.Name === item))
      if (item === 'Binance Coin') { item = 'BNB'; }
      let RawTickerData = this.props.securities.find((ticker) => ticker.Name === item);
      if (typeof RawTickerData !== 'undefined') {
        newRequiredArray.push({
          tickerName: item,
          Scope: RawTickerData.Scope,
        });
      }

    });

    newRequiredArray = newRequiredArray.sort((a, b) => {
      return parseFloat(b.Scope) - parseFloat(a.Scope);
    });

    var newArray = [];
    newRequiredArray.forEach((item) => {
      newArray.push(item.tickerName);
    });

    return newArray;
  };

  resetSearchFilterList = () => {
    this.coinSearchInput.current.value = '';
    this.setState({
      ...this.state,
      suggestionList: [],
    });
  };
  onCancelOfAlert = () => {
    this.setState({
      showSweetAlert: false,
    });
  };
  updateSelectedLevel = (count, type) => {
    const { securitiesFilter, userPackage } = this.props;
    let confirmPopup = false;
    let showonlyok = false;
    if (count !== 0) {
      if (type === 'crypto') {
        showonlyok = this.hastopcrypto(count);
      } else if (type === 'forex') {
        showonlyok = this.hastopforex(count);
      } else if (type === 'stocks') {
        showonlyok = this.hastopstocks(count);
      } else if (type === 'commodities') {
        showonlyok = this.hastopcommodities(count);
      } else if (type === 'indices') {
        showonlyok = this.hastopindices(count);
      }
    }
    console.log(showonlyok)
    this.setState({
      ...this.state,
      levelCount: count,
      showSweetAlert: true,
      showconfirmpopup: confirmPopup,
      showOnlyOk: showonlyok,
      alertMessage: () => {
        var message = '';
        if (type === 'forex') {
          if (this.hastopforex(count)) {
            message = `You already have all the top forex pairs in your watchlist`
          } else {
            message = `Are you sure you want to add the top forex pairs to your watchlist?`
          }
        } else if (type === 'stocks') {
          if (this.hastopstocks(count)) {
            message = `You already have all the top stocks in your watchlist`
          } else {
            message = `Are you sure you want to add the top stocks to your watchlist?`
          }
        } else if (type === 'commodities') {
          if (this.hastopcommodities(count)) {
            message = `You already have all the top commodities in your watchlist`
          } else {
            message = `Are you sure you want to add the top commodities to your watchlist?`
          }
        } else if (type === 'indices') {
          if (this.hastopindices(count)) {
            message = `You already have all the top indices in your watchlist`
          } else {
            message = `Are you sure you want to add the top indices to your watchlist?`
          }
        } else if (type === 'crypto') {
          if (this.hastopcrypto(count)) {
            message = `You already have all the top cryptos in your watchlist`
          } else {
            message = `Are you sure you want to add the top cryptos to your watchlist?`
          }
        } else if (count === 0) {
          message = 'Are you sure you want to remove all assets from your  watchlist?';
        } else if (count > 200 && this.props.userRole === 'admin') {
          message = 'Are you sure you want to add all assets in your watchlist?';
        }

        return message;
      },
      onConfirm: () => {
        if (type === 'forex') {
          this.addForexToWatchList(count);
        } else if (type === 'stocks') {
          this.addStocksToWatchList(count);
        } else if (type === 'commodities') {
          this.addCommoditiesToWatchList(count);
        } else if (type === 'indices') {
          this.addIndicesToWatchList(count);
        } else if (type === 'crypto') {
          this.addCryptoToWatchList(count);
        } else if (count === 0) {
          this.removeAll();
        } else if (count > 200 && this.props.userRole === 'admin') {
          this.addAll();
        }
      }
    })
  }
  updateSelectedLevelold = (count, type) => {
    const { securitiesFilter, userPackage } = this.props;
    let confirmPopup = false;
    const checkBronzeCondition = userPackage.toLowerCase() !== 'bronze' || ((count < 20 || count == 20) && userPackage.toLowerCase() === 'bronze') || (count > 20 && !TrendingMentionsHelper.FreeSubscriptionFilter());
    console.log('Trending Filter:', TrendingMentionsHelper.FreeSubscriptionFilter())
    console.log('bronze condition:', checkBronzeCondition)
    if (!TrendingMentionsHelper.FreeSubscriptionFilter() && !checkBronzeCondition) {
      confirmPopup = true;
    }
    else if (count !== 0 && count > 20) {
      if (TrendingMentionsHelper.FreeSubscriptionFilter()) {
        confirmPopup = true;
      }

    }
    let showonlyok = false;
    console.log(TrendingMentionsHelper.FreeSubscriptionFilter(), count, showonlyok)
    if (count !== 0) {
      if (TrendingMentionsHelper.FreeSubscriptionFilter() && count > 20) {
        showonlyok = true;
      } else {
        if (type !== 'crypto') {
          showonlyok = this.hastopforex(count);
        } else {
          console.log('hastopcrypto')
          showonlyok = this.hastopcrypto(count);
        }
      }
    }
    console.log(showonlyok)
    // (TrendingMentionsHelper.FreeSubscriptionFilter() && count > 20) || (count !== 0 && (securitiesFilter.length > count || securitiesFilter.length === count) && type !== 'forex')
    // console.log(count, type)
    // console.log(TrendingMentionsHelper.FreeSubscriptionFilter())
    // console.log(securitiesFilter.length)
    this.setState({
      levelCount: count,
      showSweetAlert: true,
      showconfirmpopup: confirmPopup,
      showOnlyOk: showonlyok,
      alertMessage: () => {
        var message = '';
        if (this.props.userdata.usertype === 'None' || this.props.userdata.usertype === 'WUser' || this.props.userdata.usertype === 'WAdmin') {
          if (!TrendingMentionsHelper.FreeSubscriptionFilter()) {
            if (checkBronzeCondition) {
              if (type === 'forex') {
                // alert(this.hastopforex(count))
                if (this.hastopforex(count)) {
                  message = `You already have all the top ${count} forex pairs in your watchlist`
                } else {
                  message = `Are you sure you want to add the top ${count} forex pairs to your watchlist?`
                }

              } else if (count === 0) {
                message = 'Are you sure you want to remove all assets from your  watchlist?';
              } else if (count > 200 && this.props.userRole !== 'admin') {
                message = 'You cannot add more than 200 assets in watchlist';
              } else if (count > 200 && this.props.userRole === 'admin') {
                message = 'Are you sure you want to add all assets in your watchlist?';
              } else {
                if (this.hastopcrypto(count)) {
                  message = `You already have all the top ${count} assets in your watchlist`
                } else {
                  message = `Are you sure you want to add the top ${count} assets to your watchlist?`;
                }
                // message =
                //   securitiesFilter.length > count || securitiesFilter.length === count
                //     ? `You already have all the top ${count} assets in your watchlist`
                //     : `Are you sure you want to add the top ${count} assets to your watchlist?`;
              }
            } else {
              message = 'Please upgrade your package to Premium';
            }
          } else {
            if (type === 'forex') {
              message = `Are you sure you want to add the top ${count} forex pairs to your watchlist?`
            } else if (count === 0) {
              message = 'Are you sure you want to remove all assets from your watchlist?'
            } else if (count > 20) {
              message = 'Please upgrade your subscription to premium for accessing all assets';
            } else {
              message = securitiesFilter.length > count || securitiesFilter.length === count ? `You already have all the top ${count} assets in your watchlist` : `Are you sure you want to add the top ${count} assets to your watchlist?`
            }

          }
        } else {
          if (type === 'forex') {
            message = `Are you sure you want to add the top ${count} forex pairs to your watchlist?`
          } else if (count === 0) {
            message = 'Are you sure you want to remove all companies from your watchlist?';
          } else {
            message =
              securitiesFilter.length > count || securitiesFilter.length === count
                ? `You already have all the top ${count} companies in your watchlist`
                : `Are you sure you want to add the top ${count} to your watchlist?`;
          }
        }

        return message;
      },
      onConfirm: () => {
        console.log(type, count)
        if (this.props.userdata.usertype === 'None' || this.props.userdata.usertype === 'WUser' || this.props.userdata.usertype === 'WAdmin') {
          if (!TrendingMentionsHelper.FreeSubscriptionFilter()) {
            if (checkBronzeCondition) {

              if (type === 'forex') {
                this.addForexValuesToWatchList(count);
              } else if ((count > 0 && count < 200) || count === 200) {
                // alert('here')
                this.addValuesToWatchList(count);
              } else if (count === 0) {
                this.removeAll();
              } else if (count > 200 && this.props.userRole === 'admin') {
                console.log(userPackage)
                if (userPackage !== 'bronze') {
                  console.log('here')
                  this.addAll();
                } else {
                  this.addValuesToWatchList(20);
                }
              } else {
                this.onCancelOfAlert();
              }
            } else {
              this.onCancelOfAlert();
            }
          } else {
            // console.log(count)
            if (type === 'forex') {
              this.addForexValuesToWatchList(count);
            } else if (count === 0) {
              this.removeAll();
            } else if (count <= 20 && count > 0) {
              this.addValuesToWatchList(count);
            } else {
              this.onCancelOfAlert();
            }

          }
        } else {
          if (type === 'forex') {
            this.addForexValuesToWatchList(count);
          } else if (count > 0) { this.addValuesToWatchList(count); }
          else if (count === 0) {
            this.removeAll();
          }
        }

      },
    });

  };

  // onConfirm = (count) => {
  //   const { securitiesFilter, userPackage } = this.props;
  //   const checkBronzeCondition = userPackage.toLowerCase() !== 'bronze' || ((count < 50 || count == 50) && userPackage.toLowerCase() === 'bronze') || (count > 50 && !TrendingMentionsHelper.FreeSubscriptionFilter());

  //   if(!TrendingMentionsHelper.FreeSubscriptionFilter()) {
  //     if (checkBronzeCondition) {
  //       if ((count > 0 && count < 200) || count === 200){ this.addValuesToWatchList(count);}
  //       else if (count === 0) {
  //         this.removeAll();
  //       } else if (count > 200 && this.props.userRole === 'admin') {
  //         if (userPackage !== 'bronze') {
  //           this.addAll();
  //         } else {
  //           this.addValuesToWatchList(50);
  //         }
  //       } else {
  //         this.onCancelOfAlert();
  //       }
  //     } else {
  //       this.onCancelOfAlert();
  //     }
  //   } else {
  //     // console.log(count)
  //     if (count === 0) {
  //       this.removeAll();
  //     } else if(count <= 50 && count > 0) {
  //       this.addValuesToWatchList(count);
  //     } else {
  //       this.onCancelOfAlert();
  //     }

  //   }
  // }

  render() {
    const { SecuritiesChecklist, EventTypesCheckList, welcomemode } = this.props;
    const { suggestionList, List } = this.state;
    // let maxcoins = this.props.userPackage !== 'bronze' || !TrendingMentionsHelper.FreeSubscriptionFilter() ? 200 : 20
    let maxcoins = 200;

    let suggestionListView =
      suggestionList.length !== 0
        ? suggestionList.map((sugLst, i) => (
          <div
            className={`suggestion-items row marg-0 h-100 hoversuggestion `}
            key={`${sugLst.Symbol}${i}`}
            onClick={() => {
              this.handleList(sugLst, !sugLst.selected);
            }}
          >
            {/* onClick={() => {this.handleSearchText(item.name, item.type, AddRemoveType)}} ref={`suggitem${count}`} */}
            <div className="suggestiontype col-1 align-self-center" style={{ display: 'inline-block' }}>
              <img
                src={`${APPPATH.CoinIconUrl}/${sugLst.Name.toLowerCase().replace(/ /g, '_')}.png`}
                className="tmRowTickerIcon imground"
                alt=""
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = `${APPPATH.ImageUrl}/companygenerallogo.png`
                }}
              />
            </div>
            <div className={`suggestionname ${this.props.lang && this.props.lang === 'ara' ? 'arabic' : ''} col-9 align-self-center`} style={{ display: 'inline-block' }}>
              <span>

                {
                  this.props.lang && this.props.lang === 'spa' && SpanishTranslations[sugLst.Name] ? SpanishTranslations[sugLst.Name]
                    : this.props.lang && this.props.lang === 'por' && PortugueseTranslations[sugLst.Name] ? PortugueseTranslations[sugLst.Name]
                      : this.props.lang && this.props.lang === 'jap' && JapaneseTranslations[sugLst.Name] ? JapaneseTranslations[sugLst.Name]
                        : this.props.lang && this.props.lang === 'ara' && ArabicTranslations[sugLst.Name] ? ArabicTranslations[sugLst.Name]
                          : sugLst.Name
                }
              </span>
              <span className="fs12 text-offWhitealertfeed" style={{ paddingTop: 10 }}>
                {sugLst.Symbol}
              </span>
            </div>
            <div className={`suggestionaction col-2 align-self-center padt5`} style={{ display: 'inline-block' }}>
              <button type="button" className={`btn btn-suggestionAdd watchlist fs12`}>
                {/* onClick={() => {this.handleSearchText(item.name, item.type, AddRemoveType)}} */}
                {!sugLst.selected ? (
                  <span>
                    <i className={`fa fa-plus-circle ${this.props.minify ? 'fs17' : ''}`}></i>&nbsp;{this.props.minify ? '' :
                      this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['Add'] :
                        this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['Add'] :
                          this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['Add'] :
                            this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['Add'] : 'Add'}{' '}
                  </span>
                ) : (
                  <span>
                    <i className={`fa fa-minus-circle ${this.props.minify ? 'fs17' : ''}`}></i>&nbsp;{this.props.minify ? '' :
                      this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['Remove'] :
                        this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['Remove'] :
                          this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['Remove'] :
                            this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['Remove'] : 'Remove'}{' '}
                  </span>
                )}
              </button>
            </div>
          </div>
        ))
        : [];

    let ListView =
      List.length !== 0
        ? List.map((lst, i) => (
          <Col
            lg={12}
            sm={12}
            xs={12}
            md={12}
            xl={12}
            className={`nopad text-center wtchList-item-bg ${this.props.Theme === 'light' ? 'lightmode' : ''} br5 margb5 hoveract`}
            key={`${lst.Symbol}${i}`}
            onClick={() => this.handleList(lst, !lst.selected)}
          >
            <Row className="marg-0 justify-content-center text-center">
              <Col lg={1} sm={1} xs={1} md={1} xl={1} className="nopad text-center align-self-center">
                <span className="pad10">
                  <img
                    src={`${APPPATH.CoinIconUrl}/${lst.Name.toLowerCase().replace(/ /g, '_')}.png`}
                    className="tmRowTickerIcon imground"
                    alt=""
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = `${APPPATH.ImageUrl}/companygenerallogo.png`
                    }}
                  />
                </span>
              </Col>
              <Col lg={11} sm={11} xs={11} md={11} xl={11} className="nopad align-self-center">
                <Row className="marg-0 justify-content-center text-center">
                  <Col lg={10} sm={10} xs={7} md={10} xl={10} className="nopad align-self-center fs15 fs-sm-Ticker">
                    <div className={`${this.props.lang && this.props.lang === 'ara' ? 'text-right' : 'text-left'} pad10 text-offWhitealertfeed`}>

                      {
                        this.props.lang && this.props.lang === 'spa' && SpanishTranslations[lst.Name] ? SpanishTranslations[lst.Name]
                          : this.props.lang && this.props.lang === 'por' && PortugueseTranslations[lst.Name] ? PortugueseTranslations[lst.Name]
                            : this.props.lang && this.props.lang === 'jap' && JapaneseTranslations[lst.Name] ? JapaneseTranslations[lst.Name]
                              : this.props.lang && this.props.lang === 'ara' && ArabicTranslations[lst.Name] ? ArabicTranslations[lst.Name]
                                : lst.Name
                      }
                      <span className="fs10 text-offWhitealertfeed" style={{ marginLeft: 3, marginRight: 3 }}>
                        {lst.Symbol}
                      </span>
                    </div>
                  </Col>
                  <Col
                    lg={2}
                    sm={2}
                    xs={5}
                    md={2}
                    xl={2}
                    className="nopad align-self-center fs15"
                    style={{ color: 'rgba(117, 117, 117, 0.5)' }}
                  >
                    <Row className="marg-0 fs15">
                      <Col
                        // lg={10}
                        // sm={10}
                        // xs={8}
                        // md={10}
                        // xl={10}
                        className="hoveract-item nopad align-self-center pointer"
                        onClick={() => this.handleList(lst, !lst.selected)}
                      >
                        {lst.selected && (
                          <>
                            <i className={`fa fa-minus-circle `}></i>&nbsp;{this.props.minify ?
                              this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['Remove'] :
                                this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['Remove'] :
                                  this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['Remove'] :
                                    this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['Remove'] : 'Remove'
                              :
                              this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['Remove'] :
                                this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['Remove'] :
                                  this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['Remove'] :
                                    this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['Remove'] : 'Remove'}
                          </>
                        )}
                        {!lst.selected && (
                          <>
                            <i className="fa fa-plus-circle "></i>&nbsp;{this.props.minify ? '' :
                              this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['Add'] :
                                this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['Add'] :
                                  this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['Add'] :
                                    this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['Add'] : 'Add'}
                          </>
                        )}
                      </Col>
                      {/* <Col lg={2} sm={2} xs={4} md={2} xl={2} className="nopad align-self-center">
                           <i className="fa fa-chevron-right"></i> 
                        </Col>*/}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        ))
        : [];
    const { securities, securitiesFilter } = this.state;
    const condition = SecuritiesChecklist && EventTypesCheckList;
    const conditionCheck = !(SecuritiesChecklist && EventTypesCheckList);
    return (
      <div className={`watchlist-content ${this.props.Theme === 'light' ? 'lightmode' : ''} justify-content-center text-center`}>
        {(!condition || welcomemode) && (
          <div
            className="btn tradeWindowClose text-white nopad marg-0"
            onClick={() => {
              this.props.welcomemodeon(false)
              this.props.modalClose(conditionCheck, 3)
            }}
          >
            <span className="pad10">
              <img className="tradeWindowClose-closeicon" src={`${APPPATH.ImageUrl}/button-close.svg`} alt="" />
            </span>
          </div>
        )}
        {condition && (
          <div
            className="btn tradeWindowClose text-white nopad marg-0"
            onClick={() => {
              if (securitiesFilter.length > 0) {
                this.props.welcomemodeon(false)
                this.props.modalClose();
              } else {
                this.setState({
                  showError: true,
                });
              }
            }}
          >
            <span className="pad10">
              <img className="tradeWindowClose-closeicon" src={`${APPPATH.ImageUrl}/button-close.svg`} alt="" />
            </span>
          </div>
        )}

        <Row className="marg-0 storymenuoptions-tabheader">
          <Col className="text-center pad5 ">
            <div className={`nav nav-tabs watchlist nav-fill ${this.props.Theme === 'light' ? 'lightmode' : ''} `}>
              <div className={`nav-item fs16 active`}>
                {/* <span>My Watchlist {securitiesFilter.length > 0 && `(${securitiesFilter.length} Coins)`}</span> */}
                <span>

                  {
                    this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['Update My Watchlist']
                      : this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['Update My Watchlist']
                        : this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['Update My Watchlist']
                          : this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['Update My Watchlist']
                            : 'Update My Watchlist'
                  }
                </span>
              </div>
              <div className={`fs13 text-offWhitealertfeed`}>
                {/* <span>My Watchlist {securitiesFilter.length > 0 && `(${securitiesFilter.length} Coins)`}</span> */}
                {
                  this.props.userdata.usertype === 'None' || this.props.userdata.usertype === 'WUser' || this.props.userdata.usertype === 'WAdmin' ? (
                    <span>

                      {
                        this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['Add new or remove existing assets in your watchlist']
                          : this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['Add new or remove existing assets in your watchlist']
                            : this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['Add new or remove existing assets in your watchlist']
                              : this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['Add new or remove existing assets in your watchlist']
                                : 'Add new or remove existing assets in your watchlist'
                      }
                    </span>
                  ) : (
                    <span>Add new or remove existing companies in your watchlist</span>
                  )
                }

              </div>
            </div>
          </Col>
        </Row>
        <Row className="marg-0 justify-content-center  pad5 text-center">
          <Col lg={12} sm={12} xs={12} md={12} xl={12} className={`text-center pad5 wtchList-item-bg ${this.props.Theme === 'light' ? 'lightmode' : ''} br5`}>
            <Row className="marg-0 justify-content-center  pad5text-center">
              <Col lg={1} sm={1} xs={1} md={1} xl={1} className="nopad text-center align-self-center">
                <span className="">
                  <i className="fa fa-plus-circle fs20 margt5p" style={{ color: 'rgba(117, 117, 117, 0.5)' }}></i>
                </span>
              </Col>
              <Col lg={11} sm={11} xs={11} md={11} xl={11} className="nopad text-center align-self-center">
                <div className=" input-group nopad my-input-group">
                  <input
                    type="text"
                    className={`form-control my-form-control  coinwtchlst-search ${this.props.Theme === 'light' ? 'lightmode' : ''} margt2p `}
                    placeholder={`${(this.props.userdata.usertype === 'None' || this.props.userdata.usertype === 'WUser' || this.props.userdata.usertype === 'WAdmin') ?
                      this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['Type to add more assets'] :
                        this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['Type to add more assets'] :
                          this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['Type to add more assets'] :
                            this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['Type to add more assets'] : 'Type to add more assets' : 'Type to add more companies'}`}
                    ref={this.coinSearchInput}
                    onChange={this.CoinSearch}
                    autoComplete="off"
                  />
                  <span className="pointer" onClick={this.resetSearchFilterList}>
                    <img src={`${APPPATH.ImageUrl}/button-close.svg`} className=" float-right search-init" alt="" />
                  </span>
                </div>
              </Col>
              <div>
                <div className={`cScroll searchTip watchlist ${this.props.Theme === 'light' ? 'lightmode' : ''} ${suggestionList.length ? '' : 'nodisp'}`}>
                  <div className="suggestion-wrap">{suggestionListView}</div>
                </div>
              </div>
            </Row>
          </Col>
        </Row>
        <WatchListLevel
          userRole={this.props.userRole}
          userPackage={this.props.userPackage}
          updateSelectedLevel={this.updateSelectedLevel}
          Theme={this.props.Theme}
          lang={this.props.lang}
        />
        <Row className="marg-0 justify-content-left" style={{ marginTop: 20, marginLeft: '5px', cursor: 'pointer' }}>
          <Col className={`${this.props.lang && this.props.lang === 'ara' ? 'text-right' : 'text-left'} text-offWhitealertfeed`} style={{ padding: '0px 5px', fontSize: '14px', marginBottom: 5 }}>
            {
              this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['My Watchlist']
                : this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['My Watchlist']
                  : this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['My Watchlist']
                    : this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['My Watchlist']
                      : 'My Watchlist'
            }{` : `} {`(${securitiesFilter.length > 0 ? securitiesFilter.length : '0'} / ${maxcoins} ${(this.props.userdata.usertype === 'None' || this.props.userdata.usertype === 'WUser' || this.props.userdata.usertype === 'WAdmin') ?
              this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['Assets'] :
                this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['Assets'] :
                  this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['Assets'] :
                    this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['Assets'] : 'Assets' : 'Companies'})`}
          </Col>
        </Row>
        <Row
          className="cScroll scroll-notrack marg-0 justify-content-center  pad5 text-center"
          style={{ maxHeight: 300 }}
        >
          {securities.length > 0 ? (
            ListView
          ) : (
            <Col lg={12} sm={12} xs={12} md={12} xl={12} className="nopad text-center ">
              <div>
                <i className="fa fa-spinner fa-spin"></i>
              </div>
            </Col>
          )}
        </Row>
        {this.state.showError && (
          <Row className="justify-content-center" style={{ color: 'red', marginTop: 10, fontSize: 15 }}>
            <Col>
              {
                (this.props.userdata.usertype === 'None' || this.props.userdata.usertype === 'WUser' || this.props.userdata.usertype === 'WAdmin') ? (
                  <span>Your watch list is empty. You must choose at least one coin to proceed</span>
                ) : (
                  <span>Your watch list is empty. You must choose at least one company to proceed</span>
                )
              }

            </Col>
          </Row>
        )}
        {
          !condition || welcomemode ? (
            <Row className={`${securities.length ? '' : 'nodisp'} marg-0 justify-content-center  pad5 text-center`}>
              <Col lg={3} sm={3} xs={3} md={3} xl={3} className={`text-left watchlistNavigatorButtons ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>
                <span
                  className={`${(!SecuritiesChecklist && !EventTypesCheckList) || welcomemode ? '' : 'nodisp'} pointer`}
                  onClick={() => {
                    window.Mixpanel.MixpanelProps.UserName = this.props.userdata.username;
                    window.Mixpanel.actions.identify(this.props.userdata.username);
                    window.Mixpanel.actions
                      .track('Clicking back to Welcome screen modal', window.Mixpanel.MixpanelProps)
                      .then((data) => {
                        window.Mixpanel.actions.people.set({
                          UserName: this.props.userdata.username,
                        });
                      });
                    this.props.changeView('WelcomeScreen');
                  }}
                >
                  Back
                </span>
              </Col>
              <Col lg={6} sm={6} xs={6} md={6} xl={6} className="text-center pad5 ">
                <div className={`${!condition || welcomemode ? '' : 'nodisp'}`}>
                  <span className={`wizard-step-dot ${this.props.Theme === 'light' ? 'lightmode' : ''}`}></span>
                  <span className={`wizard-step-dot ${this.props.Theme === 'light' ? 'lightmode' : ''} active`}></span>
                  <span className={`wizard-step-dot ${this.props.Theme === 'light' ? 'lightmode' : ''}`}></span>
                  <span className={`wizard-step-dot ${this.props.Theme === 'light' ? 'lightmode' : ''}`}></span>
                  {
                    (this.props.userdata.usertype === 'None' || this.props.userdata.usertype === 'WUser' || this.props.userdata.usertype === 'WAdmin') ? (
                      <span className={`wizard-step-dot ${this.props.Theme === 'light' ? 'lightmode' : ''}`}></span>
                    ) : ('')
                  }
                </div>
              </Col>
              <Col lg={3} sm={3} xs={3} md={3} xl={3} className={`text-right watchlistNavigatorButtons ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>
                <span
                  className="pointer"
                  onClick={() => {
                    if (securitiesFilter.length > 0) {
                      // if (condition) {
                      if (!condition || welcomemode) {

                        this.props.changeView('EventTypeList');
                        window.Mixpanel.MixpanelProps.UserName = this.props.userdata.username;
                        window.Mixpanel.actions.identify(this.props.userdata.username);
                        window.Mixpanel.actions
                          .track('Continued to Event type list', window.Mixpanel.MixpanelProps)
                          .then((data) => {
                            window.Mixpanel.actions.people.set({
                              UserName: this.props.userdata.username,
                            });
                          });
                      } else {
                        this.props.hardRefreshSystem();
                      }
                    } else {
                      this.setState({
                        showError: true,
                      });
                    }
                  }}
                >
                  {/* {condition ? 'Confirm' : 'Next'} */}
                  {!condition || welcomemode ? 'Next' : 'Confirm'}
                </span>
              </Col>
            </Row>
          ) : (
            <Row className={`${securities.length ? '' : 'nodisp'} marg-0 justify-content-center  pad5 text-center`}>
              <Col lg={12} sm={12} xs={12} md={12} xl={12} className={`${this.props.lang && this.props.lang === 'ara' ? 'text-left' : 'text-right '} watchlistNavigatorButtons ${this.props.Theme === 'light' ? 'lightmode' : ''}`}>
                <span
                  className="pointer"
                  onClick={() => {
                    if (securitiesFilter.length > 0) {
                      // if (condition) {
                      if (!condition || welcomemode) {

                        this.props.changeView('EventTypeList');
                        window.Mixpanel.MixpanelProps.UserName = this.props.userdata.username;
                        window.Mixpanel.actions.identify(this.props.userdata.username);
                        window.Mixpanel.actions
                          .track('Continued to Event type list', window.Mixpanel.MixpanelProps)
                          .then((data) => {
                            window.Mixpanel.actions.people.set({
                              UserName: this.props.userdata.username,
                            });
                          });
                      } else {
                        this.props.hardRefreshSystem();
                      }
                    } else {
                      this.setState({
                        showError: true,
                      });
                    }
                  }}
                >
                  {/* {condition ? 'Confirm' : 'Next'} */}
                  {/* {'Confirm Updates'} */}
                  {
                    this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['Confirm']
                      : this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['Confirm']
                        : this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['Confirm']
                          : this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['Confirm']
                            : 'Confirm'
                  }
                </span>
              </Col>
            </Row>
          )
        }


        {
          (this.state.showSweetAlert && this.state.showconfirmpopup) && (
            <ConfirmationPopUp
              showOnlyOk={this.state.showOnlyOk}
              alertMessage={this.state.alertMessage}
              onConfirm={() => this.state.onConfirm()}
              onCancel={() => this.onCancelOfAlert()}
              userPackage={this.props.userPackage}
              subscirptionPopup={true}
            />
          )}
        {
          (this.state.showSweetAlert && !this.state.showconfirmpopup) && (
            <ConfirmationPopUp
              showOnlyOk={this.state.showOnlyOk}
              alertMessage={this.state.alertMessage}
              onConfirm={() => this.state.onConfirm()}
              onCancel={() => this.onCancelOfAlert()}
              userPackage={this.props.userPackage}
            />
          )}

        <div
          className={`${this.state.plwStatus ? '' : 'nodisp'} margin-auto`}
          style={{ position: 'absolute', top: '50%', left: '50%' }}
        >
          <i className="fa fa-spinner fa-2x fa-spin"></i>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let SecNameArr = [];
  let SecSymbolArr = [];
  // let ForexSecNameArr = [];
  // let ForexSecSymbolArr = [];
  let Securities = state.filters.securities;
  // Securities =
  //   Securities.length > 0 ? (
  // Securities.sort((a, b) => {
  //   return parseFloat(b.Scope) - parseFloat(a.Scope);
  // })) : ([])
  let SecuritiesFilter =
    state.filters.securitiesFilter.length > 0 && state.filters.securitiesFilter[0] !== ''
      ? state.filters.securitiesFilter
      : [];
  // SecuritiesFilter =
  //   SecuritiesFilter.length > 0
  //     ? SecuritiesFilter.sort((a, b) => {
  //         return parseFloat(b.Scope) - parseFloat(a.Scope);
  //       })
  //     : [];
  if (Securities.length) {
    Securities.forEach((sec) => {
      SecNameArr.push(sec.Name);
      SecSymbolArr.push(sec.Symbol);
    });
  }
  // if(extrasecurities.length) {
  //   extrasecurities.forEach((sec) => {
  //     ForexSecNameArr.push(sec.Name);
  //     ForexSecSymbolArr.push(sec.Symbol);
  //   });
  // }
  return {
    userPackage: state.settings.userData.userPackage.toLowerCase(),
    securities: Securities,
    securitiesFilter: SecuritiesFilter,
    welcomemode: state.settings.welcomemode,
    SecuritiesChecklist: state.settings.checkListStatus.SecuritiesCheckList,
    EventTypesCheckList: state.settings.checkListStatus.EventTypesCheckList,
    SecNameArr,
    SecSymbolArr,
    // ForexSecNameArr,
    // ForexSecSymbolArr,
    userRole: state.settings.userData.userRole.toLowerCase(),
    Theme: state.app_preferences.preferences.user_interface.Theme,
    render: state.settings.render,
    lang: state.settings.lang,
  };
};

const mapDispatchToProps = {
  updateSecuritiesFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(WatchListIndex);
