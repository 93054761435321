import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { APPPATH } from '../../modules/helper.module';
import { connect } from 'react-redux';
import { closeEventImportanceFilterMenu } from '../../actions/settings.action';

import { SpanishTranslations, PortugueseTranslations, JapaneseTranslations, ArabicTranslations } from '../../static_data/translations';


class EventImportanceMenuDashboard extends Component {
    constructor(props) {
        super()
        // console.log(props.show_medium, props.show_high, props.show_higher)
        this.state = {
            show_medium: props.show_medium,
            show_high: props.show_high,
            show_higher: props.show_higher,
            iframecolorfiltermenustate: false,
            iframecolorfiltermenu: '',
            collapseHover: false

        }
        this.show = false;


    }
    componentDidMount() {
        let self = this
        if (typeof this.props.colIndex !== 'undefined') {
            let id = 'openmenuhandle_' + this.props.securityName
            document.getElementById(id).addEventListener('click', function (e) {
                e.stopPropagation();
                self.toggleIFrameColorFilterMenuState()
            })
        } else if (typeof this.props.searchsaveid !== 'undefined') {
            let id = 'openmenuhandle_' + this.props.searchsaveid
            document.getElementById(id).addEventListener('click', function (e) {
                e.stopPropagation();
                self.toggleIFrameColorFilterMenuState()
            })
        } else {
            document.getElementById('openmenuhandle_smartinbox').addEventListener('click', function (e) {
                // console.log('here')
                e.stopPropagation();
                self.toggleIFrameColorFilterMenuState()
            })
        }

    }

    componentWillReceiveProps(nextProps) {

        this.setState({
            ...this.state,
            show_medium: nextProps.show_medium,
            show_high: nextProps.show_high,
            show_higher: nextProps.show_higher,
        })
    }
    componentDidUpdate(prevProps, prevState) {



        if (this.props.closeeventimportancefilterMenu === true) {
            if (this.state.iframecolorfiltermenustate === true) {

                this.toggleIFrameColorFilterMenuState()
            }

        }

    }
    toggleIFrameColorFilterMenuState = (forceshow = false) => {
        this.show = forceshow === true ? true : !this.state.iframecolorfiltermenustate
        this.setState({
            ...this.state,
            iframecolorfiltermenustate: this.show,
        }, () => {
            if (this.props.closeeventimportancefilterMenu === true) {
                this.props.closeEventImportanceFilterMenu()

            }

        });
    }

    Changeiframecolorfilter = (magnitude) => {
        let value;
        switch (magnitude) {
            case 1:
                value = this.state.show_medium;
                break;
            case 2:
                value = this.state.show_high;
                break;
            case 3:
                value = this.state.show_higher;
                break;
            default:
                break;
        }

        if (typeof this.props.colIndex !== 'undefined') {
            this.props.updateCoinDetailColumnPreferences(magnitude, !value, this.props.colIndex, this.props.securityName, this.props.listkey)

        } else if (typeof this.props.searchsaveid !== 'undefined' && this.props.searchsaveid) {
            this.props.updatesavedsearchPreferences(magnitude, !value, this.props.searchsaveid)
        } else {
            this.props.updateUserPreferences_EventImportance_Iframe(magnitude, !value)
        }
        if (magnitude === 1) {

            this.setState({
                ...this.state,
                show_medium: !value,
                show_high: true,
                show_higher: true
            }, () => {
                this.toggleIFrameColorFilterMenuState(true)
            })
        } else if (magnitude === 2) {

            this.setState({
                ...this.state,
                show_medium: false,
                show_high: !value,
                show_higher: true
            }, () => {
                this.toggleIFrameColorFilterMenuState(true)
            })
        } else if (magnitude === 3) {

            this.setState({
                ...this.state,
                show_medium: false,
                show_high: false,
                show_higher: true
            }, () => {
                this.toggleIFrameColorFilterMenuState(true)
            })
        }


    }
    toggleCollapseHover = () => {
        this.setState({
            ...this.state,
            collapseHover: !this.state.collapseHover
        })
    }

    render() {
        const { show_medium, show_high, show_higher, iframecolorfiltermenustate, collapseHover } = this.state
        const containerStyleClass = `crl-tooltipMenu coincount normal-left ${this.props.lang && this.props.lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''}`;
        let pos = {
            right: this.props.lang && this.props.lang === 'ara' ? 'unset' : 20,
            left: this.props.lang && this.props.lang === 'ara' ? 20 : 'unset',
            // left: ContainerRect.left -30,
            // top: ContainerRect.bottom,
            top: 40,
        };
        // console.log(show_medium, show_high, show_higher)
        //1 -> Yellow(Regular events) and above 2-> Orange(Important events) and above 3-> red(Critical events) only
        let ImportanceIndicator;
        if (show_medium === true) {
            ImportanceIndicator = 1
        } else if (show_medium === false && show_high === true) {
            ImportanceIndicator = 2
        } else if (show_medium === false && show_high === false && show_higher === true) {
            ImportanceIndicator = 3
        }
        return (

            <span

                // onClick={(e) => {
                //     // console.log('expand clicked')
                //     e.stopPropagation();
                //     this.toggleIFrameColorFilterMenuState()
                // }}
                style={{ padding: '5px' }}
                ref={(el) => { this.iframecolorfilter = el; }}
            >
                {/* <span>{
                            this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['Credibility']
                                : this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['Credibility']
                                    : this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['Credibility']
                                        : this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['Credibility']
                                            : 'Credibility'
                        } : </span> */}

                <span style={{ padding: '0px 5px' }}>
                    <span className={`strengthindicator ${this.props.lang && this.props.lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''} active`}></span>
                    <span className={`strengthindicator ${this.props.lang && this.props.lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''} ${ImportanceIndicator >= 2 ? 'active' : ''} `}></span>
                    <span className={`strengthindicator ${this.props.lang && this.props.lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''} ${ImportanceIndicator === 3 ? 'active' : ''} `}></span>
                </span>

                <span
                    id={`${typeof this.props.colIndex !== 'undefined' ? 'openmenuhandle_' + this.props.securityName : typeof this.props.searchsaveid !== 'undefined' ? 'openmenuhandle_' + this.props.searchsaveid : 'openmenuhandle_smartinbox'}`}
                    onMouseEnter={this.toggleCollapseHover}
                    onMouseLeave={this.toggleCollapseHover}
                >
                    <img
                        style={{ width: '15px', marginTop: '-2px' }}
                        src={`${collapseHover
                            ? `${APPPATH.ImageUrl}/storyexpandiconblue.svg`
                            : `${APPPATH.ImageUrl}/storyexpandicon.svg`
                            }`}
                        className={`storyExpandIcon ${iframecolorfiltermenustate ? 'transform180anticlock' : ''} `}
                        alt=""
                    />
                    {/* ${this.props.collapse ? '' : 'transform180anticlock'} */}
                </span>
                {/* {iframecolorfiltermenu} */}
                <div className={`marg-0 ${containerStyleClass} ${!iframecolorfiltermenustate ? 'nodisp' : ''}`} style={{ ...pos, zIndex: 1203, border: '1px solid', borderColor: this.props.Theme === 'light' ? '#afafaf' : '#606060' }}>
                    <div>
                        <Row className={`marg-0 nopad`}>
                            <Col xs={12} sm={12} md={12} xl={12} lg={12} className={`tiphead-coinmenu brdr-bot-gray `}>
                                {/* <img className="tradeWindowClose-closeicon" style={{width: '15px'}} src={`${APPPATH.ImageUrl}/button-close.svg`} alt="" /> */}
                                <Row className={`marg-0 nopad`}>
                                    <Col className='bold' style={{ fontSize: '13px', textAlign: 'left', padding: '0px', color: this.props.Theme === 'light' ? "#252525" : "#ffffff" }}>
                                        <span>


                                        </span>
                                    </Col>
                                    <Col xs={1} sm={1} md={1} xl={1} lg={1} className="" style={{ marginTop: '-4px', cursor: 'pointer' }} >
                                        <i className="fa fa-times ttmenuclose" onClick={(e) => { e.stopPropagation(); this.toggleIFrameColorFilterMenuState() }}></i>
                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                        <Row className={`marg-0`} style={{ backgroundColor: this.props.Theme === 'light' ? '#ffffff' : '#0f1725', padding: "0px 10px 0px 10px" }}>
                            <Col xs={12} sm={12} md={12} xl={12} lg={12} className={`tipItem brdr-bot-gray`} onClick={() => { }}>

                                <div className={`${this.props.lang && this.props.lang === 'ara' ? 'text-right' : 'text-left'}`}>
                                    <span>
                                        <span className={`strengthindicator ${this.props.lang && this.props.lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''} active`}></span>
                                        <span className={`strengthindicator ${this.props.lang && this.props.lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''} active`}></span>
                                        <span className={`strengthindicator ${this.props.lang && this.props.lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''} active`}></span>
                                    </span>
                                    {/* <span> Critical Events</span> */}
                                    <span>
                                        {
                                            this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['Critical Events']
                                                : this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['Critical Events']
                                                    : this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['Critical Events']
                                                        : this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['Critical Events']
                                                            : 'Critical Events'
                                        }
                                    </span>
                                    <span style={{ float: this.props.lang && this.props.lang === 'ara' ? 'left' : 'right' }}>
                                        {/* <input
                                            type="checkbox"
                                            name='redcheckbox'
                                            checked={`${this.state.show_higher ? 'checked' : ''}`}
                                            // disabled={true}
                                            onChange={(e) => {
                                                this.Changeiframecolorfilter(3)
                                            }}
                                        /> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" style={{ marginTop: -3 }} onClick={(e) => { e.stopPropagation(); this.Changeiframecolorfilter(3) }}>
                                            <g id="icon-tick" transform="translate(16066 -11481)">
                                                <g id="Rectangle_408" data-name="Rectangle 408" transform="translate(-16066 11481)" fill={this.state.show_higher ? '#1da1f0' : '#ffffff'} stroke={this.state.show_higher ? '#1da1f0' : '#bbbbbb'} strokeWidth="1.5">
                                                    <rect width="22" height="22" rx="0" stroke="none" />
                                                    <rect x="0.75" y="0.75" width="20.5" height="20.5" rx="0" fill="none" />
                                                </g>
                                                {
                                                    this.state.show_higher && (
                                                        <path id="Path_387" data-name="Path 387" d="M2153.123,1499.219l3.933,3.932,7.061-7.061" transform="translate(-18213.621 9992.379)" fill="none" stroke="#fff" strokeWidth="2" />
                                                    )
                                                }

                                            </g>
                                        </svg>
                                    </span>
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={12} xl={12} lg={12} className={`tipItem brdr-bot-gray `} onClick={() => { }}>

                                <div className={`${this.props.lang && this.props.lang === 'ara' ? 'text-right' : 'text-left'}`}>
                                    <span>
                                        <span className={`strengthindicator ${this.props.lang && this.props.lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''} active`}></span>
                                        <span className={`strengthindicator ${this.props.lang && this.props.lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''} active`}></span>
                                        <span className={`strengthindicator ${this.props.lang && this.props.lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''} `}></span>
                                    </span>
                                    {/* <span> Important Events</span> */}
                                    <span>
                                        {
                                            this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['Important Events']
                                                : this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['Important Events']
                                                    : this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['Important Events']
                                                        : this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['Important Events']
                                                            : 'Important Events'
                                        }
                                    </span>
                                    <span style={{ float: this.props.lang && this.props.lang === 'ara' ? 'left' : 'right' }}>
                                        {/* <input
                                            type="checkbox"
                                            name='orangecheckbox'
                                            checked={`${this.state.show_high ? 'checked' : ''}`}
                                            onChange={(e) => {
                                                this.Changeiframecolorfilter(2)
                                            }}
                                        /> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" style={{ marginTop: -3 }} onClick={(e) => { e.stopPropagation(); this.Changeiframecolorfilter(2) }}>
                                            <g id="icon-tick" transform="translate(16066 -11481)">
                                                <g id="Rectangle_408" data-name="Rectangle 408" transform="translate(-16066 11481)" fill={this.state.show_high ? '#1da1f0' : '#ffffff'} stroke={this.state.show_high ? '#1da1f0' : '#bbbbbb'} strokeWidth="1.5">
                                                    <rect width="22" height="22" rx="0" stroke="none" />
                                                    <rect x="0.75" y="0.75" width="20.5" height="20.5" rx="0" fill="none" />
                                                </g>
                                                {
                                                    this.state.show_high && (
                                                        <path id="Path_387" data-name="Path 387" d="M2153.123,1499.219l3.933,3.932,7.061-7.061" transform="translate(-18213.621 9992.379)" fill="none" stroke="#fff" strokeWidth="2" />
                                                    )
                                                }

                                            </g>
                                        </svg>
                                    </span>
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={12} xl={12} lg={12} className={`tipItem `} onClick={() => { }}>

                                <div className={`${this.props.lang && this.props.lang === 'ara' ? 'text-right' : 'text-left'}`}>
                                    <span>
                                        <span className={`strengthindicator ${this.props.lang && this.props.lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''} active`}></span>
                                        <span className={`strengthindicator ${this.props.lang && this.props.lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''} `}></span>
                                        <span className={`strengthindicator ${this.props.lang && this.props.lang === 'ara' ? 'arabic' : ''} ${this.props.Theme === 'light' ? 'lightmode' : ''} `}></span>
                                    </span>
                                    {/* <span> Regular Events</span> */}
                                    <span>
                                        {
                                            this.props.lang && this.props.lang === 'spa' ? SpanishTranslations['Regular Events']
                                                : this.props.lang && this.props.lang === 'por' ? PortugueseTranslations['Regular Events']
                                                    : this.props.lang && this.props.lang === 'jap' ? JapaneseTranslations['Regular Events']
                                                        : this.props.lang && this.props.lang === 'ara' ? ArabicTranslations['Regular Events']
                                                            : 'Regular Events'
                                        }
                                    </span>
                                    <span style={{ float: this.props.lang && this.props.lang === 'ara' ? 'left' : 'right', paddingBottom: '5px' }}>
                                        {/* <input
                                            type="checkbox"
                                            name='yellowcheckbox'
                                            checked={`${this.state.show_medium ? 'checked' : ''}`}
                                            // defaultChecked={this.props.storyState.Show_MEDIUM}
                                            onChange={(e) => {
                                                this.Changeiframecolorfilter(1)

                                            }}
                                        /> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" style={{ marginTop: -3 }} onClick={(e) => { e.stopPropagation(); this.Changeiframecolorfilter(1) }}>
                                            <g id="icon-tick" transform="translate(16066 -11481)">
                                                <g id="Rectangle_408" data-name="Rectangle 408" transform="translate(-16066 11481)" fill={this.state.show_medium ? '#1da1f0' : '#ffffff'} stroke={this.state.show_medium ? '#1da1f0' : '#bbbbbb'} strokeWidth="1.5">
                                                    <rect width="22" height="22" rx="0" stroke="none" />
                                                    <rect x="0.75" y="0.75" width="20.5" height="20.5" rx="0" fill="none" />
                                                </g>
                                                {
                                                    this.state.show_medium && (
                                                        <path id="Path_387" data-name="Path 387" d="M2153.123,1499.219l3.933,3.932,7.061-7.061" transform="translate(-18213.621 9992.379)" fill="none" stroke="#fff" strokeWidth="2" />
                                                    )
                                                }

                                            </g>
                                        </svg>
                                    </span>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </span>

        );
    }
}

const mapStateToProps = (state, ownProps) => {

    let withYellow = false;
    let withOrange = false;
    let withRed = false;
    let CDSearch_with_yellow = false;
    let CDSearch_with_orange = false;
    let CDSearch_with_red = false;

    if (typeof ownProps.colIndex !== 'undefined') {
        if (typeof state.trendingMentions.tmDetailPreferences[ownProps.colIndex] !== 'undefined') {

            CDSearch_with_yellow = state.trendingMentions.tmDetailPreferences[ownProps.colIndex].CDSearch_with_yellow === 'true' || state.trendingMentions.tmDetailPreferences[ownProps.colIndex].CDSearch_with_yellow === true;

            CDSearch_with_orange = state.trendingMentions.tmDetailPreferences[ownProps.colIndex].CDSearch_with_orange === 'true' || state.trendingMentions.tmDetailPreferences[ownProps.colIndex].CDSearch_with_orange === true;

            CDSearch_with_red = state.trendingMentions.tmDetailPreferences[ownProps.colIndex].CDSearch_with_red === 'true' || state.trendingMentions.tmDetailPreferences[ownProps.colIndex].CDSearch_with_red === true
        }

    }
    if (typeof ownProps.searchsaveid !== 'undefined') {

        let searchSaveData = state.search.searchSaveData[ownProps.searchsaveid]
        if (searchSaveData) {
            withYellow = JSON.parse(searchSaveData.SavedDataDetails).withYellow
            withOrange = JSON.parse(searchSaveData.SavedDataDetails).withOrange
            withRed = JSON.parse(searchSaveData.SavedDataDetails).withRed
        }
    }
    return {
        lang: state.settings.lang,
        iframenav: state.settings.iframenav,
        iframefilters: state.settings.iframefilters,
        Theme: state.app_preferences.preferences.user_interface.Theme,
        show_medium: typeof ownProps.searchsaveid !== 'undefined' ? withYellow :
            typeof ownProps.colIndex !== 'undefined' ? CDSearch_with_yellow :
                state.app_preferences.preferences.event_importance_filters.Gray_Colored_Events.Show_Gray,
        show_high: typeof ownProps.searchsaveid !== 'undefined' ? withOrange :
            typeof ownProps.colIndex !== 'undefined' ? CDSearch_with_orange :
                state.app_preferences.preferences.event_importance_filters.Yellow_Colored_Events.Show_Yellow,
        show_higher: typeof ownProps.searchsaveid !== 'undefined' ? withRed :
            typeof ownProps.colIndex !== 'undefined' ? CDSearch_with_red :
                state.app_preferences.preferences.event_importance_filters.Red_Colored_Events.Show_Red,
        closeeventimportancefilterMenu: state.settings.closeeventimportancefilterMenu
    };
};

const mapDispatchToProps = {
    // setcolumnlist
    closeEventImportanceFilterMenu
};

export default connect(mapStateToProps, mapDispatchToProps)(EventImportanceMenuDashboard);
