let Preferences = {};



const UpdateUserPreferences = (preferences, title, value, category) => {
  // console.log(preferences, title, value, category)
  if (Object.keys(preferences).length) {
    Preferences = preferences;
    let preferenceCategories = Object.keys(preferences);
    Object.keys(preferences).map((cat) => {
      Object.keys(preferences[cat]).map((scat) => {
        if (
          preferences[cat][scat] &&
          Object.keys(preferences[cat][scat]).length &&
          typeof preferences[cat][scat] !== 'string'
        ) {
          preferenceCategories.push(scat);
        }
        return null;
      });
      return null;
    });
    // console.log(preferenceCategories)
    let newPreferences = {};
    if (preferenceCategories.indexOf(category) > -1) {
      switch (category) {
        case 'event_importance_filters':
          newPreferences = set_event_importance_filters(category, title, value);
          break;
        case 'Red_Colored_Events':
          newPreferences = set_event_importance_filters(category, title, value);
          break;
        case 'Yellow_Colored_Events':
          newPreferences = set_event_importance_filters(category, title, value);
          break;
        case 'Gray_Colored_Events':
          newPreferences = set_event_importance_filters(category, title, value);
          break;
        case 'White_Colored_Events':
          newPreferences = set_event_importance_filters(category, title, value);
          break;
        case 'Non_Active_Curated_Events':
          newPreferences = set_event_importance_filters(category, title, value);
          break;
        case 'notification':
          newPreferences = set_notification_filters(category, title, value);
          break;
        case 'email_preferences':
          newPreferences = set_notification_filters(category, title, value);
          break;
        case 'search_preferences':
          newPreferences = set_event_importance_filters(category, title, value);
          break;
        case 'coindetailsearch_preferences':
          newPreferences = set_event_importance_filters(category, title, value);
          break;
        case 'event_filters':
          newPreferences = set_event_importance_filters(category, title, value);
          break;
        case 'localisation_preferences':
          newPreferences = set_notification_filters(category, title, value);
          break;
        case 'user_interface':
          newPreferences = set_event_importance_filters(category, title, value);
          break;
        default:
          break;
      }
    }
    Preferences = newPreferences;
    return newPreferences;
  }
};

const CreatePreferencesStrings = (preferences = false) => {
  let newPreferences = preferences || Preferences;
  let preferenceCat = Object.keys(newPreferences);

  let eventImpFiltStr = '';
  let NotifFiltStr = '';
  let localisationStr = '';
  let NotifFiltStrObj = { DesktopNotifications: '', PhoneNotifications: '', EmailNotifications: '' };
  let phoneStr = '';
  let tmpDskTpNtfcns = [];
  let tmpPhnNtfcns = [];
  let tmpEmlNtfcns = [];
  preferenceCat.forEach((cat) => {
    let titles = Object.keys(newPreferences[cat]);

    if (
      cat === 'event_importance_filters' ||
      cat === 'event_filters' ||
      cat === 'email_preferences' ||
      cat === 'search_preferences' ||
      cat === 'coindetailsearch_preferences' ||
      cat === 'localisation_preferences' ||
      cat === 'user_interface'
    ) {
      titles.forEach((tl, i) => {
        // console.log(tl, titles.length)
        // console.log(Object.keys(newPreferences[cat][tl]).length)
        if (
          newPreferences[cat][tl] &&
          Object.keys(newPreferences[cat][tl]).length &&
          typeof newPreferences[cat][tl] !== 'string'
        ) {
          let subtitles = Object.keys(newPreferences[cat][tl]);
          subtitles.forEach((stl, y) => {
            // console.log(stl, subtitles.length)
            switch (stl) {
              case 'Show_White':
                eventImpFiltStr = `${eventImpFiltStr}Low_Importance:${newPreferences[cat][tl][stl]};`;
                break;
              case 'Show_Gray':
                eventImpFiltStr = `${eventImpFiltStr}Medium_Importance:${newPreferences[cat][tl][stl]};`;
                break;
              case 'Show_Yellow':
                eventImpFiltStr = `${eventImpFiltStr}High_Importance:${newPreferences[cat][tl][stl]};`;
                break;
              case 'Show_Red':
                eventImpFiltStr = `${eventImpFiltStr}Higher_Importance:${newPreferences[cat][tl][stl]};`;
                break;
              case 'Show_NACE':
                eventImpFiltStr = `${eventImpFiltStr}Show_Curated:${newPreferences[cat][tl][stl]};`;
                break;
              case 'Desktop_Notifications_Gray':
                tmpDskTpNtfcns.push(`Low=${newPreferences[cat][tl][stl] ? 'On' : 'Off'}`);
                // NotifFiltStrObj.DesktopNotifications = `${NotifFiltStrObj.DesktopNotifications}Low=${newPreferences[cat][tl][stl] ? 'On':'Off'}`
                // if(y<subtitles.length-1) {
                //   NotifFiltStrObj.DesktopNotifications = `${NotifFiltStrObj.DesktopNotifications},`;
                // }
                break;
              case 'Desktop_Notifications_Yellow':
                tmpDskTpNtfcns.push(`Medium=${newPreferences[cat][tl][stl] ? 'On' : 'Off'}`);
                // NotifFiltStrObj.DesktopNotifications = `${NotifFiltStrObj.DesktopNotifications}Medium=${newPreferences[cat][tl][stl] ? 'On':'Off'}`
                // if(y<subtitles.length-1) {
                //   NotifFiltStrObj.DesktopNotifications = `${NotifFiltStrObj.DesktopNotifications},`;
                // }
                break;
              case 'Desktop_Notifications_Red':
                tmpDskTpNtfcns.push(`High=${newPreferences[cat][tl][stl] ? 'On' : 'Off'}`);
                // NotifFiltStrObj.DesktopNotifications = `${NotifFiltStrObj.DesktopNotifications}High=${newPreferences[cat][tl][stl] ? 'On':'Off'}`
                // if(y<subtitles.length-1) {
                //   NotifFiltStrObj.DesktopNotifications = `${NotifFiltStrObj.DesktopNotifications},`;
                // }
                break;
              // case "Email_Notifications_Gray":
              //     tmpEmlNtfcns.push(`EN_Low=${newPreferences[cat][tl][stl]}`)

              //     break;
              // case "Email_Notifications_Yellow":
              //     tmpEmlNtfcns.push(`EN_Medium=${newPreferences[cat][tl][stl]}`)

              //     break;
              // case "Email_Notifications_Red":
              //     tmpEmlNtfcns.push(`EN_High=${newPreferences[cat][tl][stl]}`)

              //     break;
              case 'Desktop_Notify_Breaking_Only_Gray':
                tmpDskTpNtfcns.push(`Low_BreakingOnly=${newPreferences[cat][tl][stl] ? 'On' : 'Off'}`);
                // NotifFiltStrObj.DesktopNotifications = `${NotifFiltStrObj.DesktopNotifications}Low_BreakingOnly=${newPreferences[cat][tl][stl] ? 'On':'Off'}`
                // if(y<subtitles.length-1) {
                //   NotifFiltStrObj.DesktopNotifications = `${NotifFiltStrObj.DesktopNotifications},`;
                // }
                break;
              case 'Desktop_Notify_Breaking_Only_Yellow':
                tmpDskTpNtfcns.push(`Medium_BreakingOnly=${newPreferences[cat][tl][stl] ? 'On' : 'Off'}`);
                // NotifFiltStrObj.DesktopNotifications = `${NotifFiltStrObj.DesktopNotifications}Medium_BreakingOnly=${newPreferences[cat][tl][stl] ? 'On':'Off'}`
                // if(y<subtitles.length-1) {
                //   NotifFiltStrObj.DesktopNotifications = `${NotifFiltStrObj.DesktopNotifications},`;
                // }
                break;
              case 'Desktop_Notify_Breaking_Only_Red':
                tmpDskTpNtfcns.push(`High_BreakingOnly=${newPreferences[cat][tl][stl] ? 'On' : 'Off'}`);
                // NotifFiltStrObj.DesktopNotifications = `${NotifFiltStrObj.DesktopNotifications}High_BreakingOnly=${newPreferences[cat][tl][stl] ? 'On':'Off'}`
                // if(y<subtitles.length-1) {
                //   NotifFiltStrObj.DesktopNotifications = `${NotifFiltStrObj.DesktopNotifications},`;
                // }
                break;
              // case "Email_Notify_Breaking_Only_Gray":
              //     tmpEmlNtfcns.push(`EN_Low_BreakingOnly=${newPreferences[cat][tl][stl]}`)

              //     break;
              // case "Email_Notify_Breaking_Only_Yellow":
              //     tmpEmlNtfcns.push(`EN_Medium_BreakingOnly=${newPreferences[cat][tl][stl]}`)

              //     break;
              // case "Email_Notify_Breaking_Only_Red":
              //     tmpEmlNtfcns.push(`EN_High_BreakingOnly=${newPreferences[cat][tl][stl]}`)

              //     break;
              case 'SMS_Notifications_Yellow':
                tmpPhnNtfcns.push(`Medium=${newPreferences[cat][tl][stl] ? 'On' : 'Off'}`);
                // NotifFiltStrObj.PhoneNotifications = `${NotifFiltStrObj.PhoneNotifications}Medium=${newPreferences[cat][tl][stl] ? 'On':'Off'}`

                // if(i<titles.length-1) {
                //   NotifFiltStrObj.PhoneNotifications = `${NotifFiltStrObj.PhoneNotifications},`;
                // }
                break;
              case 'SMS_Notifications_Red':
                tmpPhnNtfcns.push(`High=${newPreferences[cat][tl][stl] ? 'On' : 'Off'}`);
                // NotifFiltStrObj.PhoneNotifications = `${NotifFiltStrObj.PhoneNotifications}High=${newPreferences[cat][tl][stl] ? 'On':'Off'}`
                // if(i<titles.length-1) {
                //   NotifFiltStrObj.PhoneNotifications = `${NotifFiltStrObj.PhoneNotifications},`;
                // }
                break;
              case 'SMS_Notifications_Gray':
                tmpPhnNtfcns.push(`Low=${newPreferences[cat][tl][stl] ? 'On' : 'Off'}`);
                // NotifFiltStrObj.PhoneNotifications = `${NotifFiltStrObj.PhoneNotifications}Low=${newPreferences[cat][tl][stl] ? 'On':'Off'}`
                // if(i<titles.length-1) {
                //   NotifFiltStrObj.PhoneNotifications = `${NotifFiltStrObj.PhoneNotifications},`;
                // }
                break;
              case 'Speech_Notifications_Red':
                eventImpFiltStr = `${eventImpFiltStr}High_Speech:${newPreferences[cat][tl][stl]};`;
                break;
              case 'Speech_Notifications_Yellow':
                eventImpFiltStr = `${eventImpFiltStr}Medium_Speech:${newPreferences[cat][tl][stl]};`;
                break;
              case 'Speech_Notifications_Gray':
                eventImpFiltStr = `${eventImpFiltStr}Low_Speech:${newPreferences[cat][tl][stl]};`;
                break;
              default:
                break;
            }
          });
        } else {
          // console.log(tl)
          if (cat === 'email_preferences') {
            // if(newPreferences[cat][tl]) {
            //   tmpEmlNtfcns[0] = `RealTime=${tl}`
            // }
            // console.log(`${tl} = ${newPreferences[cat][tl]}`)
            switch (tl) {
              case 'Daily_Notifications':
                // tmpEmlNtfcns.push(`Daily=${newPreferences[cat][tl]}`)
                tmpEmlNtfcns[1] = `Daily=${newPreferences[cat][tl]}`;

                break;
              case 'Weekly_Notifications':
                // tmpEmlNtfcns.push(`Weekly=${newPreferences[cat][tl]}`)
                tmpEmlNtfcns[2] = `Weekly=${newPreferences[cat][tl]}`;

                break;
              default:
                if (newPreferences[cat][tl]) {
                  tmpEmlNtfcns[0] = `RealTime=${tl}`;
                }
                break;
            }
            // console.log(tmpEmlNtfcns)
          } else if (cat === 'localisation_preferences') {
            // console.log(tl)
            switch (tl) {
              case 'Email':
                eventImpFiltStr = `${eventImpFiltStr}UserEmail:${newPreferences[cat][tl]};`;
                break;
              case 'Timezone':
                localisationStr = `${newPreferences[cat][tl]}`;
                break;
              case 'Phone':
                phoneStr = `${newPreferences[cat][tl]}`;
                break;
              default:
                break;
            }
            // console.log(localisationStr)
          } else if (cat === 'search_preferences') {
            switch (tl) {
              case 'Search_with_black':
                eventImpFiltStr = `${eventImpFiltStr}Search_with_black:${newPreferences[cat][tl]};`;
                break;
              case 'Search_with_gray':
                eventImpFiltStr = `${eventImpFiltStr}Search_with_gray:${newPreferences[cat][tl]};`;
                break;
              case 'Search_with_yellow':
                eventImpFiltStr = `${eventImpFiltStr}Search_with_yellow:${newPreferences[cat][tl]};`;
                break;
              case 'Search_with_orange':
                eventImpFiltStr = `${eventImpFiltStr}Search_with_orange:${newPreferences[cat][tl]};`;
                break;
              case 'Search_with_red':
                eventImpFiltStr = `${eventImpFiltStr}Search_with_red:${newPreferences[cat][tl]};`;
                break;

              default:
                break;
            }
          } else if (cat === 'coindetailsearch_preferences') {
            switch (tl) {
              case 'CDSearch_with_gray':
                eventImpFiltStr = `${eventImpFiltStr}CDSearch_with_gray:${newPreferences[cat][tl]};`;
                break;
              case 'CDSearch_with_black':
                eventImpFiltStr = `${eventImpFiltStr}CDSearch_with_black:${newPreferences[cat][tl]};`;
                break;
              case 'CDSearch_with_yellow':
                eventImpFiltStr = `${eventImpFiltStr}CDSearch_with_yellow:${newPreferences[cat][tl]};`;
                break;
              case 'CDSearch_with_orange':
                eventImpFiltStr = `${eventImpFiltStr}CDSearch_with_orange:${newPreferences[cat][tl]};`;
                break;
              case 'CDSearch_with_red':
                eventImpFiltStr = `${eventImpFiltStr}CDSearch_with_red:${newPreferences[cat][tl]};`;
                break;
              default:
                break;
            }
          } else if (tl !== 'Recurring_Events' && tl !== 'Planned_Scheduled_Events') {
            eventImpFiltStr = `${eventImpFiltStr}${tl}:${newPreferences[cat][tl]};`;
          }
        }
      });
      // console.log(eventImpFiltStr);
    }
  });
  NotifFiltStrObj.DesktopNotifications = tmpDskTpNtfcns.join(',');
  NotifFiltStrObj.PhoneNotifications = tmpPhnNtfcns.join(',');
  NotifFiltStrObj.EmailNotifications = tmpEmlNtfcns.join(',');

  Object.keys(NotifFiltStrObj).map((notifkey, i) => {
    // console.log(notifkey)
    NotifFiltStr = `${NotifFiltStr}${notifkey}:${NotifFiltStrObj[notifkey]}`;
    if (i < Object.keys(NotifFiltStrObj).length - 1) {
      NotifFiltStr = `${NotifFiltStr};`;
    }
    return null;
  });
  return {
    userPrefData: {
      UserPreferencesString: eventImpFiltStr,
    },
    notificationData: {
      UserPreferencesString: NotifFiltStr,
    },
    localisationData: {
      UserPreferencesString: localisationStr,
    },
    phoneData: {
      UserPreferencesString: phoneStr,
    },
  };
};

const set_event_importance_filters = (category, title, value) => {
  let preferences = Preferences;
  let CategoryList;
  switch (category) {
    case 'Red_Colored_Events':
      CategoryList = Object.keys(preferences.event_importance_filters[category]);
      break;
    case 'Yellow_Colored_Events':
      CategoryList = Object.keys(preferences.event_importance_filters[category]);
      break;
    case 'Gray_Colored_Events':
      CategoryList = Object.keys(preferences.event_importance_filters[category]);
      break;
    case 'White_Colored_Events':
      CategoryList = Object.keys(preferences.event_importance_filters[category]);
      break;
    case 'Non_Active_Curated_Events':
      CategoryList = Object.keys(preferences.event_importance_filters[category]);
      break;
    default:
      CategoryList = Object.keys(preferences[category]);
      break;
  }
  // let CategoryList = Object.keys(preferences[category]);
  // console.log(category, CategoryList, title, value)
  if (CategoryList.indexOf(title) > -1) {
    CategoryList.forEach((cat, i) => {
      switch (title) {
        case 'Show_Gray':
          if (cat === title) {
            preferences.event_importance_filters[category][title] = value;
            if (!value) {
              preferences.event_importance_filters[category].Desktop_Notifications_Gray = value;
              preferences.event_importance_filters[category].Desktop_Notify_Breaking_Only_Gray = value;
              preferences.event_importance_filters[category].Email_Notifications_Gray = value;
              preferences.event_importance_filters[category].Email_Notify_Breaking_Only_Gray = value;
              preferences.event_importance_filters[category].SMS_Notifications_Gray = value;
              preferences.event_importance_filters[category].Speech_Notifications_Gray = value;
            }
          }
          break;
        case 'Show_White':
          if (cat === title) {
            preferences.event_importance_filters[category][title] = value;
            if (!value) {
              // preferences.event_importance_filters[category].Desktop_Notifications_White = value;
              // preferences.event_importance_filters[category].Desktop_Notify_Breaking_Only_White = value;
              // preferences.event_importance_filters[category].Email_Notifications_White = value;
              // preferences.event_importance_filters[category].Email_Notify_Breaking_Only_White = value;
              // preferences.event_importance_filters[category].SMS_Notifications_White = value;
              // preferences.event_importance_filters[category].Speech_Notifications_White = value;
            }
          }
          break;
        case 'Show_Yellow':
          if (cat === title) {
            preferences.event_importance_filters[category][title] = value;
            if (!value) {
              preferences.event_importance_filters[category].Desktop_Notifications_Yellow = value;
              preferences.event_importance_filters[category].Desktop_Notify_Breaking_Only_Yellow = value;
              preferences.event_importance_filters[category].Email_Notifications_Yellow = value;
              preferences.event_importance_filters[category].Email_Notify_Breaking_Only_Yellow = value;
              preferences.event_importance_filters[category].SMS_Notifications_Yellow = value;
              preferences.event_importance_filters[category].Speech_Notifications_Yellow = value;
            }
          }
          break;
        case 'Show_Red':
          if (cat === title) {
            preferences.event_importance_filters[category][title] = value;
            if (!value) {
              preferences.event_importance_filters[category].Desktop_Notifications_Red = value;
              preferences.event_importance_filters[category].Desktop_Notify_Breaking_Only_Red = value;
              preferences.event_importance_filters[category].Email_Notifications_Red = value;
              preferences.event_importance_filters[category].Email_Notify_Breaking_Only_Red = value;
              preferences.event_importance_filters[category].SMS_Notifications_Red = value;
              preferences.event_importance_filters[category].Speech_Notifications_Red = value;
            }
          }
          break;
        case 'Show_NACE':
          if (cat === title) {
            preferences.event_importance_filters[category][title] = value;
          }
          break;
        case 'Desktop_Notifications_Gray':
          if (cat === title) {
            if (value) {
              preferences.event_importance_filters[category].Show_Gray = value;
              preferences.event_importance_filters[category][title] = value;
            } else {
              preferences.event_importance_filters[category][title] = value;
              preferences.event_importance_filters[category].Desktop_Notify_Breaking_Only_Gray = value;
            }
          }
          break;
        case 'Desktop_Notifications_Yellow':
          if (cat === title) {
            if (value) {
              preferences.event_importance_filters[category].Show_Yellow = value;
              preferences.event_importance_filters[category][title] = value;
            } else {
              preferences.event_importance_filters[category][title] = value;
              preferences.event_importance_filters[category].Desktop_Notify_Breaking_Only_Yellow = value;
            }
          }
          break;
        case 'Desktop_Notifications_Red':
          if (cat === title) {
            if (value) {
              preferences.event_importance_filters[category].Show_Red = value;
              preferences.event_importance_filters[category][title] = value;
            } else {
              preferences.event_importance_filters[category][title] = value;
              preferences.event_importance_filters[category].Desktop_Notify_Breaking_Only_Red = value;
            }
          }
          break;
        case 'Email_Notifications_Gray':
          if (cat === title) {
            if (value) {
              preferences.event_importance_filters[category].Show_Gray = value;
              preferences.event_importance_filters[category][title] = value;
            } else {
              preferences.event_importance_filters[category][title] = value;
              preferences.event_importance_filters[category].Email_Notify_Breaking_Only_Gray = value;
            }
          }
          break;
        case 'Email_Notifications_Yellow':
          if (cat === title) {
            if (value) {
              preferences.event_importance_filters[category].Show_Yellow = value;
              preferences.event_importance_filters[category][title] = value;
            } else {
              preferences.event_importance_filters[category][title] = value;
              preferences.event_importance_filters[category].Email_Notify_Breaking_Only_Yellow = value;
            }
          }
          break;
        case 'Email_Notifications_Red':
          if (cat === title) {
            if (value) {
              preferences.event_importance_filters[category].Show_Red = value;
              preferences.event_importance_filters[category][title] = value;
            } else {
              preferences.event_importance_filters[category][title] = value;
              preferences.event_importance_filters[category].Email_Notify_Breaking_Only_Red = value;
            }
          }
          break;
        case 'Desktop_Notify_Breaking_Only_Gray':
          if (cat === title) {
            if (value) {
              preferences.event_importance_filters[category].Show_Gray = value;
              // preferences.event_importance_filters[category].Desktop_Notifications_Gray = value;
              preferences.event_importance_filters[category][title] = value;
            } else {
              preferences.event_importance_filters[category][title] = value;
            }
          }
          break;
        case 'Desktop_Notify_Breaking_Only_Yellow':
          if (cat === title) {
            if (value) {
              preferences.event_importance_filters[category].Show_Yellow = value;
              // preferences.event_importance_filters[category].Desktop_Notifications_Yellow = value;
              preferences.event_importance_filters[category][title] = value;
            } else {
              preferences.event_importance_filters[category][title] = value;
            }
          }
          break;
        case 'Desktop_Notify_Breaking_Only_Red':
          if (cat === title) {
            if (value) {
              preferences.event_importance_filters[category].Show_Red = value;
              // preferences.event_importance_filters[category].Desktop_Notifications_Red = value;
              preferences.event_importance_filters[category][title] = value;
            } else {
              preferences.event_importance_filters[category][title] = value;
            }
          }
          break;
        case 'Email_Notify_Breaking_Only_Red':
          if (cat === title) {
            if (value) {
              preferences.event_importance_filters[category].Show_Red = value;
              // preferences.event_importance_filters[category].Desktop_Notifications_Red = value;
              preferences.event_importance_filters[category][title] = value;
            } else {
              preferences.event_importance_filters[category][title] = value;
            }
          }
          break;
        case 'Email_Notify_Breaking_Only_Yellow':
          if (cat === title) {
            if (value) {
              preferences.event_importance_filters[category].Show_Yellow = value;
              // preferences.event_importance_filters[category].Desktop_Notifications_Red = value;
              preferences.event_importance_filters[category][title] = value;
            } else {
              preferences.event_importance_filters[category][title] = value;
            }
          }
          break;
        case 'Email_Notify_Breaking_Only_Gray':
          if (cat === title) {
            if (value) {
              preferences.event_importance_filters[category].Show_Gray = value;
              // preferences.event_importance_filters[category].Desktop_Notifications_Red = value;
              preferences.event_importance_filters[category][title] = value;
            } else {
              preferences.event_importance_filters[category][title] = value;
            }
          }
          break;
        case 'SMS_Notifications_Red':
          if (cat === title) {
            if (value) {
              preferences.event_importance_filters[category].Show_Red = value;
              preferences.event_importance_filters[category][title] = value;
            } else {
              preferences.event_importance_filters[category][title] = value;
            }
          }
          break;
        case 'SMS_Notifications_Yellow':
          if (cat === title) {
            if (value) {
              preferences.event_importance_filters[category].Show_Yellow = value;
              preferences.event_importance_filters[category][title] = value;
            } else {
              preferences.event_importance_filters[category][title] = value;
            }
          }
          break;
        case 'SMS_Notifications_Gray':
          if (cat === title) {
            if (value) {
              preferences.event_importance_filters[category].Show_Gray = value;
              preferences.event_importance_filters[category][title] = value;
            } else {
              preferences.event_importance_filters[category][title] = value;
            }
          }
          break;
        case 'Speech_Notifications_Red':
          if (cat === title) {
            if (value) {
              preferences.event_importance_filters[category].Show_Red = value;
              preferences.event_importance_filters[category][title] = value;
            } else {
              preferences.event_importance_filters[category][title] = value;
            }
          }
          break;
        case 'Speech_Notifications_Yellow':
          if (cat === title) {
            if (value) {
              preferences.event_importance_filters[category].Show_Yellow = value;
              preferences.event_importance_filters[category][title] = value;
            } else {
              preferences.event_importance_filters[category][title] = value;
            }
          }
          break;
        case 'Speech_Notifications_Gray':
          if (cat === title) {
            if (value) {
              preferences.event_importance_filters[category].Show_Gray = value;
              preferences.event_importance_filters[category][title] = value;
            } else {
              preferences.event_importance_filters[category][title] = value;
            }
          }
          break;
        case 'Mainstream_News':
          if (cat === title) {
            preferences[category][cat] = value;
          }
          break;
        case 'Local_News':
          if (cat === title) {
            preferences[category][cat] = value;
          }
          break;
        case 'Securities_Line':
          if (cat === title) {
            preferences[category][cat] = value;
          }
          break;
        case 'Tab_Mode':
          if (cat === title) {
            preferences[category][cat] = value;
          }
          break;
        case 'Early_Events':
          if (cat === title) {
            preferences[category][cat] = value;
          }
          break;
        case 'Locations_Related_Countries':
          if (cat === title) {
            preferences[category][cat] = value;
          }
          break;

        case 'Search_with_black':
          if (cat === title) {
            if (value) {
              preferences[category][cat] = value;
              preferences[category][`Search_with_gray`] = value;
              preferences[category][`Search_with_yellow`] = value;
              preferences[category][`Search_with_orange`] = value;
              preferences[category][`Search_with_red`] = value;
            } else {
              preferences[category][cat] = value;
            }
          }
          break;
        case 'Search_with_gray':
          if (cat === title) {
            if (value) {
              preferences[category][cat] = value;
              preferences[category][`Search_with_yellow`] = value;
              preferences[category][`Search_with_orange`] = value;
              preferences[category][`Search_with_red`] = value;
            } else {
              preferences[category][cat] = value;
              preferences[category][`Search_with_black`] = value;
            }
          }
          break;
        case 'Search_with_yellow':
          if (cat === title) {
            if (value) {
              preferences[category][cat] = value;
              preferences[category][`Search_with_orange`] = value;
              preferences[category][`Search_with_red`] = value;
            } else {
              preferences[category][cat] = value;
              preferences[category][`Search_with_gray`] = value;
              preferences[category][`Search_with_black`] = value;
            }
          }
          break;
        case 'Search_with_orange':
          if (cat === title) {
            if (value) {
              preferences[category][cat] = value;
              preferences[category][`Search_with_red`] = value;
            } else {
              preferences[category][cat] = value;
              preferences[category][`Search_with_gray`] = value;
              preferences[category][`Search_with_black`] = value;
              preferences[category][`Search_with_yellow`] = value;
            }
          }
          break;
        case 'Search_with_red':
          if (cat === title) {
            if (value) {
              preferences[category][cat] = value;
            } else {
              preferences[category][cat] = value;
              preferences[category][`Search_with_gray`] = value;
              preferences[category][`Search_with_black`] = value;
              preferences[category][`Search_with_yellow`] = value;
              preferences[category][`Search_with_orange`] = value;
            }
          }
          break;
        case 'CDSearch_with_gray':
          if (cat === title) {
            if (value) {
              preferences[category][cat] = value;
              preferences[category][`CDSearch_with_yellow`] = value;
              preferences[category][`CDSearch_with_orange`] = value;
              preferences[category][`CDSearch_with_red`] = value;
            } else {
              preferences[category][cat] = value;
              preferences[category]['CDSearch_with_black'] = value;
            }
          }
          break;
        case 'CDSearch_with_black':
          if (cat === title) {
            if (value) {
              preferences[category][cat] = value;
              preferences[category][`CDSearch_with_gray`] = value;
              preferences[category][`CDSearch_with_yellow`] = value;
              preferences[category][`CDSearch_with_orange`] = value;
              preferences[category][`CDSearch_with_red`] = value;
            } else {
              preferences[category][cat] = value;
            }
          }
          break;
        case 'CDSearch_with_yellow':
          if (cat === title) {
            if (value) {
              preferences[category][cat] = value;
              preferences[category][`CDSearch_with_orange`] = value;
              preferences[category][`CDSearch_with_red`] = value;
            } else {
              preferences[category][cat] = value;
              preferences[category][`CDSearch_with_gray`] = value;
              preferences[category]['CDSearch_with_black'] = value;
            }
          }
          break;
        case 'CDSearch_with_orange':
          if (cat === title) {
            if (value) {
              preferences[category][cat] = value;
              preferences[category][`CDSearch_with_red`] = value;
            } else {
              preferences[category][cat] = value;
              preferences[category][`CDSearch_with_yellow`] = value;
              preferences[category][`CDSearch_with_gray`] = value;
              preferences[category]['CDSearch_with_black'] = value;
            }
          }
          break;
        case 'CDSearch_with_red':
          if (cat === title) {
            if (value) {
              preferences[category][cat] = value;
            } else {
              preferences[category][`CDSearch_with_yellow`] = value;
              preferences[category][`CDSearch_with_orange`] = value;
              preferences[category][`CDSearch_with_gray`] = value;
              preferences[category]['CDSearch_with_black'] = value;
            }
          }
          break;
        default:
          // if(cat === 'Low_Importance') { preferences[category][cat] = false; }
          // if(cat === 'Medium_Importance') { preferences[category][cat] = false; }
          // if(cat === 'High_Importance') { preferences[category][cat] = false; }
          break;
      }
    });
    return preferences;
  }
  return preferences;
};
const set_notification_filters = (category, title, value) => {
  let preferences = Preferences;
  // console.log(preferences)
  let RealTime = '';
  let incTitle = title;
  if (category === 'email_preferences') {
    if (title === 'Daily_notifications'/*title === 'Daily_Weekly_Notifications'*/) {
      // title = 'None';
      preferences[category]['Daily_Notifications'] = value;
      preferences[category]['Weekly_Notifications'] = false;
    } else if (title === 'Weekly_notifications') {
      preferences[category]['Daily_Notifications'] = false;
      preferences[category]['Weekly_Notifications'] = value;
    } else if (title === 'No_notifications') {
      preferences[category]['Daily_Notifications'] = false;
      preferences[category]['Weekly_Notifications'] = false;
    } else {
      for (let key in preferences[category]) {
        if (title.toLowerCase() === key.toLowerCase()) {
          preferences[category][key] = true;
          RealTime = key;
        } else {
          if (key !== 'Daily_Notifications' && key !== 'Weekly_Notifications') {
            preferences[category][key] = false;
          }
        }
      }
    }

    // if(RealTime !== 'None') {
    //   preferences[category]['Daily_Notifications'] = true;
    //   preferences[category]['Weekly_Notifications'] = true;
    // } else {
    //   if(incTitle !== 'Daily_Weekly_Notifications') {
    //     preferences[category]['Daily_Notifications'] = false;
    //     preferences[category]['Weekly_Notifications'] = false;
    //   } else {
    //     preferences[category]['Daily_Notifications'] = true;
    //     preferences[category]['Weekly_Notifications'] = true;
    //   }

    // }
  } else {
    let CategoryList = Object.keys(preferences[category]);
    console.log(category, CategoryList, preferences, value)
    if (CategoryList.indexOf(title) > -1) {
      preferences[category][title] = value;
    }
  }

  return preferences;
};

const GetCategoriesAndTitles = (Color = false, Value, type = 'main') => {
  console.log(Color, Value, type);
  let categories = [];
  let titles = [];
  let selectedCategory = '';
  if (Color) {
    switch (Color) {
      case '#000000':
        if (type === 'search') {
          categories = ['search_preferences'];
          titles = !Value
            ? ['Search_with_black']
            : ['Search_with_black', 'Search_with_gray', 'Search_with_yellow', 'Search_with_orange', 'Search_with_red'];
          selectedCategory = 'search_preferences';
        } else if (type === 'tmd') {
          categories = ['coindetailsearch_preferences'];
          titles = !Value
            ? ['CDSearch_with_black']
            : [
              'CDSearch_with_black',
              'CDSearch_with_gray',
              'CDSearch_with_yellow',
              'CDSearch_with_orange',
              'CDSearch_with_red',
            ];
          selectedCategory = 'coindetailsearch_preferences';
        }

        break;
      case '#787878':
        if (type === 'main') {
          categories = !Value
            ? ['Non_Active_Curated_Events']
            : ['Non_Active_Curated_Events', 'Gray_Colored_Events', 'Yellow_Colored_Events', 'Red_Colored_Events'];
          titles = !Value ? ['Show_NACE'] : ['Show_NACE', 'Show_Gray', 'Show_Yellow', 'Show_Red'];
          selectedCategory = 'Non_Active_Curated_Events';
        } else if (type === 'search') {
          categories = ['search_preferences'];
          titles = !Value
            ? ['Search_with_black', 'Search_with_gray']
            : ['Search_with_gray', 'Search_with_yellow', 'Search_with_orange', 'Search_with_red'];
          selectedCategory = 'search_preferences';
        } else if (type === 'tmd') {
          categories = ['coindetailsearch_preferences'];
          titles = !Value
            ? ['CDSearch_with_black', 'CDSearch_with_gray']
            : ['CDSearch_with_gray', 'CDSearch_with_yellow', 'CDSearch_with_orange', 'CDSearch_with_red'];
          selectedCategory = 'coindetailsearch_preferences';
        }

        break;
      case '#ffcf95':
        if (type === 'main') {
          categories = !Value
            ? ['Non_Active_Curated_Events', 'Gray_Colored_Events']
            : ['Gray_Colored_Events', 'Yellow_Colored_Events', 'Red_Colored_Events'];
          titles = !Value ? ['Show_NACE', 'Show_Gray'] : ['Show_Gray', 'Show_Yellow', 'Show_Red'];
          selectedCategory = 'Gray_Colored_Events';
        } else if (type === 'search') {
          categories = ['search_preferences'];
          titles = !Value
            ? ['Search_with_black', 'Search_with_gray', 'Search_with_yellow']
            : ['Search_with_yellow', 'Search_with_orange', 'Search_with_red'];
          selectedCategory = 'search_preferences';
        } else if (type === 'tmd') {
          categories = ['coindetailsearch_preferences'];
          titles = !Value
            ? ['CDSearch_with_black', 'CDSearch_with_gray', 'CDSearch_with_yellow']
            : ['CDSearch_with_yellow', 'CDSearch_with_orange', 'CDSearch_with_red'];
          selectedCategory = 'coindetailsearch_preferences';
        }
        break;
      case '#ff7f27':
        if (type === 'main') {
          categories = !Value
            ? ['Non_Active_Curated_Events', 'Gray_Colored_Events', 'Yellow_Colored_Events']
            : ['Yellow_Colored_Events', 'Red_Colored_Events'];
          titles = !Value ? ['Show_NACE', 'Show_Gray', 'Show_Yellow'] : ['Show_Yellow', 'Red_Colored_Events'];
          selectedCategory = 'Yellow_Colored_Events';
        } else if (type === 'search') {
          categories = ['search_preferences'];
          titles = !Value
            ? ['Search_with_black', 'Search_with_gray', 'Search_with_yellow', 'Search_with_orange']
            : ['Search_with_orange', 'Search_with_red'];
          selectedCategory = 'search_preferences';
        } else if (type === 'tmd') {
          categories = ['coindetailsearch_preferences'];
          titles = !Value
            ? ['CDSearch_with_black', 'CDSearch_with_gray', 'CDSearch_with_yellow', 'CDSearch_with_orange']
            : ['CDSearch_with_orange', 'CDSearch_with_red'];
          selectedCategory = 'coindetailsearch_preferences';
        }
        break;
      case '#d24242':
        if (type === 'main') {
          categories = !Value
            ? ['Non_Active_Curated_Events', 'Gray_Colored_Events', 'Yellow_Colored_Events']
            : ['Red_Colored_Events'];
          titles = !Value ? ['Show_NACE', 'Show_Gray', 'Show_Yellow'] : ['Show_Red'];
          selectedCategory = 'Red_Colored_Events';
        } else if (type === 'search') {
          categories = ['search_preferences'];
          titles = !Value
            ? ['Search_with_black', 'Search_with_gray', 'Search_with_yellow', 'Search_with_orange']
            : ['Search_with_red'];
          selectedCategory = 'search_preferences';
        } else if (type === 'tmd') {
          categories = ['coindetailsearch_preferences'];
          titles = !Value
            ? ['CDSearch_with_black', 'CDSearch_with_gray', 'CDSearch_with_yellow', 'CDSearch_with_orange']
            : ['CDSearch_with_red'];
          selectedCategory = 'coindetailsearch_preferences';
        }
        break;
      default:
        break;
    }
  }
  return { categories, titles, selectedCategory };
};

const GetCategoriesAndTitlesForMagnitudePreference_Coindetailcolumn = (Magnitude = false, Value) => {
  let categories = [];
  let titles = [];
  let selectedCategory = '';

  // console.log(Magnitude, Value)
  if (Magnitude) {
    switch (Magnitude) {
      case 1:
        if (Value) {
          categories = ['coincolumndetailsearch_preferences'];
          titles = [
            { CDSearch_with_black: false },
            { CDSearch_with_gray: false },
            { CDSearch_with_white: false },
            { CDSearch_with_yellow: Value },
            { CDSearch_with_orange: true },
            { CDSearch_with_red: true }
          ];
          selectedCategory = 'coincolumndetailsearch_preferences';
        } else {
          categories = ['coincolumndetailsearch_preferences'];
          titles = [
            { CDSearch_with_black: false },
            { CDSearch_with_gray: false },
            { CDSearch_with_white: false },
            { CDSearch_with_yellow: false },
            { CDSearch_with_orange: true },
            { CDSearch_with_red: true }
          ];
          selectedCategory = 'coincolumndetailsearch_preferences';
        }

        break;
      case 2:
        if (Value) {
          categories = ['coincolumndetailsearch_preferences'];
          titles = [
            { CDSearch_with_black: false },
            { CDSearch_with_gray: false },
            { CDSearch_with_white: false },
            { CDSearch_with_yellow: false },
            { CDSearch_with_orange: Value },
            { CDSearch_with_red: true }
          ];
          selectedCategory = 'coincolumndetailsearch_preferences';

        } else {
          categories = ['coincolumndetailsearch_preferences'];
          titles = [
            { CDSearch_with_black: false },
            { CDSearch_with_gray: false },
            { CDSearch_with_white: false },
            { CDSearch_with_yellow: false },
            { CDSearch_with_orange: false },
            { CDSearch_with_red: true }
          ];
          selectedCategory = 'coincolumndetailsearch_preferences';
        }
        break;
      case 3:
        categories = ['coincolumndetailsearch_preferences'];
        titles = [
          { CDSearch_with_black: false },
          { CDSearch_with_gray: false },
          { CDSearch_with_white: false },
          { CDSearch_with_yellow: false },
          { CDSearch_with_orange: false },
          { CDSearch_with_red: true }
        ];
        selectedCategory = 'coincolumndetailsearch_preferences';
        break;
      default:
        break;
    }
  }
  // console.log(categories, titles, selectedCategory)
  return { categories, titles, selectedCategory };
}

const GetCategoriesAndTitlesForMagnitudePreference_Iframe = (Magnitude = false, Value) => {
  let categories = [];
  let titles = [];
  let selectedCategory = '';
  // console.log(Magnitude, Value)
  if (Magnitude) {
    switch (Magnitude) {
      case 1:
        if (Value) {
          categories = [
            'Non_Active_Curated_Events',
            'White_Colored_Events',
            'Gray_Colored_Events',
            'Yellow_Colored_Events',
            'Red_Colored_Events',
          ];
          titles = [{ Show_NACE: !Value }, { Show_White: !Value }, { Show_Gray: Value }, { Show_Yellow: Value }, { Show_Red: Value }];
          selectedCategory = 'Gray_Colored_Events';
        } else {
          categories = ['Gray_Colored_Events'];
          titles = [{ Show_Gray: Value }];
          selectedCategory = 'Gray_Colored_Events';
        }

        break;
      case 2:
        if (Value) {
          categories = [
            'Non_Active_Curated_Events',
            'White_Colored_Events',
            'Gray_Colored_Events',
            'Yellow_Colored_Events',
            'Red_Colored_Events',
          ];
          titles = [{ Show_NACE: !Value }, { Show_White: !Value }, { Show_Gray: !Value }, { Show_Yellow: Value }, { Show_Red: Value }];
          selectedCategory = 'Yellow_Colored_Events';

        } else {
          categories = ['Gray_Colored_Events', 'Yellow_Colored_Events'];
          titles = [{ Show_Gray: Value }, { Show_Yellow: Value }];
          selectedCategory = 'Yellow_Colored_Events';
        }
        break;
      case 3:
        if (Value) {
          categories = [
            'Non_Active_Curated_Events',
            'White_Colored_Events',
            'Gray_Colored_Events',
            'Yellow_Colored_Events',
            'Red_Colored_Events',
          ];
          titles = [{ Show_NACE: !Value }, { Show_White: !Value }, { Show_Gray: !Value }, { Show_Yellow: !Value }, { Show_Red: Value }];
          selectedCategory = 'Red_Colored_Events';
        } else {
          categories = ['Gray_Colored_Events', 'Yellow_Colored_Events', 'Red_Colored_Events'];
          titles = [{ Show_Gray: Value }, { Show_Yellow: Value }, { Show_Red: !Value }];
          selectedCategory = 'Red_Colored_Events';
        }
        break;
      default:
        break;
    }
  }
  // console.log(categories, titles, selectedCategory)
  return { categories, titles, selectedCategory };
}

const GetCategoriesAndTitlesForSlider = (Color = false, Value, type = 'main') => {
  // console.log(Color, Value, type)
  let categories = [];
  let titles = [];
  let selectedCategory = '';
  if (Color) {
    switch (Color) {
      case '#000000':
        if (type === 'search') {
          categories = ['search_preferences'];
          titles = [
            { Search_with_black: Value },
            { Search_with_gray: Value },
            { Search_with_yellow: Value },
            { Search_with_orange: Value },
            { Search_with_red: Value },
          ];
          selectedCategory = 'search_preferences';
        } else if (type === 'tmd') {
          categories = ['coindetailsearch_preferences'];
          titles = [
            { CDSearch_with_black: Value },
            { CDSearch_with_gray: Value },
            { CDSearch_with_yellow: Value },
            { CDSearch_with_orange: Value },
            { CDSearch_with_red: Value },
          ];
          selectedCategory = 'coindetailsearch_preferences';
        } else if (type === 'tmcd') {
          categories = ['coincolumndetailsearch_preferences'];
          titles = [
            { CDSearch_with_black: Value },
            { CDSearch_with_gray: Value },
            { CDSearch_with_white: Value },
            { CDSearch_with_yellow: Value },
            { CDSearch_with_orange: Value },
            { CDSearch_with_red: Value },
          ];
          selectedCategory = 'coincolumndetailsearch_preferences';
        }

        break;
      case '#787878':
        if (type === 'main') {
          categories = [
            'Non_Active_Curated_Events',
            'White_Colored_Events',
            'Gray_Colored_Events',
            'Yellow_Colored_Events',
            'Red_Colored_Events',
          ];
          titles = [{ Show_NACE: Value }, { Show_White: Value }, { Show_Gray: Value }, { Show_Yellow: Value }, { Show_Red: Value }];
          selectedCategory = 'Non_Active_Curated_Events';
        } else if (type === 'search') {
          categories = ['search_preferences'];
          titles = [
            { Search_with_black: !Value },
            { Search_with_gray: Value },
            { Search_with_yellow: Value },
            { Search_with_orange: Value },
            { Search_with_red: Value },
          ];
          selectedCategory = 'search_preferences';
        } else if (type === 'tmd') {
          categories = ['coindetailsearch_preferences'];
          titles = [
            { CDSearch_with_black: !Value },
            { CDSearch_with_gray: Value },
            { CDSearch_with_yellow: Value },
            { CDSearch_with_orange: Value },
            { CDSearch_with_red: Value },
          ];
          selectedCategory = 'coindetailsearch_preferences';
        } else if (type === 'tmcd') {
          categories = ['coincolumndetailsearch_preferences'];
          titles = [
            { CDSearch_with_black: !Value },
            { CDSearch_with_gray: Value },
            { CDSearch_with_white: Value },
            { CDSearch_with_yellow: Value },
            { CDSearch_with_orange: Value },
            { CDSearch_with_red: Value },
          ];
          selectedCategory = 'coincolumndetailsearch_preferences';
        }

        break;
      case '#e9edf6':
        if (type === 'main') {
          categories = [
            'Non_Active_Curated_Events',
            'White_Colored_Events',
            'Gray_Colored_Events',
            'Yellow_Colored_Events',
            'Red_Colored_Events',
          ];
          titles = [{ Show_NACE: !Value }, { Show_White: Value }, { Show_Gray: Value }, { Show_Yellow: Value }, { Show_Red: Value }];
          selectedCategory = 'White_Colored_Events';
        } else if (type === 'tmcd') {
          categories = ['coincolumndetailsearch_preferences'];
          titles = [
            { CDSearch_with_black: !Value },
            { CDSearch_with_gray: !Value },
            { CDSearch_with_white: Value },
            { CDSearch_with_yellow: Value },
            { CDSearch_with_orange: Value },
            { CDSearch_with_red: Value },
          ];
          selectedCategory = 'coincolumndetailsearch_preferences';
        }
        break;
      case '#ffcf95':
        if (type === 'main') {
          categories = [
            'Non_Active_Curated_Events',
            'White_Colored_Events',
            'Gray_Colored_Events',
            'Yellow_Colored_Events',
            'Red_Colored_Events',
          ];
          titles = [{ Show_NACE: !Value }, { Show_White: !Value }, { Show_Gray: Value }, { Show_Yellow: Value }, { Show_Red: Value }];
          selectedCategory = 'Gray_Colored_Events';
        } else if (type === 'search') {
          categories = ['search_preferences'];
          titles = [
            { Search_with_black: !Value },
            { Search_with_gray: !Value },
            { Search_with_yellow: Value },
            { Search_with_orange: Value },
            { Search_with_red: Value },
          ];
          selectedCategory = 'search_preferences';
        } else if (type === 'tmd') {
          categories = ['coindetailsearch_preferences'];
          titles = [
            { CDSearch_with_black: !Value },
            { CDSearch_with_gray: !Value },
            { CDSearch_with_yellow: Value },
            { CDSearch_with_orange: Value },
            { CDSearch_with_red: Value },
          ];
          selectedCategory = 'coindetailsearch_preferences';
        } else if (type === 'tmcd') {
          categories = ['coincolumndetailsearch_preferences'];
          titles = [
            { CDSearch_with_black: !Value },
            { CDSearch_with_gray: !Value },
            { CDSearch_with_white: !Value },
            { CDSearch_with_yellow: Value },
            { CDSearch_with_orange: Value },
            { CDSearch_with_red: Value },
          ];
          selectedCategory = 'coincolumndetailsearch_preferences';
        }
        break;
      case '#ff7f27':
        if (type === 'main') {
          categories = [
            'Non_Active_Curated_Events',
            'White_Colored_Events',
            'Gray_Colored_Events',
            'Yellow_Colored_Events',
            'Red_Colored_Events',
          ];
          titles = [
            { Show_NACE: !Value },
            { Show_White: !Value },
            { Show_Gray: !Value },
            { Show_Yellow: Value },
            { Red_Colored_Events: Value },
          ];
          selectedCategory = 'Yellow_Colored_Events';
        } else if (type === 'search') {
          categories = ['search_preferences'];
          titles = [
            { Search_with_black: !Value },
            { Search_with_gray: !Value },
            { Search_with_yellow: !Value },
            { Search_with_orange: Value },
            { Search_with_red: Value },
          ];
          selectedCategory = 'search_preferences';
        } else if (type === 'tmd') {
          categories = ['coindetailsearch_preferences'];
          titles = [
            { CDSearch_with_black: !Value },
            { CDSearch_with_gray: !Value },
            { CDSearch_with_yellow: !Value },
            { CDSearch_with_orange: Value },
            { CDSearch_with_red: Value },
          ];
          selectedCategory = 'coindetailsearch_preferences';
        } else if (type === 'tmcd') {
          categories = ['coincolumndetailsearch_preferences'];
          titles = [
            { CDSearch_with_black: !Value },
            { CDSearch_with_gray: !Value },
            { CDSearch_with_white: !Value },
            { CDSearch_with_yellow: !Value },
            { CDSearch_with_orange: Value },
            { CDSearch_with_red: Value },
          ];
          selectedCategory = 'coincolumndetailsearch_preferences';
        }
        break;
      case '#d24242':
        if (type === 'main') {
          categories = [
            'Non_Active_Curated_Events',
            'White_Colored_Events',
            'Gray_Colored_Events',
            'Yellow_Colored_Events',
            'Red_Colored_Events',
          ];
          titles = [
            { Show_NACE: !Value },
            { Show_White: !Value },
            { Show_Gray: !Value },
            { Show_Yellow: !Value },
            { Red_Colored_Events: Value },
          ];
          selectedCategory = 'Red_Colored_Events';
        } else if (type === 'search') {
          categories = ['search_preferences'];
          titles = [
            { Search_with_black: !Value },
            { Search_with_gray: !Value },
            { Search_with_yellow: !Value },
            { Search_with_orange: !Value },
            { Search_with_red: Value },
          ];
          selectedCategory = 'search_preferences';
        } else if (type === 'tmd') {
          categories = ['coindetailsearch_preferences'];
          titles = [
            { CDSearch_with_black: !Value },
            { CDSearch_with_gray: !Value },
            { CDSearch_with_yellow: !Value },
            { CDSearch_with_orange: !Value },
            { CDSearch_with_red: Value },
          ];
          selectedCategory = 'coindetailsearch_preferences';
        } else if (type === 'tmcd') {
          categories = ['coincolumndetailsearch_preferences'];
          titles = [
            { CDSearch_with_black: !Value },
            { CDSearch_with_gray: !Value },
            { CDSearch_with_white: !Value },
            { CDSearch_with_yellow: !Value },
            { CDSearch_with_orange: !Value },
            { CDSearch_with_red: Value },
          ];
          selectedCategory = 'coincolumndetailsearch_preferences';
        }
        break;
      default:
        break;
    }
  }
  return { categories, titles, selectedCategory };
};

const UpdateSavedSearchColorPreference = (savedDetails, updateKey, value) => {
  savedDetails[`${updateKey}`] = updateKey !== 'withRed' ? value : true;
  switch (updateKey) {
    case 'withBlack':
      if (value) {
        savedDetails['withCurated'] = value;
        savedDetails['withYellow'] = value;
        savedDetails['withOrange'] = value;
        savedDetails['withRed'] = value;
      }
      break;
    case 'withCurated':
      if (value) {
        savedDetails['withYellow'] = value;
        savedDetails['withOrange'] = value;
        savedDetails['withRed'] = value;
      } else {
        savedDetails['withBlack'] = value;
      }
      break;
    case 'withYellow':
      if (value) {
        savedDetails['withOrange'] = value;
        savedDetails['withRed'] = value;
      } else {
        savedDetails['withBlack'] = value;
        savedDetails['withCurated'] = value;
      }
      break;
    case 'withOrange':
      if (value) {
        savedDetails['withRed'] = value;
      } else {
        savedDetails['withBlack'] = value;
        savedDetails['withCurated'] = value;
        savedDetails['withYellow'] = value;
      }
      break;
    case 'withRed':
      if (!value) {
        savedDetails['withBlack'] = value;
        savedDetails['withCurated'] = value;
        savedDetails['withYellow'] = value;
        savedDetails['withOrange'] = value;
      }
      break;
    default:
      break;
  }
  return savedDetails;
};
const UpdateSavedSearchColorPreference_NewLogic = (savedDetails, updateKey, value) => {

  switch (updateKey) {
    case 'withYellow':
      savedDetails['withBlack'] = false;
      savedDetails['withCurated'] = false;
      savedDetails['withWhite'] = false;
      savedDetails[`${updateKey}`] = value;
      savedDetails['withOrange'] = true;
      savedDetails['withRed'] = true;

      break;
    case 'withOrange':
      savedDetails['withBlack'] = false;
      savedDetails['withCurated'] = false;
      savedDetails['withWhite'] = false;
      savedDetails['withYellow'] = false;
      savedDetails[`${updateKey}`] = value;
      savedDetails['withRed'] = true;

      break;
    case 'withRed':
      savedDetails['withBlack'] = false;
      savedDetails['withCurated'] = false;
      savedDetails['withWhite'] = false;
      savedDetails['withYellow'] = false;
      savedDetails['withOrange'] = false;
      savedDetails[`${updateKey}`] = true;

      break;
    default:
      break;
  }
  return savedDetails;
}
const UpdateSavedSearchColorPreferenceForSlider = (savedDetails, updateKey, value) => {
  savedDetails[`${updateKey}`] = true;
  switch (updateKey) {
    case 'withBlack':
      savedDetails['withCurated'] = true;
      savedDetails['withWhite'] = true;
      savedDetails['withYellow'] = true;
      savedDetails['withOrange'] = true;
      savedDetails['withRed'] = true;

      break;
    case 'withCurated':
      savedDetails['withBlack'] = false;
      savedDetails['withWhite'] = true;
      savedDetails['withYellow'] = true;
      savedDetails['withOrange'] = true;
      savedDetails['withRed'] = true;

      break;
    case 'withWhite':
      savedDetails['withBlack'] = false;
      savedDetails['withCurated'] = false;
      savedDetails['withYellow'] = true;
      savedDetails['withOrange'] = true;
      savedDetails['withRed'] = true;

      break;
    case 'withYellow':
      savedDetails['withBlack'] = false;
      savedDetails['withCurated'] = false;
      savedDetails['withWhite'] = false;
      savedDetails['withOrange'] = true;
      savedDetails['withRed'] = true;

      break;
    case 'withOrange':
      savedDetails['withBlack'] = false;
      savedDetails['withCurated'] = false;
      savedDetails['withWhite'] = false;
      savedDetails['withYellow'] = false;
      savedDetails['withRed'] = true;

      break;
    case 'withRed':
      savedDetails['withBlack'] = false;
      savedDetails['withCurated'] = false;
      savedDetails['withWhite'] = false;
      savedDetails['withYellow'] = false;
      savedDetails['withOrange'] = false;

      break;
    default:
      break;
  }
  return savedDetails;
};

const CreateColumnOrderString = (orderdata) => {
  let CoStr = ``;
  if (orderdata.length) {
    CoStr = `${CoStr}columnorderpreference--`;
    orderdata.forEach((data, i) => {
      if (Object.keys(data).length) {
        let contentStrArr = [];
        let contentStr = '';
        Object.keys(data).map((key, ln) => {
          contentStrArr.push(`${key}__${data[key]}`)
        })
        contentStr = contentStrArr.join('||');
        CoStr = `${CoStr}${contentStr}??`
      }
    })
  }
  return CoStr;
}

const CreateColumnOrderData = (orderstring) => {
  let prefArr = [];
  if (typeof orderstring !== 'undefined' && orderstring !== '') {
    let prefObjStr = orderstring.split('--')[1]
    let eachordersetting = prefObjStr.split('??').filter(itm => (itm))
    eachordersetting.forEach((order, i) => {
      let prefData = {};
      let settings = order.split('||');
      settings.forEach(elem => {
        prefData[elem.split('__')[0]] = elem.split('__')[1]
      })
      prefArr.push(prefData);
    })
  }
  return prefArr;
}

const CleanupSavedSearchColumnOrder = (columnOrder, SearchSaveData) => {
  // console.log(columnOrder, SearchSaveData)
  let savedsearchcleanupdata = [];

  // let savedsearchcleanupdata = [];
  let savedsearchcleanupids = [];
  let columnordercleanupindex = [];
  let Searchids = [];
  if (Object.keys(SearchSaveData).length) {
    Searchids = Object.keys(SearchSaveData);
  }
  // console.log(Searchids)

  Searchids.forEach((id, i) => {
    let InOrderList = false;
    // console.log('checking saved searched mismatch')
    columnOrder.forEach((el, y) => {
      // console.log(id, el.UserSavedDataID)
      if (typeof el.UserSavedDataID !== 'undefined' && id === el.UserSavedDataID) {
        InOrderList = true;
      }
    })
    if (!InOrderList) {
      savedsearchcleanupdata.push(SearchSaveData[id])
      savedsearchcleanupids.push(id)
    }
  })
  // console.log(savedsearchcleanupdata, savedsearchcleanupids)

  columnOrder.forEach((el, y) => {
    let InSavedsearch = false;
    Searchids.forEach((id, i) => {
      if (typeof el.UserSavedDataID !== 'undefined' && id === el.UserSavedDataID) {
        InSavedsearch = true;
      }
    })
    if (!InSavedsearch && typeof el.UserSavedDataID !== 'undefined') {
      columnordercleanupindex.push(y)
    }
  })
  // console.log(columnordercleanupindex)

  if (savedsearchcleanupids.length) {
    savedsearchcleanupids.forEach(id => {
      delete SearchSaveData[id];
    })
  }
  if (columnordercleanupindex.length) {
    columnordercleanupindex.forEach(index => {
      columnOrder.splice(index, 1);
    })
  }
  // console.log(SearchSaveData, columnOrder)
  return {
    columnOrder,
    SearchSaveData,
    savedsearchcleanupdata
  }

}

export const PreferenceHandler = {
  UpdateUserPreferences,
  CreatePreferencesStrings,
  GetCategoriesAndTitles,
  GetCategoriesAndTitlesForSlider,
  GetCategoriesAndTitlesForMagnitudePreference_Iframe,
  GetCategoriesAndTitlesForMagnitudePreference_Coindetailcolumn,
  UpdateSavedSearchColorPreference,
  UpdateSavedSearchColorPreference_NewLogic,
  UpdateSavedSearchColorPreferenceForSlider,
  CreateColumnOrderString,
  CreateColumnOrderData,
  CleanupSavedSearchColumnOrder
};
