import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { APPPATH } from '../../../../modules/helper.module';
import * as appSettingsActionCreators from '../../../../actions/settings.action';
import Changepassword from '../../../../screens/changepassword';

class ResetPassword extends Component {
    constructor(props) {
        super();
        this.state = {
          _this: this,
        }
    }
//
    render() {
        return(
            <div className="watchlist-content trd-window-brdr justify-content-center text-center">
                <div className="btn tradeWindowClose text-white nopad marg-0" onClick={() => {
                    if(this.props.signature === 'Auth') {
                        this.props.finishInitialChangePass()
                    } else {
                        this.props.modalClose()
                    }
                }}>
                    <span className="pad10">
                    <img className="tradeWindowClose-closeicon" src={`${APPPATH.ImageUrl}/button-close.svg`} alt="" />
                    </span>
                </div>
                <Changepassword {...this.props} userData={this.props.userData} initialReset={true} />
                
            </div>
                
        )
    }
}

const mapStateToProps = (state) => {
    return {
      changePassword: state.settings.changePassword
    }
  }
  
  const mapDispatchToProps = {
    ...appSettingsActionCreators
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
