import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import StoryResource from './story_resource';
// import StoryElapsedTime from './story_elapsedtime';
// import LikeDislike from './story_likedislike';
// import StoryMessage from './story_message';
// import MuteUnmute from './muteunmute';
import * as moment from 'moment';
import { APPPATH, NotifyFeedsHandler } from '../../modules/helper.module';

class StoryToggleBar extends Component {
  constructor(props) {
    super();
    let LocationParamString = props.story.Location

    this.state = {
      viewSetTo: 'SecurityDetails',
      securitiesToolTip: false,
      feedsToolTip: false,
      parentColWidth: '',
      TLPopup: false,
      eventTimeActive: false,
    };
    this.eventTime = React.createRef();
    this.EventSentiment = React.createRef();
  }
  componentWillMount() {
    this.setToggleBarDom();
  }

  componentWillReceiveProps(nextProps) {
    this.setToggleBarDom(nextProps);
  }
  setToggleBarDom = (nextProps = false) => {
    this.setState({
      ...this.state,
      parentColWidth: nextProps ? nextProps.TDeckColWidth : this.props.TDeckColWidth,
    });
  };
  toggleDetails = (e) => {
    let val = e.currentTarget.dataset.setto;

    let newVal;
    if (val === 'FeedDetails') {
      newVal = 'SecurityDetails';
      window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
      window.Mixpanel.MixpanelProps.EventId = this.props.story.FeedId;
      window.Mixpanel.actions.identify(this.props.userData.username);
      window.Mixpanel.actions.track('Clicked Securities', window.Mixpanel.MixpanelProps).then((data) => {
        window.Mixpanel.actions.people.set({
          UserName: this.props.userData.username,
        });
        window.Mixpanel.actions.resetProp('EventId');
      });
    } else if (val === 'SecurityDetails') {
      // console.log(this.props.story)
      newVal = 'FeedDetails';
      window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
      window.Mixpanel.MixpanelProps.EventId = this.props.story.FeedId;
      window.Mixpanel.actions.identify(this.props.userData.username);
      window.Mixpanel.actions.track('Clicked Feeds', window.Mixpanel.MixpanelProps).then((data) => {
        window.Mixpanel.actions.people.set({
          UserName: this.props.userData.username,
        });
        window.Mixpanel.actions.resetProp('EventId');
      });
    }
    this.setState({ ...this.state, viewSetTo: newVal }, () => {
      this.props.toggleDet(this.state.viewSetTo);
    });
  };

  toggleSecuritiesToolTip = (showState) => {
    this.setState({
      ...this.state,
      securitiesToolTip: showState,
    });
  };

  toggleFeedsToolTip = (showState) => {
    this.setState({
      ...this.state,
      feedsToolTip: showState,
    });
  };

  setTimeAgoFromNow = (story) => {
    const { RootChangeLogList, FeedTime, LastUpdateTime } = story;
    let alertTime;
    let alertType;
    let EventTime;
    if (RootChangeLogList.length) {
      alertTime = RootChangeLogList[RootChangeLogList.length - 1].RootChangeLogTime;
      switch (RootChangeLogList[RootChangeLogList.length - 1].UpdateTypeCategory.toLowerCase()) {
        case 'activate':
          alertType = 'Event Raised';
          break;
        case 'detected':
          alertType = 'Signal Raised';
          break;
        case 'pre trade activate':
          alertType = 'Pre trade event confirmed';
          break;
        case 'details level':
          alertType = 'Integrity Updated';
          break;
        case 'event type':
          alertType = 'Event Type Updated';
          break;
        case 'attributes':
          alertType = 'Topics Updated';
          break;
        case 'location':
          alertType = 'Location Updated';
          break;
        case 'magnitude':
          alertType = 'Magnitude Updated';
          break;
        default:
          alertType = 'Major Update';
          break;
      }
    } else {
      if (alertType === 'Updated') {
        alertType = 'Major Update';
      } else {
        alertType = 'First Feed';
      }
    }
    if (alertType === 'First Feed') {
      EventTime = moment(FeedTime);
    } else {
      if (alertTime) {
        EventTime = moment(alertTime);
      } else {
        EventTime = moment(LastUpdateTime);
      }
    }
    // console.log(EventTime.fromNow(true))
    let TimeChar = EventTime.fromNow(true).split(' ')[0][0] === 'a' ? 1 : EventTime.fromNow(true).split(' ')[0];
    let FromChar = `${EventTime.fromNow(true).split(' ')[1][0]}${EventTime.fromNow(true).split(' ')[1][1]}`;
    // if(FromChar === 'we') { FromChar = 'wk' };
    // console.log(TimeChar.toString(), FromChar.toString())
    if (FromChar === 'we') {
      FromChar = 'w';
    }
    if (FromChar === 'da') {
      FromChar = 'd';
    }
    if (FromChar === 'ho') {
      FromChar = 'h';
    }
    if (FromChar === 'ye') {
      FromChar = 'y';
    }
    if (FromChar === 'mi') {
      FromChar = 'm';
    }
    let EventTimeAgo = `${TimeChar}${FromChar}`;
    // console.log(EventTimeAgo)
    if (EventTimeAgo === '1fe') {
      EventTimeAgo = '1m';
    }
    // console.log(EventTimeAgo)
    return EventTimeAgo;
  };

  timeLine = (e) => {
    e.preventDefault();
    let { offsetMins } = NotifyFeedsHandler.TimeZoneOffsetSettings();
    // console.log(this.eventTime.current.getBoundingClientRect())
    let ContainerRect = this.eventTime.current.getBoundingClientRect();
    let mousePosition = {
      left: ContainerRect.left,
      top: ContainerRect.bottom + 10,
    };
    this.props.togglePopup(false);
    let TLPopup = !this.state.TLPopup;
    let eventTimeActive = !this.state.eventTimeActive;
    let updateList;
    let changesArr = [];
    // const { RootChangeLogList, StoryFeedTime, StoryLastFeedsUpdateTime } = this.props;
    const { RootChangeLogList, FeedTime, LastUpdateTime, Reports, Resources } = this.props.story;
    // console.log(moment(StoryFeedTime), moment(StoryLastFeedsUpdateTime))

    for (let i = RootChangeLogList.length - 1; i >= 0; i--) {
      changesArr.push(RootChangeLogList[i]);
    }

    if (Reports > 1) {
      let lastlog = {
        RootChangeLogTime: LastUpdateTime,
        UpdateType: '',
        UpdateTypeCategory: 'Last Feed',
      };
      changesArr.unshift(lastlog);
    }

    let newChangesArr = changesArr.sort((a, b) => {
      return moment(b.RootChangeLogTime) - moment(a.RootChangeLogTime);
    });

    updateList = newChangesArr.map((item, i) => {
      let updateType = '';
      switch (item.UpdateTypeCategory.toLowerCase()) {
        case 'activate':
          updateType = 'Event Raised';
          break;
        case 'detected':
          updateType = 'Signal Raised';
          break;
        case 'pre trade activate':
          updateType = 'Pre trade event confirmed';
          break;
        case 'details level':
          updateType = 'Integrity Updated';
          break;
        case 'event type':
          updateType = 'Event Type Updated';
          break;
        case 'attributes':
          updateType = 'Topics Updated';
          break;
        case 'location':
          updateType = 'Location Updated';
          break;
        case 'magnitude':
          updateType = 'Magnitude Updated';
          break;
        case 'last feed':
          updateType = 'Last Feed';
          break;
        default:
          updateType = 'Major Update';
          break;
      }
      return (
        <li key={`${i}timeline`}>
          {i === 0 ? (
            <img src={`${APPPATH.ImageUrl}/fullcircle.png`} className="timelineIndicator1" alt="CrowdSense.ai" />
          ) : (
            <span className="timelineIndicator" alt="CrowdSense.ai">
              {changesArr.length - i}
            </span>
          )}
          <label>{updateType}</label>
          {moment.utc(item.RootChangeLogTime).utcOffset(offsetMins).format('DD/MM/YYYY hh:mmA')}
        </li>
      );
    });
    let content = (
      <Row className={`marg-0`}>
        <Col xs={12} sm={12} md={12} xl={12} lg={12} className="tipHead">
          Event Timeline
        </Col>
        <Col xs={12} sm={12} md={12} xl={12} lg={12} className="tipItem">
          <ul className={`timeline ${Reports > 1 ? '' : 'timeline-without-last-feed'}`}>
            {updateList}
            <li>
              <img src={`${APPPATH.ImageUrl}/emptycircle.png`} className="timelineIndicator1" alt="CrowdSense.ai" />
              <label>First Feed</label>
              {moment.utc(FeedTime).utcOffset(offsetMins).format('DD/MM/YYYY hh:mmA')}
            </li>
          </ul>
        </Col>
      </Row>
    );
    let containerClass = 'time-tooltipMenu';
    this.props.togglePopup(TLPopup, content, mousePosition, containerClass);
    this.setState({
      ...this.state,
      TLPopup,
      eventTimeActive,
    });
  };

  ExtractSemtiment_Source = (story, type = 'Sentiment') => {
    let LocationString = story.Location
    if(LocationString !== null) {
      let items = LocationString.split(';')
      let SentimentTotal = null;
      let Sources = [];
      if(items.length) {
        items.forEach((el, i) => {
          // console.log(el)
          let key = el.split(':')[0]
          let value = el.split(':')[1]
          if(key === 'SentimentTotal') {
            SentimentTotal = value
          } else if(key === 'Source') {
            Sources = value.split(',')
          }
        })
      }
      if(type === 'Sentiment') {
        if(typeof SentimentTotal === 'undefined' || isNaN(SentimentTotal)) {
          SentimentTotal = null
        }
        if(SentimentTotal !== null) {
          return parseFloat(SentimentTotal);
        } else {
          return SentimentTotal;
        }
        
      } else if(type === 'Source') {
        return Sources;
      }
      // console.log(Sources, SentimentTotal)
      // return { Source : Sources, Sentiment: SentimentTotal }
    }
    

  }

  render() {
    // const { parentColWidth  } = this.state
    const { story } = this.props;
    // let HdlineCharLength = story.HeadlineText && story.HeadlineText.length > 0 ? story.HeadlineText.length : 0;
    // let LocationParamString = story.Location
    // console.log(LocationParamString)
    // let Sentiment = this.ExtractSemtiment_Source(story)//let { Source , Sentiment } = 
    // let SentimentIndicator = ''
    // // console.log(Sentiment)
    // if(Sentiment !== null && typeof Sentiment !== 'undefined' && !isNaN(Sentiment) && parseInt(story.MagnitudeRate) > 0) {
    //   if(Sentiment <= 3.5) {
    //     SentimentIndicator = 'neg_sentiment.png'
    //   } else if(Sentiment >= 6.5) {
    //     SentimentIndicator = 'pos_semtiment.png'
    //   }
    // }
    // const EventTimeAgo = `${this.setTimeAgoFromNow(story)}`;
    // const xssmModeStyle = document.documentElement.clientWidth > 767 ? parentColWidth <= 632 ? (
    //   {'display' : 'flex'}
    // ) : (
    //   {'display' : 'none'}
    // ) : ({})
    // const lgmdModeStyle = document.documentElement.clientWidth > 767 ? parentColWidth <= 632 ? (
    //   {'display' : 'none'}
    // ) : (
    //   {'display' : 'unset'}
    // ) : ({})
    // console.log(this.props.colLength)
    return (
      <div
        className="nopad justify-content-center text-center"
        // style={{ width: !this.props.tabmode && this.props.colLength === 1 ? '50%' : '80%' }}
      >
        <div className="marginTopX">
          <StoryResource
            {...story}
            EventReadStatusMain={this.props.EventReadStatus}
            storyClicked={this.props.storyClicked}
            togglePopup={this.props.togglePopup}
          />
        </div>
        {/* <div
          style={{ position: 'relative' }}
          className={`text-sigbarTime fs11 ${HdlineCharLength > 65 ? 'margt2p' : ''} pointer ${
            this.state.eventTimeActive ? 'active' : ''
          }`}
          ref={this.eventTime}
          onMouseEnter={(e) => {
            let ToolTipStyleData = {
              Arrow: {
                marginLeft: '15px',
              },
              ToolTip: {
                align: 'left',
              },
            };
            this.props.toggleOverlay(
              true,
              this.eventTime,
              'Click to see the event changes over time in the system',
              ToolTipStyleData
            );
          }}
          onMouseLeave={(e) => {
            this.props.toggleOverlay(false);
          }}
          onClick={(e) => {
            this.props.toggleOverlay(false);
            this.timeLine(e);
          }}
        >
          {EventTimeAgo}
        </div> */}
        {/* {
          Sentiment !== null && typeof Sentiment !== 'undefined' && !isNaN(Sentiment) && SentimentIndicator !== '' ? (
            <div 
              ref={this.EventSentiment} 
              onMouseEnter={(e) => {
                let ToolTipStyleData = {
                  Arrow: {
                    marginLeft: '13px',
                  },
                  ToolTip: {
                    align: 'left',
                  },
                };
                this.props.toggleOverlay(
                  true,
                  this.EventSentiment,
                  SentimentIndicator === 'neg_sentiment.png' ? 'Negative impact potential' : SentimentIndicator === 'pos_semtiment.png' ? 'Positive impact potential' : '',
                  ToolTipStyleData
                );
              }}
              onMouseLeave={(e) => {
                this.props.toggleOverlay(false);
              }}
              onClick={(e) => {
                this.props.toggleOverlay(false);
                // this.timeLine(e);
              }}
            >
              <img src={`${APPPATH.ImageUrl+'/'+SentimentIndicator} `} className="pointer" alt="CrowdSense.ai" style={{width: '20px'}} onClick={() => ('')} />
            </div>
          ) : ('')
        } */}
        
      </div>
    );
  }
}

export default StoryToggleBar;
