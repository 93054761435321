import React, { Component } from 'react'; 
import { connect } from 'react-redux';
import * as filtersActionCreators from '../../actions/filters.action';
import LocationListItem from './location-list-item';

class LocationSetting extends Component {
  constructor() {
    super();
    this.state= {
      parentText: '',
      parentActive: false,
      locationHeirarchialState: [],
      locationFilter:[]
    }
  }

  componentWillMount() {
    this.setState({
      ...this.state,
      parentActive: this.props.active,
      parentText: this.props.text,
      locationFilter: this.props.locationFilter
    }, () => {
      // if(!this.props.locationContinents.length) { 
      //   this.props.getLocationContinents();
      // }      
    })
    
  }

  componentWillReceiveProps(nextProps) {
    if(this.props.active !== nextProps.active) {
      this.setState({
        ...this.state,
        parentActive: nextProps.active,
        parentText: nextProps.text,
        locationFilter: nextProps.locationFilter
      },() => {
        console.log(nextProps.locationContinents)
        if(nextProps.locationContinents.length) {
          let LocationHeirarchialState = [];
          let ContinentState = [];
          let filters = this.state.locationFilter;
          nextProps.locationContinents.forEach(locCont => {
            if(filters.length && filters.indexOf(locCont.name) > -1) {
              locCont.selected = true;
            }

            ContinentState.push(locCont.name);
          });
          LocationHeirarchialState.push(ContinentState);
          this.setState({
            ...this.state,
            locationHeirarchialState: LocationHeirarchialState
          })
        }
      })
    }
  }
  getLocationByContinent = (continent) => {
    if(!this.props.continentFilteredLocations[continent] || !this.props.continentFilteredLocations[continent].length) {
      this.props.getLocationByContinents(continent);
    }
  }
  locationListManage = (activeState, key, groupindex, type) => { 
    let LS = this.state.locationHeirarchialState;
    let newLS = [];
    if(activeState) {
      if(LS[groupindex].indexOf(key) > -1) {
        for(let i = 0; i < LS.length; i++) {
          if(i === groupindex) {
            newLS[i] = [key];
          } else if(i < groupindex) {
            newLS[i] = LS[i];
          }
        }
        if(type === 'parent') {
          if(this.props.continentFilteredLocations[key] && this.props.continentFilteredLocations[key].length) {
            let locList = [];
            this.props.continentFilteredLocations[key].forEach(item => {
              
              locList.push(item.name)
            })
            newLS.push(locList);
          }
        }

        // console.log(newLS)
        
      }
      this.setState({
        ...this.state,
        locationHeirarchialState: newLS
      })  
    } else {
      if(LS[groupindex].indexOf(key) > -1) {
        for(let i = 0; i < LS.length; i++) {
          if(i === groupindex) {
            let ContinentState = [];
            this.props.locationContinents.forEach(locCont => {
              ContinentState.push(locCont.name);
            });
            newLS[i] = ContinentState;
          } else if(i < groupindex) {
            newLS[i] = LS[i];
          }
        }
      }
      this.setState({
        ...this.state,
        locationHeirarchialState: newLS
      }) 
    }
    
  }
  RecursiveChildren = (childLocArr, response = []) => {
    
    if(childLocArr.length) {
      childLocArr.forEach(child => {
        response.push(child.name)
        if(this.props.continentFilteredLocations[`${child.name}`] && this.props.continentFilteredLocations[`${child.name}`].length) {
          response = this.RecursiveChildren(this.props.continentFilteredLocations[`${child.name}`], response)
        }
      })
    }
    return response;
  }
  RecursiveParent = (parent, text, stateFilter, response = []) => {
    let siblingCount = 0; let siblingSelectedCount = 0; let grandParent = '';
    if(this.props.continentFilteredLocations[parent] && this.props.continentFilteredLocations[parent].length) {
      this.props.continentFilteredLocations[parent].forEach(item => {
        if(item.name === text) {
          // item.selected = stateFilter;
          response.push(item.name)
          siblingCount = this.props.continentFilteredLocations[parent].length-1
          this.props.continentFilteredLocations[parent].forEach(sibItem => {
            if(sibItem.name !== item.name && sibItem.selected) {
              siblingSelectedCount++;
            }
          })
          if(siblingCount === siblingSelectedCount) {
            if(stateFilter) {
              response.push(parent)
              Object.keys(this.props.continentFilteredLocations).map(loc => {
                this.props.continentFilteredLocations[loc].forEach(reg => {
                  if(reg.name === parent) {
                    // reg.selected = stateFilter
                    // response.push(reg.name)
                    if(reg.parent !== '') {
                      grandParent = reg.parent
                    }
                  }
                })
                return null;
              })
              if(grandParent !== '') {
                response = this.RecursiveParent(grandParent, parent, stateFilter, response)
              } 
            }
          } else if(!siblingSelectedCount) {
            response.push(parent)
            Object.keys(this.props.continentFilteredLocations).map(loc => {
              this.props.continentFilteredLocations[loc].forEach(reg => {
                if(reg.name === parent) {
                  // reg.selected = stateFilter
                  // response.push(reg.name)
                  if(reg.parent !== '') {
                    grandParent = reg.parent
                  }
                }
              })
              return null;
            })
            if(grandParent !== '') {
              response = this.RecursiveParent(grandParent, parent, stateFilter, response)
            }
          }
        }
      })
    }
    return response;
  }

  RecursiveGenerateLF = (LF = [], parentloc = '') => {
    if(parentloc === '') {
      Object.keys(this.props.continentFilteredLocations).map(loc => {
        
        this.props.continentFilteredLocations[loc].forEach(reg => {
          if(reg.selected === true) { LF.push(reg.name) }
          if(this.props.continentFilteredLocations[reg] && this.props.continentFilteredLocations[reg].length) {
            LF = this.RecursiveGenerateLF(LF, reg.name)
          }
        })
        return null;
      })
    } else {
      // if(parentloc === loc) {
        this.props.continentFilteredLocations[parentloc].forEach(reg => {
          if(reg.selected === true) { LF.push(reg.name) }
          
        })
      // }
    }
    return LF;
  }
  manageFilter = (stateFilter, text, type, parent) => {
    let LF = [];
    

    if(type === 'children') {
      // console.log(this.props.locationContinents)
      // console.log(this.props.continentFilteredLocations)
      let affectedChildren = []; let affectedParents = [];
      if(this.props.continentFilteredLocations[text] && this.props.continentFilteredLocations[text].length) {
        affectedChildren = this.RecursiveChildren(this.props.continentFilteredLocations[text])
        if(affectedChildren.length) {
          Object.keys(this.props.continentFilteredLocations).map(loc => {
            this.props.continentFilteredLocations[loc].forEach(reg => {
              if(affectedChildren.indexOf(reg.name) > -1) {
                reg.selected = stateFilter
              }
            })
            return null;
          })
          return null;
        }
      } 
      affectedParents = this.RecursiveParent(parent, text, stateFilter, [])
      if(affectedParents.length) {
        Object.keys(this.props.continentFilteredLocations).map(loc => {
          this.props.continentFilteredLocations[loc].forEach(reg => {
            if(affectedParents.indexOf(reg.name) > -1) {
              reg.selected = stateFilter
            }
          })
          return null;
        })
        this.props.locationContinents.forEach(locCont =>{
          if(affectedParents.indexOf(locCont.name) > -1) {
            locCont.selected = stateFilter;
          }
        })
      }
      this.props.locationContinents.forEach(ele => {
        if(ele.selected === true) { LF.push(ele.name) }
      })
      LF = this.RecursiveGenerateLF(LF)
      
        
          

        // console.log(this.props.continentFilteredLocations, this.props.locationContinents)
        
        // console.log(LF)

    } else if(type === 'parent') {
      if(this.props.locationContinents.length) {
        this.props.locationContinents.forEach(ele => {
          if(ele.name === text) {
            ele.selected = stateFilter;
            let affectedChildren = []
            if(this.props.continentFilteredLocations[`${ele.name}`] && this.props.continentFilteredLocations[`${ele.name}`].length) {
              affectedChildren = this.RecursiveChildren(this.props.continentFilteredLocations[`${ele.name}`])
              // this.props.continentFilteredLocations[`${ele.name}`].forEach(elem => {
              //   elem.selected = stateFilter;
              // })
              console.log(affectedChildren);
              if(affectedChildren.length) {
                Object.keys(this.props.continentFilteredLocations).map(loc => {
                  // console.log(loc)
                  if(this.props.continentFilteredLocations[loc].length) {
                    this.props.continentFilteredLocations[loc].forEach(reg => {
                      if(affectedChildren.indexOf(reg.name) > -1) {
                        reg.selected = stateFilter
                      }
                    })
                  }
                  return null;
                })
              }

            }
          }

        })
      }
      this.props.locationContinents.forEach(ele => {
        
        if(ele.selected === true) { LF.push(ele.name) }
      })
      LF = this.RecursiveGenerateLF(LF)      
    }

    let data = {
      "UserName": this.props.userData.username,
      "FilterString": LF.join(';')
    }
    console.log(LF)
    this.props.updateLocationFilter(data)
    this.setState({
      ...this.state,
      locationFilter: LF
    })
  }
  render() {

    const { parentActive, parentText, locationHeirarchialState } = this.state;
    const { locationContinents, continentFilteredLocations } = this.props
    console.log(continentFilteredLocations)
    let locationList = [];
    if(parentText === 'Locations' && parentActive) {
      let grpind = 0;
      let selCont;
      locationHeirarchialState.forEach((eachGroup, groupIndex) => {

        let groupLocationList = []
        let locnt = 0;
        
        if(locationContinents.length) {

          
          locationContinents.forEach(locCont => {

            let indexchange = false;
            if(eachGroup.indexOf(locCont.name) > -1) {
              selCont = locCont.name;
              if(groupIndex !== grpind) { indexchange = true; grpind = groupIndex }

              groupLocationList[locnt] = <LocationListItem key={locCont.name}  text={locCont.name} type={locCont.type} selected={locCont.selected} parent={locCont.parent} CountriesByContinent={this.locationListManage} groupindex={groupIndex} akey={locCont.name} indexChange={indexchange} getLocationByContinent={this.getLocationByContinent} locations={continentFilteredLocations}  manageFilters={this.manageFilter} userData={this.props.userData} />

              locnt++;


            } else {
              
              if(continentFilteredLocations[locCont.name] && continentFilteredLocations[locCont.name].length) {
                continentFilteredLocations[locCont.name].forEach(locNme => {
                  // console.log(eachGroup)
                  indexchange = false;
                  if(eachGroup.indexOf(locNme.name) > -1 && locCont.name === selCont) {
                    if(groupIndex !== grpind) { indexchange = true; grpind = groupIndex }
                    groupLocationList[locnt] = <LocationListItem key={locNme.name}  text={locNme.name} type={locNme.type} selected={locNme.selected}  parent={locNme.parent} CountriesByContinent={this.locationListManage} groupindex={groupIndex} akey={locNme.name} indexChange={indexchange} getLocationByContinent={this.getLocationByContinent} locations={continentFilteredLocations}  manageFilters={this.manageFilter} userData={this.props.userData} />
                    
                    locnt++;
                  }
                })
              }
            }
          })
        }
        
        locationList.push(groupLocationList);
       
      })
    }

    
    return(
      <div className={`securityItem`}>
        { parentText === 'Locations' ? locationList : '' }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.settings.userData,
    locationContinents: state.filters.locationContinents,
    continentFilteredLocations: state.filters.continentFilteredLocations,
    // locationFilteredRegions: state.filters.locationFilteredRegions,
    locationFilter: state.filters.locationFilter
  }
}

export default connect(mapStateToProps, filtersActionCreators)(LocationSetting)