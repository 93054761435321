import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';

const TweetDeckFiller = ({ colRect }) => {
    let colstyle = {
        width: colRect['width'],
        left: !isNaN(parseInt(colRect['left'].replace('px', ''))) ? colRect['left'] : '',
        // top: props.colRect['top'].replace('px', ''),
        zIndex: '1100 !important',
    };
    return (
        <div className={`TDeck-window filler`} style={{...colstyle,padding: 1}} ></div>
    )
}

export default TweetDeckFiller