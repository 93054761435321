import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import EachSecurity from './security_each';
import { APPPATH, TrendingMentionsHelper } from '../../modules/helper.module';
import CorelTag from './coreltag';
import * as moment from 'moment';
import LikeEvent from './story_newLike';
import StoryResource from './story_resource';
import { isMobile } from 'react-device-detect';

class SecurityDetails extends Component {
  constructor(props) {
    super();
    this.state = {
      correlations: [],
      corelList: [],
      coreltaglimit: 0,
      direct: [],
      indirect: [],
      showDetails: false,
      crlmoretip: false,
      locations: [],
      FDPopup: false,
      tmOffset: props.tmOffset,
      nosecTags: 0,
    };
    this.corelTagSizes = [];
    this.corelsArr = [];
    this.crlcontainer = '';
    this.moreTags = '';
    this.eventTime = React.createRef();
    this.feedResourceWrap = React.createRef();
    this.OverlayTarget = React.createRef();
    this.OverlayTarget2 = React.createRef();
    this.OverlayTarget6 = React.createRef();
    this.srcttHandle = "";
    this.SourceOverlay = React.createRef();
    
  }
  componentWillMount() {
    const {
      storyID,
      correlationResults,
      Locations,
      preferencesLocationRelatedCountries,
      preferencesSecuritiesLine,
      Reports,
      RootChangeLogList,
      StoryFeedTime,
      StoryLastFeedsUpdateTime,
      TickerIconsList,
      storyState,
      UserReputationRate,
      EventType,
    } = this.props;
    this.setCorels(
      storyID,
      correlationResults,
      Locations,
      preferencesLocationRelatedCountries,
      preferencesSecuritiesLine,
      Reports,
      RootChangeLogList,
      StoryFeedTime,
      StoryLastFeedsUpdateTime,
      TickerIconsList,
      storyState,
      UserReputationRate,
      EventType
    );
  }

  componentDidMount() {
    if (this.corelsArr.length === this.state.corelList.length) {
      this.corelTagSizes = this.corelsArr.map((itm) => itm.clientWidth);
    }

    let limit = this.corelTagLimit(this.state.corelList);
    this.setState({
      ...this.state,
      coreltaglimit: limit,
    });
  }
  componentWillReceiveProps(nextProps) {
    const {
      storyID,
      correlationResults,
      Locations,
      preferencesLocationRelatedCountries,
      preferencesSecuritiesLine,
      Reports,
      RootChangeLogList,
      StoryFeedTime,
      StoryLastFeedsUpdateTime,
      TickerIconsList,
      storyState,
      UserReputationRate,
      EventType,
    } = nextProps;
    this.setCorels(
      storyID,
      correlationResults,
      Locations,
      preferencesLocationRelatedCountries,
      preferencesSecuritiesLine,
      Reports,
      RootChangeLogList,
      StoryFeedTime,
      StoryLastFeedsUpdateTime,
      TickerIconsList,
      storyState,
      UserReputationRate,
      EventType,
      true
    );
  }

  CreateGeoMarkers = (markersDataString = false) => {
    try {
      if (markersDataString && markersDataString !== '') {
        let markersData = markersDataString.split('~').map((eachData) => {
          let subStringStartIndex = eachData.trim().indexOf('(');
          let dataCoords = eachData
            .trim()
            .substring(subStringStartIndex + 1, eachData.trim().length - 1)
            .split(',')
            .map((coord) => coord.trim())
            .join(',');
          let dataTitle = eachData.trim().substr(0, subStringStartIndex).trim();
          return {
            coords: { lat: parseFloat(dataCoords.split(',')[0]), lng: parseFloat(dataCoords.split(',')[1]) },
            title: `${dataTitle}`,
          };
        });
        // console.log(markersData)
        return markersData;
      }
      return false;
    } catch (err) {
      console.log(err);
    }
  };

  setIcons = (corels, TickerIconsList) => {
    return new Promise((resolve) => {
      const promises = [];
      corels.map((itm, i) => {
        itm.Icon = itm.Symbol && TickerIconsList.indexOf(itm.Symbol.toLowerCase()) > -1 ? true : false;
        promises.push(itm);
        if (i === corels.length - 1) {
          resolve(promises);
        }
        return null;
      });
    });
  };

  setCorels = (
    storyID,
    corels,
    locations,
    preferencesLocationRelatedCountries,
    preferencesSecuritiesLine,
    Reports,
    RootChangeLogList,
    FeedTime,
    LastUpdateTime,
    TickerIconsList,
    storyState,
    UserReputationRate,
    EventType,
    calculateLimit = false
  ) => {
    // if(storyID === 49344334) {
    //   console.log(Reports, UserReputationRate)
    // }
    let correlations = corels;
    let tmpnewCorrelations =
      correlations !== null && typeof correlations !== 'undefined' ? this.handleCorrelations(correlations) : [];
    let newCorrelations = !tmpnewCorrelations.length ? [] : this.corelSpreadFilter(tmpnewCorrelations);
    let dircorel = [];
    let indircorel = [];
    let nacorel = [];
    let ActualCorrelations = [];
    let locationArr;
    if (newCorrelations.length) {
      newCorrelations.map((each) => {
        if (each.Impact.toLowerCase() === 'direct') {
          dircorel.push(each);
        }
        if (each.Impact.toLowerCase() === 'indirect') {
          indircorel.push(each);
        }
        if (each.Impact.toLowerCase() !== 'indirect' && !each.Impact.toLowerCase().includes('direct')) {
          nacorel.push(each);
        }
        return null;
      });

      ActualCorrelations = dircorel.concat(indircorel, nacorel);
    }

    let reportsStr =
      Math.abs(Reports) > 999
        ? Math.sign(Reports) * (Math.abs(Reports) / 1000).toFixed(1) + 'k'
        : Math.sign(Reports) * Math.abs(Reports);
    let nosecTags = 0;
    // if(storyID === 49344334) {
    //   console.log(reportsStr)
    //   console.log(`${Math.sign(Reports)} * ${Math.abs(Reports)} = ${Math.sign(Reports) * Math.abs(Reports)}`)
    //   console.log(!isNaN(parseInt(reportsStr)))
    //   console.log(parseInt(reportsStr) > 0)
    // }
    if (!isNaN(parseInt(reportsStr)) && parseInt(reportsStr) > 0) {
      nosecTags = nosecTags + 1;
      ActualCorrelations = [
        {
          SecutiryType: reportsStr,
          Security: ``,
          Impact: 'feeds',
          Symbol: null,
          Link: null,
          Description: '',
        },
      ].concat(ActualCorrelations);
    }
    if (parseInt(UserReputationRate) > 0) {
      nosecTags = nosecTags + 1;
      ActualCorrelations = [
        {
          SecutiryType: `${TrendingMentionsHelper.abbreviateNumber(UserReputationRate)}`,
          Security: ``,
          Impact: 'usrReach',
          Symbol: null,
          Link: null,
          Description: '',
        },
      ].concat(ActualCorrelations);
    }

    if (EventType && !EventType.toLowerCase().includes('none')) {
      nosecTags = nosecTags + 1;
      ActualCorrelations = [
        {
          SecutiryType: `${EventType}`,
          // SecutiryType : `${alertType}`,
          time: ``,
          // time: `${EventTime}`,
          Security: ``,
          Impact: 'evttime',
          Symbol: null,
          Link: null,
          Description: '',
        },
      ].concat(ActualCorrelations);
    }
    // if(storyID === 49344334) {
    //   console.log(ActualCorrelations  )
    // }
    if (calculateLimit) {
      this.setState(
        {
          ...this.state,
          correlations: corels,
          corelList: ActualCorrelations,
          coreltaglimit: ActualCorrelations.length,
          direct: dircorel,
          indirect: indircorel,
          locations: locationArr,
          nosecTags,
        },
        () => {
          let limit = this.corelTagLimit(ActualCorrelations);
          this.setState({
            ...this.state,
            coreltaglimit: limit,
          });
        }
      );
    } else {
      this.setState({
        ...this.state,
        correlations: corels,
        corelList: ActualCorrelations,
        coreltaglimit: ActualCorrelations.length,
        direct: dircorel,
        indirect: indircorel,
        locations: locations,
        nosecTags,
      });
    }
  };

  corelTagLimit = (corels) => {
    let sizeDataOFTags = [];
    let containerWidth = 0;
    let limitedTagNumber = 0;
    let tottagWidth = 0;
    let colWidth = parseInt(this.props.colRect.width.replace('px', '').trim());
    // console.log(this.corelTagSizes)
    if (colWidth < 700) {
      for (let i = 0; i <= this.corelTagSizes.length; i++) {
        if (i > 0) {
          sizeDataOFTags.push(this.corelTagSizes[i]);
        }
      }
    } else {
      if (corels.length) {
        for (let i = 0; i <= this.corelTagSizes.length; i++) {
          if (this.corelTagSizes[i]) {
            sizeDataOFTags.push(this.corelTagSizes[i]);
          }
        }
      }
    }

    let sizeDataOFContainer = this.crlcontainer;
    containerWidth = sizeDataOFContainer && sizeDataOFContainer.clientWidth;
    let allowedTagWidth = containerWidth * (70 / 100);
    let rightMarginForEactTag = 3;
    for (let i = 0; i < sizeDataOFTags.length; i++) {
      if (tottagWidth < allowedTagWidth && tottagWidth + rightMarginForEactTag + sizeDataOFTags[i] < allowedTagWidth) {
        tottagWidth = tottagWidth + sizeDataOFTags[i] + rightMarginForEactTag;
      } else {
        limitedTagNumber = i;

        break;
      }
    }
    return 2;
  };

  handleCorrelations = (correlations, newCorels = []) => {
    // console.log(correlations)
    newCorels = correlations.length
      ? correlations
          .map((item) => {
            if (item.Security) {
              let secs = item.Security.split(';');
              if (secs.length > 1) {
                return this.recursedCorrelations(secs, item, 'Security', []);
              } else {
                return item;
              }
            } else {
              // let secs = item.SecutiryType ? item.SecutiryType.split(';') : [];
              // if(secs.length > 1) {
              //   return this.recursedCorrelations(secs, item, 'SecutiryType', []);
              // } else {
              //   return item;
              // }
            }
            return null;
          })
          .filter((itm) => itm)
      : [];

    // console.log(newCorels)

    return newCorels;
  };
  recursedCorrelations = (securityArr, correlationItem, type, response = []) => {
    for (let sec of securityArr) {
      if (sec) {
        let newItem = {
          SecutiryType: null,
          Security: '',
          Impact: '',
          Symbol: null,
          Link: null,
          Description: '',
          Icon: false,
        };
        newItem[`${type}`] = sec;
        newItem['Impact'] = correlationItem.Impact;
        newItem['Description'] = correlationItem.Description;
        if (type === 'Security') {
          newItem['SecutiryType'] = correlationItem.SecutiryType;
        }
        response.push(newItem);
      }
    }
    return response;
  };
  corelSpreadFilter = (tmp, resp = []) => {
    let filt = [];
    for (let row of tmp) {
      if (Array.isArray(row)) {
        for (let subRow of row) {
          if (subRow.Security && filt.indexOf(subRow.Security.trim().toLowerCase()) === -1) {
            resp.push(subRow);
            filt.push(subRow.Security.trim().toLowerCase());
          } else if (!subRow.Security) {
            if (subRow.SecutiryType && filt.indexOf(subRow.SecutiryType.trim().toLowerCase()) === -1) {
              resp.push(subRow);
              filt.push(subRow.SecutiryType.trim().toLowerCase());
            }
          }
        }
      } else {
        if (row.Security && filt.indexOf(row.Security.trim().toLowerCase()) === -1) {
          resp.push(row);
          filt.push(row.Security.trim().toLowerCase());
        } else if (!row.Security) {
          if (row.SecutiryType && filt.indexOf(row.SecutiryType.trim().toLowerCase()) === -1) {
            resp.push(row);
            filt.push(row.SecutiryType.trim().toLowerCase());
          }
        }
      }
    }
    return resp;
  };

  // Item.Icon

  tagIcon = (Item) => {
    // console.log(Item)
    // console.log(Item.Symbol === 'WETH' ? console.log(Item) : 'notFNK')

    let icon =
      Item.Impact !== '' && Item.Impact.toLowerCase().includes('direct') ? (
        Item.Symbol !== null ? (
          <span>
            <object
              className="timelineIndicator imground"
              data={`${APPPATH.CoinIconUrl}/${
                Item.Security !== null ? Item.Security.toLowerCase().trim().replace(/ /g, '_') : ''
              }.png`}
              type="image/png"
            >
              <img
                src={`${APPPATH.ImageUrl}/logo_hdr.png`}
                className="timelineIndicator imground"
                alt="CrowdSense.ai"
              />
            </object>
          </span>
        ) : (
          <span>
            <img
              src={`${APPPATH.ImageUrl}/circle_full.svg`}
              className="timelineIndicator imground"
              alt="CrowdSense.ai"
            />
          </span>
          // <span><img src={`${APPPATH.ImageUrl}/ss1icon.svg`} className="timelineIndicator " alt="CrowdSense.ai"  /></span>
        )
      ) : Item.Impact !== '' && Item.Impact.toLowerCase() === 'indirect' ? (
        <span>
          <img
            src={`${APPPATH.ImageUrl}/circle_half.svg`}
            className="timelineIndicator imground "
            alt="CrowdSense.ai"
          />
        </span>
      ) : // <span><img src={`${APPPATH.ImageUrl}/ss2icon.svg`} className="timelineIndicator " alt="CrowdSense.ai" /></span>
      Item.Impact !== '' && Item.Impact.toLowerCase() === 'location' ? (
        <span>
          <img
            src={`${APPPATH.ImageUrl}/locationsecicon.svg`}
            className="timelineIndicator imground"
            alt="CrowdSense.ai"
          />
        </span>
      ) : Item.Impact !== '' && Item.Impact.toLowerCase() === 'map' ? (
        <span>
          <img src={`${APPPATH.ImageUrl}/mapicon.png`} className="timelineIndicator imground" alt="CrowdSense.ai" />
        </span>
      ) : Item.Impact !== '' && Item.Impact.toLowerCase() === 'feeds' ? (
        <span
          onContextMenu={(e)=> {
            e.preventDefault();
            e.stopPropagation(); // not necessary in my case, could leave in case stopImmediateProp isn't available? 
            // e.stopImmediatePropagation();
            return false;
          }}
          className={`preventselect`}
          style={{ position: 'relative' }}
          ref={this.OverlayTarget}
          onMouseEnter={(e) => {
            if(!isMobile) {
              if(this.feedstimerhandle) {
                clearTimeout(this.feedstimerhandle);
              }
              this.feedstimerhandle = setTimeout((e) => {
                let arrowstyledata = {};
                let tooltipstyledata = {}
                let tiptext = '';
                if(this.props.lang && this.props.lang === 'ara') {
                  arrowstyledata.marginRight = '10px';
                  tooltipstyledata.align = 'center'
                  tiptext = "عدد المصادر التي تشير إلى حدث الذكاء الاصطناعي هذا"
                  tooltipstyledata.alignRight = true
                } else {
                  arrowstyledata.marginLeft = '10px';
                  tooltipstyledata.align = 'center';
                  if(this.props.lang && this.props.lang === 'spa') {
                    tiptext = "Número de fuentes que mencionan este evento de IA"
                  } else if(this.props.lang && this.props.lang === 'por') {
                    tiptext = "Número de fontes que mencionam este evento de IA"
                  } else if(this.props.lang && this.props.lang === 'jap') {
                    tiptext = 'このAIイベントに言及している情報源の数'
                  } else {
                    tiptext = 'Number of sources mentioning this AI event'
                  }
                }
                
                let ToolTipStyleData = {
                  Arrow: {...arrowstyledata},
                  ToolTip: {...tooltipstyledata},
                };

                this.props.toggleOverlay(true, this.OverlayTarget, tiptext, ToolTipStyleData);
              }, 1500)
            }
            
          }}
          onMouseLeave={(e) => {
            if(!isMobile) {
              if(this.feedstimerhandle) {
                clearTimeout(this.feedstimerhandle)
              }
              this.props.toggleOverlay(false);
            }
            
          }}
          onClick={(e) => {
            // e.preventDefault();
            e.stopPropagation();
            // console.log('touch start')
            // e.stopPropagation();
            if(isMobile) {
              // console.log('touch start')
              if(this.feedstimerhandle) {
                clearTimeout(this.feedstimerhandle);
              }
              // this.feedstimerhandle = setTimeout((e) => {
               
                let arrowstyledata = {};
                let tooltipstyledata = {}
                let tiptext = '';
                if(this.props.lang && this.props.lang === 'ara') {
                  arrowstyledata.marginRight = '10px';
                  tooltipstyledata.align = 'center'
                  tiptext = "عدد المصادر التي تشير إلى حدث الذكاء الاصطناعي هذا"
                  tooltipstyledata.alignRight = true
                } else {
                  arrowstyledata.marginLeft = '10px';
                  tooltipstyledata.align = 'center';
                  if(this.props.lang && this.props.lang === 'spa') {
                    tiptext = "Número de fuentes que mencionan este evento de IA"
                  } else if(this.props.lang && this.props.lang === 'por') {
                    tiptext = "Número de fontes que mencionam este evento de IA"
                  } else if(this.props.lang && this.props.lang === 'jap') {
                    tiptext = 'このAIイベントに言及している情報源の数'
                  } else {
                    tiptext = 'Number of sources mentioning this AI event'
                  }
                }
                
                let ToolTipStyleData = {
                  Arrow: {...arrowstyledata},
                  ToolTip: {...tooltipstyledata},
                };

                this.props.toggleOverlay(true, this.OverlayTarget, tiptext, ToolTipStyleData);
              // }, 300)
            }
            
            
           
          }}
          // onTouchEnd={(e) => {
          //   // console.log('touch end')
          //   // e.preventDefault();
          //   e.stopPropagation();
          //   if(this.feedstimerhandle) {
          //     clearTimeout(this.feedstimerhandle);
          //   }
          //   // console.log('touch end')
          // }}
        >
          <img src={`${APPPATH.ImageUrl}/icon-trending.svg`} className="timelineIndicator " alt="CrowdSense.ai" />
        </span>
      ) : Item.Impact !== '' && Item.Impact.toLowerCase() === 'usrreach' ? (
        <span
          onContextMenu={(e)=> {
            e.preventDefault();
            e.stopPropagation(); // not necessary in my case, could leave in case stopImmediateProp isn't available? 
            // e.stopImmediatePropagation();
            return false;
          }}
          className={`preventselect`}
          style={{ position: 'relative' }}
          ref={this.OverlayTarget6}
          onMouseEnter={(e) => {
            if(!isMobile) {
              if(this.followerstimerhandle) {
                clearTimeout(this.followerstimerhandle);
              }
              this.followerstimerhandle = setTimeout((e) => {
                let arrowstyledata = {};
                let tooltipstyledata = {}
                let tiptext = '';
                if(this.props.lang && this.props.lang === 'ara') {
                  arrowstyledata.marginRight = '10px';
                  tooltipstyledata.align = 'right'
                  tiptext = "إجمالي عدد المتابعين لمصادر أحداث الذكاء الاصطناعي"
                  // tooltipstyledata.alignRight = true
                } else {
                  arrowstyledata.marginLeft = '10px';
                  tooltipstyledata.align = 'center';
                  if(this.props.lang && this.props.lang === 'spa') {
                    tiptext = "Número total de seguidores de fuentes de eventos de IA"
                  } else if(this.props.lang && this.props.lang === 'por') {
                    tiptext = "Número total de seguidores para fontes de eventos de IA"
                  } else if(this.props.lang && this.props.lang === 'jap') {
                    tiptext = 'AIイベントソースのフォロワー総数'
                  } else {
                    tiptext = 'Total number of followers for AI event sources'
                  }
                }
                
                let ToolTipStyleData = {
                  Arrow: {...arrowstyledata},
                  ToolTip: {...tooltipstyledata},
                };
                
                this.props.toggleOverlay(true, this.OverlayTarget6, tiptext, ToolTipStyleData);
              }, 1500);
            }
            
          }}
          onMouseLeave={(e) => {
            if(!isMobile) {
              if(this.followerstimerhandle) {
                clearTimeout(this.followerstimerhandle)
              }
              this.props.toggleOverlay(false);
            }
            
          }}
          onClick={(e) => {
            // e.preventDefault();
            e.stopPropagation();
            // console.log('touch start')
            // e.stopPropagation();
            if(isMobile) {
              // console.log('touch start')
              if(this.followerstimerhandle) {
                clearTimeout(this.followerstimerhandle);
              }
              // this.followerstimerhandle = setTimeout((e) => {
               
                let arrowstyledata = {};
                let tooltipstyledata = {}
                let tiptext = '';
                if(this.props.lang && this.props.lang === 'ara') {
                  arrowstyledata.marginRight = '10px';
                  tooltipstyledata.align = 'right'
                  tiptext = "إجمالي عدد المتابعين لمصادر أحداث الذكاء الاصطناعي"
                  // tooltipstyledata.alignRight = true
                } else {
                  arrowstyledata.marginLeft = '10px';
                  tooltipstyledata.align = 'center';
                  if(this.props.lang && this.props.lang === 'spa') {
                    tiptext = "Número total de seguidores de fuentes de eventos de IA"
                  } else if(this.props.lang && this.props.lang === 'por') {
                    tiptext = "Número total de seguidores para fontes de eventos de IA"
                  } else if(this.props.lang && this.props.lang === 'jap') {
                    tiptext = 'AIイベントソースのフォロワー総数'
                  } else {
                    tiptext = 'Total number of followers for AI event sources'
                  }
                }
                
                let ToolTipStyleData = {
                  Arrow: {...arrowstyledata},
                  ToolTip: {...tooltipstyledata},
                };
                
                this.props.toggleOverlay(true, this.OverlayTarget6, tiptext, ToolTipStyleData);
              // }, 300)
            }
            
            
           
          }}
          // onTouchEnd={(e) => {
          //   // console.log('touch end')
          //   e.stopPropagation();
          //   if(this.followerstimerhandle) {
          //     clearTimeout(this.followerstimerhandle);
          //   }
          //   // console.log('touch end')
          // }}
        >
          <img src={`${APPPATH.ImageUrl}/icon-mentions.svg`} className="timelineIndicator " alt="CrowdSense.ai" />
        </span>
      ) : Item.Impact !== '' && Item.Impact.toLowerCase() === 'evttime' ? (
        ''
      ) : (
        ''
      );
    return icon;
  };

  tagContent = (item, action = false) => {
    // if(this.props.storyID === 49344334) {
    //   console.log(item)
    // }
    let content = item.Symbol ? (
      <span className="coreltext fs11">{item.Symbol}</span>
    ) : item.Security ? (
      item.Security.substring(item.Security.indexOf('(') + 1, item.Security.indexOf(')')) !== '' ? (
        <span className="coreltext fs11">
          {item.Security.substring(item.Security.indexOf('(') + 1, item.Security.indexOf(')'))}
        </span>
      ) : (
        <span className="coreltext fs11">{item.Security}</span>
      )
    ) : item.Impact === 'map' ? (
      ''
    ) : item.Impact === 'feeds' ? (
      <span
        className={`preventselect text-offWhitealertfeed ${this.props.lang && this.props.lang === 'ara' ? 'padr2' : 'padl2'}  pointer fs11 `}
        //onClick={(e) => this.feedResource(e, action)}
        ref={(el) => (this.feedResourceWrap = el)}
        onTouchStart={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {item.SecutiryType}{' '}
      </span>
    ) : item.Impact === 'evttime' ? (
      <span
        onContextMenu={(e)=> {
          e.preventDefault();
          e.stopPropagation(); // not necessary in my case, could leave in case stopImmediateProp isn't available? 
          // e.stopImmediatePropagation();
          return false;
        }}
        className={`pointer preventselect`}
        style={{ position: 'relative' }}
        ref={this.OverlayTarget2}
        onMouseEnter={(e) => {
          if(!isMobile) {
            if(this.EventCategorytimerhandle) {
              clearTimeout(this.EventCategorytimerhandle);
            }
            this.EventCategorytimerhandle = setTimeout((e) => {
              let arrowstyledata = {};
              let tooltipstyledata = {}
              let tiptext = '';
              if(this.props.lang && this.props.lang === 'ara') {
                arrowstyledata.marginRight = '20px';
                tooltipstyledata.align = 'right'
                tiptext = "فئة الحدث";
                // tooltipstyledata.alignRight = true
              } else {
                arrowstyledata.marginLeft = '20px';
                tooltipstyledata.align = 'left';
                if(this.props.lang && this.props.lang === 'spa') {
                  tiptext = "Categoría de evento"
                } else if(this.props.lang && this.props.lang === 'por') {
                  tiptext = "Categoria do Evento"
                } else if(this.props.lang && this.props.lang === 'jap') {
                  tiptext = 'イベントカテゴリー'
                }  else {
                  tiptext = 'Event category'
                }
              }
              
              let ToolTipStyleData = {
                Arrow: {...arrowstyledata},
                ToolTip: {...tooltipstyledata},
              };
              this.props.toggleOverlay(true, this.OverlayTarget2, tiptext, ToolTipStyleData);
                // if(this.props.lang && this.props.lang === 'spa') {
                //   tiptext = "Categoría de evento"
                // } else if(this.props.lang && this.props.lang === 'por') {
                //   tiptext = "Categoria do Evento"
                // } else if(this.props.lang && this.props.lang === 'jap') {
                //   tiptext = 'イベントカテゴリー'
                // } else if(this.props.lang && this.props.lang === 'ara') {
                //   tiptext = "فئة الحدث"
                // } else {
                //   tiptext = 'Event category'
                // }
                
            }, 1500)
          }
          
        }}
        onMouseLeave={(e) => {
          if(!isMobile) {
            if(this.EventCategorytimerhandle) {
              clearTimeout(this.EventCategorytimerhandle);
            }
            this.props.toggleOverlay(false);
          }
          
        }}
        onClick={(e) => {
          // e.preventDefault();
          e.stopPropagation();
          // console.log('touch start')
          // e.stopPropagation();
          if(isMobile) {
            // console.log('touch start')
            if(this.EventCategorytimerhandle) {
              clearTimeout(this.EventCategorytimerhandle);
            }
            // this.EventCategorytimerhandle = setTimeout((e) => {
              let arrowstyledata = {};
              let tooltipstyledata = {}
              let tiptext = '';
              if(this.props.lang && this.props.lang === 'ara') {
                arrowstyledata.marginRight = '20px';
                tooltipstyledata.align = 'right'
                tiptext = "فئة الحدث";
                // tooltipstyledata.alignRight = true
              } else {
                arrowstyledata.marginLeft = '20px';
                tooltipstyledata.align = 'left';
                if(this.props.lang && this.props.lang === 'spa') {
                  tiptext = "Categoría de evento"
                } else if(this.props.lang && this.props.lang === 'por') {
                  tiptext = "Categoria do Evento"
                } else if(this.props.lang && this.props.lang === 'jap') {
                  tiptext = 'イベントカテゴリー'
                }  else {
                  tiptext = 'Event category'
                }
              }
             
              let ToolTipStyleData = {
                Arrow: {...arrowstyledata},
                ToolTip: {...tooltipstyledata},
              };
              
              this.props.toggleOverlay(true, this.OverlayTarget2, tiptext, ToolTipStyleData);
            // }, 300)
          }
          
          
         
        }}
        // onTouchEnd={(e) => {
        //   // console.log('touch end')
        //   // e.preventDefault();
        //   e.stopPropagation();
        //   if(this.EventCategorytimerhandle) {
        //     clearTimeout(this.EventCategorytimerhandle);
        //   }
        //   // console.log('touch end')
        // }}
      >
        <span className="  textToUp nopad time-data-wrap  ">{item.SecutiryType} |</span>
        {/* <span className={`nopad time-data-wrap text-blue1`}>{item.time}</span> */}
      </span>
    ) : item.Impact === 'usrReach' ? (
      <span className={`pointer preventselect`}>
        <span 
          className={`  text-offWhitealertfeed nopad time-data-wrap ${this.props.lang && this.props.lang === 'ara' ? 'padr2' : 'padl2'}  `}
          onTouchStart={(e) => {
            // e.preventDefault();
            e.stopPropagation();
          }}
        >
          {item.SecutiryType} 
        </span>
      </span>
    ) : (
      '' // <span className="coreltext fssmsec">{item.SecutiryType}</span> onClick={this.timeLine} ref={(el) => this.eventTime = el}
    );

    return content;
  };
  
  feedResource = (e, action = false) => {
    e.preventDefault();
    if (action) {
      let ContainerRect = this.feedResourceWrap.getBoundingClientRect();
      console.log(ContainerRect, action);
      let mousePosition = {
        left: ContainerRect.left - 165,
        top: ContainerRect.bottom + 10,
      };
      this.props.togglePopup(false);
      const { Resources, Reports } = this.props;
      let FDPopup = !this.state.FDPopup;
      let content = (
        <Row className={`marg-0 pad10`}>
          <Col xs={12} sm={12} md={12} xl={12} lg={12} className="tipHead">
            Story Integrity
          </Col>
          <Col xs={6} sm={6} md={6} xl={6} lg={6} className="tipItem bg-content-2 brdrRT">
            <div>
              {Reports}
              <br />
              <label>Feeds</label>
            </div>
          </Col>
          <Col xs={6} sm={6} md={6} xl={6} lg={6} className="tipItem bg-content-2 brdrLT">
            <div>
              {Resources} <br />
              <label>Sources</label>
            </div>
          </Col>
        </Row>
      );
      let containerStyleClass = 'tooltipMenu';
      this.props.togglePopup(FDPopup, content, mousePosition, containerStyleClass);
      this.setState({
        ...this.state,
        FDPopup,
      });
    }
  };

  toggleCRLtip = (event, stat) => {
    const { corelList, coreltaglimit, nosecTags } = this.state;
    console.log(nosecTags);
    let colWidth = parseInt(this.props.colRect.width.replace('px', '').trim());
    let Tip = stat;
    let balcrlItems = [];
    let balcrlcount = 0;
    let ContainerRect = this.moreTags.getBoundingClientRect();
    let mousePosition = {
      left: ContainerRect.x - 100,
      top: ContainerRect.bottom + 15,
    };
    if (corelList.length) {
      if (colWidth >= 700) {
        corelList.map((each, i) => {
          if (i >= coreltaglimit) {
            balcrlItems.push(each);
            balcrlcount++;
          }
          return null;
        });
      } else {
        corelList.map((each, i) => {
          if (each.Impact !== 'evttime' && each.Impact !== 'feeds' && each.Impact !== 'usrReach') {
            if (i > 0 && i - nosecTags >= coreltaglimit) {
              balcrlItems.push(each);
              balcrlcount++;
            }
          }
          return null;
        });
      }
    }
    let balCRLList = [];
    balcrlItems.forEach((item, i) => {
      if (item.Impact !== 'evttime' && item.Impact !== 'feeds' && item.Impact !== 'usrReach') {
        let tmp = (
          <Row className={`tipItem marg-0 h-100`} key={i}>
            <CorelTag each={item} tagicon={this.tagIcon(item)} tagcontent={this.tagContent(item)} {...this.props} />
          </Row>
        );
        balCRLList.push(tmp);
      }
    });
    let balCRLCountList = balcrlcount > 0 ? <div className={``}>{balCRLList}</div> : '';
    let containerClass = 'crl-tooltipMenu';

    this.props.togglePopup(Tip, balCRLCountList, mousePosition, containerClass);
    this.setState({
      ...this.state,
      crlmoretip: Tip,
    });
  };

  handleCollapse = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.togglePopup(false);
    this.props.handleCollapse();
  };
  toggleStorySourceTip = (e, container, Source = false, status = false) => {
    // console.log(e, container, Source, status)
    if(!isMobile) {
      if(this.srcttHandle) {
        clearTimeout(this.srcttHandle);
      }
      if (status) {
        let arrowstyledata = {};
        let tooltipstyledata = {}
          if(this.props.lang && this.props.lang === 'ara') {
            arrowstyledata.marginRight = '5px';
            tooltipstyledata.align = 'center'
            tooltipstyledata.alignRight = true
          } else {
            arrowstyledata.marginLeft = '5px';
            tooltipstyledata.align = 'right';
          }
          let ToolTipStyleData = {
            Arrow: {...arrowstyledata},
            ToolTip: {...tooltipstyledata},
          };
          let formattedsources = [];
          
          if(Source.length) {
            Source.forEach((src,i) => {
              // console.log(src)
              if(src === 'WebSites') {
                src = "WebSite(s)"
              }
              formattedsources.push(src)
            })
          }
          let tipText = `Sources: ${formattedsources.join(', ')}`
          this.srcttHandle = setTimeout(() => {
            this.props.toggleOverlay(true, this.SourceOverlay, tipText, ToolTipStyleData);
          }, 1000)
          
      } else {
        this.props.toggleOverlay(false);
      }

      // let ContainerRect = container.getBoundingClientRect();
      // let Pos = {
      //   left: ContainerRect.left -60,
      //   top: ContainerRect.bottom + 10,
      // };
      
      // const containerStyleClass = `infoTip normal-left blue x ${this.props.Theme === 'light' ? 'lightmode' : ''}`;
      // if (status) {
      //   let tipText = `Sources: ${Source.join(',')}`
      //   this.props.togglePopup(status, tipText, Pos, containerStyleClass);
      // } 
      // else {
      //   this.props.togglePopup(status);
      // }
    }

    // let ContainerRect = container.getBoundingClientRect();
    // let Pos = {
    //   left: ContainerRect.left -60,
    //   top: ContainerRect.bottom + 10,
    // };
    // if(this.srcttHandle) {
    //   clearTimeout(this.srcttHandle);
    // }
    
    // const containerStyleClass = `infoTip normal-left blue x ${this.props.Theme === 'light' ? 'lightmode' : ''}`;
    // if (status) {
    //   let tipText = `Sources: ${Source.join(',')}`
    //   this.srcttHandle = setTimeout(() => {
    //     this.props.togglePopup(status, tipText, Pos, containerStyleClass);
    //     // window.Mixpanel.MixpanelProps.UserName = this.props.userData.username;
    //     // window.Mixpanel.MixpanelProps.EventId = this.props.FeedId;
    //     // window.Mixpanel.MixpanelProps.Headline = this.props.HeadlineText;
    //     // window.Mixpanel.MixpanelProps.ToolTipType = 'Resource Indicator';
    //     // window.Mixpanel.actions.identify(this.props.userData.username);
    //     // window.Mixpanel.actions.track(`Tooltip shown`, window.Mixpanel.MixpanelProps).then((data) => {
    //     //   window.Mixpanel.actions.people.set({
    //     //     UserName: this.props.userData.username,
    //     //   });
    //     //   window.Mixpanel.actions.resetProp('EventId');
    //     //   window.Mixpanel.actions.resetProp('Headline');
    //     //   window.Mixpanel.actions.resetProp('ToolTipType');
    //     // });
    //   }, 1500);
    // } else {
    //   this.props.togglePopup(status);
    // }
  }
  toggleStorySourceTipMobile = (e, container, Source = false, status = false) => {

    if(isMobile) {
      if (status) {
        let arrowstyledata = {};
        let tooltipstyledata = {}
          if(this.props.lang && this.props.lang === 'ara') {
            arrowstyledata.marginRight = '5px';
            tooltipstyledata.align = 'center'
            tooltipstyledata.alignRight = true
          } else {
            arrowstyledata.marginLeft = '5px';
            tooltipstyledata.align = 'right'
          }
          let ToolTipStyleData = {
            Arrow: {...arrowstyledata},
            ToolTip: {...tooltipstyledata},
          };
          let formattedsources = [];
          
          if(Source.length) {
            Source.forEach((src,i) => {
              if(src === 'WebSites') {
                src = "WebSite(s)"
              }
              formattedsources.push(src)
            })
          }
          let tipText = `Sources: ${formattedsources.join(', ')}`
          this.props.toggleOverlay(true, this.SourceOverlay, tipText, ToolTipStyleData);
      }

      // let ContainerRect = container.getBoundingClientRect();
      // let Pos = {
      //   left: ContainerRect.left -60,
      //   top: ContainerRect.bottom + 10,
      // };
      
      // const containerStyleClass = `infoTip normal-left blue x ${this.props.Theme === 'light' ? 'lightmode' : ''}`;
      // if (status) {
      //   let tipText = `Sources: ${Source.join(',')}`
      //   this.props.togglePopup(status, tipText, Pos, containerStyleClass);
      // } 
      // else {
      //   this.props.togglePopup(status);
      // }
    }
    
    
  }
  ExtractSemtiment_Source = (story, type = 'Source') => {
    let LocationString = story.Location
    if(LocationString !== null) {
      let items = LocationString.split(';')
      let SentimentTotal = null;
      let Sources = [];
      if(items.length) {
        items.forEach((el, i) => {
          // console.log(el)
          let key = el.split(':')[0]
          let value = el.split(':')[1]
          if(key === 'SentimentTotal') {
            SentimentTotal = value
          } else if(key === 'Source') {
            Sources = value.split(',')
          }
        })
      }
      if(type === 'Sentiment') {
        if(typeof SentimentTotal === 'undefined' || isNaN(SentimentTotal)) {
          SentimentTotal = null
        }
        if(SentimentTotal !== null) {
          return parseFloat(SentimentTotal);
        } else {
          return SentimentTotal;
        }
        
      } else if(type === 'Source') {
        return Sources;
      }
      // console.log(Sources, SentimentTotal)
      // return { Source : Sources, Sentiment: SentimentTotal }
    }
    

  }

  feedSourceIcon = (source) => {
    let sourceType = source
    let feedIcon = '';
    // console.log(sourceType)
    switch (sourceType) {
      case 'Local News English':
        feedIcon = this.props.Theme === 'light' ? 'news_light' : 'news_dark';
        break;
      case 'International News English':
        feedIcon = this.props.Theme === 'light' ? 'news_light' : 'news_dark';
        break;
      case 'Financial News':
        feedIcon = this.props.Theme === 'light' ? 'news_light' : 'news_dark';
        break;
      case 'Financial News Aggregator':
        feedIcon = this.props.Theme === 'light' ? 'news_light' : 'news_dark';
        break;
      case 'Bloomberg':
        feedIcon = this.props.Theme === 'light' ? 'news_light' : 'news_dark';
        break;
      case 'Reuters':
        feedIcon = this.props.Theme === 'light' ? 'news_light' : 'news_dark';
        break;
      case 'Reddit':
        feedIcon = 'reddit_feed_source';
        break;
      case 'Telegram':
        feedIcon = 'telegram_feed_source';
        break;
      case 'Local News Local Language':
        feedIcon = this.props.Theme === 'light' ? 'news_light' : 'news_dark';
        break;
      case 'WebSites':
        feedIcon = this.props.Theme === 'light' ? 'general_feed_source1_1light' : 'general_feed_source1_1dark';
        break;
      case 'TwitterScraping':
        feedIcon = this.props.Theme === 'light' ? 'twitter_light' : 'twitter_dark';
        break;
      case 'Twitter':
        feedIcon = this.props.Theme === 'light' ? 'twitter_light' : 'twitter_dark';
        break;
      case 'TwitterScraping|Financial News':
        feedIcon = this.props.Theme === 'light' ? 'news_light' : 'news_dark';
        break;
      case 'Twitter Financial News':
        feedIcon = this.props.Theme === 'light' ? 'news_light' : 'news_dark';
        break;
      case 'Twitter Financial User':
        feedIcon = this.props.Theme === 'light' ? 'news_light' : 'news_dark';
        break;
      case 'Telegram Financial News':
        feedIcon = this.props.Theme === 'light' ? 'news_light' : 'news_dark';
        break;
      case 'Telegram Financial User':
        feedIcon = this.props.Theme === 'light' ? 'news_light' : 'news_dark';
        break;
      case 'Telegram|Financial User':
        feedIcon = this.props.Theme === 'light' ? 'news_light' : 'news_dark';
        break;
      default:
        feedIcon = this.props.Theme === 'light' ? 'news_light' : 'news_dark';
        break;
    }
    // if(source.toLowerCase().includes('twitter')) {
    //   feedIcon = 'twitter';
    // }
    return feedIcon;
  };

  render() {
    const { corelList, coreltaglimit, crlmoretip, nosecTags } = this.state;
    const { lang } = this.props;
    let colWidth = parseInt(this.props.colRect.width.replace('px', '').trim());
    // console.log(colWidth, coreltaglimit, nosecTags,  corelList)
    let balcrlcount = 0;
    let correltags = [];
    let timeFeedTags = [];
    if (colWidth >= 700) {
      // console.log('hhh')
      correltags = corelList.length
        ? corelList
            .map((each, i) => {
              // console.log(i < coreltaglimit)
              if (i <= coreltaglimit) {
                if (each.Impact !== 'evttime' && each.Impact !== 'feeds' && each.Impact !== 'usrReach') {
                  if (this.props.correlationResults && this.props.correlationResults.length > 1) {
                    return (
                      // <div
                      //   className={`coreltag hover ${this.props.Theme === 'light' ? 'lightmode' : ''} ${each.Impact === 'map' ? 'nopad' : ''} bgcrlsec`}
                      //   key={`tg${i}`}
                      //   ref={(el) => {
                      //     this.corelsArr.push(el);
                      //   }}
                      //   onClick={() => {
                      //     let option = {
                      //       SecurityTicker: each.Symbol,
                      //       SecurityName: each.Security,
                      //     };
                      //     if (TrendingMentionsHelper.FreeCoinFilter(option)) {
                      //       this.props.TMViewDetails(option);
                      //       this.props.storyTabSelect('Trending Mentions Details');
                      //     } else {
                      //       this.props.toggleConfirmPopup(
                      //         true,
                      //         true,
                      //         'Going Premium allows you to search data for thousands of additional coins'
                      //       );
                      //     }
                      //   }}
                      // >
                      //   <CorelTag
                      //     each={each}
                      //     tagicon={this.tagIcon(each)}
                      //     tagcontent={this.tagContent(each, true)}
                      //     {...this.props}
                      //   />
                      // </div>
                      ''
                    );
                  }
                } else {
                  
                  // if(this.props.storyID === 49344334) {
                  //   console.log(each)
                  //   console.log(coreltaglimit, i)

                  // }
                  
                  return (
                    <div
                      className={`coreltag ${this.props.Theme === 'light' ? 'lightmode' : ''} ${each.Impact === 'map' ? 'nopad' : ''} nobrdr`}
                      key={`tg${i}`}
                      ref={(el) => {
                        this.corelsArr.push(el);
                      }}
                    >
                      <CorelTag
                        each={each}
                        tagicon={this.tagIcon(each)}
                        tagcontent={this.tagContent(each, true)}
                        {...this.props}
                      />
                    </div>
                  );
                }
              } else {
                balcrlcount++;
              }
              return null;
            })
            .filter((item) => item)
        : [];
    } else {
      // console.log(coreltaglimit)
      correltags = corelList.length
        ? corelList
            .map((each, i) => {
              if (each.Impact !== 'evttime' && each.Impact !== 'feeds' && each.Impact !== 'usrReach') {
                if (i > 0 && i - nosecTags < coreltaglimit) {
                  return (
                    // <div
                    //   className={`coreltag hover ${each.Impact === 'map' ? 'nopad' : ''} bgcrlsec `}
                    //   key={`tg${i}`}
                    //   ref={(el) => {
                    //     this.corelsArr.push(el);
                    //   }}
                    //   onClick={() => {
                    //     let option = {
                    //       SecurityTicker: each.Symbol,
                    //       SecurityName: each.Security,
                    //     };
                    //     if (TrendingMentionsHelper.FreeCoinFilter(option)) {
                    //       this.props.TMViewDetails(option);
                    //       this.props.storyTabSelect('Trending Mentions Details');
                    //     } else {
                    //       this.props.toggleConfirmPopup(
                    //         true,
                    //         true,
                    //         'Going Premium allows you to search data for thousands of additional coins'
                    //       );
                    //     }
                    //   }}
                    // >
                    //   <CorelTag
                    //     each={each}
                    //     tagicon={this.tagIcon(each)}
                    //     tagcontent={this.tagContent(each, true)}
                    //     {...this.props}
                    //   />
                    // </div>
                    ''
                  );
                } else {
                  balcrlcount++;
                }
                // }
              }
              return null;
            })
            .filter((itm) => itm)
        : [];

      timeFeedTags = corelList.length
        ? corelList
            .map((each, i) => {
              if (each.Impact === 'evttime' || each.Impact === 'feeds' || each.Impact === 'usrReach') {
                return (
                  <div
                    className={`coreltag ${this.props.Theme === 'light' ? 'lightmode' : ''} ${each.Impact === 'map' ? 'nopad' : ''}${
                      each.Impact !== 'evttime' && each.Impact !== 'feeds' && each.Impact !== 'usrReach'
                        ? 'bgcrlsec'
                        : 'nobrdr'
                    }`}
                    key={`tg${i}`}
                    ref={(el) => {
                      this.corelsArr.push(el);
                    }}
                  >
                    <CorelTag
                      each={each}
                      tagicon={this.tagIcon(each)}
                      tagcontent={this.tagContent(each, true)}
                      {...this.props}
                    />
                  </div>
                );
              }
              return null;
            })
            .filter((itm) => itm)
        : [];
    }
    let balCRLCountList =
      balcrlcount > 0 ? (
        <div className={`crltagmore margr10p fs12 fssmsec `}>
          <span
            className="text-offWhite3"
            ref={(el) => (this.moreTags = el)}
            onClick={(e) => this.toggleCRLtip(e, !crlmoretip)}
          >
            +{balcrlcount}
          </span>
        </div>
      ) : (
        ''
      );
    const correlList = corelList.length
      ? corelList.map((each, i) => {
          if (each.Impact !== 'evttime' && each.Impact !== 'feeds' && each.Impact !== 'usrReach') {
            return <EachSecurity secData={each} key={i} handleCollapse={this.props.handleCollapse} clickable={false} />;
          }
          return null;
        })
      : '';

    
    let Source = this.ExtractSemtiment_Source(this.props.story)
    // console.log(Source)
    let sourceview = [];
    if(typeof Source !== 'undefined' && Source.length) {
      let iconArr = []
      Source.forEach((src, i) => {
        let icon = this.feedSourceIcon(src)
        if(iconArr.indexOf(icon) === -1) {
          sourceview.push(
            <div className={`coreltag srcico nobrdr ${this.props.Theme === 'light' ? 'lightmode' : ''}`}
              key={`tg${i}`}
              ref={(el) => {
                this.corelsArr.push(el);
              }}
              
              
            >
              <img
                src={`${APPPATH.ImageUrl}/${icon}.svg`}
                className={`timelineIndicator ${icon === 'reddit_feed_source' || icon === 'telegram_feed_source' ? 'srcico1' : icon === 'general_feed_source_1' ? 'srcico2' : 'srcico'} ${lang && lang === 'ara' ? 'arabic' : ''}`}
                alt="CrowdSense.ai"
              />
            </div>
          )
          iconArr.push(icon)
        }
        
      })
    }
    return (
      <div>
        {colWidth < 700 ? (
          <Row className="marg-0 security-detail-item ">
            <Col lg={12} sm={12} xs={12} md={12} xl={12} className=" nopad text-left">
              <Row className="marg-0">
                {/* {
                  this.props.render !== 'iframe' && (
                    <Col lg={1} sm={1} xs={1} md={1} xl={1} className=" nopad text-left">
                      <div className="marginTopX8" style={{marginLeft:'8px'}}>
                        
                        <LikeEvent {...this.props} story={this.props.story} storyID={this.props.storyID} />
                      </div>
                    </Col>
                  )
                }
                 */}
                <Col
                  lg={this.props.render !== 'iframe' ? 11 : 11}
                  sm={this.props.render !== 'iframe' ? 11 : 11}
                  xs={this.props.render !== 'iframe' ? 11 : 11}
                  md={this.props.render !== 'iframe' ? 11 : 11}
                  xl={this.props.render !== 'iframe' ? 11 : 11}
                  className={`coreltagcontainer ${lang && lang === 'ara' ? 'arabic text-right' : 'text-left'} `}//${colWidth < 500 ? 'reduceWidAdj' : ''}
                  // style={{flex: '0 0 61.333333%', maxWidth: '61.333333%'}}
                >
                  {timeFeedTags}
                  <div
                    onContextMenu={(e)=> {
                      e.preventDefault();
                      e.stopPropagation(); // not necessary in my case, could leave in case stopImmediateProp isn't available? 
                      // e.stopImmediatePropagation();
                      return false;
                    }}
                   className={`preventselect coreltag  nobrdr`}
                   ref={this.SourceOverlay}
                   onMouseEnter={(e) => {
                    if(!isMobile) {
                      
                      this.toggleStorySourceTip(e, this.SourceOverlay, Source, true)
                    }
                    
                  }}
                  onMouseLeave={(e) => {
                    if(!isMobile) {
                      this.toggleStorySourceTip(e, this.SourceOverlay)
                    }
                    
                  }}
                   onClick={(e) => {
                     e.stopPropagation();
                     if(isMobile) {
                      //  this.sourcetimerhandle = setTimeout((e) => {
                         
                         this.toggleStorySourceTipMobile(e, this.SourceOverlay, Source, true)
                      //  }, 300)
                     }
                     
                   }}
                  //  onTouchEnd={(e) => {
                  //    e.stopPropagation();
                  //    if(this.sourcetimerhandle) {
                  //      clearTimeout(this.sourcetimerhandle);
                  //    }
                  //  }}
                  >
                  { sourceview }
                  </div>
                  {/* <div className={`coreltag nobrdr`}
                      key={`tg${timeFeedTags.length +1}`}
                      ref={(el) => {
                        this.corelsArr.push(el);
                    }}
                  >
                    <LikeEvent {...this.props} story={this.props.story} storyID={this.props.storyID} />
                  </div> */}
                  
                </Col>
                <Col
                  lg={1}
                  sm={1}
                  xs={1}
                  md={1}
                  xl={1}
                  className={`coreltagcontainer ${lang && lang === 'ara' ? 'arabic text-left' : 'text-right'} optionMenuButton ${colWidth < 500 ? 'reduceWidAdj' : ''}`}
                  // style={{flex: '0 0 30.333333%', maxWidth: '30.333333%'}}
                >
                  <Row className="marg-0">
                    <Col className="nopad pointer padr9 ">
                      {/* <LikeEvent {...this.props} story={this.props.story} storyID={this.props.storyID} /> */}
                      <span className="pointer" onClick={this.props.handleCollapse} style={{paddingRight: '0px', position: 'relative', right: '0px', top: '0px'}}>
                        <span className={`text-sigbarTime ${this.props.Theme === 'light' ? 'lightmode' : ''} fs11 pointer text-left padr5`}>
                          
                        </span>
                        <img
                          style={{ width: '15px'}}
                          src={`${APPPATH.ImageUrl}/storyexpandicon.svg`}
                          className={`storyExpandIcon ${this.props.collapse ? '' : 'transform180anticlock'}`}
                          alt=""
                        />
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            {correltags.length > 1 && (
              <Col
                lg={12}
                sm={12}
                xs={12}
                md={12}
                xl={12}
                className=" text-left coreltagcontainer "
                ref={(el) => {
                  this.crlcontainer = el;
                }}
              >
                {/* {correltags} {balCRLCountList} */}
              </Col>
            )}
          </Row>
        ) : (
          <Row className="marg-0 security-detail-item ">
            {/* {
              this.props.render !== 'iframe' && (
                <Col lg={1} sm={1} xs={1} md={1} xl={1} className=" nopad text-left" style={{flex:'0 0 4.333333%',maxWidth: '4.333333%'}}>
                  <div className="marginTopX8" style={{marginLeft:'8px'}}>
                    
                    <LikeEvent {...this.props} story={this.props.story} storyID={this.props.storyID} />
                  </div>
                </Col>
              )
            }
             */}
            <Col
              lg={this.props.render !== 'iframe' ? 11 : 11}
              sm={this.props.render !== 'iframe' ? 11 : 11}
              xs={this.props.render !== 'iframe' ? 11 : 11}
              md={this.props.render !== 'iframe' ? 11 : 11}
              xl={this.props.render !== 'iframe' ? 11 : 11}
              className={`coreltagcontainer ${lang && lang === 'ara' ? 'arabic text-right' : 'text-left'} `}
              ref={(el) => {
                this.crlcontainer = el;
              }}
              // style={{flex: '0 0 61.333333%', maxWidth: '61.333333%'}}
            >
              {correltags.length >= 1 && correltags}
              <div
                onContextMenu={(e)=> {
                  e.preventDefault();
                  e.stopPropagation(); // not necessary in my case, could leave in case stopImmediateProp isn't available? 
                  // e.stopImmediatePropagation();
                  return false;
                }}
                className={`preventselect coreltag  nobrdr`}
                ref={this.SourceOverlay}
                onMouseEnter={(e) => {
                  if(!isMobile) {
                    this.toggleStorySourceTip(e, this.SourceOverlay, Source, true)
                  }
                  
                }}
                onMouseLeave={(e) => {
                  if(!isMobile) {
                    this.toggleStorySourceTip(e, this.SourceOverlay)
                  }
                  
                }}
                onTouchStart={(e) => {
                  e.stopPropagation();
                  if(isMobile) {
                    this.sourcetimerhandle = setTimeout((e) => {
                      
                      this.toggleStorySourceTipMobile(e, this.SourceOverlay, Source, true)
                    }, 300)
                  }
                  
                }}
                onTouchEnd={(e) => {
                  e.stopPropagation();
                  if(this.sourcetimerhandle) {
                    clearTimeout(this.sourcetimerhandle);
                  }
                }}
              >
                { sourceview }
              </div>
              <div className={`coreltag nobrdr ${this.props.Theme === 'light' ? 'lightmode' : ''}`}
                  key={`tg${corelList.length +1}`}
                  ref={(el) => {
                    this.corelsArr.push(el);
                }}
              >
                {/* <LikeEvent {...this.props} story={this.props.story} storyID={this.props.storyID} /> */}
              </div>
              {/* {balcrlcount > 0 && correltags.length > 1 && balCRLCountList} */}
            </Col>
            <Col
              lg={1}
              sm={1}
              xs={1}
              md={1}
              xl={1}
              className={`coreltagcontainer ${lang && lang === 'ara' ? 'arabic text-left' : 'text-right'} padright5  optionMenuButton`}
              // style={{flex: '0 0 30.333333%', maxWidth: '30.333333%'}}
            >
              <Row className="marg-0">
                <Col className="nopad pointer padr9 ">
                  {/* <LikeEvent story={this.props.story} storyID={this.props.storyID} /> */}
                  <span className="pointer" onClick={this.props.handleCollapse} style={{paddingRight: '0px', position: 'relative', right: '0px', top: '0px'}}>
                    <span className={`text-sigbarTime ${this.props.Theme === 'light' ? 'lightmode' : ''} fs11 pointer text-left padr5`}>
                      
                    </span>
                    <img
                      style={{ width: '15px'}}
                      src={`${APPPATH.ImageUrl}/storyexpandicon.svg`}
                      className={`storyExpandIcon ${this.props.collapse ? '' : 'transform180anticlock'}`}
                      alt=""
                    />
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
        )}

        <div
          className={`marg-0 margt10top ${this.state.showDetails ? '' : 'nodisp'} correllistContainer ${
            this.state.showDetails ? 'overflow-feeds' : ''
          } `}
        >
          {/* {correlList} */}
        </div>
      </div>
    );
  }
}

export default SecurityDetails;
